import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS } from "./ats";
import { CollaborationPortal } from "models/collaboration-portal";

@inject
export class CollaborationPortalRepository {
  constructor(private s: ATS) { }

  async post(portal: CollaborationPortal) {
    const res = await this.s.post("collaboration-portal", portal);
    return createFrom(CollaborationPortal, res.body);
  }

  async put(portal: CollaborationPortal) {
    const res = await this.s.put<CollaborationPortal>(
      `collaboration-portal/${portal.Id}`,
      portal,
    );
    return createFrom(CollaborationPortal, res.body);
  }

  async del(ids: string[]) {
    await this.s.post("collaboration-portal/delete", ids);
  }
}
