import { needs, prop, inject, ComponentEventBus } from "@derekpitt/fw"
import { type WidgetContext, TasksWidgetData } from "../../models";
import { Icon } from "../icon"

@needs(Icon)
@inject
export class TasksChart {
  @prop(false) interactive!: boolean;
  @prop(null) data!: TasksWidgetData;
  @prop(null) context!: WidgetContext;

  private chartRef: SVGElement;

  constructor(private ceb: ComponentEventBus) { }

  get currentData() {
    if (this.context == null || this.data == null) return null;

    return {
      currentUserId: this.context.CurrentUserId,
      overdue: this.data.Overdue,
      today: this.data.DueToday,
      soon: this.data.DueSoon,
    };
  }

  dataClick(type: string) {
    if (!this.interactive) return;

    const data = {
      currentUserId: this.currentData.currentUserId
    }

    this.ceb.dispatch("data-click", { data, type });
  }
}
