import { prop, inject } from "fw";
import { ContactsSlideroomApplicationsFilter as ContactsSlideroomApplicationsFilterModel } from "models/contacts-filters";

@inject
export class ContactsSlideroomApplicationsFilter {
  @prop(null) filter!: ContactsSlideroomApplicationsFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  private getOptions(name: string): string[] {
    try {
      if (this.filter.field.data.columns) {
        const options = this.filter.field.data.columns.find(c => c.name === name).options;
        if (options && options.length > 0) {
          return options.map(o => { return { text: o.name, value: o.name }; });
        }
      }
    } catch (ex) { }

    return [];
  }
}
