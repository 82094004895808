import { inject, prop } from "fw";

import { EvaluationPhasesStore } from "state/evaluation-phases";
import { MyCompletedEvaluationsFilter as MyCompletedEvaluationsFilterModel  } from "models/application-filters";
import { CurrentUserStore } from "state/current-user";

@inject
export class MyCompletedEvaluationsFilter {
  @prop(null)
  filter: MyCompletedEvaluationsFilterModel;

  @prop(() => ({}))
  validation;

  @prop(false)
  editing: boolean;

  constructor(
    private phaseStore: EvaluationPhasesStore,
    private currentUserStore: CurrentUserStore,
  ) {}

  get phases() {
    return [
      { text: "Any Phase", value: this.phaseStore.state.phases.map(p => p.Key).join(',')},
      { text: "Current Phase", value: "current" },
      ...this.phaseStore.state.phases.map(p => ({ text: p.Name, value: p.Key })),
    ];
  }

  getPhaseName(phaseKey: string): string {
    if(phaseKey.indexOf(',') > -1) return "Any Phase";
    if(phaseKey == "current") return "Current Phase";

    return this.phaseStore.state.phases.find(p => p.Key == phaseKey)?.Name;
  }

  async attached() {
    this.filter.userCompletedEvaluation = true;
    this.filter.userId = this.currentUserStore.state.user.Id;
  }
}
