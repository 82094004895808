export class PortalMetaData {
  OrganizationId: string = null;
  SeasonId: string = null;
  ScopedCss: string = null;
}

export enum PortalColorType {
  primaryColor = "PrimaryColor",
  secondaryColor = "SecondaryColor",
  tertiaryColor = "TertiaryColor",
  navBarColor = "NavBarColor",
}

export class PortalContent {
  FavIconFileId: string = null;
  FavIconUrl: string = null;
  CompactLogoFileId: string = null;
  CompactLogoUrl: string = null;
  FullLogoFileId: string = null;
  FullLogoUrl: string = null;
  OrganizationDisplayName: string = null;
  OrganizationWebsiteUrl: string = null;
  SupportEmailAddress: string = null;
  SupportPhoneNumber: string = null;
  TermsAndConditionsMessage: string = null;
  DashboardWelcomeBGImageFileId?: string = null;
  DashboardWelcomeBGImageUrl?: string = null;
  DashboardWelcomeMessage?: string = null;
  WelcomeMessageBGImageFileId: string = null;
  WelcomeMessageBGImageUrl: string = null;
  WelcomeMessage: string = null;
  TrackingScript: string = null;
  CompactLogoLightFileId: string = null;
  CompactLogoLightUrl: string = null;
  FullLogoLightFileId: string = null;
  FullLogoLightUrl: string = null;
  [PortalColorType.primaryColor]: string = null;
  [PortalColorType.secondaryColor]: string = null;
  [PortalColorType.tertiaryColor]: string = null;
  [PortalColorType.navBarColor]: string = null;
}

export enum PortalSocialType {
  faceBookPageUrl = "FaceBookPageUrl",
  linkedInPageUrl = "LinkedInPageUrl",
  twitterPageUrl = "TwitterPageUrl",
  youTubePageUrl = "YouTubePageUrl",
  instagramPageUrl = "InstagramPageUrl",
  tikTokPageUrl = "TikTokPageUrl",
  snapchatPageUrl = "SnapchatPageUrl",
  flickrPageUrl = "FlickrPageUrl",
}

export class PortalSocial {
  [PortalSocialType.faceBookPageUrl]: string = null;
  [PortalSocialType.linkedInPageUrl]: string = null;
  [PortalSocialType.twitterPageUrl]: string = null;
  [PortalSocialType.youTubePageUrl]: string = null;
  [PortalSocialType.instagramPageUrl]: string = null;
  [PortalSocialType.tikTokPageUrl]: string = null;
  [PortalSocialType.snapchatPageUrl]: string = null;
  [PortalSocialType.flickrPageUrl]: string = null;
}

export const DEFAULT_HTML = `
  <!DOCTYPE html>
  <html xmlns="http://www.w3.org/1999/xhtml">

  <head>
      <style>
          * {
              box-sizing: border-box;
          }

          body {
              margin: 0;
          }

          .row {
              display: table;
              padding-top: 10px;
              padding-right: 10px;
              padding-bottom: 10px;
              padding-left: 10px;
              width: 100%;
          }

          .cell {
              width: 8%;
              display: table-cell;
          }

          @media (max-width: 768px) {
              .cell {
                  width: 100%;
                  display: block;
              }
          }
      </style>
  </head>

  <body>
      <div class="row">
          <div class="cell">
              <section class="bdg-sect">
                  <h1 class="heading">Headline text goes here</h1>
                  <p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua </p>
              </section>
          </div>
      </div>
  </body>

  </html>`;

export const PRIMARY_COLOR_DEFAULT = "D4E157";
export const SECONDARY_COLOR_DEFAULT = "0D47A1";
export const TERTIARY_COLOR_DEFAULT = "E3F2FD";
export const NAVBAR_COLOR_DEFAULT = "12274E";

export const PORTAL_SOCIAL_NETWORKS: { label: string; value: string }[] = [
  { label: "FaceBook Page Url", value: PortalSocialType.faceBookPageUrl },
  { label: "Instagram Page Url", value: PortalSocialType.instagramPageUrl },
  { label: "Twitter Page Url", value: PortalSocialType.twitterPageUrl },
  { label: "TikTok Page Url", value: PortalSocialType.tikTokPageUrl },
  { label: "LinkedIn Page Url", value: PortalSocialType.linkedInPageUrl },
  { label: "Snapchat Page Url", value: PortalSocialType.snapchatPageUrl },
  { label: "Flickr Page Url", value: PortalSocialType.flickrPageUrl },
  { label: "YouTube Page Url", value: PortalSocialType.youTubePageUrl },
];

export const PORTAL_COLORS: { defaultColor: string; field: string }[] = [
  {
    defaultColor: PRIMARY_COLOR_DEFAULT,
    field: PortalColorType.primaryColor,
  },
  {
    defaultColor: SECONDARY_COLOR_DEFAULT,
    field: PortalColorType.secondaryColor,
  },
  {
    defaultColor: TERTIARY_COLOR_DEFAULT,
    field: PortalColorType.tertiaryColor,
  },
  {
    defaultColor: NAVBAR_COLOR_DEFAULT,
    field: PortalColorType.navBarColor,
  },
];
