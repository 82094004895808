import { inject, needs, prop } from "fw";
import { dispatch } from "fw-state";

import { type ICustomFieldDefinition, ICustomFieldOption } from "models/contact-organization";
import { orderBy, replace } from "lodash-es";
import { TagPill } from "views/components/tag-pill";
import { ItemAssignmentContext, ItemState } from "views/components/item-assignment-context";
import { hashOn } from "hashing";
import { PopoverController } from "service/popover";
import { Notification } from "service/notification";
import { EntitySelection } from "models/application-client-model";
import { BulkUpdateContactPropertiesAction, ContactStore } from "state/contacts";

import { Operation } from "fast-json-patch";
import { ContactRepository } from "network/contact-repository";
import { IBucketResult } from "network/ats";
import { select } from "d3";

export interface TagContactsPopoverData {
  selection: EntitySelection;
  selectionTotal: number;
  dataId: string;
  field: ICustomFieldDefinition;
}

export type CountHash = {
   [tag: string]: number
}

@inject
@needs(TagPill, ItemAssignmentContext)
export class TagContactsPopover {
  @prop(null) private field!: ICustomFieldDefinition;
  public selection: EntitySelection = null;
  public selectionTotal: number = 0;
  public loadingContext: boolean = true;
  public tags: { id: string, name: string }[] = [];
  public tagContext: CountHash = null;
  public tagStateHash: { [tag: string]: ItemState } = {};
  public saving: boolean = false;



  constructor(
    private notify: Notification,
    private controller: PopoverController<void>,
    private contactsRepo: ContactRepository,
    private contactStore: ContactStore
  ) {}

  public async activate(params: TagContactsPopoverData) {

    this.selection = params.selection;
    this.selectionTotal = params.selectionTotal;
    this.field = params.field;
    await this.loadContext();
  }

  private async loadContext() {
    this.loadingContext = true;

    let tags: string[];
    tags = orderBy(this.field.options, 'display_order').map(o => o.name);

    this.tagStateHash = hashOn(
      tags,
      t => t,
      t => "none" as ItemState,
    );

    this.tags = tags.map(t => ({ id: t, name: t }));

    let selection = this.selection;
    if (this.contactStore.state.selectAll){
      selection.filter = this.contactStore.getWholeFilter();
      selection.excludedIds = [];
      selection.ids = [];
    }

    const res = await this.contactsRepo.count(selection, null, "terms:tags");

    this.tagContext = res.aggregations["terms_tags"].items.reduce<CountHash>((ctx:  CountHash ,i: IBucketResult) => {ctx[i.key as string] = i.total; return ctx;} , {}  ); // { ["one"]: 1, ["two"]: 2  };// await this.appRepo.tagsContext(this.selection);

    this.loadingContext = false;
  }
  public get hasSomeActions(): boolean {
    for (const key in this.tagStateHash) {
      switch (this.tagStateHash[key]) {
        case "add":
        case "remove":
          return true;
      }
    }

    return false;
  }

  public async save() {
    this.saving = true;

    const addTags: string[] = [];
    const deleteTags: string[] = [];
    const operations: Operation[] = [];

    for (const key in this.tagStateHash) {
      switch (this.tagStateHash[key]) {
        case "add":
          operations.push(<Operation>{ "op": "add","path": "/tags/-", "value": key });
          break;

        case "remove":
          operations.push(<Operation>{ "op": "remove","path":"$.tags[?(@ == '" + key.replace("'","\\'") + "')]" });
          break;
      }
    }

    try {
      await dispatch(new BulkUpdateContactPropertiesAction(this.selection, operations));
      this.notify.notify(`Tagging ${this.selectionTotal} contact${this.selectionTotal == 1 ? '' : 's'}`);
      this.controller.ok(null);
    } catch(err) {}

    this.saving = false;
  }

  public createNewTag(newTag: string) {
    this.tags.unshift({ id: newTag, name: newTag });

    this.tagContext = {
      ...this.tagContext,
      [newTag]: null,
    };
    this.tagStateHash = {
      ...this.tagStateHash,
      [newTag]: "add" as ItemState,
    };
  }

}
