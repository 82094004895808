import { inject } from 'fw';
import { PopoverController } from 'service/popover';
import { FormForType } from 'fw-model';
import { exportCodeSetFormCreator } from 'forms/decision-settings';

export class AddUpdateExportCodeSetModalResult {
  constructor(public Name: string, public Action: 'save' | 'remove') { }
}

export interface AddUpdateExportCodeSetModalParams {
  Name: string;
  AllowRemove: boolean;
}

@inject
export class AddUpdateExportCodeSetModal {
  private form: FormForType<AddUpdateExportCodeSetModalResult> = null;
  private allowRemove: boolean = true;

  constructor(private popoverController: PopoverController<AddUpdateExportCodeSetModalResult>) { }

  private activate(params: AddUpdateExportCodeSetModalParams) {
    if (params != null) {
      this.allowRemove = params.AllowRemove;
    }
    const result = ((params != null) && (params.Name != null))
      ? new AddUpdateExportCodeSetModalResult(params.Name, 'save')
      : new AddUpdateExportCodeSetModalResult(null, 'save')

    this.form = exportCodeSetFormCreator(result);
  }

  private save() {
    this.form.validate();
    this.popoverController.ok(<AddUpdateExportCodeSetModalResult>{
      Action: "save",
      Name: this.form.updatedModel().Name
    });
  }

  private cancel() {
    this.popoverController.cancel();
  }

  private remove() {
    this.popoverController.ok(<AddUpdateExportCodeSetModalResult>{
      Action: "remove"
    });
  }
}
