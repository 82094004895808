import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import {  createFrom } from "fw-model";
import {
  ContactsIntegrationSettings,
  CeebImportStatus,
  ContactsCeebDataSettings,
} from "models/contacts-integration-settings";
import { IntegrationRepository } from "network/integration-repository";
import { UpdateContactsIntegrationSettingsAction } from "forms/integration-contacts";
import { EnsureTaskRequestAction, TaskFinishedAction, StartAction, LogoutAction } from "./actions";
import { TaskRequestStatusTypeCode } from "models/task-request";

interface IntegrationsStoreShape {
  contacts: {
    settings: ContactsIntegrationSettings;
    enabled: boolean;
  };
}

@inject
export class IntegrationsStore extends Store<IntegrationsStoreShape> {
  constructor(private integrationRepo: IntegrationRepository) {
    super();
  }

  defaultState() {
    return {
      contacts: {
        settings: null,
        enabled: false,
      },
    };
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    const settings = action.context.ContactsIntegrationSettings;

    if (settings.CeebDataSettings == null)
      settings.CeebDataSettings = createFrom(ContactsCeebDataSettings, {});

    if (
      settings != null &&
      settings.CeebDataSettings != null &&
      settings.CeebDataSettings.InitialImportStatus ==
        CeebImportStatus.Running &&
      settings.CeebDataSettings.IsEnabled
    ) {
      dispatch(
        new EnsureTaskRequestAction(
          [settings.CeebDataSettings.ImportTaskRequestId],
          "Updating CEEB Settings",
        ),
      );
    }

    this.setState(state => ({
      ...state,
      contacts: {
        settings,
        enabled: settings.Id != null,
      },
    }));
  }

  @handle(UpdateContactsIntegrationSettingsAction)
  private async handleUpdateContactsIntegrationSettingsAction(
    action: UpdateContactsIntegrationSettingsAction,
  ) {
    action.form.validate();
    const model = action.form.updatedModel();

    // lets clean it
    if (!model.CeebDataSettings.IsEnabled) {
      model.CeebDataSettings.FieldMappings = [];
    }

    const settings = await this.integrationRepo.putContactsSettings(model);
    this.setState(state => ({
      ...state,
      contacts: {
        settings,
        enabled: settings.Id != null,
      },
    }));
  }

  @handle(TaskFinishedAction)
  private handleTaskFinishedAction(action: TaskFinishedAction) {
    const settings = this.state.contacts.settings;

    if (settings == null || settings.CeebDataSettings == null) return;

    const {
      ImportTaskRequestId,
      InitialImportStatus,
    } = settings.CeebDataSettings;

    if (ImportTaskRequestId != action.taskRequest.Id) return;
    if (InitialImportStatus != CeebImportStatus.Running) return;

    settings.CeebDataSettings.InitialImportStatus =
      action.taskRequest.Status == TaskRequestStatusTypeCode.Complete
        ? CeebImportStatus.Complete
        : CeebImportStatus.Error;

    this.setState(s => s);
  }
}
