import { inject, needs } from "fw";
import { createFrom } from "fw-model";
import { DialogController } from "fw-dialog";

import {
  ReportChartDefinition,
  WidgetRenderer,
  WidgetData,
  ChartDataSource,
} from "shared/report-runtime";
import { GroupFilter } from "models/filter-setup";
import { AppRepository } from "network/app-repository";
import { ReportChart } from "./report-chart";
import { WidgetContext } from "./widget-context";
import { FeatureFlagService } from "service/feature-flag";

export type ChartFilterArgs = {
  groupFilter: any;
  chartDefinition: ReportChartDefinition;
  data: WidgetData;
  reportFilter: string;
};

@inject
@needs(WidgetRenderer, ReportChart, WidgetContext)
export class ChartFilter {
  constructor(
    private controller: DialogController<GroupFilter>,
    private appRepo: AppRepository,
    private ffs: FeatureFlagService
  ) { }

  public chart: ReportChartDefinition = null;
  public chartData: WidgetData = null;
  public chartDataLoading = false;
  private reportFilter = "";

  public fc: GroupFilter = null;
  public validateFilters: (validate?: boolean) => Promise<boolean> = null;

  public activate(args: ChartFilterArgs) {
    this.fc = createFrom(GroupFilter, args.groupFilter);
    this.chart = args.chartDefinition;
    this.chartData = args.data;
    this.reportFilter = args.reportFilter;
  }

  public get isContactsChart() {
    return this.chart.DataSource === ChartDataSource.Contacts;
  }

  public get isAdmissionsChart() {
    return this.chart.DataSource === ChartDataSource.Admissions;
  }

  public get isTasksChart() {
    return this.chart.DataSource === ChartDataSource.Tasks;
  }

  public close() {
    this.controller.cancel();
  }

  public async update() {
    if (this.validateFilters && !await this.validateFilters()) {
      return;
    }

    this.controller.ok(this.fc);
  }

  async filterChanged(newFilter: GroupFilter) {
    if (this.ffElectiveIndexing) {
      return;
    }

    this.chartDataLoading = true;

    try {
      this.chart.ChartFilter = newFilter.toFilterString();
      const res = await this.appRepo.previewReportChartData(this.reportFilter, [
        this.chart,
      ]);
      this.chartData = res[0];
    } catch (err) { }

    this.chartDataLoading = false;
  }

  public get ffElectiveIndexing(): boolean {
    return this.ffs.isFeatureFlagEnabled("ElectiveIndexing");
  }
}
