import { inject, ComponentEventBus, prop } from "fw";

import { CurrentOrganizationStore } from "state/current-organization";

import moment from "moment";
import "moment-timezone";
import { DateService } from "service/date-service";

@inject
export class DatetimeField {
  @prop(null) public label!: string;
  @prop("MM/DD/YYYY") public format!: string;
  @prop("toISOString") public syncFormat!: string;
  @prop(true) public useTimezone!: boolean;
  @prop(null) public value!: string;
  @prop(null) public autocomplete!: string;
  @prop(false) public required: boolean;
  @prop(null) public minDate: Date;
  @prop(null) public maxDate: Date;
  @prop(null) public validation: string;
  @prop(false) public readonly: boolean;

  public localValue: string = "";
  private timezone: string = "America/Chicago";
  private onlyDateISO: RegExp = /(\d{4})-(\d{2})-(\d{2})$/s;

  constructor(
    private componentEventBus: ComponentEventBus, 
    private currentOrganizationStore: CurrentOrganizationStore,
    private dateService: DateService) {
    this.timezone = this.currentOrganizationStore.state.organization.Timezone || this.timezone;
  }

  public attached() {
    this.valueChanged();
  }

  public valueChanged() {
    let date = moment(this.value, moment.ISO_8601);
    if (this.useTimezone) {
      date = date.tz(this.timezone);
    }

    this.localValue = date.isValid() ? date.format(this.format) : "";
  }

  private valueChange() {
    const date = this.useTimezone
      ? moment.tz(this.dateService.parseDateOnly(this.localValue), this.timezone)
      : moment(this.dateService.parseDateOnly(this.localValue), moment.ISO_8601);
    let syncFormatValue = "";
    if (date.isValid()) {
      this.localValue = date.format(this.format);
      syncFormatValue = this.syncFormat == "toISOString" ? date.toISOString() : date.format(this.syncFormat);
      // Remove empty milliseconds to avoid false positives in form dirtiness checks caused by discrepancies
      // between toISOString format and the format in which we save datetimes with no time component.
      // ("2023-09-03T05:00:00.000Z" vs "2023-09-03T05:00:00Z")
      syncFormatValue = syncFormatValue.replace(/\.000Z$/, 'Z');
    }

    this.componentEventBus.dispatch("update:value", syncFormatValue);
    this.componentEventBus.dispatch("change");
  }
}
