import { fromClass } from "fw-model";
import { InquiryResponse } from "./inquiry-response";
import { getSeriesAndInstanceFromId } from "./calendar-event";

export enum CalendarEventRegistrationStatus {
  registered = "Registered",
  cancelled = "Cancelled",
  attended = "Attended",
  waitListed = "WaitListed",
}

export enum CalendarEventRegistrationStatusPretty {
  registered = "Registered",
  cancelled = "Cancelled",
  attended = "Checked-In",
  waitListed = "Wait Listed",
}

export class CalendarEventRegistration {
  id: string = null;
  organization_id: string = null;
  calendar_event_series_id: string = null;
  calendar_event_id: string = null;
  contact_id: string = null;
  details: string = null;
  status: CalendarEventRegistrationStatus = null;
  guest_count: number = null;
  first_name: string = null;
  last_name: string = null;
  email_address: string = null;
  date_time_utc: Date = null; // registration date
  inquiry_response_id: string = null;
  @fromClass inquiry_response: InquiryResponse;
}

export class CalendarEventRegistrationStatusInfo {
  status: string = null;
  registration_count: number = 0;
  guest_count: number = 0;
  total_count: number = 0;
}

export class ContactRegistrationResponse {
  first_name: string = null;
  last_name: string = null;
  email_address: string = null;
  guest_count: number = 0;
  registration_form_name: string = null;
  registration_form_id: string = null;
  inquiry_response_id: string = null;
  contact_id: string = null;
  organization_id: string = null;
  calendar_event_registration_id: string = null;
  calendar_event_series_id: string = null;
  instance_key: string = null;
  data_source_instance_id: string = null;
}

export const getSeriesAndInstanceFromRegistration = (registration: CalendarEventRegistration): { calendarEventSeriesId: string, instanceKey: string } => {
  return getSeriesAndInstanceFromId(registration?.calendar_event_id);
}
