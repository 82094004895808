import { needs, prop, inject, ComponentEventBus } from "@derekpitt/fw"
import { type WidgetContext, AssignmentData } from "../../models";
import { Icon } from "../icon"

import { percentPie } from "slideroom-charts";
import { AssignmentCounts } from "./progress";

@needs(Icon)
@inject
export class AssignmentChart {
  @prop(false) interactive!: boolean;
  @prop(null) data!: AssignmentData;
  @prop(null) context!: WidgetContext;
  @prop("Assignment chart") svgAlt: string;

  private chartRef: SVGElement;

  constructor(private ceb: ComponentEventBus) {}

  get currentData() {
    if (this.context == null || this.data == null) return null;

    if (this.chartRef != null) {
      const progress = this.data.Total != 0 ? 100 * this.data.Completed / this.data.Total : 0;
      percentPie(this.chartRef, progress, "Progress");
    }

    return {
      mode: this.data.Definition.Mode,
      teamId: this.data.Definition.Mode == "team" ? this.data.Definition.Id : null,
      userId: this.data.Definition.Mode == "user" ? this.data.Definition.Id || this.context.CurrentUserId : null,
      you: this.data.Definition.Mode == "user" && (this.data.Definition.Id == null || this.data.Definition.Id == "" || this.data.Definition.Id == this.context.CurrentUserId),
      phaseKey: this.data.Definition.PhaseKey,
      name: this.data.ObjectName,
      completed: this.data.Completed,
      total: this.data.Total,
      remaining: Math.max(this.data.Total - this.data.Completed,0)
    };
  }

  get subject() {
    const { mode, name, you } = this.currentData;
    switch (mode) {
      case "user":
        if (you) return "You"
        else return name;
      case "team":
        return `The ${name} team`;
    }
  }

  get verb() {
    return this.subject == "You" ? "have" : "has";
  }

  attached() {
    if (this.currentData != null) {
      const { completed, total } = this.currentData;
      const progress = total != 0 ? 100 * completed / total : 0;
      percentPie(this.chartRef, progress, "Progress");
    }
  }

  dataClick(type: string) {
    if (!this.interactive) return;

    const data = {
      userId: this.currentData.userId,
      teamId: this.currentData.teamId,
      phaseKey: this.currentData.phaseKey
    }

    this.ceb.dispatch("data-click", { data, type });
  }
}
