import {
  conditionExpressionFormCreator,
  conditionFormCreator,
  ConditionFormType,
  conditionGroupFormCreator,
  type ConditionGroupFormType,
} from "forms/condition-info";
import { prop, inject, ComponentEventBus, needs } from "fw";
import { Completer } from "helpers/auto-complete";
import { DataDictionaryField } from "models/data-dictionary";
import {
  Condition,
  ConditionExpression,
  ConditionFieldType,
  ConditionGroup,
  ConditionGroupOperator,
  ConditionOperator,
} from "models/form";
import { UtilityRepository } from "network/utility-repository";
import { ConditionBuilderCondition } from "./condition-builder-condition";
import { ConditionBuilderExpression } from "./condition-builder-expression";

@needs(ConditionBuilderCondition, ConditionBuilderExpression)
@inject
export class ConditionBuilderGroup {
  @prop(null) public fields: DataDictionaryField[];
  @prop(null) public group: ConditionGroupFormType;
  @prop(null) public completer: Completer | Completer[];
  @prop(false) public disabled: boolean;
  @prop(true) public showClear: boolean;
  @prop(true) public showFunctionSelector: boolean;
  @prop(true) public showFieldDictionaryLink: boolean;
  @prop(false) private expressionModeOnly!: boolean;

  @prop(true) public top?: boolean;
  @prop(true) public canDelete: boolean;

  @prop(0) public nestLevel: number;

  constructor(
    private utilityRepo: UtilityRepository,
    private ceb: ComponentEventBus
  ) {}

  public attached() {
    if (!this.disabled && this.group.Conditions?.length == 0) {
      this.addCondition();
    }
  }

  public clickAnd() {
    this.group.Operator = ConditionGroupOperator.And;
    this.onChanged();
  }

  public clickOr() {
    this.group.Operator = ConditionGroupOperator.Or;
    this.onChanged();
  }

  public addCondition() {
    let newRule: Condition = {
      Field: null,
      FieldType: ConditionFieldType.String,
      Operator: ConditionOperator.Equals,
      Value: null,
      ValueIsField: false,
    };
    let newRuleForm = conditionFormCreator(newRule);
    this.group.Conditions.push(newRuleForm);
    if (this.expressionModeOnly) {
      this.useExpression(
        newRuleForm,
        this.group.Conditions.indexOf(newRuleForm)
      );
    } else {
      this.onChanged();
    }
  }

  public async addGroup() {
    let newGroup: ConditionGroup = {
      Operator:
        this.group.Operator == ConditionGroupOperator.And
          ? ConditionGroupOperator.Or
          : ConditionGroupOperator.And,
      Conditions: [
        {
          Field: null,
          FieldType: ConditionFieldType.String,
          Operator: ConditionOperator.Equals,
          Value: null,
          ValueIsField: false,
        },
      ],
    };
    let newGroupForm = conditionGroupFormCreator(newGroup);
    if (this.expressionModeOnly) {
      newGroupForm.Conditions[0] = await this.convertToExpressionForm(
        newGroupForm.Conditions[0] as ConditionFormType
      );
    }
    this.group.Conditions.push(newGroupForm);
    this.onChanged();
  }

  public deleteSelf() {
    this.ceb.dispatch("delete-group");
  }

  public deleteRule(index) {
    this.group.Conditions.splice(index, 1);
    this.onChanged();
  }

  public clearCondition() {
    this.group.Conditions.splice(0);
    this.onChanged();
  }

  private async convertToExpressionForm(condition: ConditionFormType) {
    const conditionExpression: ConditionExpression = new ConditionExpression();
    conditionExpression.Expression = await this.generateExpression(condition);

    return conditionExpressionFormCreator(conditionExpression);
  }

  public async useExpression(condition: ConditionFormType, index: number) {
    const conditionExpressionForm = await this.convertToExpressionForm(
      condition
    );
    this.group.Conditions.splice(index, 1, conditionExpressionForm);
    this.onChanged();
  }

  private async generateExpression(
    condition: ConditionFormType
  ): Promise<string> {
    if (!condition.hasOwnProperty("Expression") && !condition?.Field && !condition?.Value) {
      return null;
    }

    try {
      const cg = new ConditionGroup();
      cg.Operator = this.group.Operator;
      cg.Conditions.push(condition.updatedModel());
      return await this.utilityRepo.generateConditionScript(cg);
    } catch {
      return null;
    }
  }

  public onChanged() {
    this.ceb.dispatch("change");
  }
}
