import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { AutomationRule, AutomationRuleExecution } from "models/automation-rule";

@inject
export class AutomationRuleRepository {
  constructor(private s: ATS) { }

  public async list() {
    const res = await this.s.get<any[]>("automation-rule");
    return createFromArray(AutomationRule, res.body);
  }

  async post(rule: AutomationRule) {
    const res = await this.s.post(`automation-rule`, rule);
    return createFrom(AutomationRule, res.body);
  }

  async run(ruleId: string) {
    await this.s.post(`automation-rule/${ruleId}/run`, null);
  }

  async put(rule: AutomationRule) {
    const res = await this.s.put(`automation-rule/${rule.Id}`, rule);
    return createFrom(AutomationRule, res.body);
  }

  public async del(ids: string[]) {
    await this.s.delete(`automation-rule/${ids.join(",")}`);
  }

  public async execution(ruleId: string, page = 1, pageSize = 20, f: string = null) {
    const res = await this.s.get<any[]>(`automation-rule/${ruleId}/execution`, { page, pageSize, f });
    return { list: createFromArray(AutomationRuleExecution, res.body), total: parseInt(res.headers["x-ats-total"], 10) };
  }
}
