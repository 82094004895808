import { inject } from "fw";
import { Store, dispatch, handle } from "fw-state";

import { once } from "helpers/once";
import { LogoutAction, StartAction } from "./actions";
import { ContactsDataSource } from "models/contacts-data-source";
import { ContactsDataSourceInstance } from "models/contacts-data-source-instance";
import { DataSourceRepository } from "network/data-source-repository";
import { DataSourceInstanceRepository } from "network/data-source-instance-repository";
import { remove } from "lodash-es";
import { compare } from "fast-json-patch";
import {
  EntityChanged,
  WebSocketMessageAction,
  filterWebsocketMessage
} from './filter-websocket-message';

type WaitResolver = () => void;

export class EnsureContactsDataSourceInstanceStoreAction {
  constructor() { }
}

export class DataSourcesChangedAction {
  constructor() { }
}

export class DataSourceInstancesChangedAction {
  constructor() { }
}

export class AddContactsDataSourceInstanceAction {
  constructor(public instance: ContactsDataSourceInstance) { }
}

export class UpdateContactsDataSourceInstanceAction {
  constructor(public instance: ContactsDataSourceInstance) { }
}

export class RemoveContactsDataSourceInstanceAction {
  constructor(public instance: ContactsDataSourceInstance) { }
}

interface ContactsDataSourceInstanceStoreShape {
  organizationId: string;
  loadingSources: boolean;
  loadingInstances: boolean;
  dataSources: ContactsDataSource[];
  dataSourceInstances: ContactsDataSourceInstance[];
}

@inject
export class ContactsDataSourceInstanceStore extends Store<ContactsDataSourceInstanceStoreShape> {
  constructor(
    private dataSourceRepository: DataSourceRepository,
    private dataSourceInstanceRepository: DataSourceInstanceRepository,
  ) {
    super();
  }
  private isFetchingInstances = false;
  private fetchedInstances = false;
  private isFetchingDataSources = false;
  private fetchedDataSources = false;

  private instanceWaiters: WaitResolver[] = [];
  private dataSourceWaiters: WaitResolver[] = [];

  defaultState() {
    return {
      organizationId: null,
      loadingSources: false,
      loadingInstances: false,
      dataSources: [],
      dataSourceInstances: [],
    }
  }

  private async updateInstances() {
    if (!this.state.organizationId)
      return;

    this.isFetchingInstances = true;
    this.fetchedInstances = false;

    let dsiResult = await this.dataSourceInstanceRepository.list(null, null, null, null, 1, 1000);
    let instances: ContactsDataSourceInstance[] = [];
    dsiResult.results.forEach(d => {
      if (d.is_deleted || d.name === "ATS Integration" /* remove from the client to prevent editing */) {
        return;
      }

      instances.push(d);
    });

    await this.setDataSourceInstancesState(instances);

    this.isFetchingInstances = false;
    this.fetchedInstances = true;

    this.drainWaiters(this.instanceWaiters);
  }

  private async updateDataSources() {
    if (!this.state.organizationId)
      return;

    this.isFetchingDataSources = true;
    this.fetchedDataSources = false;

    const dsResult = await this.dataSourceRepository.list();
    dsResult.results = dsResult.results.filter(ds => ds.key !== "slideroom");
    
    await this.setDataSourceState(dsResult.results);

    this.isFetchingDataSources = false;
    this.fetchedDataSources = true;

    this.drainWaiters(this.dataSourceWaiters);
  }

  private drainWaiters(list: WaitResolver[]) {
    list.forEach(w => w());
    list = [];
  }

  private async waitOnCall(list: WaitResolver[]) {
    return new Promise<void>((res) => { list.push(res); });
  }

  public async getInstances(): Promise<ContactsDataSourceInstance[]> {
    if (this.fetchedInstances) {
      return this.state.dataSourceInstances;
    }

    if (this.isFetchingInstances) {
      await this.waitOnCall(this.instanceWaiters);
    } else {
      await this.updateInstances();
    }

    await dispatch(new DataSourceInstancesChangedAction());
    return this.state.dataSourceInstances;
  }

  public async getDataSources(): Promise<ContactsDataSource[]> {
    if (this.fetchedDataSources) {
      return this.state.dataSources;
    }

    if (this.isFetchingDataSources) {
      await this.waitOnCall(this.dataSourceWaiters);
    } else {
      await this.updateDataSources();
    }

    await dispatch(new DataSourcesChangedAction());
    return this.state.dataSources;
  }

  @handle(StartAction)
  private async handleStartAction(action: StartAction) {
    this.setState(state => ({
      ...this.defaultState(),
      organizationId: action.context.ContactOrganization.id,
    }));
  }

  @handle(EnsureContactsDataSourceInstanceStoreAction)
  private async handleEnsureContactsDataSourceInstanceStoreAction(action: EnsureContactsDataSourceInstanceStoreAction) {
    await once("ensure-contacts-data-source-instances", async () => {
      this.setState(state => ({
        ...state,
        loadingSources: true,
        loadingInstances: true
      }));
      await this.updateDataSources();
      await this.updateInstances();
    });
  }

  @handle(WebSocketMessageAction, filterWebsocketMessage("EntityChanged"))
  private async handleEntityChangedAction(action: WebSocketMessageAction<EntityChanged>) {
    if (action.data.type === "DataSource") {
      await this.updateDataSources();
    } else if (action.data.type === "DataSourceInstance") {
      await this.updateInstances();
    }
  }

  @handle(LogoutAction)
  private handleLogoutAction(action: LogoutAction) {
    this.setState(s => this.defaultState());
  }

  @handle(AddContactsDataSourceInstanceAction)
  private async handleAddNewDataSourceInstanceAction(action: AddContactsDataSourceInstanceAction) {
    action.instance = await this.dataSourceInstanceRepository.post(action.instance);
    await this.setDataSourceInstancesState([...this.state.dataSourceInstances, action.instance]);
  }

  @handle(UpdateContactsDataSourceInstanceAction)
  private async handleUpdateContactsDataSourceInstanceAction(action: UpdateContactsDataSourceInstanceAction) {
    const existingInstance = this.state.dataSourceInstances.find(m => m.id == action.instance.id);
    if (existingInstance == null) {
      return;
    }

    const operations = compare(existingInstance, action.instance);
    if (operations.length === 0) {
      return;
    }

    action.instance = await this.dataSourceInstanceRepository.patch(action.instance.id, operations);
    Object.assign(existingInstance, action.instance);

    await this.setDataSourceInstancesState(this.state.dataSourceInstances);
  }

  @handle(RemoveContactsDataSourceInstanceAction)
  private async handleRemoveContactsDataSourceInstanceAction(action: RemoveContactsDataSourceInstanceAction) {
    await this.dataSourceInstanceRepository.remove(action.instance);
    await this.setDataSourceInstancesState(remove(this.state.dataSourceInstances, d => d.id !== action.instance.id));
  }

  private async setDataSourceState(dataSources: ContactsDataSource[]) {
    this.setState(state => ({
      ...state,
      dataSources: dataSources,
      loadingSources: false
    }));

    await dispatch(new DataSourcesChangedAction());
  }

  private async setDataSourceInstancesState(instances: ContactsDataSourceInstance[]) {
    this.setState(state => ({
      ...state,
      dataSourceInstances: instances,
      loadingInstances: false
    }));

    await dispatch(new DataSourceInstancesChangedAction());
  }
}
