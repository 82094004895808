export enum CalculatedFieldDataTypeCode {
  Number = 0,
  String = 1,
  Date = 2,
  Boolean = 3,
  File = 4,
  Enumeration = 5,
  Encrypted = 6,
  Json = 7,
  WeightedScore = 8
}

export enum CalculatedFieldOperatorTypeCode {
  Mean = 0,
  Median = 1,
  Sum = 2,
  Count = 3,
}

export { CalculatedField } from "../../../form-runtime/src/models";

export class AggregateCalculatedField {
  Label: string = null;
  Key: string = null;
  //Expression: string;
  FieldPath: string = null;
  AnswerOptionId: string = null;
  Operator = CalculatedFieldOperatorTypeCode.Mean;
  DataType = CalculatedFieldDataTypeCode.Number;
}
export class WeightedScoreSettings {
  Inputs: WeightedScoreFieldInput[] = [];
  IgnoreNullFields: boolean = false;
  MaxInputWeight: number = 5;
  Scale: number = 100;
  validation: { [key: string]: string | null };
  isInvalid: boolean = false;
}
export class WeightedScoreFieldInput {
  FieldKey: string;
  Label: string;
  Weight: number;
}
