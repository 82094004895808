import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ApplicationSettings, PinnedFieldSettings, ApplicationProperty, ApplicationPropertiesUpdateResult } from "models/application-settings";
import { Season } from "models/organization";
import { GridColumn } from 'models/grid-column';

import { ATS } from "./ats";

@inject
export class ApplicationSettingsRepository {
  constructor(private s: ATS) {}

  // this is get season, needs to be moved to it's own repo
  async getSettings(seasonId: string) {
    const res = await this.s.get(`season/${seasonId}`);
    const season = createFrom(Season, res.body);
    return season.ApplicationSettings;
  }

  async put(settings: ApplicationSettings) {
    const res = await this.s.put<ApplicationSettings>(
      `application-settings`,
      settings,
    );
    return createFrom(ApplicationSettings, res.body);
  }

  async putGridColumns(columns: GridColumn[]) {
    const res = await this.s.put<any[]>(
      "application-settings/grid-columns",
      columns,
    );

    return createFromArray(GridColumn, res.body);
  }

  async putPinnedFields(settings: PinnedFieldSettings) {
    const res = await this.s.put(
      "application-settings/pinned-fields",
      settings,
    );

    return createFrom(PinnedFieldSettings, res.body);
  }

  async putApplicationProperties(properties: ApplicationProperty[], version: number) {
    const res = await this.s.put<any[]>(
      "application-settings/application-properties",
      properties, 
      {
        version
      }
    );

    return createFrom(ApplicationPropertiesUpdateResult, res.body);
  }
}
