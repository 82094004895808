import { fromClassArray, fromClass } from "fw-model";

import {
  FormAnswer,
  CalculatedFieldValue,
  ValidationItem,
} from "./application";

export class ApplicationEvaluationMetaData {
  OrganizationUserId: string;
  ApplicationId: string;
  PhaseId: string;
  CollaborationModuleId: string;
  FormId: string;
  FormKey: string;
  FormVersion: number;
  OrganizationId: string;
  SeasonId: string;
  TeamId: string;
  DateUpdatedUtc: string;
  DateUserUpdatedUtc: string;

  SeenQuestionKeys: string[];
  IsValid: boolean;
  @fromClassArray(ValidationItem) ValidationSummary: ValidationItem[];
}

export class ApplicationEvaluation {
  Id: string;

  @fromClassArray(FormAnswer) Answers: FormAnswer[];

  @fromClassArray(CalculatedFieldValue)
  CalculatedValues: CalculatedFieldValue[];

  @fromClass MetaData: ApplicationEvaluationMetaData;
}
