import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS } from "./ats";
import { ReportDefinition, ReportResult } from "shared/report-runtime";

@inject
export class ReportDefinitionRepository {
  constructor(private s: ATS) {}

  public async post(reportDefinition: ReportDefinition, isDashboard = false, dashboardUserId: string = null) {
    const res = await this.s.post("report-definition", reportDefinition, { isDashboard, dashboardUserId });
    return createFrom(ReportDefinition, res.body);
  }

  public async update(reportDefinition: ReportDefinition) {
    const res = await this.s.put(
      `report-definition/${reportDefinition.Id}`,
      reportDefinition,
    );
    return createFrom(ReportDefinition, res.body);
  }

  public async getById(id: string) {
    const res = await this.s.get(`report-definition/${id}`);
    return createFrom(ReportDefinition, res.body);
  }

  public async putCategory(ids: string[], category: string) {
    await this.s.put(`report-definition/${ids.join(",")}/category`, null, {
      category,
    });
  }

  public async del(ids: string[]) {
    await this.s.delete(`report-definition/${ids.join(",")}`);
  }

  public async result(reportDefinitionId: string) {
    const res = await this.s.get(
      `report-definition/${reportDefinitionId}/result`,
    );
    return createFrom(ReportResult, res.body);
  }

  public async share(reportDefinitionId: string, password: string, revoke = false) {
    const res = await this.s.post(`report-definition/${reportDefinitionId}/share`, password, { revoke });
    return createFrom(ReportDefinition, res.body);
  }
}
