import { inject, needs, prop } from "fw";
import { ContactsRelationshipFilter as ContactsRelationshipFilterModel } from "models/contacts-filters";
import { Field } from "views/contacts/components/field";

@inject
@needs(Field)
export class ContactsRelationshipFilter {
  @prop(null) public filter!: ContactsRelationshipFilterModel;
  @prop(() => ({})) validation;
  @prop(false) public editing!: boolean;

  public get modeText() {
    return {
      any: "Any of the following",
      all: "All of the following",
      none: "None of the following"
    };
  }

  public get modes() {
    return [
      { text: this.modeText["any"], value: "any" },
      { text: this.modeText["all"], value: "all" },
      { text: this.modeText["none"], value: "none" }
    ];
  }
}
