import { inject } from "fw";
import { DialogController } from "fw-dialog";

export interface ConfirmDialogArgs {
  text: string;
  okButtonText?: string;
  okButtonClass?: string;
  cancelButtonText?: string;
}

@inject
export class ConfirmDialog {
  private text = "";
  private okButtonText = "Ok";
  private okButtonClass = "";
  private cancelButtonText = "Cancel";
  private closeButtonEl: HTMLElement;

  constructor(private controller: DialogController<void>) { }

  attached() {
    this.closeButtonEl.focus();
  }

  activate(args: ConfirmDialogArgs) {
    const { cancelButtonText, okButtonClass, okButtonText, text } = args;
    this.text = text;
    this.okButtonText = okButtonText || this.okButtonText;
    this.okButtonClass = okButtonClass || this.okButtonClass;
    this.cancelButtonText = cancelButtonText || this.cancelButtonText;
  }

  ok() {
    this.controller.ok(null);
  }

  cancel() {
    this.controller.cancel();
  }
}
