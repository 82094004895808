import { inject, needs, ComponentEventBus, prop } from "fw";
import { dispatch } from "fw-state";

import type { ICustomFieldDefinition } from "models/contact-organization";
import { Contact, ContactMetaData, IContactFieldValueData } from "models/contact";
import { DatasourceImage } from "./datasource-image";
import { allTypes, getControlType } from "./fields";
import { DateService } from "service/date-service";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { ContactsDataSourceInstanceStore, EnsureContactsDataSourceInstanceStoreAction } from "state/contacts-data-source-instance";
import { FieldContext } from "./field";
import { hashOn } from "hashing";

export class ChooseFieldValueResult {
  fieldValue: IContactFieldValueData;
  context: FieldContext;
}

@inject
@needs(...allTypes, DatasourceImage)
export class ChooseFieldValue {
  @prop(null) private contact!: Contact;
  @prop(null) private contactMeta!: ContactMetaData;
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(null) public context!: FieldContext;
  @prop(false) private open!: boolean;

  public contextHash: { [tag: string]: FieldContext } = {};

  constructor(
    private ceb: ComponentEventBus,
    private dateService: DateService,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private contactsDataSourceInstanceStore: ContactsDataSourceInstanceStore,
  ) { }

  public async attached() {
    await dispatch(new EnsureContactsDataSourceInstanceStoreAction());
    this.contextChanged();
  }

  public openChanged() {
    this.contextChanged();
  }

  private contactMetaChanged() {
    this.contextChanged();
  }

  private contextChanged() {
    const values = this.fieldMeta?.values || [];
    this.contextHash = hashOn(
      values,
      v => v.id,
      v => <FieldContext>{ ...this.context, contactMetaValueId: v.id, decryptedValue: null }
    );
  }

  public lastUpdated(v: IContactFieldValueData) {
    const dsi = this.getDataSourceInstance(v.data_source_instance_id);
    const ds = this.getDataSource(v.data_source_key);
    if (ds != null) {
      if (dsi != null) {
        return `${ds.name}: ${dsi.name}`;
      } else {
        return ds.name;
      }
    } else {
      return null;
    }
  }

  public dataSourceDescription(v: IContactFieldValueData) {
    const ds = this.getDataSource(v.data_source_key);
    if (ds != null) {
      return `from ${ds.name} – ${this.dateService.formatDate(v.updated_utc)}`;
    } else {
      return "";
    }
  }

  public getDataSourceInstance(id: string) {
    if (!id) {
      return null;
    }
    return this.contactsDataSourceInstanceStore.state.dataSourceInstances.find(ds => ds.id === id);
  }

  public getDataSource(key: string) {
    if (!key) {
      return null;
    }
    return this.contactsDataSourceInstanceStore.state.dataSources.find(ds => ds.key === key);
  }

  public get orderedValues() {
    const values = this.fieldMeta.values || [];
    let orderedValues = [];

    this.organization.data_source_instance_priorities.forEach(id => {
      const value = values.find(v => v.data_source_instance_id === id);
      if (value) {
        orderedValues.push(value);
      }
    });

    return [...orderedValues, ...values.filter(v => !orderedValues.includes(v))];
  }

  public get organization() {
    return this.currentContactOrganizationStore.state.organization;
  }

  public get testDateService() {
    const d = new Date();
    return this.dateService.formatDate(d);
  }

  public get fieldMeta() {
    if (!this.contactMeta) {
      return null;
    }

    if (this.field.is_system_field) {
      return this.contactMeta.fields.find(f => f.field_name == this.field.name);
    } else {
      // TODO: Why don't the ids match for system fields?
      return this.contactMeta.fields.find(f => f.field_id == this.field.id);
    }
  }

  public get contactType(): string {
    return this.field && this.field.data && this.field.data.contact_type;
  }

  public chooseValue(v: IContactFieldValueData) {
    // NOTE: if this is a concealed field, it may not be decrypted.
    this.ceb.dispatch("value-selected", <ChooseFieldValueResult>{
      context: this.contextHash[v.id],
      fieldValue: v
    });
  }

  public get controlType(): string {
    return getControlType(this.field?.type);
  }
}
