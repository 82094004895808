import { inject } from "fw";
import { nameof } from "helpers/nameof";
import { Contact, ContactMetaData, IContactFieldData, IContactFieldValueData, UnrestrictedContact } from "models/contact";
import { GridColumn } from "models/grid-column";
import type { ICustomFieldDefinition } from "models/contact-organization";

@inject
export class ContactsService {
  // NOTE: do not add any state stores to this service
  constructor() { }

  public get contactEmailPath() {
    return nameof<Contact | UnrestrictedContact>("email_address");
  }

  public getEmailAddress(contact: Contact | UnrestrictedContact) {
    if (!contact) return null;

    return contact[this.contactEmailPath];
  }

  get emailPathAndLabel() {
    return {
      Label: "Email",
      Path: nameof<Contact>("email_address"),
      Sort: "email"
    };
  }

  get emailToColumn() {
    return {
      name: "emailaddress",
      displayName: "Email Address",
      path: "contact.email_address",
      isIdentifier: true
    };
  }

  get defaultGridColumns(): GridColumn[] {
    return [
      { Label: "Name", Path: nameof<Contact>("displayName"), Sort: "name" },
      this.emailPathAndLabel
    ];
  }

  public isFirstClass(path: string) {
    switch (path) {
      case nameof<Contact>("displayName"):
      case nameof<Contact>("display_name"):
      case nameof<Contact>("first_name"):
      case nameof<Contact>("last_name"):
      case nameof<Contact>("company_name"):
      case nameof<Contact>("tags"):
      case nameof<Contact>("type"):
      case nameof<Contact>("default_email_address"):
      case nameof<Contact>("email_address"):
      case nameof<Contact>("engagement_score"):
      case nameof<Contact>("contact_number"):
        return true;
    }
    return false;
  }

  public isManuallySelected(contact: Contact, contactMeta: ContactMetaData, field: ICustomFieldDefinition): boolean {
    const metaField = contactMeta.fields.find(f => f.field_id === field.id);
    if (!metaField || metaField.is_deleted) {
      return false;
    }

    const manuallySelectedValue = this.getManuallySelectedValue(metaField);
    if (!manuallySelectedValue) {
      return false;
    }

    const isRoutingTableValue: boolean = manuallySelectedValue.identifier?.startsWith("rt_") || false;
    return !isRoutingTableValue;
  }

  private getManuallySelectedValue(metaField: IContactFieldData): IContactFieldValueData {
    const values = metaField.values.filter(v => !v.is_deleted
      && (v.is_manually_selected || v.data_source_key == "system")
      && (v.resolved_value != null && v.resolved_value.is_valid)
    );

    let manuallySelectedValue: IContactFieldValueData = null;
    let manualSelectedValues = values.filter(v => v.is_manually_selected);

    // If there is only single manual selected value use it!
    if (manualSelectedValues.length == 1) {
      manuallySelectedValue = manualSelectedValues[0];
    } else if (manuallySelectedValue == null && manualSelectedValues.length > 1) {
      // Prefer user manual values over routing table values.
      manuallySelectedValue = manualSelectedValues.find(v => v.identifier == null || !v.identifier.startsWith("rt_"));
    }

    // Prefer user manual values over routing table values.
    if (manuallySelectedValue == null) {
      manuallySelectedValue = values.find(v => v.identifier == null || !v.identifier.startsWith("rt_")) || (values && values[0]);
    }

    return manuallySelectedValue;
  }

  public getLocationAddress(contact: Contact): string {
    if (!contact.geo_set?.length) {
      return '';
    }

    return contact.geo_set[0]?.full_address;
  }

  public getLocationLink(contact: Contact): string {
    if(!contact.geo_set?.length) {
      return '#';
    }

    const geoSet = contact.geo_set[0];
    const logitude = geoSet?.longitude;
    const latitude = geoSet?.latitude;
    return logitude && latitude
      ? `http://maps.google.com?q=${latitude},${logitude}`: '#';
  }
}
