import { inject, prop } from "fw";
import { dispatch } from "fw-state";

import { UsersStore, EnsureUsersAction } from "state/users";
import { TaskAssignedUserFilter as TaskAssignedUserFilterModel } from "models/task-filters";
import { PopoverService } from "service/popover";
import { SelectUserPopover } from "views/components/select-user-popover";

@inject
export class TaskAssignedUserFilter {
  @prop(null)
  filter: TaskAssignedUserFilterModel;

  @prop(() => ({}))
  validation;

  @prop(false)
  editing: boolean;

  constructor(
    private popover: PopoverService,
    private usersStore: UsersStore
  ) { }


  attached() {
    dispatch(new EnsureUsersAction([this.filter.userId]));
  }

  get selectedUser() {
    if (this.filter == null || this.filter.userId == null) return "Choose User";

    const u = this.usersStore.state.userHash[this.filter.userId];
    if (u == null) return "Loading...";

    return `${u.FirstName} ${u.LastName}`;
  }

  async selectUser() {
    const res = await this.popover.open<string>(SelectUserPopover);
    if (res.canceled) return;

    this.filter.userId = res.result;
    dispatch(new EnsureUsersAction([res.result]));
  }
}
