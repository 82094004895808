import { inject, needs } from "fw";
import { FeatureFlagService } from "service/feature-flag";

import { PopoverController } from "service/popover";
import { ApplicationClientModelCompleter } from "helpers/completers/application-client-model";
import { CodeEditor } from "views/components/code-editor";
import { isValidJavascript } from "forms/validators";
import { DataDictionaryField } from "models/data-dictionary";
import { ConditionInfoFormType } from "forms/condition-info";
import { OpenConditionBuilderDialog } from "./open-condition-builder-dialog";
import { FunctionClientModelCompleter } from "helpers/completers/function-client-model";
import { Completer } from "helpers/auto-complete";

export interface ColumnSpanGroupCommand {
  command: "delete" | "colspan" | "condition";
  data?: any;
}

export interface IColumnSpanGroupOptions {
  columnSpan: number;
  showResize: boolean;
  showCondition?: boolean;
  condition?: string;
  conditionInfoForm?: ConditionInfoFormType;
  fields?: DataDictionaryField[];
}

@needs(CodeEditor, OpenConditionBuilderDialog)
@inject
export class ColumnSpanGroupOptions {
  public colSpan: number = null;
  public showResize = false;
  public showCondition: boolean = false;
  public applyCondition: boolean = false;
  public condition: string = null;
  public conditionInfoForm: ConditionInfoFormType = null;
  public fields: DataDictionaryField[] = [];
  public validation: string = null;

  constructor(
    private controller: PopoverController<ColumnSpanGroupCommand>,
    private ffs: FeatureFlagService,
    public appCompleter: ApplicationClientModelCompleter,
    private fnCompleter: FunctionClientModelCompleter
  ) { }

  public activate(params: IColumnSpanGroupOptions) {
    this.colSpan = params.columnSpan;
    this.showResize = params.showResize;
    this.condition = params.condition;
    this.conditionInfoForm = params.conditionInfoForm;
    this.fields = params.fields;
    this.showCondition = params.showCondition;
    this.applyCondition = params.condition != null;
  }

  public get completer(): Completer[] {
    return [this.appCompleter, this.fnCompleter];
  }  

  public conditionBuilderDialogOpened() {
    this.controller.cancel();
  }

  public remove() {
    this.controller.ok({ command: "delete" });
  }

  public setColSpan(newSpan: number) {
    this.controller.ok({
      command: "colspan",
      data: newSpan,
    });
  }
}
