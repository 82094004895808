import { Validators, FormForType, formFor, Validator } from "fw-model";
const { required, isInteger, inRange, wrap } = Validators;

import {
  ExportDefinition,
  JsonExportFormatOptions,
  TabularExportFormatOptions,
  ExportFieldTypeCode,
  CSVExportField,
  PdfExportFormatOptions,
  JsonEvaluationModeTypeCode,
  FileExportFormatOptions,
  FileExportIndexOptions,
  FileExportPathNamePair,
  JsonExportField,
  CsvPivotField,
  CsvPivotRow,
  ExportDefinitionMetaData
} from "models/export-definition";
import { isValidJavascript } from "./validators";

export type ExportDefinitionFormType = FormForType<ExportDefinition>;
export type CSVExportFieldFormType = FormForType<CSVExportField>;
export type CsvPivotFieldFormType = FormForType<CsvPivotField>;
export type JSONExportFieldFormType = FormForType<JsonExportField>;

export class UpdateExportDefinitionAction {
  constructor(public form: ExportDefinitionFormType, public newOwnerId: string = null) { }
}

export class AddExportDefinitionAction {
  public addedId: string = null;

  constructor(public form: ExportDefinitionFormType, public ownerId: string = null) { }
}

export class DeleteExportDefinitionsAction {
  constructor(public exportDefinitionIds: string[]) { }
}

export type FileExportIndexFormType = FormForType<FileExportIndexOptions>;


export const jsonExportFieldFormCreator = formFor(JsonExportField, s => {
  s.requiredField(o => o.PropertyName, "Key");
  s.field(o => o.Path, "Path");
  s.field(
    o => o.Expression,
    "Expression",
    v => v.if(o => o.Type == ExportFieldTypeCode.Custom, required, isValidJavascript)
  );
  s.field(o => o.Type, "Type");
  s.field(o => o.DataType, "Data Type");
  s.field(o => o.FormatType, "Format Type");
  s.field(o => o.Format, "Format");
  s.field(o => o.ExportCodeId, "Export Code");
  s.field(o => o.TranslationTableId, "Translation Table");
});

const jsonExportFormatOptionsFormCreator = formFor(
  JsonExportFormatOptions,
  s => {
    s.field(j => j.BatchSize, "Batch Size", wrap(isInteger));
    s.field(
      j => j.TransformFunction,
      "Transform Function",
      v => v.if(j => j.EvaluationMode == JsonEvaluationModeTypeCode.Transform, required),
    );
    s.field(j => j.EvaluationMode, "Mode");
    s.formArray(j => j.Fields, "Fields", jsonExportFieldFormCreator);
  },
);

export const pdfExportFormatOptionsFormCreator = formFor(
  PdfExportFormatOptions,
  s => {
    s.field(j => j.CustomizeContent, "Custom Content");
    s.field(j => j.CreateCoverPage, "Create Cover Page");
    s.field(j => j.CreateSummaryPage, "Create Summary Page");
    s.field(j => j.DocumentStepKeys, "Include Documents");
    s.field(j => j.EvaluationPhaseKeys, "Include Reviews");
    s.field(j => j.FormStepKeys, "Include Forms");
    s.field(j => j.IncludeAttachments, "Include Attachments");
    s.field(j => j.IncludeComments, "Include Comments");
    s.field(j => j.PortfolioStepKeys, "Include Portfolios");
    s.field(j => j.CollaborationModuleKeys, "Include Collaborations");
    s.field(j => j.ReferenceStepKeys, "Include References");
    s.field(j => j.IncludedDocumentPaths, "Included Document Paths");
    s.field(j => j.IncludedReferencePaths, "Included Reference Paths");
    s.field(j => j.IncludedFormPaths, "Included Form Paths");
    s.field(j => j.IncludedEvaluationPhasePaths, "Included Phase Paths");
    s.field(j => j.IncludedPortfolioPaths, "Included Portfolio Paths");
  },
);

export const csvExportFieldRequiredExpressionFormCreator = formFor(CSVExportField, s => {
  s.field(o => o.Label, "Label");
  s.requiredField(o => o.Expression, "Expression", wrap(isValidJavascript));
});

export const csvExportFieldPivotFilterExpressionFormCreator = formFor(CSVExportField, s => {
  s.field(o => o.PivotFilterExpression, "Expression", wrap(isValidJavascript));
});

export const csvPivotFieldFormCreator = formFor(CsvPivotField, s => {
  s.field(o => o.Type, "Type");
  s.field(o => o.DataType, "Data Type");
  s.field(o => o.Path, "Path");
  s.field(o => o.Label, "Label");
  s.field(
    o => o.Expression,
    "Expression",
    v => v.if(o => o.Type == ExportFieldTypeCode.Custom, required, isValidJavascript),
  );
  s.field(o => o.Width, "Width", wrap(isInteger));
  s.field(o => o.TranslationTableId, "Translation Table");
  s.field(o => o.FormatType, "Format Type");
  s.field(o => o.Format, "Format");
});

export const csvPivotRowFormCreator = formFor(CsvPivotRow, s => {
  s.field(o => o.Label, "Label");
  s.requiredField(o => o.Path, "Path");
});

export const csvExportFieldFormCreator = formFor(CSVExportField, s => {
  s.field(o => o.Type, "Type");
  s.field(o => o.DataType, "Data Type");
  s.field(o => o.FormatType, "Format Type");
  s.field(o => o.Format, "Format");
  s.field(o => o.Path, "Path");
  s.field(o => o.Label, "Label");
  s.field(
    o => o.Expression,
    "Expression",
    v => v.if(o => o.Type == ExportFieldTypeCode.Custom, required, isValidJavascript),
  );
  s.field(o => o.Width, "Width", wrap(isInteger));
  s.field(o => o.ExportCodeId, "Export Code");
  s.field(o => o.TranslationTableId, "Translation Table");
  s.field(o => o.PivotRowCount,
    "Pivot Row Count",
    v => v.if(o => o.Type == ExportFieldTypeCode.Pivot && o.PivotRows.length == 0, required, isInteger, inRange(1, null)));
  s.field(o => o.PivotRowSort, "Pivot Row Sort");
  s.field(o => o.PivotFields,
    "Pivot Columns",
    v => v.if(o => o.Type == ExportFieldTypeCode.Pivot, required));
  s.field(o => o.PivotFilterExpression, "Expression", wrap(isValidJavascript));
  s.formArray(o => o.PivotFields, "Pivot Fields", csvPivotFieldFormCreator);
  s.formArray(o => o.PivotRows, "Pivot Rows", csvPivotRowFormCreator);
});

const tabularExportFormatOptionsFormCreator = formFor(
  TabularExportFormatOptions,
  s => {
    s.field(o => o.FileRecordCount, "File Record Count", wrap(isInteger));
    s.field(o => o.Style, "Column Style");
    s.field(o=>o.CustomDelimiter, "Custom Delimiter");
    s.formArray(o => o.Fields, "Fields", csvExportFieldFormCreator);
  },
);

export const fileExportPathPairFormCreator = formFor(FileExportPathNamePair, s => {
  s.field(o => o.Path, "Path");
  s.field(o => o.FileNameFormat, "File Name Format");
});


export const fileExportFormatOptionsFormCreator = formFor(
  FileExportFormatOptions,
  s => {
    s.field(o => o.FileApplicationCount, "File Application Count", wrap(isInteger));
    s.formArray(o => o.FilePaths, "File Paths", fileExportPathPairFormCreator);
    s.form(o => o.IndexOptions, "Index Options", fileExportIndexFormCreator);
  },
);

export const fileExportIndexFormCreator = formFor(FileExportIndexOptions, s => {
  s.field(u => u.Style, "Column Style");
  s.field(o=> o.CustomDelimiter, "Custom Delimiter");
  s.formArray(o => o.Fields, "Fields", csvExportFieldFormCreator);
});

export const exportDefinitionFormCreator = formFor(ExportDefinition, s => {
  s.requiredField(d => d.Name, "Name");
  s.requiredField(d => d.DataType, "Data Type");
  s.requiredField(d => d.Format, "Format");

  s.field(d => d.ContactType, "Contact Type");
  s.field(d => d.IsShared, "Shared Export");
  s.field(d => d.FileNameFormat, "File Name Format");
  s.field(d => d.Visibility, "Visibility");
  s.form(
    d => d.JsonOptions,
    "JSON Options",
    jsonExportFormatOptionsFormCreator,
  );
  s.form(d => d.PdfOptions, "PDF Options", pdfExportFormatOptionsFormCreator);
  s.form(
    d => d.TabularOptions,
    "Tabular Options",
    tabularExportFormatOptionsFormCreator,
  );
  s.form(d => d.FileOptions, "File Options", fileExportFormatOptionsFormCreator);
});
