import { fromClass } from "fw-model";

export enum FunctionType {
  default = "default",
  translate = "translate"
}

export class ParsedFunction {
  Name: string;
  Parameters: string[] = [];
}

export class ParsedContents {
  @fromClass ParsedFunctions: ParsedFunction[] = [];
}

export class FunctionMetaData {
  OrganizationId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  IsManaged: boolean = false;
  IncludeByDefault: boolean = false;
  @fromClass ParsedContents: ParsedContents;
}

export class Function {
  Id: string;
  Name: string = null;
  Contents: string = null;
  IncludeFunctionIds: string[] = [];
  IsGlobal: boolean = false;
  @fromClass MetaData: FunctionMetaData;
}
