import { prop, inject, ComponentEventBus } from "fw";

@inject
export class Information {
  @prop(null) text: string;
  @prop(false) showClose: boolean;

  constructor(private ceb: ComponentEventBus) {}

  public close() {
    this.ceb.dispatch("close", null);
  }
}
