import { inject, prop, needs, ComponentEventBus, provided } from "@derekpitt/fw"

import {
  type WidgetData,
  type WidgetContext,
  WidgetDefinition,
  ReportChartType,
  DataClickEvent,
  isReportChartData,
  ChartDataSource
} from "../models";

import { getComponent, widgetComponents } from "./charts";
import moment from "moment";

@inject
@needs(...widgetComponents)
export class WidgetRenderer {
  @prop(false) public interactive: boolean;
  @prop(null) public data: WidgetData;
  @prop(false) public dataLoading: boolean;
  @prop(1) public columnSpan: number;
  @prop(null) public clientData: string;


  @provided(undefined)
  public widgetContext: WidgetContext;

  constructor(private ceb: ComponentEventBus) {}

  public get chartComponent() {
    if (this.data == null) return null;
    return getComponent(this.data.Definition);
  }

  public get dataSourceLabel() {
    if (this.data == null) return null;

    switch(this.data.Definition.DataSource) {
      case ChartDataSource.Admissions:
        return "Applications";
      case ChartDataSource.Contacts:
        return "Contacts";
      case ChartDataSource.WeightScoreSettings:
        return "Weights (% out of 100%)";

      default:
        return null;
    }
  }

  public get hideCount() {
    return this.data.Definition.DataSource === ChartDataSource.WeightScoreSettings;
  }

  public get svgAlt() {
    return `${ReportChartType[this.data.Definition.ChartType]} chart of ${this.data.Definition.Name}`;
  }

  public get dataForComponent() {
    if (this.data == null) return null;
    if (isReportChartData(this.data)) {

      // progress is not a widget... but we want to treat it like that
      if (this.data.Definition.ChartType == ReportChartType.Progress)
        return this.data;

      return this.ensureDataType(this.data.Data);
    }

    return this.ensureDataType(this.data);
  }

  public dataClick(data) {
    const event: DataClickEvent = { WidgetData: this.data, Data: data };
    this.ceb.dispatch("data-click", event);
  }

  private ensureDataType(data) {
    try {
      if (Array.isArray(data)) {
        return data.map(item => {
          const momentObject = moment(item.Label, moment.ISO_8601, true);
          if (momentObject.isValid() && momentObject["_f"] !== "YYYY")
            item.Label = moment(item.Label).format("YYYY-MM-DD");
          if (item.Label.includes('1970-01-01')) item.Label = '__missing__';
          return item;
        });
      } else {
        return data;
      }
    } catch (e) {
      console.log(e);
      return data;
    }
  }
}
