import { inject } from "fw";

@inject
export class PageToolbar {
  public limitPosition = 50;
  public scrolled = false;

  public handleScroll() {
    this.scrolled = this.limitPosition < window.scrollY;
  }

  public attached() {
    window.addEventListener("scroll", this.handleScroll);
  }

  public detached() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
