import { prop, ComponentEventBus, inject } from "fw";
import { Form } from "fw-model";

@inject
export class SwitchFormField {
  @prop(null) private form!: Form;
  @prop(null) private field!: string;
  @prop(false) private disabled;

  private name: string = null;

  constructor(private ceb: ComponentEventBus) {}

  public attached() {
    this.formChanged();
  }

  private formChanged() {
    this.name = this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;
  }

  private onChange() { this.ceb.dispatch("change"); }
}
