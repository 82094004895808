export class AllowCheckRouting {
    private static instance:AllowCheckRouting = new AllowCheckRouting();
    private isAllowToCheck: boolean = true;

    constructor() {
        if(AllowCheckRouting.instance) {
            throw new Error("Error: Instantiation failed: Use AllowCheckRouting.getInstance() instead of new.");
        }
        AllowCheckRouting.instance = this;
    }
    public static getInstance():AllowCheckRouting {
        return AllowCheckRouting.instance;
    }
    public get isAllow() {
        return this.isAllowToCheck;
    }
    public disable() {
        this.isAllowToCheck = false;
    }
    public enable() {
        this.isAllowToCheck = true;
    }
}