import { needs, inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer } from "../../models";
import { QuestionScaleMode } from "../../enums";
import { Icon } from "../icon"

@needs(Icon)
@inject
export class ScaleType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(null) ariaLabelledby;

  private options: { label: string; value: number }[]  = [];
  private endPointStart = null;
  private endPointEnd = null;

  constructor(private ecb: ComponentEventBus) { }

  attached() {
    const options = this.question.Options.Scale;
    switch (options.Mode) {
      case QuestionScaleMode.Range:
        this.endPointStart = options.Values[0].Label;
        this.endPointEnd = options.Values[1].Label;

        this.options = [];
        for (let i = options.Values[0].Value; i <= options.Values[1].Value; i++) {
          this.options.push({ label: i.toString(), value: i });
        }

        break;

      case QuestionScaleMode.Labels:
        this.options = [];
        this.options = options.Values.map(v => ({ label: v.Label, value: v.Value }));
        break;
    }
  }

  change() {
    this.answer.Text = this.answer.Number != null ? `${this.answer.Number}` : null;

    this.ecb.dispatch("answer-changed");
  }

  clearAnswer() {
    this.answer.Number = null;
    this.change();
  }
}
