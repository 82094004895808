import { prop, inject, ComponentEventBus } from "fw";

@inject
export class MultiselectPill {
  @prop(undefined) public value!: string;
  @prop(false) showRemove!: boolean;

  constructor(private ceb: ComponentEventBus) {}

  remove() {
    this.ceb.dispatch("remove", this.value);
  }
}
