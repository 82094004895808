import { inject } from "fw";
import { groupBy, uniq, some } from "lodash-es";

import { DataDictionaryAggregationType, DataDictionaryField, DataDictionaryFieldCategory, DataDictionaryIndexStatus } from "models/data-dictionary";
import { CategoryDataDictionaryFields } from "views/components/data-dictionary-field-selector-popover";
import { DataDictionaryService } from "./data-dictionary";
import { DataPolicyService } from "service/data-policy";
import { ChartDataSource, ReportChartDefinition, ReportChartDefinitionClientData, ReportDefinition, ReportResult, WidgetDefinition } from "shared/report-runtime";
import { createFrom } from "fw-model";
import { GroupFilter } from "models/filter-setup";
import { ApplicationRepository } from "network/application-repository";
import { FeatureFlagService } from "./feature-flag";

@inject
export class ReportService {
  constructor(
    private applicationRepo: ApplicationRepository,
    private dataDictionaryService: DataDictionaryService,
    private dataPolicy: DataPolicyService,
    private ffs: FeatureFlagService
  ) {}

  public async getChartableField(path: string): Promise<DataDictionaryField> {
    const restrictions = this.dataPolicy.applicationPropertyPaths;
    const fields = await this.dataDictionaryService.getAggregateFields(true);
    for (const field of fields) {
      if (field.IndexStatus === DataDictionaryIndexStatus.Unavailable) {
        continue;
      }

      if (field.Category === DataDictionaryFieldCategory.Application && restrictions.includes(field.Path)) {
        continue;
      }

      switch (field.AggregationType) {
        case DataDictionaryAggregationType.Histogram:
        case DataDictionaryAggregationType.Terms:
          break;
        case DataDictionaryAggregationType.None:
        default:
          continue;
      }

      if (field.SearchPath === path || field.Path === path)
        return field;
    }

    return null;
  }

  public async getChartableFieldCategories(onlyShowIndexed: boolean = false): Promise<CategoryDataDictionaryFields[]> {
    const cats: CategoryDataDictionaryFields[] = [];
    const restrictions = this.dataPolicy.applicationPropertyPaths;

    const fields = await this.dataDictionaryService.getAggregateFields(false);
    const groupedFieldsByCategory = groupBy(fields, f => f.Category);
    for (const category in groupedFieldsByCategory) {
      const cat: CategoryDataDictionaryFields = { category: category, fields: [] };

      for (const field of groupedFieldsByCategory[category]) {
        if (onlyShowIndexed && field.IndexStatus !== DataDictionaryIndexStatus.Indexed) {
          continue;
        }

        if (field.IndexStatus === DataDictionaryIndexStatus.Unavailable) {
          continue;
        }

        if (category === DataDictionaryFieldCategory.Application && restrictions.includes(field.Path)) {
          continue;
        }

        switch (field.AggregationType) {
          case DataDictionaryAggregationType.Histogram:
          case DataDictionaryAggregationType.Terms:
            break;
          case DataDictionaryAggregationType.None:
          default:
            continue;
        }

        cat.fields.push(field);
      }

      if (cat.fields.length > 0) {
        cats.push(cat);
      }
    }

    return cats;
  }

  public async indexNotIndexedFields(report: ReportDefinition): Promise<string> {
    const fieldsToIndex = await this.getFieldsToIndex(report);
    if (fieldsToIndex.length) {
      const taskRequestId = await this.applicationRepo.indexFields(fieldsToIndex);
      return taskRequestId;
    }
    return null;
  }

  public async getFieldsToIndex(report: ReportDefinition) {
    const terms = this.getAdmissionsChartTerms(report);
    if (terms.length === 0) {
      return [];
    }

    const fields = await this.dataDictionaryService.getFields();
    const termsToIndex = terms.filter(term => fields.find(f => f.SearchPath === term || f.Path === term)?.IndexStatus === DataDictionaryIndexStatus.NotIndexed);
    return termsToIndex.length > 0 && !this.ffs.isFeatureFlagEnabled("TestDisableAutoFieldIndexing")
     ? termsToIndex
     : [];
  }

  public async getIndexingFields(report: ReportDefinition): Promise<DataDictionaryField[]> {
    const termsSet = new Set<string>(this.getAdmissionsChartTerms(report));
    if (termsSet.size === 0) {
      return [];
    }

    const fields = await this.dataDictionaryService.getFields();
    return fields.filter(x => x.IndexStatus === DataDictionaryIndexStatus.Indexing && (termsSet.has(x.Path) || termsSet.has(x.SearchPath)));
  }

  public hasPendingIndex(reportResult: ReportResult) {
    return some(reportResult.FieldGroups, fg => some(fg.List, l => l.UnavailablePendingIndex));
  }

  private getAdmissionsChartTerms(report: ReportDefinition): string[] {
    const terms: string[] = [];
    const widgets = report.FieldGroups.reduce((definitions, fg) => definitions.concat(fg.List), <WidgetDefinition[]>[]).concat(report.Widgets);
    for (const widget of widgets) {
      if (widget.WidgetType === "chart") {
        const chartWidget = <ReportChartDefinition>widget;
        if (chartWidget.DataSource !== ChartDataSource.Admissions) {
          continue;
        }

        if (chartWidget.PrimaryIndependentVariable?.Path) {
          terms.push(chartWidget.PrimaryIndependentVariable?.Path);
        }

        if (chartWidget.SecondaryIndependentVariable?.Path) {
          terms.push(chartWidget.SecondaryIndependentVariable?.Path);
        }
      }

      if (widget.ChartFilter && widget.ClientData) {
        const clientData: ReportChartDefinitionClientData = JSON.parse(widget.ClientData);
        if (clientData.filterContainer) {
          const group: GroupFilter = createFrom(GroupFilter, clientData.filterContainer);
          terms.push(...group.toFilterTerms().map(t => t.term));
        }
      }
    }

    return uniq(terms);
  }
}
