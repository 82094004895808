import { FormForType, formFor, Validators } from "fw-model";

import { User, UserMembership, PostUserArgs, AddressInfo } from "models/user";
import { UserSeasonNotificationSettings, UserSeasonEmailNotificationSettings } from "models/user-season-settings";

const { isEmail } = Validators;

export type UpdateUserFormType = FormForType<User>;
export type UpdateUserMembershipFormType = FormForType<UserMembership>;
export type PostUserArgsFormType = FormForType<PostUserArgs>;

export type UserNotificationSettingsFormType = FormForType<UserSeasonNotificationSettings>;
export class UpdateUserNotificationSettingsAction {
  constructor(public userSeasonNotificationSettings: UserSeasonNotificationSettings) { }
}

export const UserSeasonEmailNotificationSettingsFormCreator = formFor(UserSeasonEmailNotificationSettings, s => {
  s.requiredField(u => u.Enabled, "Enabled");
  s.requiredField(u => u.Digest, "Digest");
});

export const UserSeasonNotificationSettingsFormCreator = formFor(UserSeasonNotificationSettings, s => {
  s.form(p => p.Email, "Email", UserSeasonEmailNotificationSettingsFormCreator);
});

export class UpdateUserAction {
  constructor(public form: UpdateUserFormType) { }
}

export class AddUserAction {
  constructor(public form: PostUserArgsFormType, public callback?: Function) { }
}

// for new one
export class UpdateUserInSeasonAction {
  constructor(public id: string, public seasonId: string, public form: PostUserArgsFormType) { }
}

export class UpdateUserMembershipAction {
  constructor(
    public form: UpdateUserMembershipFormType,
    public userId: string,
    public isCollabRoleUpdate = false,
  ) { }
}

export class UpdateUserCollaborationModulesAction {
  constructor(
    public userId: string,
    public organizationId: string,
    public collaboratorRoleIds: string[],
  ) { }
}
export const addressInfoFormCreator = formFor(AddressInfo, a => {
  a.field(f => f.Address1, "Address1");
  a.field(f => f.Address2, "Address2");
  a.field(f => f.City, "City");
  a.field(f => f.Region, "Region");
  a.field(f => f.PostalCode, "PostalCode");
  a.field(f => f.Country, "Country");
});

// Don't need this quite yet, just hard-coded to PreferredCEEBCodes
// export const applicationMatchingPropertiesFormCreator = formFor(ApplicationMatchingProperty, s => {
//   s.requiredField(u => u.Key, "Key");
//   s.requiredField(u => u.Values, "Values");
// });

export const userMembershipFormCreator = formFor(UserMembership, s => {
  s.requiredField(u => u.RoleId, "Role");
  s.field(u => u.MaxAssignmentsPerModule, "Max Assignments Per Module");
  s.requiredField(u => u.IsAvailableForAssignment, "Auto Assignment");
  s.field(u => u.ApplicationMatchingProperties, "Application Matching Properties");
  s.field(u => u.OptOutAssignmentSummary, "Opt Out Assignment Summary");
  // s.formArray(u => u.ApplicationMatchingProperties, "Application Matching Properties", applicationMatchingPropertiesFormCreator);
});

export const updateUserFormCreator = formFor(User, s => {
  s.requiredField(u => u.FirstName, "First Name");
  s.requiredField(u => u.LastName, "Last Name");
  s.field(u => u.AvatarFileId, "Avatar");
  s.field(u => u.Title, "Title");
  s.field(u => u.Department, "Department");
  s.field(u => u.Bio, "Bio");
  s.field(u => u.OfficePhone, "OfficePhone");
  s.field(u => u.MobilePhone, "MobilePhone");
  s.form(f => f.Address, "Address", addressInfoFormCreator);
  s.formArray(u => u.Memberships, "Memberships", userMembershipFormCreator);
});

// this is for the new Feature Flag: UsersBeforeInvites
export const userFormCreator = formFor(User, s => {
  s.requiredField(u => u.EmailAddress, "Email Address", o => o.use(isEmail));
  s.requiredField(u => u.FirstName, "First Name");
  s.requiredField(u => u.LastName, "Last Name");
  s.field(u => u.AvatarFileId, "Avatar");
  s.field(u => u.Title, "Title");
  s.field(u => u.Department, "Department");
  s.field(u => u.Bio, "Bio");
  s.field(u => u.OfficePhone, "OfficePhone");
  s.field(u => u.MobilePhone, "MobilePhone");
  s.form(f => f.Address, "Address", addressInfoFormCreator);
});

export const postUserArgsFormCreator = formFor(PostUserArgs, s => {
  s.requiredField(u => u.EmailAddress, "Email Address", o => o.use(isEmail));
  s.requiredField(u => u.FirstName, "First Name");
  s.requiredField(u => u.LastName, "Last Name");
  s.field(u => u.Title, "Title");
  s.field(u => u.Department, "Department");
  s.field(u => u.Bio, "Bio");
  s.field(u => u.OfficePhone, "OfficePhone");
  s.field(u => u.MobilePhone, "MobilePhone");
  s.field(u => u.RoleId, "Role");
  s.field(u => u.OptOutAssignmentSummary, "Opt Out Assignment Notifications");
  s.field(u => u.CollaboratorTeamIds, "Collaborator Teams");
  s.field(u => u.EvaluationTeamIds, "Review Teams");
  s.field(u => u.InvitePortalIds, "Invite Portals");
  s.form(f => f.Address, "Address", addressInfoFormCreator);
});
