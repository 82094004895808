import { fromClass, fromClassArray } from "fw-model";
import { FormAnswer, ValidationItem } from "./application";

export class ApplicationReferenceMetaData {
  FormId: string;
  FormKey: string;
  FormVersion: number;
  OrganizationId: string;
  SeenQuestionKeys: string[];
  IsValid: boolean;

  @fromClassArray(ValidationItem) ValidationSummary: ValidationItem[];
}

export class ApplicationReference {
  Id: string;
  Name: string;
  EmailAddress: string;

  @fromClass MetaData: ApplicationReferenceMetaData;
  @fromClassArray(FormAnswer) Answers: FormAnswer[];
}
