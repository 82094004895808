import { bootstrap, FrameworkConfig, ContainerInstance } from "fw";
import { dispatch } from "fw-state";

import * as vueBreakpoints from "vue-breakpoints";
import { AtsConfig } from "config/ats-config";
import { App } from "app";

import { registerComponents } from "bootstrap-components";
import { configureStores } from "bootstrap-stores";

import { ATS } from "network/ats";
import { AppRepository } from "network/app-repository";
import { ContactOrganizationRepository } from "network/contact-organization-repository";

import { StartAction } from "state/actions";
import { registerApplicationFilters } from "models/application-filters";
import { registerContactsFilters } from "models/contacts-filters";
import { registerActivityFilters } from "models/activity-filters";
import { registerTaskFilters } from "models/task-filters";
import { registerCalendarEventFilters } from "models/calendar-event-filters";
import { OAuth } from "service/oauth-2";

import vuedraggable from "vuedraggable";
import inView from "plugins/in-view";
import scrollTo from "plugins/scroll-to";
import PortalVue from "portal-vue";
import DateRangePicker from "vue2-daterange-picker";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueSlider from "vue-slider-component";
import { Chrome as ChromeColorPicker } from "vue-color";

import Filters from "filters/filters";
import { parseJwt } from "helpers/parse-jwt";
import { seedOrganizationPortal } from "helpers/organization-portal-context";

import "scss/all.scss";

bootstrap(async (c: FrameworkConfig) => {
  registerApplicationFilters();
  registerContactsFilters();
  registerActivityFilters();
  registerTaskFilters();
  registerCalendarEventFilters();

  c.useVuePlugin((v) => {
    v.component("hide-at", vueBreakpoints.hideAt);
    v.component("show-at", vueBreakpoints.showAt);
  });
  c.useVuePlugin(inView);
  c.useVuePlugin(scrollTo);
  c.useVuePlugin(PortalVue as any);
  c.useVuePlugin((v) => v.component("swiper", Swiper));
  c.useVuePlugin((v) => v.component("vue-qrcode", VueQrcode));
  c.useVuePlugin((v) => v.component("vue-slider", VueSlider));
  c.useVuePlugin((v) => v.component("ChromeColorPicker", ChromeColorPicker));
  c.useVuePlugin((v) => v.component("swiper-slide", SwiperSlide));
  c.useVuePlugin((v) => v.component("DateRangePicker", DateRangePicker));
  c.useVuePlugin((v) => v.component("draggable", vuedraggable));
  c.useVuePlugin((v) =>
    v.directive("focus", {
      inserted: (el) => setTimeout(() => el.focus(), 1),
    })
  );
  c.useVuePlugin(Filters);

  const atsConfig = await c.withConfig(AtsConfig, process.env.ATS_CONFIG_FILE || "/ats-config.json");

  if (localStorage) {
    if (localStorage["apiUrl"]) atsConfig.apiUrl = localStorage["apiUrl"];

    if (localStorage["contactsApiUrl"]) {
      atsConfig.contactsApiUrl = localStorage["contactsApiUrl"];
      atsConfig.contactsApiUrlSticky = true;
    }
  }

  stopFormSubmit();
  configureStores();
  registerComponents(c);

  const atsNetwork = ContainerInstance.get(ATS);

  const hash = window.location.hash;

  // look for a session lifespan in the url hash
  if (hash.startsWith("#/lifespan=")) {
    atsNetwork.setTokenLifespan(parseInt(hash.substr(11), 10));
    window.location.hash = "#/";
  }

  // look for an apiToken in the url hash
  if (hash.startsWith("#/apiKey=")) {
    atsNetwork.setToken(hash.substr(9));
    atsNetwork.deleteImpersonationToken();

    window.location.hash = "#/";
  }

  // look for a ticket in the url hash
  if (hash.startsWith("#/ticket=")) {
    const ticket = hash.substr(9);
    const appRepo = ContainerInstance.get(AppRepository);
    const result = await appRepo.exchangeLoginTicket(ticket);

    if (result && result.BearerToken && result.BearerToken.length > 0)
      atsNetwork.setToken(result.BearerToken);
    else atsNetwork.deleteToken();

    if (result && result.ImpersonationBearerToken && result.ImpersonationBearerToken.length > 0)
      atsNetwork.setImpersonationToken(result.ImpersonationBearerToken);
    else atsNetwork.deleteImpersonationToken();

    window.location.hash = "#/";
  }

  if (hash.startsWith("#oauth")) {
    const o = ContainerInstance.get(OAuth);
    o.processRedirect(hash);
  }

  // also look at it this way:
  const tokenRegex = /\?apikey=([a-zA-Z0-9\.\_\-\=\+\/]+)/;
  const tokenRegexResult = tokenRegex.exec(window.location.hash);
  if (tokenRegexResult != null) {
    atsNetwork.setToken(tokenRegexResult[1]);
    atsNetwork.deleteImpersonationToken();
    window.location.hash = window.location.hash.replace(tokenRegex, "");
  }

  let context = null;

  // get a bare org portal context
  await seedOrganizationPortal();

  // check portalId compat between token and context
  if (!!context && atsNetwork.hasToken) {
    const resolvedToken = atsNetwork.hasImpersonationToken
      ? atsNetwork.impersonationToken
      : atsNetwork.hasToken
      ? atsNetwork.token
      : null;

    const parsedJWT = resolvedToken && parseJwt(resolvedToken);
    if (parsedJWT?.["ats-portal-id"]) {
      const portalId = context.PortalId;
      const unaligned =
        portalId &&
        (Array.isArray(parsedJWT["ats-portal-id"])
          ? !parsedJWT["ats-portal-id"].includes(portalId)
          : parsedJWT["ats-portal-id"] !== portalId);

      if (unaligned) {
        atsNetwork.deleteToken();
      }
    }
  }

  try {
    if (atsNetwork.hasToken) {
      const appRepo = ContainerInstance.get(AppRepository);
      const res = await appRepo.organizationContext();

      /*
      const exceptionlessService = ContainerInstance.get(ExceptionlessService);
      exceptionlessService.configure(res, atsNetwork.hasImpersonationToken);
      */

      if (res.Organization.Id != null) {
        const contactOrganizationRepository = ContainerInstance.get(ContactOrganizationRepository);
        res.ContactOrganization = await contactOrganizationRepository.getById(res.Organization.Id);
      }

      await dispatch(new StartAction(res));
    }
  } catch (err) {}

  c.startWith(App);
});

function stopFormSubmit() {
  document.addEventListener("submit", (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  });
}
