import { inject, prop, ComponentEventBus } from "fw";

@inject
export class ConcealedField {
  @prop(null) public value!: string;
  @prop(null) public decryptedValue!: string;
  @prop(() => { }) public decrypt: (text: string) => Promise<string>;

  public isRevealing: boolean = false;
  public isRevealingError: boolean = false;

  constructor(
    private ceb: ComponentEventBus
  ) {}

  public get showIcon(): boolean {
    return !!this.value && !this.decryptedValue;
  }

  public valueChanged() {
    this.isRevealingError = false;
    this.ceb.dispatch("update:decrypted-value", null);
  }

  public async reveal() {
    if (!this.decrypt) {
      return;
    }

    this.isRevealing = true;
    this.isRevealingError = false;

    try {
      const decryptedValue = await this.decrypt(this.value);
      this.ceb.dispatch("update:decrypted-value", decryptedValue);
    } catch (err) {
      this.isRevealingError = true;
    } finally {
      this.isRevealing = false;
    }
  }

  public hide() {
    this.isRevealingError = false;
    this.ceb.dispatch("update:decrypted-value", null);
  }
}
