import { prop, inject, ComponentEventBus } from "fw";

let uniqueId = 0;

@inject
export class Pager {
  @prop(null) total!: number;
  @prop(null) pageSize!: number;
  @prop(null) page!: number;
  @prop(null) loading!: boolean;

  private uniqueId = uniqueId++;

  constructor(private ceb: ComponentEventBus) {}

  get start() {
    if (this.total === 0) {
      return 0;
    }
    return Math.min(((this.page - 1) * this.pageSize) + 1, this.total);
  }

  get end() {
    return Math.min((this.start - 1) + this.pageSize, this.total);
  }

  get hasPages() {
    return this.total > this.pageSize;
  }

  get canPrev() {
    return this.page > 1;
  }

  get canNext() {
    return (this.start + this.pageSize) <= this.total;
  }

  prevPage() {
    this.ceb.dispatch("previous");
  }

  nextPage() {
    this.ceb.dispatch("next");
  }

  public get visuallyHiddenPageRangeText(): string {
    return `Showing records ${this.start} to ${this.end} out of ${this.total}.`;
  }

  public makeId() {
    return `pager-${ this.uniqueId }`
  }
}
