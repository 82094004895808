import { inject, prop } from "fw";

@inject
export class StickyMiniHeader {
  @prop(null) fullHeaderElDomPath: string;
  @prop(null) scrollElDomPath: string;

  public fullHeaderEl: HTMLElement = null;
  public scrollEl: HTMLElement = null;
  public scrollHeightBuffer: number = 0;
  public isStickyMini: boolean = false;

  public handleScroll(event) {
    this.isStickyMini = event?.target?.scrollTop > this.scrollHeightBuffer;
  }

  public attached() {
    this.getDomElements();

    // listen for scroll
    this.scrollEl.addEventListener("scroll", this.handleScroll)

    // set length user must scroll before rendering sticky mini header
    this.scrollHeightBuffer = this.fullHeaderEl?.offsetHeight || 0;
  }

  private getDomElements(): void {
    this.scrollEl = document.querySelector(this.scrollElDomPath);
    this.fullHeaderEl = document.querySelector('#application-preview-header');
  }

  public detached() {
    this.scrollEl.removeEventListener("scroll", this.handleScroll)
  }
}
