import { inject } from "fw";
import { PopoverController } from "service/popover";

export interface Command {
  command: "delete" | "duplicate" | "filter" | "choose-columns";
}

@inject
export class ChartSettings {
  constructor(private controller: PopoverController<Command>) { }

  deleteChart() {
    this.controller.ok({ command: "delete" });
  }

  duplicate() {
    this.controller.ok({ command: "duplicate" });
  }
}
