import { inject, needs } from "fw";
import { Contact } from "models/contact";

import { PopoverController } from "service/popover";
import { ContactCard } from "./contact-card";

export interface ContactCardPopoverOptions {
  contact: Contact;
  showRemove: boolean;
}

export interface ContactCardPopoverResult {
  remove: boolean;
}

@inject
@needs(ContactCard)
export class ContactCardPopover {
  public contact: Contact = null;
  public remove: () => void = null;

  constructor(
    private controller: PopoverController<ContactCardPopoverResult>
  ) { }

  private async activate(options: ContactCardPopoverOptions) {
    this.contact = options.contact;
    if (options.showRemove) {
      this.remove = () => this.controller.ok({ remove: true });
    }
  }
}
