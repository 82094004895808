import { prop, ComponentEventBus, inject } from "fw";
import { UtilityRepository } from "network/utility-repository";

let uniqueId = 0;

@inject
export class EncryptedTextField {
  constructor(private componentEventBus: ComponentEventBus,private utilityRepo: UtilityRepository) {}

  @prop(null) private label;
  @prop("") private placeholder!: string;
  @prop(null) private value;
  @prop(null) private validation;
  @prop(null) private maxlength;
  @prop(null) private readonly;
  @prop(null) private setfocus;
  @prop(false) private disabled;
  @prop(false) private floatingLabel;
  @prop(() => { }) decrypt: (text: string) => Promise<void>;

  private uniqueId = uniqueId++;

  private isFocused = false;
  private inputEl;

  private encrypted = false;
  private revealing = false;
  private revealError = false;

  public attached() {

    this.encrypted = this.value != null && this.value.EncryptedValue != null && this.value.EncryptedValue != "";

    if(!this.encrypted && this.setfocus === true) {
      setTimeout(() => this.inputEl.focus(), 50);
    }
  }

  private detached() {
  }

  private makeId() {
    return `${ this.label ? this.label.replace(/\s/g,'') : "" }-tf-${ this.uniqueId }`
  }

  get haveValue() {
      return this.value != null
        &&
        (
            (this.value.PlainTextValue != null && this.value.PlainTextValue !== "")
            ||
            (this.value.EncryptedValue != null && this.value.EncryptedValue !== "")
        );
  }

  async reveal() {
    this.revealing = true;
    this.revealError = false;

    try {
      this.value.PlainTextValue = (await this.utilityRepo.decryptPayloads(
        [this.value.EncryptedValue]
      ))[0];
      this.value.EncryptedValue = null;

      this.encrypted = false;
    } catch (err) {
        this.revealError = true;
    }

    this.revealing = false;
  }

  private onFocus(e) {
    this.isFocused = true;
    this.componentEventBus.dispatch("focus", e);
  }

  private onBlur(e) {
    this.isFocused = false;
    this.componentEventBus.dispatch("blur", e);
  }

  private onChange() { this.componentEventBus.dispatch("change"); }
  private onKeyUp() { this.componentEventBus.dispatch("keyup"); }
}
