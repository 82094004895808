import { fromClass } from "fw-model";

export class ApiKeyMetaData {
  OrganizationId: string;
  Token: string;

  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

export class ApiKey {
  Id: string;
  IsActive: boolean = true;
  RoleId: string = null;

  @fromClass MetaData: ApiKeyMetaData;
}
