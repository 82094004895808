import { WebSocketMessageAction } from "./web-socket-message";
import { EntityChanged } from "models/entity-changed";

export const filterWebsocketMessage = (type: string) => {
  return class FilterWebsocketMessage {
    handle(method: () => Promise<any>, action: WebSocketMessageAction) {
      if (type == action.type)
        method();
    }
  };
};

export const filterEntityChangedMessage = (type: string) => {
  return class FilterWebsocketMessage {
    handle(method: () => Promise<any>, action: WebSocketMessageAction<EntityChanged>) {
      if (action.type == "EntityChanged" && action.data.type == type)
        method();
    }
  };
};

export { WebSocketMessageAction } from "./web-socket-message";
export type { ApplicationWorkflowEvent } from "models/application-workflow-event";
export type { EntityChanged } from "models/entity-changed";
