import { needs, inject } from "fw";
import { PopoverController } from "service/popover";
import { CalendarEventSeriesRepository } from "network/calendar-event-series-repository";
import { CalendarEventSeries } from "models/calendar-event";
import { ItemSearcher, LoadDataContext, SearchResults } from "views/components/item-searcher";

export interface EventSeriesPopoverOptions {
  prependAllSeriesOption?: boolean,
  sort: string,
}

@inject
@needs(ItemSearcher)
export class EventSeriesPopover {
  public prependAllSeriesOption: boolean = false;
  public sort: string = null;

  constructor(
    private controller: PopoverController<CalendarEventSeries>,
    private calendarEventSeriesRepo: CalendarEventSeriesRepository,
  ) {}

  public activate(options: EventSeriesPopoverOptions) {
    if (!options) return;

    this.prependAllSeriesOption = options.prependAllSeriesOption;
    this.sort = options.sort;
  }

  public select(eventSeries: CalendarEventSeries) {
    this.controller.ok(eventSeries);
  }

  public async loadData(context: LoadDataContext): Promise<SearchResults<CalendarEventSeries>> {
    const { filter, search, page, pageSize } = context;
    const result = await this.calendarEventSeriesRepo.list(search, filter, undefined, this.sort, page, pageSize);

    if (this.prependAllSeriesOption) {
      const emptySeries = new CalendarEventSeries();
      emptySeries.display_name = "All Series";
      result.results.unshift(emptySeries);
    }
    return { total: result.total, list: result.results };
  }
}
