import { FormForType, formFor, Validators } from "fw-model";
const { required, wrap, isNumber } = Validators;
import { conditionOperatorsNoValueRequired } from "shared/form-runtime";
import { isValidJavascript } from "./validators";

import {
  Condition,
  ConditionExpression,
  ConditionFieldType,
  ConditionGroup,
  ConditionInfo,
  ICondition
} from "models/form";
import { WeightedScoreSettings, WeightedScoreFieldInput } from "models/calculated-field";

export type ConditionInfoFormType = FormForType<ConditionInfo>;
export type ConditionGroupFormType = FormForType<ConditionGroup>;
export type ConditionFormType = FormForType<Condition>;
export type ConditionExpressionFormType = FormForType<ConditionExpression>;


export const conditionInfoFormCreator = formFor(ConditionInfo, s => {
  s.field(f => f.ReferencedFields, "Referenced Fields");
  s.form(f => f.Conditions, "Conditions", conditionGroupFormCreator);
});

export const conditionGroupFormCreator = formFor(ConditionGroup, s => {
  s.requiredField(a => a.Operator, "Operator");
  s.formArray(a => a.Conditions, "Conditions", (condition: ICondition) => {
    if ((<ConditionGroup>condition)?.Conditions) {
      return conditionGroupFormCreator(<ConditionGroup>condition);
    } else if ((<ConditionExpression>condition)?.Expression) {
      return conditionExpressionFormCreator(<ConditionExpression>condition);
    } else {
      return conditionFormCreator(<Condition>condition);
    }
  });
});

export const conditionFormCreator = formFor(Condition, s => {
  s.requiredField(a => a.Field, "Field");
  s.requiredField(a => a.FieldType, "Field Type");
  s.requiredField(a => a.Operator, "Operator");
  s.field(a => a.Value, "Value", wrap(requiredIfValueOperator()));
  s.field(a => a.ValueIsField, "Value is a Field");
});

export const conditionExpressionFormCreator = formFor(ConditionExpression, s => {
  s.requiredField(a => a.Expression, "Expression", wrap(isValidJavascript));
});

const requiredIfValueOperator = () => {
  return (input, condition: Condition) => {
    if (condition.FieldType === ConditionFieldType.Number && !condition.ValueIsField) return isNumber(input);
    if (conditionOperatorsNoValueRequired.includes(condition?.Operator)) {
      return null;
    }

    return required(input);
  };
};
