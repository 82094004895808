import { fromClass, fromClassArray } from "fw-model";
import { ExportDefinition } from "./export-definition";

export enum ExportTaskStatusTypeCode {
  Requested = 0,
  Running = 1,
  Complete = 2,
  Error = 3,
  PendingPackaging = 4,
  PendingDelivery = 5,
  DeliveryError = 6,
  Empty = 7
}

export class FileDeliveryInfo {
  FileId?: string;
  VirtualPath?: string;
  AlternateFileName?: string;
}

export class FileFailedDeliveryInfo extends FileDeliveryInfo {
  DeliveryFailureMessage: string
}

export class ExportTaskMetaData {
  OrganizationId: string;
  ActorType: string;
  ActorId: string;
  TaskRequestId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  Progress: number;
  Status: ExportTaskStatusTypeCode;
  IdCount: number;

  @fromClassArray(FileDeliveryInfo) FailedDeliveries: FileFailedDeliveryInfo[];
  @fromClassArray(FileDeliveryInfo) FilesToDeliver: FileDeliveryInfo[];
  @fromClassArray(FileDeliveryInfo) DeliveredFiles: FileDeliveryInfo[];
}

export class ExportDeliveryOptions {
  FileProviderId: string = null;
  FileProviderFolder: string = null;
  ShouldZipContents: boolean = false;
  ZipFileNameFormat: string = null; // = "{{exportName}} - File {{fileNumber}}";
  ZipFileCount: number = 0;
}

export class ExportTask {
  Id: string;
  Ids: string[];

  @fromClass Delivery: ExportDeliveryOptions;
  @fromClass Definition: ExportDefinition;
  @fromClass MetaData: ExportTaskMetaData;
}
