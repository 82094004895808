import { ComponentEventBus, inject, prop } from "fw";
import { isEmpty, keys } from "lodash-es";

import { Form, FormTypeCode } from "models/form";

import {
  ApplicationRestriction,
  type ApplicationRestrictionType,
  getFormType,
  formPath,
  calculatedFieldPath,
  questionPath,
} from "models/role";

import { FormStore } from "state/forms";
import { ApplicationSettingsStore } from "state/application-settings";

import { ApplicationSettingsService } from "service/application-settings";
import { FormService } from "service/form";
import { DataDictionaryService } from "service/data-dictionary";
import { DataDictionaryFieldCategory } from "models/data-dictionary";
import { dispatch } from "fw-state";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";

type RestrictionItem = { label:string; key:string, path:string; title?:string; form?:Form; }

@inject
export class AllRestrictions {
  @prop(null) public type!: ApplicationRestrictionType;
  @prop(() => []) public activeRestrictions!: ApplicationRestriction[];

  public searchTerm: string = "";
  public existingHash: { [key: string]: boolean } = {};
  public selectedHash: { [key: string]: boolean } = {};

  private items: RestrictionItem[] = [];
  public forms: { [key in FormTypeCode]?: { label: string, key: string, path: string, fieldCount: number } } = {};
  public documentStepsLength: number = 0;
  public portfolioStepsLength: number = 0;
  public formTypeNameHash: { [key in FormTypeCode]?: string } = {};
  public formRestrictionTypeHash: { [key in FormTypeCode]?: string } = {};

  constructor(
    private ceb: ComponentEventBus,
    private applicationSettingsStore: ApplicationSettingsStore,
    private applicationSettingsService: ApplicationSettingsService,
    private dataDictionaryStore: DataDictionaryStore,
    private dataDictionaryService: DataDictionaryService,
    private formStore: FormStore,
    private formService: FormService
  ) { }

  public get localType() {
    return this.type;
  }
  public set localType(type: ApplicationRestrictionType) {
    this.ceb.dispatch("update:type", type);
  }

  public async attached() {
    const existingHash = {};
    for (const r of this.activeRestrictions || []) {
      switch (r.Type) {
        case "ApplicantIdentity":
          if (!existingHash["applicant_identity"])
            existingHash["applicant_identity"] = true;
          break;

        case "ApplicantProfileImage":
          if (!existingHash["applicant_profile_image"])
            existingHash["applicant_profile_image"] = true;
          break;

        case "ApplicationProperty":
          r.Paths.forEach(p => {
            const propertyPath = p.startsWith("properties.") ? p : `properties.${p}`;
            existingHash[propertyPath] = true;
          });
          break;

        case "FormStep":
        case "Evaluation":
        case "ReferenceForm":
          r.Paths.forEach(p => {
            const formPath = p.startsWith("forms.") ? p : `forms.${p}`;
            existingHash[formPath] = true;
          });
          break;

        case "DocumentStep":
          existingHash[`documents.${r.StepKey}`] = true;
          break;
        case "PortfolioStep":
          existingHash[`portfolios.${r.StepKey}`] = true;
          break;

        default:
          return;
      }
    }
    this.existingHash = existingHash;

    await this.setup();
  }

  private async setup() {
    const forms = {};
    const items: RestrictionItem[] = [];

    items.push({ label: "Applicant Identity", key: "applicant_identity", path: "applicant_identity" });

    items.push({ label: "Applicant Profile Image", key: "applicant_profile_image", path: "applicant_profile_image" });

    const applicationProperties = this.applicationProperties;
    if (applicationProperties.length > 0) {
      applicationProperties.forEach(property => {
        items.push({ label: property.Label, key: property.Key, path: `properties.${property.Key}` });
      });
    }

    const state = this.formStore.state.forms;
    if (state && state.length > 0) {
      for (const form of state) {
        const code = form.MetaData.Type;
        const type = getFormType(code);
        if (!type || type.length === 0) continue;

        let count = 0;
        for (const section of form.Sections) {
          for (const question of section.Questions) {
            count++;
            items.push({
              label: question.Label,
              title: question.Text,
              key: question.Key,
              path: questionPath(form, section, question, "forms"),
              form: form,
            });
          }
        }

        // calc fields
        if (form.CalculatedFields) {
          count += form.CalculatedFields.length;
          form.CalculatedFields.forEach(calcField => {
            items.push({
              label: calcField.Label,
              key: calcField.Key,
              path: calculatedFieldPath(form, calcField, "forms"),
              form: form
            });
          });
        }

        if (!forms[code]) {
          forms[code] = [];
          this.setTypes(code);
        }
        forms[code].push({ label: form.Name, key: form.Key, path: form.Key, fieldCount: count });

      }
    }


    await dispatch(new EnsureDataDictionaryFieldsAction(false));
    const fields = this.dataDictionaryStore.state.fields;
    const documentSteps = this.dataDictionaryService.includeSteps(fields, DataDictionaryFieldCategory.DocumentSteps);
    this.documentStepsLength = documentSteps.length;
    for (const documentStep of documentSteps) {
      const stepKey: string = this.dataDictionaryService.getFieldPathKey(documentStep);
      items.push({ label: documentStep.Label, path: `documents.${stepKey}`, key: stepKey });
    }

    const portfolioSteps = this.dataDictionaryService.includeSteps(fields, DataDictionaryFieldCategory.PortfolioSteps);
    this.portfolioStepsLength = portfolioSteps.length;
    for (const portfolioStep of portfolioSteps) {
      const stepKey: string = this.dataDictionaryService.getFieldPathKey(portfolioStep);
      items.push({ label: portfolioStep.Label, path: `portfolios.${stepKey}`, key: stepKey });
    }

    this.forms = forms;
    this.items = items;
  }

  get formTypes() {
    if (!this.forms || isEmpty(this.forms))
      return [];
    return keys(this.forms);
  }

  private setTypes(code: FormTypeCode) {
    switch (code) {
      case FormTypeCode.Applicant:
        this.formTypeNameHash[code] = "Applicant";
        this.formRestrictionTypeHash[code] = "FormStep";
        break;
      case FormTypeCode.Evaluation:
        this.formTypeNameHash[code] = this.formRestrictionTypeHash[code] = "Evaluation";
        break;
      case FormTypeCode.Recommender:
        this.formTypeNameHash[code] = "Reference";
        this.formRestrictionTypeHash[code] = "ReferenceForm";
        break;
      // case FormTypeCode.Inquiry:
      //   this.formTypeNameHash[code] = "Inquiry";
      //   this.formRestrictionTypeHash[code] = "FormStep";
      //   break;
    }
  }

  get hasSelectedValues() {
    if (!this.selectedHash || isEmpty(this.selectedHash))
      return false;

    const paths = keys(this.selectedHash);
    return paths && paths.length > 0;
  }

  get applicationProperties() {
    const {
      ApplicationProperties,
    } = this.applicationSettingsStore.state.applicationSettings;
    return ApplicationProperties || [];
  }

  get filtered() {
    if (!this.items || this.items.length === 0)
      return [];

    if (!this.searchTerm || this.searchTerm.length === 0) {
      return this.items;
    }

    const st = this.searchTerm.toLowerCase();
    return this.items.filter(i =>
      (i.label?.toLowerCase().indexOf(st) >= 0)
      || (i.key?.toLowerCase().indexOf(st) >= 0)
    );
  }

  isSelected(field: RestrictionItem) {
    if (!field) return false;
    return this.existingHash && (this.existingHash[field.path] || (field.form && this.existingHash[formPath(field.form, "forms", true)]));
  }

  ensureField(checked: boolean, path: string) {
    if (!checked) {
      delete this.selectedHash[path];

      const paths = keys(this.selectedHash);
      if (!paths || paths.length === 0) {
        this.selectedHash = {};
      }
    }
  }

  createApplicantIdentity() {
    const restriction = new ApplicationRestriction();
    restriction.Type = "ApplicantIdentity";
    this.ceb.dispatch("done", [restriction]);
  }

  createApplicantProfileImage() {
    const restriction = new ApplicationRestriction();
    restriction.Type = "ApplicantProfileImage";
    this.ceb.dispatch("done", [restriction]);
  }

  public async select(): Promise<void> {
    let restrictions = [];
    const paths = keys(this.selectedHash);
    const propertiesHash = {};
    const formsHash = {};
    const documentsHash = {};
    const portfoliosHash = {};

    for (const path of paths) {
      if (path === "applicant_identity") {
        const applicantIdentityRestriction = new ApplicationRestriction();
        applicantIdentityRestriction.Type = "ApplicantIdentity";
        restrictions.push(applicantIdentityRestriction);
      } else if (path === "applicant_profile_image") {
        const applicantProfileImageRestriction = new ApplicationRestriction();
        applicantProfileImageRestriction.Type = "ApplicantProfileImage";
        restrictions.push(applicantProfileImageRestriction);
      } else {
        if (path.startsWith("forms.")) {
          const f = await this.formService.getFormFromPath(path);
          if (!f) continue;

          const type = getFormType(f.MetaData.Type);
          if (!formsHash[type]) {
            formsHash[type] = {};
          }

          if (!formsHash[type][f.Key]) {
            formsHash[type][f.Key] = {};
          }

          formsHash[type][f.Key][path.substring(6)] = true;

        } else if (path.startsWith("properties.")) {
          const p = this.applicationSettingsService.getApplicationPropertyFromPath(path);
          if (!p) continue;
          propertiesHash[p.Key] = true;

        } else if (path.startsWith("documents.")) {
          documentsHash[path.substring(10)] = true;

        } else if (path.startsWith("portfolios.")) {
          portfoliosHash[path.substring(11)] = true;
        }
      }
    }

    if (propertiesHash && !isEmpty(propertiesHash)) {
      const propertyPaths = keys(propertiesHash);
      if (propertyPaths && propertyPaths.length > 0) {
        const propertyRestriction = new ApplicationRestriction;
        propertyRestriction.Type = "ApplicationProperty";
        propertyRestriction.Paths = propertyPaths;
        restrictions.push(propertyRestriction);
      }
    }

    if (formsHash && !isEmpty(formsHash)) {
      for (const type of keys(formsHash)) {
        if (formsHash[type] && !isEmpty(formsHash[type])) {
          for (const key of keys(formsHash[type])) {
            if (formsHash[type][key] && !isEmpty(formsHash[type][key])) {
              const formPaths = keys(formsHash[type][key]);
              if (formPaths && formPaths.length > 0) {
                //  TODO: add this back when api is fixed for evals
                // this.ensureRestriction(type as ApplicationRestrictionType, key, formPaths);
                const formRestriction = new ApplicationRestriction;
                formRestriction.Type = type as ApplicationRestrictionType;
                formRestriction.Paths = formPaths;
                restrictions.push(formRestriction);
              }
            }
          }
        }
      }
    }

    if (portfoliosHash && !isEmpty(portfoliosHash)) {
      const portfolioKeys = keys(portfoliosHash);
      if (portfolioKeys && portfolioKeys.length > 0) {
        for (const portfolioKey of portfolioKeys) {
          const portfolioRestriction = new ApplicationRestriction;
          portfolioRestriction.Type = "PortfolioStep";
          portfolioRestriction.StepKey = portfolioKey;
          restrictions.push(portfolioRestriction);
        }
      }
    }

    if (documentsHash && !isEmpty(documentsHash)) {
      const documentKeys = keys(documentsHash);
      if (documentKeys && documentKeys.length > 0) {
        for (const documentKey of documentKeys) {
          const documentRestriction = new ApplicationRestriction;
          documentRestriction.Type = "DocumentStep";
          documentRestriction.StepKey = documentKey;
          restrictions.push(documentRestriction);
        }
      }
    }

    this.ceb.dispatch("done", restrictions);
  }

  getFieldDescription(field: RestrictionItem) {
    return field.label?.trim().length > 0 ? field.label : field.key;
  }
}
