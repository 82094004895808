import { inject, prop, ComponentEventBus } from "@derekpitt/fw"
import { mergeFilterData } from "../../helpers";
import { countries, regions } from "../../../../countries/index";

import { Question, FormAnswer } from "../../models";

@inject
export class AddressType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledBy;

  private address1: string = "";
  private address2: string = "";
  private city: string = "";
  private region: string = "";
  private country: string = "";
  private tempCountry: string = "";
  private postalCode: string = "";

  private countryText = null;
  private regionText = null;

  constructor(
    private ecb: ComponentEventBus
  ) { }

  attached() {
    this.answerChanged();
  }

  get countries() {
    return mergeFilterData(countries, this.country);
  }

  get regions() {
    return mergeFilterData(regions[this.country], this.region);
  }

  updateText() {
    const regionOption = this.regions ? this.regions.find(o => o.value == this.region) : null;
    this.regionText = regionOption != null ? regionOption.text : this.region;
    
    const countryOption = this.countries.find(o => o.value == this.country);
    this.countryText = countryOption != null ? countryOption.text : this.country;
  }

  answerChanged() {
    
    if (typeof this.answer?.Data === "undefined") return;

    this.tempCountry = this.answer.Data?.["country"];
    this.address1 = this.answer.Data?.["address1"];
    this.address2 = this.answer.Data?.["address2"];
    this.city = this.answer.Data?.["city"];
    this.region = this.answer.Data?.["region"];
    this.country = this.answer.Data?.["country"];
    this.postalCode = this.answer.Data?.["postalCode"];

    this.updateText();
  }

  onClick(event, field) {
    const handleBlur = () => this.onBlur(
      field,
      () => event.target.removeEventListener("blur", handleBlur)
    );
    event.target.addEventListener("blur", handleBlur);
  }

  onBlur(field, cb)  {
    this[field] = this[field]?.trim();
    this.change();
    cb();
  }

  change() {
    let { address1, address2, city, country, postalCode, region } = this;

    if(country && this.tempCountry !== country) {
      region = "";
      this.region = "";
      this.tempCountry = country;
    }

    if (address1.trim().length > 0 ||
        address2.trim().length > 0 ||
        city.trim().length > 0 ||
        country.trim().length > 0 ||
        postalCode.trim().length > 0 ||
        region.trim().length > 0) {
      this.answer.Data = {
        address1,
        address2,
        city,
        country,
        postalCode,
        region,
      };
    } else {
      this.answer.Data = null;
    }
    this.updateText();
    this.ecb.dispatch("answer-changed");
  }
}
