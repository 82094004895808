import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { CustomPortal } from "models/custom-portal";

@inject
export class CustomPortalRepository {
  constructor(private s: ATS) {}

  public async get(id: string) {
    const res = await this.s.get(`custom-portal/${id}`);
    return createFrom(CustomPortal, res.body);
  }

  public async list() {
    const res = await this.s.get<CustomPortal[]>("custom-portal");
    return createFromArray(CustomPortal, res.body);
  }

  public async post(portal: CustomPortal) {
    const res = await this.s.post("custom-portal", portal);
    return createFrom(CustomPortal, res.body);
  }

  public async put(portal: CustomPortal) {
    const res = await this.s.put<CustomPortal>(
      `custom-portal/${portal.Id}`,
      portal
    );
    return createFrom(CustomPortal, res.body);
  }

  public async search(
    search: string = null,
    sort: string = null,
    page: number = null,
    pageSize: number = null
  ) {
    const res = await this.s.get<CustomPortal[]>("custom-portal", {
      search,
      sort,
      page,
      pageSize,
    });
    return {
      list: createFromArray(CustomPortal, res.body),
      total: parseInt(res.headers["x-ats-total"], 10),
    };
  }
  
  public async delete(ids: string[]) {
    await this.s.delete(`custom-portal/${ids.join(",")}`);
  }
}
