import { formFor, FormForType, Validators } from "fw-model";

import {
    AssignmentDefinition,
    ChartDataSource,
    FieldGroup,
    FunnelDefinition,
    GoalsDefinition,
    GridColumn,
    GridViewDefinition,
    RegistrationStatusCountsDefinition,
    ReportAxisDefinition,
    ReportChartDefinition,
    ReportChartType,
    ReportDefinition,
    ReportPreviewDefinition,
    TasksDefinition,
    WidgetDefinition,
} from "shared/report-runtime";

const { wrap, required } = Validators;

export type FormFormReportAxisDefinition = FormForType<ReportAxisDefinition>;
export type FormForFieldGroup = FormForType<FieldGroup>;

export const fieldGroupFormCreator = formFor(FieldGroup, s => {
  s.field(a => a.Label, "Group Label (optional)");
  s.requiredField(a => a.ColumnSpan, "Column Span");
  s.formArray(a => a.List, "List", widgetDefinitionFormCreator);
});

const reportAxisDefinitionFormCreatorNotRequired = formFor(
  ReportAxisDefinition,
  s => {
    s.field(a => a.Label, "Label");
    s.field(a => a.Path, "Path");
    s.field(a => a.AggregationType, "Type");
    s.field(a => a.Interval, "Interval");
    s.field(a => a.BucketLimit, "Limit");
    s.field(a => a.LabelTransform, "LabelTransform");
    s.field(a => a.TermsOrderBy, "Order");
  },
);

export const reportAxisDefinitionFormCreator = formFor(
  ReportAxisDefinition,
  s => {
    s.requiredField(a => a.Label, "Label");
    s.requiredField(a => a.Path, "Path");
    s.requiredField(a => a.AggregationType, "Type");
    s.field(a => a.Interval, "Interval");
    s.field(a => a.BucketLimit, "Limit");
    s.field(a => a.LabelTransform, "LabelTransform");
    s.field(a => a.TermsOrderBy, "Order");
  },
);

export type FormForReportChartDefinition = FormForType<ReportChartDefinition>;
export type FormForWidgetDefinition = FormForType<WidgetDefinition>;
export type FormForAssignmentDefinition = FormForType<AssignmentDefinition>;
export type FormForGoalsDefinition = FormForType<GoalsDefinition>;
export type FormForFunnelDefinition = FormForType<FunnelDefinition>;
export type FormForTasksDefinition = FormForType<TasksDefinition>;
export type FormForGridViewDefinition = FormForType<GridViewDefinition>;
export type FormForRegistrationStatusCountsDefinition = FormForType<RegistrationStatusCountsDefinition>;
export type FormForGridColumn = FormForType<GridColumn>;

export const changeRequiredMessage = (input: string) =>
    required(input) ? "The Chart Name field is required" : null;

export const reportChartDefinitionContactsFormCreator = (
    data: ReportChartDefinition,
    isRequiredSecondaryIndependentVariable: boolean = false
) => formFor(
  ReportChartDefinition,
  s => {
    s.field(c => c.Name, "Chart Name", wrap(changeRequiredMessage));
    s.requiredField(c => c.ChartType, "Type");

    s.field(c => c.ChartFilter, "Filter");
    s.field(c => c.ClientData, "Client Data");
    s.requiredField(c => c.ContactType, "ContactType");
    s.requiredField(c => c.DataSource, "DataSource");

    s.form(
      c => c.PrimaryIndependentVariable,
      "Primary",
      reportAxisDefinitionFormCreator,
    );

    const formCreator = isRequiredSecondaryIndependentVariable ? reportAxisDefinitionFormCreator :
      reportAxisDefinitionFormCreatorNotRequired;

    s.form(
      c => c.SecondaryIndependentVariable,
      "Secondary",
      formCreator,
    );
  },
)(data);

export const goalsDefinitionFormCreator = formFor(
  GoalsDefinition,
  s => {
    s.field(c => c.Name, "Chart Name", wrap(changeRequiredMessage));
    s.field(c => c.ChartFilter, "Filter");
    s.field(c => c.ClientData, "Client Data");
    s.field(c => c.ContactType, "ContactType");
    s.field(c => c.DataSource, "DataSource");
    s.requiredField(c => c.GoalIds, "Goal Ids");
  },
);

export const funnelDefinitionFormCreator = formFor(
  FunnelDefinition,
  s => {
    s.field(c => c.Name, "Chart Name", wrap(changeRequiredMessage));
    s.field(c => c.ChartFilter, "Filter");
    s.field(c => c.ClientData, "Client Data");
    s.field(c => c.ContactType, "ContactType");
    s.field(c => c.DataSource, "DataSource");
    s.requiredField(c => c.Path, "Path");
  },
);

export const assignmentDefinitionFormCreator = formFor(
  AssignmentDefinition,
  s => {
    s.field(c => c.Name, "Chart Name", wrap(changeRequiredMessage));
    s.field(c => c.ChartFilter, "Filter");
    s.field(c => c.ClientData, "Client Data");
    s.field(c => c.ContactType, "ContactType");
    s.field(c => c.DataSource, "DataSource");
    s.field(c => c.Id, "Id");
    s.requiredField(c => c.Mode, "Mode");
    s.requiredField(c => c.PhaseKey, "Phase");
  },
);

export const tasksDefinitionFormCreator = formFor(
  TasksDefinition,
  s => {
    s.field(c => c.Name, "Chart Name", wrap(changeRequiredMessage));
    s.field(c => c.ChartFilter, "Filter");
    s.field(c => c.ClientData, "Client Data");
    s.field(c => c.ContactType, "ContactType");
    s.field(c => c.DataSource, "DataSource");
    s.field(c => c.Id, "Id");
  },
);

export const gridColumnFormCreator = formFor(
  GridColumn,
  s => {
    s.requiredField(c => c.Label, "Label");
    s.requiredField(c => c.Path, "Path");
    s.field(c => c.Sort, "Sort");
  },
);

export const gridViewDefinitionFormCreator = formFor(
  GridViewDefinition,
  s => {
    s.field(c => c.Name, "Chart Name", wrap(changeRequiredMessage));
    s.field(c => c.ChartFilter, "Filter");
    s.field(c => c.ClientData, "Client Data");
    s.field(c => c.ContactType, "ContactType");
    s.field(c => c.DataSource, "DataSource");
    s.field(c => c.ListLimit, "ListLimit");
    s.field(c => c.UserId, "UserId");
    s.field(c => c.Sort, "Sort");
    s.formArray(c => c.Columns, "Columns", gridColumnFormCreator);
  }
);

export const registrationStatusCountsDefinitionFormCreator = formFor(
  RegistrationStatusCountsDefinition,
  s => {
    s.field(c => c.Name, "Chart Name", wrap(changeRequiredMessage));
    s.field(c => c.ChartFilter, "Filter");
    s.field(c => c.ClientData, "Client Data");
    s.field(c => c.ContactType, "ContactType");
    s.field(c => c.DataSource, "DataSource");
    s.field(c => c.ListLimit, "ListLimit");
    s.field(c => c.Sort, "Sort");
    s.field(c => c.DataContext, "DataContext");
    s.field(c => c.DataContextId, "DataContextId");
    s.formArray(c => c.Columns, "Columns", gridColumnFormCreator);
  }
);

export const reportChartPreviewDefinitionFormCreator = formFor(
  ReportPreviewDefinition,
  s => {
    s.field(c => c.Name, "Chart Name", wrap(changeRequiredMessage));
    s.field(c => c.ChartFilter, "Filter");
    s.field(c => c.ClientData, "Client Data");
    s.field(c => c.ContactType, "ContactType");
    s.field(c => c.DataSource, "DataSource");
    s.field(c => c.Path, "Path");
    s.field(c => c.WidgetType, "WidgetType");
    s.field(c => c.ChartType, "ChartType");
    s.field(c => c.ChartFilter, "ChartFilter");
  }
);

export const reportChartDefinitionFormCreator = (
  data: ReportChartDefinition,
  isRequiredSecondaryIndependentVariable: boolean = false
) => formFor(
  ReportChartDefinition,
  s => {
    s.field(c => c.Name, "Chart Name", wrap(changeRequiredMessage));
    s.requiredField(c => c.ChartType, "Type");

    s.field(c => c.ChartFilter, "Filter");
    s.field(c => c.ClientData, "Client Data");
    s.field(c => c.ContactType, "ContactType");
    s.field(c => c.DataSource, "DataSource");

    s.form(
      c => c.PrimaryIndependentVariable,
      "Primary",
      reportAxisDefinitionFormCreator,
    );

    const formCreator = isRequiredSecondaryIndependentVariable ? reportAxisDefinitionFormCreator :
      reportAxisDefinitionFormCreatorNotRequired;

    s.form(
      c => c.SecondaryIndependentVariable,
      "Secondary",
      formCreator,
    );
  },
)(data);

export type FormForReportDefinition = FormForType<ReportDefinition>;

export class UpdateReportDefinitionAction {
  constructor(public form: FormForReportDefinition) { }
}

export class CreateReportDefinitionAction {
  public createdId: string = null;

  constructor(public form: FormForReportDefinition, public isDashboard = false, public dashboardUserId: string = null) { }
}

export const widgetDefinitionFormCreator = (data: WidgetDefinition) => {
  switch (data.WidgetType) {
    case "chart": {
      const isRequiredSecondaryIndependentVariable = data.ChartType === ReportChartType.Table;

      if (data.DataSource == ChartDataSource.Contacts) {
        return reportChartDefinitionContactsFormCreator(data as ReportChartDefinition, isRequiredSecondaryIndependentVariable);
      } else {
        return reportChartDefinitionFormCreator(data as ReportChartDefinition, isRequiredSecondaryIndependentVariable);
      }
    }

    case "assignment":
      return assignmentDefinitionFormCreator(data as AssignmentDefinition);

    case "goals":
      return goalsDefinitionFormCreator(data as GoalsDefinition);

    case "funnel":
      return funnelDefinitionFormCreator(data as FunnelDefinition);

    case "tasks":
      return tasksDefinitionFormCreator(data as TasksDefinition);

    case "grid-view":
      return gridViewDefinitionFormCreator(data as GridViewDefinition);

    case "registration-status-counts":
      return registrationStatusCountsDefinitionFormCreator(data as RegistrationStatusCountsDefinition);
  }

  return null;
};

export const reportDefinitionFormCreator = formFor(ReportDefinition, s => {
  s.requiredField(rd => rd.Name, "Report Name");
  s.field(rd => rd.Filter, "Program");
  s.field(rd => rd.Category, "Category");

  s.formArray(rd => rd.FieldGroups, "Groups", fieldGroupFormCreator);
  s.formArray(rd => rd.Widgets, "Widgets", widgetDefinitionFormCreator);
});
