import { inject, Navigator } from "fw";
import { dispatch } from "fw-state";

import { ContactsService } from "service/contacts";
import { PopoverController } from "service/popover";
import { Notification } from "service/notification";
import { addNewContactFormCreator, AddNewContactFormType } from 'forms/add-new-contact';
import { ContactTypeDefinition } from 'models/contact-organization';
import { CurrentContactOrganizationStore } from 'state/current-contact-organization';
import { orderBy } from "lodash-es";
import { Contact } from "models/contact";

export class AddNewContactAction {
  public createdContact: Contact = null;
  constructor(
    public form: AddNewContactFormType,
    public contactType: ContactTypeDefinition,
  ) { }
}

export interface AddNewContactProps {
  selectedContactType?: ContactTypeDefinition,
  navigateAfterCreation: boolean
}

@inject
export class AddNewContact {
  public selectedContactType: ContactTypeDefinition = null;
  public addNewContactForm: AddNewContactFormType = null;
  public contactTypeKey: string = "";
  public email: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public fullName: string = "";
  public isSaving: boolean = false;
  public allowContactTypeSwitching: boolean = true;
  public navigateAfterCreation: boolean = false;

  constructor(
    private controller: PopoverController<any>,
    private notify: Notification,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private nav: Navigator,
  ) { }

  private get contactTypes() {
    return this.currentContactOrganizationStore.state.organization.contact_types;
  }

  public get availableContactTypes() {
    return orderBy(
      this.contactTypes.map(ct => ({
        text: ct.name,
        value: ct.key
      })),
      [ct => (ct.text || "").toLowerCase()]
    );
  }

  public attached() {
    if (this.contactTypeKey != "") {
      this.selectedContactType = this.contactTypes.find(t => t.key == this.contactTypeKey);
      this.resetForm();
    }
  }

  public activate(props: AddNewContactProps) {
    if (props == null) return;

    this.navigateAfterCreation = props.navigateAfterCreation;

    if (props.selectedContactType != null) {
      this.allowContactTypeSwitching = false;
      this.contactTypeKey = props.selectedContactType.key;
    }
  }

  public contactTypeChanged() {
    if (this.contactTypeKey != "") {
      this.selectedContactType = this.contactTypes.find(t => t.key == this.contactTypeKey);
      this.resetForm();
    }
  }

  public cancel() {
    this.controller.cancel();
  }

  private resetForm() {
    this.email = "";
    this.firstName = "";
    this.lastName = "";
    this.fullName = "";
    this.addNewContactForm = addNewContactFormCreator(this.selectedContactType)(null);
  }

  public async addNewContact() {
    this.isSaving = true;
    let throwErr = null;

    try {
      this.addNewContactForm.email_address = this.email;
      this.addNewContactForm.first_name = this.firstName;
      this.addNewContactForm.last_name = this.lastName;
      this.addNewContactForm.company_name = this.fullName;
      const action = new AddNewContactAction(this.addNewContactForm, this.selectedContactType);
      await dispatch(action);
      const displayName = this.selectedContactType.use_full_name
        ? this.addNewContactForm.company_name
        : `${this.addNewContactForm.first_name} ${this.addNewContactForm.last_name}`;
      this.controller.ok(action.createdContact);
      this.notify.notify(`Created a ${this.selectedContactType.name} record for ${displayName}`);
      
      if(this.navigateAfterCreation) {
        this.nav.navigate(`/contacts/${action.createdContact.id}`);
      }
    } catch (err) {
      throwErr = err;
    }

    this.isSaving = false;
    if (throwErr != null) throw throwErr;
  }
}
