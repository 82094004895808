import { prop } from "fw";

import type { ICustomFieldDefinition } from "models/contact-organization";
import type { IFieldAttachmentData } from "models/contact";
import { FieldMode } from "../field-mode";

export class AttachmentType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: IFieldAttachmentData;
  @prop(FieldMode.View) public mode!: FieldMode;
}
