import {Validators, FormForType, formFor } from "fw-model";
import { FileSource, FileSourceType, FileSourcePostResult, FileSourceSftpProvider, FileSourceCustomS3Provider } from "models/file-source";

const { required } = Validators;

export type FileSourceFormType = FormForType<FileSource>;

export interface FieldsRequiredStatus
{
    passwordRequired: boolean,
    sshKeyRequired: boolean
};

export class UpdateFileSourceAction {
    constructor(public form: FileSourceFormType) {}
    public updated: FileSource;
}

export class AddFileSourceAction {
    constructor(public form: FileSourceFormType, public type = FileSourceType.ManagedS3) {}
    public result: FileSourcePostResult;
}

export const SftpFormCreator = (fieldsRequiredStatus: FieldsRequiredStatus) => formFor(
    FileSourceSftpProvider,
    s => {
        s.requiredField(u => u.Host, "SFTP Host");
        s.requiredField(u => u.Port, "SFTP Port");
        s.field(u => u.BasePath, "Base Path");
        s.field(u => u.WildcardFileFilter, "Wildcard File Filter");
        s.requiredField(u => u.Username, "SFTP Username");
        s.field(u => u.Password, "SFTP Password", v => v.if(f => fieldsRequiredStatus.passwordRequired, required));
        s.field(u => u.SshVaultKeyId, "SSH Vault Key Id", v => v.if(f => fieldsRequiredStatus.sshKeyRequired, required));
    },
);

export const customS3FormCreator = (credentialsRequired: boolean) => formFor(
    FileSourceCustomS3Provider,
    s => {
        s.requiredField(u => u.Region, "Region");
        s.requiredField(u => u.BucketName, "Bucket Name");
        s.field(u => u.BasePath, "Base Path");
        s.field(u => u.WildcardFileFilter, "Wildcard File Filter");
        s.requiredField(u => u.AccessKeyId, "Access Key Id");
        s.field(u => u.SecretAccessKey, "Secret Access Key", v => v.if(f => credentialsRequired, required));
    }
);

export const fileSourceSftpFormCreator = (fieldsRequiredStatus: FieldsRequiredStatus) => formFor(FileSource, s => {
  s.requiredField(u => u.Name, "Name");
  s.form(p => p.Sftp, "Sftp", SftpFormCreator(fieldsRequiredStatus));
});

export const fileSourceManagedS3FormCreator = formFor(FileSource, s => {
  s.requiredField(u => u.Name, "Name");
});

export const fileSourceCustomS3FormCreator = (credentialsRequired: boolean) => formFor(FileSource, s => {
    s.requiredField(u => u.Name, "Name");
    s.form(p => p.CustomS3, "CustomS3", customS3FormCreator(credentialsRequired));
});