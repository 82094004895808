import { inject } from "fw";
import { compare, Operation } from "fast-json-patch";
import { groupBy } from "lodash-es";

import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { FormStore } from "state/forms";
import { EvaluationPhasesStore } from "state/evaluation-phases";
import { ApplicationSettingsStore } from "state/application-settings";
import { CollaborationModulesStore } from "state/collaboration-modules";
import { QuestionType, FormTypeCode, allQuestions } from "models/form";
import { DataPolicyService } from "service/data-policy";
import { FeatureFlagService } from "service/feature-flag";
import { PopoverController } from "service/popover";
import { AggregationType } from "shared/report-runtime";
import { includes } from 'lodash-es';
import { DataDictionaryService } from "service/data-dictionary";
import { DataDictionaryAggregationType, DataDictionaryFieldCategory, DataDictionaryIndexStatus } from "models/data-dictionary";

export type ChartField = {
  Path: string;
  Label: string;
  Type: AggregationType;
  LabelTransform?: string;
  HasOptionValues?: boolean;
};

export type ChartCategory = { category: string; paths: ChartField[] };

export interface FieldSelectorOptions {
  selectedChartField?: ChartField;
  updateOptionFocus?: Function;
}

@inject
export class FieldSelector {
  public fields: ChartCategory[] = [];
  public searchTerm = "";
  public selectedChartField: ChartField = null;
  public updateOptionFocus: Function = () => {};

  constructor(
    private controller: PopoverController<ChartField>,
    private collaborationModulesStore: CollaborationModulesStore,
    private dataDictionaryService: DataDictionaryService,
    private dataPolicy: DataPolicyService,
    private ffs: FeatureFlagService,
    private formStore: FormStore,
    private phasesStore: EvaluationPhasesStore,
    private applicationSettingsStore: ApplicationSettingsStore,
  ) { }

  public async activate(options: FieldSelectorOptions) {
    if (this.ffs.isFeatureFlagEnabled("ElectiveIndexing")) {
      this.fields = await this.getPaths();
      this.logFeatureFlagDifferences(this.getPathsPreElectiveIndexingFF(), JSON.parse(JSON.stringify(this.fields)));
    } else {
      this.fields = this.getPathsPreElectiveIndexingFF();
    }
    this.selectedChartField = options?.selectedChartField;
    if (options?.updateOptionFocus) {
      this.updateOptionFocus = options?.updateOptionFocus;
    }
    if (this.selectedChartField) {
      this.updateOptionFocus(this.makeOptionId(this.selectedChartField));
    }
  }

  public makeOptionId(field: ChartField): string {
    const pathAsId = field?.Path?.replace(/\./g, "");
    return !!pathAsId ? `${pathAsId}-cfs` : null;
  }

  public selectField(field: ChartField) {
    this.selectedChartField = field;
    if (this.selectedChartField) {
      this.updateOptionFocus(this.makeOptionId(this.selectedChartField));
    }
    this.controller.ok(field);
  }

  public search(fields) {
    if (this.searchTerm && this.searchTerm.length) {
      return fields.filter(f => f.Label
        ? includes(f.Label.toLowerCase(), this.searchTerm.toLowerCase())
        : false);
    } else {
      return fields;
    }
  }

  private async getPaths(): Promise<ChartCategory[]> {
    const cats: ChartCategory[] = [];
    const restrictions = this.dataPolicy.applicationPropertyPaths;

    const fields = await this.dataDictionaryService.getAggregateFields(false);
    const groupedFieldsByCategory = groupBy(fields, f => f.Category);
    for (const category in groupedFieldsByCategory) {
      const cat: ChartCategory = { category: category, paths: [] };

      for (const field of groupedFieldsByCategory[category]) {
        if (field.IndexStatus === DataDictionaryIndexStatus.Unavailable) {
          continue;
        }

        if (category === DataDictionaryFieldCategory.Application && restrictions.includes(field.Path)) {
          continue;
        }

        let aggType: AggregationType;
        switch (field.AggregationType) {
          case DataDictionaryAggregationType.None:
            continue;
          case DataDictionaryAggregationType.Histogram:
            aggType = this.dataDictionaryService.isDateField(field) ? AggregationType.Dates : AggregationType.Histogram;
            break;
          case DataDictionaryAggregationType.Terms:
            aggType = AggregationType.Terms;
            break;
          default:
            continue;
        }
        
        cat.paths.push({
          Label: field.Label,
          LabelTransform: field.LabelTransform,
          Path: field.Path,
          Type: aggType,
          HasOptionValues: field.Values?.length > 0
        });
      }

      if (cat.paths.length > 0)
        cats.push(cat);
    }

    return cats;
  }

  private get applicationProperties() {
    const {
      ApplicationProperties,
    } = this.applicationSettingsStore.state.applicationSettings;
    return ApplicationProperties && ApplicationProperties.length > 0 ? ApplicationProperties : [];
  }

  private getPathsPreElectiveIndexingFF(): ChartCategory[] {
    const cats: ChartCategory[] = [];

    const calcCat: ChartCategory = {
      category: "Application Calculated Fields",
      paths: [],
    };

    const applicationProperties = this.applicationProperties;
    const restrictions = this.dataPolicy.applicationPropertyPaths;
    for (let i = 0; i < applicationProperties.length; i++) {
      if (restrictions.indexOf(applicationProperties[i].Key) !== -1)
        continue;

      const calcField = applicationProperties[i];

      let LabelTransform =null;

      switch (calcField.DataType){
        case CalculatedFieldDataTypeCode.Boolean:
          LabelTransform="boolean"
          break;
        case CalculatedFieldDataTypeCode.Date:
          LabelTransform="date"
          break;
        default :
          LabelTransform=null
          break;
      }

      calcCat.paths.push({
        Label: calcField.Label,
        LabelTransform,
        Path: `${calcField.Key}`,
        Type:
          calcField.DataType == CalculatedFieldDataTypeCode.Number
            ? AggregationType.Histogram
            : AggregationType.Terms,
      });
    }

    if (calcCat.paths && calcCat.paths.length > 0) cats.push(calcCat);

    // form questions
    const { forms } = this.formStore.state;

    for (let form of forms) {

      if (form.MetaData.Type != FormTypeCode.Applicant) continue;

      const cat: ChartCategory = { category: `Form: ${form.Name}`, paths: [] };

      const questionsAvail = allQuestions(form)
        .filter(
          q =>
            q.Type == QuestionType.CheckBoxList ||
            q.Type == QuestionType.DropDown ||
            q.Type == QuestionType.RadioButton ||
            q.Type == QuestionType.Number ||
            q.Type == QuestionType.Address ||
            q.Type == QuestionType.CEEBCode ||
            q.Type == QuestionType.Scale ||
            q.Type == QuestionType.ScaleGroup,
        );
      if (questionsAvail.length > 0) {
        for (let q of questionsAvail) {
          const label = q.Label || q.Key;

          if (q.Type == QuestionType.Address) {
            cat.paths.push({
              Label: `${label} - Country`,
              Path: `forms.${form.Key}.${q.Key}.country`,
              Type: AggregationType.Terms,
            });
            cat.paths.push({
              Label: `${label} - State/Region`,
              Path: `forms.${form.Key}.${q.Key}.region`,
              Type: AggregationType.Terms,
            });
          } else if (q.Type == QuestionType.CEEBCode) {
            cat.paths.push({
              Label: `${label} - Code`,
              Path: `forms.${form.Key}.${q.Key}.code`,
              Type: AggregationType.Terms,
            });
            cat.paths.push({
              Label: `${label} - Name`,
              Path: `forms.${form.Key}.${q.Key}.name`,
              Type: AggregationType.Terms,
            });
            cat.paths.push({
              Label: `${label} - Country`,
              Path: `forms.${form.Key}.${q.Key}.country`,
              Type: AggregationType.Terms,
            });
            cat.paths.push({
              Label: `${label} - State/Region`,
              Path: `forms.${form.Key}.${q.Key}.region`,
              Type: AggregationType.Terms,
            });
            cat.paths.push({
              Label: `${label} - City`,
              Path: `forms.${form.Key}.${q.Key}.city`,
              Type: AggregationType.Terms,
            });
          } else if (q.Type == QuestionType.ScaleGroup) {
            q.Options.ScaleGroup.Items.forEach(i => {
              cat.paths.push({
                Label: `${label} - ${i.Label}`,
                Path: `forms.${form.Key}.${q.Key}.${i.Key}`,
                Type: AggregationType.Terms,
              });
            });
            cat.paths.push({
              Label: label + " - Sum",
              Path: `forms.${form.Key}.${q.Key}.sum`,
              Type: AggregationType.Terms,
            });
            cat.paths.push({
              Label: label + " - Average",
              Path: `forms.${form.Key}.${q.Key}.average`,
              Type: AggregationType.Histogram,
            });
          } else {
            cat.paths.push({
              Label: label,
              Path: `forms.${form.Key}.${q.Key}`,
              Type:
                q.Type == QuestionType.Number
                  ? AggregationType.Histogram
                  : AggregationType.Terms,
            });
          }
        }
      }

      for (let calcField of form.CalculatedFields) {
        const LabelTransform =
          calcField.DataType == CalculatedFieldDataTypeCode.Boolean
            ? "boolean"
            : null;

        cat.paths.push({
          Label: calcField.Label,
          Path: `forms.${form.Key}.${calcField.Key}`,
          LabelTransform,
          Type:
            calcField.DataType == CalculatedFieldDataTypeCode.Number
              ? AggregationType.Histogram
              : AggregationType.Terms,
        });
      }

      if (cat.paths.length > 0) cats.push(cat);
    }

    for (let phase of this.phasesStore.state.phases) {
      const paths = [];

      paths.push({
        Label: "Assigned Team",
        Path: `phases.${phase.Key}.assigned_team_id`,
        Type: AggregationType.Terms,
        LabelTransform: "team",
      });
      paths.push({
        Label: "Assigned Users",
        Path: `phases.${phase.Key}.assigned_users`,
        Type: AggregationType.Terms,
        LabelTransform: "username",
      });

      if (phase.FormId != null) {
        paths.push({
          Label: "Completed Reviews",
          Path: `phases.${phase.Key}.evals_completed`,
          Type: AggregationType.Terms,
        });
        paths.push({
          Label: "Reviewed By",
          Path: `phases.${phase.Key}.evals_completed_by`,
          Type: AggregationType.Terms,
          LabelTransform: "username",
        });
      }

      phase.CalculatedFields.forEach(cf => {
        const Path = `phases.${phase.Key}.${cf.Key}`;
        const LabelTransform =
          cf.DataType == CalculatedFieldDataTypeCode.Boolean ? "boolean" : null;

        paths.push({
          Label: cf.Label,
          Path,
          Type: AggregationType.Histogram,
          LabelTransform,
        });
      });

      if (paths.length > 0) {
        cats.push({
          category: `Phase: ${phase.Name}`,
          paths,
        });
      }
    }

    for (const collabModule of this.collaborationModulesStore.state.modules) {
      const paths = [];

      paths.push({
        Label: "Assigned Team",
        Path: `collaborations.forms.${collabModule.Key}.assigned_team_id`,
        Type: AggregationType.Terms,
        LabelTransform: "team",
      });
      paths.push({
        Label: "Assigned Users",
        Path: `collaborations.forms.${collabModule.Key}.assigned_users`,
        Type: AggregationType.Terms,
        LabelTransform: "username",
      });

      paths.push({
        Label: "Completed Reviews",
        Path: `collaborations.forms.${collabModule.Key}.evals_completed`,
        Type: AggregationType.Terms,
      });
      paths.push({
        Label: "Reviewed By",
        Path: `collaborations.forms.${collabModule.Key}.evals_completed_by`,
        Type: AggregationType.Terms,
        LabelTransform: "username",
      });

      if (paths.length > 0) {
        cats.push({
          category: `Collaboration Module: ${collabModule.Name}`,
          paths,
        });
      }
    }

    const application_cat: ChartCategory = {
      category: `Application:`,
      paths: [],
    };
    application_cat.paths.push({
      Label: `Start Date`,
      Path: `application.metaData.dateStartedUTC`,
      Type: AggregationType.Dates,
    });
    application_cat.paths.push({
      Label: `Submit Date`,
      Path: `application.metaData.dateSubmittedUTC`,
      Type: AggregationType.Dates,
    });
    application_cat.paths.push({
      Label: `Phase`,
      Path: `phase`,
      Type: AggregationType.Terms,
    });
    application_cat.paths.push({
      Label: `Stage`,
      Path: `stage`,
      Type: AggregationType.Terms,
    });

    if (this.ffs.isFeatureFlagEnabled("ApplicationOrigin")) {
      application_cat.paths.push({
        Label: `Origin`,
        Path: `origin`,
        Type: AggregationType.Terms,
      });
    }

    application_cat.paths.push({
      Label: `Decision`,
      Path: `decision`,
      Type: AggregationType.Terms,
    });

    application_cat.paths.push({
      Label: `Tags`,
      Path: `tags`,
      Type: AggregationType.Terms,
    });

    application_cat.paths.push({
      Label: `# Comments`,
      Path: `commentCount`,
      Type: AggregationType.Histogram,
    });

    cats.push(application_cat);
    return cats;
  }

  // TODO: Remove when ElectiveIndexing is removed.
  private logFeatureFlagDifferences(original: ChartCategory[], modified: ChartCategory[]) {
    const added = modified.filter(m => !original.find(o => o.category === m.category));
    const removed = original.filter(o => !modified.find(m => m.category === o.category));

    const intersection = modified.filter(c => original.find(o => o.category === c.category));
    let differences = intersection.map(mod => {
      const orig = original.find(o => o.category === mod.category);

      return {
        category: mod.category,
        ...this.getPathDifferences(orig.paths, mod.paths)
      };
    });

    const unchanged = differences.filter(d => d.added.length === 0 && d.removed.length === 0 && d.differences.length === 0)
    differences = differences.filter(d => d.added.length > 0 || d.removed.length > 0 || d.differences.length > 0);

    if (added.length === 0 && removed.length === 0 && differences.length === 0) {
      console.log({ message: "No category differences", unchanged });
    } else {
      console.log({ message: "Category differences", added, removed, unchanged, differences });
    }
  }

  // TODO: Remove when ElectiveIndexing is removed.
  private getPathDifferences(original: ChartField[], modified: ChartField[]): { added: ChartField[], removed: ChartField[], unchanged: ChartField[], differences: { path: string, differences: Operation[], original: ChartField, modified: ChartField }[] } {
    const differences = modified
      .filter(c => original.find(o => o.Path === c.Path))
      .map(mod => {
        const orig = original.find(o => o.Path === mod.Path);
        return {
          path: mod.Path,
          differences: compare(orig, mod),
          original: orig,
          modified: mod,
        };
      }
      );

    return {
      added: modified.filter(c => !original.find(o => o.Path === c.Path)),
      removed: original.filter(o => !modified.find(m => m.Path === o.Path)),
      unchanged: differences.filter(d => d.differences.length === 0).map(d => d.modified),
      differences: differences.filter(d => d.differences.length > 0)
    };
  }
}
