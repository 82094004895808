import { inject, prop } from "fw";
import { hashOn } from "hashing";
import moment from "moment";

import { PopoverService } from "service/popover";
import { IListResults } from "network/ats";
import { CalendarEventSeriesRepository } from "network/calendar-event-series-repository";
import { CalendarEventRepository } from "network/calendar-event-repository";
import { CurrentOrganizationStore } from "state/current-organization";
import { ContactsEventFilter as ContactsEventFilterModel } from "models/contacts-filters";
import { CalendarEvent, CalendarEventSeries, CalendarEventSeriesInfo, DropdownOptionType } from "models/calendar-event";
import { EventSeriesPopover, EventSeriesPopoverOptions } from "views/calendar/event/components/event-series-popover";
import { EventOccurrencePopover, EventOccurrencePopoverOptions } from "views/calendar/event/components/event-occurrence-popover";

const dateFormat: string = "MMM D, YYYY [at] h:mm a";

@inject
export class ContactsEventFilter {
  @prop(null) public filter!: ContactsEventFilterModel;
  @prop(() => ({})) public validation;
  @prop(false) public editing!: boolean;

  private lastSeries: string = null;
  private selectedSeries: CalendarEventSeries = null;
  private selectedOccurrence: CalendarEvent = null;
  private seriesList: IListResults<CalendarEventSeries> = null;
  private occurrences: CalendarEventSeriesInfo = null;

  constructor(
    private currentOrganizationStore: CurrentOrganizationStore,
    private calendarEventSeriesRepo: CalendarEventSeriesRepository,
    private calendarEventRepo: CalendarEventRepository,
    private popoverService: PopoverService,
  ) { }

  public async attached() {
    if (this.filter.series) {
      this.selectedSeries = await this.calendarEventSeriesRepo.get(this.filter.series);

      if (this.filter.occurrence && this.filter.occurrence != "Any") {
        this.selectedOccurrence = await this.calendarEventRepo.get(this.filter.series, this.filter.occurrence);
      }
    }
  }

  public get statusOptions(): DropdownOptionType[] {
    return [
      { text: "All", value: "All" },
      { text: "None", value: "None" },
      { text: "Attended", value: "Attended" },
      { text: "Cancelled", value: "Cancelled" },
      { text: "Registered", value: "Registered" },
      { text: "Waitlisted", value: "Waitlisted" },
    ];
  }

  public get seriesOptions(): DropdownOptionType[] {
    return this.seriesList?.results.map(s => (
      <DropdownOptionType>{
        text: s.display_name,
        value: s.id
      }
    ));
  }

  private get timezone(): string {
    return this.currentOrganizationStore.state.organization.Timezone;
  }

  public get occurrenceOptions(): DropdownOptionType[] {
    if (!this.filter.series) return [];

    if (this.filter.series != this.lastSeries) {
      this.lastSeries = this.filter.series;

      this.fetchOccurrencesBySeries();
    }

    let calendar_event_infos = this.occurrences?.calendar_event_infos?.map(e => (
      <DropdownOptionType>{
        text: moment.tz(e.from_utc, this.timezone).format(dateFormat),
        value: e.instance_key
      }
    ));
    calendar_event_infos = calendar_event_infos ? calendar_event_infos : [];

    return [
      { text: "Any", value: "Any" },
      ...calendar_event_infos
    ];
  }

  private async fetchOccurrencesBySeries() {
    if (!this.filter.series) return null;

    this.occurrences = await this.calendarEventSeriesRepo.getInfo(this.filter.series);
  }

  public get seriesName() {
    return this.selectedSeries?.display_name;
  }

  public get occurrenceName() {
    if (this.filter.occurrence == "Any") return this.filter.occurrence;

    if (!this.selectedOccurrence) return null;

    return moment.tz(this.selectedOccurrence.from_utc, this.timezone).format(dateFormat);
  }

  public get occurrenceNameHash() {
    return hashOn(this.occurrenceOptions, s => `${s.value}`, s => s.text);
  }

  public async selectEventSeries() {
    const options = <EventSeriesPopoverOptions>{
      sort: "display_name ASC",
    }

    const res = await this.popoverService.open<CalendarEventSeries>(EventSeriesPopover, options)

    if (res.canceled) return;

    this.selectedSeries = <CalendarEventSeries>res.result;
    this.filter.series = this.selectedSeries.id;
    this.selectedOccurrence = null;
    this.filter.occurrence = "Any";
  }

  public async selectEventOccurrence() {
    const options = <EventOccurrencePopoverOptions>{
      series: this.filter.series,
      sort: "display_name ASC",
    }

    const res = await this.popoverService.open<CalendarEvent | string>(EventOccurrencePopover, options)

    if (res.canceled) return;

    if (res.result == "Any") {
      this.selectedOccurrence = null;
      this.filter.occurrence = res.result;
    } else {
      this.selectedOccurrence = <CalendarEvent>res.result;
      this.filter.occurrence = this.selectedOccurrence.instance_key;
    }
  }
}
