import { formFor, FormForType, Validators } from "fw-model";

import { applicationPropertyFormCreator } from "./application-settings";
import { ApplicantSettings } from "models/applicant-settings";

export class UpdateApplicantSettingsAction {
  constructor(public form: ApplicantSettingsForm) {}
}

export type ApplicantSettingsForm = FormForType<ApplicantSettings>;

export const applicantSettingsFormCreator = formFor(ApplicantSettings, s => {
  s.formArray(
    s => s.ApplicantProperties,
    "Applicant Properties",
    applicationPropertyFormCreator,
  );
});
