import { prop, inject } from "fw";
import { ContactsAddressFilter as ContactsAddressFilterModel } from "models/contacts-filters";

@inject
export class ContactsAddressFilter {
  @prop(null) filter!: ContactsAddressFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  get isValidAddress(): boolean {
    if (this.filter.country || this.filter.state || this.filter.city || this.filter.postalCode) {
      return true;
    }
    return false;
  }
}
