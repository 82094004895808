import { ContainerInstance } from "fw";
import { Validators, FormForType, formFor } from "fw-model";
const { wrap, required, isEmail } = Validators;

import { UserInvitation, UserInviteArgs } from "models/user";
import { FeatureFlagService } from "service/feature-flag";

export type FormForUserInvite = FormForType<UserInvitation>;
export type FormForUserInviteArgs = FormForType<UserInviteArgs>;

const validEmailList = (input: any): string => {
  if (input == null || input.length == 0) return "Required";
  for (const email of input) {
    const res = isEmail(email);
    if (res != null) return res;
  }

  return null;
};

export const userInviteArgsFormCreator = formFor(UserInviteArgs, s => {
  s.requiredField(i => i.EmailAddresses, "Email Address", wrap(validEmailList));
  s.field(i => i.RoleId, "Role", v => {
    const teamsFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("CollabTeams");

    if (!teamsFF) {
      v.if(
        u => u.CollaboratorRoleIds == null || u.CollaboratorRoleIds.length == 0,
        required,
      );
    } else {
      v.if(
        u => u.CollaboratorTeamIds == null || u.CollaboratorTeamIds.length == 0,
        required,
      );
    }
  });

  s.field(i => i.CollaboratorRoleIds, "Role", v => {
    const teamsFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("CollabTeams");

    if (!teamsFF) {
      v.if(u => u.RoleId == null || u.RoleId.trim().length == 0, required);
    }
  });

  s.field(i => i.CollaboratorTeamIds, "Team", v => {
    const teamsFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("CollabTeams");

    if (teamsFF) {
      v.if(u => u.RoleId == null || u.RoleId.trim().length == 0, required);
    }
  });
});

export const userInvitationFormCreator = formFor(UserInvitation, s => {
  s.requiredField(i => i.EmailAddress, "Email Address", wrap(isEmail));
  s.field(i => i.RoleId, "Role", v => {
    const teamsFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("CollabTeams");

    if (!teamsFF) {
      v.if(
        u => u.CollaboratorRoleIds == null || u.CollaboratorRoleIds.length == 0,
        required,
      );
    } else {
      v.if(
        u => u.CollaboratorTeamIds == null || u.CollaboratorTeamIds.length == 0,
        required,
      );
    }
  });

  s.field(i => i.CollaboratorRoleIds, "Role", v => {
    const teamsFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("CollabTeams");

    if (!teamsFF) {
      v.if(u => u.RoleId == null || u.RoleId.trim().length == 0, required);
    }
  });

  s.field(i => i.CollaboratorTeamIds, "Team", v => {
    const teamsFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("CollabTeams");

    if (teamsFF) {
      v.if(u => u.RoleId == null || u.RoleId.trim().length == 0, required);
    }
  });
});

export class ResendInvitesAction {
  constructor(public inviteIds: string[]) {}
}

export class UpdateInviteRoleAction {
  constructor(public form: FormForUserInvite) {}
}

export class AddInviteAction {
  constructor(public form: FormForUserInviteArgs) {}
}

export class DeleteInvitesAction {
  constructor(public inviteIds: string[]) {}
}
