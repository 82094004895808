import { Filter, GroupFilter, createFilter } from "models/filter-setup";
import {
  AssignedUserFilter,
  EvaluatedByFilter,
  EvaluationCompleteFilter,
  ProgramFilter,
  SegmentFilter,
  PhaseFilter,
  PhaseFilterWithCategories,
  ErrorsFilter,
  FormFieldFilter,
  DecisionFilter,
  ApplicationPropertyFilter, MyIncompletedEvaluationsFilter, MyCompletedEvaluationsFilter,
} from "models/application-filters";

export const putInGroup = (filters: Filter[] | GroupFilter): GroupFilter => {
  const f = new GroupFilter();
  f.operation = "AND";

  if (filters instanceof GroupFilter) {
    f.filters.push({ type: "group-filter", data: filters });
  } else {
    f.filters.push(createFilter(GroupFilter, s => {
      s.filters = filters;
    }));
  }

  return f;
};

export const applicationPropertyFilter = (
  key: string,
  value: any,
  operator = "=",
  shouldQuoteValue = false,
) => {
  return createFilter(ApplicationPropertyFilter, s => {
    s.field = key;
    s.operator = operator;
    s.shouldQuoteValue = shouldQuoteValue;
    s.value = value;
  });
}

export const prependErrorsFilter = (groupFilter: GroupFilter): GroupFilter => {
  const f = new GroupFilter();
  f.operation = "AND";

  f.filters.push(createFilter(ErrorsFilter, s => s.hasErrors = true));

  if (groupFilter.filters.length > 0)
    f.filters.push({ type: "group-filter", data: groupFilter });

  return f;
};

export const phaseFilter = (phaseIds: string[]): GroupFilter => {
  const f = new GroupFilter();
  f.operation = "OR";

  phaseIds.forEach(phaseId => {
    f.filters.push(createFilter(PhaseFilter, s => {
      s.phaseId = phaseId;
    }));
  });

  return f;
};

export const phaseFilterWithCategories = (phaseIds: string[]): GroupFilter => {
  const f = new GroupFilter();
  f.operation = "OR";

  phaseIds.forEach(phaseId => {
    f.filters.push(createFilter(PhaseFilterWithCategories, s => {
      s.phaseId = phaseId;
    }));
  });

  return f;
};

export const decisionFilter = (decisionIds: string[]): GroupFilter => {
  const f = new GroupFilter();
  f.operation = "OR";

  decisionIds.forEach(decisionId => {
    f.filters.push(createFilter(DecisionFilter, s => {
      s.decisionId = decisionId;
    }));
  });

  return f;
};

export const teamFilter = (
  teamId: string,
  completed: boolean = null,
  phaseKey = "current",
  programId: string = null,
  segmentId: string = null,
): GroupFilter => {
  const f = new GroupFilter();

  const assignmentFilter = createFilter(AssignedUserFilter, s => {
    s.assignmentType = "team";
    s.phaseKey = phaseKey;
    s.teamId = teamId;
  });

  f.filters.push(assignmentFilter);

  if (completed != null) {
    const evaluationCompleteFilter = createFilter(EvaluationCompleteFilter, s => {
      s.complete = completed;
      s.phaseKey = phaseKey;
    });

    f.filters.push(evaluationCompleteFilter);
  }

  if (programId != null) {
    const programFilter = createFilter(ProgramFilter, s => {
      s.programId = programId;
    });

    f.filters.push(programFilter);
  }

  if (segmentId != null) {
    const segmentFilter = createFilter(SegmentFilter, s => {
      s.segmentId = segmentId;
    });

    f.filters.push(segmentFilter);
  }

  return f;
};

export const fullApplicationReviewFilter = (
  userId: string,
  phaseKey: string = "current",
  teamId: string = null
): GroupFilter => {
  const f = new GroupFilter();

  const assignedUserFilter = createFilter(AssignedUserFilter, s => {
    s.assignmentType = "user";
    s.phaseKey = phaseKey;
    s.teamId = teamId;
    s.userId = userId;
  });

  f.filters.push(assignedUserFilter);

  const myIncompleteEvaluations = createFilter(MyIncompletedEvaluationsFilter, s => {
    s.phaseKey = phaseKey;
    s.userId = userId;
  });

  f.filters.push(myIncompleteEvaluations);

  return f;
}

export const assignedApplicationReviewFilter = (
  userId: string,
  phaseKey: string = "current"
): GroupFilter => {
  const f = new GroupFilter();

  const myIncompleteEvaluations = createFilter(MyIncompletedEvaluationsFilter, s => {
    s.phaseKey = phaseKey;
    s.userId = userId;
  });

  f.filters.push(myIncompleteEvaluations);

  return f;
}

export const reviewedApplicationReviewFilter = (
  userId: string,
  phaseKey: string = "current"
): GroupFilter => {
  const f = new GroupFilter();

  const myCompleteEvaluations = createFilter(MyCompletedEvaluationsFilter, s => {
    s.phaseKey = phaseKey;
    s.userId = userId;
  });

  f.filters.push(myCompleteEvaluations);

  return f;
}

export const userFilter = (
  userId: string,
  completed: boolean = null,
  phaseKey = "current",
  programId: string = null,
  segmentId: string = null,
): GroupFilter => {
  const f = new GroupFilter();

  const assignmentFilter = createFilter(AssignedUserFilter, s => {
    s.assignmentType = "user";
    s.phaseKey = phaseKey;
    s.userId = userId;
  });

  f.filters.push(assignmentFilter);

  if (completed != null) {
    const evaluationCompleteFilter = createFilter(EvaluatedByFilter, s => {
      s.phaseKey = phaseKey;
      s.userId = userId;
      s.userCompletedEvaluation = completed;
    });

    f.filters.push(evaluationCompleteFilter);
  }

  if (programId != null) {
    const programFilter = createFilter(ProgramFilter, s => {
      s.programId = programId;
    });

    f.filters.push(programFilter);
  }

  if (segmentId != null) {
    const segmentFilter = createFilter(SegmentFilter, s => {
      s.segmentId = segmentId;
    });

    f.filters.push(segmentFilter);
  }

  return f;
};

export const formFieldFilter = (
  formKey: string,
  sectionKey: string,
  field: string,
  value: string = null,
  dataFields: { [key: string]: { value: string; op: string } } = null,
  operator = "=",
  shouldQuoteValue = true,
): GroupFilter => {
  const f = new GroupFilter();

  f.filters.push(createFilter(FormFieldFilter, s => {
    s.formKey = formKey;
    s.sectionKey = sectionKey;
    s.field = field;
    s.value = value;
    s.dataFields = dataFields;
    s.operator = operator;
    s.shouldQuoteValue = shouldQuoteValue;
  }));

  return f;
};

export const ceebFilter = (
  formKey: string,
  field: string,
  data_name: string,
  data_value: string,
  operator: string = "=",
): GroupFilter => {
  let data_fields = {};

  ['code', 'name', 'region', 'city', 'postalCode', 'country'].forEach(i => {
    data_fields[i] = {'value': '', 'op': "="};
  });

  data_fields[data_name] = {'value': data_value, 'op': operator};
  return formFieldFilter(formKey, null, field, null, data_fields, operator);
};
