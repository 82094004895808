import { prop } from "fw";

export class SearchNoResultsRow {
  @prop("") public plural: string;
  @prop(0) public colspan: number;
  @prop(false) public isSearching: boolean;
  @prop(null) public clearFilters: () => Promise<void>;

  public clear() {
    if (this.clearFilters) this.clearFilters();
  }
}
