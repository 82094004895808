
import { Validators } from "fw-model";
import type { ICustomFieldDefinition } from "../../../models/contact-organization";
const { required } = Validators;

export const isValidDisplayName = (fields: ICustomFieldDefinition[], field: ICustomFieldDefinition) => {
  let validation = required(field.display_name);
  if (validation) {
    return validation;
  }

  if (field.display_name.replace(/[^0-9a-z]/gi, "") === "") {
    return "Must contain alphanumeric characters.";
  }

  const duplicate = fields.find(f => (f.display_name === field.display_name || f.name === field.name) && f.id !== field.id);
  if (duplicate) {
    return "Duplicate field name.";
  }

  return null;
};

export const isValidOptions = (field: ICustomFieldDefinition) => {
  const allowWriteIn: boolean = field && field.data && field.data.automatically_add_values;
  if (!field.options || field.options.length === 0) {
    return allowWriteIn ? null : "Option value(s) required.";
  }

  const optionNames: string[] = field.options.reduce((names: string[], option) => {
    names.push(option.name, ...(option.aliases || []).map(a => a.name));
    return names;
  }, []);

  const distinctNames = optionNames.map(n => n.toLocaleLowerCase()).filter((v, i, o) => o.indexOf(v) === i);
  if (optionNames.length !== distinctNames.length) {
    return "Option value duplicated.";
  }

  return null;
};

export const hasValidMaxTagDepth = (tag: string, maximumTagHierarchyDepth: number): boolean => {
  if (!tag) {
    return true;
  }

  const parts: string[] = tag.split("/");
  if (parts.length > maximumTagHierarchyDepth) {
    return false;
  }

  return true;
}

export const isValidTag = (tag: string, maximumTagHierarchyDepth: number): boolean => {
  if (!tag) {
    return false;
  }

  const parts: string[] = tag.split("/");
  if (parts.length > maximumTagHierarchyDepth) {
    return false;
  }

  const pattern = new RegExp(`^[^\/\\*\\r\\n\\t\\f\\v]*$`, "i");
  const invalidParts = parts.filter(n => n.trim() !== n || !pattern.test(n));
  return invalidParts.length === 0;
}

export const isValidTags = (field: ICustomFieldDefinition): string|null => {
  const allowWriteIn: boolean = field && field.data && field.data.automatically_add_values;
  if (!field.options || field.options.length === 0) {
    return allowWriteIn ? null : "Tag value(s) required.";
  }

  const optionNames: string[] = field.options.reduce((names: string[], option) => {
    names.push(option.name, ...(option.aliases || []).map(a => a.name));
    return names;
  }, []);

  const distinctNames = optionNames.map(n => n.toLocaleLowerCase()).filter((v, i, o) => o.indexOf(v) === i);
  if (optionNames.length !== distinctNames.length) {
    return "Tag value duplicated.";
  }

  let maxDepth: number = parseInt(field.data && field.data.maximum_tag_hierarchy_depth);
  if (isNaN(maxDepth) || maxDepth < 1) {
    maxDepth = 1;
  }

  const invalidNames = optionNames.filter(n => !isValidTag(n, maxDepth));
  if (invalidNames.length > 0) {
    if (invalidNames.filter(n => !hasValidMaxTagDepth(n, maxDepth)).length > 0) {
      return `Tag value(s) '${invalidNames.join("', '")}' are invalid. Must not contain * characters or start with or end with whitespace characters. Depth must not exceed ${maxDepth} levels.`;
    }

    return `Tag value(s) '${invalidNames.join("', '")}' are invalid. Must not contain * characters or start with or end with whitespace characters.`;
  }

  return null;
};
