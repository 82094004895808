import { ComponentEventBus, inject, prop } from "@derekpitt/fw";
import {
  AsYouType,
  CountryCode,
  getCountryCallingCode,
} from "libphonenumber-js";
import Vue from "vue";
import CountryFlag from "vue-country-flag";

import { FormAnswer, Question } from "../../models";
import { countries } from "../../../../countries/index";

const DEFAULT_COUNTRY: CountryCode = "US";

@inject
export class PhoneType {
  @prop(null) answer!: FormAnswer;
  @prop(null) ariaLabelledby;
  @prop(false) compact!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(null) question!: Question;
  @prop(false) readonly!: boolean;

  public countryCallingCode: string = '';
  public dataId: string = '';
  public previousCountryCallingCode: string = '';
  public selectedCountry: CountryCode = null;
  public validation: string = '';

  constructor(
    private ceb: ComponentEventBus,
  ) {}

  public created() {
    Vue.use(CountryFlag);
  }

  public attached() {
    this.dataId = this.question?.Key;

    if (this.answer?.Text) {
      this.setCountryFromPhone(this.answer.Text);
    }
  }

  public set value(value: string) {
    this.answer.Text = value;
    this.ceb.dispatch("answer-changed", this.answer?.Text?.trim());
  }

  public get value(): string {
    return this.answer?.Text;
  }

  public get disabled(): boolean {
    return this.readonly;
  }

  private get countries() {
    let options = [];
    for (const countryKey in countries) {
      options.push({
        value: countryKey,
        text: countries[countryKey],
        dataId: countryKey,
      });
    }

    return options;
  }

  public setCountry(selectedCountry: CountryCode) {
    this.selectedCountry = selectedCountry;
    this.previousCountryCallingCode = this.countryCallingCode;
    if (!!this.selectedCountry) {
      this.countryCallingCode = getCountryCallingCode(this.selectedCountry);
    } else {
      this.countryCallingCode = '';
    }
  }

  private setCountryFromPhone(phoneNumber: string) {
    const dynamicPhoneType = new AsYouType();
    dynamicPhoneType.input(phoneNumber);

    const derivedCountry = dynamicPhoneType.getCountry();
    if (!!derivedCountry && (this.selectedCountry !== derivedCountry)) {
      this.setCountry(derivedCountry);
    }
  }

  public onKeyUp() {
    if (this.value) {
      if (-1 === this.value.indexOf('+')) {
        this.prependCountryCallingCode(DEFAULT_COUNTRY);
      }
      this.setCountryFromPhone(this.value);
    }

    this.ceb.dispatch("keyup");
  }

  public clear() {
    this.answer.Text = '';
    this.setCountry(null);
    this.ceb.dispatch("answer-changed", this.answer.Text);
  }

  public prependCountryCallingCode(selectedCountry: CountryCode) {
    this.setCountry(selectedCountry);

    let phoneNumber: string = this.answer?.Text || '';
    if (!!phoneNumber) {
      // improve ux: remove user populated country code if exists (before adding it back)
      const countryCodeRegex = new RegExp(`^\\+?${this.countryCallingCode}`);
      phoneNumber = phoneNumber.replace(countryCodeRegex, '');

      // improve ux: remove previous user populated country code if exists (before adding it back)
      if (!!this.previousCountryCallingCode) {
        const previousCountryCodeRegex = new RegExp(`^\\+?${this.previousCountryCallingCode}`);
        phoneNumber = phoneNumber.replace(previousCountryCodeRegex, '');
      }

      // improve ux: remove `+` if exists (before adding it back)
      phoneNumber = phoneNumber.replace(/\+/g, '');
    }

    if (!!this.selectedCountry) {
      this.answer.Text = `+${this.countryCallingCode}${phoneNumber}`;
    } else {
      const defaultCountryCallingCode = getCountryCallingCode(DEFAULT_COUNTRY);
      this.answer.Text = `+${defaultCountryCallingCode}${phoneNumber}`;
    }
    this.ceb.dispatch("answer-changed", this.answer.Text);
  }
}
