import { Validators, FormForType, formFor } from "fw-model";
const { required, isInteger, isUrl, wrap } = Validators;
import { noNewLines } from "./validators";

import {
  FileProvider,
  WebhookDeliveryContext,
  ProviderType,
  S3DeliveryContext,
  SftpDeliveryContext,
  EncryptedValuePair,
  HeaderData,
} from "models/file-provider";

export type FileProviderFormType = FormForType<FileProvider>;
export type WebhookDeliveryContextFormType = FormForType<
  WebhookDeliveryContext
>;

export interface FileProviderValidationSettings {
  requireSensitive: boolean;
  type: ProviderType;
}

export class UpdateFileProviderAction {
  constructor(public form: FileProviderFormType) { }
}

export class AddFileProviderAction {
  constructor(public form: FileProviderFormType) { }
}

export class DeleteFileProvidersAction {
  constructor(public fileProviderIds: string[]) { }
}

export class DeleteFileProviderFolderAction {
  constructor(public id: string, public folder: string) { }
}

export const headerDataFromCreator = formFor(HeaderData, s => {
  s.requiredField(a => a.Header, "Header Key");
  s.form(a => a.Value, "Value", encryptedValuePairFormCreator);
});


const requiredIf = (requiredType: ProviderType, isSensitive = false) => {
  return (input, model, settings: FileProviderValidationSettings) => {
    if (settings.type == requiredType) {
      if (isSensitive && !settings.requireSensitive) return null;
      else return required(input);
    }

    return null;
  };
};

export const sftpDeliveryContextFormCreator = formFor(
  SftpDeliveryContext,
  s => {
    s.field(f => f.Host, "Host", wrap(requiredIf("sftp")));
    s.field(f => f.Port, "Port", wrap(isInteger));
    s.field(f => f.Username, "User Name", wrap(requiredIf("sftp")));
    s.field(f => f.Password, "Password", v => v.if(j => !j.SshPrivateKey, requiredIf("sftp", true)));
    s.field(f => f.SshPrivateKey, "SSH Private Key", v => v.if(j => !j.Password, requiredIf("sftp", true)));
  },
);

export const encryptedValuePairFormCreator = formFor(EncryptedValuePair, s => {
  s.field(f => f.PlainTextValue, "Value", wrap(noNewLines));
});

export const webhookDeliveryContextFormCreator = formFor(
  WebhookDeliveryContext,
  s => {
    s.field(f => f.BearerToken, "Bearer Token");
    s.field(f => f.Url, "URL", wrap(requiredIf("webhook")));
    s.field(f => f.Method, "Method", wrap(requiredIf("webhook")));
    s.formArray(f => f.Headers, "Headers", headerDataFromCreator);
  },
);

export const s3DeliveryContextFormCreator = formFor(
  S3DeliveryContext,
  s => {
    s.field(f => f.AccessKey, "Access Key", wrap(requiredIf("s3")));
    s.field(f => f.SecretKey, "Secret Key", wrap(requiredIf("s3", true)));
    s.field(f => f.Bucket, "Bucket", wrap(requiredIf("s3")));
    s.field(f => f.RootPath, "Root Path", wrap(isUrl({ allowedProtocols: [], requireProtocol: false, allowPort: false, allowPath: true })));
  },
);

export const fileProviderFormCreator = formFor(FileProvider, s => {
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.ProviderType, "Provider Type");
  s.field(f => f.VaultKeyId, "Vault Key");
  s.field(f => f.PgpPublicKey, "PGP Public Key");
  s.field(f => f.PgpPublicKeyId, "PGP Key Id");
  s.field(f => f.WorkspaceIds, "Workspaces");

  s.form(f => f.WebhookContext, "Webhook Context", webhookDeliveryContextFormCreator);
  s.form(f => f.SftpContext, "SFTP Context", sftpDeliveryContextFormCreator);
  s.form(f => f.S3Context, "S3 Context", s3DeliveryContextFormCreator);
});
