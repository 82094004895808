import { FormForType, formFor, Validators } from "fw-model";
import { MarketingOutreachTrack } from "models/marketing-outreach-track";
import { isValidDate, noNewLines } from "./validators";

const { wrap } = Validators;

export type FormForMarketingOutreachTrack = FormForType<MarketingOutreachTrack>;

export class CreateMarketingOutreachTrackAction {
  public created: MarketingOutreachTrack = null;
  constructor(public form: FormForMarketingOutreachTrack) {}
}

export class UpdateMarketingOutreachTrackAction {
  public updated: MarketingOutreachTrack = null;
  constructor(public form: FormForMarketingOutreachTrack) {}
}

export class CopyMarketingOutreachTrackAction {
  constructor(public id: number) {}
}

export class DeleteMarketingOutreachTrackAction {
  constructor(public id: number) {}
}

export const createMarketingOutreachTrackFormCreator = formFor(MarketingOutreachTrack, s => {
  s.requiredField(f => f.OutreachCampaignId, "Campaign");
  s.requiredField(f => f.Name, "Name");
  s.field(f => f.Description, "Description", wrap(noNewLines));
  s.requiredField(f => f.SegmentType, "Segment type");
  s.requiredField(f => f.SegmentId, "Segment");
  s.requiredField(f => f.ScheduleType, "Schedule type");
});

export const createMarketingOutreachTrackNewFormCreator = formFor(MarketingOutreachTrack, s => {
  s.requiredField(f => f.OutreachCampaignId, "Campaign");
  s.requiredField(f => f.Name, "Name");
  s.field(f => f.Description, "Description", wrap(noNewLines));
  s.requiredField(f => f.SegmentType, "Segment type");
  s.requiredField(f => f.ScheduleType, "Schedule type");
  s.requiredField(f => f.EndDate, "End Date", wrap((s: string) => s === '0000-00-00' ? null : isValidDate(s)));
  s.requiredField(f => f.StartDate, "Start Date", wrap((s: string) => s === '0000-00-00' ? null : isValidDate(s)));
  s.field(f => f.Archived, "Campaign Active / Inactive")
  s.field(f => f.Filter, "Filter")
});

export const updateMarketingOutreachTrackFormCreator = formFor(MarketingOutreachTrack, s => {
  s.requiredField(f => f.Id, "Id");
  s.requiredField(f => f.OutreachCampaignId, "Campaign");
  s.requiredField(f => f.Name, "Name");
  s.field(f => f.Description, "Description", wrap(noNewLines));
  s.requiredField(f => f.SegmentType, "Segment type");
  s.requiredField(f => f.SegmentId, "Segment");
  s.requiredField(f => f.ScheduleType, "Schedule type");
});

export const updateMarketingOutreachTrackNewFormCreator = formFor(MarketingOutreachTrack, s => {
  s.requiredField(f => f.Id, "Id");
  s.requiredField(f => f.OutreachCampaignId, "Campaign");
  s.requiredField(f => f.Name, "Name");
  s.field(f => f.Description, "Description", wrap(noNewLines));
  s.requiredField(f => f.SegmentType, "Segment type");
  s.field(f => f.SegmentId, "Segment Id") // legacy field that should be deleted after updating all legacy campaings
  s.requiredField(f => f.ScheduleType, "Schedule type");
  s.requiredField(f => f.EndDate, "End Date", wrap((s: string) => s === '0000-00-00' ? null : isValidDate(s)));
  s.requiredField(f => f.StartDate, "Start Date", wrap((s: string) => s === '0000-00-00' ? null : isValidDate(s)));
  s.field(f => f.Archived, "Campaign Active / Inactive")
  s.field(f => f.Filter, "Filter")
});
