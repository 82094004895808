import { inject, prop } from "fw";

import { ApplicationSettingsStore } from "state/application-settings";
import { ApplicationProperty } from "models/application-settings";
import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { ApplicantPropertyFilter as ApplicantPropertyFilterModel} from "models/application-filters";

@inject
export class ApplicantPropertyFilter {
  @prop(null) filter!: ApplicantPropertyFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  private fields: { text: string, value: string }[] = [];
  private fieldHash: { [key: string]: ApplicationProperty } = {};
  private ops = [
    { text: "=", value: "=" },
    { text: ">", value: ">" },
    { text: ">=", value: ">=" },
    { text: "<=", value: "<=" },
    { text: "<", value: "<" },
  ];

  private optionType: "number" | "string" | "enum" | "boolean" | "file" = "string";

  private enumOptions: { text: string, value: string }[] = [];

  constructor(private appSettingsStore: ApplicationSettingsStore) {
    this.setupFields();
  }

  attached() {
    this.fieldChanged(false);
  }

  private setupFields() {
    const newHash: { [key: string]: ApplicationProperty } = {};

    this.fields = [];
    this.appSettingsStore.state.applicantSettings.ApplicantProperties.forEach(field => {
      newHash[field.Key] = field;
      this.fields.push({ text: field.Label, value: field.Key });
    });

    this.fieldHash = newHash;
  }

  private fieldChanged(clearValue = true) {
    if (clearValue) {
      this.filter.value = null;
      this.filter.operator = "=";
    }

    if (this.filter.field == null) {
      this.optionType = "string";
      return;
    }

    switch (this.fieldHash[this.filter.field].DataType) {
      case CalculatedFieldDataTypeCode.Number:
      case CalculatedFieldDataTypeCode.WeightedScore:
        this.filter.shouldQuoteValue = false;
        this.optionType = "number";
        break;

      case CalculatedFieldDataTypeCode.Enumeration:
        this.filter.shouldQuoteValue = true;
        this.enumOptions = this.fieldHash[this.filter.field].EnumerationOptions.map(eo => ({ text: eo.Label, value: eo.Label }));
        this.optionType = "enum";
        break;

      case CalculatedFieldDataTypeCode.Boolean:
        this.filter.shouldQuoteValue = false;
        this.optionType = "boolean";
        break;

      case CalculatedFieldDataTypeCode.File:
        this.filter.shouldQuoteValue = false;
        this.filter.operator = "+";
        this.optionType = "file";
        break;

      default:
        this.filter.shouldQuoteValue = false;
        this.optionType = "string";
        break;
    }
  }
}
