import { inject, prop } from "fw";
import { dispatch } from "fw-state";

import { EvaluationPhasesStore } from "state/evaluation-phases";
import { UsersStore, EnsureUsersAction } from "state/users";
import { TeamsStore, EnsureTeamsAction } from "state/teams";
import { AssignedUserFilter as AssignedUserFilterModel } from "models/application-filters";
import { Team } from "models/team";
import { PopoverService } from "service/popover";
import { SelectUserPopover } from "views/components/select-user-popover";
import { SelectTeamPopover } from "../select-team-popover";

@inject
export class AssignedUserFilter {
  @prop(null)
  filter: AssignedUserFilterModel;

  @prop(() => ({}))
  validation;

  @prop(false)
  editing: boolean;

  constructor(
    private popover: PopoverService,
    private phaseStore: EvaluationPhasesStore,
    private teamsStore: TeamsStore,
    private usersStore: UsersStore
  ) {}

  get phases() {
    return [
      { text: "Current Phase", value: "current" },
      ...this.phaseStore.state.phases.map(p => ({ text: p.Name, value: p.Key })),
    ];
  }

  get phaseNameHash() {
    const hash: { [key: string]: string } = {
      current: "Current Phase",
    };

    this.phaseStore.state.phases.forEach(p => hash[p.Key] = p.Name);
    return hash;
  }

  attached() {
    dispatch(new EnsureUsersAction([ this.filter.userId ]));
    dispatch(new EnsureTeamsAction([ this.filter.teamId ]));
  }

  get selectedUser() {
    if (this.filter == null || this.filter.userId == null) return "Choose User";

    const u = this.usersStore.state.userHash[this.filter.userId];
    if (u == null) return "Loading...";

    return `${u.FirstName} ${u.LastName}`;
  }

  async selectUser() {
    const res = await this.popover.open<string>(SelectUserPopover);
    if (res.canceled) return;

    this.filter.userId = res.result;
    dispatch(new EnsureUsersAction([ res.result ]));
  }

  get selectedTeam() {
    if (this.filter == null || this.filter.teamId == null) return "Choose Team";

    const t = this.teamsStore.state.teamHash[this.filter.teamId];
    if (t == null) return "Loading...";

    return `${t.Name}`;
  }

  async selectTeam() {
    const res = await this.popover.open<Team>(SelectTeamPopover, {
      filter: "metaData.teamType:0"
    });
    if (res.canceled) return;

    this.filter.teamId = res.result.Id;
    dispatch(new EnsureTeamsAction([ res.result.Id ]));
  }
}
