import { inject, prop } from "fw";
import { Permissions } from "service/permission";

@inject
export class AnyOf {
  @prop(null) perms!: string[] | string;
  @prop("span") rootType!: string;
  @prop(null) features!: string[] | string;

  constructor(private permissions: Permissions) { }

  get shouldShow() {
    return this.permissions.any(this.perms, this.features);
  }
}
