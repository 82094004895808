import { inject } from "fw";
import { createFromArray } from "fw-model";

import { ATS } from "./ats";

import { DataDictionaryField } from "models/data-dictionary";

@inject
export class DataDictionaryRepository {
  constructor(private s: ATS) { }

  public async list(includeUsages: boolean = true) {
    const res = await this.s.get<DataDictionaryField[]>("data-dictionary", { includeUsages });
    return createFromArray(DataDictionaryField, res.body);
  }

  public async listTaskData() {
    const res = await this.s.get<any[]>("data-dictionary/task-data");
    return createFromArray(DataDictionaryField, res.body);
  }

  public async listUserData() {
    const res = await this.s.get<DataDictionaryField[]>("data-dictionary/user-data");
    return createFromArray(DataDictionaryField, res.body);
  }
}
