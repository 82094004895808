import { prop } from "fw";
import { Form } from "fw-model";

export class CheckboxFormField {
  @prop(null) private form!: Form;
  @prop(null) private field!: string;
  @prop(false) public disabled: boolean;

  private name: string = null;

  public attached() {
    this.formChanged();
  }

  private formChanged() {
    this.name = this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;
  }
}
