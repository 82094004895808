import { inject } from "fw";

import { DataDictionaryField } from "models/data-dictionary";
import { DataDictionaryRepository } from "network/data-dictionary-repository";
import { Category, toPathAndLabel } from "views/components/path-chooser";

@inject
export class TasksFieldPaths {
  constructor(private dataDictionaryRepository: DataDictionaryRepository) { }

  public getFields(): Promise<DataDictionaryField[]> {
    return this.dataDictionaryRepository.listTaskData();
  }

  public async getCategory(): Promise<Category> {
    const category: Category = { category: "", paths: [] };
    const data = await this.dataDictionaryRepository.listTaskData();
    if (!data || data.length === 0)
      return category;

    for (const field of data) {
      category.paths.push(toPathAndLabel(field));
    }

    return category;
  }
}
