import { VoiceMessageType } from "./marketing-outreach-sent";

export class MarketingOutreachArchiveContent {
  Id: number = null;
  OutreachId: number = null;
  OutreachSentId: number = null;
  AtsRecordType: 'applications' | 'contacts' | 'users' = null;
  AtsRecordId: string = null;
  Test: number = 0; // Email, Text Message, Voice Message
  PhoneNumber: number = null; // Text Message, Voice Message
  Status: '' | 'success' | 'no answer' = null; // Voice Message
  ArchiveId: string = null; // Email
  EmailAddress: string = null; // Email
  EventOccurrenceId: string = null; // EventOccurrenceId
  content: string = null; // content
  Open: number = 0; // Open
  Click: number = 0; // Click
  Reply: number = 0; // Reply
  VoiceMessageType: VoiceMessageType = null; // Recorded or Typed voice message
  VoiceMessageVoice: string = null; // Voice used for reading Typed voice message
  CreatedAt: string = null; // CreatedAt
  UpdatedAt: string = null; // UpdatedAt
  sendToAddress: string = ''; // Contact field path reference OR actual delivery address
}

export enum MarketingOutreachArchiveType {
  email = 'email',
  print = 'print',
  text = 'text',
  voice = 'voice',
}
