export class ContactsDataSource {
  public id: string = null;
  public organization_id: string = null;
  public name: string = null;
  public description: string = null;
  public category: string = null;
  public icon_url: string = null;
  public type: string = null;
  public order: number = 0;
  public key: string = null;
  public needs_mapping_configuration: boolean = false;
  public is_public: boolean = false;
  public data: { [key: string]: any; } = null;
  public created_utc: Date = null;
  public updated_utc: Date = null;
}

export interface IManualMapping {
  request_id: string;
  name: string;
  row_count: number;
  requested_utc: Date;
  can_first_row_be_headers: boolean;

  columns: IColumnMap[];
}

export interface IColumnMap {
  name: string;
  values: string[];
}

export class DataSourceResource {
  key: string = null;
  value: any = null;
}
