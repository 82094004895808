import { inject } from "fw";
import { Store, dispatch, handle } from "fw-state";
import { FormErrorHandling } from "./error-handling";
import { FileRepositoryRepository } from "network/file-provider-repository";

import { FileProvider } from "models/file-provider";
import {
  AddFileProviderAction,
  UpdateFileProviderAction,
  FileProviderValidationSettings,
  DeleteFileProvidersAction,
  DeleteFileProviderFolderAction,
} from "forms/file-provider";

import {
  LogoutAction,
  StartAction,
  RefreshFileProviderAction,
} from "./actions";
import { difference } from "lodash-es";
import { EntityChanged, WebSocketMessageAction, filterEntityChangedMessage } from "./filter-websocket-message";

interface FileProvidersShape {
  loaded: boolean;
  fileProviders: FileProvider[];
}

export class EnsureFileProvidersStoreAction {
  constructor() { }
}

export class RefreshFileProvidersListAction { }

@inject
export class FileProvidersStore extends Store<FileProvidersShape> {
  constructor(private fileProviderRepository: FileRepositoryRepository) {
    super();
  }

  defaultState() {
    return {
      loaded: false,
      fileProviders: [],
    };
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("FileProvider"))
  private async handleWebSocketMessageAction(action: WebSocketMessageAction<EntityChanged>) {
    await dispatch(new RefreshFileProvidersListAction());
  }

  @handle(RefreshFileProvidersListAction)
  private async handleRefreshFileProvidersListAction() {
    this.setState(state => ({
      ...state,
      loaded: false
    }));

    await dispatch(new EnsureFileProvidersStoreAction());
  }

  @handle(EnsureFileProvidersStoreAction)
  private async handleEnsureFileProvidersStoreAction(action: EnsureFileProvidersStoreAction) {
    if (this.state.loaded) {
      return;
    }

    const fileProviders = await this.fileProviderRepository.list();
    this.setState(state => ({
      loaded: true,
      fileProviders: fileProviders
    }));
  }

  @handle(StartAction)
  @handle(LogoutAction)
  private handleStartAndLogoutActions() {
    this.setState(s => this.defaultState());
  }

  private cleanOptions(model: FileProvider) {
    switch (model.ProviderType) {
      case "webhook":
        model.S3Context = null;
        model.SftpContext = null;
        break;

      case "sftp":
        model.S3Context = null;
        model.WebhookContext = null;
        break;

      case "s3":
        model.SftpContext = null;
        model.WebhookContext = null;
        break;
    }

    return model;
  }

  @handle(AddFileProviderAction, FormErrorHandling)
  private async handleAddFileProviderAction(action: AddFileProviderAction) {
    const validationSettings: FileProviderValidationSettings = {
      type: action.form.ProviderType,
      requireSensitive: true,
    };

    action.form.validate(validationSettings);

    const model = this.cleanOptions(action.form.updatedModel());
    const newFileProvider = await this.fileProviderRepository.post(model);

    this.setState(state => ({
      ...state,
      fileProviders: [...state.fileProviders, newFileProvider],
    }));
  }

  @handle(RefreshFileProviderAction)
  private async handleRefreshFileProviderAction(
    action: RefreshFileProviderAction,
  ) {
    const existingFileProvider = this.state.fileProviders.find(
      f => f.Id == action.id,
    );
    if (existingFileProvider == null) return;

    const updatedFileProvider = await this.fileProviderRepository.getById(
      action.id,
    );
    Object.assign(existingFileProvider, updatedFileProvider);

    this.setState(s => s);
  }

  @handle(UpdateFileProviderAction, FormErrorHandling)
  private async handleUpdateFileProviderAction(
    action: UpdateFileProviderAction,
  ) {
    const validationSettings: FileProviderValidationSettings = {
      type: action.form.ProviderType,
      requireSensitive: false,
    };

    action.form.validate(validationSettings);

    const model = this.cleanOptions(action.form.updatedModel());
    const updatedFileProvider = await this.fileProviderRepository.put(model);

    const existingFileProvider = this.state.fileProviders.find(
      t => t.Id == updatedFileProvider.Id,
    );
    if (existingFileProvider == null) return;

    Object.assign(existingFileProvider, updatedFileProvider);

    this.setState(s => s);
  }

  @handle(DeleteFileProvidersAction)
  private async handleDeleteFileProvidersAction(
    action: DeleteFileProvidersAction,
  ) {
    const fileProviders = this.state.fileProviders.filter(t =>
      action.fileProviderIds.some(id => id == t.Id),
    );

    await this.fileProviderRepository.del(fileProviders.map(t => t.Id));

    this.setState(state => ({
      ...state,
      fileProviders: difference(state.fileProviders, fileProviders),
    }));
  }

  @handle(DeleteFileProviderFolderAction)
  private async handleDeleteFileProviderFolderAction(
    action: DeleteFileProviderFolderAction,
  ) {
    const updatedFileProvider = await this.fileProviderRepository.deleteFolder(
      action.id,
      action.folder,
    );

    const existingFileProvider = this.state.fileProviders.find(
      t => t.Id == updatedFileProvider.Id,
    );
    if (existingFileProvider == null) return;

    Object.assign(existingFileProvider, updatedFileProvider);

    this.setState(s => s);
  }
}
