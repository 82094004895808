import { ComponentEventBus, prop, inject, needs } from "fw";

import { RelationshipPill } from "./relationship-pill";
import type { IRelationship } from "models/contact";

@inject
@needs(RelationshipPill)
export class RelationshipField {
  @prop(undefined) public value!: IRelationship[];
  @prop(false) showRemove!: boolean;

  constructor(private ceb: ComponentEventBus) { }

  remove(value: IRelationship) {
    this.ceb.dispatch("remove", value);
  }
}
