import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS } from "./ats";
import { PaymentSettings, PaymentGateway } from "models/organization";

@inject
export class PaymentSettingsRepository {
  constructor(private s: ATS) {}

  public async getSettings() {
    const res = await this.s.get("payment-settings");
    return createFrom(PaymentSettings, res.body);
  }

  public async postGateway(gateway: PaymentGateway, activate = false) {
    const res = await this.s.post("payment-gateway", gateway, { activate });
    return createFrom(PaymentGateway, res.body);
  }

  public async putGateway(gateway: PaymentGateway, activate = false) {
    const res = await this.s.put(`payment-gateway/${gateway.Id}`, gateway, {
      activate,
    });
    return createFrom(PaymentGateway, res.body);
  }

  public async activateGateway(id: string) {
    const res = await this.s.put(`payment-gateway/${id}/activate`, null);
    return createFrom(PaymentGateway, res.body);
  }

  public async deleteGateway(gatewayId: string) {
    await this.s.delete(`payment-gateway/${gatewayId}`);
  }
  
  public async touchNetGenerateSecureLinkTicket(
    gateway: PaymentGateway, 
    primaryTransactionId: string, 
    secondaryTransactionId: string,
    chargeAmount: number, 
    paymentArgs: TouchNetApplicationPaymentArgs) {
    
    console.log("Sending gateway info to get secure link ticket");

    var args: TouchNetGenerateSecureLinkTicketArgs = new TouchNetGenerateSecureLinkTicketArgs();
    args.gatewayId = gateway.Id;
    args.chargeAmount = chargeAmount;
    args.primaryTransactionId = primaryTransactionId;
    args.secondaryTransactionId = secondaryTransactionId;
    args.paymentArgs = paymentArgs;

    const res = await this.s.post("payment-gateway/touchnet/generateSecureLinkTicket", args); 

    console.log("Got secure link ticket");
    console.debug(res);

    return res;
  }

  public async touchNetAuthorizeAndChargeCustomer(gateway: PaymentGateway, sessionId: string) {
    
    console.log("Sending gateway info to authorize payment");
    
    const res = await this.s.post(`payment-gateway/${gateway.Id}/touchnet/authorizeAndChargeCustomer`, gateway.Id, { sessionId });

    console.log("Got payment authorization/charge response");
    console.debug(res);

    return res;
  }

  public async touchNetGetTransactionResults(gateway: PaymentGateway, sessionId: string) {
    
    console.log("Sending gateway info to authorize payment");
    
    const res = await this.s.post(`payment-gateway/${gateway.Id}/touchnet/getTransactionResults`, null, { sessionId });

    console.log("Got transaction results response");
    console.debug(res);

    return res;
  }
}

export class TouchNetGenerateSecureLinkTicketArgs {
  gatewayId: string = null;
  primaryTransactionId: string = null;
  secondaryTransactionId: string = null;
  chargeAmount: number = null;
  paymentArgs: TouchNetApplicationPaymentArgs = null;
}

export class TouchNetApplicationPaymentArgs {
  ClientReturnUrl: string = null;
  PortalId: string = null;
  PortalType: string = null;
  ApplicationId:  string = null;
  ApplicationStepGroupId: string = null;
  EmailAddress: string = null;
  GivenName: string = null;
  FamilyName: string = null;
  Address1: string = null;
  Address2: string = null;
  City: string = null;
  Region: string = null;
  PostalCode: string = null;
  CountryCode: string = null;
  PhoneNumber: string = null;
}