import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { LocalStorageCache } from "caching";

import { EmailTaskRepository } from "network/email-task-repository";
import { TaskRequestRepository } from "network/task-request-repository";

import {
  LogoutAction,
  StartAction,
} from "./actions";
import { EmailTask } from "models/email-task";
import { GridColumn } from "models/grid-column";
import { createFromArray } from "fw-model";

interface EmailTaskStoreShape {
  organizationId: string;
  seasonId: string,
  userId: string,
  firstPageLoaded: boolean;
  loaded: boolean;
  errorLoading: boolean;

  filter: string;
  additionalFilter: string;
  sort: string;

  total: number;
  lastRefresh: Date;
  previousPageToken: string;
  nextPageToken: string;
  currentPreviousPageToken: string;
  currentNextPageToken: string;
  pageNumber: number,
  pageSize: number;

  hasErrors: boolean;
  list: EmailTask[];
}

export class EnsureEmailTasksAction {}
export class NextPageAction { }
export class PreviousPageAction { }
export class SetAdditionalFilterAction {
  constructor(public additionalFilter: string) { }
}
export class RefreshGridAction {
  constructor() { }
}

export class ToggleSortAction {
  constructor(public sort: string) { }
}
const DEFAULT_PAGE_SIZE = 20;
type SavedState = {
  filter: string;
  additionalFilter: string;
  sort: string;
  pageSize: number;
  pageNumber: number,
  previousPageToken: string;
  nextPageToken: string;
  currentPreviousPageToken: string;
  currentNextPageToken: string;
  lastRefresh: Date;
};

@inject
export class EmailTaskStore extends Store<EmailTaskStoreShape> {
  constructor(
    private emailTaskRepo: EmailTaskRepository,
    private cache: LocalStorageCache,
  ) {
    super();
  }

  public DEFAULT_COLUMNS = createFromArray(GridColumn, [
    {
      Label: "Subject",
      Path: "EmailTemplate.Subject",
      Sort: "emailTemplate.subject"
    },
    
    {
      Label: "Date",
      Path: "MetaData.DateCreatedUtc",
      Sort: "metaData.dateCreatedUtc"
    },
    {
      Label: "Recipients",
      Path: "MetaData.IdCount",
      Sort: "metaData.idCount"
    }
  ]);

  defaultState() {
    return {
      organizationId: null,
      seasonId: null,
      userId: null,
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      total: 0,
      firstPageLoaded: false,
      loaded: false,
      errorLoading: false,
      list: [],
      filter: "",
      additionalFilter: "",
      sort: "",
      hasErrors: false,
      lastRefresh: null
    };
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    const defaultState = this.defaultState();
    defaultState.organizationId = s.context.Organization.Id;
    defaultState.userId = s.context.Me.Id;

    this.setState(s => defaultState);
    this.restoreState();
  }

  @handle(NextPageAction)
  private async handleNextPage(s: NextPageAction) {
    const { nextPageToken, pageSize, pageNumber, total } = this.state;

    if (pageNumber * pageSize > total) {
      return;
    }

    this.setState(state => ({
      ...state,
      loaded: false,
      pageNumber: pageNumber + 1
    }));

    this.saveState();
    await this.loadStoreData(null, nextPageToken);
  }

  @handle(PreviousPageAction)
  private async handlePreviousPage(s: PreviousPageAction) {
    const { pageSize, previousPageToken, pageNumber, total } = this.state;
    if (pageNumber <= 1) {
      return;
    }

    this.setState(state => ({
      ...state,
      loaded: false,
      pageNumber: pageNumber - 1
    }));

    this.saveState();
    await this.loadStoreData(previousPageToken, null);
  }

  @handle(ToggleSortAction)
  private async handleToggleSort(ts: ToggleSortAction) {
    if (ts.sort == this.state.sort) {
      this.setState(state => ({ ...state, loaded: false, pageNumber: 1, sort: `-(${ts.sort})` }));
    } else {
      this.setState(state => ({ ...state, loaded: false, pageNumber: 1, sort: ts.sort }));
    }

    this.saveState();
    await dispatch(new EnsureEmailTasksAction());
  }

  @handle(RefreshGridAction)
  private async handleForceFullRefreshGridAction(action: RefreshGridAction) {
    this.setState(state => ({ 
      ...state, 
      loaded: false,
      pageNumber: 1
     }));
    await dispatch(new EnsureEmailTasksAction());
  }

  @handle(SetAdditionalFilterAction)
  private async handleSetAdditionalFilter(s: SetAdditionalFilterAction) {
    this.setState(state => ({
      ...state,
      firstPageLoaded: false,
      loaded: false,
      errorLoading: false,
      pageNumber: 1,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      additionalFilter: s.additionalFilter
    }));

    this.saveState();

    await this.loadStoreData();
  }

  @handle(EnsureEmailTasksAction)
  private async handleEnsureEmailTasks() {
    if (this.state.loaded) return;

    this.setState(s => ({
      ...s,
      loading: true,
    }));

    await this.loadStoreData();
  }

  private async loadStoreData(previousPageToken: string = null, nextPageToken: string = null) {
    const { seasonId, pageSize, sort, additionalFilter } = this.state;
    try {
      const clientModelResponse = await this.emailTaskRepo.list(seasonId, additionalFilter, null, sort, pageSize, previousPageToken, nextPageToken);
      this.setState(state => ({
        ...state,
        firstPageLoaded: true,
        loaded: true,
        total: clientModelResponse.Total,
        previousPageToken: clientModelResponse.PreviousPageToken,
        nextPageToken: clientModelResponse.NextPageToken,
        list: clientModelResponse.Results,
        lastRefresh: new Date(),
      }));

    } catch (err) {
      this.setState(state => ({ ...state, errorLoading: true }));
    }
  }

  private saveState() {
    if (this.state.organizationId == null) {
      return;
    }

    this.cache.set<SavedState>(
      `${this.state.organizationId}:${this.state.seasonId}:${this.state.userId}-email-tasks-store`,
      {
        filter: this.state.filter,
        additionalFilter: this.state.additionalFilter,
        sort: this.state.sort,
        pageSize: this.state.pageSize,
        pageNumber: this.state.pageNumber,
        previousPageToken: this.state.previousPageToken,
        nextPageToken: this.state.nextPageToken,
        currentPreviousPageToken: this.state.currentPreviousPageToken,
        currentNextPageToken: this.state.currentNextPageToken,
        lastRefresh: this.state.lastRefresh,
      },
    );
  }

  private restoreState() {
    if (this.state.organizationId == null) {
      return;
    }

    const savedState = this.cache.get<SavedState>(`${this.state.organizationId}:${this.state.seasonId}:${this.state.userId}-email-tasks-store`);
    if (savedState == null) {
      return;
    }

    this.setState(state => ({
      ...state,
      filter: savedState.filter,
      additionalFilter: savedState.additionalFilter,
      sort: savedState.sort,
      pageNumber: savedState.pageNumber || 1,
      pageSize: savedState.pageSize || DEFAULT_PAGE_SIZE,
      previousPageToken: savedState.previousPageToken,
      nextPageToken: savedState.nextPageToken,
      currentPreviousPageToken: savedState.currentPreviousPageToken,
      currentNextPageToken: savedState.currentNextPageToken,
      lastRefresh: savedState.lastRefresh,
    }));
  }
}
