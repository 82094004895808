import { inject } from "fw";

import { FeatureFlagService } from "./feature-flag";
import { OrganizationModuleService } from "./organization-module";
import { CurrentOrganizationStore } from "state/current-organization";
import { CurrentUserStore } from "state/current-user";
import { CUSTOM_PERMISSIONS_PROPERTY, SPLIT_CHAR } from "views/settings/roles/edit-role";

@inject
export class RoleService {
  constructor(
    private currentOrganizationStore: CurrentOrganizationStore,
    private currentUserStore: CurrentUserStore,
    private ffs: FeatureFlagService,
    private organizationModuleService: OrganizationModuleService
  ) {}

  permissions() {
    const { permissions: currentUserPermissions, isGlobalPrincipal } = this.currentUserStore.state;

    let coreTrackPermissions = this.getCoreTrackPermissions();
    let applicationTrackPermissions = this.getApplicationTrackPermissions();
    let applicationTrackSettingsPermissions = this.getApplicationTrackSettingsPermissions();
    let contactsTrackPermissions = this.getContactsTrackPermissions();
    let marketingPermissions = this.getMarketingPermissions();
    let eventsTrackPermissions = this.getEventsTrackPermissions();
    let settingsPermissions: { [role: string]: string } = {};
    let applicationSettingsPermissions: { [role: string]: string } = {};
    let applicationPermissions: { [role: string]: string } = {};
    let contactPermissions: { [role: string]: string } = {};
    let paymentTrackPermissions = this.getPaymentTrackPermissions();
    return {
      settingsPermissions: this.filterByCurrentUserPermissions(
        settingsPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      applicationPermissions: this.filterByCurrentUserPermissions(
        applicationPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      contactPermissions: this.filterByCurrentUserPermissions(
        contactPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      applicationSettingsPermissions: this.filterByCurrentUserPermissions(
        applicationSettingsPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      //  org module permissions below
      coreTrackPermissions: this.filterByCurrentUserPermissions(
        coreTrackPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      marketingPermissions: this.filterByCurrentUserPermissions(
        marketingPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      applicationTrackPermissions: this.filterByCurrentUserPermissions(
        applicationTrackPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      applicationTrackSettingsPermissions: this.filterByCurrentUserPermissions(
        applicationTrackSettingsPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      contactsTrackPermissions: this.filterByCurrentUserPermissions(
        contactsTrackPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      eventsTrackPermissions: this.filterByCurrentUserPermissions(
        eventsTrackPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
      paymentTrackPermissions: this.filterByCurrentUserPermissions(
        paymentTrackPermissions,
        currentUserPermissions,
        isGlobalPrincipal
      ),
    };
  }

  public customPermissions(): string[] {
    const { permissions: currentUserPermissions, isGlobalPrincipal } = this.currentUserStore.state;
    const { PropertyValues = [] } = this.currentOrganizationStore.state.organization;
    const customPermissionsPropertyValue = PropertyValues.find(
      (item) => item.FieldKey === CUSTOM_PERMISSIONS_PROPERTY
    )?.Value;
    const customPermissions =
      customPermissionsPropertyValue?.split(SPLIT_CHAR).map((value) => value.trim()) ?? [];
    if (isGlobalPrincipal) {
      return customPermissions;
    }

    return customPermissions.filter((p) => currentUserPermissions.includes(p));
  }

  private getCoreTrackPermissions() {
    let coreTrackPermissions: { [role: string]: string } = {};
    if (this.ffs.isFeatureFlagEnabled("GlobalDataRestrictions")) {
      coreTrackPermissions.ManageGlobalDataRestrictions = "Manage Global Data Restrictions";
    }

    coreTrackPermissions.ManageUsers = "Manage Users";
    coreTrackPermissions.InviteUsers = "Invite Users";
    coreTrackPermissions.ManageRoles = "Manage Roles";
    coreTrackPermissions.ManageWorkspaces = "Manage Workspaces";

    if (this.interviewPortalEnabled && this.ffs.isFeatureFlagEnabled("InterviewManagement")) {
      coreTrackPermissions.ManageInterviewPortal = "Manage Interview Portal";
    }

    if (this.ffs.isFeatureFlagEnabled("PendingContactsPage")) {
      coreTrackPermissions.ManagePendingContacts = "Manage Pending Contacts";
    }

    coreTrackPermissions = {
      ...coreTrackPermissions,
      ManageTeams: "Manage Teams",
      ImpersonateUsers: "Impersonate Organization Users",
      OrganizationSettings: "Manage Organization Settings",
      ManageEmailSettings: "Manage Email Settings",
      ManageReports: "Manage Reports",
      ManageGoals: "Manage Goals",
      ManageVaults: "Manage Key Vault",
      ManageExports: "Manage Exports",
      GenerateExports: "Generate Exports",
      ManageInquiryPortal: "Manage Inquiry Portal",
      ManageReferencePortal: "Manage Reference Portal",
      ManageStudentPortal: "Manage Student Portal",
      ManageContentTemplates: "Manage Content Templates",
    };

    if (!this.ffs.isFeatureFlagEnabled("DeprecateApplicantPortal")) {
      coreTrackPermissions.ManageApplicantPortal = "Manage Applicant Portal";
    }
    if (this.organizationModuleService.canPersonalizeDashboard) {
      coreTrackPermissions.PersonalizeDashboard = "Personalize Dashboard";
    }
    if (this.ffs.isFeatureFlagEnabled("RestrictViewToSettingsPages")) {
      coreTrackPermissions.ViewAllSettingsPages = "View All Settings Pages";
    }
    if (this.ffs.isFeatureFlagEnabled("Collaboration")) {
      coreTrackPermissions.ManageCollaborationPortal = "Manage Collaboration Portal";
    }
    if (this.ffs.isFeatureFlagEnabled("CustomPortals")) {
      coreTrackPermissions.ManageCustomPortal = "Manage Custom Portal";
    }
    if (this.hasReviewPortalFeatureEnabled) {
      coreTrackPermissions.ManageReviewPortal = "Manage Review Portal";
    }
    if (this.ffs.isFeatureFlagEnabled("ApplicationEmailTemplates")) {
      coreTrackPermissions.ManageSystemEmailTemplates = "Manage System Email Templates";
    }

    if (this.ffs.isFeatureFlagEnabled("ContactEmailTemplates")) {
      coreTrackPermissions.ManageContactEmailTemplates = "Manage Contact Email Templates";
    }

    return coreTrackPermissions;
  }

  private getApplicationTrackPermissions() {
    let applicationTrackPermissions: { [role: string]: string } = {};

    if (this.organizationModuleService.hasReviewFeature) {
      applicationTrackPermissions = {
        ImpersonateApplicant: "Impersonate Applicants",
        EvaluateApplications: "Review Applications",
        AssignApplications: "Assign Applications",
        EmailApplications: "Send Email to Applications",
        EditApplicationData: "Edit Application Data",
        EditApplicationProperties: "Edit Application Properties",
        EditApplicationPhase: "Change Application Phase",
        EditApplicationTags: "Edit Application Tags",
        EditApplicationStage: "Change Application Stage",
        ViewEvaluations: "View Reviews",
        ViewEncryptedFields: "View Concealed Fields",
        CommentOnApplications: "Comment on Applications",
        CopyApplication: "Copy Applications",
      };

      // this is what the name of the permission will be (Copy/Create) once CopyApplication and ApplicationOrigin are
      // deleted..
      if (this.ffs.isFeatureFlagEnabled("ApplicationOrigin")) {
        applicationTrackPermissions.CopyApplication = "Copy/Create Applications";
      }

      if (this.hasDecisionsFeatureEnabled) {
        applicationTrackPermissions.EditApplicationDecision = "Change Application Decision";
      }
      applicationTrackPermissions.ManageProgramSettings = "Manage Program Properties";
      if (this.ffs.isFeatureFlagEnabled("EvaluationsForOtherUsers")) {
        applicationTrackPermissions.SubmitReviewsForOthers = "Submit Reviews for Others";
      }
    }

    return applicationTrackPermissions;
  }

  private getApplicationTrackSettingsPermissions() {
    let applicationTrackSettingsPermissions: { [role: string]: string } = {};

    if (this.organizationModuleService.hasReviewFeature) {
      applicationTrackSettingsPermissions = {
        ManagePrograms: "Manage Programs",
        ManageApplicationSettings: "Manage Application View Layout & Properties",
        ManageApplicationSegments: "Manage Application Segments",
        ManageApplicantForms: "Manage Application Forms",
        ManageEvaluationForms: "Manage Review Forms",
        ManageEvaluationPhases: "Manage Review Phases",
      };

      if (this.organizationModuleService.hasReferencesFeature) {
        applicationTrackSettingsPermissions.ManageReferenceForms = "Manage Reference Forms";
      }
      if (this.organizationModuleService.hasWorkflowFeature) {
        applicationTrackSettingsPermissions.ManageAutomationRules = "Manage Automation Rules";
      }
      if (this.organizationModuleService.hasReviewFeature && this.hasDecisionsFeatureEnabled) {
        applicationTrackSettingsPermissions.ManageDecisionSettings = "Manage Decision Settings";

        if (this.ffs.isFeatureFlagEnabled("ProgramProperties")) {
          applicationTrackSettingsPermissions.ManageProgramSettings = "Manage Program Properties";
        }
      }
      if (
        this.ffs.isFeatureFlagEnabled("Collaboration") &&
        this.organizationModuleService.hasCollaborationFeature
      ) {
        applicationTrackSettingsPermissions.ManageCollaboration = "Manage Collaboration Portals & Modules";
      }

      if (this.hasDecisionsFeatureEnabled) {
        applicationTrackSettingsPermissions.GenerateDecisionLetter = "Generate Decision Letter";
        applicationTrackSettingsPermissions.ManageDecisionLetterTemplate = "Manage Decision Letter Template";
      }
    }

    if (this.ffs.isFeatureFlagEnabled("ApplicationEmailTemplates")) {
      applicationTrackSettingsPermissions.ManageApplicationEmailTemplates =
        "Manage Application Email Templates";
      applicationTrackSettingsPermissions.UseApplicationEmailTemplates = "Use Application Email Templates";
    }

    return applicationTrackSettingsPermissions;
  }

  public get hasDecisionsFeatureEnabled() {
    return this.currentOrganizationStore.hasDecisionsFeatureEnabled;
  }

  public get hasReviewPortalFeatureEnabled() {
    return this.currentOrganizationStore.hasReviewPortalFeatureEnabled;
  }

  private getContactsTrackPermissions() {
    let contactsTrackPermissions: { [role: string]: string } = {
      EditContacts: "Edit Contacts",
      ManageContactDataSources: "Manage Contact Data Sources",
      ManageContactRoutingTables: "Manage Contact Routing Tables",
      ManageContactSegments: "Manage Contact Segments",
      ManageContactSummaryViews: "Manage Contact Summary Views",
      ManageContactWorkflows: "Manage Contact Workflows",
    };

    contactsTrackPermissions.ManageDuplicateContacts = "Manage Duplicate Contacts";

    if (this.organizationModuleService.hasContactsFeature) {
      contactsTrackPermissions.ManageInquiryForms = "Inquiry Forms";
    }

    contactsTrackPermissions.ManageContactType = "Manage Contact Type";
    contactsTrackPermissions.ManageContactCalculatedFields = "Manage Contact Calculated Fields";

    if (!this.organizationModuleService.hasReviewFeature) {
      contactsTrackPermissions.ManageExports = "Manage Exports";
    }
    if (this.ffs.isFeatureFlagEnabled("SMSConversationsInbox")) {
      contactsTrackPermissions.ManageAssignedConversations = "Manage Assigned Conversations";
      contactsTrackPermissions.ViewAllConversations = "View All Conversations";
      contactsTrackPermissions.ManageAllConversations = "Manage All Conversations";
    }

    if (this.ffs.isFeatureFlagEnabled("ContactEmailTemplates")) {
      contactsTrackPermissions.UseContactEmailTemplates = "Use Contact Email Templates";
      contactsTrackPermissions.EmailContacts = "Send Contact Email";
    }

    return contactsTrackPermissions;
  }

  private getMarketingPermissions() {
    let marketingPermissions: { [role: string]: string } = {
      ManageMarketingSettings: "Manage Marketing Settings",
      ViewOutreach: "View Outreach",
      ManageOutreach: "Manage Outreach Campaigns & Tracks",
      ManageEmails: "Manage Emails",
      SendEmails: "Send Emails",
      // ManagePrintPieces: "Manage Print Pieces",
      // SendPrintPieces: "Send Print Pieces",
      ManageTextMessages: "Manage Text Messages",
      SendTextMessages: "Send Text Messages",
      ManageVoiceMessages: "Manage Voice Messages",
      SendVoiceMessages: "Send Voice Messages",
      ViewEmailTemplates: "View Email Templates",
      ManageEmailTemplates: "Manage Email Templates",
      // ViewPrintTemplates: "View Print Templates",
      // ManagePrintTemplates: "Manage Print Templates",
      // ViewPdfApproval: "View PDF Approval",
      // ManagePdfApproval: "Manage PDF Approval"
    };

    if (this.ffs.isFeatureFlagEnabled("RenameMarketingEmailTemplatesOUT5290")) {
      marketingPermissions.ViewEmailTemplates = "View Marketing Email Templates";
      marketingPermissions.ManageEmailTemplates = "Manage Marketing Email Templates";
    }

    return marketingPermissions;
  }

  private getEventsTrackPermissions() {
    let eventsTrackPermissions: { [role: string]: string } = {};

    if (this.ffs.isFeatureFlagEnabled("CalendarModule")) {
      eventsTrackPermissions.ViewEvents = "View Events";
      eventsTrackPermissions.ManageEvents = "Manage Events";
      eventsTrackPermissions.ManageEventEmails = "Manage Event Emails";
      eventsTrackPermissions.ManageEventRegistrations = "Manage Event Registrations";
      eventsTrackPermissions.ManageEventTypes = "Manage Event Types";
    }

    return eventsTrackPermissions;
  }

  private getSettingsPermissions() {
    let settingsPermissions: { [role: string]: string } = {
      OrganizationSettings: "Organization",
      ManageUsers: "Users & Permissions",
      InviteUsers: "Invite Users",
      ManageReports: "Reports",
      ManageGoals: "Goals",
      ImpersonateUsers: "Impersonate Users",
    };

    return settingsPermissions;
  }

  private getApplicationSettingsPermissions() {
    let applicationSettingsPermissions: { [role: string]: string } = {};

    if (this.organizationModuleService.hasReviewFeature) {
      applicationSettingsPermissions.ManageWorkspaces = "Manage Workspaces";

      if (this.interviewPortalEnabled && this.ffs.isFeatureFlagEnabled("InterviewManagement")) {
        applicationSettingsPermissions.ManageInterviewPortal = "Manage Interview Portal";
      }

      if (this.ffs.isFeatureFlagEnabled("PendingContactsPage")) {
        applicationSettingsPermissions.ManagePendingContacts = "Manage Pending Contacts";
      }

      applicationSettingsPermissions = {
        ...applicationSettingsPermissions,
        ManageTeams: "Teams",
        ManagePrograms: "Programs",
        ManageApplicationSettings: "Application View & Properties",
        ManageApplicationSegments: "Application Segments",
        ManageApplicantForms: "Application Forms",
        ManageReferenceForms: "Reference Forms",
        ManageEvaluationForms: "Review Forms",
        ManageEvaluationPhases: "Review Phases",
        ManageEmailSettings: "Email Settings",
        ManageCollaboration: "Collaboration Settings",
        ManageAutomationRules: "Edit Automation",
      };
    }

    return applicationSettingsPermissions;
  }

  private getApplicationPermissions() {
    let applicationPermissions: { [role: string]: string } = {};

    if (this.organizationModuleService.hasReviewFeature) {
      applicationPermissions = {
        ViewEvaluations: "View Reviews",
        EvaluateApplications: "Review Applications",
        AssignApplications: "Assign Applications",
        EditApplicationData: "Edit Application Data",
        EditApplicationProperties: "Edit Application Properties",
        EditApplicationPhase: "Change Application Phase",
        EditApplicationStage: "Change Application Stage",
        ImpersonateApplicant: "Impersonate Applicant",
        ViewEncryptedFields: "View Concealed Fields",
        EditApplicationTags: "Edit Application Tags",
        CommentOnApplications: "Comment on Applications",
        EditApplicationDecision: "Change Application Decision",
      };
    }

    return applicationPermissions;
  }

  private getContactPermissions() {
    let contactPermissions: { [role: string]: string } = {
      EditContacts: "Edit Contacts",
      ManageContactDataSources: "Contact Data Sources",
      ManageContactRoutingTables: "Contact Routing Tables",
      ManageContactSegments: "Contact Segments",
      ManageContactSummaryViews: "Contact Summary Views",
      ManageContactWorkflows: "Contact Workflows",
      ManageInquiryForms: "Inquiry Forms",
      ManageContactCalculatedFields: "Manage Contact Calculated Fields",
      ManageContactType: "Manage Contact Type",
    };

    return contactPermissions;
  }

  private getPaymentTrackPermissions() {
    let paymentTrackPermissions: { [role: string]: string } = {
      ManagePaymentProcessors: "Manage Payment Processors",
      WaivePayment: "Waive Payment",
    };

    if (this.ffs.isFeatureFlagEnabled("PaymentJournal")) {
      paymentTrackPermissions.ViewPaymentDetails = "View Payment Details";
    }

    return paymentTrackPermissions;
  }

  private get interviewPortalEnabled(): boolean {
    const { Features } = this.currentOrganizationStore.state.organization;
    return !!Features?.InterviewPortal;
  }

  private filterByCurrentUserPermissions(
    permissions: { [role: string]: string },
    currentUserPermissions: string[],
    isGlobalPrincipal: boolean
  ): { [role: string]: string } {
    if (isGlobalPrincipal) {
      return permissions;
    }

    const filteredPermissions: { [role: string]: string } = {};
    for (let permission in permissions) {
      if (currentUserPermissions.includes(permission)) {
        filteredPermissions[permission] = permissions[permission];
      }
    }

    return filteredPermissions;
  }
}
