import { inject } from "fw";

import { ApplicationSettingsStore } from "state/application-settings";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { FormStore } from "state/forms";

import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { CustomPropertyTypeCode } from "models/application-settings";
import { CustomFieldType, ICustomFieldDefinition } from "models/contact-organization";
import { Program, ProgramStepTypeCode } from "models/program";
import { Form, QuestionType, Question } from "models/form";

import { CompletionItem } from "helpers/auto-complete";
import { ProgramRepository } from "network/program-repository";
import { FeatureFlagService } from "service/feature-flag";

@inject
export class ClientModelHelper {
  constructor(
    private settingsStore: ApplicationSettingsStore,
    private contactsOrgStore: CurrentContactOrganizationStore,
    private formsStore: FormStore,
    private programRepo: ProgramRepository
  ) { }

  private fillOutApplicationProperties(cm: any) {
    const allowedTypes = [CustomPropertyTypeCode.Standard, CustomPropertyTypeCode.System];

    const list = this.settingsStore.state.applicationSettings.ApplicationProperties.filter(p => allowedTypes.includes(p.PropertyType));

    for (const prop of list) {
      let value: any = null;

      switch (prop.DataType) {
        case CalculatedFieldDataTypeCode.Number:
          value = 0;
          break;

        case CalculatedFieldDataTypeCode.Date:
          value = "yyyy-MM-ddTHH:mm:ss";
          break;

        case CalculatedFieldDataTypeCode.Json:
          value = {};
          break;

        case CalculatedFieldDataTypeCode.String:
        case CalculatedFieldDataTypeCode.Encrypted:
          value = "";
          break;

        case CalculatedFieldDataTypeCode.Boolean:
          value = false;
          break;

        case CalculatedFieldDataTypeCode.Enumeration:
          value = prop.EnumerationOptions.map(e => e.Label).join("|");
          break;
      }

      cm[prop.Key] = value;
    }
  }

  private fillOutApplicantProperties(cm: any) {
    // some base info
    cm.applicant = {
      name: "",
      givenName: "",
      emailAddress: "",
      familyName: "",
      tags: [],
    };


    const fields = this.contactsOrgStore.state.organization.fields.filter(f => f.contact_type == "applicant" && !f.is_read_only && !f.is_system_field)
    for (const f of fields) {
      let value: any = null;

      switch (f.type) {
        case CustomFieldType.string:
          value = "";
          break;

        // TODO: more here
      }

      cm.applicant[f.name] = value;
    }
  }

  private fillOutReferences(program: Program, cm: any) {
    cm.references = {};
    for (const stage of program.Stages) {
      for (const group of stage.StepGroups) {
        for (const step of group.StepList.filter(s => s.StepType == ProgramStepTypeCode.ReferenceSet)) {
          const f = this.formsStore.state.forms.find(f => f.Id == step.ReferenceSetSettings.FormId);


          const refObj: any = {};
          refObj['name'] = "";
          refObj['email'] = "";
          refObj['is_complete'] = true;
          refObj['waive_right'] = false;
          refObj['dateRequested'] = "yyyy-MM-ddTHH:mm:ss";
          refObj['dateCompleted'] = "yyyy-MM-ddTHH:mm:ss";
          refObj['form'] = this.getFormSample(f);

          const references = [refObj];
          const stepObj = {};
          stepObj['references'] = references;
          cm.references[step.Key] = stepObj;
        }
      }
    }
  }

  private getFormSample(form: Form) {
    const obj: any = {};
    const getSample = (question: Question) => {
      let value: any = null;

      switch (question.Type) {
        case QuestionType.Date:
          value = "yyyy-MM-ddTHH:mm:ss";
          break;

        case QuestionType.Number:
          value = 0;
          break;

        case QuestionType.URL:
          value = "http://example.com";
          break;

        case QuestionType.Name:
          value = {
            title: "",
            given: "",
            middle: "",
            family: "",
            suffix: "",
            full: "",
          };
          break;

        case QuestionType.Address:
          value = {
            address1: "",
            address2: "",
            city: "",
            region: "",
            country: "",
            postalCode: "",
            location: "",
          }
          break;

        case QuestionType.Scale:
          value = 0;
          break;

        case QuestionType.LongText:
        case QuestionType.ShortText:
        case QuestionType.Encrypted:
          value = "";
          break;

        case QuestionType.RadioButton:
        case QuestionType.DropDown:
          value = question.AnswerOptions.map(a => a.Label).join("|");
          if (question.Options.AllowWriteIn) {
            value += "|<Allows Write In>";
          }
          break;

        case QuestionType.CEEBCode:
          value = {
            code: "",
            name: "",
            address: "",
            city: "",
            region: "",
            country: "",
            postalCode: "",
          };
          break;

        case QuestionType.PhoneNumber:
          value = "555-123-1234";
          break;

        case QuestionType.EmailAddress:
          value = "email@example.com";
          break;

        case QuestionType.CheckBoxList:
          value = question.AnswerOptions.map(a => a.Label);
          if (question.Options.AllowWriteIn) {
            value.push("<Allows Write In>");
          }
          break;

        case QuestionType.ScaleGroup:
          value = {};

          for (const v of question.Options.ScaleGroup.Items) {
            value[v.Key] = 0;
          }

          break;

        case QuestionType.Table:
          // do we need to support named rows?? not sure if we even expose that
          value = [
            question.Options.Table.Columns.map(a => a.Label),
          ];
          break;

        case QuestionType.File:
          value = 'http://example.com/example.pdf|file://./attachments/example.pdf';
          break;
      }

      return value;
    }
    for (const section of form.Sections) {
      const availableQuestions = section.Questions;

      if (section.IsTableSection) {
        let value: any = null;

        const questionMap: any = {};
        for (const q of availableQuestions) {
          questionMap[q.Key] = getSample(q);
        }

        if (section.TableSectionOptions.NamedRows != null && section.TableSectionOptions.NamedRows.length > 0) {
          value = {};
          for (const nr of section.TableSectionOptions.NamedRows) {
            value[nr.Key] = questionMap;
          }
        } else {
          value = [
            questionMap,
          ];
        }

        obj[section.TableSectionOptions.Key] = value;
      } else {
        for (const question of availableQuestions) {
          obj[question.Key] = getSample(question);
        }
      }
    }
    return obj;
  }


  private fillOutForms(program: Program, cm: any) {
    const forms: Form[] = [];

    for (const stage of program.Stages) {
      for (const group of stage.StepGroups) {
        for (const step of group.StepList.filter(s => s.StepType == ProgramStepTypeCode.Form)) {
          const f = this.formsStore.state.forms.find(f => f.Id == step.FormSettings.FormId);
          forms.push(f);
        }
      }
    }

    cm.forms = {};

    for (const form of forms) {
      cm.forms[form.Key] = this.getFormSample(form);
    }
  }

  private fillOutFirstClassData(program: Program, cm: any) {
    const firstClassProps = {
      stage: program.StartingStageName,
      phase: "",
      tags: [],
    };

    Object.assign(cm, firstClassProps);
  }

  public async getBlankClientModel(programId: string) {
    const program: Program = await this.programRepo.get(programId);

    if (program == null) {
      throw new Error("Could not find program");
    }

    const clientModel: any = {};

    this.fillOutApplicationProperties(clientModel);
    this.fillOutApplicantProperties(clientModel);
    this.fillOutForms(program, clientModel);
    this.fillOutReferences(program, clientModel);
    // not supported yet
    //this.fillOutFirstClassData(program, clientModel);

    return clientModel;
  }
}

export function fillOutContactProperties(f: ICustomFieldDefinition) {
  let properties: CompletionItem[] = [];

  switch (f.type) {
    case CustomFieldType.address:
      properties = [
        { token: "address1", type: CustomFieldType.string },
        { token: "address2", type: CustomFieldType.string },
        { token: "city", type: CustomFieldType.string },
        { token: "state", type: CustomFieldType.string },
        { token: "postalCode", type: CustomFieldType.string },
        { token: "country", type: CustomFieldType.string }
      ];

      break;

    case CustomFieldType.link:
      properties = [
        { token: "name", type: CustomFieldType.string },
        { token: "url", type: CustomFieldType.string }
      ];

      break;
  }

  return { token: f.name, properties: properties, type: f.type };
}
