import { needs, inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer } from "../../models";
import { QuestionScaleMode } from "../../enums";
import { Icon } from "../icon"

@needs(Icon)
@inject
export class ScaleGroupType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(null) ariaLabelledby;

  private options: { label: string; value: number }[]  = [];
  private endPointStart = null;
  private endPointEnd = null;

  constructor(private ecb: ComponentEventBus) { }

  attached() {
    const options = this.question.Options.ScaleGroup;

    // ensure there is data
    const tempAnswerData = this.answer.Data || {};
    // this.answer.Data = this.answer.Data || {};
    for (const row of options.Items) {
      if (tempAnswerData[row.Key] == undefined) {
        tempAnswerData[row.Key] = null;
      }
    }
    this.answer.Data = tempAnswerData;

    switch (options.Mode) {
      case QuestionScaleMode.Range:
        this.endPointStart = options.Values[0].Label;
        this.endPointEnd = options.Values[1].Label;

        this.options = [];
        for (let i = options.Values[0].Value; i <= options.Values[1].Value; i++) {
          this.options.push({ label: i.toString(), value: i });
        }

        break;

      case QuestionScaleMode.Labels:
        this.options = [];
        this.options = options.Values.map(v => ({ label: v.Label, value: v.Value }));
        break;
    }
  }

  answerChanged() {
    this.attached();
  }

  change() {
    this.answer.Text = this.answer.Number != null ? `${this.answer.Number}` : null;

    this.ecb.dispatch("answer-changed");
  }

  clearAnswer() {
    this.answer.Number = null;
    for (let key in this.answer.Data) this.answer.Data[key] = null;
    this.change();
  }

  get showClearAnswer(): boolean {
    if (this.readonly) return false;
    if (this.answer.Text) return true;

    return this.answer && this.answer.Data && Object.keys(this.answer.Data).some(key => this.answer.Data[key] !== null);
  }
}
