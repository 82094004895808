import { prop, makeVueComponent } from "fw";

import { Notification } from "models/notification";

const viewerCache: { [key: string]: any } = {};

export class NotificationView {
  @prop(null) notification!: Notification;

  loading = false;
  component = null;

  created() {
    if (this.notification != null) this.loadComponent();
  }

  async loadComponent() {
    const { EventType } = this.notification;

    if (viewerCache[EventType]) {
      this.component = viewerCache[EventType];
      return;
    }

    if (this.loading) return;
    this.loading = true;

    const parts = EventType.split(":");

    if (parts.length == 2) {
      try {
        const component = await import(`./renderers/${parts[0]}/${parts[1]}`);
        viewerCache[EventType] = makeVueComponent(component.default);
      } catch (err) { }
    }

    this.component = viewerCache[EventType];
    this.loading = false;
  }
}
