import { inject } from "fw";
import { Store, handle } from "fw-state";

import { once } from "helpers/once";
import { LogoutAction, StartAction } from "./actions";
import { ContentTemplate } from "models/content-template";
import { ContentRepository } from "network/content-repository";
import { UpdateContentTemplateAction, AddContentTemplateAction, DeleteContentTemplatesAction } from "forms/content-template";
import { FormErrorHandling } from "./error-handling";
import { difference } from "lodash-es";

interface ContentShape {
  contentTemplates: ContentTemplate[];
}

export class EnsureContentStoreAction {
  constructor() { }
}

@inject
export class ContentStore extends Store<ContentShape> {
  constructor(private contentRepo: ContentRepository) {
    super();
  }

  defaultState() {
    return {
      contentTemplates: [],
    };
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureContentStoreAction)
  private async handleEnsureContentStoreAction(action: EnsureContentStoreAction) {
    await once("ensure-content-templates", async () => {
      const contentTemplates = await this.contentRepo.list();
      this.setState(state => ({
        contentTemplates: contentTemplates,
      }));
    });
  }

  @handle(AddContentTemplateAction, FormErrorHandling)
  private async handleAddContentTemplate(i: AddContentTemplateAction) {
    i.form.validate();

    const newTemplate = await this.contentRepo.post(i.form.updatedModel());
    this.setState(state => ({
      contentTemplates: [...state.contentTemplates, newTemplate],
    }));

    i.addedId = newTemplate.Id;
  }


  @handle(UpdateContentTemplateAction, FormErrorHandling)
  private async handleUpdateContentTemplate(action: UpdateContentTemplateAction) {
    action.form.validate();

    const updatedBlock = await this.contentRepo.put(
      action.form.updatedModel(),
    );

    const currentBlock = this.state.contentTemplates.find(e => e.Id == updatedBlock.Id);
    if (currentBlock == null) return;

    Object.assign(currentBlock, updatedBlock);

    this.setState(s => s);
  }

  @handle(DeleteContentTemplatesAction)
  private async handleDeleteContentTemplatesAction(action: DeleteContentTemplatesAction) {
    const cts = this.state.contentTemplates.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.contentRepo.del(cts.map(t => t.Id));

    this.setState(state => ({
      contentTemplates: difference(state.contentTemplates, cts),
    }));
  }
}
