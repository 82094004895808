import { prop, needs, ComponentEventBus } from "fw";
import { Form } from "fw-model";

import { Completer } from "helpers/auto-complete";
import { CustomFieldType } from "models/contact-organization";
import { FunctionType } from "models/function";
import { DataDictionaryField } from "models/data-dictionary";
import { CodeEditorField } from "../code-editor-field";

@needs(CodeEditorField)
export class CodeEditorFormField {
  @prop(null) public form!: Form;
  @prop(null) public field!: string;
  @prop(false) public disabled;
  @prop("htmlmixed") public mode!: string;
  @prop(true) public lineNumbers!: boolean;
  @prop(null) public completer!: Completer | Completer[];
  @prop(null) public contactType: string;
  @prop(null) public completionTypes: Array<CustomFieldType | FunctionType>;
  @prop(false) public handlebars: boolean;
  @prop(null) public changingValue!: string;
  @prop(true) public showFunctionSelector: boolean;
  @prop(true) public showFieldDictionaryLink: boolean;

  constructor(private ceb: ComponentEventBus) {}

  public get name() {
    return this.form && this.form.getFieldName
      ? this.form.getFieldName(this.field)
      : this.field;
  }

  public onChange() {
    this.ceb.dispatch("change");
  }
}
