import { prop, inject, ComponentEventBus } from "fw";
import { dispatch } from "fw-state";

import { Team } from "models/team";
import { User } from "models/user";
import { ChartDataSource } from "shared/report-runtime";
import { EnsureUsersAction, UsersStore } from "state/users";
import { PopoverService } from "service/popover";
import { TasksFieldPaths } from "service/tasks-field-paths";
import type { FormForGridViewDefinition } from "forms/report-definition";
import { SelectUserPopover } from "views/components/select-user-popover";
import {
  OptionChoice,
  OptionChooserPopover
} from "../charts/option-chooser-popover";

let inputIdIterator: number = 0;

@inject
export class GridViewEditor {
  @prop(null) widgetDefinitionForm: FormForGridViewDefinition;

  private focusedOptionId: string = '';
  private gridViewEditorBaseInputId: string = "grid-view-editor-input";
  private gridViewEditorPopoverId: string = "grid-view-editor-popover";
  listLimitOptions: { value: any; text: string }[] = [
    { text: 'Top 5', value: 5 },
    { text: 'Top 10', value: 10 },
    { text: 'Top 20', value: 20 },
  ];
  private popoverIsOpen: { [key: string]: boolean } = {
    assignedTo: false,
    listLimit: false,
    sort: false,
  };
  private selectedAssignee: User | Team = null;
  private sortFields: { Label: string; Sort: string }[] = [];

  constructor(
    private ceb: ComponentEventBus,
    private usersStore: UsersStore,
    private popoverService: PopoverService,
    private tasksFieldPaths: TasksFieldPaths
  ) { }

  async attached() {
    this.ensureUser();
    if (this.widgetDefinitionForm.DataSource === ChartDataSource.Tasks) {
      const fields = await this.tasksFieldPaths.getFields() || [];
      this.sortFields = fields.filter(f => f.Sort).map(f => { return { Label: f.Label, Sort: f.Sort } });
    }
  }

  chartChanged() {
    this.ceb.dispatch("changed");
  }

  get gridViewEditorInputId() {
    inputIdIterator++;
    return `${this.gridViewEditorBaseInputId}-${inputIdIterator}`;
  }

  ensureUser() {
    if (this.widgetDefinitionForm.UserId && this.widgetDefinitionForm.UserId.length > 0)
      dispatch(new EnsureUsersAction([this.widgetDefinitionForm.UserId]));
  }

  public updateOptionFocus(optionId: string) {
    this.focusedOptionId = optionId;
  }

  async selectUser() {
    this.popoverIsOpen.assignedTo = true;
    const res = await this.popoverService.open<string>(
      SelectUserPopover,
      {
        allowUnassigned: true,
        selectedAssignee: this.selectedAssignee,
        unassignedPlaceholder: "Current User",
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.gridViewEditorPopoverId },
    );
    this.popoverIsOpen.assignedTo = false;

    if (res.canceled) {
      return;
    }

    this.widgetDefinitionForm.UserId = res.result;

    this.ensureUser();
    this.chartChanged();
  }

  get assignedTo(): string {
    if (this.widgetDefinitionForm == null) return "";

    const userId = this.widgetDefinitionForm.UserId;
    if (userId != null) {
      const user = this.usersStore.state.userHash[userId];
      if (user != null) {
        this.selectedAssignee = user;
        return `${user.FirstName} ${user.LastName}`;
      }

      return "Loading...";
    }

    return "Current User";
  }

  get sort() {
    const sort = this.widgetDefinitionForm?.Sort;
    if (sort == null) return "Select Sort";

    const field = this.sortFields.find(f => sort === f.Sort || sort == `-${f.Sort}`);
    return field?.Label;
  }

  async selectSort() {
    const sortOptions = this.sortFields.map(f => { return { text: f.Label, value: f.Sort } });
    this.popoverIsOpen.sort = true;
    const res = await this.popoverService.open<OptionChoice<string>>(
      OptionChooserPopover,
      {
        options: sortOptions,
        searchHint: "Search Sortable",
        selectedOption: sortOptions.find(option => option.text === this.sort),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.gridViewEditorPopoverId },
    );
    this.popoverIsOpen.sort = false;

    if (res.canceled) {
      return;
    }

    this.widgetDefinitionForm.Sort = res.result.value;
    this.chartChanged();
  }

  get isAscending() {
    return this.widgetDefinitionForm?.Sort && this.widgetDefinitionForm.Sort?.indexOf("-") === -1
  }

  toggleSort() {
    this.widgetDefinitionForm.Sort = this.widgetDefinitionForm.Sort.indexOf("-") === 0
      ? this.widgetDefinitionForm.Sort.substr(1)
      : `-${this.widgetDefinitionForm.Sort}`;
    this.chartChanged();
  }

  get listLimit() {
    const opt = this.listLimitOptions.find(opt => opt.value == this.widgetDefinitionForm.ListLimit);
    return opt?.text;
  }

  async selectListLimit() {
    this.popoverIsOpen.listLimit = true;
    const res = await this.popoverService.open<OptionChoice<number>>(
      OptionChooserPopover,
      {
        options: this.listLimitOptions,
        selectedOption: this.listLimitOptions.find(option => option.text === this.listLimit),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.gridViewEditorPopoverId },
    );
    this.popoverIsOpen.listLimit = false;

    if (res.canceled) {
      return;
    }

    this.widgetDefinitionForm.ListLimit = res.result.value;
    this.chartChanged();
  }
}
