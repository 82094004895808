import { inject, prop, needs } from "fw";

import { ValueType, ValueField } from "views/components/application-filters/value-field";
import { getFriendlyTiming } from "models/date-filter";
import { DateStartedFilter as DateStartedFilterModel } from "models/application-filters";

@inject
@needs(ValueField)
export class DateStartedFilter {
  @prop(null) filter!: DateStartedFilterModel;
  @prop(false) editing!: boolean;
  @prop(() => ({})) validation;

  valueType = ValueType.Date;

  get friendlySince() {
    if (this.filter == null) return "";
    return getFriendlyTiming(this.filter);
  }
}
