import { inject } from "fw";
import { PopoverController } from "service/popover";
import { IDropdownFieldOptions } from "./dropdown-field";

@inject
export class DropdownWithFilterPopover {

  public searchTerm: string = "";
  public options: IDropdownFieldOptions[] = null;
  public selectedValue: string | number = null;
  public customLink: { text: string } = null;

  constructor(
    private controller: PopoverController<string>
  ) {}

  activate(params: { list: IDropdownFieldOptions[], selected: string | number, customLink }) {
    this.options = params?.list || [];
    this.selectedValue = params?.selected || null;
    this.customLink = params?.customLink || null;
  }

  public selectValue(value: string) {
    this.controller.ok(value);
  }

  public customLinkClick() {
    this.controller.close(false )
  }

  public searched(list: IDropdownFieldOptions[]) {
    if (list == null) {
      return [];
    }

    const st = this.searchTerm.toLowerCase();
    return list.filter(i => i.text.toLowerCase().indexOf(st) != -1);
  }

}
