import { inject } from 'fw';
import { Store, handle, dispatch } from "fw-state";
import moment from 'moment';

import { StartAction } from './actions';
import {
  ApplicationSegmentCreatedAction,
  ApplicationSegmentUpdatedAction,
  ApplicationSegmentsDeletedAction,
} from './application-segments';
import {
  ContactSegmentUpdatedAction,
  ContactSegmentsDeletedAction,
} from './contact-segments';
import { UpdateGoalAction, AddGoalAction, DeleteGoalAction } from 'forms/goal';
import { GoalDefinition, GoalValueSet } from 'models/goal';
import { DateCountHash, GoalHistoryChart, GoalHistoryPagedDictionaryResult } from 'models/goal-history';
import { GoalRepository, PostArgs } from 'network/goal-repository';

export interface GoalView {
  completionPercentage: number;
  currentValue: number;
  daysElapsed: number;
  daysRemaining: number;
  definition: GoalDefinition;
  history?: GoalHistoryChart[];
  isComplete: boolean;
  hasStarted: boolean;
}

export interface GoalsShape {
  filter: string;
  goals: GoalView[];
  loadingGoals: boolean;
}

export class LoadGoalsAction {
  constructor(public forceRefresh: boolean = false) { }
}

export class LoadGoalHistoryAction {
  constructor(public goalId: string = '') { }
}

export class FilterGoalsAction {
  constructor(public filter:string = null) { }
}

@inject
export class GoalsStore extends Store<GoalsShape> {
  constructor(
    private goalRepo: GoalRepository,
  ) {
    super();
  }

  protected defaultState(): GoalsShape {
    return {
      filter: '',
      goals: null,
      loadingGoals: false,
    };
  }

  @handle(StartAction)
  private async handleStartAction(action: StartAction) {
    this.setState((state) => ({
      ...this.defaultState(),
    }));
  }

  @handle(LoadGoalsAction)
  private async handleLoadGoalsAction(action: LoadGoalsAction) {
    if ((action.forceRefresh) || (this.state.goals == null)) {
      
      this.setState(state => ({ ...state, loadingGoals: true }));
      const goals = await this.retrieveGoalViews();
      this.setState(state => ({ ...state, loadingGoals: false }));
      
      this.filterGoals(goals);
    }
  }

  @handle(LoadGoalHistoryAction)
  private async handleLoadGoalHistoryAction(action: LoadGoalHistoryAction) {
    if ((action?.goalId)) {
      const goalId: string = action?.goalId;
      this.setState((state) => ({
        ...state,
        loadingGoals: true,
      }));
      const goalHistory = await this.retrieveHistory(goalId);
      this.setState((state) => ({
        ...state,
        loadingGoals: false,
        goals: state?.goals.map((goal: GoalView) => {
          if (goalId === goal?.definition?.Id) {
            goal.history = this.buildChartData(goalHistory, goal?.definition.TargetNumber);
          }
          return goal;
        }),
      }));
    }
  }

  @handle(AddGoalAction)
  private async handleAddGoalAction(action: AddGoalAction) {
    action.form.validate();
    const goal = action.form.updatedModel();
    const newGoal = await this.goalRepo.post(<PostArgs>{
      segmentId: goal.SegmentId,
      name: goal.Name,
      targetNumber: goal.TargetNumber,
      goalType: goal.GoalType,
      startDateUtc: goal.StartDateUtc,
      endDateUtc: goal.EndDateUtc
    });
    const goals = this.state.goals;
    const values = await this.goalRepo.counts([newGoal.Id]);
    goals.push(this.buildGoalView(newGoal, values));
    await this.filterGoals(goals);
  }

  @handle(UpdateGoalAction)
  private async handleUpdateGoalAction(action: UpdateGoalAction) {
    action.form.validate();
    const updatedGoal = action.form.updatedModel();
    await this.goalRepo.put(updatedGoal);

    const goals = this.state.goals;
    const goalIndex = goals.findIndex(existingGoal => existingGoal.definition.Id == updatedGoal.Id);
    goals.splice(goalIndex, 1);
    const values = await this.goalRepo.counts([updatedGoal.Id]);
    goals.push(this.buildGoalView(updatedGoal, values));

    dispatch(new LoadGoalHistoryAction(updatedGoal.Id));
    await this.filterGoals(goals);
  }

  @handle(DeleteGoalAction)
  private async handleDeleteGoalAction(action: DeleteGoalAction) {
    await this.goalRepo.del([action.id]);
    const goals = this.state.goals;
    const goalIndex = goals.findIndex(existingGoal => existingGoal.definition.Id == action.id);
    goals.splice(goalIndex, 1);
    await this.filterGoals(goals);
  }

  @handle(FilterGoalsAction)
  private async handleFilterGoalsAction(action: FilterGoalsAction) {
    this.setState(state => ({...state, filter: action.filter}));
    dispatch(new LoadGoalsAction(true));
  }

  // the following action listeners are just here to force a reload of the goals list
  // when segments change, as the changes to those segments might yield updates to the goal
  // counts, etc..  Would be nice to be more precise, but here we are.

  @handle(ApplicationSegmentUpdatedAction)
  @handle(ApplicationSegmentsDeletedAction)
  @handle(ContactSegmentUpdatedAction)
  @handle(ContactSegmentsDeletedAction)
  private async handleSegmentChangeAction(action:any) {
    dispatch(new LoadGoalsAction(true));
  }

  private async filterGoals(goals: GoalView[]) {
    if (this.state.filter != null && this.state.filter.length) {
      goals = goals.filter(goal => {
        if (goal.definition.Name.toLowerCase().includes(this.state.filter.toLowerCase())) {
          return goal;
        }
      });
    }
    this.setState((state) => ({
      ...state,
      goals: goals
    }));
  }

  private async retrieveGoalViews(): Promise<GoalView[]> {
    const goals = await this.goalRepo.list();
    let values: GoalValueSet = {};
    if (goals.length > 0) {
      let goalIds = goals.map(g => g.Id);
      values = await this.goalRepo.counts(goalIds);
    }
    return goals.map(goal => this.buildGoalView(goal, values));
  }

  private async retrieveHistory(goalId: string): Promise<DateCountHash> {
    let result: DateCountHash = {};
    let response: GoalHistoryPagedDictionaryResult = null;
    do {
      response = await this.goalRepo.history(goalId, response?.NextPageToken);
      if (response && response.Results) {
        result = {...result, ...response.Results};
      }
    } while (response?.NextPageToken);
    return result;
  }

  private getGoalCount(goalValue: number): number {
    return goalValue || 0;
  }

  private getGoalPercentComplete(count: number, target: number): number {
    return (count <= 0) ? 0 : Math.round(((count / target) * 100));
  }

  private buildChartData(dateCounts: DateCountHash, target: number): GoalHistoryChart[] {
    return Object.entries(dateCounts)
      .filter(([iso]: [string, number]) => iso < new Date().toISOString())
      .sort(([aDate]: [string, number], [bDate]: [string, number]) => {
        return (aDate > bDate) ? 1 : -1;                            // by ascending date
      })
      .map(([Date, Count]) => {
        const count = this.getGoalCount(Count as number);
        return {
          count,
          d3Date: null,
          date: Date,
          percent: this.getGoalPercentComplete(count, target),
        };
      });
  }

  private buildGoalView(definition: GoalDefinition, goalValues: GoalValueSet = {}): GoalView {
    const currentValue: number = Object.keys(goalValues).length && (goalValues[definition.Id] || 0);
    const completionPercentage: number = this.getGoalPercentComplete(currentValue, definition.TargetNumber);
    const startDate = moment(definition.StartDateUtc);
    const endDate = moment(definition.EndDateUtc);
    const now = moment(Date.now());
    const totalDays = Math.ceil(moment.duration(endDate.diff(startDate)).asDays());
    const daysRemaining = Math.max(0, Math.ceil(moment.duration(endDate.diff(now)).asDays()));
    const daysElapsed = Math.min(totalDays, Math.floor(moment.duration(now.diff(startDate)).asDays()));
    const hasStarted = now >= startDate;
    const isComplete = now >= endDate;

    return <GoalView>{
      completionPercentage,
      currentValue,
      daysElapsed,
      daysRemaining,
      definition,
      hasStarted,
      history: null,
      isComplete,
    };
  }
}
