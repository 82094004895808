import { inject, ComponentEventBus, prop } from "fw";

import { UsersStore, EnsureUsersAction } from "state/users";
import { dispatch } from "fw-state";

@inject
export class UserCard {
  @prop(null) public userId!: string;
  @prop(false) public allowRemove!: boolean;

  constructor(
    private usersStore: UsersStore,
    private ceb: ComponentEventBus,
  ) { }

  public async attached() {
    await dispatch(new EnsureUsersAction([this.userId]));
  }

  public async userIdChanged() {
    await dispatch(new EnsureUsersAction([this.userId]));
  }

  public get user() {
    return this.usersStore.state.userHash[this.userId];
  }

  public clear() {
    this.ceb.dispatch("clear");
  }
}
