import { ContactRestrictionType } from "models/contact-organization";
// import { AllContactRestrictions } from "./all-contact-restrictions";
import { ContactFieldRestrictions } from "./contact-field-restrictions";
import { ContactTypeVisibilityRestrictions } from "./contact-type-visibility-restrictions";

export const getContactDataRestrictionType = (type: ContactRestrictionType) => {
  switch (type) {
    case "FieldDefinition":
      return "contact-field-restrictions";
    case "ContactTypeVisibility":
      return "contact-type-visibility-restrictions";

    default: return "contact-field-restrictions" // "all-contact-restrictions";
  }
};

export const dataRestrictionComponents = [
  // AllContactRestrictions,
  ContactFieldRestrictions,
  ContactTypeVisibilityRestrictions,
];
