import { prop, ComponentEventBus, inject, needs } from "fw";
import { ContactsActivityGroupFilter } from "models/contacts-filters";
import {
  TimingFilterType,
  OccurrenceOptions,
  TimingOptions
} from "models/filter-timing";
import { FeatureFlagService } from "service/feature-flag";
import { DateRange, RangeStamp } from "views/components/date-range";

@inject
@needs(DateRange)
export class ActivityGroupFilter {
  @prop(null) filter!: ContactsActivityGroupFilter;
  @prop(() => ({})) validation;

  constructor(
    private ceb: ComponentEventBus,
    private ffs: FeatureFlagService
  ) { }

  public occurrenceOptions = OccurrenceOptions;
  public timingOptions = TimingOptions;

  public get timingFilterType() {
    return TimingFilterType;
  }

  public get occurrence() {
    return this.filter.occurrence;
  }

  public dateRangeCallback(date: RangeStamp): void {
    this.filter.timing = date.timing;
    this.filter.since = date.timing;
    this.filter.marker = date.marker;

    if (date.timing === 'date') {
      this.filter.startDate = date?.range?.startDate;
      this.filter.endDate = date?.range?.endDate;
    }

    this.ceb.dispatch("update:filter", this.filter);
    this.ceb.dispatch("filter-changed", this.filter);
  }

  public set occurrence(occurrence: string) {
    this.ceb.dispatch("update:filter", { ...this.filter, occurrence: occurrence });
    this.ceb.dispatch("filter-changed", this.filter);
  }

  public get timing() {
    return this.filter.timing;
  }

  public set timing(timing: string) {
    const filter = { ...this.filter, timing, daysFrom: "", daysTo: "", startDate: "", endDate: "" };
    this.ceb.dispatch("update:filter", filter);
    if (!(filter.timing == TimingFilterType.LastXDays || filter.timing == TimingFilterType.DayRange || filter.timing == TimingFilterType.DateRange)) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get startDate() {
    return this.filter.startDate;
  }

  public set startDate(startDate: string) {
    const filter = { ...this.filter, startDate };
    this.ceb.dispatch("update:filter", filter);
    if (filter.startDate && filter.endDate) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get endDate() {
    return this.filter.endDate;
  }

  public set endDate(endDate: string) {
    const filter = { ...this.filter, endDate };
    this.ceb.dispatch("update:filter", filter);
    if (filter.startDate && filter.endDate) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get daysFrom() {
    return this.filter.daysFrom;
  }

  public set daysFrom(daysFrom: number) {
    const filter = { ...this.filter, daysFrom };
    this.ceb.dispatch("update:filter", filter);
    // when daysFrom is blanked out it will be typeof string instead of number
    if (typeof daysFrom == "number" && filter.daysFrom >= 0 && filter.daysTo >= 0) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get daysTo() {
    return this.filter.daysTo;
  }

  public set daysTo(daysTo: number) {
    const filter = { ...this.filter, daysTo };
    this.ceb.dispatch("update:filter", filter);
    if (filter.daysFrom >= 0 && filter.daysTo >= 0) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }
}
