import { inject } from "fw";
import { createFrom, createFromProperties, cloneOf } from "fw-model";

import { ATS } from "./ats";
import { encodePdfExportTemplateSet } from "./encode";

import {
  PdfExportTemplate,
  PdfExportTemplateSet,
} from "models/pdf-export-template";

@inject
export class PdfExportTemplateSetRepository {
  constructor(private s: ATS) {}

  async get(setName: string, defaults = false) {
    const res = await this.s.get<PdfExportTemplateSet>("pdf-export-template-set", {
      setName,
      defaults,
    });

    return res.body;
  }

  async put(
    setName: string,
    templateSet: PdfExportTemplateSet,
  ): Promise<PdfExportTemplateSet> {
    const encoded = encodePdfExportTemplateSet(templateSet);
    const res = await this.s.put<any[]>(`pdf-export-template-set`, encoded, {
      setName,
    });
    return createFrom(PdfExportTemplateSet, res.body);
  }
}
