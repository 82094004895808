import { inject, prop } from "fw";

import { DateFormatter, DateFormatContext } from "shared/report-runtime";
import { CurrentOrganizationStore } from "state/current-organization";

@inject
export class FormattedDate {
  @prop(null) private date!: any;
  @prop("MMM D, YYYY") private format!: string;
  @prop(false) private fromNow!: boolean;
  @prop(false) private withSuffix!: boolean;
  @prop(true) private useTimezone!: boolean;
  @prop(false) private fromUtc!: boolean;
  @prop(false) private calendar!: boolean;
  @prop(false) private dateOnly!: boolean;
  @prop('') private defaultValue!: string;
  @prop(false) private timezoneAbbr!: boolean;

  private dateFormatter: DateFormatter = new DateFormatter();

  constructor(private currentOrganizationStore: CurrentOrganizationStore) { }

  get formatted() {
    const context: DateFormatContext = {
      format: this.format,
      fromNow: this.fromNow,
      withSuffix: this.withSuffix,
      useTimezone: this.useTimezone,
      fromUtc: this.fromUtc,
      calendar: this.calendar,
      dateOnly: this.dateOnly,
      timezoneAbbr: this.timezoneAbbr
    };

    if(this.useTimezone || this.timezoneAbbr)
      context.timezone = this.currentOrganizationStore.state.organization?.Timezone;

    return this.dateFormatter?.formatDate(this.date, context, this.defaultValue);
  }

  get timestamp() {
    if (!this.date) return null;
    const context: DateFormatContext = {
      format: "" // standard ISO format
    }
    return this.dateFormatter?.formatDate(this.date, context);
  }
}
