import { ComponentEventBus, inject, prop } from "fw";
import { Validators } from "fw-model";
import {
  type ICustomFieldDefinition,
  IdentifierMode,
} from "models/contact-organization";
import { ContactsOrganizationService } from "service/contacts-organization";
import { FieldMode } from "../field-mode";

const { required } = Validators;

@inject
export class StringType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(true) public setfocus!: boolean;
  @prop(false) public showClear!: boolean;

  public validation: string = null;

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  public attached() {
    this.ceb.dispatch("validate", this.validate);
  }

  public get metaDescription() {
    return this.showMeta
      ? this.contactOrganizationService.getFieldMetaDescription(this.field)
      : null;
  }

  public get label() {
    return this.showLabel ? this.field.display_name : null;
  }

  public get localValue() {
    return this.value;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", value && value.trim());
  }

  private validate(originalValue: string): boolean {
    if (
      originalValue &&
      this.field &&
      this.field.identifier_mode !== IdentifierMode.None
    ) {
      this.validation = required(this.value);
      return this.validation === null;
    }

    this.validation = null;
    return true;
  }
}
