import { FormForType, formFor } from "fw-model";

import { Team, TeamMember, TeamType } from "models/team";

export type TeamFormType = FormForType<Team>;

export class UpdateTeamAction {
  constructor(public form: TeamFormType) { }
}

export class AddTeamAction {
  constructor(
    public form: TeamFormType,
    public teamType: TeamType = TeamType.Evaluation,
    public seasonId?: string
  ) { }
}

export const teamMemberFormCreator = formFor(TeamMember, s => {
  s.requiredField(tm => tm.UserId, "User Id");
});

export const teamFormCreator = formFor(Team, s => {
  s.requiredField(u => u.Name, "Name");
  s.field(u => u.Category, "Category");
  s.field(u => u.AvatarFileId, "Avatar");
  s.field(u => u.WorkspaceIds, "Workspaces");

  s.formArray(u => u.Members, "Members", teamMemberFormCreator);
  s.formArray(u => u.Managers, "Managers", teamMemberFormCreator);
});
