import { FormForType, formFor } from "fw-model";

import { ApplicationSegment } from "models/application-segment";

export type FormForApplicationSegment = FormForType<ApplicationSegment>;

export class UpdateApplicationSegmentAction {
  constructor(public form: FormForApplicationSegment) {}
}

export class CreateApplicationSegmentAction {
  public createdId: string = null;

  constructor(public form: FormForApplicationSegment) {}
}

export const applicationSegmentFormCreator = formFor(ApplicationSegment, s => {
  s.requiredField(a => a.Label, "Name");
  s.requiredField(a => a.SegmentQuery, "Query (Temp)");
  s.field(a => a.Category, "Category");
  s.field(a => a.ClientData, "Client Data");
});
