import { inject } from "fw";

import { ATS } from "./ats";
import { FeatureFlagSet, OrganizationFeatureFlagSet } from 'models/feature-flag-set';

@inject
export class FeatureFlagsRepository {
  constructor(private s: ATS) {}

  public async getDefaults() {
    const res = await this.s.get<FeatureFlagSet>("feature-flag/defaults");
    return res.body;
  }

  public async putDefaults(changeSet: FeatureFlagSet) {
    const res = await this.s.put<FeatureFlagSet>("feature-flag/defaults", changeSet);
    return res.body;
  }

  public async putOrgs(orgId: string, changeSet: FeatureFlagSet) {
    const res = await this.s.put<FeatureFlagSet>(`feature-flag/${orgId}`, changeSet);
    return res.body;
  }

  public async getOrgs(orgId: string, overridesOnly = false) {
    const res = await this.s.get<FeatureFlagSet>(`feature-flag/${orgId}`, { overridesOnly });
    return res.body;
  }


}
