import { inject, needs } from "fw";
import { PopoverController } from "service/popover";

import { ApplicationRepository } from "network/application-repository";
import { ContactRepository } from "network/contact-repository";
import { IListResults } from "network/ats";
import { Contact } from "models/contact";
import { ContactsService } from "service/contacts";
import { FeatureFlagService } from "service/feature-flag";
import { AppRepository } from "network/app-repository";
import { ApplicationClientModel } from "models/application-client-model";

import { Pager } from "views/components/pager";
import { ContactTypeDropdown } from "views/contacts/components/contact-type-dropdown";

export interface TaskTargetSelectorPopoverResult {
  targetType: "Application" | "Contact",
  targetId: string;
}

@inject
@needs(Pager, ContactTypeDropdown)
export class TaskTargetSelectorPopover {
  private loading: boolean = false;
  private targetType: "Application" | "Contact" = null;
  private contactType: string = null;
  private page: number = 1;
  private targetId: string = "";
  private filter: string = "";
  private disableTypeChange: boolean = false;
  private results: IListResults<Contact | ApplicationClientModel> = null;

  constructor(
    private controller: PopoverController<TaskTargetSelectorPopoverResult>,
    private applicationRepo: ApplicationRepository,
    private appRepo: AppRepository,
    private contactRepo: ContactRepository,
    private contactsService: ContactsService,
    private ffs: FeatureFlagService
  ) { }

  async activate(params: { targetType: "Application" | "Contact"; targetId: string; filter: string; disableTypeChange: boolean }) {
    this.targetType = params.targetType || "Contact";
    this.targetId = params.targetId;
    this.disableTypeChange = params.disableTypeChange;

    if (this.targetType === 'Contact') {
      const contact = await this.contactRepo.getById(this.targetId);
      this.contactType = contact.type;
    }

    if (params.filter != null) {
      this.filter = params.filter;
      this.search()
    }
  }

  selectType(targetType: "Application" | "Contact") {
    this.targetType = targetType;
    this.results = null;
  }

  async search() {
    this.loading = true;
    if (this.targetType == "Application" && this.filter) {
      let sliceTo = this.page > 1 ?  this.page * 10 : 10;
      let sliceFrom = this.page > 1 ? sliceTo - 10 : 0;

      const res = await this.applicationRepo.search(this.filter);
      const ids = res.Ids.slice(sliceFrom, sliceTo);
      const clientModels = await this.appRepo.clientModelList(ids);
      this.results = { total: res.Total, results: clientModels };
    }
    else if (this.targetType == "Contact" && this.filter) {
      this.results = await this.contactRepo.list(null, this.filter, null, null, this.page, null, this.contactType);
    }
    this.loading = false;
  }
  contactTypeChanged()  {
    this.results = { total: 0, results: [] }
    this.filter = "";
  }

  private setPage(page: number): void {
    this.page = page;
    this.search();
  }

  get searchString() {
    return this.targetType ? `Search ${this.targetType}s` : 'Select Type';
  }

  selectTarget(targetId: string) {
    if (targetId.length == 0) return;
    this.controller.ok({ targetType: this.targetType, targetId: targetId });
  }

  remove() {
    this.controller.ok({ targetType: null, targetId: null });
  }
}
