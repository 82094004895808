import { ComponentEventBus, inject, needs, prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { FieldMode } from "../field-mode";
import { MultiselectSelector } from "views/components/multiselect-selector";

import { orderBy } from "lodash-es";
import { LocalStorageCache } from "caching";
import { CurrentUserStore } from "state/current-user";
import { ContactsOrganizationService } from "service/contacts-organization";
import { generateDataId } from "helpers/data-id-gen";
import { ContactFieldOptionsResolverService } from "views/contacts/contact-field-options-resolver-service";
import { FieldContext } from "../field";

@inject
@needs(MultiselectSelector)
export class MultiselectType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string[];
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public isFilter!: boolean;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(false) public showClear!: boolean;
  @prop(null) private context!: FieldContext;

  public options: string[] = [];

  constructor(
    private ceb: ComponentEventBus,
    private cache: LocalStorageCache,
    private currentUserStore: CurrentUserStore,
    private contactOrganizationService: ContactsOrganizationService,
    private contactFieldOptionsResolver: ContactFieldOptionsResolverService
  ) {}

  public async attached() {
    await this.processOptions();
  }

  private async processOptions() {
    if (this.mode !== FieldMode.Edit) {
      return;
    }

    if (!this.field || !this.field.options){
      return;
    }

    let fieldOptions = orderBy(this.field.options, "display_order");

    if(!this.isFilter && this.context.contact) {
      fieldOptions = await this.contactFieldOptionsResolver.resolveOptions(this.context.contact, fieldOptions);
    }

    this.options = fieldOptions.map((o) => o.name);
  }

  public async fieldChanged() {
    await this.processOptions();
  }

  public get dataId() {
    return generateDataId(null, this.field.display_name);
  }

  public get metaDescription() {
    return this.contactOrganizationService.getFieldMetaDescription(this.field);
  }

  public get localValue() {
    return this.value;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", value);
  }

  public get editable(): boolean {
    return this.isFilter || (this.field && !this.field.is_read_only && !this.disabled);
  }

  public clear() {
    this.ceb.dispatch("update:value", []);
  }
}
