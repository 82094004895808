export const hashOn = <T, T2>(list: T[], keyFn: (item: T) => string, valueFn: (item: T) => T2): { [key: string]: T2 } => {
  const h: { [key: string]: T2 } = {};

  if (list != null)
    list.forEach(i => h[keyFn(i)] = valueFn(i));

  return h;
};

export const hashOnId = <T extends { Id: string }>(list: T[]): { [key: string]: T } => {
  const h: { [key: string]: T } = {};

  if (list != null)
    list.forEach(i => h[i.Id] = i);

  return h;
};
