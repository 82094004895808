import { dispatch } from "fw-state";
import { ReplaceTokenAction } from "state/actions";
import { NetworkResponseMiddleware, ResponseContext } from "fw";

export const REPLACE_TOKEN_HEADER_NAME = "x-ats-replace-token";

export class ReplaceTokenMiddleware implements NetworkResponseMiddleware {
  onResponse(context: ResponseContext) {
    if (context.headers[REPLACE_TOKEN_HEADER_NAME] == null) return;

    const token = context.headers[REPLACE_TOKEN_HEADER_NAME];
    dispatch(new ReplaceTokenAction(token));
  }
}