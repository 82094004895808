import { keys } from "lodash-es";
const loaderResolvers = {};
const loaded: { [key: string]: any } = {};

export const once = async (key: string, loader: () => Promise<void>) => {
  if (loaded[key]) return;

  if (loaderResolvers[key] === undefined) {
    loaderResolvers[key] = [];
    if (loader) {
      await loader();
      for (const resolver of loaderResolvers[key]) {
        resolver();
      }
      loaderResolvers[key] = [];
      loaded[key] = true;

      return;
    }

  } else {
    await new Promise<void>((resolver) => {
      loaderResolvers[key].push(resolver);
    });

  }
}

export const resetAllOnce = () => {
  for (const key of keys(loaderResolvers)) {
    delete loaderResolvers[key];
  }
  for (const key of keys(loaded)) {
    delete loaded[key];
  }
}
