import { prop } from "fw";
import { TaskTargetTypeFilter as TaskTargetTypeFilterModel } from "models/task-filters";

export class TaskTargetTypeFilter {
  @prop(null) filter!: TaskTargetTypeFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  targetTypes = [
    { value: "Contact", text: "Contacts" },
    { value: "Application", text: "Applications" },
  ];

  get targetText() {
    if (!this.filter?.targetType)
      return "";
    return this.targetTypes.find(f => f.value === this.filter.targetType).text;
  }
}
