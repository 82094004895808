import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { Role } from "models/role";

@inject
export class RoleRepository {
  constructor(private s: ATS) { }

  async list() {
    const res = await this.s.get<any[]>("role");
    return createFromArray(Role, res.body);
  }

  async post(role: Role) {
    const res = await this.s.post("role", role);
    return createFrom(Role, res.body);
  }

  async put(role: Role, force: boolean = false) {
    const res = await this.s.put(`role/${role.Id}${force ? "?force=true" : ""}`, role);
    return createFrom(Role, res.body);
  }

  async del(id: string) {
    await this.s.delete(`role/${id}`);
  }
}
