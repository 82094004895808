import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS, IListResults, ICountResult } from "./ats";
import { ContactsDataSourceInstance, ImportType, IMapField, IFileUploadConfiguration, IConfiguredCustomMapping, IExpandedDataSourcesResult } from "models/contacts-data-source-instance";
import { ReplaceOperation, Operation } from '../../node_modules/fast-json-patch/lib/core';
import { IManualMapping } from "models/contacts-data-source";

@inject
export class DataSourceInstanceRepository {
  constructor(private s: ATS) {}

  public async list(q: string = null, f: string = null, aggs: string = null, sort: string = null, page = 1, limit = 10) : Promise<IListResults<ContactsDataSourceInstance>> {
    return this.s.contacts.list("data-source-instances", ContactsDataSourceInstance, q, f, aggs, sort, page, limit);
  }

  public async count(f: string = null, aggs: string = null) : Promise<ICountResult> {
    return this.s.contacts.count("data-source-instances/count", f, aggs);
  }

  public async getById(id: string) : Promise<ContactsDataSourceInstance> {
    const res = await this.s.contacts.get<ContactsDataSourceInstance>(`data-source-instances/${id}`);
    return createFrom(ContactsDataSourceInstance, res.body);
  }

  public async enable(dataSourceInstance: ContactsDataSourceInstance, params: any = null) : Promise<ContactsDataSourceInstance> {
    await this.s.contacts.post<void>(`data-source-instances/${dataSourceInstance.id}/enable`, params, null);
    dataSourceInstance.is_enabled = true;
    return dataSourceInstance;
  }

  public async disable(dataSourceInstance: ContactsDataSourceInstance, params: any = null) : Promise<ContactsDataSourceInstance> {
    await this.s.contacts.post<void>(`data-source-instances/${dataSourceInstance.id}/disable`, params, null);
    dataSourceInstance.is_enabled = false;
    return dataSourceInstance;
  }

  public async setImportType(dataSourceInstanceId: string, importType: ImportType) : Promise<ImportType> {
    await this.s.contacts.post<void>(`data-source-instances/${dataSourceInstanceId}/import-type`, JSON.stringify(importType));
    return importType;
  }

  public async post(dataSourceInstance: ContactsDataSourceInstance): Promise<ContactsDataSourceInstance> {
    const res = await this.s.contacts.post<ContactsDataSourceInstance>("data-source-instances", dataSourceInstance);
    return createFrom(ContactsDataSourceInstance, res.body);
  }

  public async patch(id: string, data: Operation[]): Promise<ContactsDataSourceInstance> {
    const res = await this.s.contacts.patch<ContactsDataSourceInstance>(`data-source-instances/${id}`, data);
    return createFrom(ContactsDataSourceInstance, res.body);
  }

  public async remove(dataSourceInstance: ContactsDataSourceInstance) {
    await this.s.contacts.delete(`data-source-instances/${dataSourceInstance.id}`);
  }

  public async enqueue(dataSourceInstance: ContactsDataSourceInstance) : Promise<void> {
    await this.s.contacts.post<void>(`data-source-instances/${dataSourceInstance.id}/enqueue`, { });
  }

  public async getDispatchCall<T>(dataSourceInstance: ContactsDataSourceInstance, method: string, params: any = null) : Promise<T> {
    const res = await this.s.contacts.get<T>(`data-source-instances/${dataSourceInstance.id}/call/${method}`, params);
    return res.body;
  }

  public async dispatchCall<T>(dataSourceInstance: ContactsDataSourceInstance, method: string, params: any = null) : Promise<T> {
    const res = await this.s.contacts.post<T>(`data-source-instances/${dataSourceInstance.id}/call/${method}`, params, null);
    return res.body;
  }

  public async setValue(dataSourceInstance: ContactsDataSourceInstance, key: string, value: any) : Promise<void> {
    await this.s.contacts.post<void>(`data-source-instances/${dataSourceInstance.id}/config/${key}`, value);
    dataSourceInstance.data[key] = value;
  }

  public async createMappingConfig(dataSourceInstance: ContactsDataSourceInstance, config: IConfiguredCustomMapping) : Promise<ContactsDataSourceInstance> {
    const res = await this.s.contacts.post<ContactsDataSourceInstance>(`data-source-instances/${dataSourceInstance.id}/mapping-config`, config);
    return res.body;
  }

  public async getExpandedDataSources(f: string = null) : Promise<IExpandedDataSourcesResult[]> {
    const params: any = {};
    if (f != null && f.length > 0) {
      params.f = f;
    }
    const res = await this.s.contacts.get<IExpandedDataSourcesResult[]>("data-source-instances/expanded", params);
    return res.body;
  }

  public async createFileUploadMappingConfig(contactType: string, config: IConfiguredCustomMapping) : Promise<ContactsDataSourceInstance> {
    let uploadConfig: IFileUploadConfiguration = { contact_type: contactType, mapping_configuration: config };
    const res = await this.s.contacts.post<ContactsDataSourceInstance>("data-source-instances/file-upload/config", uploadConfig);
    return res.body;
  }

  public async renameFileUploadMappingConfig(dataSourceInstanceId: string, newName: string) : Promise<ContactsDataSourceInstance> {
    const operation: ReplaceOperation<string> = { op: 'replace', path: 'name', value: newName};
    const res = await this.s.contacts.patch<ContactsDataSourceInstance>(`data-source-instances/${dataSourceInstanceId}`, [operation]);
    return res.body;
  }

  public async renameFileWithManualMappingUploadMappingConfig(dataSourceInstanceId: string, newName: string, mapping: IManualMapping): Promise<ContactsDataSourceInstance> {
    const updateNameOperation: ReplaceOperation<string> = { op: 'replace', path: 'name', value: newName };
    const updateManualMappingOperation: ReplaceOperation<IManualMapping> = { op: 'replace', path: '/data/ManualMapping', value: mapping };
    const res = await this.s.contacts.patch<ContactsDataSourceInstance>(`data-source-instances/${dataSourceInstanceId}`, [updateNameOperation, updateManualMappingOperation]);
    return res.body;
  }

  public async listPreConfiguredFileUploadMappingConfigs(dataSourceTypeId: string) : Promise<ContactsDataSourceInstance[]> {
    const res = await this.s.contacts.list<ContactsDataSourceInstance>('data-source-instances',
      ContactsDataSourceInstance, null, `data_source_id:${dataSourceTypeId}`, null, null, 1, 1000);
    return res.results as ContactsDataSourceInstance[];
  }

  public async getCustomFields(dataSourceInstance: ContactsDataSourceInstance) : Promise<IMapField[]> {
    const res = await this.s.contacts.get<IMapField[]>(`data-source-instances/${dataSourceInstance.id}/discover-custom-fields`);
    return res.body;
  }

  public async getValue(id: string, key: string) : Promise<{ key: string, value: string }> {
    const res = await this.s.contacts.get<{ key: string, value: string }>(`data-source-instances/${id}/config/${key}`);
    return res.body;
  }
}
