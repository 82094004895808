import { fromClass, fromClassArray } from "fw-model";

import { ProgramStepTypeCode } from "./program";
import { ApplicationPropertyValue } from "./application-property-value";
import { FormAnswer } from "./form";
import {ApplicationReference} from 'models/application-reference';
export { FormAnswer } from "./form";

export enum EmailReviewType {
  FULL = "full",
  CURRENT_USER_ASSIGNED = "assigned",
  CURRENT_USER_REVIEWED = "reviewed"
}

export class ApplicationCommentMetaData {
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  OrganizationUserId: string;
  ApplicationId: string;
}

export class ApplicationComment {
  Id: string;
  Comment: string;
  ExternalId: string;
  ExternalUsername: string;
  @fromClass MetaData: ApplicationCommentMetaData;
}

export class SearchList {
  Ids: string[];
  Aggregations: any;
  PreviousPageToken: string;
  NextPageToken: string;
  AsyncQueryId: string;
  Total: number;
}

export class ClientModelSearchResult {
  Results: any[];
  Aggregations: any;
  PreviousPageToken: string;
  NextPageToken: string;
  AsyncQueryId: string;
  Total: number;
}

export class ContractionInfo {
  Count: number
  ContractableCount: number
  ContractableMappingsCount: number
  //public Dictionary<QuestionTypeCode, int> FieldsContractedByType { get; set; }
}

export class ValidationItem {
  Path: string;
  Message: string;
}

export interface MetaDataValidation {
  IsValid: boolean;
  ValidationSummary: ValidationItem[];
}

export class ApplicationAttachmentMetaData {
  UserId: string;
  DateCreatedUTC: string;
  IsValid: boolean;
  ValidationSummary: ValidationItem[];
}

export class ApplicationAttachment {
  Id: string;
  Fields: { [key: string]: string };
  FileId: string = null;
  Title: string = null;

  @fromClass MetaData: ApplicationAttachmentMetaData;
}

export class ApplicationStepMetaData implements MetaDataValidation {
  ProgramStepId: string;
  StepType: ProgramStepTypeCode;
  IsSuspect: boolean;
  IsComplete: boolean;

  IsValid: boolean;
  @fromClassArray(ValidationItem) ValidationSummary: ValidationItem[];
}

export class ApplicationFormMetaData {
  IsLocked: boolean;
  FormId: string;
  FormVersion: number;
  SeenQuestionKeys: string[];
}

export class CalculatedFieldValue {
  FieldKey: string;
  Value: string;
  NumberValue: number;
}

export class ApplicationFormData {
  @fromClassArray(FormAnswer) Answers: FormAnswer[];
  @fromClass MetaData: ApplicationFormMetaData;
  @fromClassArray(CalculatedFieldValue)
  CalculatedValues: CalculatedFieldValue[];
}

export class ApplicationPortfolioItemMetaData implements MetaDataValidation {
  IsValid: boolean;
  @fromClassArray(ValidationItem) ValidationSummary: ValidationItem[];
}

export class ApplicationPortfolioItem {
  Fields: { [key: string]: string };

  FileId: string;
  ExternalKey: string;

  @fromClass MetaData: ApplicationPortfolioItemMetaData;
}

export class ApplicationPortfolioData {
  @fromClassArray(ApplicationPortfolioItem)
  ItemList: ApplicationPortfolioItem[];
}

export class ApplicationReferenceRequestMetaData implements MetaDataValidation {
  IsComplete: boolean;
  ReferenceId: string;
  DateCreatedUTC: string;
  DateSentUTC: string;
  DateOpenedUTC: string;
  DateCompletedUTC: string;

  IsValid: boolean;
  @fromClassArray(ValidationItem) ValidationSummary: ValidationItem[];
}

export class ApplicationReferenceRequest {
  Id: string;
  Name: string;
  EmailAddress: string;
  PersonalMessage: string;

  WaiveRight: boolean;

  @fromClass MetaData: ApplicationReferenceRequestMetaData;
}

export class ApplicationReferenceSetMetaData implements MetaDataValidation {
  IsValid: boolean;
  @fromClassArray(ValidationItem) ValidationSummary: ValidationItem[];
}

export class ApplicationReferenceSetStep {
  @fromClassArray(ApplicationReferenceRequest)
  ReferenceList: ApplicationReferenceRequest[] = [];
  @fromClass MetaData: ApplicationReferenceSetMetaData;
}

export class ApplicationDocumentStep {
  DocumentIdList: string[];
}

export class ApplicationFolderItemMetaData {
  IsValid: boolean;
  ValidationSummary: ValidationItem[];

  FormId: string;
  FormVersion: number;
  SeenQuestionKeys: string[];
}

export class ApplicationFolderItem {
  @fromClassArray(FormAnswer) Answers: FormAnswer[];
  @fromClassArray(CalculatedFieldValue) CalculatedValues: CalculatedFieldValue[];

  FileId: string = null;
  ExternalKey: string = null;

  @fromClass MetaData: ApplicationFolderItemMetaData;
}

export class ApplicationFolderData {
  @fromClassArray(ApplicationFolderItem) ItemList: ApplicationFolderItem[];
}

export class ApplicationDecisionLetterStep {
  Placeholder: string;
}

export class ApplicationStep {
  Id: string;
  WaiveRequirement: boolean;

  @fromClass Form: ApplicationFormData;
  @fromClass Portfolio: ApplicationPortfolioData;
  @fromClass ReferenceSet: ApplicationReferenceSetStep;
  @fromClass Documents: ApplicationDocumentStep;
  @fromClass Folder: ApplicationFolderData;
  @fromClass DecisionLetter: ApplicationDecisionLetterStep;

  @fromClass MetaData: ApplicationStepMetaData;
}

export class ApplicationStepGroupMetaData {
  ProgramStepGroupId: string;
  DateFirstSubmittedUTC: string;
  DateSubmittedUTC: string;
  PaymentToken: string;
  PaymentGatewayId: string;
  PendingPayment: [];
}




export class ApplicationStepGroup {
  Id: string;
  IsSubmitted: boolean;
  WaiveDeadline: boolean;
  WaiveFee: boolean;
  WaivedBy: string;
  WaivedOn: string;

  @fromClass MetaData: ApplicationStepGroupMetaData;
  @fromClassArray(ApplicationStep) StepList: ApplicationStep[];
}

export enum PaymentStatus
{
    WaivedByUser = 0,
    WaivedByRule = 1,
    Paid = 2,
    Failed = 3
}

export class ApplicationMetaData {
  ApplicantId: string;
  OrganizationId: string;
  ProgramId: string;
  SeasonId: string;
  StageId: string;
  PhaseId: string;
  PublicId: string;
  DecisionId: string;

  DateCreatedUTC: string;
  DateFirstSubmittedUTC: string;
  DateSubmittedUTC: string;
  DateStartedUTC: string;
  DateLastApplicantChangedUTC: string;
  DateUpdatedUtc: string;
  ErrorMessages: string[];
}

export class DecisionLetter {
  DateUpdatedUtc: Date;
  DecisionLetterTemplateSetId: string;
  FileId: string;
  RevealedAtUtc: string;
}

export class Application {
  Id: string;
  IsDeleted: boolean;
  Tags: string[];

  @fromClass MetaData: ApplicationMetaData;
  @fromClass DecisionLetter: DecisionLetter;

  @fromClassArray(CalculatedFieldValue)
  CalculatedValues: CalculatedFieldValue[];

  @fromClassArray(ApplicationAttachment) Attachments: ApplicationAttachment[];
  @fromClassArray(ApplicationStepGroup) StepGroups: ApplicationStepGroup[];

  @fromClassArray(ApplicationPropertyValue)
  PropertyValues: ApplicationPropertyValue[];
}

export class ApplicationCopyResult {
  Application: Application;
  References: ApplicationReference[];
  Messages: { StepType: ProgramStepTypeCode; Message: string }[];
}

export class ApplicationPreviewContentSection {
  Name: string;
}
