import { prop, inject } from "fw";

import { TaskRequestStore } from "state/task-request";

@inject
export class TaskProgress {
  @prop(null) taskId!: string;
  @prop(true) showName!: boolean;
  @prop(null) name!: string;

  constructor(private taskRequestStore: TaskRequestStore) { }

  get task() {
    if (this.taskId == null) return null;
    const task = this.taskRequestStore.state.runningTasks.find(a => a.task.Id == this.taskId);
    if (task != null) return task;

    // fall back to the finished list
    const finished = this.taskRequestStore.state.finishedTasks.find(a => a.Id == this.taskId);
    if (finished == null) return null;
    return {
      task: finished,
      name: finished.Type,
    };
  }

  get progress() {
    if (this.task == null) return null;
    return `${(this.task.task.CompletedActions / this.task.task.TotalActions) * 100}%`;
  }

  get resolvedName() {
    if (this.name != null && this.name.length > 0) return this.name;

    return this.task?.name;
  }
}
