import { inject } from "fw";

import { ATS } from "./ats";
import { LoginResult } from "models/misc";

@inject
export class ApplicantRepository {
  constructor(private s: ATS) {}

  async impersonateApplicant(applicantId: string) {
    const res = await this.s.post<LoginResult>(
      `applicant/${applicantId}/impersonate`,
      null,
    );
    return res.body.BearerToken;
  }

  async impersonateApplicantAccount(applicantAccountId: string) {
    const res = await this.s.post<LoginResult>(
      `applicant-account/impersonate/${applicantAccountId}`,
      null,
    );
    return res.body.BearerToken;
  }

  async putPropertyValues(
    applicantIds: string[],
    propertyValues: { [key: string]: any },
  ) {
    await this.s.put(`applicant/property-values`,  [ {
      applicantIds,
      propertyValues,
    }]);
  }
}
