import { prop, ComponentEventBus, inject } from "fw";

let uniqueId = 0;

@inject
export class CheckboxField {
  @prop(null) private label;
  @prop(null) private value;
  @prop(null) private valueList;
  @prop(null) private validation;
  @prop(null) private readonly;
  @prop(false) private disabled;

  private uniqueId = uniqueId++;
  private isFocused = false;

  constructor(private ceb: ComponentEventBus) { }

  public makeId() {
    return `${ this.label ? this.label.replace(/\s/g,'') : "" }-cb-${ this.uniqueId }`
  }

  public onFocus(e) {
    this.isFocused = true;
    this.ceb.dispatch("focus", e);
  }

  public onBlur(e) {
    this.isFocused = false;
    this.ceb.dispatch("blur", e);
  }

  public onInput(value) { 
    this.ceb.updateModel(value); 
    this.ceb.dispatch("changed", value);
  }

  get pValueList() {
    return this.valueList;
  }

  set pValueList(m) {
    this.ceb.dispatch("update:valueList", m);
  }

  get isChecked() {
    if (this.valueList != null) {
      return this.valueList.includes(this.value);
    }

    return this.value;
  }
}
