import { prop, needs } from "fw";

import { ValueType, ValueField } from "views/components/application-filters/value-field";

type DecisionLetterRevealOption = { text: string, value: boolean };

const DECISION_LETTER_OPTIONS: DecisionLetterRevealOption[] = [
  { text: "Revealed", value: true },
  { text: "Revealed in period", value: null },
  { text: "Not Revealed", value: false },
];

@needs(ValueField)
export class DecisionLetterRevealedFilter {
  @prop(() => ({})) validation;
  @prop(null) filter;
  @prop(false) editing!: boolean;

  private decisionLetterRevealOptions: DecisionLetterRevealOption[] = DECISION_LETTER_OPTIONS;
  private valueType = ValueType.Date;

  get selectedLabel(): string {
    return this.decisionLetterRevealOptions
               .find(item => item.value === this.filter.decisionLetterRevealed)?.text ?? "None";
  }
}

