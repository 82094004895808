import { inject, Navigator, needs, RouterConfig } from "fw";
import { dispatch } from "fw-state";
import { DialogService } from "fw-dialog";

import { Notification as Notifier } from "service/notification";
import { ActionBar } from "views/components/action-bar";

import {
  EnsureNotificationsStoreAction,
  NotificationsStore,
  DeleteNotificationsAction,
  MarkAsReadNotificationsAction,
  MarkAsStarredNotificationsAction,
  MarkAllAsReadNotificationsAction,
  RefreshNotificationsStoreAction,
  FilterNotificationsListAction,
  SortNotificationsListAction,
} from "state/notifications";
import { Notification } from "models/notification";
import {
  NotificationDialogOptions,
  NotificationDialog,
} from "./notification-dialog";
import { NotificationRepository } from "network/notification-repository";
import {
  UserNotificationSettingsFormType,
  UserSeasonNotificationSettingsFormCreator,
  UpdateUserNotificationSettingsAction,
} from "forms/user";
import { CurrentUserSettingsStore } from "state/current-user-settings";
import { NotificationView } from "./notification-view";
import { PopoverService } from "service/popover";
import { EmailSettings } from "./email-settings";
import { Pager } from "views/components/pager";
import { debounce } from "lodash-es";
import { SearchFilter } from "views/components/search-filter";
import { GridBulkMenu } from "views/components/grid-bulk-menu";
import { CurrentOrganizationStore } from "state/current-organization";

@inject
@needs(ActionBar, GridBulkMenu, NotificationView, Pager, SearchFilter)
export class Notifications {
  public searchTerm = "";
  public ids: string[] = [];
  public checkedAll = false;
  private userNotificationSettingsForm: UserNotificationSettingsFormType = null;
  public debouncedFilter = null;
  public router;

  public currentNotification: Notification = null;

  constructor(
    private notificationsStore: NotificationsStore,
    private currentUserSettingsStore: CurrentUserSettingsStore,
    private dialogService: DialogService,
    private nav: Navigator,
    private notifier: Notifier,
    private notificationRepo: NotificationRepository,
    private popoverService: PopoverService,
    private currentOrganizationStore: CurrentOrganizationStore
  ) {}

  public registerRoutes(r: RouterConfig) {
    this.router = r;
  }

  public async activate(params) {
    await dispatch(new EnsureNotificationsStoreAction());
    this.userNotificationSettingsForm =
      UserSeasonNotificationSettingsFormCreator(
        this.currentUserSettingsStore.state.userSeasonSettings
          .NotificationSettings
      );
    if (params.id != null) {
      const notification = this.notificationsStore.state.notifications.find(
        (n) => n.Id == params.id
      );
      if (notification != null) {
        this.setCurrentNotification(notification);
      } else {
        const retreivedNotification = await this.notificationRepo.get(
          params.id
        );
        this.setCurrentNotification(retreivedNotification);
      }
    }
    this.searchTerm = this.notificationsStore.state.search || "";
  }

  public created() {
    this.debouncedFilter = debounce(this.search, 250);
  }

  public get total() {
    return this.notificationsStore.state.total;
  }

  public get page() {
    return this.notificationsStore.state.page;
  }

  public get loading() {
    return this.notificationsStore.state.loading;
  }

  public get pageSize() {
    return this.notificationsStore.state.pageSize;
  }

  public get notifications() {
    return this.notificationsStore.state.notifications;
  }

  public get sort() {
    return this.notificationsStore.state.sort;
  }

  public getSortIcon(columnName: string) {
    return this.sort === columnName ? 'arrow_drop_up' : 'arrow_drop_down';
  }

  public isSorted(columnName: string) {
    return this.sort.includes(columnName);
  }

  public async search() {
    await dispatch(new FilterNotificationsListAction(this.searchTerm));
    this.currentNotification = null;
    this.ids = [];
  }

  public async toggleSort(sort: string) {
    await dispatch(new SortNotificationsListAction(sort));
    if (this.currentNotification != null) {
      this.currentNotification = this.notificationsStore.state.notifications[0];
    }
  }

  public get unreadCount() {
    return this.notificationsStore.state.profile.Unread;
  }

  public checkAllChange() {
    if (this.checkedAll) {
      this.ids = this.notificationsStore.state.notifications.map((n) => n.Id);
    } else {
      this.ids = [];
    }
  }

  public async nextPage() {
    this.notificationsStore.state.page++;
    await dispatch(new RefreshNotificationsStoreAction());
    if (this.currentNotification != null) {
      this.currentNotification = this.notificationsStore.state.notifications[0];
    }
  }

  public async prevPage() {
    if (this.notificationsStore.state.page < 1) {
      return;
    }
    this.notificationsStore.state.page--;
    await dispatch(new RefreshNotificationsStoreAction());
    if (this.currentNotification != null) {
      this.currentNotification = this.notificationsStore.state.notifications[0];
    }
  }

  public async emailSettings() {
    const res =
      await this.popoverService.open<UserNotificationSettingsFormType>(
        EmailSettings,
        { form: this.userNotificationSettingsForm }
      );
    if (res.canceled) return;
    this.saveNotificationSettings();
  }

  public get notificationSetting(): { text; icon } {
    const { Enabled, Digest } =
      this.currentUserSettingsStore.state.userSeasonSettings
        .NotificationSettings.Email;
    if (!Enabled) {
      return { text: "Don't email me", icon: "notifications_off" };
    } else if (!Digest) {
      return { text: "Email each time I have notifications", icon: "check" };
    } else {
      return {
        text: "Email me a daily summary of notifications",
        icon: "check",
      };
    }
  }

  public gotoNotification(notification: Notification) {
    this.nav.navigate(`/notifications/${notification.Id}`);
  }

  public setCurrentNotification(notification: Notification) {
    this.currentNotification = notification;
    if (this.currentNotification.MetaData.IsRead) return;
    dispatch(
      new MarkAsReadNotificationsAction([this.currentNotification.Id], true)
    );
  }

  public async open(notification: Notification) {
    const options: NotificationDialogOptions = {
      notification: notification,
    };

    this.dialogService.open(NotificationDialog, options);
  }

  public async saveNotificationSettings() {
    try {
      await dispatch(
        new UpdateUserNotificationSettingsAction(
          this.userNotificationSettingsForm.updatedModel()
        )
      );
      this.notifier.notify("Your notification preferences have been saved.");
    } catch (err) {
      this.notifier.error(`Couldn't save notification settings.`);
    }
  }

  public async deleteChecked() {
    const count = this.ids.length;
    if (count == 0) return;

    try {
      await dispatch(new DeleteNotificationsAction(this.ids));
      this.ids = [];
      this.checkedAll = false;
      this.notifier.notify(
        `${count} notification${count > 1 ? "s" : ""} deleted.`
      );
    } catch (err) {
      this.notifier.error(`Encountered an error trying to delete.`);
    }
  }

  public async markIsRead(isRead: boolean) {
    const count = this.ids.length;
    if (count == 0) return;

    try {
      await dispatch(new MarkAsReadNotificationsAction(this.ids, isRead));
      this.ids = [];
      this.checkedAll = false;
      this.notifier.notify(
        `${count} notification${count > 1 ? "s" : ""} marked as ${
          isRead ? "read" : "unread"
        }.`
      );
    } catch (err) {
      this.notifier.error(`Encountered an error trying to mark as read.`);
    }
  }

  public async markAllRead() {
    try {
      await dispatch(new MarkAllAsReadNotificationsAction(new Date(), true));
      this.notifier.notify(`All notifications marked as read.`);
    } catch (err) {
      this.notifier.error(`Encountered an error trying to mark as read.`);
    }
  }

  public star(notification: Notification, isStarred: boolean) {
    dispatch(
      new MarkAsStarredNotificationsAction([notification.Id], isStarred)
    );
  }

  public async markIsStarred(isStarred: boolean) {
    const count = this.ids.length;
    if (count == 0) return;

    try {
      await dispatch(new MarkAsStarredNotificationsAction(this.ids, isStarred));
      this.ids = [];
      this.checkedAll = false;
      this.notifier.notify(
        `${isStarred ? "Starred" : "Unstarred"} ${count} notification${
          count > 1 ? "s" : ""
        }.`
      );
    } catch (err) {
      this.notifier.error(`Encountered an error trying to mark as read.`);
    }
  }
}

export default Notifications;
