import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { ChartingService, funnelChart, ChartData } from "slideroom-charts";
import { funnelFillColorPalette } from "../../util";

@inject
export class FunnelChart {
  @prop(null) data!: ChartData[];
  @prop(false) interactive!: boolean;
  @prop("Funnel chart") svgAlt: string;

  private svgElement: SVGElement = null;

  private height = 400;

  constructor(
    private ceb: ComponentEventBus,
    private charting: ChartingService,
  ) { }

  attached() {
    this.charting.setColors(funnelFillColorPalette);
    this.buildChart();
    window.addEventListener("resize", this.buildChart);
  }

  detached() {
    window.removeEventListener("resize", this.buildChart);
  }

  buildChart() {
    this.svgElement.setAttribute("width", "100%");
    this.charting.drawChart(funnelChart, this.svgElement, this.data, this.interactive, (data) => {
      if (!this.interactive) return;
      this.ceb.dispatch("data-click", data);
    });
  }

  color(i: number) {
    return this.charting.color(i);
  }

  dataChanged() {
    this.buildChart();
  }

  isMissing(text: string) {
    return text == "__missing__";
  }
}
