import { prop } from "fw";
import { ContactsNameFilter as ContactsNameFilterModel } from "models/contacts-filters";

export class ContactsNameFilter {
  @prop(null) filter!: ContactsNameFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  get isValidName(): boolean {
    if (this.filter.name || this.filter.first || this.filter.last) {
      return true;
    }
    return false;
  }
}
