import { prop, ComponentEventBus, inject, needs } from "fw";
import { PopoverService, PopoverCoordinator } from "service/popover";
import { ColorPickerWrapper } from "./color-picker-wrapper";

let uniqueId = 0;

@inject
@needs(ColorPickerWrapper)
export class ColorField {
  constructor(
    private componentEventBus: ComponentEventBus,
    private popover: PopoverService,
    private coordinator: PopoverCoordinator
  ) {
  }

  @prop(null) public label!: string;
  @prop(false) public hideLabel!: boolean;
  @prop("") public placeholder!: string;
  @prop(null) public value;
  @prop(null) public validation!: string;
  @prop(null) public readonly!: boolean;
  @prop(null) public required: boolean;
  @prop(null) public setfocus;
  @prop(false) public disabled!: boolean;
  @prop(false) showReset!: boolean;
  @prop(false) public floatingLabel!: boolean;
  @prop(null) public ariaLabel!: string;
  @prop(null) public helpText!: string;
  @prop(null) public defaultValue: string;

  private uniqueId = uniqueId++;

  private isFocused = false;
  private swatchEl;
  private colorPicker = null;
  private hinting: boolean = false;

  public attached() {

    if (this.setfocus === true) {
      setTimeout(() => this.swatchEl.focus(), 50);
    }

  }

  public detached() {

    if (this.colorPicker != null) {
          this.colorPicker.destroy();
    }
  }

  public get labelAsId(): string {
    return `${this.label ? this.label.replace(/\s/g, '') : ""}`
  }

  public makeId() {
    return `${this.label ? this.label.replace(/\s/g, '') : ""}-tf-${this.uniqueId}`
  }

  public getSwatchStyle() {
    return "background-color: #" + this.value + "; border: 1px;";
  }

  public async showColorPicker () {

    const res = await this.popover.open<string>(ColorPickerWrapper, {val: this.value}, this.swatchEl);

    if (!res.canceled) {
      this.componentEventBus.dispatch("update:value", res.result);
    }

  }

  public onFocus(e) {
    this.isFocused = true;
    this.componentEventBus.dispatch("focus", e);
  }

  public onBlur(e) {
    this.isFocused = false;
    this.componentEventBus.dispatch("blur", e);
  }

  public onInput(value) {
    this.componentEventBus.dispatch("update:value", value);
  }

  public onChange() {
    this.componentEventBus.dispatch("change");
  }

  public resetToDefault() {
    this.componentEventBus.dispatch("update:value", this.defaultValue);
  }

}
