import { ComponentEventBus, inject, prop, needs } from "fw";
import type { ICustomFieldDefinition, ContactTypeDefinition } from "models/contact-organization";
import type { IRelationship, Contact } from "models/contact";

import { ContactRepository } from "network/contact-repository";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { ContactsService } from 'service/contacts';
import { FeatureFlagService } from "service/feature-flag";
import { IListResults } from "network/ats";
import { throttle } from "lodash-es";
import { ContactCard } from "./contact-card";

export interface EntitySearchPopoverOptions {
  field: ICustomFieldDefinition;
  relationship: IRelationship;
  showRemove: boolean;
}

@inject
@needs(ContactCard)
export class EntitySearchViewer {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(null) public relationship!: IRelationship;
  @prop(false) public canEdit!: boolean;
  @prop(false) public isFilter!: boolean;

  private contact: Contact = null;
  private loadingContact: boolean = false;

  private searching = false;
  private searchFilter = "";
  private throttledSearch: Function = () => { };
  private searchResults: IListResults<Contact> = { results: [], total: 0 };

  constructor(
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private contactRepo: ContactRepository,
    private ceb: ComponentEventBus,
    private contactsService: ContactsService,
    private ffs: FeatureFlagService,
  ) {
    this.resetSearchState();
  }

  public async attached() {
    this.throttledSearch = throttle(() => this.loadSearch(), 250);
    await this.relationshipChanged();
  }

  public get localRelationship() {
    return this.relationship;
  }

  public set localRelationship(value) {
    this.ceb.dispatch("update:relationship", value);
  }

  public get contactType(): string {
    return this.field && this.field.data && this.field.data.contact_type;
  }

  public get contactTypeColor(): string {
    const organization = this.currentContactOrganizationStore.state.organization;
    if (organization == null) {
      return "";
    }

    const definition: ContactTypeDefinition = organization.contact_types.find(ct => ct.key == this.contactType);
    if (definition) {
      return "#" + definition.color;
    } else {
      return "";
    }
  }

  private async loadContact() {
    const id: string = this.localRelationship && this.localRelationship.id;
    if (!id) {
      return;
    }

    this.loadingContact = true;
    try {
      this.contact = await this.contactRepo.getById(id);
    } catch (ex) {}
    this.loadingContact = false;
  }

  public selectContact(contact: Contact) {
    this.contact = contact;
    this.localRelationship = contact && { id: contact.id, display_name: contact.display_name };
  }

  private resetSearchState() {
    this.searchFilter = "";
    this.searchResults = { results: [], total: 0 };
    this.searching = false;
  }

  public searchFilterChanged() {
    if (this.searchFilter.length >= 2) {
      this.searching = true;
      this.throttledSearch();
    } else {
      this.searchResults = { results: [], total: 0 };
    }

    return true;
  }

  
  private async loadSearch() {
    try {
      const contactType = this.contactType;
      
      this.searchResults = await this.contactRepo.list(this.searchFilter, null, null, null, 1, 100, contactType);

    } catch (err) {
      this.searchResults = { results: [], total: 0 };
    }
  }

  private async relationshipChanged() {
    if (!this.localRelationship || !this.localRelationship.id) {
      this.contact = null;
    } else {
      await this.loadContact();
    }
  }
}
