import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LogoutAction, StartAction } from "./actions";
import { MarketingOutreachTierRepository } from "network/marketing-outreach-tier-repository";
import { MarketingOutreachTier } from "models/marketing-outreach-tier";
import {
  CreateMarketingOutreachTierAction,
  UpdateMarketingOutreachTierAction,
  CopyMarketingOutreachTierAction,
  DeleteMarketingOutreachTierAction
} from "forms/marketing-outreach-tier";
import { FormErrorHandling } from "./error-handling";

interface OutreachTierShape {
  loadedTracks: number[];
  collection: MarketingOutreachTier[];
}

export class FetchMarketingOutreachTiersAction {
  constructor(public trackId: number, public refresh: boolean = false) {}
}
export class FetchMarketingOutreachTierAction {
  constructor(public id: number) {}
}

@inject
export class MarketingOutreachTierStore extends Store<OutreachTierShape> {
  constructor(private network: MarketingOutreachTierRepository) {
    super();
  }

  defaultState() {
    return { loadedTracks: [], collection: [] };
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    this.setState(state => ({ ...this.defaultState() }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(state => this.defaultState());
  }

  @handle(FetchMarketingOutreachTiersAction)
  private async handleFetchMarketingOutreachTiers(action: FetchMarketingOutreachTiersAction) {
    if (action.refresh) this.defaultState();
    else if (this.state.loadedTracks.find(i => i == action.trackId)) return;

    const newItems = await this.network.index(action.trackId);
    const loadedTracks = this.state.loadedTracks.slice();
    const collection = this.state.collection.slice();

    loadedTracks.push(action.trackId);
    collection.push(...newItems.filter(i => ! collection.find(j => j.Id == i.Id)));
    this.setState(state => ({ ...state, loadedTracks: loadedTracks, collection: collection }));
  }

  @handle(FetchMarketingOutreachTierAction)
  private async handleFetchMarketingOutreachTier(action: FetchMarketingOutreachTierAction) {
    if (this.state.collection.find(i => i.Id == action.id)) return;

    const newItem = await this.network.show(action.id);
    const collection = this.state.collection.slice();

    collection.push(newItem);
    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(CreateMarketingOutreachTierAction, FormErrorHandling)
  private async handleCreateMarketingOutreachTier(action: CreateMarketingOutreachTierAction) {
    action.form.validate();

    const newItem = await this.network.post(action.form.updatedModel());
    const collection = this.state.collection.slice();

    collection.push(newItem);
    this.setState(state => ({ ...state, collection: collection }));
    action.created = newItem;
  }

  @handle(UpdateMarketingOutreachTierAction, FormErrorHandling)
  private async handleUpdateMarketingOutreachTier(action: UpdateMarketingOutreachTierAction) {
    action.form.validate();

    const updatedItem = await this.network.put(action.form.updatedModel());
    const collection = this.state.collection.slice();
    const currentItem = collection.find(i => i.Id == updatedItem.Id);

    collection[collection.indexOf(currentItem)] = updatedItem;
    this.setState(state => ({ ...state, collection: collection }));
    action.updated = updatedItem;
  }

  @handle(DeleteMarketingOutreachTierAction)
  private async handleDeleteMarketingOutreachTier(action: DeleteMarketingOutreachTierAction) {
    const item = this.state.collection.find(i => i.Id == action.id);
    if (! item) return;

    await this.network.destroy(action.id);
    this.state.collection.splice(this.state.collection.indexOf(item), 1);
  }

  @handle(CopyMarketingOutreachTierAction, FormErrorHandling)
  private async handleCopyMarketingOutreachTier(action: CopyMarketingOutreachTierAction) {
    if (! this.state.collection.find(i => i.Id == action.id)) return;

    const item = await this.network.copy(action.id);
    const collection = this.state.collection.slice();

    collection.push(item);
    this.setState(state => ({ ...state, collection: collection }));
  }
}
