import { ICustomFieldDefinition, CustomFieldType } from "models/contact-organization";

export enum TestScoreType {
    ACT = "ACT",
    AP = "AP",
    GRE = "GRE",
    GRE_Subject = "GRE_Subject",
    SAT = "SAT",
    SAT_pre2016 = "SAT_pre2016",
    SAT_Subject = "SAT_Subject",
    TOEFL = "TOEFL"
  }
  
  export enum TestScoringMode {
    All = 0,
    BestSitting = 1,
    BestSittingSum = 2,
    BestSittingMean = 3,
    Superscore = 4,
    SuperscoreSum = 5,
    SuperscoreMean = 6
  }
  
  export enum TestScoreSource {
    // ACT = "ACT"
    GRE = "GRE",
    SAT = "SAT",
    SFTP = "SFTP",
    TOEFL = "TOEFL"
  }
  
  export interface ITestScoreContainer {
    Scores: ITestScoreInfo[];
    CalculatedScore?: ICalculatedTestScore;
  }
  
  export interface ICalculatedTestScore {
    Date?: Date;
    Total?: number;
    Sections: { [key: string]: number };
  }
  
  export interface ITestScoreInfo {
    Date: Date;
    Section?: string;
    IsRevised?: boolean;
    MinScore?: number;
    MaxScore?: number;
    Score?: number;
    SubScores?: ITestScoreInfo[];
    Comments?: string;
    Data?: { [key: string]: any };
  }


  export interface ITestScoreSectionMeta {
    key: string,
    name: string
    search: string
  }
  
  export interface ITestScoreTypeMeta {
    key: TestScoreType,
    name: string,
    defaultMode: TestScoringMode,
    defaultSections: string[],
    description?: string,
    sources?: TestScoreSource[];
  }
  
  export interface ITestScoringModeMeta {
    key: TestScoringMode,
    name: string,
    description?: string
  }
  
  export interface ITestScoreSourceMeta {
    key: TestScoreSource,
    name: string,
    description?: string
  }
  

  export const getSectionsFromFieldDefinition = (field: ICustomFieldDefinition): string[] => {
    return ((field && field.data["test_score_sections"]) || "").split(",").filter(s => s.length > 0);
  }
  
  export const getSections = (field: ICustomFieldDefinition, scores: ITestScoreInfo[] = []): Map<string, ITestScoreSectionMeta> => {
    if (!field || field.type !== CustomFieldType.testscore) {
      return new Map();
    }
  
    const type: TestScoreType = (field && field.data && field.data["test_score_type"]) || "";
    return new Map(getSectionNames(field, scores).map((s): [string, ITestScoreSectionMeta] => [s, <ITestScoreSectionMeta>{
      key: s,
      name: getSectionAlias(type, s),
      search: removeNonAlphaNumeric(s, ["_"])
    }]));
  }
  
  export const getSectionMetas = (field: ICustomFieldDefinition, scores: ITestScoreInfo[] = []): ITestScoreSectionMeta[] => {
    const mode: TestScoringMode = (field && field.data["test_score_scoring_mode"]) || 0;
    const shouldIncludeTotal: boolean = mode !== TestScoringMode.All && mode !== TestScoringMode.BestSitting && mode !== TestScoringMode.Superscore;
    const sections = Array.from(getSections(field, scores).values());
    if (shouldIncludeTotal && sections && sections.length > 0) {
      return [{ name: "Total", key: "total", search: "total" }, ...sections]
    }
  
    return [];
  }
  
  export const getSectionNames = (field: ICustomFieldDefinition, scores: ITestScoreInfo[] = []): string[] => {
    const fieldSections = getSectionsFromFieldDefinition(field);
    if (fieldSections.length > 0) {
      return fieldSections;
    }
  
    if (scores.length > 0) {
      const useTopLevelSections: boolean = scores.every(s => !!s.Section);
      return Array.from(new Set(scores.map(test => {
        if (useTopLevelSections) {
          return [test.Section];
        }
  
        // SAT - sections are one deep.
        return (test.SubScores || []).map(t1 => t1.Section);
      }).reduce((list, names) => list.concat(names.filter(i => i && i.length > 0)), [])));
    }
  
    return getKnownSectionNames(field);
  }
  
  export const getKnownSectionNames = (field: ICustomFieldDefinition): string[] => {
    const type = (field && field.data && field.data["test_score_type"]) || "";
    return Array.from(getSectionAliasMap(type).keys());
  }
  
  export const getSectionScore = (scores: ITestScoreInfo[], score: ITestScoreInfo, section: string): number => {
    const useTopLevelSections: boolean = (scores || []).every(s => !!s.Section);
    if (useTopLevelSections) {
      if (score.Section === section) {
        return score.Score;
      }
  
      return;
    }
  
    const result = score.SubScores && score.SubScores.find(s => s.Section === section);
    return result && result.Score;
  }
  
  export const getSectionMaxScore = (scores: ITestScoreInfo[], section: string): number => {
    scores = scores || [];
  
    const useTopLevelSections: boolean = scores.every(s => !!s.Section);
    const results: number[] = Array.from(new Set(scores.map(test => {
      if (useTopLevelSections) {
        if (test.Section === section) {
          return [test.Score];
        }
  
        return [];
      }
  
      // SAT - sections are one deep.
      return (test.SubScores || []).filter(t1 => t1.Section === section).map(t2 => t2.Score);
    }).reduce((list, scores) => list.concat(scores.filter(i => i && i >= 0)), [])))
      .sort((a, b) => a - b)
      .reverse();
  
    return results && results[0];
  }
  
  export const getSectionAlias = (type: TestScoreType, section: string): string => {
    return getSectionAliasMap(type).get(section) || section;
  }
  
  const _actAliasMap: Map<string, string> = new Map([
    ['MATH', 'Math'],
    ['ENGLISH', 'English'],
    ['READING', 'Reading'],
    ['SCIENCE', 'Science']
  ]);
  
  const _greAliasMap: Map<string, string> = new Map([
    ['Verbal Reasoning', 'Verbal Reasoning'],
    ['Quantitative Reasoning', 'Quantitative Reasoning'],
    ['Analytical Writing', 'Analytical Writing']
  ]);
  
  const _satAliasMap: Map<string, string> = new Map([
    ['MATH_SECTION', 'Math'],
    ['EBRW', 'EBRW'],
    ['READING', 'Reading Test'],
    ['WRIT_LANG', 'Writing and Language Test'],
    ['MATH_TEST', 'Math Test'],
    ['SCI_CROSS', 'Analysis in Science Cross-Test'],
    ['HIST_SOCST_CROSS', 'Analysis in History/Social Science Cross-Test'],
    ['WORDS_CONTEXT', 'Words in Context Subscore'],
    ['COMM_EVIDENCE', 'Command of Evidence Subscore'],
    ['EXPR_IDEAS', 'Expression of Ideas Subscore'],
    ['ENG_CONVENT', 'Standard English Conventions Subscore'],
    ['HEART_ALGEBRA', 'Heart of Algebra Subscore'],
    ['ADV_MATH', 'Passport to Algebra Subscore'],
    ['PROBSLV_DATA', 'Problem Solving and Data Analysis Subscore'],
    ['ESSAY_READING', 'Essay Reading Subscore'],
    ['ESSAY_ANALYSIS', 'Essay Analysis Subscore'],
    ['ESSAY_WRITING', 'Essay Writing Subscore']
  ]);
  
  const _satPre2016AliasMap: Map<string, string> = new Map([
    ['CRITICAL_READING', 'Reading'],
    ['MATH', 'Math'],
    ['WRITING', 'Writing'],
    ['ESSAY', 'Essay Subscore'],
    ['ESSAY_MC', 'Writing Multiple Choice Subscore']
  ]);
  
  const _toeflAliasMap: Map<string, string> = new Map([
    ['Listening', 'Listening'],
    ['Reading', 'Reading'],
    ['Speaking', 'Speaking'],
    ['Writing', 'Writing']
  ]);
  
  const getSectionAliasMap = (type: TestScoreType): Map<string, string> => {
    switch (type) {
      case TestScoreType.ACT: {
        return _actAliasMap;
      }
      case TestScoreType.GRE: {
        return _greAliasMap;
      }
      case TestScoreType.SAT: {
        return _satAliasMap;
      }
      case TestScoreType.SAT_pre2016: {
        return _satPre2016AliasMap;
      }
      case TestScoreType.TOEFL: {
        return _toeflAliasMap;
      }
      case TestScoreType.GRE_Subject:
      case TestScoreType.SAT_Subject:
      default: {
        return new Map();
      }
    }
  }
  
  export const getKnownTestScoreTypeMeta = (): ITestScoreTypeMeta[] => {
    return [
      { key: TestScoreType.AP, name: 'AP', defaultMode: TestScoringMode.Superscore, defaultSections: [], sources: [TestScoreSource.SFTP]  },
      { key: TestScoreType.ACT, name: 'ACT', defaultMode: TestScoringMode.SuperscoreMean, defaultSections: ['MATH', 'READING', 'ENGLISH', 'SCIENCE'], sources: [/* TestScoreSource.ACT, */ TestScoreSource.SFTP]  },
      { key: TestScoreType.GRE, name: 'GRE', defaultMode: TestScoringMode.Superscore, defaultSections: ['Verbal Reasoning', 'Quantitative Reasoning', 'Analytical Writing'], sources: [/* TestScoreSource.GRE, */ TestScoreSource.SFTP]  },
      { key: TestScoreType.GRE_Subject, name: 'GRE Subject', defaultMode: TestScoringMode.SuperscoreSum, defaultSections: [] },
      { key: TestScoreType.SAT, name: 'SAT', defaultMode: TestScoringMode.BestSittingSum, defaultSections: ['MATH_SECTION', 'EBRW'], sources: [TestScoreSource.SAT, TestScoreSource.SFTP] },
      { key: TestScoreType.SAT_pre2016, name: 'SAT Pre 2016', defaultMode: TestScoringMode.BestSittingSum, defaultSections: ['MATH', 'CRITICAL_READING', 'WRITING']},
      { key: TestScoreType.SAT_Subject, name: 'SAT Subject', defaultMode: TestScoringMode.Superscore, defaultSections: [] },
      { key: TestScoreType.TOEFL, name: 'TOEFL', defaultMode: TestScoringMode.SuperscoreSum, defaultSections: ['Listening', 'Reading', 'Speaking', 'Writing'], sources: [/* TestScoreSource.TOEFL, */ TestScoreSource.SFTP] }
    ];
  }
  
  export const getKnownTestScoringModeMeta = (): ITestScoringModeMeta[] => {
    return [
      { key: TestScoringMode.All, name: 'All' },
      { key: TestScoringMode.BestSitting, name: 'Best Sitting' },
      { key: TestScoringMode.BestSittingSum, name: 'Best Sitting Sum' },
      { key: TestScoringMode.BestSittingMean, name: 'Best Sitting Mean' },
      { key: TestScoringMode.Superscore, name: 'Superscore' },
      { key: TestScoringMode.SuperscoreSum, name: 'Superscore Sum' },
      { key: TestScoringMode.SuperscoreMean, name: 'Superscore Mean' }
    ];
  }
  
  export const getKnownTestScoreSourceMetas = (): ITestScoreSourceMeta[] => {
    return [
      { key: TestScoreSource.GRE, name: 'GRE', description: 'Get GRE scores using ETS ScoreLink web service' },
      { key: TestScoreSource.SAT, name: 'SAT', description: 'Get SAT scores using SAT Download Center web service' },
      { key: TestScoreSource.SFTP, name: 'SFTP', description: 'Get scores using SFTP' },
      { key: TestScoreSource.TOEFL, name: 'TOEFL', description: 'Get TOEFL scores using ETS ScoreLink web service' },
    ];
  }
  
  export const removeNonAlphaNumeric = (input: string = "", additionalAllowedChars: string[] = []): string => {
    let result: string = "";
    for (let c of input.toLowerCase()) {
      if (/^[A-Z]|[a-z]|\d$/.test(c) || additionalAllowedChars.includes(c)) {
        result += c;
      }
    }
  
    return result;
  }
  