import { Validators, formFor, FormForType, ModeledFormSetup } from "fw-model";
import {
  Program,
  ProgramStage,
  ProgramStepGroup,
  ProgramStep,
  ProgramStepTypeCode,
  ReferenceSetSettings,
  ContentSettings,
  FormSettings,
  PortfolioSettings,
  FolderSettings,
  MediaTypeFieldInfo,
  MediaTypeConfiguration,
  DocumentSettings,
  DocumentSource,
  ContentBlock,
  EnhancedMediaTypeConfiguration,
  DecisionLetterSettings,
  ProgramStageData,
} from "models/program";
import { conditionInfoFormCreator } from "./condition-info";

const { required, isNumber, isInteger, wrap } = Validators;
import { isValidKey, isValidDate, isValidOptionalKey } from "./validators";

export type EditProgramFormType = FormForType<Program>;
export type ProgramStepFormType = FormForType<ProgramStep>;

export class EditProgramAction {
  constructor(public form: EditProgramFormType) {}
}

export class ProgramCreateAction {
  public createdId: string = null;
  constructor(public form: EditProgramFormType) {}
}

const requiredIf = (requiredStepType: ProgramStepTypeCode) => {
  return (input, model, stepType: ProgramStepTypeCode) => {
    if (stepType == requiredStepType) return required(input);

    return null;
  };
};

export const MediaTypeFieldInfoForm = formFor(MediaTypeFieldInfo, (s) => {
  s.requiredField((f) => f.Name, "Name");
  s.field((f) => f.IsRequired, "Required Field");
  s.field((f) => f.FieldType, "Field Type");
});

export const MediaTypeConfigurationForm = formFor(
  MediaTypeConfiguration,
  (s) => {
    s.field((f) => f.MediaType, "Media Type");
    s.field((f) => f.Name, "Name");
    s.field((f) => f.Enabled, "Enabled");
    s.field((f) => f.ExternalProviders, "External Providers");
    s.field((f) => f.AllowedExtensions, "Accepted File Extensions");
    s.formArray((f) => f.Fields, "Fields", MediaTypeFieldInfoForm);
  }
);

export const EnhancedMediaTypeConfigurationForm = formFor(
  EnhancedMediaTypeConfiguration,
  (s) => {
    s.field((f) => f.MediaType, "Media Type");
    s.field((f) => f.Name, "Name");
    s.field((f) => f.Enabled, "Enabled");
    s.field((f) => f.ExternalProviders, "External Providers");
    s.field((f) => f.AllowedExtensions, "Accepted File Extensions");
    s.field((f) => f.FormId, "Form Id");
  }
);

export const PortfolioSettingsForm = formFor(PortfolioSettings, (s) => {
  s.field((f) => f.MinimumItems, "Minimum Items");
  s.field((f) => f.MaximumItems, "Maximum Items");
  s.formArray((f) => f.MediaTypes, "Media Types", MediaTypeConfigurationForm);
});

export const FolderSettingsForm = formFor(FolderSettings, (s) => {
  s.field((f) => f.ApplicantSupplied, "Applicant Supplied");
  s.field((f) => f.MinimumItems, "Minimum Items");
  s.field(
    (f) => f.MaximumItems,
    "Maximum Items",
    (v) =>
      v.if(
        (s) => !!s.MinimumItems,
        (_, s) =>
          s.MaximumItems < s.MinimumItems
            ? "Minimum should not be more than Maximum."
            : null
      )
  );
  s.formArray(
    (f) => f.MediaTypes,
    "Media Types",
    EnhancedMediaTypeConfigurationForm
  );
});

export const FormSettingsForm = formFor(FormSettings, (s) => {
  s.field((f) => f.FormId, "Form", wrap(requiredIf(ProgramStepTypeCode.Form)));
});

export const ContentSettingsForm = formFor(ContentSettings, (s) => {
  s.field(
    (f) => f.Content,
    "Content",
    wrap(requiredIf(ProgramStepTypeCode.Content))
  );
});

export const DocumentSettingsForm = formFor(DocumentSettings, (s) => {
  s.formArray(
    (f) => f.AllowedDocumentSources,
    "Document Sources",
    DocumentSourceForm
  );
});

export const DecisionLetterSettingsForm = formFor(
  DecisionLetterSettings,
  (s) => {
    s.field(
      (f) => f.Placeholder,
      "Placeholder Content (If no Decision Letter is available)"
    );
  }
);

export const DocumentSourceForm = formFor(DocumentSource, (s) => {
  s.field((f) => f.Key, "Provider");
  s.field((f) => f.Types, "Document Types");
});

export const ReferenceSetSettingsForm = formFor(ReferenceSetSettings, (s) => {
  s.field((f) => f.AllowWaiveRight, "Allow Waiver Opt Out");
  s.requiredField((f) => f.ReferencesCount, "Number of References");
  s.field(
    (f) => f.FormId,
    "Form",
    requiredIf(ProgramStepTypeCode.ReferenceSet)
  );
  s.field((f) => f.ReferencesCountConditionType, "");
});

export const programStepFormCreator = formFor(ProgramStep, (s) => {
  s.requiredField((f) => f.StepType, "Step Type");
  s.requiredField((f) => f.Name, "Name");
  s.field((f) => f.Instructions, "Instructions");
  s.field(
    (f) => f.IsRequired,
    "Step Required",
    (v) => {
      v.if(
        (s) =>
          s.StepType === ProgramStepTypeCode.Document ||
          s.StepType === ProgramStepTypeCode.Portfolio,
        (_, s) => {
          if (s?.IsRequired && (s?.FolderSettings?.MinimumItems ?? 0) < 1) {
            return "Must set Minimum Items greater than 0.";
          } else if (!s?.IsRequired && s?.FolderSettings?.MinimumItems > 0)
            return "Required must be selected if Minimum Items is greater than 0.";
        }
      );
      v.if(
        (s) => s.StepType === ProgramStepTypeCode.ReferenceSet,
        (_, s) => {
          if (s?.IsRequired && s?.ReferenceSetSettings?.ReferencesCount < 1) {
            return "Must set Number of References greater than 0.";
          } else if (
            !s?.IsRequired &&
            s?.ReferenceSetSettings?.ReferencesCount > 0
          )
            return "Required must be selected if Number of References is greater than 0.";
        }
      );
    }
  );
  s.field((f) => f.Condition, "Condition");
  s.field((f) => f.Condition, "Condition");
  s.form((f) => f.ConditionInfo, "Condition Builder", conditionInfoFormCreator);
  s.requiredField((f) => f.Key, "Key", wrap(isValidKey));
  s.form((f) => f.FormSettings, "Form Settings", FormSettingsForm);
  s.form(
    (f) => f.PortfolioSettings,
    "Portfolio Settings",
    PortfolioSettingsForm
  );
  s.form((f) => f.FolderSettings, "Folder Settings", FolderSettingsForm);
  s.form(
    (f) => f.ReferenceSetSettings,
    "Reference Settings",
    ReferenceSetSettingsForm
  );
  s.form((f) => f.ContentSettings, "Content Settings", ContentSettingsForm);
  s.form((f) => f.DocumentSettings, "Document Settings", DocumentSettingsForm);
  s.form(
    (f) => f.DecisionLetterSettings,
    "Decision Letter Settings",
    DecisionLetterSettingsForm
  );
});

export type ProgramStepGroupForm = FormForType<ProgramStepGroup>;

const programStepGroupSetupValidateSteps = (includeSteps: boolean) => {
  return (s: ModeledFormSetup<ProgramStepGroup>) => {
    s.requiredField((f) => f.Name, "Name");
    s.field((f) => f.Key, "Checklist Key", wrap(isValidOptionalKey))
    s.field((f) => f.StartDateUTC, "Start Date", wrap(isValidDate));
    s.field(
      (f) => f.DeadlineUTC,
      "Deadline",
      wrap(isValidDate),
      (v) =>
        v.if(
          (g) => !!g.StartDateUTC,
          (_, sg) =>
            new Date(sg.DeadlineUTC) < new Date(sg.StartDateUTC)
              ? "Deadline must be on or after start date."
              : null
        )
    );
    s.field((f) => f.PreStartInstructions, "Pre-Start Instructions");
    s.field((f) => f.PostDeadlineInstructions, "Post-Deadline Instructions");
    s.field(
      (f) => f.DeadlineType,
      "Deadline Type",
      (v) => v.if((g) => !!g.DeadlineUTC, required)
    );
    s.field(
      (f) => f.Fee,
      "Fee",
      (v) => v.if((g) => !!g.Fee, isNumber)
    );
    s.field((f) => f.Condition, "Condition");
    s.form(
      (f) => f.ConditionInfo,
      "Condition Builder",
      conditionInfoFormCreator
    );
    s.field((f) => f.WaiveFeeCondition, "Waive Fee Condition");
    s.form(
      (f) => f.WaiveFeeConditionInfo,
      "Waive Fee Condition Builder",
      conditionInfoFormCreator
    );
    s.field((f) => f.SubmitContentTemplate, "Submit Content Template");
    s.field((f) => f.SendCustomEmailOnSubmit, "Send Custom Email On Submit");
    s.field(
      (f) => f.CustomEmailKeyOnSubmit,
      "Email Template Key",
      (v) => v.if((g) => g.SendCustomEmailOnSubmit, required)
    );
    s.field((f) => f.GracePeriod, "Grace Period", wrap(isNumber, isInteger));

    if (includeSteps)
      s.formArray((f) => f.StepList, "Steps", programStepFormCreator);
  };
};

export const programStepGroupFormCreator = formFor(
  ProgramStepGroup,
  programStepGroupSetupValidateSteps(true)
);
export const programStepGroupNoStepsFormCreator = formFor(
  ProgramStepGroup,
  programStepGroupSetupValidateSteps(false)
);

export const ContentBlockForm = formFor(ContentBlock, (s) => {
  s.field((f) => f.Condition, "Condition");
  s.form((f) => f.ConditionInfo, "Condition Builder", conditionInfoFormCreator);
  s.requiredField((f) => f.ContentTemplateId, "Content Template");
});

export const ProgramStageForm = formFor(ProgramStage, (s) => {
  s.requiredField((f) => f.Name, "Stage");
  s.field((f) => f.IntroductionContent, "Introduction Content");
  s.field((f) => f.ProgramStageTemplateId, "Template Id");
  s.formArray((f) => f.StepGroups, "Step Groups", programStepGroupFormCreator);
  s.formArray((f) => f.ContentBlocks, "Content Blocks", ContentBlockForm);
});

export const programFormCreator = formFor(Program, (s) => {
  s.requiredField((f) => f.Name, "Program Name");
  s.requiredField((f) => f.StartDateUTC, "Start Date", wrap(isValidDate));
  s.field((f) => f.EndDateUTC, "End Date", wrap(isValidDate));
  s.requiredField((f) => f.PublicName, "Program Public Name");
  s.field((f) => f.Description, "Program Description");
  s.field((f) => f.ExternalStartLink, "External Start Link");
  s.requiredField((f) => f.StartingStageName, "Starting Stage Name");
  s.formArray((f) => f.Stages, "Stages", ProgramStageForm);
  s.field((f) => f.IsActive, "Active");
  s.field((f) => f.IsPrivate, "Private");
  s.field((f) => f.IsHidden, "Hidden");

  s.field((f) => f.PropertyValues, "Property Values");
});

export const ProgramStageDataForm = formFor(ProgramStageData, (s) => {
  s.requiredField((f) => f.Name, "Stage");
  s.field((f) => f.IntroductionContent, "Introduction Content");
  s.formArray((f) => f.StepGroups, "Step Groups", programStepGroupFormCreator);
  s.formArray((f) => f.ContentBlocks, "Content Blocks", ContentBlockForm);
});
