import { ComponentEventBus, inject, needs, prop } from "fw";
import { MultiselectField } from "./multiselect-field";
import { MultiselectPill } from "./multiselect-pill";
import { difference } from "lodash-es";
import {
  MultiselectPopoverOptions,
  MultiselectSelectorPopover,
} from "./multiselect-selector-popover";
import { PopoverService } from "service/popover";

@inject
@needs(MultiselectField, MultiselectPill)
export class MultiselectSelector {
  @prop("option") public entityName!: string;
  @prop(undefined) public value!: string[];
  @prop(null) private options!: string[];
  @prop(false) private showAdd!: boolean;
  @prop(false) public showRemove!: boolean;
  @prop(() => null) private validate!: (
    options: string[]
  ) => Promise<string | null>;

  public withOverflowCount: boolean = true;
  public optionsListRef: any;
  public optionsPopoverOpened: boolean = false;

  constructor(
    private ceb: ComponentEventBus,
    private popoverService: PopoverService
  ) {}

  public attached() {
    if (this.value == null) {
      this.ceb.dispatch("update:value", []);
    }
  }

  public onOverflowClick() {
    this.withOverflowCount = !this.withOverflowCount;
  }

  public async openOptionsPopover() {
    const options: MultiselectPopoverOptions = {
      options: this.options,
      entityName: this.entityName,
      showAdd: this.showAdd,
      getValue: () => this.value,
      onChange: this.onChange,
      validate: this.validate,
    };

    this.optionsPopoverOpened = true;
    await this.popoverService.open<string[]>(
      MultiselectSelectorPopover,
      options,
      this.optionsListRef
    );
    this.optionsPopoverOpened = false;
  }

  public onChange(updatedValue: string[]) {
    this.ceb.dispatch("update:value", updatedValue);
  }

  public remove(option: string) {
    this.ceb.dispatch("update:value", difference(this.value, [option]));
  }
}
