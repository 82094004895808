import { cloneOf } from "fw-model";
import {
  PdfExportTemplateSet,
  PdfExportTemplate,
} from "models/pdf-export-template";
import { ApplicantPortal } from "models/applicant-portal";
import { StudentPortal } from "models/student-portal";
import { ReferencePortal } from "models/reference-portal";
import { InquiryPortal } from "models/inquiry-portal";
import { PortalPreviewArgs } from "models/app-portal-preview";
import { EmailTemplate } from "models/email-template";
import { MarketingOutreachItem } from "models/marketing-outreach-item";
import { MarketingEmailTemplate } from "models/marketing-email-template";
import {
  Program,
  ProgramStageTemplate,
  ProgramStepTypeCode,
} from "models/program";
import { ContentTemplate } from "models/content-template";
import { MarketingPrintPieceSnapshot } from "models/marketing-print-piece-snapshot";
import { EmailTemplatePreviewArgs } from "./marketing-outreach-item-repository";
import { MarketingBulkMessage } from "../models/marketing-bulk-message";
import { CustomPortal } from "../models/custom-portal";

export const encodeProgram = (program: Program) => {
  const clone = JSON.parse(JSON.stringify(program));

  for (const stage of clone.Stages) {
    for (const stepGroup of stage.StepGroups) {
      for (const step of stepGroup.StepList) {
        if (
          step.StepType == ProgramStepTypeCode.Content &&
          step.ContentSettings != null
        ) {
          step.ContentSettings.Content =
            step.ContentSettings.Content != null
              ? encodeURIComponent(step.ContentSettings.Content)
              : null;
        }
      }
    }
  }

  return clone;
};

export const encodeProgramStageTemplate = (
  programStageTemplate: ProgramStageTemplate
) => {
  const clone = JSON.parse(JSON.stringify(programStageTemplate));

  for (const stepGroup of clone.StepGroups) {
    for (const step of stepGroup.StepList) {
      if (
        step.StepType == ProgramStepTypeCode.Content &&
        step.ContentSettings != null
      ) {
        step.ContentSettings.Content =
          step.ContentSettings.Content != null
            ? encodeURIComponent(step.ContentSettings.Content)
            : null;
      }
    }
  }

  return clone;
};

export const encodePdfExportTemplateSet = (
  templateSet: PdfExportTemplateSet
) => {
  const clone: PdfExportTemplateSet = cloneOf(
    PdfExportTemplateSet,
    templateSet
  );

  for (const key of Object.keys(clone.Templates)) {
    if (clone.Templates[key] && clone.Templates[key].Template != null) {
      clone.Templates[key].Template = encodeURIComponent(
        clone.Templates[key].Template
      );
    }
  }

  return clone;
};

export const encodeApplicantPortal = (portal: ApplicantPortal) => {
  const clone = cloneOf(ApplicantPortal, portal);

  clone.Content.Css =
    clone.Content.Css != null ? encodeURIComponent(clone.Content.Css) : null;
  clone.Content.FooterHtml =
    clone.Content.FooterHtml != null
      ? encodeURIComponent(clone.Content.FooterHtml)
      : null;
  clone.Content.HeaderHtml =
    clone.Content.HeaderHtml != null
      ? encodeURIComponent(clone.Content.HeaderHtml)
      : null;
  clone.Content.GlobalCss =
    clone.Content.GlobalCss != null
      ? encodeURIComponent(clone.Content.GlobalCss)
      : null;

  return clone;
};

export const encodeStudentPortal = (portal: StudentPortal) => {
  const clone = cloneOf(StudentPortal, portal);

  return clone;
};

export const encodeInquiryPortal = (portal: InquiryPortal) => {
  const clone = cloneOf(InquiryPortal, portal);

  clone.Content.Css =
    clone.Content.Css != null ? encodeURIComponent(clone.Content.Css) : null;
  clone.Content.FooterHtml =
    clone.Content.FooterHtml != null
      ? encodeURIComponent(clone.Content.FooterHtml)
      : null;
  clone.Content.HomeHtml =
    clone.Content.HomeHtml != null
      ? encodeURIComponent(clone.Content.HomeHtml)
      : null;
  clone.Content.HeaderHtml =
    clone.Content.HeaderHtml != null
      ? encodeURIComponent(clone.Content.HeaderHtml)
      : null;
  clone.Content.GlobalCss =
    clone.Content.GlobalCss != null
      ? encodeURIComponent(clone.Content.GlobalCss)
      : null;

  return clone;
};

export const encodeReferencePortal = (portal: ReferencePortal) => {
  const clone = cloneOf(ReferencePortal, portal);

  clone.Content.Css =
    clone.Content.Css != null ? encodeURIComponent(clone.Content.Css) : null;
  clone.Content.FooterHtml =
    clone.Content.FooterHtml != null
      ? encodeURIComponent(clone.Content.FooterHtml)
      : null;
  clone.Content.HeaderHtml =
    clone.Content.HeaderHtml != null
      ? encodeURIComponent(clone.Content.HeaderHtml)
      : null;
  clone.Content.GlobalCss =
    clone.Content.GlobalCss != null
      ? encodeURIComponent(clone.Content.GlobalCss)
      : null;

  return clone;
};

export const encodePortalPreviewArgs = (args: PortalPreviewArgs) => {
  const clone = cloneOf(PortalPreviewArgs, args);
  clone.Css = clone.Css != null ? encodeURIComponent(clone.Css) : null;
  clone.GlobalCss =
    clone.GlobalCss != null ? encodeURIComponent(clone.GlobalCss) : null;
  return clone;
};

export const encodeEmailTemplate = (template: EmailTemplate) => {
  const clone = cloneOf(EmailTemplate, template);

  if (clone.Html != null) {
    clone.Html = encodeURIComponent(clone.Html);
  }
  return clone;
};

export const encodeMarketingOutreachItem = (
  outreach: MarketingOutreachItem
) => {
  const clone = cloneOf(MarketingOutreachItem, outreach);

  if (clone.Html != null) {
    clone.Html = encodeURIComponent(clone.Html);
  }
  return clone;
};

export const encodeMarketingBulkMessage = (outreach: MarketingBulkMessage) => {
  const clone = cloneOf(MarketingBulkMessage, outreach);

  if (clone.Html != null) {
    clone.Html = encodeURIComponent(clone.Html);
  }
  return clone;
};

export const encodeMarketingEmailTemplate = (
  template: MarketingEmailTemplate
) => {
  const clone = cloneOf(MarketingEmailTemplate, template);

  if (clone.Html != null) {
    clone.Html = encodeURIComponent(clone.Html);
  }
  return clone;
};

export const encodeContentTemplate = (template: ContentTemplate) => {
  const clone = cloneOf(ContentTemplate, template);

  if (clone.Template != null) {
    clone.Template = encodeURIComponent(clone.Template);
  }
  return clone;
};

export const encodeMarketingPrintPieceSnapshot = (
  snapshot: MarketingPrintPieceSnapshot
) => {
  return cloneOf(MarketingPrintPieceSnapshot, snapshot);
};

export const encodeEmailTemplatePreviewArgs = (
  template: EmailTemplatePreviewArgs
) => {
  const clone = cloneOf(EmailTemplatePreviewArgs, template);

  if (clone.Html != null) {
    clone.Html = encodeURIComponent(clone.Html);
  }
  return clone;
};
