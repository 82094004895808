import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS, IListResults } from "./ats";
import { MarketingOutreachCampaign } from "models/marketing-outreach-campaign";

@inject
export class MarketingOutreachCampaignRepository {
  constructor(private network: ATS) {}

  async index() {
    const res = await this.network.marketing.get<MarketingOutreachCampaign[]>("outreach_campaigns");
    return createFromArray(MarketingOutreachCampaign, res.body);
  }

  async list(q: string = null, f: string = null, aggs: string = null, sort: string = null, page = 1, limit = 10): Promise<IListResults<MarketingOutreachCampaign>> {
    return this.network.marketing.list("outreach_campaigns", MarketingOutreachCampaign, q, f, aggs, sort, page, limit);
  }

  async show(id: number) {
    const res = await this.network.marketing.get<MarketingOutreachCampaign>(`outreach_campaigns/${id}`);
    return createFrom(MarketingOutreachCampaign, res.body);
  }

  async post(tier: MarketingOutreachCampaign) {
    const res = await this.network.marketing.post<MarketingOutreachCampaign>("outreach_campaigns", tier);
    return createFrom(MarketingOutreachCampaign, res.body);
  }

  async put(campaign: MarketingOutreachCampaign) {
    const res = await this.network.marketing.put<MarketingOutreachCampaign>(`outreach_campaigns/${campaign.Id}`, campaign);
    return createFrom(MarketingOutreachCampaign, res.body);
  }

  async destroy(ids: number | number[], all: boolean = false, active: boolean = true, search: string = "") {
    const idsStr = Array.isArray(ids) ? ids.join() : ids;

    await this.network.marketing.delete<MarketingOutreachCampaign[]>("outreach_campaigns", {
      Ids: idsStr,
      All: all,
      Active: active,
      Search: search
    });
  }

  async copy(ids: number | number[], all: boolean = false, active: boolean = true, search: string = "") {
    const idsStr = Array.isArray(ids) ? ids.join() : ids;

    const res = await this.network.marketing.post<MarketingOutreachCampaign[]>("outreach_campaigns/copy", {
      Ids: idsStr,
      All: all,
      Active: active,
      Search: search
    });

    return createFromArray(MarketingOutreachCampaign, res.body);
  }

  async activate(ids: number | number[], all: boolean = false, active: boolean = true, search: string = "") {
    const idsStr = Array.isArray(ids) ? ids.join() : ids;

    await this.network.marketing.post<MarketingOutreachCampaign[]>("outreach_campaigns/activate", {
      Ids: idsStr,
      All: all,
      Active: active,
      Search: search
    });
  }

  async deactivate(ids: number | number[], all: boolean = false, active: boolean = true, search: string = "") {
    const idsStr = Array.isArray(ids) ? ids.join() : ids;

    await this.network.marketing.post<MarketingOutreachCampaign[]>("outreach_campaigns/deactivate", {
      Ids: idsStr,
      All: all,
      Active: active,
      Search: search
    });
  }
}
