import { inject } from "fw";
import { dispatch } from "fw-state";
import { DialogController } from "fw-dialog";

import { UserTaskForm, userTaskFormCreator } from "forms/user-task";
import { UserTask } from "models/user-task";
import {
  EntityReferenceStore,
  EnsureApplicationReferencesAction,
  EnsureContactReferencesAction
} from "state/entity-reference";
import { PopoverService } from "service/popover";
import { Notification } from "service/notification";
import { SelectUserPopover } from "views/components/select-user-popover";
import { TaskTagSelectorPopover } from "./task-tag-selector-popover";
import { TaskTargetSelectorPopover, TaskTargetSelectorPopoverResult } from "./task-target-selector-popover";
import { CurrentUserStore } from "state/current-user";
import { UserTaskStore, UpdateUserTaskAction, AddUserTaskAction, EnsureUserTasksAction } from "state/user-tasks";

@inject
export class EditTask {
  private isNew = false;
  private loading = false;
  private saving = false;
  private form: UserTaskForm = null;
  private targetName: string = ""
  status = null;
  statusOptions: { value: any; text: string }[] = [
    { value: false, text: "Open" },
    { value: true, text: "Complete" },
  ];

  constructor(
    private entityReferenceStore: EntityReferenceStore,
    private currentUserStore: CurrentUserStore,
    private userTaskStore: UserTaskStore,
    private popoverService: PopoverService,
    private controller: DialogController<UserTask>,
    private notifier: Notification
  ) { }

  async activate(params: { task: UserTask, targetType: "Application" | "Contact", targetId: string, id?: string }) {
    const id = params?.task?.Id || params?.id;
    if (id)
      await dispatch(new EnsureUserTasksAction([id]));

    const task = params?.task || this.userTaskStore.state.userTasks.find(t => t.Id === id);
    this.isNew = !task;
    this.form = userTaskFormCreator(this.isNew ? new UserTask() : task);
    if (this.isNew) {
      const d = new Date();
      d.setDate(d.getDate() + 1);
      this.form.DateDueUtc = d.toISOString();
      this.form.AssignedToUserId = this.currentUserStore.state.user.Id;
      if (params.targetType && params.targetId) {
        this.form.TargetType = params.targetType;
        this.form.TargetId = params.targetId;
      }
    }
    this.status = this.form.IsCompleted;
    this.loadTargetName();

  }

  getReference(id: string) {
    return this.entityReferenceStore.state.referenceHash[id]?.description;
  }

  async loadTargetName() {
    switch (this.form.TargetType) {
      case "Application":
        await dispatch(new EnsureApplicationReferencesAction([this.form.TargetId]));
        break;
      case "Contact":
        await dispatch(new EnsureContactReferencesAction([this.form.TargetId]));
        break;
    }
  }

  async selectUser() {
    const res = await this.popoverService.open<string>(SelectUserPopover);
    if (res.canceled) return;
    this.form.AssignedToUserId = res.result;
  }

  public async addTarget() {
    const { canceled, result } = await this.popoverService.open<TaskTargetSelectorPopoverResult>(
      TaskTargetSelectorPopover,
      {
        targetType: this.form.TargetType,
        targetId: this.form.TargetId,
        filter: this.getReference(this.form.TargetId) || "",
      }
    );

    if (canceled) return;

    if (!result.targetType || !result.targetId) {
      this.removeTarget();
      return;
    }

    this.form.TargetType = result.targetType;
    this.form.TargetId = result.targetId;

    switch (result.targetType) {
      case "Application":
        await dispatch(new EnsureApplicationReferencesAction([result.targetId]));
        break;
      case "Contact":
        await dispatch(new EnsureContactReferencesAction([result.targetId]));
        break;
    }
  }

  removeTarget() {
    this.form.TargetType = null;
    this.form.TargetId = null;
  }

  async addTag() {
    const res = await this.popoverService.open<string>(TaskTagSelectorPopover, {
      showAdd: true,
      selected: this.form.Tags,
    });

    if (res.canceled) return;

    const tag = res.result;
    if (this.form.Tags == null)
      this.form.Tags = [];
    if (!this.form.Tags.includes(tag))
      this.form.Tags.push(tag);
  }

  async removeTag(tag: string) {
    if (this.form.Tags == null) return;

    if (this.form.Tags.includes(tag)) {
      const idx = this.form.Tags.indexOf(tag);
      this.form.Tags.splice(idx, 1);
    }
  }

  close() {
    this.controller.cancel();
  }

  async save() {
    this.saving = true;
    this.form.IsCompleted = this.status;
    try {
      if (!this.isNew && this.form.Id != null) {
        await dispatch(new UpdateUserTaskAction(this.form));
        this.notifier.notify('Task has been successfully updated');
      } else {
        await dispatch(new AddUserTaskAction(this.form));
        this.notifier.notify('Task has been successfully added');
      }
      this.controller.ok(this.form.updatedModel());
    } catch (err) { }
    this.saving = !this.form.isInvalid;
  }

}
