import { LocalStorageCache } from 'caching';
import { GroupFilter } from "models/filter-setup";
import { inject } from 'fw';
import { createFrom } from 'fw-model';

@inject
export class ContactsFilter {

  constructor(
    private cache: LocalStorageCache
  ) { }

  public setSelectedContactTypeFor(organizationId: string, userId: string, contactTypeKey: string) {
    const cacheKey = this.createSelectedContactTypeCacheKey(organizationId, userId);
    this.cache.set<string>(cacheKey, contactTypeKey);
  }

  public getSelectedContactTypeFor(organizationId: string, userId: string) {
    const cacheKey = this.createSelectedContactTypeCacheKey(organizationId, userId);
    return this.cache.get<string>(cacheKey);
  }

  public removeSelectedContactTypeFor(organizationId: string, userId: string) {
    const cacheKey = this.createSelectedContactTypeCacheKey(organizationId, userId);
    return this.cache.remove(cacheKey);
  }

  public getFilterFor(organizationId: string, userId: string, contactTypeKey: string) {
    const cacheKey = this.createContactTypeFilterCacheKey(organizationId, userId, contactTypeKey);
    const filter = this.cache.get<GroupFilter>(cacheKey);
    return (filter == null) ? new GroupFilter() : createFrom(GroupFilter, filter);
  }

  public setFilterFor(organizationId: string, userId: string, contactTypeKey: string, filter: GroupFilter) {
    const cacheKey = this.createContactTypeFilterCacheKey(organizationId, userId, contactTypeKey);
    this.cache.set<GroupFilter>(cacheKey, filter);
  }

  public removeFilterFor(organizationId: string, userId: string, contactTypeKey: string) {
    const cacheKey = this.createContactTypeFilterCacheKey(organizationId, userId, contactTypeKey);
    this.cache.remove(cacheKey);
  }

  private createSelectedContactTypeCacheKey = (organizationId: string, userId: string) =>
    `${organizationId}:${userId}-selected-contact-type`;

  private createContactTypeFilterCacheKey = (organizationId: string, userId: string, contactTypeKey: string) =>
    `${organizationId}:${userId}-filter-for-${(contactTypeKey == null)
      ? 'all-contacts'
      : 'type-' + contactTypeKey + '-contacts'}`;
}
