import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { Team, TeamType} from "models/team";

@inject
export class TeamRepository {
  constructor(private s: ATS) {}

  async post(team: Team, teamType = TeamType.Evaluation) {
    const res = await this.s.post(`team`, team, { teamType });
    return createFrom(Team, res.body);
  }

  async put(team: Team) {
    const res = await this.s.put(`team/${team.Id}`, team);
    return createFrom(Team, res.body);
  }

  public async putCategory(ids: string[], category: string) {
    await this.s.put(`team/${ids.join(",")}/category`, null, {
      category,
    });
  }

  async categories(type: TeamType) {
    const res = await this.s.get<string[]>("team/categories", { type });
    return res.body;
  }

  public async del(ids: string[]) {
    await this.s.delete(`team/${ids.join(",")}`);
  }

  async list(f = null, s = null, sort = null, page = 1, pageSize = 20, fields: string = null,) {
    const res = await this.s.get<any[]>("team", { f, s, sort, page, pageSize, fields });
    return { list: createFromArray(Team, res.body), total: parseInt(res.headers["x-ats-total"], 10) };
  }

  public async getIds(ids: string[]) {
    const res = await this.s.post<any[]>("team/batch", ids);
    return createFromArray(Team, res.body);
  }
}
