import { prop, ComponentEventBus, inject } from "fw";
import { TaskDateDueFilter as TaskDateDueFilterModel, DateDueFilterType } from "models/task-filters";


@inject
export class TaskDateDueFilter {
  @prop(null) filter!: TaskDateDueFilterModel;
  @prop(false) editing!: boolean;
  @prop(() => ({})) validation;

  constructor(private ceb: ComponentEventBus) { }

  dateDueOptions = [
    { value: DateDueFilterType.Any, text: "Any" },
    { value: DateDueFilterType.Overdue, text: "Overdue" },
    { value: DateDueFilterType.DueToday, text: "Due today" },
    { value: DateDueFilterType.DueSoon, text: "Due soon" },
    { value: DateDueFilterType.Today, text: "Today" },
    { value: DateDueFilterType.Tomorrow, text: "Tomorrow" },
    { value: DateDueFilterType.Next7Days, text: "In next 7 days" },
    { value: DateDueFilterType.NextXDays, text: "In next X days" },
    { value: DateDueFilterType.CurrentMonth, text: "In current month" },
    { value: DateDueFilterType.CurrentYear, text: "In current calendar year" },
    { value: DateDueFilterType.DayRange, text: "Sliding range" },
    { value: DateDueFilterType.DateRange, text: "Date range" },
  ];

  public get dateDueFilterType() {
    return DateDueFilterType;
  }

  public get timing() {
    return this.filter.timing;
  }

  public set timing(timing: string) {
    const filter = { ...this.filter, timing, daysFrom: "", daysTo: "", startDate: "", endDate: "" };
    this.ceb.dispatch("update:filter", filter);
    if (!(filter.timing == DateDueFilterType.NextXDays || filter.timing == DateDueFilterType.DayRange || filter.timing == DateDueFilterType.DateRange)) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get startDate() {
    return this.filter.startDate;
  }

  public set startDate(startDate: string) {
    const filter = { ...this.filter, startDate };
    this.ceb.dispatch("update:filter", filter);
    if (filter.startDate && filter.endDate) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get endDate() {
    return this.filter.endDate;
  }

  public set endDate(endDate: string) {
    const filter = { ...this.filter, endDate };
    this.ceb.dispatch("update:filter", filter);
    if (filter.startDate && filter.endDate) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get daysFrom() {
    return this.filter.daysFrom;
  }

  public set daysFrom(daysFrom: number) {
    const filter = { ...this.filter, daysFrom };
    this.ceb.dispatch("update:filter", filter);
    // when daysFrom is blanked out it will be typeof string instead of number
    if (typeof daysFrom == "number" && filter.daysFrom >= 0 && filter.daysTo >= 0) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get daysTo() {
    return this.filter.daysTo;
  }

  public set daysTo(daysTo: number) {
    const filter = { ...this.filter, daysTo };
    this.ceb.dispatch("update:filter", filter);
    if (filter.daysFrom >= 0 && filter.daysTo >= 0) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  get friendlyTiming() {
    if (this.filter?.timing == null) return "";

    switch (this.filter.timing) {
      case DateDueFilterType.Overdue: return "overdue";
      case DateDueFilterType.DueToday: return "due today";
      case DateDueFilterType.DueSoon: return "due soon";
      case DateDueFilterType.Today: return "today";
      case DateDueFilterType.Tomorrow: return "tomorrow";
      case DateDueFilterType.Next7Days: return "in next 7 days";
      case DateDueFilterType.NextXDays: return `in next ${this.filter.daysFrom} day${this.filter.daysFrom == 1 ? '' : 's'}`;
      case DateDueFilterType.CurrentMonth: return "in current month";
      case DateDueFilterType.CurrentYear: return "in current calendar year";
      case DateDueFilterType.DateRange: return `from ${this.filter.startDate} to ${this.filter.endDate}`;
      case DateDueFilterType.DayRange: return `${this.filter.daysFrom} day${this.filter.daysFrom == 1 ? '' : 's'} from now to ${this.filter.daysTo} day${this.filter.daysTo == 1 ? '' : 's'} from now`;

      default: return "any";
    }
  };
}
