import { inject, needs, prop } from "fw";
import { ContactsTagsFilter as ContactsTagsFilterModel }  from "models/contacts-filters";
import { Field } from 'views/contacts/components/field';

@inject
@needs(Field)
export class ContactsTagsFilter {
  @prop(null) filter!: ContactsTagsFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  get modeText() {
    return {
      any: "Any of the following",
      all: "All of the following",
      none: "None of the following",
      wildcard: "Wildcard match",
      wildcardExclude: "Wildcard exclude"
    };
  }

  maxDepth() {
    return this.filter && this.filter.field.data.maximum_tag_hierarchy_depth || 1;
  }

  get wildcardPlaceholder() {
    return "*/".repeat(this.maxDepth() - 1) + "*";
  }

  get modes() {
    return [
      { text: this.modeText["any"], value: "any" },
      { text: this.modeText["all"], value: "all" },
      { text: this.modeText["none"], value: "none" },
      { text: this.modeText["wildcard"], value: "wildcard" },
      { text: this.modeText["wildcardExclude"], value: "wildcardExclude" },
    ];
  }
}
