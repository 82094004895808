import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { TaskRequest } from "models/task-request";

@inject
export class TaskRequestRepository {
  constructor(private s: ATS) { }

  public async get(id: string) {
    const res = await this.s.get(`task-request/${id}`);
    return createFrom(TaskRequest, res.body);
  }

  public async getIds(ids: string[]) {
    if(ids?.length){
      const res = await this.s.get<any[]>(`task-requests/${ids.join(",")}`);
      return createFromArray(TaskRequest, res?.body);
    }else
      return null;
  }

  public async list(pageSize: number = null, sort: string = null) {
    const res = await this.s.get<any[]>("task-request", { pageSize, sort });
    return createFromArray(TaskRequest, res.body);
  }
}
