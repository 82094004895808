import { inject, needs } from "fw";
import { PopoverController } from "service/popover";
import { GoalsStore, GoalView } from 'state/goals';
import { GoalType } from "models/goal";

export interface SelectGoalsPopoverArgs {
  selectedGoalIds: string[];
  goalType: GoalType;
}

@inject
export class SelectGoalsPopover {

  private selectedGoalIds: string[] = [];
  private goalType: GoalType

  constructor(
    private controller: PopoverController<string[]>,
    private goalsStore: GoalsStore
  ) { }

  private activate(params: SelectGoalsPopoverArgs) {
    this.selectedGoalIds = !params.selectedGoalIds
      ? []
      : params.selectedGoalIds;
    this.goalType = params.goalType;
  }

  get goals() {
    const goals: GoalView[] = this.goalsStore.state.goals || [];

    return  goals.filter((item) => item.definition.GoalType === this.goalType);
  }

  private selectGoal(goalId: string) {
    if (!this.selectedGoalIds.includes(goalId)) {
      this.selectedGoalIds.push(goalId);
    } else {
      const index = this.selectedGoalIds.findIndex(goalToFind => goalToFind == goalId);
      this.selectedGoalIds.splice(index, 1);
    }
  }

  get allSelected() {
    return this.goals.length === this.selectedGoalIds.length;
  }

  private selectAll() {
    if (this.allSelected) {
      this.selectedGoalIds = [];
    } else {
      this.selectedGoalIds = this.goals.map(goal => goal.definition.Id);
    }
  }

  private save() {
    this.controller.ok(this.selectedGoalIds);
  }
}
