import { fromClass, fromClassArray } from "fw-model";

export enum AssignmentType {
  Evaluation = 0,
  Collaboration = 1,
}

export class RoutingTableFallThroughAssignment {
  TeamId = "";
  UserId = "";
}

export class RoutingTableCell {
  StringValue = "";
}

export class RoutingTableRow {
  TeamId = "";
  UserId = "";
  @fromClassArray(RoutingTableCell) Cells: RoutingTableCell[];
}

export enum RoutingTableColumnFieldType {
  Number = 0,
  Boolean = 1,
  Date = 2,
  Text = 3,
  PostalCode = 4,
}

export class RoutingTableColumnMetaData {
  DataType = RoutingTableColumnFieldType.Text;
}

export class RoutingTableColumn {
  Label = "";
  Path = "";

  @fromClass MetaData: RoutingTableColumnMetaData;
}

export class RoutingTableMetaData {
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  OrganizationId: string;
  SeasonId: string;
  AssignmentType: AssignmentType;
}

export class RoutingTable {
  Id: string;
  Name = "";

  @fromClassArray(RoutingTableRow) Rows: RoutingTableRow[];
  @fromClassArray(RoutingTableColumn) Columns: RoutingTableColumn[];
  @fromClass FallThroughAssignment: RoutingTableFallThroughAssignment;

  @fromClass MetaData: RoutingTableMetaData;
}
