import { inject, prop, needs } from "fw";

import type { FormForReportChartDefinition } from "forms/report-definition";
import { ReportChartType } from "shared/report-runtime";

import { TableOptions } from "./chart-options/table-options";

@inject
@needs(TableOptions)
export class ChartOptions {
  @prop(null) chartDefinitionForm!: FormForReportChartDefinition;

  private chartTypes = ReportChartType;

  constructor() { }
}
