import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { MarketingOutreachTrack } from "models/marketing-outreach-track";

@inject
export class MarketingOutreachTrackRepository {
  constructor(private network: ATS) {}

  async index(campaignId: number = 0) {
    const res = await this.network.marketing.get<any[]>(`outreach_tracks/index/${campaignId}`);
    return createFromArray(MarketingOutreachTrack, res.body);
  }

  async show(id: number) {
    const res = await this.network.marketing.get<MarketingOutreachTrack>(`outreach_tracks/${id}`);
    return createFrom(MarketingOutreachTrack, res.body);
  }

  async post(track: MarketingOutreachTrack) {
    const res = await this.network.marketing.post<MarketingOutreachTrack>("outreach_tracks", track);
    return createFrom(MarketingOutreachTrack, res.body);
  }

  async put(track: MarketingOutreachTrack) {
    const res = await this.network.marketing.put<MarketingOutreachTrack>(`outreach_tracks/${track.Id}`, track);
    return createFrom(MarketingOutreachTrack, res.body);
  }

  async destroy(id: number) {
    await this.network.marketing.delete<MarketingOutreachTrack>(`outreach_tracks/${id}`);
  }

  async copy(id: number) {
    const res = await this.network.marketing.post<MarketingOutreachTrack>("outreach_tracks/copy", {Id: id});
    return createFrom(MarketingOutreachTrack, res.body);
  }
}
