import { ComponentEventBus, inject, prop } from "fw";
import { dispatch } from "fw-state";
import { isEmpty, keys } from "lodash-es";
import { DataDictionaryField, DataDictionaryFieldCategory } from "models/data-dictionary";
import { ProgramStep } from "models/program";

import { ApplicationRestriction, type ApplicationRestrictionType, getCategoryFromApplicationRestrictionType, getStepCode } from "models/role";
import { DataDictionaryService } from "service/data-dictionary";
import { FeatureFlagService } from "service/feature-flag";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";

interface DataDictionaryFieldKeyName {
  key: string,
  name: string
}

@inject
export class ProgramStepRestrictions {
  @prop(null) type!: ApplicationRestrictionType;
  @prop(() => []) activeRestrictions!: ApplicationRestriction[];

  private existingHash = {};
  private searchTerm = "";
  private selectedHash = {};
  private fields: DataDictionaryField[] = [];

  constructor(
    private ceb: ComponentEventBus,
    private dataDictionaryStore: DataDictionaryStore,
    private dataDictionaryService: DataDictionaryService,    
    private ffs: FeatureFlagService
  ) { }

  public async attached() {
    const existingHash = {};
    for (const restriction of this.activeRestrictions.filter(r => r.Type == this.type) || []) {
      existingHash[restriction.StepKey] = true;
    }
    this.existingHash = existingHash;


    await dispatch(new EnsureDataDictionaryFieldsAction(false));
    this.fields = this.dataDictionaryStore.state.fields;
  }

  get name() {
    switch (this.type) {
      case "DocumentStep": return "Documents";
      case "PortfolioStep": return "Portfolios";
      default: return "";
    }
  }

  get searchText() {
    return `Search ${this.name}s...`;
  }

  public get localType() {
    return this.type;
  }
  public set localType(type: ApplicationRestrictionType) {
    this.ceb.dispatch("update:type", type);
  }

  get hasSelectedValues() {
    if (!this.selectedHash || isEmpty(this.selectedHash))
      return false;

    const stepKeys = keys(this.selectedHash);
    return stepKeys && stepKeys.length > 0;
  }
  

  public get filteredFields(): DataDictionaryFieldKeyName[] {
    const category: DataDictionaryFieldCategory = getCategoryFromApplicationRestrictionType(this.type);
    let stepFields = this.dataDictionaryService.includeSteps(this.fields, category);
    if (stepFields.length === 0) {
      return [];
    }

    if (this.searchTerm) {
      const st = this.searchTerm.toLowerCase();
      stepFields = stepFields.filter(p => p.Label.toLowerCase().includes(st))
    }

    return stepFields.map(s => <DataDictionaryFieldKeyName>{
      key: this.dataDictionaryService.getFieldPathKey(s),
      name: s.Label
    });
  }

  ensureField(checked: boolean, path: string) {
    if (!checked) {
      delete this.selectedHash[path];

      const stepKeys = keys(this.selectedHash);
      if (!stepKeys || stepKeys.length === 0) {
        this.selectedHash = {};
      }
    }
  }

  done() {
    if (this.selectedHash && !isEmpty(this.selectedHash)) {
      let restrictions = [];
      for (const stepKey of keys(this.selectedHash)) {
        const restriction = new ApplicationRestriction;
        restriction.Type = this.type;
        restriction.StepKey = stepKey;
        restrictions.push(restriction);
      }
      this.ceb.dispatch("done", restrictions);
    }
  }

}
