import { prop, inject, ComponentEventBus } from "fw";
import { dispatch } from "fw-state";

import { PopoverService } from "service/popover";
import type { FormForAssignmentDefinition } from "forms/report-definition";
import {
  OptionChoice,
  OptionChooserPopover
} from "../charts/option-chooser-popover";
import { EvaluationPhasesStore } from "state/evaluation-phases";
import {
  SelectAssignmentResult,
  SelectAssignmentPopover
} from "../charts/select-assignment-popover";
import { EnsureTeamsAction, TeamsStore } from "state/teams";
import { EnsureUsersAction, UsersStore } from "state/users";

let inputIdIterator: number = 0;

@inject
export class AssignmentEditor {
  @prop(null) widgetDefinitionForm: FormForAssignmentDefinition;

  private assignmentEditorBaseInputId: string = "assignment-editor-input";
  private assignmentEditorPopoverId: string = "assignment-editor-popover";
  private focusedOptionId: string = '';
  private popoverIsOpen: { [key: string]: boolean } = {
    assignedTo: false,
    phase: false,
  };

  constructor(
    private evalPhaseStore: EvaluationPhasesStore,
    private ceb: ComponentEventBus,
    private popoverService: PopoverService,
    private teamStore: TeamsStore,
    private usersStore: UsersStore
  ) {}

  attached() {
    this.ensureObject();
  }

  ensureObject() {
    if (this.widgetDefinitionForm.Mode == "team")
      dispatch(new EnsureTeamsAction([this.widgetDefinitionForm.Id]));

    if (
      this.widgetDefinitionForm.Mode == "user" &&
      this.widgetDefinitionForm.Id != null &&
      this.widgetDefinitionForm.Id != ""
    )
      dispatch(new EnsureUsersAction([this.widgetDefinitionForm.Id]));
  }

  chartChanged() {
    this.ceb.dispatch("changed");
  }

  get assignmentEditorInputId() {
    inputIdIterator++;
    return `${this.assignmentEditorBaseInputId}-${inputIdIterator}`;
  }

  get type() {
    return this.widgetDefinitionForm.Mode == "team" ? "Team" : "User";
  }

  get phase() {
    if (this.widgetDefinitionForm == null) return null;
    if (this.widgetDefinitionForm.PhaseKey == "current") return "Current Phase";

    const p = this.evalPhaseStore.state.phases.find(
      p => p.Key == this.widgetDefinitionForm.PhaseKey
    );

    return p ? p.Name : this.widgetDefinitionForm.PhaseKey;
  }

  public updateOptionFocus(optionId: string) {
    this.focusedOptionId = optionId;
  }

  async selectType() {
    this.popoverIsOpen.assignedTo = true;
    const res = await this.popoverService.open<SelectAssignmentResult>(
      SelectAssignmentPopover,
      {
        selectedOption: this.assignedTo,
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.assignmentEditorPopoverId },
    );
    this.popoverIsOpen.assignedTo = false;

    if (res.canceled) {
      return;
    }

    this.widgetDefinitionForm.Mode = res.result.type;
    this.widgetDefinitionForm.Id = res.result.id;

    this.ensureObject();
    this.chartChanged();
  }

  async selectPhase() {
    const options = [
      { text: "Current Phase", value: "current" },
      ...this.evalPhaseStore.state.phases.map(p => ({
        text: p.Name,
        value: p.Key
      })),
    ];
    this.popoverIsOpen.phase = true;
    const res = await this.popoverService.open<OptionChoice<string>>(
      OptionChooserPopover,
      {
        options,
        selectedOption: options.find(option => this.phase === option.text),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.assignmentEditorPopoverId },
    );
    this.popoverIsOpen.phase = false;

    if (res.canceled) {
      return;
    }

    this.widgetDefinitionForm.PhaseKey = res.result.value;
    this.chartChanged();
  }

  get assignedTo() {
    if (this.widgetDefinitionForm == null) return "";

    const teamId =
      this.widgetDefinitionForm.Mode == "team"
        ? this.widgetDefinitionForm.Id
        : null;
    const userId =
      this.widgetDefinitionForm.Mode == "user"
        ? this.widgetDefinitionForm.Id
        : null;

    if (teamId != null) {
      const team = this.teamStore.state.teamHash[teamId];
      if (team != null) return team.Name;

      return "Loading...";
    }
    if (userId != null) {
      const user = this.usersStore.state.userHash[userId];
      if (user != null) return `${user.FirstName} ${user.LastName}`;

      return "Loading...";
    }

    return "Current User";
  }
}
