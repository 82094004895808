import { inject, prop, needs } from "fw";

import { ErrorsFilter as ErrorsFilterModel } from "models/application-filters";

@inject
export class ErrorsFilter {
  @prop(null) filter!: ErrorsFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  constructor() { }

  get friendly() {
    if (this.filter == null) return "";
    return this.filter.hasErrors ? "Has" : "No";
  }
}
