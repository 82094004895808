import { inject } from "fw";
import { dispatch } from "fw-state";

import { TaskRequestRepository } from "network/task-request-repository";
import { TaskRequest, TaskRequestStatusTypeCode } from "models/task-request";
import { TaskFinishedAction, TaskUpdatedAction } from "state/actions";


export const isTerminal = (task: TaskRequest) => task.Status == TaskRequestStatusTypeCode.Complete || task.Status == TaskRequestStatusTypeCode.Error;

const TIMER_INTERVAL = 1000;

@inject
export class TaskWatcher {
  private timer = null;

  private watching: TaskRequest[] = [];
  private isChecking = false;

  constructor(private taskRepo: TaskRequestRepository) { }

  watch(task: TaskRequest) {
    this.startWatching(task, true);
  }

  isWatching(task: TaskRequest) {
    return this.watching.find(x => x.Id == task.Id);
  }

  clearAll() {
    this.watching = [];

    this.isChecking = false;

    if (this.timer != null) clearInterval(this.timer);
    this.timer = null;
  }

  private startWatching(task: TaskRequest, startOver = false) {
    if (isTerminal(task)) return;

    this.watching.push(task);
    this.triggerNextCheck(startOver);
  }

  private triggerNextCheck(startOver = false) {
    if (this.watching.length == 0) return;

    if (this.timer != null) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(this.checkTasks.bind(this), TIMER_INTERVAL);
  }

  private async checkTasks() {
    if (this.isChecking || this.watching.length == 0) return;

    this.isChecking = true;

    try {
      const tasks = await this.taskRepo.getIds(this.watching.map(t => t.Id));

      tasks.forEach(t => {
        const isInWatching = this.watching.find(wt => wt.Id == t.Id) != null;

        if (!isInWatching) return;

        if (isTerminal(t)) {
          this.removeFromWatching(t);
          dispatch(new TaskUpdatedAction(t));
          setTimeout(() => dispatch(new TaskFinishedAction(t)), 1000);
        } else {
          dispatch(new TaskUpdatedAction(t));
        }
      });
    } catch(err) { }

    this.isChecking = false;

    this.triggerNextCheck();
  }

  // this will try remove it from both lists
  private removeFromWatching(task: TaskRequest) {
    const taskInList = this.watching.find(t => t.Id == task.Id);
    if (taskInList != null) {
      const idx = this.watching.indexOf(taskInList);
      if (idx != -1) this.watching.splice(idx, 1);
    }
  }
}
