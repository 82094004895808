import { inject, ComponentEventBus, prop } from "fw";

@inject
export class SearchFilter {
  @prop("") public name: string;
  @prop("") public value: string;
  @prop(null) public onKeyup: () => Promise<void>;

  public localValue: string = "";
  private gridFilterRef: any;

  constructor(private ceb: ComponentEventBus) { }

  public attached() {
    this.valueChanged();
  }

  public keyup(e) {
    if (e?.code === "Enter") {
      this.ceb.dispatch("enter");
    }
    this.ceb.dispatch("update:value", this.localValue);
    this.ceb.dispatch("change");

    if (this.onKeyup) this.onKeyup();
  }

  public valueChanged() {
    this.localValue = this.value;
  }

  public onClick() {
    this.gridFilterRef.focus();
  }

  public clearFilter() {
    this.localValue = "";
    this.gridFilterRef.focus();
    this.keyup(null);
  }

  public get dataId(): string {
    const name = this.name.toLowerCase().replace(" ", "-");

    return "search-" + name;
  }
}
