import { inject } from "fw";

import { Permissions } from "service/permission";
import { UserPermissionService } from "./user";

@inject
export class ConversationPermissionService {
  constructor(
    private permissions: Permissions,
    private userPermissionService: UserPermissionService,
  ) { }

  public get isGlobalUser(): boolean {
    return this.userPermissionService.isGlobalUser;
  }

  public get isImpersonating(): boolean {
    return this.userPermissionService.isImpersonating;
  }

  public get currentUserId(): string {
    return this.userPermissionService.currentUserId;
  }

  public get canAccess(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.any(["ManageAssignedConversations", "ViewAllConversations", "ManageAllConversations"]);
  }

  public get canViewAll(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ViewAllConversations"]);
  }

  public get canManageAssigned(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ManageAssignedConversations"]);
  }

  public get canManageAll(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ManageAllConversations"]);
  }

  public get shouldLoadMyConversations(): boolean {
    if (this.isGlobalUser) return false;
    return this.canManageAssigned && !this.canManageAll;
  }

  public get canViewAllListing(): boolean {
    if (this.canViewAll) return true;
    if (this.canManageAll) return true;
    if (this.isGlobalUser) return true;
    return false;
  }

  public get canViewListing(): boolean {
    if (this.canViewAll) return true;
    if (this.canManageAll) return true;
    if (this.isGlobalUser) return true;
    return this.canManageAssigned;
  }

  public get canGenerallyUpdate(): boolean {
    if (this.isGlobalUser) return true;
    if (this.isImpersonating) return false;
    if (this.canManageAll) return true;
    return this.canManageAssigned;
  }

  public canView(assigneeId: string): boolean {
    if (this.canViewAll) return true;
    if (this.canManageAll) return true;
    if (this.isGlobalUser) return true;
    return this.canManageAssigned && this.isAssignedToMe(assigneeId);
  }

  public canUpdateStatus(assigneeId: string): boolean {
    if (this.isGlobalUser) return true;
    if (this.isImpersonating) return false;
    if (this.canManageAll) return true;
    return this.canManageAssigned && this.isAssignedToMe(assigneeId);
  }

  public getUnableToUpdateStatusReason(assigneeId: string): string | undefined {
    if (this.isImpersonating) {
      return "Impersonated users cannot update status.";
    }
    if (!this.canManageAssigned) {
      return "The current user cannot update conversation status.";
    }
    if (!this.isAssignedToMe(assigneeId)) {
      return "The conversation does not belong to the current user.";
    }
  }

  public canReassign(assigneeId: string): boolean {
    if (this.isGlobalUser) return true;
    if (this.isImpersonating) return false;
    if (this.canManageAll) return true;
    return this.canManageAssigned && this.isAssignedToMe(assigneeId);
  }

  public getUnableToReassignReason(assigneeId: string): string | undefined {
    if (this.isImpersonating) {
      return "Impersonated users cannot reassign conversations.";
    }
    if (!this.canManageAssigned) {
      return "The current user cannot reassign conversations.";
    }
    if (!this.isAssignedToMe(assigneeId)) {
      return "The conversation does not belong to the current user.";
    }
  }

  public canBulkUpdate(assigneeIds: string[]): boolean {
    if (this.isGlobalUser) return true;
    if (this.isImpersonating) return false;
    if (this.canManageAll) return true;
    if (1 === assigneeIds.length) {
      return this.canManageAssigned && this.isAssignedToMe(assigneeIds[0]);
    }
    return false;
  }

  public getUnableToBulkUpdateReason(assigneeIds: string[]): string | undefined {
    if (this.isImpersonating) {
      return "Impersonated users cannot update conversations.";
    }
    if (!this.canManageAssigned) {
      return "The current user cannot update conversations.";
    }
    if (
      (assigneeIds.length > 1 && !this.canManageAll)
      || (1 === assigneeIds.length && !this.isAssignedToMe(assigneeIds[0]))
    ) {
      return "The current user can only manage conversations that belong to them.";
    }
  }

  public canMessage(assigneeId: string): boolean {
    if (this.isGlobalUser) return true;
    if (this.isImpersonating) return false;
    if (this.canManageAll) return true;
    return this.canManageAssigned && this.isAssignedToMe(assigneeId);
  }

  public getUnableToMessageReason(assigneeId: string): string | undefined {
    if (this.isImpersonating) {
      return "Impersonated users cannot message contacts.";
    }
    if (!this.canManageAssigned) {
      return "The current user cannot assign conversations.";
    }
    if (!this.isAssignedToMe(assigneeId)) {
      return "The conversation does not belong to the current user.";
    }
  }

  public isAssignedToMe(assigneeId: string): boolean {
    return this.currentUserId === assigneeId;
  }
}
