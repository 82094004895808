import { ComponentEventBus, inject, prop } from "fw";
import { isEmpty } from "lodash-es";
import { ApplicationRestriction, type ApplicationRestrictionType } from "models/role";
import { DataDictionaryService } from "service/data-dictionary";
import { FeatureFlagService } from "service/feature-flag";
import { PathAndLabel, toPathAndLabel } from "views/components/path-chooser";

@inject
export class ApplicationPropertyRestrictions {
  @prop(null) type!: ApplicationRestrictionType;
  @prop(() => []) activeRestrictions!: ApplicationRestriction[];

  private searchTerm = "";
  private existingHash = {};
  private selectedHash = {};
  private applicationProperties: PathAndLabel[] = [];

  constructor(
    private ceb: ComponentEventBus,    
    private dataDictionaryService: DataDictionaryService,
    private ffs: FeatureFlagService,
  ) { }

  public async attached() {
    const existingHash = {};
    for (const restriction of this.activeRestrictions.filter(r => r.Type == this.type) || []) {
      for (const path of restriction.Paths) {
        existingHash[path.startsWith("properties.") ? path.substring(11) : path] = true;
      }
    }
    this.existingHash = existingHash;


    const fields = await this.dataDictionaryService.getApplicationPropertyFields();
    this.applicationProperties = fields.map(f => toPathAndLabel(f));

  }

  public get localType() {
    return this.type;
  }
  public set localType(type: ApplicationRestrictionType) {
    this.ceb.dispatch("update:type", type);
  }

  get hasSelectedValues() {
    if (!this.selectedHash || isEmpty(this.selectedHash))
      return false;

    const keys = Object.keys(this.selectedHash);
    return keys && keys.length > 0;
  }

  public get filtered() {
    const pathAndLabels = this.applicationProperties;
    if (!pathAndLabels || pathAndLabels.length === 0)
      return [];

    if (!this.searchTerm || this.searchTerm.length === 0) {
      return pathAndLabels;
    }

    const st = this.searchTerm.toLowerCase();
    return pathAndLabels.filter(p => p.Label.toLowerCase().indexOf(st) != -1);
  }

  ensureField(checked: boolean, path: string) {
    if (!checked) {
      delete this.selectedHash[path];

      const keys = Object.keys(this.selectedHash);
      if (!keys || keys.length === 0) {
        this.selectedHash = {};
      }
    }
  }

  done() {
    const paths = Object.keys(this.selectedHash);
    const restriction = new ApplicationRestriction;
    restriction.Type = this.type;
    restriction.Paths = paths;
    this.ceb.dispatch("done", [restriction]);
  }

}
