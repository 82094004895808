import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { PortalInvitation } from "models/portal-invitation";
import { PortalInvitationRepository } from "network/portal-invitation-repository";
import { CurrentOrganizationStore } from "./current-organization";

interface UserInvites {
  isLoading: boolean;
  invites: PortalInvitation[];
  userId: string;
}

export class SetUserInvitesAction {
  constructor(public userId: string) {}
}

export class SetStartUpdateUserInvitesAction {
  constructor() {}
}

export class UserInvitationsRefreshAction {}

@inject
export class ManageUserInvitesStore extends Store<UserInvites> {
  constructor(
    private inviteRepo: PortalInvitationRepository,
    private currentOrgStore: CurrentOrganizationStore
  ) {
    super();
  }

  defaultState() {
    return {
      isLoading: false,
      invites: [],
      userId: null,
    };
  }

  @handle(SetStartUpdateUserInvitesAction)
  private async handleSetStartUpdateUserInvitesAction(
    action: SetStartUpdateUserInvitesAction
  ) {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
  }

  @handle(SetUserInvitesAction)
  private async handleSetUserInvitesAction(action: SetUserInvitesAction) {
    if (!this.state.isLoading)
      await dispatch(new SetStartUpdateUserInvitesAction());
    try {
      const invites = await this.inviteRepo.list(
        `metaData.organizationId:${this.currentOrgStore.state.organization.Id}`,
        `userId:${action.userId}`
      );
      this.setState((state) => ({
        isLoading: false,
        invites: invites.list,
        userId: action.userId,
      }));
    } catch (e) {
      console.error("Failed to refresh invites. Error: ", e);

      this.setState((state) => ({
        ...state,
        isLoading: false,
      }));
    }
  }

  @handle(UserInvitationsRefreshAction)
  private async handleUserInvitationsRefreshAction(
    action: UserInvitationsRefreshAction
  ) {
    if (!this.state.userId) return;
    await dispatch(new SetUserInvitesAction(this.state.userId));
  }
}
