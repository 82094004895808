import { prop, inject, ComponentEventBus } from "fw";

export type IconBadgeDataType = {
  label: any;
  bgColor: string;
  textColor: string;
  icon: string;
  key?: any;
  emit?: boolean;
};

@inject
export class BadgeWithIcon {
  @prop(null) private badgeData: IconBadgeDataType;

  constructor(
    private ceb: ComponentEventBus
  ) {}

  public async emit() {
    this.ceb.dispatch('emit:click');
  }
}
