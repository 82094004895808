import { inject, needs } from "fw";

import { PopoverController } from "service/popover";
import { UsersStore } from "state/users";
import { TeamsStore } from "state/teams";
import { User } from "models/user";
import { CurrentOrganizationStore } from "state/current-organization";
import { Team, TeamType } from "models/team";
import { UserRepository } from "network/user-repository";
import { TeamRepository } from "network/team-repository";
import { ItemSearcher, LoadDataContext, SearchResults } from "views/components/item-searcher";

export type SelectAssignmentResult = { type: "user" | "team", id: string };

export interface SelectAssignmentPopoverOptions {
  selectedAssignee?: User | Team;
  updateOptionFocus?: Function;
}

@inject
@needs(ItemSearcher)
export class SelectAssignmentPopover {
  private page = "";
  selectedAssignee?: User | Team = null;
  updateOptionFocus?: Function = () => {};

  public constructor(
    private currentOrgStore: CurrentOrganizationStore,
    private teamsStore: TeamsStore,
    private usersStore: UsersStore,
    private userRepo: UserRepository,
    private teamRepo: TeamRepository,
    private controller: PopoverController<SelectAssignmentResult>,
  ) { }

  public activate(params: SelectAssignmentPopoverOptions) {
    this.page = "select";
    //this.assignmentType = params.assignmentType;
    this.selectedAssignee = params.selectedAssignee;
    if (params?.updateOptionFocus) {
      this.updateOptionFocus = params.updateOptionFocus;
    }
    this.updateOptionFocus(this.selectedAssignee?.Id);
  }

  public get allTeams(): Team[] {
    return this.teamsStore.state.teams.filter(t => t.MetaData.TeamType == TeamType.Evaluation);
  }

  public loadUserData(context: LoadDataContext): Promise<SearchResults<User>> {
    const { filter, search, sort, page, pageSize } = context;
    return this.userRepo.list(filter, search, sort, page, pageSize);
  }

  public loadTeamData(context: LoadDataContext): Promise<SearchResults<Team>> {
    const { filter, search, sort, page, pageSize, fields } = context;
    return this.teamRepo.list(filter, search, sort, page, pageSize, fields);
  }

  public get teamFilter(): string {
    return `metaData.teamType:0`;
  }

  public selectUser(user: User) {
    this.selectedAssignee = user;
    this.updateOptionFocus(user?.Id);
    this.controller.ok({ type: "user", id: user?.Id });
  }

  public selectTeam(team: Team) {
    this.selectedAssignee = team;
    this.updateOptionFocus(team?.Id);
    this.controller.ok({ type: "team", id: team?.Id });
  }

  public selectCurrentUser() {
    this.selectedAssignee = null;
    this.updateOptionFocus('CurrentUser');
    this.controller.ok({ type: "user", id: null });
  }
}
