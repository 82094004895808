import { inject } from "fw";
import { Store, handle } from "fw-state";

import { StartAction, LogoutAction } from "./actions";
import { CollaborationModuleRepository } from "network/collaboration-module-repository";

import { FormErrorHandling } from "./error-handling";
import { CollaborationModule } from "models/collaboration-module";
import {
  CreateCollaborationModuleAction,
  DeleteCollaborationModuleAction,
  UpdateCollaborationModuleAction,
} from "forms/collaboration-module";
import { difference } from "lodash-es";

interface CollaborationModulesShape {
  modules: CollaborationModule[];
}

@inject
export class CollaborationModulesStore extends Store<
  CollaborationModulesShape
> {
  constructor(private collabRepo: CollaborationModuleRepository) {
    super();
  }

  defaultState() {
    return {
      modules: [],
    };
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    this.setState(state => ({
      ...state,
      modules: action.context.CollaborationModules,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(CreateCollaborationModuleAction, FormErrorHandling)
  private async handleCreateCollaborationModuleAction(action: CreateCollaborationModuleAction) {
    action.form.validate();

    const newModule = await this.collabRepo.post(action.form.updatedModel(), action.type);

    this.setState(state => ({
      ...state,
      modules: [ ...state.modules, newModule ],
    }));
  }

  @handle(UpdateCollaborationModuleAction, FormErrorHandling)
  private async handleUpdateCollaborationModuleAction(action: UpdateCollaborationModuleAction) {
    action.form.validate();

    const updatedModule = await this.collabRepo.put(action.form.updatedModel());
    const existingModule = this.state.modules.find(m => m.Id == updatedModule.Id);

    if (existingModule != null) {
      Object.assign(existingModule, updatedModule);
    }

    this.setState(s => s);
  }

  @handle(DeleteCollaborationModuleAction)
  private async handleDeleteCollaborationModuleAction(action: DeleteCollaborationModuleAction) {
    await this.collabRepo.del(action.ids);

    const deleted = this.state.modules.filter(u =>
      action.ids.some(id => id == u.Id),
    );

    this.setState(state => ({
      ...state,
      modules: difference(state.modules, deleted),
    }));
  }
}
