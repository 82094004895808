import { inject, needs, prop, ComponentEventBus } from "fw";
import allFilters from "./../filters-index";
import { GroupFilter as GroupFilterFormModel } from "models/filter-setup";

@inject
@needs(...allFilters)
export class GroupFilter {
  @prop(null) public filter: GroupFilterFormModel;
  @prop(false) public editing: boolean;
  @prop(true) public showRuntimeFieldsWarning!: boolean;

  constructor(private ceb: ComponentEventBus) { }
}
