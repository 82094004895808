import { needs, inject } from "fw";

import { PopoverController } from "service/popover";

import { SelectUser } from "./select-user";
import { User } from "models/user";

export interface SelectUserPopoverOptions {
  selectedIds: string[];
  excludedIds: string[];
  allowUnassigned: boolean;
  selectedUser: User;
  unassignedPlaceholder: string;
  updateOptionFocus?: Function;
}

@inject
@needs(SelectUser)
export class SelectUserPopover {
  public selectedIds: string[] = [];
  public excludedIds: string[] = [];
  public allowUnassigned: boolean = false;
  public selectedUser: User = null;
  public unassignedPlaceholder: string = null;
  public updateOptionFocus: Function = () => {};

  constructor(
    private controller: PopoverController<string>
  ) {}

  public activate(options: SelectUserPopoverOptions) {
    if (!options) {
      return;
    }

    this.selectedIds = options.selectedIds;
    this.excludedIds = options.excludedIds;
    this.allowUnassigned = options.allowUnassigned;
    this.unassignedPlaceholder = options.unassignedPlaceholder;
    this.selectedUser = options.selectedUser;
    if (options?.updateOptionFocus) {
      this.updateOptionFocus = options?.updateOptionFocus;
    }
    this.updateOptionFocus(this.selectedUser?.Id);
  }

  public select(user: User) {
    this.updateOptionFocus(user?.Id);
    this.controller.ok(user?.Id);
  }
}
