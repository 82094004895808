import { inject } from "fw";
import { Store, handle } from "fw-state";

import { once } from "helpers/once";
import { FormErrorHandling } from "./error-handling";
import { AutomationRuleRepository } from "network/automation-rule-repository";

import { AutomationRule } from "models/automation-rule";
import { AddAutomationRuleAction, UpdateAutomationRuleAction, DeleteAutomationRulesAction } from "forms/automation-rule";

import { LogoutAction, StartAction } from "./actions";
import { difference } from "lodash-es";
import { ScheduleRecurrenceType } from 'models/scheduled-export';

interface AutomationRulesShape {
  rules: AutomationRule[];
}

export class EnsureAutomationsRulesStoreAction {
  constructor() { }
}

export class RunRuleAction {
  constructor(public ruleId: string) { }
}

@inject
export class AutomationsRulesStore extends Store<AutomationRulesShape> {
  constructor(private rulesRepo: AutomationRuleRepository) {
    super();
  }

  defaultState() {
    return {
      rules: [],
    };
  }

  @handle(StartAction)
  private async handleStart(action: StartAction) {
    this.setState(s => this.defaultState());
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureAutomationsRulesStoreAction)
  private async handleEnsureAutomationsRulesStoreAction(action: EnsureAutomationsRulesStoreAction) {
    await once("ensure-automation-rules", async () => {
      const automationRules = await this.rulesRepo.list();

      this.setState(state => ({
        rules: automationRules,
      }));
    });
  }

  @handle(AddAutomationRuleAction, FormErrorHandling)
  private async handleAddAutomationRuleAction(action: AddAutomationRuleAction) {
    action.form.validate();

    const newObj = await this.rulesRepo.post(action.form.updatedModel());

    this.setState(state => ({
      ...state,
      rules: [...state.rules, newObj],
    }));

    action.form.Id = newObj.Id;
  }

  @handle(UpdateAutomationRuleAction, FormErrorHandling)
  private async handleUpdateAutomationRuleAction(action: UpdateAutomationRuleAction) {
    action.form.validate();

    const updatedModel = action.form.updatedModel();

    if (updatedModel.Trigger.Schedule.Type !== ScheduleRecurrenceType.Once) {
      updatedModel.Trigger.Schedule.StartDate = null;
    }

    const updatedObj = await this.rulesRepo.put(updatedModel);

    const existingObj = this.state.rules.find(t => t.Id == updatedObj.Id);
    if (existingObj == null) return;

    Object.assign(existingObj, updatedObj);

    this.setState(s => s);
  }

  @handle(DeleteAutomationRulesAction)
  private async handleDeleteAutomationRulesAction(action: DeleteAutomationRulesAction) {
    const rules = this.state.rules.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.rulesRepo.del(rules.map(t => t.Id));
    this.setState(state => ({
      ...state,
      rules: difference(state.rules, rules),
    }));
  }

  @handle(RunRuleAction)
  private async handleRunRuleAction(action: RunRuleAction) {
    await this.rulesRepo.run(action.ruleId);
  }
}
