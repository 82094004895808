import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS, IListResults, ICountResult } from "./ats";
import {
  ContactsDataSource,
  DataSourceResource,
  IManualMapping,
} from "models/contacts-data-source";
import { ActivityData, ActivityPreview } from 'models/activity-template';
import { AtsConfig } from 'config/ats-config';

@inject
export class DataSourceRepository {
  constructor(private s: ATS,
    private config: AtsConfig) { }

  public async list(): Promise<IListResults<ContactsDataSource>> {
    const res = await this.s.contacts.get<IListResults<ContactsDataSource>>("data-sources");
    return res.body;
  }

  public async count(f: string = null, aggs: string = null): Promise<ICountResult> {
    return this.s.contacts.count("data-sources/count", f, aggs);
  }

  public async getById(id: string): Promise<ContactsDataSource> {
    const res = await this.s.contacts.get<ContactsDataSource>(`data-sources/${id}`);
    return createFrom(ContactsDataSource, res.body);
  }

  public async listWithSystem(includeSystem: boolean = true): Promise<ContactsDataSource[]> {
    const res = await this.s.contacts.get<IListResults<ContactsDataSource>>("data-sources", { system: includeSystem });
    return createFromArray(ContactsDataSource, res.body.results);
  }

  public async dispatchCall<T>(dataSourceId: string, method: string, params: any = null) : Promise<T> {
    const res = await this.s.contacts.get<T>(`data-sources/${dataSourceId}/call/${method}`, params);
    return res.body;
  }

  public async getResources(dataSourceId: string) : Promise<DataSourceResource[]> {
    const res = await this.s.contacts.get<DataSourceResource[]>(`data-sources/data-source/${dataSourceId}/resources`);
    return createFromArray(DataSourceResource, res.body);
  }

  public async updateResource(dataSourceId: string, key: string, value: any) : Promise<void> {
    await this.s.contacts.put<void>(`data-sources/data-source/${dataSourceId}/resources/${key}`, value);
  }

  public async generateManualMappingSample(data: string) : Promise<IManualMapping> {
    const res = await this.s.contacts.post<IManualMapping>("data-sources/generate-manual-mapping-sample", data);
    return res.body;
  }

  public async getActivitySamples(dataSourceId: string, activityType: string) : Promise<ActivityData[]> {
    const res = await this.s.contacts.get<IListResults<ActivityData>>("activities/global", {
      f: `datasource:${dataSourceId} AND type:${activityType}`,
      limit: 5,
      sort: "-created",
    });
    return createFromArray(ActivityData, res.body.results);
  }

  public async previewTemplate(activities: ActivityPreview[], template: string) {
    const res = await this.s.contacts.post<ActivityPreview[]>('activities/preview-template', {
      ids: activities.map(a => a.activity.id),
      template: template
    });
    activities.forEach(a => {
      const data = res.body.find(aa => a.activity.id == aa.activity.id);
      if (data != null) {
        a.success = data.success;
        a.error = data.error;
        a.show_error = data.error != null;

        if (data.activity != null) {
          a.activity.description = data.activity.description;
        }
      }
    });
  }

  public generateExportUrl() {
    const token = this.s.hasImpersonationToken ? this.s.impersonationToken : this.s.token;
    return `${this.config.contactsApiUrl}data-sources/export-resources?apikey=${token}`;
  }

  public async importActivityTemplates(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    await this.s.contacts.post('data-sources/import-resources', formData);
  }
}
