import { fromClass } from "fw-model";

export type NotificationRecipientType = "Manager" | "OrganizationUser" | "Applicant";

export class NotificationProfile {
  Total: number;
  Unread: number;
}

export class NotificationMetaData {
  OrganizationId: string;
  SeasonId: string;

  DateCreatedUtc: string;
  DateUpdatedUtc: string;

  IsRead: boolean;
  IsStarred: boolean;
}

export class NotificationDeliveryStatus {
  Email: boolean;
}


export class Notification<T = object> {
  Id: string;
  ShortText: string;
  LongText: string;

  RecipientType: string;
  RecipientId: string;

  EventType: string;
  Event: T;

  @fromClass DeliveryStatus: NotificationDeliveryStatus;
  @fromClass MetaData: NotificationMetaData;
}
