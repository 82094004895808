export class AtsConfig {
  apiUrl = ""; //"https://api.slideroom-auto.net/api/";
  contactsApiUrl = ""; //"https://auto2-api.contacts.slideroom.io/api/v1/";
  marketingApiUrl = ""; //"https://emp-backend.ats-alt.slideroom.io/";
  marketingTrackApiUrl = ""; //"https://emp-backend.ats-alt.slideroom.io/";

  /*
    we usually sync up the contactsApiUrl with
    org context call, setting this to true will stop that
    useful if setting from localStorage
  */
  contactsApiUrlSticky = false;
  marketingApiUrlSticky = false;
  marketingTrackApiUrlSticky = false;
}
