import { fromClass, fromClassArray } from "fw-model";

import { EncryptedValuePair } from "./file-provider";

export class SAML2Settings {
  MetadataUrl: string = null;
  IsFederation: boolean = false;
  UniqueIdAttribute: string = null;
  EmailAttribute: string = null;
  GivenNameAttribute: string = null;
  SurnameAttribute: string = null;
  SignAuthnRequest: boolean = true;
  ForceAuthn: boolean = true;
}

export class OAuthTokenTransformerSettings {
  Type: OAuthTokenTransformerType;
  Configuration: { [key: string]: string };
}

export class OAuthSettings {
  ClientId: string = null;
  @fromClass ClientSecret: EncryptedValuePair;
  Scope: string = null;
  AuthorizationUrl: string = null;
  TokenUrl: string = null;
  TokenType = OAuthTokenType.Jwt;
  UniqueIdAttribute: string = null;
  EmailAttribute: string = null;
  GivenNameAttribute: string = null;
  SurnameAttribute: string = null;

  @fromClassArray(OAuthTokenTransformerSettings) TokenTransformers: OAuthTokenTransformerSettings[];
}

export enum OAuthTokenTransformerType {
  JSON = 0,
  JWT = 1,
  REST = 2,
  KVP = 3,
  REGEX = 4,
  DELAY = 5
}

export enum IdentityProviderAuthType {
  SAML2 = 0,
  OAuth = 1
}

export enum OAuthTokenType {
  Jwt = 0
}

export class IdentityProviderMetaData {
  OrganizationId: string;
  AuthType: IdentityProviderAuthType;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

export class IdentityProvider {
  Id: string;
  Name: string = null;
  EntityId: string = null;

  @fromClass SAML2Settings: SAML2Settings;
  @fromClass OAuthSettings: OAuthSettings;
  @fromClass MetaData: IdentityProviderMetaData;
}
