import { needs, prop, inject, ComponentEventBus } from "fw";
import { FeatureFlagService } from "service/feature-flag";
import { DateFilter } from "views/components/filters/date-filter";
import { DateRange, RangeStamp } from "views/components/date-range";
import { ShortTimingOptions, TimingOptions } from "models/filter-timing";
import { DateFilter as DateFilterModel } from "models/date-filter";

export enum ValueType {
  Text,
  Number,
  Date,
  Dropdown,
  DataFields,
  IsOnly,
  AnsweredOnly,
  Boolean
}

export type Range = {
  startDate: Date;
  endDate: Date;
}

@inject
@needs(DateFilter, DateRange)
export class ValueField {
  @prop(null) dataType!: ValueType;
  @prop("=") operator!: string;
  @prop(null) value!: string;
  @prop(null) options!: any[];
  @prop(null) editing!: string;
  @prop(null) filter!: DateFilterModel;
  @prop(() => ({})) validation;
  @prop("left") opens: "center" | "left" | "right" | "inline";

  constructor(
    private ceb: ComponentEventBus,
    private ffs: FeatureFlagService
  ) { }

  get ops() {
    switch (this.dataType) {
      case ValueType.AnsweredOnly:
        return [
          { text: "is answered", value: "~" },
          { text: "is not answered", value: "!~" },
        ];

      case ValueType.IsOnly:
        return [
          { text: "is", value: "=" },
          { text: "is not", value: "!=" },
        ];

      case ValueType.Date:
        return ShortTimingOptions

      case ValueType.Text:
        return [
          { text: "is exactly", value: "==" },
          { text: "is not exactly", value: "<>" },
          { text: "is answered", value: "~" },
          { text: "is not answered", value: "!~" },
          { text: "contains", value: "=" },
          { text: "does not contain", value: "!=" }
        ];

      case ValueType.Dropdown:
        let options = [                    
          { text: "is answered", value: "~" },
          { text: "is not answered", value: "!~" }
        ];
        
        if (this.useDropdownFilterAsKeyword){
          options.unshift({ text: "is", value: "==" });        
          options.unshift({ text: "is not", value: "!==" });
        }
        else {
          options.unshift({ text: "is", value: "=" });
          options.unshift({ text: "is not", value: "!=" });
        }
        return options;
      case ValueType.Boolean:
        return [                               
          { text: "is", value: "=" },
          { text: "is not", value: "!=" },
          { text: "is answered", value: "~" },
          { text: "is not answered", value: "!~" }          
        ];          
      case ValueType.Number:
        return [
          { text: "is", value: "=" },
          { text: "is greater than", value: ">" },
          { text: "is greater than or equal to", value: ">=" },
          { text: "is less than", value: "<" },
          { text: "is less than or equal to", value: "<=" },
          { text: "is answered", value: "~" },
          { text: "is not answered", value: "!~" },
        ];
    }

    return [];
  }

  get showValue() {
    switch (this.operator) {
      case "~":
      case "!~":
        return false;
    }

    if (this.admissionDateFormat) return false;

    return true;
  }

  get isText() {
    return this.dataType == ValueType.Text;
  }

  get isNumber() {
    return this.dataType == ValueType.Number;
  }

  get isDropdown() {
    return this.dataType == ValueType.Dropdown || this.dataType == ValueType.Boolean || (this.dataType == ValueType.IsOnly && this.options?.length);
  }

  // special case thing...
  get isLastXDays() {
    if (this.dataType !== ValueType.Date) return;
    return this.operator === "lastX" || this.operator === "lastXDays" ;
  }

  get showValueTextField() {
    if (this.isLastXDays) {
      return true;
    }

    return this.isNumber || this.isText;
  }

  get valueTextFieldLabel() {
    return this.isLastXDays ? "Days" : "Value";
  }

  get pValue() {
    return this.value;
  }

  set pValue(v) {
    this.ceb.dispatch("update:value", v);
  }

  get pOperator() {
    return this.operator;
  }

  set pOperator(v) {
    this.ceb.dispatch("update:operator", v);
  }

  get operationLabel() {
    switch (this.dataType) {
      case ValueType.Date:
        return "When";
    }

    return "Operation";
  }

  public dateRangeCallback(date: RangeStamp): void {
    this.filter.timing = date.timing;
    this.filter.since = date.timing;
    this.filter.marker = date.marker;

    if (date.timing === 'date') {
      this.filter.startDate = date?.range?.startDate;
      this.filter.endDate = date?.range?.endDate;
    }

    this.ceb.dispatch("update:filter", this.filter);
    this.ceb.dispatch("filter-changed", this.filter);
  }

  get admissionDateFormat() {
    return this.isDate;
  }

  get useDropdownFilterAsKeyword() {
    return this.ffs.isFeatureFlagEnabled("DropdownFilterAsKeyword8366");
  }

  get isDate() {
    return this.dataType == ValueType.Date;
  }

}
