import { prop, inject } from "fw";
import { dispatch } from "fw-state";
import { EnsureUsersAction, UsersStore } from "state/users";
import { User } from "models/user";

@inject
export class LazyUser {
  @prop(null) id!: string;
  @prop("div") rootType!: string;

  private isLoading = false;

  constructor(
    private userStore: UsersStore,
  ) {}

  public get loading(): boolean {
    if (this.user != null) {
      return false;
    }

    return this.isLoading;
  }

  public get user(): User {
    if (this.id == null) {
      return null;
    }

    const { userHash } = this.userStore.state;
    if (!userHash[this.id]) {
      this.isLoading = true;
      dispatch(new EnsureUsersAction([ this.id ]));
      return null;
    }

    return userHash[this.id];
  }
}
