import { inject } from "fw";
import { Store, handle } from "fw-state";

import { resetAllOnce } from "helpers/once";
import { OrganizationRepository } from "network/organization-repository";
import { LogoutAction, StartAction } from "./actions";
import { AddOrganizationAction } from "forms/organization";
import { MemoryCache } from 'caching';
import { CurrentUserStore } from "./current-user";

interface ApplicationStoreShape {
  hideChrome: boolean;
  globalPrincipalMode: boolean;
}

export class HideChrome {
  constructor(public value = false) { }
}

@inject
export class ApplicationStore extends Store<ApplicationStoreShape> {
  constructor(private orgRepo: OrganizationRepository, private memoryCache: MemoryCache, private currentUserstore: CurrentUserStore) {
    super();
  }

  defaultState() {
    return {
      hideChrome: false,

      // this is a special mode for which a global principal is logged in, but hasn't selected an organization yet (and will be forced to)
      // now that global users come from manager, this really only applies for local development when a global admin logs in directly
      globalPrincipalMode: false
    };
  }

  @handle(HideChrome)
  private async handleHideChrome(h: HideChrome) {
    this.setState(state => ({
      ...state,
      hideChrome: h.value,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    // reset the lazy load tracking for all states
    resetAllOnce();

    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    this.memoryCache.clearAll();
    // if we get a user that is global admin and org is null, we are in a global admin mode
    const { Organization } = s.context;
    const globalPrincipalMode = this.currentUserstore.state.isGlobalPrincipal && Organization.Id == null;

    this.setState(state => ({
      ...state,
      globalPrincipalMode: globalPrincipalMode
    }));
  }

  // maybe move to a global admin type of store??
  @handle(AddOrganizationAction)
  private async handleAddOrganizationAction(action: AddOrganizationAction) {
    action.form.validate();

    const org = await this.orgRepo.post({
      Domain: action.form.Domain,
      Name: action.form.Name,
      Timezone: "America/Chicago",
    });

    action.addedOrganization = org;
  }
}
