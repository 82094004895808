export class ActivityData {
  id: string;
  organization_id: string;
  contact_id: string;
  contact_display_name: string;
  profile_image_url: string;
  business_value: number;
  monetary_value: number;
  data_source_id: string;
  data_source_instance_id: string;
  type: string;
  data_source_icon_url: string;
  data_source_name: string;
  data_source_key: string;
  description: string;
  created_utc: Date;
  data: { [key: string]: any; };
  display_name: string;
  user_id: string;
  user_full_name: string;
  user_profile_image_url: string;
  show_details: boolean;
}

export interface ActivityTemplate {
  dataSourceName: string
  dataSourceId: string;
  activityName: string;
  businessValue: number;
  template: string;
}

export class ActivityPreview {
  public activity: ActivityData;
  public success: boolean;
  public error: string;
  public show_detail: boolean;
  public show_error: boolean;
}
