import { formFor, FormForType, Validators } from "fw-model";
const { wrap, isUrl } = Validators;

import {
  ApplicantPortal,
  ApplicantPortalContent,
} from "models/applicant-portal";

export class CreateApplicantPortalAction {
  newPortal: ApplicantPortal;
  constructor(public form: ApplicantPortalForm) { }
}

export class UpdateApplicantPortalAction {
  constructor(public form: ApplicantPortalForm) { }
}

export type ApplicantPortalForm = FormForType<ApplicantPortal>;
export type ApplicantPortalContentForm = FormForType<ApplicantPortalContent>;

export const ApplicantPortalContentFormCreator = formFor(
  ApplicantPortalContent,
  s => {
    s.field(c => c.HeaderHtml, "Header HTML");
    s.field(c => c.FooterHtml, "Footer HTML");
    s.field(c => c.Css, "Header/Footer CSS");
    s.field(c => c.GlobalCss, "Global CSS Override");
    s.field(c => c.FavIconUrl, "Favicon Url");
    s.field(c => c.SupportEmailAddress, "Support Email Address");
  },
);

export const ApplicantPortalFormCreator = formFor(ApplicantPortal, s => {
  s.requiredField(p => p.Name, "Name");
  s.requiredField(p => p.Domain, "Domain", wrap(isUrl({ allowPort: process.env.NODE_ENV == "development" })));
  s.form(p => p.Content, "Content", ApplicantPortalContentFormCreator);
});
