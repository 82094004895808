import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { PortalInvitation } from "models/portal-invitation";
import { EmailTemplatePreview } from "models/email-template";
import { parseHeader, TASK_HEADER_NAME } from "./middleware/task-request-middleware";

@inject
export class PortalInvitationRepository {
  constructor(private s: ATS) { }

  async getOrganizationCount() {
    const res = await this.s.get<number>("portal-invitation/count");
    return res.body;
  }

  async list(f = null, s = null, sort = null, page = 1, pageSize = 10) {
    const res = await this.s.get<PortalInvitation[]>("portal-invitation", { page, pageSize, f, s, sort });
    return { list: createFromArray(PortalInvitation, res.body), total: parseInt(res.headers["x-ats-total"], 10) };
  }

  public async getById(id: string) {
    const res = await this.s.get(`portal-invitation/${id}`);
    return createFrom(PortalInvitation, res.body);
  }

  async preview(portalInvitationId: string) {
    const res = await this.s.get(`portal-invitation/${portalInvitationId}/preview`);
    return createFrom(EmailTemplatePreview, res.body);
  }

  public async deleteByIds(portalInvitationIds: string[]) {
    await this.s.post("portal-invitation/delete", portalInvitationIds);
  }

  public async deleteAll(filter: string, search: string) {
    await this.s.delete("portal-invitation", { f: filter, s: search });
  }

  public async send(portalInvitationIds: string[]) {
    await this.s.post("portal-invitation/send", portalInvitationIds);
  }

  public async sendAll(filter: string, search: string) {
    const res = await this.s.post("portal-invitation/send-all", null, { f: filter, s: search });
    return parseHeader(res.headers[TASK_HEADER_NAME]);
  }
}
