import { formFor, FormForType, Validators } from "fw-model";
const { required, wrap } = Validators;
import { UserTask } from "models/user-task";

import { isValidDate } from "./validators";

export type UserTaskForm = FormForType<UserTask>;

export const userTaskFormCreator = formFor(UserTask, s => {
  s.requiredField(s => s.AssignedToUserId, "Assigned User");
  s.requiredField(s => s.Description, "Description");
  s.field(s => s.TargetType, "Target Type");
  s.field(s => s.TargetId, "Target", v => v.if(c => c.TargetType != null, required));
  s.field(s => s.DateDueUtc, "Due Date", wrap(isValidDate));
  s.field(s => s.IsCompleted, "Completed");
  s.field(s => s.Tags, "Tags");
});
