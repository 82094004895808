import { inject } from "fw";
import { Store, handle } from "fw-state";
import { difference } from "lodash-es";

import { once } from "helpers/once";
import { LogoutAction, StartAction } from "./actions";
import { FormErrorHandling } from "./error-handling";
import { RoutingTable } from "models/routing-table";
import { AddRoutingTableAction, UpdateRoutingTableAction } from "forms/routing-table";
import { RoutingTableRepository } from "network/routing-table-repository";

interface RoutingTablesShape {
  tables: RoutingTable[];
}

export class EnsureRoutingTablesStoreAction {
  constructor() { }
}

export class DeleteRoutingTablesAction {
  constructor(public tableIds: string[]) { }
}

@inject
export class RoutingTablesStore extends Store<RoutingTablesShape> {
  constructor(private tableRepo: RoutingTableRepository) {
    super();
  }

  defaultState() {
    return {
      tables: [],
    };
  }

  @handle(StartAction)
  @handle(LogoutAction)
  private handleStartAndLogoutActions() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureRoutingTablesStoreAction)
  private async handleEnsureRoutingTablesStoreAction(s: EnsureRoutingTablesStoreAction) {
    await once("ensure-routing-tables", async () => {
      const routingTables = await this.tableRepo.list();

      this.setState(state => ({
        ...state,
        tables: routingTables,
      }));
    });
  }

  @handle(AddRoutingTableAction, FormErrorHandling)
  private async handleAddRoutingTableAction(action: AddRoutingTableAction) {
    action.form.validate();

    const newTable = await this.tableRepo.post(action.form.updatedModel(), action.type);

    this.setState(state => ({
      ...state,
      tables: [...state.tables, newTable],
    }));
  }

  @handle(UpdateRoutingTableAction, FormErrorHandling)
  private async handleUpdateRoutingTableAction(action: UpdateRoutingTableAction) {
    action.form.validate();

    const updated = await this.tableRepo.put(action.form.updatedModel());

    const existingTable = this.state.tables.find(t => t.Id == updated.Id);
    if (existingTable == null) return;

    Object.assign(existingTable, updated);

    this.setState(s => s);
  }

  @handle(DeleteRoutingTablesAction)
  private async handleDeleteRoutingTablesAction(action: DeleteRoutingTablesAction) {
    const tables = this.state.tables.filter(t =>
      action.tableIds.some(id => id == t.Id),
    );

    await this.tableRepo.del(tables.map(t => t.Id));
    this.setState(state => ({
      ...state,
      tables: difference(state.tables, tables),
    }));
  }
}
