import { inject, prop, needs } from "fw";

import { ValueType, ValueField } from "views/components/application-filters/value-field";
import { getFriendlyTiming } from "models/date-filter";
import { PaymentStatusFilter as PaymentStatusFilterModel } from "models/application-filters";
import { hashOn } from "hashing";
import { IDropdownFieldOptions } from "../dropdown-field";
import { dispatch } from "fw-state";
import { EnsureUsersAction, UsersStore } from "state/users";
import { PopoverService } from "service/popover";
import { SelectUserPopover } from "../select-user-popover";
import { TimingFilterType } from "models/filter-timing";
import { PaymentStatus } from "models/application";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";

export enum PaymentFields {
  PaymentStatus = "paymentStatus",
  PaymentDate = "paymentDate",
  WaivedOn = "waivedOn",
  AmountPaid = "amountPaid",
  WaivedBy = "waivedByUserId",
  FeeWaived = "feeWaived"
}

@inject
@needs(ValueField)
export class PaymentStatusFilter {
  @prop(null) filter!: PaymentStatusFilterModel;
  @prop(false) editing!: boolean;
  @prop(() => ({})) validation;

  public paymentFields = PaymentFields;

  public valueTypes = ValueType;

  public statuses = [ { text:"Paid", value: PaymentStatus.Paid },
    { text:"Waived By User", value: PaymentStatus.WaivedByUser },
    { text:"Waived By Rule", value: PaymentStatus.WaivedByRule },
    { text:"Failed", value: PaymentStatus.Failed },
  ];

  public fields = [
    { text:"Payment Status", value: PaymentFields.PaymentStatus },
    { text:"Payment Date", value: PaymentFields.PaymentDate },
    { text:"Payment Waive Date", value: PaymentFields.WaivedOn },
    { text:"Invoiced Amount", value: PaymentFields.AmountPaid },
    { text:"Payment Waived By", value: PaymentFields.WaivedBy }
  ];
  public fieldsHash = hashOn(this.fields, y => y.value, f => f);

  public stepGroupKeys: IDropdownFieldOptions[] = [];
  public stepGroupKeysHash: {} = {};

  constructor(
    private popover: PopoverService,
    private dataDictionaryStore: DataDictionaryStore,
    private usersStore: UsersStore
  ) {}

  public async attached() {
    await dispatch(new EnsureDataDictionaryFieldsAction(false));
    if(this.filter.field === PaymentFields.WaivedBy && this.filter.value != null) {
      await dispatch(new EnsureUsersAction([ this.filter.value as string ]));
    }

    this.stepGroupKeys = this.dataDictionaryStore.state.fields
      .filter(f => f.Category === "Program" && f.JsonDataType === "object" && f.Path.startsWith("stepGroups."))
      .map(f => ({ text: f.Path.replace("stepGroups.", ""), value: f.Path }));

    this.stepGroupKeysHash = hashOn(this.stepGroupKeys, y => y.value, f => f);
  }

  public onFieldChange() {
    if(this.valueType === ValueType.Date) {
      this.filter.timing = this.filter.timing ?? TimingFilterType.Today;
      return;
    }

    this.filter.timing = null;
    this.filter.value = null;
    this.filter.operator = "=";

    if(this.filter.field === PaymentFields.PaymentStatus) {
      this.filter.value = PaymentStatus.Paid;
    }
  }

  public get options() {
    switch(this.filter.field) {
      case PaymentFields.PaymentStatus:
        return this.statuses;
      default:
        return [];
    }
  }

  public get selectedUser() {
    if(this.filter.field === PaymentFields.WaivedBy && this.filter.value == null)
      return "Choose User"

    const u = this.usersStore.state.userHash[this.filter.value];
    if (u == null) return "Loading...";

    return `${u.FirstName} ${u.LastName}`;
  }

  public async selectUser() {
    const res = await this.popover.open<string>(SelectUserPopover);
    if (res.canceled) return;

    this.filter.value = res.result;
    dispatch(new EnsureUsersAction([ res.result ]));
  }

  public get friendlyExpression() {
    const fieldName = this.fieldsHash[this.filter.field]?.text;
    const operator = this.operators.find(o => o.value === this.filter.operator);
    switch(this.filter.field) {
      case PaymentFields.PaymentStatus:
        const statusName = this.statuses.find(s => s.value === this.filter.value)?.text;
        return `${fieldName} ${operator.text} ${statusName}`;
      case PaymentFields.WaivedBy:
        return `${fieldName} ${this.selectedUser}`;
      case PaymentFields.PaymentDate:
      case PaymentFields.WaivedOn:
        return `${fieldName} ${getFriendlyTiming(this.filter)}`;
      default:
        return `${fieldName} ${operator.text} ${ (operator.value === '~' || operator.value === '!~') ? "" : this.filter.value}`;
    }
  }

  public get valueType()  {
    switch(this.filter.field) {
      case PaymentFields.PaymentStatus:
        return ValueType.IsOnly;
      case PaymentFields.PaymentDate:
      case PaymentFields.WaivedOn:
        return ValueType.Date;
      case PaymentFields.AmountPaid:
        return ValueType.Number;
      case PaymentFields.WaivedBy:
        return null;
      default:
        return ValueType.Text;
    }
  }

  private get operators() {
    switch (this.valueType) {
      case ValueType.IsOnly:
        return [
          { text: "is", value: "=" },
          { text: "is not", value: "!=" },
        ];

      case ValueType.Text:
        return [
          { text: "is exactly", value: "==" },
          { text: "is not exactly", value: "<>" },
          { text: "is answered", value: "~" },
          { text: "is not answered", value: "!~" },
          { text: "contains", value: "=" },
          { text: "does not contain", value: "!=" }
        ];

      case ValueType.Number:
        return [
          { text: "is", value: "=" },
          { text: "is greater than", value: ">" },
          { text: "is greater than or equal to", value: ">=" },
          { text: "is less than", value: "<" },
          { text: "is less than or equal to", value: "<=" },
          { text: "is answered", value: "~" },
          { text: "is not answered", value: "!~" },
        ];
      default: return [];
    }
  }
}
