import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { ChartingService, ChartData, stackedChart } from "slideroom-charts";
import { allNumbers, fillColorPalette } from "../../util";

type Stack = { label: string, color: string };

@inject
export class StackBarChart {
  @prop(null) data!: ChartData[];
  @prop(false) interactive!: boolean;
  @prop("Stack Bar chart") svgAlt: string;

  private svgElement: SVGElement = null;

  private height = 350;
  private stacks: Stack[] = [];

  constructor(
    private ceb: ComponentEventBus,
    private charting: ChartingService,
  ) { }

  attached() {
    this.charting.setColors(fillColorPalette);
    this.dataChanged();
  }

  dataChanged() {
    this.buildChart();
  }

  color(i: number) {
    return this.charting.color(i);
  }

  buildChart() {
    if (this.svgElement == null) return;

    this.charting.drawChart(stackedChart, this.svgElement, this.data, this.interactive, (data) => {
      if (!this.interactive) return;
      this.ceb.dispatch("data-click", data);
    });

    if (this.data == null) return;

    const labels = this.data.map(d => d.Label);

    const keys: Stack[] = [];
    let onIdx = 0;

    for (let i = 0; i < labels.length; i++) {
      const row = labels[i];

      const rowData = this.data.find(d => d.Label == row);

      if (rowData.Data == null) continue;

      for (let j = 0; j < rowData.Data.length; j++) {
        const colData  = rowData.Data[j];

        const extCol = keys.find(c => c.label == colData.Label);
        if (extCol == null) {
          keys.push({ label: colData.Label, color: (colData.Color ? (colData.Color.startsWith('#') ? colData.Color : '#' + colData.Color) : this.color(onIdx)) });
          onIdx += 1;
        }
      }
    }

    if (keys.length > 1) {
      if (allNumbers(keys.map(k => k.label))) {
        keys.sort((a, b) => parseInt(a.label, 10) - parseInt(b.label, 10));
      }
      this.stacks = keys;
    }
  }

  isMissing(s: Stack) {
    return s.label == "__missing__" || s.label == "NaN";
  }
}
