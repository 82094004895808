import { formFor, FormForType, Validators } from "fw-model";
import {
  CustomPortalContent,
  CustomPortalSocial,
  CustomPortal,
} from "models/custom-portal";

const { wrap, isUrl, required } = Validators;

export class CreateCustomPortalAction {
  newPortal: CustomPortal;
  constructor(public form: CustomPortalForm) {}
}

export class UpdateCustomPortalAction {
  constructor(public form: CustomPortalForm) {}
}

export type CustomPortalForm = FormForType<CustomPortal>;
export type CustomPortalContentForm = FormForType<CustomPortalContent>;
export type CustomPortalSocialForm = FormForType<CustomPortalSocial>;

export const updateValue = {};

export const CustomPortalContentFormCreator = formFor(
  CustomPortalContent,
  (s) => {
    s.field((c) => c.FavIconFileId, "Fav Icon File Id");
    s.field((c) => c.FavIconUrl, "Fav Icon Url");
    s.requiredField((c) => c.OrganizationDisplayName, "Organization Display Name");
    s.field((c) => c.OrganizationWebsiteUrl, "Organization Website Url");
    s.field((c) => c.SupportEmailAddress, "Support Email Address");
    s.field((c) => c.SupportPhoneNumber, "Support Phone Number");
    s.field((c) => c.FullLogoFileId, "Full Logo File Id");
    s.field((c) => c.FullLogoUrl, "Full Logo Url");
    s.field((c) => c.CompactLogoFileId, "Compact Logo File Id");
    s.field((c) => c.CompactLogoUrl, "Compact Logo Url");
    s.requiredField((c) => c.PrimaryColor, "Primary Color");
    s.requiredField((c) => c.SecondaryColor, "Secondary Color");
    s.requiredField((c) => c.TertiaryColor, "Tertiary Color");
    s.requiredField((c) => c.NavBarColor, "Navigation Bar Color");
    s.field((c) => c.TermsAndConditionsMessage, "Terms And Conditions Message");
    s.field(
      (c) => c.DashboardWelcomeBGImageFileId,
      "Dashboard Welcome Background Image File Id"
    );
    s.field(
      (c) => c.DashboardWelcomeBGImageUrl,
      "Dashboard Welcome Background Image Url"
    );
    s.field(
      (c) => c.DashboardWelcomeMessage,
      "Dashboard Welcome Message (in HTML)"
    );
    s.field(
      (c) => c.WelcomeMessageBGImageFileId,
      "Login Welcome Background Image File Id"
    );
    s.field(
      (c) => c.WelcomeMessageBGImageUrl,
      "Login Welcome Background Image Url"
    );
    s.field((c) => c.WelcomeMessage, "Login Welcome Message (in HTML)");
    s.field((c) => c.TrackingScript, "Tracking Script");
    s.field((c) => c.FullLogoLightFileId, "Full Logo File Id");
    s.field((c) => c.FullLogoLightUrl, "Full Logo Url");
    s.field((c) => c.CompactLogoLightFileId, "Compact Logo File Id");
    s.field((c) => c.CompactLogoLightUrl, "Compact Logo Url");
  }
);

export const CustomPortalSocialFormCreator = formFor(
  CustomPortalSocial,
  (s) => {
    s.field((c) => c.FaceBookPageUrl, "FaceBook Page Url");
    s.field((c) => c.LinkedInPageUrl, "LinkedIn Page Url");
    s.field((c) => c.TwitterPageUrl, "Twitter Page Url");
    s.field((c) => c.YouTubePageUrl, "YouTube Page Url");
    s.field((c) => c.InstagramPageUrl, "Instagram Page Url");
    s.field((c) => c.TikTokPageUrl, "TikTok Page Url");
    s.field((c) => c.SnapchatPageUrl, "Snapchat Page Url");
    s.field((c) => c.FlickrPageUrl, "Flickr Page Url");
  }
);

export const CustomPortalFormCreator = formFor(CustomPortal, (s) => {
  s.requiredField((p) => p.Name, "Name");
  s.requiredField((p) => p.BrowserTitle, "Browser Title");
  s.requiredField(
    (p) => p.Domain,
    "Domain",
    wrap(isUrl({ allowPort: process.env.NODE_ENV == "development" }))
  );
  s.requiredField((p) => p.PrimaryContactType, "Primary Contact Type");
  s.form((p) => p.Content, "Content", CustomPortalContentFormCreator);
  s.form((p) => p.Social, "Social", CustomPortalSocialFormCreator);
});
