import { ICustomFieldDefinition, CustomFieldType } from "models/contact-organization";
import { getSectionMetas } from "models/testscore";

export class ContactSegment {
  id: string = null;
  organization_id: string = null;
  contact_type: string = null;
  display_name: string = null;
  key: string = null;
  filter: string = null;
  client_data: string = null;
  category: string = null;
  created_utc: Date = null;
  updated_utc: Date = null;
}

export const getFilterUpdates = (clientData: any, fields: ICustomFieldDefinition[]) => {
  if (!clientData)
    return { operation: null, filters: [] };

  const { operation, filters = [] } = clientData;

  const updatedFilters = filters.map(filter => {
    if (filter.type === "group-filter") {
      return {
        type: filter.type,
        data: getFilterUpdates(filter.data, fields)
      }
    }
    const { data } = filter;
    let { fieldId } = data;

    // code for backward compatibility
    if (!fieldId && data.field) {
      fieldId = data.field.id;
      const { options = [] } = data.field;
      data.optionId = options.length && data.value && options.find(o => o.name === data.value)?.id;
    }

    if (!fieldId)
      return filter;

    const field = fields.find(f => f.id === fieldId);
    if (!field)
      return filter;

    const { options = [], type } = field;
    data.field = field;
    data.value = data.optionId ? options.find(o => o.id === data.optionId)?.name : data.value;

    switch (type) {
      case CustomFieldType.dropdown:
      case CustomFieldType.funnel:
        data.options = options.map(o => { return { text: o.name, value: o.name }; });
        break;
      case CustomFieldType.boolean:
        data.options = [{ text: "Yes", value: true }, { text: "No", value: false }];
        break;
      case CustomFieldType.multiselect:
      case CustomFieldType.tags:
        data.options = options.map(o => o.name);
        data.values = data.optionIds?.length ? options.filter(o => data.optionIds.indexOf(o.id) !== -1)?.map(f => f.name) : data.values;
        break;
      case CustomFieldType.testscore:
        data.sections = getSectionMetas(field);
        break;
    }
    return filter;
  });

  return { operation, filters: updatedFilters };
}
