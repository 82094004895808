export enum ConversationType {
  Text = 0,
  Email = 1,
}

export enum ConversationAssignmentType {
  Workflow = 'workflow',
  User = 'user',
}

export enum ConversationFilterType {
  Text = 'phone-number',
  Email = 'email',
  WhatsApp = 'whats-app',
}

export enum ConversationTypeLabel {
  'phone-number' = 'SMS',
  'email' = 'Email',
  'whats-app' = 'WhatsApp',
}

export enum ConversationTypeIcon {
  'phone-number' = 'chat',
  'email' = 'email',
  'whats-app' = 'phone',
}

export enum ConversationListType {
  Me = 'My Conversations',
  All = 'All',
  Unassigned = 'Unassigned Conversations'
}

export enum ConversationStatus {
  Open = 'open',
  Responded = 'responded',
  Resolved = 'resolved',
}

export enum ConversationStatusChangeAction {
  Open = 'Reopen',
  Responded = 'Respond',
  Resolved = 'Resolve'
}

export enum MessageType {
  Assignment = 'assignment',
  Message = 'message',
  OptInOut = 'opt-in-out',
  Status = 'status',
}

export enum MessageDelivery {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export enum OptInOutType {
  OptIn = 'opt-in',
  OptOut = 'opt-out',
}

export class ConversationTypeCounts {
  OpenTotal: number = 0;
  RespondedTotal: number = 0;
  ResolvedTotal: number = 0;
}

export class ConversationSummaryCounts {
  Text: string = null;
  Count: number = 0;
  Filter: string = null;
}

export class Conversation {
  AssignedToUserId: string = null;
  AtsOrgId: string = null;
  ContactId: string = null;
  ContactHasOptedOut: boolean = false;
  CreatedAt: string;
  DeliveryAddress: string = null;
  Id: string = null;
  LastMessageSent: string = null;
  LastMessageSentId: string = null;
  LastMessageSentAt: string = null;
  LastReplyId: string = null;
  LastReplyReceived: string = null;
  LastReplyReceivedAt: string = null;
  Status: ConversationStatus = ConversationStatus.Open;
  Type: ConversationFilterType = ConversationFilterType.Text;
  UpdatedAt: string;

  constructor(obj = {}) {
    Object.assign(this, obj);
  }
}

export class ConversationFilters {
  Type: ConversationFilterType = null;
  Search: string = null;
  Sort: string = null;
  ReceivedDateFrom: string = null;
  ReceivedDateTo: string = null;
  AssignedToUserId: string = null;
  Status: ConversationStatus[] = [];
}

export class ConversationNotificationCounts {
  OpenConversations: number = 0;
  UnassignedConversations: number = 0;
}

export class IncomingMessageModel {
  Id: string = null;
  ConversationId: string = null;
  MediaType: string = null;
  DeliveryAddress: string = null;
  HtmlFile: string = null;
  TextFile: string = null;
  Text: string = null;
  CreatedAt: string;
  UpdatedAt: string;
}

export class OutgoingMessageModel {
  Id: string = null;
  AtsOrgId: string = null;
  AtsUserId: string = null;
  HtmlFile: string = null;
  TextFile: string = null;
  DeliveryAddress: string = null;
  OutreachId: string = null;
  Subject: string = null;
  CreatedAt: string;
  UpdatedAt: string;
  Text: string = null;
  BulkMessage?: boolean = false;                                    // @todo: remove optional `?`
  DirectMessage?: boolean = false;                                  // ...when the following props are included on fetch
  ConversationMessage?: boolean = true;
  EventSeriesId?: string = '';
  OutreachName?: string = '';
  CampaignName?: string = '';
}

export class DeliveredMessageModel {
  Id: string = null;
  ConversationId: string = null;
  Delivery: MessageDelivery = null;
  MessageId: string = null
  SenderUserId: string = null;
  SearchText: string = null;
  CreatedAt: string;
  UpdatedAt: string;
  message: IncomingMessageModel | OutgoingMessageModel = null;
}

export class StatusMessageModel {
  Id: string = null;
  ConversationId: string = null;
  Status: ConversationStatus = ConversationStatus.Open;
  UserId: string = null
  CreatedAt: string;
  UpdatedAt: string;
}

export class AssignmentMessageModel {
  Id: string = null;
  ConversationId: string = null;
  Type: ConversationAssignmentType = ConversationAssignmentType.Workflow;
  AssignerUserId: string = null
  AssigneeUserId: string = null;
  Comment: string = null;
  CreatedAt: string;
  UpdatedAt: string;
}

export class OptInOutMessageModel {
  Id: string = null;
  ConversationId: string = null;
  PhoneOptInId: string = null;
  Type: OptInOutType = null;
  CreatedAt: string;
  UpdatedAt: string;
}

export class Message {
  Id: string = null;
  ConversationId: string = null;
  Type: MessageType = null;
  TargetId: string = null;
  CreatedAt: string;
  UpdatedAt: string;
  target: DeliveredMessageModel | StatusMessageModel | AssignmentMessageModel | OptInOutMessageModel = null;

  constructor(obj = {}) {
    Object.assign(this, obj);
  }
}

export class MessageMeta {
  LastOutgoingMessageId: number = 0;
}

export class DeliveryAddress {
  conversationId: string = '';
  deliveryAddress: string = '';
  deliveryAddressType: ConversationFilterType = ConversationFilterType.Text;

  constructor(obj = {}) {
    Object.assign(this, obj);
  }
}
