import { FormForType, formFor } from "fw-model";

import { scheduleFormCreator } from "./scheduled-export";
import {
  AutomationRule,
  AutomatedAction,
  RuleTrigger,
  RoutedActionSet,
  SendApplicantEmailAction
} from "models/automation-rule";

export type AutomationRuleFormType = FormForType<AutomationRule>;
export type AutomatedActionFormType = FormForType<AutomatedAction>;
export type SendApplicantEmailFormType = FormForType<SendApplicantEmailAction>;

export class UpdateAutomationRuleAction {
  constructor(public form: AutomationRuleFormType) {}
}

export class AddAutomationRuleAction {
  constructor(public form: AutomationRuleFormType) {}
}

export class DeleteAutomationRulesAction {
  constructor(public ids: string[]) {}
}

export const sendApplicantEmailFormCreator = formFor(SendApplicantEmailAction, s => {
  s.requiredField(a => a.templateKey, "Email Template");
});

export const automatedActionFormCreator = formFor(AutomatedAction, s => {
  s.requiredField(a => a.Type, "Rule Type");
  s.field(u => u.Parameters, "Parameters");
});

export const ruleTriggerFormCreator = formFor(RuleTrigger, s => {
  s.form(u => u.Schedule, "Schedule", scheduleFormCreator);
});

export const actionSetFormCreator = formFor(RoutedActionSet, s => {
  s.field(u => u.Filter, "Filter");
  s.field(u => u.FilterClientData, "Filter Client Data");

  s.formArray(u => u.Actions, "Actions", automatedActionFormCreator);
});

export const automationRuleFormCreator = formFor(AutomationRule, s => {
  s.requiredField(u => u.Name, "Name");
  s.field(u => u.Filter, "Filter");
  s.field(u => u.FilterClientData, "Filter Client Data");
  s.field(u => u.IsEnabled, "Enabled");

  s.formArray(u => u.ActionSets, "Action Sets", actionSetFormCreator);
  s.form(u => u.Trigger, "Trigger", ruleTriggerFormCreator);
});
