import { formFor, FormForType, Validators } from "fw-model";
import { CollaborationPortal } from "models/collaboration-portal";
import { gridColumnFormCreator } from "./grid-column";

const { isUrl, wrap } = Validators;

export class CreateCollaborationPortalAction {
  newPortal: CollaborationPortal;
  constructor(public form: CollaborationPortalForm) {}
}

export class UpdateCollaborationPortalAction {
  constructor(public form: CollaborationPortalForm) {}
}

export class DeleteCollaborationPortalsAction {
  constructor(public ids: string[]) {}
}

export type CollaborationPortalForm = FormForType<CollaborationPortal>;

export const collaborationPortalFormCreator = (collabTeamsFeature: boolean) => formFor(
  CollaborationPortal,
  s => {
    s.requiredField(p => p.Name, "Name");
    s.requiredField(p => p.Domain, "Domain", wrap(isUrl()));

    s.field(p => p.AllowLocalLogin, "Allow Local Login");
    s.field(p => p.IdentityProviderIds, "Provider Ids");

    s.field(
      p => p.RoleIds,
      "Roles",
      wrap(input => {
        if (!collabTeamsFeature) {
          if (input == null || input.length == 0)
            return "Atleast one role is required";
        }

        return null;
      }),
    );

    s.field(
      p => p.TeamCategories,
      "Team Categories",
      wrap(input => {
        if (collabTeamsFeature) {
          if (input == null || input.length == 0)
            return "Atleast one team category is required";
        }

        return null;
      }),
    );

    s.formArray(p => p.GridColumns, "Grid Columns", gridColumnFormCreator);
  },
);
