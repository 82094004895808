import { inject, prop } from "fw";

import { CurrentUserStore } from "state/current-user";

@inject
export class GlobalUser {
  @prop("span") rootType!: string;

  constructor(private currentUserStore: CurrentUserStore) {}

  public get isGlobalPrincipal() {
    return this.currentUserStore.state.isGlobalPrincipal;
  }
}
