import { FormForType, formFor } from "fw-model";

import { ApiKey } from "models/api-key";

export type ApiKeyFormType = FormForType<ApiKey>;

export class UpdateApiKeyAction {
  constructor(public form: ApiKeyFormType) {}
}

export class UpdateApiKeyActiveAction {
  constructor(public ids: string[], public isActive: boolean) {}
}

export class AddApiKeyAction {
  public addedId: string = null;

  constructor(public form: ApiKeyFormType) {}
}

export class DeleteApiKeysAction {
  constructor(public apiKeyIds: string[]) {}
}

export const apiKeyFormCreator = formFor(ApiKey, s => {
  s.requiredField(u => u.IsActive, "Active");
  s.requiredField(u => u.RoleId, "Role");
});
