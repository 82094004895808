import { inject } from "fw";
import { createFrom, createFromArray, cloneOf } from "fw-model";

import { ATS } from "./ats";
import { encodeReferencePortal } from "./encode";

import { ReferencePortal } from "models/reference-portal";



@inject
export class ReferencePortalRepository {
  constructor(private s: ATS) {}

  public async get(id: string) {
    const res = await this.s.get(`reference-portal/${id}`);
    return createFrom(ReferencePortal, res.body);
  }

  public async list() {
    const res = await this.s.get<any[]>("reference-portal");
    return createFromArray(ReferencePortal, res.body);
  }

  public async delete(id: string) {
    await this.s.delete(`reference-portal/${id}`);
  }

  public async post(portal: ReferencePortal) {
    const res = await this.s.post("reference-portal", encodeReferencePortal(portal));
    return createFrom(ReferencePortal, res.body);
  }

  public async put(portal: ReferencePortal) {
    const res = await this.s.put<ReferencePortal>(
      `reference-portal/${portal.Id}`,
      encodeReferencePortal(portal),
    );
    return createFrom(ReferencePortal, res.body);
  }
}
