import { IAddressData } from "models/contact";
import { AddressInfo } from "models/user";

export function getFullAddress(
  addressInfo: AddressInfo,
  defaultPlaceholder: string = null
) {
  let address = "";
  for (const fieldName of [
    "Address1",
    "Address2",
    "City",
    "Region",
    "PostalCode",
    "Country",
  ]) {
    const field = addressInfo[fieldName];
    if (field != null && field.length) {
      address += field;
      if (fieldName == "City") {
        address += ", ";
      } else {
        address += " ";
      }
    }
  }

  // NOTE: Trim trailing whitespace and commas.
  address = address.trim().replace(/(^,)|(,$)/g, "");
  if (address.length) {
    return address;
  } else {
    return defaultPlaceholder;
  }
}

export function getFullContactAddress(value: IAddressData): string {
  if (!value) return null;

  let address: string = null;
  if (value.city) {
    address = value.city;
  }

  if (value.state) {
    address = address ? `${address}, ${value.state}` : value.state;
  }

  if (value.postal_code) {
    address = address ? `${address} ${value.postal_code}` : value.postal_code;
  }

  if (value.country) {
    address = address ? `${address} ${value.country}` : value.country;
  }

  return address;
}
