import { fromClass, fromClassArray } from "fw-model";
import { PathType } from "views/components/path-chooser";

export enum ExportDataTypeCode {
  Application = 0,
  Contact = 1,
  User = 2,
}

export enum ExportFormatTypeCode {
  JSON = 0,
  PDF = 1,
  Tabular = 2,
  Files = 3,
}

export enum JsonEvaluationModeTypeCode {
  All = 0,
  Transform = 1,
  Fields = 2,
}

export enum ExportFieldTypeCode {
  Standard = 0,
  Custom = 1,
  Reserved = 2,
  Pivot = 3,
}

export enum FieldFormatType {
  None = 0,
  DateTime = 1,
  Number = 2
}

export enum VisibilityStatus {
  Visible = 0,
  Hidden = 1
}

export class JsonExportField {
  Type: ExportFieldTypeCode = ExportFieldTypeCode.Standard;
  DataType: PathType = null;
  FormatType: FieldFormatType = FieldFormatType.None;
  Format: string = null;
  PropertyName: string = null;
  Path: string = null;
  Expression: string = null;
  ExportCodeId: string = null;
  TranslationTableId: string = null;
}

export class JsonExportFormatOptions {
  TransformFunction: string = null;
  BatchSize = 0;
  EvaluationMode = JsonEvaluationModeTypeCode.All;
  @fromClassArray(JsonExportField) Fields: JsonExportField[] = [];
}

export class PdfExportFormatOptions {
  CustomizeContent = false;

  CreateCoverPage = true;
  CreateSummaryPage = false;
  IncludeAttachments = true;
  IncludeComments = false;

  FormStepKeys = ["*"];
  PortfolioStepKeys = ["*"];
  CollaborationModuleKeys = ["*"];
  ReferenceStepKeys = ["*"];
  DocumentStepKeys = ["*"];
  EvaluationPhaseKeys = ["*"];

  IncludedFormPaths: string[] = ["*"];
  IncludedPortfolioPaths: string[] = ["*"];  
  IncludedReferencePaths: string[] = ["*"];
  IncludedDocumentPaths: string[] = ["*"];
  IncludedEvaluationPhasePaths: string[] = ["*"];
}

export class ExportDefinitionMetaData {
  OrganizationId: string;
  SeasonId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  OwnerUserId: string;
}

export class CsvPivotRow {
  Label: string = null;
  Path: string = null;
}

export class CsvPivotField {
  Type: ExportFieldTypeCode = ExportFieldTypeCode.Standard;
  DataType?: PathType = null;
  Label: string = null;
  Path: string = null;
  Expression: string = null;
  Width: number = 20;
  TranslationTableId: string = null;
  FormatType: FieldFormatType = FieldFormatType.None;
  Format: string = null;
}

export class CSVExportField {
  StartingColumnNumber?: number;
  Type: ExportFieldTypeCode = ExportFieldTypeCode.Standard;
  DataType: PathType = null;
  FormatType: FieldFormatType = FieldFormatType.None;
  Format: string = null;
  Label: string = null;
  Path: string = null;
  Expression: string = null;
  Width: number = 20;
  ExportCodeId: string = null;
  TranslationTableId: string = null;

  PivotRowCount: number = null;
  PivotRowSort?: string = null;
  PivotFilterExpression?: string = null;
  @fromClassArray(CsvPivotRow) PivotRows: CsvPivotRow[] = [];
  @fromClassArray(CsvPivotField) PivotFields: CsvPivotField[] = [];
}

export enum TabularStyleTypeCode {
  CommaDelimited = 0,
  TabDelimited = 1,
  FixedWidth = 2,
  CustomDelimited = 3,
}

export class TabularExportFormatOptions {
  @fromClassArray(CSVExportField) Fields: CSVExportField[] = [];

  FileRecordCount = 0;
  CustomDelimiter="";
  Style = TabularStyleTypeCode.CommaDelimited;
}

export class FileExportPathNamePair {
  Path: string = "";
  FileNameFormat: string = "{{filename}}";
}

export class FileExportIndexOptions {
  Style = TabularStyleTypeCode.CommaDelimited;
  CustomDelimiter="";
  @fromClassArray(CSVExportField) Fields: CSVExportField[] = [];
}

export class FileExportFormatOptions {
  @fromClassArray(FileExportPathNamePair)
  FilePaths: FileExportPathNamePair[] = [];

  @fromClass IndexOptions: FileExportIndexOptions;

  FileApplicationCount = 1000;
}

export class ExportDefinition {
  Id: string;
  Name: string = null;
  IsDeleted: boolean;
  IsShared = false;
  Visibility: VisibilityStatus = VisibilityStatus.Visible;

  DataType = ExportDataTypeCode.Application;
  ContactType: string = null;
  Format = ExportFormatTypeCode.Tabular;
  FileNameFormat: string = null; // = "{{ applicant.fullName }} - {{ application.publicId }} - {{ date }}";

  @fromClass JsonOptions: JsonExportFormatOptions;
  @fromClass TabularOptions: TabularExportFormatOptions;
  @fromClass PdfOptions: PdfExportFormatOptions;
  @fromClass FileOptions: FileExportFormatOptions;

  @fromClass MetaData: ExportDefinitionMetaData;
}
