import { inject } from "fw";
import { DialogController } from "fw-dialog";

@inject
export class CheckSave {
  saving: boolean = false;
  showSave: boolean = false;
  saveFn: () => Promise<void>;
  discarding: boolean = false;
  discardFn: () => Promise<void>;
  closeButtonEl: HTMLElement;

  constructor(private controller: DialogController<boolean>) { }

  attached() {
    this.closeButtonEl.focus();
  }

  activate(args: { discard: () => Promise<void>, save: () => Promise<void>, showSave: boolean }) {
    this.discardFn = args && args.discard;
    this.saveFn = args && args.save;
    this.showSave = args && args.showSave;
  }

  async save() {
    this.saving = true;

    if (this.saveFn) {
      try {
        await this.saveFn();
      } catch (err) {
        this.controller.cancel();
        return;
      }
    }

    this.saving = false;

    this.controller.ok(true);
  }

  async discard() {
    this.discarding = true;

    if (this.discardFn) {
      try {
        await this.discardFn();
      } catch (err) {
        this.controller.cancel();
        return;
      }
    }

    this.discarding = false;

    this.controller.ok(false);
  }

  cancel() {
    this.controller.cancel();
  }
}
