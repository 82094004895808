import { CustomFieldType } from "./contact-organization";
import {
  DataDictionaryField,
  DataDictionaryFieldType,
  DataDictionaryFieldDataSource,
  SystemFieldType,
  DataDictionaryIndexStatus,
  DataDictionaryAggregationType
} from "./data-dictionary";

export class ContactDataDictionaryField {
  aggregation_type: DataDictionaryAggregationType;
  label: string;
  label_transform?: string;
  path?: string;
  export_path?: string;
  parent_path?: string;
  contacts_field_id?: string;
  category: string;
  description: string;
  is_routable: boolean;
  index_status: DataDictionaryIndexStatus;
  data_source?: DataDictionaryFieldDataSource
  data_type?: SystemFieldType | CustomFieldType | number;
  json_data_type: DataDictionaryFieldType;
  sort: string;
  values?: string[];
  allows_write_in_value?: boolean;
  validation?: string[];
}

export function toDataDictionaryField(c: ContactDataDictionaryField): DataDictionaryField {
  if (!c) return null;

  return {
    AggregationType: c.aggregation_type,
    Label: c.label,
    LabelTransform: c.label_transform,
    Path: c.path,
    ExportPath: c.export_path,
    ParentPath: c.parent_path,
    Description: c.description,
    ContactsFieldId: c.contacts_field_id,
    Category: c.category,
    IsTableSection: false,
    IsRoutable: c.is_routable,
    IndexStatus: c.index_status,
    DataSource: c.data_source,
    DataType: c.data_type,
    JsonDataType: c.json_data_type,
    Sort: c.sort,
    TaskRequestId: null,
    Values: c.values,
    AllowsWriteInValue: c.allows_write_in_value,
    Validation: c.validation,
    ProgramsUsingCount: 0
  }
}

export function toDataDictionaryFields(contactFields: ContactDataDictionaryField[]): DataDictionaryField[] {
  return contactFields?.map(c => (toDataDictionaryField(c))) || [];
}
