import { prop, inject, ComponentEventBus } from "@derekpitt/fw"
import { ChartData, normalizeChartData } from "slideroom-charts";

@inject
export class CountChart {
  @prop(null) data!: ChartData[];
  @prop(false) interactive!: boolean;
  colors = ["#5571AD",
            "#b4bd00",
            "#4eaf9c",
            "#f18eab",
            "#98a6ad",
            "#e78747",
            "#55b8d4",
            "#c98ad2"];

  constructor(private ceb: ComponentEventBus) {}

  get counts() {
    if (this.data == null) return [];
    const allData = normalizeChartData(this.data).map(d => ({
      label: d.label == "__missing__" || d.label == "NaN" ? "No Data" : d.label,
      count: d.count,
      data: d,
      color: "#f00",
    }));

    const rows = [];

    let currentRow = [];

    for (let i = 0; i < allData.length; i++) {
      allData[i].color = allData[i].data?.color || this.colors[i % this.colors.length];
    }

    for (const data of allData) {
      if (currentRow.length >= 5) {
        rows.push(currentRow.slice());

        currentRow = [];
      }

      currentRow.push(data);
    }

    if (currentRow.length > 0)
      rows.push(currentRow.slice());

    return rows;
  }

  dataClick(data: { data: any }) {
    if (!this.interactive) return;

    this.ceb.dispatch("data-click", data.data);
  }
}
