import { inject } from "fw";
import { Store, handle } from "fw-state";
import { UtilityRepository } from "../network/utility-repository";
import { CurrentUserStore } from "./current-user";

interface UtilityShape {
  serverTime?: string;
}

export class RefreshServerTime {}

@inject
export class UtilityStore extends Store<UtilityShape> {
  constructor(private utilityRepo: UtilityRepository, private userStore: CurrentUserStore) {
    super();
  }

  defaultState() {
    return {
      serverTime: undefined
    };
  }

  @handle(RefreshServerTime)
  private async handleRefreshServerTimeAction(action: RefreshServerTime) {
    if (this.userStore.state.loggedIn) {
      try {
        const serverTime = await this.utilityRepo.getServerTime() as string
        this.setState(state => ({
          ...state,
          serverTime,
        }));
      } catch {}
    }
  }
}
