import { RouterConfig } from "fw";

import { List } from "./list";
import { EditGoal } from './edit-goal';

export class GoalsRoutes {
  registerRoutes(r: RouterConfig) {
    r.add("", List);
    r.add("new", EditGoal, { hideChrome: true } );
    r.add(":goalId", EditGoal, { hideChrome: true } );
  }
}

export default GoalsRoutes;
