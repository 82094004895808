import { Validators, formFor } from "fw-model";
import {
  AggregateCalculatedField,
  CalculatedFieldOperatorTypeCode,
  CalculatedField,
  CalculatedFieldDataTypeCode,
} from "models/calculated-field";
import { conditionInfoFormCreator } from "./condition-info";

const { wrap, required } = Validators;
import { isValidKey } from "./validators";

export const CalculatedFieldFormCreator = formFor(CalculatedField, s => {
  s.requiredField(f => f.Label, "Label");
  s.requiredField(f => f.Key, "Key", wrap(isValidKey));
  s.requiredField(f => f.Expression, "Expression");
  s.form(f => f.BooleanExpressionInfo, "Expression Builder", conditionInfoFormCreator);
  s.requiredField(f => f.DataType, "Data Type");
});

export const fieldTypes = [
  { value: CalculatedFieldDataTypeCode.Boolean, text: "Boolean" },
  { value: CalculatedFieldDataTypeCode.Number, text: "Number" },
  { value: CalculatedFieldDataTypeCode.String, text: "String" },
  { value: CalculatedFieldDataTypeCode.Date, text: "Date" },
];

export const operatorTypes = [
  { value: CalculatedFieldOperatorTypeCode.Sum, text: "Sum" },
  { value: CalculatedFieldOperatorTypeCode.Mean, text: "Average (Mean)" },
  { value: CalculatedFieldOperatorTypeCode.Median, text: "Median" },
];

export const AggregateCalculatedFieldFormCreator = formFor(
  AggregateCalculatedField,
  s => {
    s.requiredField(f => f.Label, "Label");
    s.requiredField(f => f.Key, "Key", wrap(isValidKey));
    //s.field(f => f.Expression, "Expression", required);
    s.requiredField(f => f.Operator, "Operator");
    s.requiredField(f => f.FieldPath, "Path");
    s.field(f => f.AnswerOptionId, "Answer Option", o => o.if(cf => cf.Operator == CalculatedFieldOperatorTypeCode.Count, required));
  },
);
