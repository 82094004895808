import { inject, Route, Navigator } from "fw";

import { CurrentUserStore } from "state/current-user";
import { clearStorages } from "helpers/clear-storages";

@inject
export class RequireAuth {
  constructor(private userStore: CurrentUserStore, private nav: Navigator) {}

  navigating(r: Route, fullRoute: string) {
    const requiresAuth = r.data && r.data.auth;

    const { loggedIn } = this.userStore.state;

    if (requiresAuth && !loggedIn) {
      clearStorages();
      this.nav.navigate("/login", { redirect: fullRoute });
      return false;
    }

    return true;
  }
}
