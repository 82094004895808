import { inject } from "fw";
import { handle, Store } from "fw-state";

import {
  RefreshDashboardDataAction,
  LogoutAction,
  StartAction,
  ApplyEvaluationPhasesAction,
} from "./actions";
import { ApplicationRepository } from "network/application-repository";
import { EvaluationPhase } from "models/evaluation-phase";
import { wait } from "wait";

export interface AssignmentCounts {
  teamId: string;
  userId: string;
  total: number;
  completed: number;
}

export enum TeamDashboardMode {
  Team,
  User,
}

interface TeamDashboard {
  loading: boolean;

  phaseKey: string;
  programId: string;
  segmentId: string;
  mode: TeamDashboardMode;

  assignments: AssignmentCounts[];
}

interface DashboardStoreShape {
  loadPhaseCountsOnNextStart: boolean;
  loadingPhaseCounts: boolean;
  phaseCounts: { [phaseId: string]: number };
  phaseKeyHash: { [phaseId: string]: string };

  teamDashboard: TeamDashboard;
}

export class EnsureDashboardDataAction {
  constructor(public onNextStart: boolean = false) {}
}

export enum TeamDashboardFilter {
  Program,
  Segment,
  Phase,
}

export class ChooseTeamDashboardFilterAction {
  constructor(public filterType: TeamDashboardFilter, public value: string) {}
}

export class ChooseTeamDashboardMode {
  constructor(public mode: TeamDashboardMode) {}
}

const getTeamAssignments = (aggs: any, phaseKey: string) => {
  const phaseAgg = aggs[`terms_phases.${phaseKey}.assigned_team_id`];
  if (phaseAgg == null || phaseAgg.Items.length == 0) return [];

  const teamAssignments: AssignmentCounts[] = [];

  phaseAgg.Items.forEach(teamAgg => {
    const teamAssignment: AssignmentCounts = {
      userId: null,
      teamId: teamAgg.Key,
      total: teamAgg.Total,
      completed: 0,
    };

    if (teamAgg.Key == "") return;

    if (teamAgg.Aggregations != null) {
      const teamInnerAgg =
        teamAgg.Aggregations[`terms_phases.${phaseKey}.evaluation_complete`];
      if (teamInnerAgg != null) {
        const completedItem = teamInnerAgg.Items.find(a => a.Key == 1);
        if (completedItem) teamAssignment.completed = completedItem.Total;
      }
    }

    teamAssignments.push(teamAssignment);
  });

  return teamAssignments;
};

const getUserAssignments = (aggs: any, phaseKey: string) => {
  const phaseAgg = aggs[`terms_phases.${phaseKey}.assigned_users`];
  if (phaseAgg == null || phaseAgg.Items.length == 0) return [];

  const userAssignments: AssignmentCounts[] = [];

  phaseAgg.Items.forEach(userAgg => {
    const userAssignment: AssignmentCounts = {
      teamId: null,
      userId: userAgg.Key,
      total: userAgg.Total,
      completed: 0,
    };

    if (userAgg.Key == "") return;

    if (userAgg.Aggregations != null) {
      const teamInnerAgg =
        userAgg.Aggregations[`terms_phases.${phaseKey}.evals_completed_by`];
      if (teamInnerAgg != null) {
        const completedItem = teamInnerAgg.Items.find(
          a => a.Key == userAgg.Key,
        );
        if (completedItem) userAssignment.completed += completedItem.Total;
      }
    }

    userAssignments.push(userAssignment);
  });

  return userAssignments;
};

@inject
export class DashboardStore extends Store<DashboardStoreShape> {
  constructor(private applicantRepo: ApplicationRepository) {
    super();
  }

  defaultState() {
    return {
      loadPhaseCountsOnNextStart: false,
      loadingPhaseCounts: false,
      phaseCounts: null,
      phaseKeyHash: {},
      teamDashboard: {
        mode: TeamDashboardMode.Team,
        loading: false,
        phaseKey: null,
        programId: null,
        segmentId: null,
        assignments: [],
      },
    };
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    const { EvaluationPhases } = action.context.Season;
    this.setState(state => ({
      ...state,
      phaseKeyHash: this.getPhaseHash(EvaluationPhases) || {},
    }));

    if (
      this.state.loadPhaseCountsOnNextStart == false &&
      this.state.phaseCounts == null
    )
      return;

    this.updateDashboardData();
  }

  @handle(EnsureDashboardDataAction)
  private async handleEnsureDashboardDataAction(
    action: EnsureDashboardDataAction,
  ) {
    if (action.onNextStart) {
      this.setState(state => ({
        ...state,
        loadPhaseCountsOnNextStart: true,
      }));

      return;
    }

    if (this.state.phaseCounts != null) return;

    await this.updateDashboardData();
  }

  @handle(RefreshDashboardDataAction)
  private async handleRefreshDashboardDataAction() {
    if (this.state.phaseCounts == null) return;

    await wait(2000);
    await this.updateDashboardData();
  }

  private async getAssignmentCounts(): Promise<AssignmentCounts[]> {
    const phaseKey = this.state.teamDashboard.phaseKey || "current";

    const filters: string[] = [];
    if (this.state.teamDashboard.programId != null)
      filters.push(`programId:${this.state.teamDashboard.programId}`);
    if (this.state.teamDashboard.segmentId != null)
      filters.push(`@include:${this.state.teamDashboard.segmentId}`);

    let agg = "";
    switch (this.state.teamDashboard.mode) {
      case TeamDashboardMode.Team:
        agg = `terms:(phases.${phaseKey}.assigned_team_id~100 terms:phases.${phaseKey}.evaluation_complete~100)`;
        break;

      case TeamDashboardMode.User:
        agg = `terms:(phases.${phaseKey}.assigned_users~100 terms:phases.${phaseKey}.evals_completed_by~100)`;
        break;
    }

    const res = await this.applicantRepo.search(
      filters.join(" AND "),
      null,
      null,
      null,
      null,
      agg,
      true,
    );

    const { Aggregations } = res;
    if (Aggregations == null) return [];

    switch (this.state.teamDashboard.mode) {
      case TeamDashboardMode.Team:
        return getTeamAssignments(Aggregations, phaseKey);

      case TeamDashboardMode.User:
        return getUserAssignments(Aggregations, phaseKey);

      default:
        return [];
    }
  }

  private async updateDashboardData() {
    const phaseCounts: { [id: string]: number } = {};

    this.setState(state => ({
      ...state,
      loadingPhaseCounts: true,
      loadPhaseCountsOnNextStart: false,
      teamDashboard: { ...state.teamDashboard, loading: true },
    }));

    const [res, assignments] = await Promise.all([
      this.applicantRepo.search(
        null,
        null,
        null,
        null,
        null,
        "terms:application.metaData.phaseId",
        true,
      ),
      this.getAssignmentCounts(),
    ]);

    let remaining = res.Total;

    const { Aggregations } = res;
    if (
      Aggregations != null &&
      Aggregations["terms_application.metaData.phaseId"] != null
    ) {
      Aggregations["terms_application.metaData.phaseId"].Items.forEach(i => {
        if (phaseCounts[i.Key] == null) {
          phaseCounts[i.Key] = 0;
        }

        phaseCounts[i.Key] += i.Total;
        remaining -= i.Total;
      });
    }

    phaseCounts["in-progress"] = remaining;

    this.setState(state => ({
      ...state,
      loadingPhaseCounts: false,
      teamDashboard: { ...state.teamDashboard, loading: false, assignments },
      phaseCounts,
    }));
  }

  private getPhaseHash(phases: EvaluationPhase[]): { [id: string]: string } {
    if (phases == null) return null;
    const hash: { [id: string]: string } = {};
    phases.forEach(p => (hash[p.Id] = p.Key));
    return hash;
  }

  @handle(ApplyEvaluationPhasesAction)
  private handleApplyEvaluationPhases(action: ApplyEvaluationPhasesAction) {
    this.setState(state => ({
      ...state,
      phaseKeyHash: this.getPhaseHash(action.newPhases),
    }));
  }

  @handle(ChooseTeamDashboardFilterAction)
  private async handleChooseTeamDashboardFilterAction(
    action: ChooseTeamDashboardFilterAction,
  ) {
    switch (action.filterType) {
      case TeamDashboardFilter.Phase:
        this.setState(state => ({
          ...state,
          teamDashboard: { ...state.teamDashboard, phaseKey: action.value },
        }));
        break;

      case TeamDashboardFilter.Program:
        this.setState(state => ({
          ...state,
          teamDashboard: { ...state.teamDashboard, programId: action.value },
        }));
        break;

      case TeamDashboardFilter.Segment:
        this.setState(state => ({
          ...state,
          teamDashboard: { ...state.teamDashboard, segmentId: action.value },
        }));
        break;
    }

    await this.refreshTeamAssignments();
  }

  @handle(ChooseTeamDashboardMode)
  private async handleChooseTeamDashboardModeAction(
    action: ChooseTeamDashboardMode,
  ) {
    this.setState(state => ({
      ...state,
      teamDashboard: { ...state.teamDashboard, mode: action.mode },
    }));
    await this.refreshTeamAssignments();
  }

  private async refreshTeamAssignments() {
    this.setState(state => ({
      ...state,
      teamDashboard: { ...state.teamDashboard, loading: true },
    }));

    const assignments = await this.getAssignmentCounts();

    this.setState(state => ({
      ...state,
      teamDashboard: { ...state.teamDashboard, loading: false, assignments },
    }));
  }
}
