import { ComponentEventBus, inject, prop } from "fw";
import { Validators } from "fw-model";
import type { ICustomFieldDefinition } from "models/contact-organization";
import type { ILinkInfo } from "models/contact";
import { FieldMode } from "../field-mode";
import { ContactsOrganizationService } from "service/contacts-organization";

const { required, isUrl } = Validators;

@inject
export class LinkType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: ILinkInfo;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(true) public setfocus!: boolean;
  @prop(false) public showClear!: boolean;

  public validation: string | null = null;

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  public attached() {
    this.ceb.dispatch("validate", this.validate);
  }

  public clear() {
    this.ceb.dispatch("update:value", null);
  }

  public get metaDescription() {
    return this.showMeta
      ? this.contactOrganizationService.getFieldMetaDescription(this.field)
      : null;
  }

  public get label() {
    return this.showLabel ? this.field.display_name : "URL";
  }

  public get localUrl(): string {
    return this.value && this.value.url;
  }

  public set localUrl(url: string) {
    if (!url && !this.value?.name) {
      this.clear();
      return;
    }

    this.ceb.dispatch("update:value", {
      ...this.value,
      url: url && url.trim(),
    });
  }

  public get localName(): string | undefined {
    return this.value && this.value.name;
  }

  public set localName(name: string | undefined) {
    if (!name && !this.value?.url) {
      this.clear();
      return;
    }

    this.ceb.dispatch("update:value", {
      ...this.value,
      name: name && name.trim(),
    });
  }

  private validate(originalValue: ILinkInfo): boolean {
    if (!this.value) {
      return true;
    }

    this.validation = required(this.value.url);
    if (this.validation !== null) {
      return false;
    }

    this.validation = isUrl({
      allowedProtocols: ["http", "https"],
      requireProtocol: true,
      allowPort: true,
      allowPath: true,
    })(this.value.url);
    return this.validation === null;
  }
}
