import { ComponentEventBus, inject, prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import type { IAddressData } from "models/contact";
import { FieldMode } from "../field-mode";
import { ContactsOrganizationService } from "service/contacts-organization";

@inject
export class CountryType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: IAddressData;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(true) public setfocus!: boolean;
  @prop(false) public showClear!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  public get localValue() {
    return this.value && this.value.country;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", {
      ...this.value,
      address1: undefined,
      address2: undefined,
      city: undefined,
      state: undefined,
      postal_code: undefined,
      country: value && value.trim(),
    });
  }

  public get metaDescription() {
    return this.showMeta
      ? this.contactOrganizationService.getFieldMetaDescription(this.field)
      : null;
  }

  public get label() {
    return this.showLabel ? this.field.display_name : null;
  }
}
