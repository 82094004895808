import { inject } from "fw";
import { Store, handle } from "fw-state";
import { StartAction, LogoutAction } from "./actions";
import { FeatureFlagService } from 'service/feature-flag';
import { DecisionSettings } from 'models/decision-settings';
import { DecisionSettingsRepository } from 'network/decision-settings-repository';
import { UpdateDecisionSettingsAction } from 'forms/decision-settings';

interface DecisionSettingsStoreShape {
  seasonId: string;
  decisionSettings: DecisionSettings;
}

@inject
export class DecisionSettingsStore extends Store<DecisionSettingsStoreShape> {

  constructor(
    private ffs: FeatureFlagService,
    private decisionSettingsRepo: DecisionSettingsRepository
  ) {
    super();
  }

  defaultState() {
    return {
      seasonId: null,
      decisionSettings: null
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    this.setState(state => ({
      seasonId: s.context.Season.Id,
      decisionSettings: s.context.Season.DecisionSettings
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(UpdateDecisionSettingsAction)
  private async handleUpdateDecisionSettingsAction(action: UpdateDecisionSettingsAction) {
    action.form.validate();

    const updatedSettings = await this.decisionSettingsRepo.put(
      action.form.updatedModel()
    );

    this.setState(state => ({
      ...state,
      decisionSettings: updatedSettings
    }));
  }
}
