import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { ATS } from "./ats";
import { GoalDefinition, GoalValueSet } from 'models/goal';
import { GoalHistoryPagedDictionaryResult } from "models/goal-history";

export interface PostArgs {
  segmentId: string;
  name: string;
  targetNumber: number;
  goalType: number;
  startDateUtc: Date;
  endDateUtc: Date;
}

@inject
export class GoalRepository {
  constructor(private s: ATS) {}

  public async post(args: PostArgs) {
    const res = await this.s.post("goal", args);
    return createFrom(GoalDefinition, res.body);
  }

  public async get(id: string) {
    const res = await this.s.get(`goal/${id}`);
    return createFrom(GoalDefinition, res.body);
   }

   public async put(goal: GoalDefinition) {
    const res = await this.s.put(`goal/${goal.Id}`, goal);
    return createFrom(GoalDefinition, res.body);
  }

  public async del(ids: string[]) {
    await this.s.post("goal/delete", ids);
  }

  public async list() {
    const res = await this.s.get<GoalDefinition[]>("goal");
    return createFromArray(GoalDefinition, res.body);
  }

  public async counts(goalIds: string[]) {
    const res = await this.s.post<GoalValueSet>('goal/counts', goalIds);
    return res.body;
  }

  public async history(goalId: string, nextPageToken: string = null, pageSize: number = 1000) {
    const res = await this.s.get<GoalHistoryPagedDictionaryResult>(`goal/${goalId}/history`, {nextPageToken, pageSize});
    return res.body;
  }
}
