import { CustomFieldType } from "models/contact-organization";
import { DataDictionaryField } from "models/data-dictionary";
import { FunctionType } from "models/function";
import { get, set, orderBy } from "lodash-es";

export interface Completer {
  // token will include a whole word with .'s, word is just the word
  getCompletions(
    token: string,
    word: string,
    completionTypes?: Array<CustomFieldType | FunctionType>,
    contactType?: string,
    handlebars?: boolean
  ): Promise<CompletionItem[]>;
}

export function filterByPartialWord(word: string, list: CompletionItem[], rootPrefix: string = null) {
  if (word == null) return list;
  const suggestions = word.trim().length > 0
    ? list.filter(w => w.token.toLowerCase().startsWith(word.toLowerCase()))
    : list;
  if (rootPrefix && !suggestions.length) {
    const root = list.find(x => x.token == rootPrefix);
    const childProperties = root?.properties.filter(w => w.token.toLowerCase().startsWith(word.toLowerCase()))
      .map(item => ({ ...item, token: `${root.token}?.${item.token}` }));

    return childProperties ? childProperties : suggestions;
  }

  return suggestions;
}

export function toCompleterStructure(fields: DataDictionaryField[], rootPrefix: string = null) {
  const flatStructure = [];
  const completerStructure = {};

  fields.map(item => item.Path && flatStructure.push(item.Path?.split(".")));

  flatStructure.forEach(arr => {
    if (arr.length === 1) return completerStructure[arr[0]] = "";

    set(completerStructure, arr, "")
  });

  if (rootPrefix) {
    return {
      [rootPrefix]: completerStructure
    }
  }

  return completerStructure
};

export function getCompletionItems(tw, completerList) {
  let searchPath = tw.token && tw.token.replaceAll("?", "");
  let completions = {};

  if (searchPath.charAt(searchPath.length - 1) === ".") searchPath = searchPath.substring(0, searchPath.length - 1);

  if (tw.word === "") {
    completions = searchPath ? get(completerList, searchPath) : completerList;
  } else {
    const lastDelimiterIndex = searchPath.lastIndexOf(".");

    completions = get(completerList, searchPath.substring(0, lastDelimiterIndex)) ?? completerList;

    completions = Object
      .keys(completions)
      .filter(key => key.startsWith(tw.word))
      .reduce((obj, key) => {
        obj[key] = completions[key];
        return obj;
      }, {});
  }

  return parseCompletionItem(completions);
}

function parseCompletionItem(completions) {
  let items: CompletionItem[] = []
  if (!!completions) {
    Object.entries(completions).forEach(([key, value]) => {
      let item: CompletionItem = {
        token: key,
        properties: value ? parseCompletionItem(value) : []
      };
      items.push(item);
    });
  }
  return items;
}

export interface CompletionItem {
  token: string;
  properties?: CompletionItem[];
  type?: CustomFieldType | FunctionType;
}

export const getItems = (items: CompletionItem[], token: string, word: string, rootPrefix = null): CompletionItem[] => {
  if (!token && !word) return items;

  const split = token.split(/[.?]+/);
  const indexOfWord = split.indexOf(word);

  if (items == null) return [];
  if (indexOfWord == 0) return filterByPartialWord(word, items, rootPrefix);

  const item = items.find(i => i.token.toLowerCase() == split[0].toLowerCase());
  if (item != null) return getItems(item.properties, split.slice(1).join("."), word);

  return [];
};

export interface TokenWord {
  token: string,
  tokenStart: number,
  tokenEnd: number,
  word: string,
  wordStart: number,
  wordEnd: number
}

export const getTokenWord = (value: string, start: number, end: number): TokenWord => {
  const tokenBoundry = /[\w\[\]\.\?]/;
  let tokenStart = start;
  let tokenEnd = end;

  while (tokenStart && tokenBoundry.test(value.charAt(tokenStart - 1))) --tokenStart;
  while (tokenEnd < value.length && tokenBoundry.test(value.charAt(tokenEnd))) ++tokenEnd;

  const token = value.slice(tokenStart, tokenEnd);
  if (!token || /^\.+$/.test(token) ) return null;

  const wordBoundry = /\w/;
  let wordStart = start;
  let wordEnd = end;

  while (wordStart && wordBoundry.test(value.charAt(wordStart - 1))) --wordStart;
  while (wordEnd < value.length && wordBoundry.test(value.charAt(wordEnd))) ++wordEnd;

  const word = value.slice(wordStart, wordEnd);

  return { token, tokenStart, tokenEnd, word, wordStart, wordEnd };
};

export interface ReplacedValue {
  newValue: string,
  newPosition: number,
  start: number,
  end: number
}

export const getNewToken = (variable: CompletionItem, value: string, tokenWord: TokenWord) => {
  let start = tokenWord.tokenStart, end = tokenWord.tokenEnd;
  let startBrackets = "{{ ", endBrackets = " }}";

  // match last instance of "{{" without a matching "}}" up to the start of the token
  const startMatches = value.substring(0, start).match(new RegExp("(?:{{)(?!.*(?:({{)|(}})))", "gms"));
  if (startMatches) startBrackets = "";

  // match first instance of "}}" without a matching "{{" after the token
  const endMatches = value.substring(end).match(new RegExp("(?:}})(?<!.*({{((?!}}).)*?}}))", "gms"));
  if (endMatches) endBrackets = "";

  const tokenWithoutWord = tokenWord.token.substr(0, tokenWord.token.length - tokenWord.word.length);
  const dot = variable.properties && variable.properties.length ? "." : "";
  const token = startBrackets + tokenWithoutWord + variable.token + dot + endBrackets;

  return {
    start,
    end,
    token,
    dot,
    endBrackets,
  };
}

export const replaceVariable = (variable: CompletionItem, value: string, tokenWord: TokenWord): ReplacedValue => {
  const { start, end, token, dot, endBrackets} = getNewToken(variable, value, tokenWord);
  const newValue = value.substr(0, start) + token + value.substr(end);
  const newPosition = start + token.length + (!endBrackets && !dot ? 3 : 0) - (endBrackets && dot ? 3 : 0);

  return { newValue, newPosition, start, end };
};

export interface VariablePicked {
  variable: CompletionItem;
  clicked: boolean;
};

export const correctPopoverPosition = () => setTimeout(() => {
  const popover = <HTMLElement>document.querySelector(".fw-popover-wrapper.open");
  if (popover) {
    const padding = 10;
    const sizeX = popover.clientWidth + parseFloat(popover.style.left);
    const sizeY = popover.clientHeight + parseFloat(popover.style.top);
    const windowSizeX = window.innerWidth + window.scrollX;
    const windowSizeY = window.innerHeight + window.scrollY;

    const translateX = windowSizeX < sizeX ? windowSizeX - sizeX - padding : 0;
    const translateY = windowSizeY < sizeY ? windowSizeY - sizeY - padding : 0;

    popover.style.transform = `translate(${translateX}px, ${translateY}px)`;
  }
}, 100);