import { fromClass } from "fw-model";
import { MarketingOutreachItem } from "./marketing-outreach-item";

export class MarketingOutreachTier {
  Id: number = null;
  OutreachTrackId: number = null;
  Days: number = 1;
  SendOn: Date = null;
  Time: string = null;
  Repeat: string = null;
  Order: number = null;
  @fromClass items: MarketingOutreachItem[] = null;
}
