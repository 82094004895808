import { formFor, FormForType, Validators } from "fw-model";
import {
  PinnedFieldGroup,
  PinnedField,
  ApplicationSettings,
  ApplicationProperty,
  CustomPropertyEnumerationOption,
  CustomPropertyTypeCode,
  PinnedFieldSettings,
  ProgramProperty
} from "models/application-settings";
import { gridColumnFormCreator, GridColumnForm } from 'forms/grid-column';

import { isValidKey } from "./validators";
import { GridColumn } from "models/grid-column";
import { conditionInfoFormCreator } from "./condition-info";
import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { weightedScoreSettingsFormCreator } from "forms/weighted-score";

const { wrap, required } = Validators;

export class UpdateApplicationSettingsAction {
  constructor(public form: ApplicationSettingsForm) { }
}

export class UpdateGridColumnsAction {
  constructor(public form: GridColumnForm[],
    public isDefault: boolean = false) { }
}

export class RevertToDefaultGridColumnsAction { }

export class GridColumnsChangedAction {
  constructor(public original: GridColumn[], public modified: GridColumn[]) { }
}

export class UpdatePinnedFieldsAction {
  constructor(public form: PinnedFieldSettingsForm) { }
}

export class UpdateApplicationPropertiesAction {
  constructor(public form: ApplicationSettingsForm) { }
}

export type PinnedFieldForm = FormForType<PinnedField>;
export type PinnedFieldSettingsForm = FormForType<PinnedFieldSettings>;
export type ApplicationPropertyForm = FormForType<ApplicationProperty>;
export type ProgramPropertyForm = FormForType<ProgramProperty>;

export const pinnedFieldRequiredExpressionFormCreator = formFor(
  PinnedField,
  s => {
    s.requiredField(c => c.Label, "Label");
    s.field(c => c.Path, "Path");
    s.field(c => c.HideLabel, "Hide Label In Application View");
    s.requiredField(c => c.Expression, "Custom Expression");
  },
);

export const pinnedFieldFormCreator = formFor(PinnedField, s => {
  s.requiredField(c => c.Label, "Label");
  s.field(c => c.Path, "Path", v =>
    v.if(
      c => c.Expression == null || c.Expression.trim().length == 0,
      required,
    ),
  );
  s.field(c => c.HideLabel, "Hide Label In Application View");
  s.field(c => c.Expression, "Custom Expression");
});

export type PinnedFieldGroupForm = FormForType<PinnedFieldGroup>;

export const pinnedFieldGroupFormCreator = formFor(PinnedFieldGroup, s => {
  s.field(g => g.Label, "Group Title");
  s.requiredField(g => g.ColumnSpan, "Column Span");
  s.field(g => g.Condition, "Condition");
  s.form(f => f.ConditionInfo, "Condition Builder", conditionInfoFormCreator);

  s.formArray(g => g.Fields, "Fields", pinnedFieldFormCreator);
});

export type ApplicationSettingsForm = FormForType<ApplicationSettings>;

export const applicationEnumerationOptionFormCreator = formFor(
  CustomPropertyEnumerationOption,
  s => {
    s.field(f => f.ColorHexCode, "Color");
    s.requiredField(f => f.Label, "Label");
  },
);

export const applicationPropertyFormCreator = formFor(
  ApplicationProperty,
  s => {
    s.requiredField(f => f.Label, "Label");
    s.requiredField(f => f.Key, "Key", wrap(isValidKey));
    s.field(f => f.Expression, "Expression", v =>
      v.if(
        f => f.PropertyType == CustomPropertyTypeCode.Calculated && f.DataType !== CalculatedFieldDataTypeCode.WeightedScore,
        required,
      ),
    );
    s.form(f => f.WeightedScoreSettings, "Weighted Score Settings", weightedScoreSettingsFormCreator);
    s.form(f => f.BooleanExpressionInfo, "Expression Builder", conditionInfoFormCreator);
    s.requiredField(f => f.PropertyType, "PropertyType");
    s.requiredField(f => f.DataType, "Data Type");

    s.formArray(
      f => f.EnumerationOptions,
      "Options",
      applicationEnumerationOptionFormCreator,
    );
  },
);

export const programPropertyFormCreator = formFor(
  ProgramProperty,
  s => {
    s.requiredField(f => f.Label, "Label");
    s.requiredField(f => f.Key, "Key", wrap(isValidKey));
    s.field(f => f.Expression, "Expression", v =>
      v.if(
        f => f.PropertyType == CustomPropertyTypeCode.Calculated,
        required,
      ),
    );
    s.form(f => f.BooleanExpressionInfo, "Expression Builder", conditionInfoFormCreator);
    s.requiredField(f => f.PropertyType, "PropertyType");
    s.requiredField(f => f.DataType, "Data Type");
    s.field(f => f.IsPublic, "Public");

    s.formArray(
      f => f.EnumerationOptions,
      "Options",
      applicationEnumerationOptionFormCreator,
    );
  },
);

export const pinnedFieldsSettingsFormCreator = formFor(
  PinnedFieldSettings,
  s => {
    s.formArray(
      ss => ss.PinnedFieldGroups,
      "Pinned Fields",
      pinnedFieldGroupFormCreator,
    );
    s.formArray(
      ss => ss.SidePinnedFieldGroups,
      "Side Pinned Fields",
      pinnedFieldGroupFormCreator,
    );
  },
);

export const applicationSettingsFormCreator = formFor(
  ApplicationSettings,
  s => {
    s.formArray(
      ss => ss.PinnedFieldGroups,
      "Pinned Fields",
      pinnedFieldGroupFormCreator,
    );
    s.formArray(
      ss => ss.SidePinnedFieldGroups,
      "Side Pinned Fields",
      pinnedFieldGroupFormCreator,
    );
    s.formArray(
      ss => ss.ApplicationProperties,
      "Application Properties",
      applicationPropertyFormCreator,
    );
    s.formArray(ss => ss.GridColumns, "Grid Columns", gridColumnFormCreator);
  },
);


