import { prop } from "fw";

import { CalendarEventTypeFilter as CalendarEventTypeFilterModel } from "models/calendar-event-filters";

export class CalendarEventTypeFilter {
  @prop(null) filter!: CalendarEventTypeFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  ops = [
    { value: "=", text: "contains" },
    { value: "!=", text: "does not contain" }
  ];

  get operatorText(): string {
    if (!this.filter || !this.filter.operator)
      return null;

    return this.ops.find(o => o.value === this.filter.operator)?.text;
  }
}
