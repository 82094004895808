import { ComponentEventBus, inject, prop } from "fw";
import { ApplicationRestriction } from "models/role";

@inject
export class ApplicantProfileImageRestriction {
  @prop(null) restriction!: ApplicationRestriction;
  @prop(false) disabled?: boolean;

  constructor(
    private ceb: ComponentEventBus
  ) { }

  deletePath(index) {
    this.ceb.dispatch("delete-path", { restriction: this.restriction, index: index });
  }

}
