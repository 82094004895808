import { inject, prop } from "fw";
import { DecisionSettingsStore } from 'state/decision-settings';

@inject
export class DecisionFilter {
  @prop(() => ({})) validation;
  @prop(null) filter;
  @prop(false) editing!: boolean;

  private decisions: { text: string, value: string }[] = [];
  private decisionNameHash: { [id: string]: string } = {};

  constructor(private decisionSettingsStore: DecisionSettingsStore) { }

  public attached() {
    const newHash: { [id: string]: string } = {};

    this.decisions.push({ text: "None", value: null });

    this.decisionSettingsStore.state.decisionSettings.Codes.forEach(c => {
      this.decisions.push({ text: c.Code, value: c.Id });
      newHash[c.Id] = c.Code;
    });

    this.decisionNameHash = newHash;
  }
}

