import { inject, prop, ComponentEventBus } from "fw";

import { GroupFilter as GroupFilterFormModel } from "models/filter-setup";

@inject
export class ContactsApplicationGroupFilter {
  @prop(null)
  filter: GroupFilterFormModel;

  @prop(false)
  editing: boolean;

  constructor(private ceb: ComponentEventBus) { }

  get proxyFilter() {
    return this.filter;
  }

  set proxyFilter(val) {
    this.ceb.dispatch("update:filter", val);
  }
}
