import { prop, inject, ComponentEventBus } from "fw";

import { FeatureFlagService } from "service/feature-flag";
import { DataDictionaryService } from "service/data-dictionary";
import { Completer, getTokenWord } from "helpers/auto-complete";
import { CustomFieldType } from "models/contact-organization";
import { FunctionType } from "models/function";

let uniqueCodeEditorId = 0;

@inject
export class CodeEditor {
  @prop(null) public value!: string;

  /**
  * This is the default value.
  */
  @prop(null) public changingValue!: string;
  @prop("htmlmixed") public mode!: string;
  @prop(true) public lineNumbers!: boolean;
  @prop(null) public completer!: Completer | Completer[];
  @prop(null) public contactType: string;
  @prop(null) public completionTypes: Array<CustomFieldType | FunctionType>;
  @prop(false) public handlebars: boolean;
  @prop(false) public disabled!: boolean;
  @prop(false) public lineWrapping;

  public textAreaEl: HTMLTextAreaElement;
  public editorEl: HTMLElement;
  public loading: boolean = false;
  public currentCompleter: Completer | Completer[] = null;

  private uniqueId: number = uniqueCodeEditorId++;
  private Pos: CodeMirror.PositionConstructor;
  private cmInstance: CodeMirror.EditorFromTextArea = null;

  constructor(
    private ceb: ComponentEventBus,
    private ffs: FeatureFlagService,
    private dataDictionaryService: DataDictionaryService,
  ) {
  }

  public disabledChanged() {
    if (this.cmInstance == null)
      return;

    this.cmInstance.setOption("readOnly", this.disabled);
  }

  public async attached() {
    this.loading = true;

    const codemirror = await import("./code-mirror");
    this.Pos = codemirror.Pos;

    const config = {
      mode: this.mode,
      theme: "neo",
      readOnly: this.disabled,
      lineNumbers: this.lineNumbers,
      lineWrapping: this.lineWrapping,
      viewportMargin: Infinity,
      scrollbarStyle: "overlay",
      screenReaderLabel: "code editor",
      tabindex: 0
    };

    this.currentCompleter = this.completer;
    if (this.currentCompleter !== null) {
      Object.assign(config, {
        extraKeys: { "Ctrl-Space": "autocomplete" },
        hintOptions: {
          hint: this.hints.bind(this),

        },
      });
    }

    const codeMirrorInstance = codemirror.default.fromTextArea(
      this.textAreaEl,
      config,
    );

    this.cmInstance = codeMirrorInstance;

    this.cmInstance.setOption("extraKeys", {
      "Tab": false,
      "Shift-Tab": false,
    });

    if (this.currentCompleter != null) {
      const runAc = (cm) => {

        (<any>codemirror.default).commands.autocomplete(cm, null, {
          completeSingle: false,
        });
      };

      (codeMirrorInstance.on as any)("keyup", (cm, event) => {
        if (!cm.state.completionActive && event.keyCode != 13) runAc(cm);
      });

      (codeMirrorInstance.on as any)("endCompletion", runAc);
    }

    codeMirrorInstance.on("change", (instance, opts) => {
      this.ceb.dispatch("update:value", instance.getValue());
      this.ceb.dispatch("change");
    });

    codeMirrorInstance.on("blur", () => {
      this.currentCompleter = null;
    });

    codeMirrorInstance.on("focus", () => {
      if (!this.currentCompleter)
        this.currentCompleter = this.completer;
    });

    (codeMirrorInstance as any).display.scroller.setAttribute('tabindex', '0');

    this.loading = false;
  }

  public get pValue() {
    return this.value || this.changingValue;
  }

  changingValueChanged() {
    if (this.cmInstance == null)
      return;

    this.cmInstance.setValue(this.changingValue);
  }

  public insertValue(value) {
    this.cmInstance.getDoc().replaceSelection(value);
  }

  private async hints(cm, option) {
    const cursor = cm.getCursor();
    const line = cm.getLine(cursor.line);
    const tw = getTokenWord(line, cursor.ch, cursor.ch);

    if (!tw || !this.currentCompleter) return null;

    const completers = !Array.isArray(this.currentCompleter) ? [this.currentCompleter] : this.currentCompleter;
    const completions = await Promise.all(completers.map(
      async i => i.getCompletions(tw.token, tw.word, this.completionTypes, this.contactType, this.handlebars)
    ));
    const list = [].concat.apply([], completions.filter(e => e !== undefined)).map(i => {
      i.text = this.mode == "javascript" && i.properties?.length ? `${i.token}?.` : i.token;
      i.displayText = i.token;
      return i;
    });

    if (!list.length || list.length == 1 && list[0].token === tw.word) return null;

    return {
      list,
      from: this.Pos(cursor.line, tw.wordStart),
      to: this.Pos(cursor.line, tw.wordEnd),
    };
  }
}
