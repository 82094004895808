import { formFor, FormForType, Validators } from "fw-model";
const { wrap, isUrl } = Validators;
import { isValidPageSlug } from "./validators";

import {
  InquiryPortal,
  InquiryPortalContent,
  InquiryPortalForm,
} from "models/inquiry-portal";

export class CreateInquiryPortalAction {
  newPortal: InquiryPortal;
  constructor(public form: FormForInquiryPortal) {}
}

export class UpdateInquiryPortalAction {
  constructor(public form: FormForInquiryPortal) {}
}

export class DeleteInquiryPortalAction {
  constructor(public id: string) {}
}

export type FormForInquiryPortal = FormForType<InquiryPortal>;
export type FormFormInquiryPortalContent = FormForType<InquiryPortalContent>;
export type FormForInquiryPortalForm = FormForType<InquiryPortalForm>;

export const inquiryPortalFormFormCreator = formFor(InquiryPortalForm, s => {
  s.requiredField(s => s.FormId, "Form");
  s.requiredField(s => s.PageSlug, "Url", wrap(isValidPageSlug));
});

export const inquiryPortalContentFormCreator = formFor(
  InquiryPortalContent,
  s => {
    s.field(c => c.HeaderHtml, "Header HTML");
    s.field(c => c.FooterHtml, "Footer HTML");
    s.field(c => c.HomeHtml, "Home HTML");
    s.field(c => c.ConfirmationHtml, "Confirmation HTML");
    s.field(c => c.Css, "Custom Content CSS");
    s.field(c => c.GlobalCss, "Global CSS Override");
    s.field(c => c.FavIconUrl, "Favicon Url");
    s.field(c => c.SupportEmailAddress, "Support Email Address");
  },
);

export const inquiryPortalFormCreator = formFor(InquiryPortal, s => {
  s.requiredField(p => p.Name, "Name");
  s.requiredField(p => p.Domain, "Domain", wrap(isUrl()));
  s.form(p => p.Content, "Content", inquiryPortalContentFormCreator);
  s.formArray(p => p.Forms, "Forms", inquiryPortalFormFormCreator);
});
