import { inject, prop, needs } from "fw";

import { ValueType, ValueField } from "views/components/application-filters/value-field";
import { FeatureFlagService } from "service/feature-flag";
import { getFriendlyTiming } from "models/date-filter";
import { LastUpdatedFilter as LastUpdatedFilterModel } from "models/application-filters";

@inject
@needs(ValueField)
export class LastUpdatedFilter {
  @prop(null) filter!: LastUpdatedFilterModel;
  @prop(false) editing!: boolean;
  @prop(() => ({})) validation;

  constructor(
    private ffs: FeatureFlagService
  ) {}

  valueType = ValueType.Date;

  types = [
    { text: "User", value: "user" },
    { text: "Applicant", value: "applicant" },
  ];

  get userTypeFriendly() {
    if (this.filter == null) return "";

    switch (this.filter.type) {
      case "user": return "reviewer";
      case "applicant": return "applicant";
    }

    return this.filter.type;
  }

  get friendlySince() {
    if (this.filter == null) return "";
    return getFriendlyTiming(this.filter);
  }
}
