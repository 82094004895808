import { ComponentEventBus, inject, prop } from "fw";
import { dispatch } from "fw-state";
import { groupBy, keys } from "lodash-es";

import { ContactSegment } from "models/contact-segment";
import { type ContactRestrictionType, ContactTypeDefinition } from "models/contact-organization";
import type { ContactTypeDataPolicyFormType } from "forms/contact-organization";
import { ContactSegmentStore, EnsureContactSegmentStoreAction } from "state/contact-segments";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";

@inject
export class ContactTypeVisibilityRestrictions {
  @prop(null) type!: ContactRestrictionType;
  @prop(null) form!: ContactTypeDataPolicyFormType;

  public contactType: ContactTypeDefinition = null;
  public searchTerm: string = "";
  public selectedCategory: string = null;

  constructor(
    private ceb: ComponentEventBus,
    private contactSegmentStore: ContactSegmentStore,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
  ) { }

  async attached() {
    await dispatch(new EnsureContactSegmentStoreAction());
    this.contactType = this.currentContactOrganizationStore.state.organization.contact_types.find(c => c.key === this.form.contact_type);
  }

  public get localType() {
    return this.type;
  }
  public set localType(type: ContactRestrictionType) {
    this.ceb.dispatch("update:type", type);
  }

  public get filteredSegments() {
    return this.contactSegmentStore.state.segments.filter(s => s.contact_type === this.form.contact_type);
  }

  public get segmentsWithNoCategory() {
    return this.filteredSegments.filter(s => s.category == null);
  }

  public get categories(): { [categoryName: string]: ContactSegment[] } {
    return groupBy(this.filteredSegments.filter(s => s.category != null), s => s.category);
  }

  public selectSegment(segmentId: string) {
    this.form.is_hidden = false;
    this.form.segment_id = segmentId;
    this.done()
  }

  hideAll() {
    this.form.restricted_field_ids = [];
    this.form.segment_id = null;
    this.form.is_hidden = true;
    this.done();
  }
  showAll() {
    this.form.restricted_field_ids = [];
    this.form.segment_id = null;
    this.form.is_hidden = false;
    this.done();
  }

  public selectCategory(cat: string) {
    this.selectedCategory = cat;
  }

  public searched(list: ContactSegment[]) {
    if (list == null) {
      return [];
    }

    const st = this.searchTerm.toLowerCase();
    return list.filter(i => i.contact_type === this.form.contact_type && i.display_name.toLowerCase().indexOf(st) != -1);
  }

  done() {
    this.ceb.dispatch("done", this.form);
  }
}
