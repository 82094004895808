import { inject } from "fw";
import { groupBy, orderBy, transform } from "lodash-es";

import { ApplicationSegment } from "models/application-segment";
import { Program } from "models/program";
import { ApplicationSegmentStore } from "state/application-segments";
import { ProgramStore } from "state/program";
import { PopoverController } from "service/popover";
import { StringService } from "service/string";

export interface ApplicationSegmentSelectorPopoverParams {
  showAll?: boolean;
  showPrograms?: boolean;
  excludeSegments?: string[];
  updateOptionFocus?: Function;
}

export interface ApplicationSegmentSelectorPopoverResponse {
  type?: "Program" | "Segment";
  id: string;
}

@inject
export class ApplicationSegmentSelectorPopover {
  public searchTerm: string = "";
  public selectedCategory: string = null;
  public isProgramsSelected: boolean = false;
  public showAll: boolean = false;
  public showPrograms: boolean = false;
  public segmentsWithNoCategory: ApplicationSegment[] = [];
  public programs: Program[] = [];
  private gridFilterRef: any;
  private excludeSegments: Set<string>;
  private categories: { [categoryName: string]: ApplicationSegment[] };
  public updateOptionFocus: Function = () => {};

  constructor(
    private segmentStore: ApplicationSegmentStore,
    private programStore: ProgramStore,
    private controller: PopoverController<ApplicationSegmentSelectorPopoverResponse>,
    private stringService: StringService,
  ) { }

  public activate(params: ApplicationSegmentSelectorPopoverParams) {
    this.showAll = params?.showAll;
    this.showPrograms = params?.showPrograms;
    this.excludeSegments = new Set(params?.excludeSegments || []);
    this.segmentsWithNoCategory = this.segmentStore.state.segments.filter(s => !this.excludeSegments.has(s.Id) && s.Category == null);
    this.updateOptionFocus = params?.updateOptionFocus;

    if (this.showPrograms) {
      this.programs = orderBy(
        this.programStore.state.programs.filter(p => p.IsHidden !== true),
        [p => p.Name.toLowerCase()]
      );
    }

    this.categories = this.excludeSegments.size == 0
      ? this.segmentStore.state.categories
      : transform(this.segmentStore.state.categories, (result, value, key) => {
        const filteredSegments = value.filter(s => !this.excludeSegments.has(s.Id));
        if(filteredSegments.length > 0)
          result[key] = filteredSegments;
      });
  }

  public isExcluded(segmentId: string) {
    return this.excludeSegments.has(segmentId);
  }

  public makeProgramId(programId: string) {
    return `app-segment-program-${programId}`;
  }

  public makeCategoryId(category: string) {
    const categoryHash = this.stringService.generateSimpleHash(category);
    return `app-segment-category-${categoryHash}`;
  }

  public makeSegmentId(segmentId: string) {
    return `app-segment-option-${segmentId}`;
  }

  public focusOnOption(optionId: string) {
    if (this.updateOptionFocus instanceof Function) {
      this.updateOptionFocus(optionId);
    }
  }

  public selectCategory(category: string) {
    this.searchTerm = "";
    this.selectedCategory = category;
  }

  public selectSegment(segmentId: string) {
    this.controller.ok(<ApplicationSegmentSelectorPopoverResponse>{ type: "Segment", id: segmentId });
  }

  public selectProgram(programId: string) {
    this.controller.ok(<ApplicationSegmentSelectorPopoverResponse>{ type: "Program", id: programId });
  }

  public toggleIsProgramsSelected() {
    this.isProgramsSelected = !this.isProgramsSelected;
  }

  public get searchedCategories(): { [categoryName: string]: ApplicationSegment[] } {
    if (this.selectedCategory != null) return {};

    const search = this.searchTerm.toLowerCase();
    const filtered = this.segmentStore.state.segments.filter(s => !this.excludeSegments.has(s.Id) && s.Category != null && s.Category.toLowerCase().indexOf(search) != -1);

    return groupBy(filtered, s => s.Category);
  }

  public get searchedSegments(): ApplicationSegment[] {
    const list = this.selectedCategory != null ? this.categories[this.selectedCategory] : this.segmentStore.state.segments;
    const search = this.searchTerm.toLowerCase();

    return orderBy(
      list.filter(i => !this.excludeSegments.has(i.Id) && i.Label.toLowerCase().indexOf(search) != -1),
      [s => s.Label.toLowerCase()]
    );
  }

  public get searchedPrograms(): Program[] {
    const list = this.programStore.state.programs.filter(p => !p.IsHidden);
    const search = this.searchTerm.toLowerCase();

    return orderBy(
      list.filter(i => i.Name.toLowerCase().indexOf(search) != -1),
      [p => p.Name.toLowerCase()]
    );
  }

  public clearSearch() {
    this.searchTerm = "";
    this.gridFilterRef.focus();
  }

  public get searchPlaceholder(): string {
    let placeholder = "Search ";

    if (this.isProgramsSelected) {
      placeholder += "Programs";
    } else if (this.showPrograms && !this.selectedCategory) {
      placeholder += "Programs and Segments";
    } else {
      placeholder += "Segments";
    }

    if (!this.isProgramsSelected && this.selectedCategory) {
      placeholder += " in " + this.selectedCategory;
    }

    return placeholder + '...';
  }

  public get searchedCategoriesLength(): number {
    return Object.keys(this.searchedCategories).length;
  }

  public get totalSearchResultCount(): number {
    let total = 0;

    if (this.showPrograms && this.programs.length && this.selectedCategory == null) {
      total += this.searchedPrograms.length;
    }

    if (!this.isProgramsSelected) {
      total += this.searchedCategoriesLength + this.searchedSegments.length;
    }

    return total;
  }
}
