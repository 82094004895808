import { inject, prop, ComponentEventBus, needs } from "fw";

import {
  ChartData,
  ChartDataSource,
  ReportChartType,
  WidgetRenderer,
  isReportChartDefinition,
} from "shared/report-runtime";
import type { FormForWidgetDefinition, FormForGridViewDefinition } from "forms/report-definition";

import { PopoverService } from "service/popover";
import { ChartOptions } from "./chart-options";
import { ChartSettings, Command } from "./chart-options/chart-settings";
import { FeatureFlagService } from "service/feature-flag";
import { ChartsAxisEditor } from "./editors/charts-axis-editor";
import { ProgressEditor } from "./editors/progress-editor";
import { AssignmentEditor } from "./editors/assignment-editor";
import { WidgetContext } from "./widget-context";
import { GoalsEditor } from './editors/goals-editor';
import { FunnelEditor } from './editors/funnel-editor';
import { GridViewEditor } from "./editors/grid-view-editor";
import { RegistrationStatusCountsEditor } from "./editors/registration-status-counts-editor";

@inject
@needs(
  AssignmentEditor,
  ChartOptions,
  ChartsAxisEditor,
  FunnelEditor,
  GoalsEditor,
  GridViewEditor,
  ProgressEditor,
  RegistrationStatusCountsEditor,
  WidgetContext,
  WidgetRenderer,
)
export class ReportChart {
  @prop(null) reportFilter: string;
  @prop(null) widgetDefinitionForm: FormForWidgetDefinition;
  @prop(false) editing: boolean;
  @prop(null) data: ChartData[];
  @prop(false) dataLoading: boolean;
  @prop(false) interactive: boolean;
  @prop(false) sorting: boolean;
  @prop(1) columnSpan: number;
  @prop(-1) groupIndex: number;
  @prop(-1) subIndex: number;

  @prop(null) onclick: (groupIndex: number, subIndex: number) => void;
  @prop(null) onblur: () => void;
  @prop(null) keydown: (e) => void;
  @prop(null) keyup: (e) => void;
  public focusedIndex = -1;

  private dataSource: ChartDataSource = ChartDataSource.Admissions;

  constructor(
    private ffs: FeatureFlagService,
    private ceb: ComponentEventBus,
    private popoverService: PopoverService,
  ) { }

  public attached() {
    this.dataSource = this.widgetDefinitionForm.DataSource;
  }

  async chartOptions() {
    const res = await this.popoverService.open<Command>(ChartSettings);

    if (res.canceled) return;

    this.ceb.dispatch("command", {
      chart: this.widgetDefinitionForm,
      command: res.result,
    });
  }

  filter() {
    this.ceb.dispatch("command", {
      chart: this.widgetDefinitionForm,
      command: { command: "filter" }
    });
  }

  get allowColumnSelection() {
    return ['grid-view'].includes(this.widgetDefinitionForm?.WidgetType);
  }

  get hasColumns() {
    const columns = (this.widgetDefinitionForm as FormForGridViewDefinition)?.Columns;
    return columns && columns.length > 0;
  }

  chooseColumns() {
    this.ceb.dispatch("command", {
      chart: this.widgetDefinitionForm,
      command: { command: "choose-columns" }
    });
  }

  chartChanged() {
    this.ceb.dispatch("changed");
  }

  private isContactType() {
    return ChartDataSource.Contacts == this.dataSource;
  }

  get isFiltered() {
    return this.widgetDefinitionForm != null && this.widgetDefinitionForm.ChartFilter != null && this.widgetDefinitionForm.ChartFilter != "";
  }

  get editorType() {
    if (this.widgetDefinitionForm == null) return null;

    if (isReportChartDefinition(this.widgetDefinitionForm)) {
      switch (this.widgetDefinitionForm.ChartType) {
        case ReportChartType.Bar:
        case ReportChartType.Line:
        case ReportChartType.Percentiles:
        case ReportChartType.Pie:
        case ReportChartType.Chevron:
        case ReportChartType.Count:
        case ReportChartType.Stack:
        case ReportChartType.Table:
          return "charts-axis-editor";

        case ReportChartType.Progress:
          return "progress-editor";

        case ReportChartType.Funnel:
          return "funnel-editor";
      }
    }

    switch (this.widgetDefinitionForm.WidgetType) {
      case "assignment":
        return "assignment-editor";
      case "goals":
        return "goals-editor";
      case "funnel":
        return "funnel-editor";
      case "grid-view":
        return "grid-view-editor";
      case "registration-status-counts":
        return "registration-status-counts-editor";
    }

    return null;
  }

  dataClick(data) {
    this.ceb.dispatch("data-click", data);
  }

  onDragIconClick(groupIndex, subIndex) {
    this.focusedIndex = -1;

    if (this.onclick) {
      this.onclick(groupIndex, subIndex);
    }
  }

  onDragIconFocus(subIndex) {
    this.focusedIndex = subIndex;
  }

  onDragIconBlur() {
    this.focusedIndex = -1;

    if (this.onblur) {
      this.onblur();
    }
  }

  onDragIconKeyDown(e) {
    if (this.keydown) {
      this.keydown(e);
    }
  }

  onDragIconKeyUp(e) {
    if (this.keyup) {
      this.keyup(e);
    }
  }

  public get ffAccessibleDragDrop(): boolean {
    return this.ffs.isFeatureFlagEnabled("AccessibleDragDropOUT2055Nov2023");
  }
}
