import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { ReportDefinitionRepository } from "network/report-definition";
import { AppRepository } from "network/app-repository";

import { FormErrorHandling } from "./error-handling";
import { EnsureTaskRequestAction, LogoutAction, StartAction, TaskFinishedAction } from "./actions";
import { FieldGroup, ReportDefinition, ReportResultModel } from "shared/report-runtime";
import {
  CreateReportDefinitionAction,
  UpdateReportDefinitionAction
} from "forms/report-definition";
import { ShareTokenFormType } from "forms/share-token";

import { difference } from "lodash-es";
import { TaskRequestStatusTypeCode } from "models/task-request";
import { ReportService } from "service/report";
import { ForceRefreshDataDictionaryFieldsAction } from "./data-dictionary";
import { FeatureFlagService } from "service/feature-flag";

type ReportPreviewStatus = {
  reportId: string;
  taskRequestId: string;
  inProgress: boolean;
}

interface ReportDefinitionsStoreShape {
  allDefinitions: ReportDefinition[];
  definitions: ReportDefinition[];
  showSnapshots: boolean;
  dashboardDefinition: ReportDefinition;
  resolvedDashboardId: string;
  selectedCategory: string;
  currentReportResult: ReportResultModel; 
  reportPreviewStatus: ReportPreviewStatus;
}

export class ShareReportDefinitionAction {
  public shareToken: string = null;

  constructor(
    public id: string,
    public form: ShareTokenFormType,
    public revoke = false
  ) {}
}

export class DeleteReportDefinitionAction {
  constructor(public ids: string[]) {}
}

export class ReportPreviewIndexingStatusAction {
  constructor(public taskRequestId: string, public isIndexing: boolean, public reportId: string) {}
}

export class IndexingFinishedAction {
  constructor(public taskRequestId: string) {}
}

export class ToggleShowSnapshotsAction {
  constructor() {}
}

export class SelectReportsCategoryAction {
  constructor(public selectedCategory: string) {}
}

export class ReportsCategorizeAction {
  constructor(public ids: string[], public category: string) {}
}

export class RunReportAction {
  constructor(public reportId: string) {}
}

export class EnsureResolvedDashboardAction {
  constructor(public requery = false) {}
}

@inject
export class ReportDefinitionsStore extends Store<ReportDefinitionsStoreShape> {
  constructor(
    private appRepo: AppRepository,
    private reportDefinitionRepo: ReportDefinitionRepository,
    private reportService: ReportService,
    private ffs: FeatureFlagService
  ) {
    super();
  }

  defaultState() {
    return {
      allDefinitions: [],
      definitions: [],
      dashboardDefinition: null,
      showSnapshots: false,
      selectedCategory: null,
      resolvedDashboardId: null,
      currentReportResult: null,
      reportPreviewStatus: null
    };
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private handleStartAction(s: StartAction) {
    this.setState(state => ({
      ...state,
      allDefinitions: s.context.ReportDefinitions,
      definitions: s.context.ReportDefinitions,
      resolvedDashboardId: s.context.ResolvedDashboardId
    }));
  }

  @handle(EnsureResolvedDashboardAction)
  private async handleEnsureResolvedDashboardAction(
    action: EnsureResolvedDashboardAction
  ) {
    if (action.requery) {
      const resolvedId = await this.appRepo.resolvedDashboardId();
      this.setState(state => ({
        ...state,
        resolvedDashboardId: resolvedId
      }));
    }

    const id = this.state.resolvedDashboardId;
    if (id == null) return;

    let def = this.state.allDefinitions.find(r => r.Id == id);
    if (def == null) {
      def = await this.reportDefinitionRepo.getById(id);
    }

    this.setState(state => ({
      ...state,
      dashboardDefinition: def
    }));
  }

  @handle(CreateReportDefinitionAction, FormErrorHandling)
  private async handleCreateReportDefinitionAction(
    action: CreateReportDefinitionAction
  ) {
    action.form.validate();

    const model = action.form.updatedModel();

    model.clean();
    model.Id = null;

    const newReport = await this.reportDefinitionRepo.post(
      model,
      action.isDashboard,
      action.dashboardUserId
    );

    action.createdId = newReport.Id;

    if (action.dashboardUserId == null) {
      this.setState(state => ({
        ...state,
        definitions: [...state.definitions, newReport],
        allDefinitions: [...state.allDefinitions, newReport]
      }));
    } else {
      // assume that we can set these
      this.setState(state => ({
        ...state,
        resolvedDashboardId: newReport.Id,
        dashboardDefinition: newReport
      }));
    }
  }

  @handle(UpdateReportDefinitionAction, FormErrorHandling)
  private async handleUpdateReportDefinitionAction(
    action: UpdateReportDefinitionAction
  ) {
    action.form.validate();

    const model = action.form.updatedModel();

    model.clean();
    const updatedReport = await this.reportDefinitionRepo.update(model);

    const defsToLookAt = [...this.state.definitions];
    if (this.state.dashboardDefinition != null)
      defsToLookAt.push(this.state.dashboardDefinition);

    const existingReport = defsToLookAt.find(s => s.Id == updatedReport.Id);
    if (existingReport == null) return;

    Object.assign(existingReport, updatedReport);

    this.setState(s => s);
  }

  @handle(RunReportAction)
  private async handleRunReportAction(
    action: RunReportAction
  ) {
    try {
      const reportResult = await this.reportDefinitionRepo.result(action.reportId);
      const hasPendingIndex = this.reportService.hasPendingIndex(reportResult);
      let indexingTaskRequests = [];
      
      if(hasPendingIndex && this.ffs.isFeatureFlagEnabled("ElectiveIndexing")) {
        const report = this.state.dashboardDefinition?.Id == action.reportId
        ? this.state.dashboardDefinition
        : this.state.definitions.find(r => r.Id == action.reportId);
        const fieldsIndexing = await this.reportService.getIndexingFields(report);
        indexingTaskRequests = fieldsIndexing.filter(x => !!x.TaskRequestId).map(x => x.TaskRequestId);
        await dispatch(new EnsureTaskRequestAction(indexingTaskRequests));
      }
  
      const fieldGroups = reportResult.FieldGroups.map(fg => ({
        fieldGroup: (fg as any) as FieldGroup,
        list: fg.List.map(c => ({
          widgetData: c,
          dataLoading: false,
        })),
      }));
  
      const results = reportResult.Widgets.map(c => ({
        widgetData: c,
        dataLoading: false,
      }));

      this.setState(state => ({
        ...state,
        currentReportResult: { ReportId: action.reportId, IndexingTasks: indexingTaskRequests,  Results: results, FieldGroups: fieldGroups }
      }));
    } catch (err) {
      this.setState(state => ({
        ...state,
        currentReportResult: { ReportId: action.reportId, IndexingTasks: [], Results: [], FieldGroups: [] }
      }));
    }
  }

  @handle(ReportPreviewIndexingStatusAction)
  private async handleReportPreviewIndexingStatusAction(
    action: ReportPreviewIndexingStatusAction
  ) {
    if(!action.isIndexing && action.taskRequestId == this.state.reportPreviewStatus?.taskRequestId) {
      this.setState(state => ({
        ...state,
        reportPreviewStatus: null
      }));
    }

    if(action.isIndexing) {
      this.setState(state => ({
        ...state,
        reportPreviewStatus: {
          reportId: action.reportId,
          inProgress: action.isIndexing,
          taskRequestId: action.taskRequestId
        }
      }));
    }
  }

  @handle(DeleteReportDefinitionAction)
  private async handleDeleteReportDefinitionAction(
    action: DeleteReportDefinitionAction
  ) {
    await this.reportDefinitionRepo.del(action.ids);

    const toBeDeleted = this.state.definitions.filter(rd =>
      action.ids.some(i => i == rd.Id)
    );

    this.setState(state => ({
      ...state,
      definitions: difference(state.definitions, toBeDeleted),
      allDefinitions: difference(state.allDefinitions, toBeDeleted)
    }));
  }

  @handle(ShareReportDefinitionAction, FormErrorHandling)
  private async handleShareReportDefinitionAction(
    action: ShareReportDefinitionAction
  ) {
    if (!action.revoke) action.form.validate();

    const res = await this.reportDefinitionRepo.share(
      action.id,
      action.form.AccessCode,
      action.revoke
    );

    action.shareToken = res.MetaData.ShareToken.Token;

    const existingReport = this.state.definitions.find(s => s.Id == res.Id);
    if (existingReport == null) return;

    Object.assign(existingReport, res);

    this.setState(s => s);
  }

  @handle(ToggleShowSnapshotsAction)
  private handleToggleShowSnapshotsAction() {
    this.setState(state => ({
      ...state,
      showSnapshots: !state.showSnapshots
    }));
  }

  @handle(SelectReportsCategoryAction)
  private handleSelectReportsCategoryAction(
    action: SelectReportsCategoryAction
  ) {
    this.setState(state => ({
      ...state,
      selectedCategory: action.selectedCategory
    }));
  }

  @handle(ReportsCategorizeAction)
  private async handleReportsCategorizeAction(action: ReportsCategorizeAction) {
    await this.reportDefinitionRepo.putCategory(action.ids, action.category);

    action.ids.forEach(sid => {
      const def = this.state.definitions.find(s => s.Id == sid);
      if (def == null) return;

      def.Category = action.category;
    });

    this.setState(s => s);
  }

  @handle(TaskFinishedAction)
  private async handleTaskFinishedAction(action: TaskFinishedAction) {
    if (action.taskRequest.Status == TaskRequestStatusTypeCode.Error) {
      return;
    }

    switch (action.taskRequest.Type) {
      case "FieldIndex":
        await dispatch(new ForceRefreshDataDictionaryFieldsAction());
        await dispatch(new ReportPreviewIndexingStatusAction(action.taskRequest.Id, false, null));
        if(this.state.currentReportResult && this.state.currentReportResult.IndexingTasks.find(x => x == action.taskRequest.Id)) {
          await dispatch(new RunReportAction(this.state.currentReportResult.ReportId));
        }
        break;
    }
  }
}
