import { fromClass, fromClassArray } from "fw-model";

import { CalculatedFieldDataTypeCode } from "./calculated-field";

export enum CeebImportStatus {
  None = 0,
  Running = 1,
  Complete = 2,
  Error = 3,
}

export class ContactsApiFieldMapping {
  ATSPath: string = null;
  ContactsPath: string = null;
  Label: string = null;
  DataType: CalculatedFieldDataTypeCode = CalculatedFieldDataTypeCode.String;
}

export class ContactsCeebDataSettings {
  IsEnabled = false;
  InitialImportStatus = CeebImportStatus.None;
  ImportTaskRequestId: string;
  ContactType = "ceeb";
  @fromClassArray(ContactsApiFieldMapping)
  FieldMappings: ContactsApiFieldMapping[];
}

export class ContactsApplicantDataSettings {
  IsEnabled = false;
  ContactType = "applicant";

  @fromClassArray(ContactsApiFieldMapping) FieldMappings: ContactsApiFieldMapping[];
}

export class ContactsIntegrationSettingsMetaData {
  OrganizationId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

export class ContactsIntegrationSettings {
  Id: string;
  ApiKey: string = null;

  @fromClass CeebDataSettings: ContactsCeebDataSettings;
  @fromClass ApplicantDataSettings: ContactsApplicantDataSettings;
  @fromClass MetaData: ContactsIntegrationSettingsMetaData;
}
