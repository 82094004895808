import { inject } from "fw";
import { createFromArray } from "fw-model";

import { ATS } from "./ats";
import { AtsConfig } from "config/ats-config";
import { MarketingEmailTemplateDesign } from "models/marketing-email-template";

@inject
export class MarketingEmailTemplateDesignRepository {
  constructor(private network: ATS) {}

  async index() {
    const res = await this.network.marketing.get<any[]>("email_template_designs");
    return createFromArray(MarketingEmailTemplateDesign, res.body);
  }
}
