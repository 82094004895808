import { Form, Validators, field } from "fw-model";

const { required, isEmail } = Validators;

export class LoginForm extends Form {
  constructor(
    public inviteToken: string = null,
    public organizationId: string = null,
    public redirectTo: string = null
  ) {
    super();
  }

  @field("Email Address", required, isEmail)
  public EmailAddress: string = null;

  @field("Password", required)
  public Password: string = null;

  @field("Authentication Code")
  public Code: string = null;
}
