import { inject, ResponseContext } from "fw";
import { DialogService } from "fw-dialog";

import { Unauthorized } from "views/errors/unauthorized";

@inject
export class UnauthorizedMiddleware {
  public loggedIn = false;
  private dialogOpened = false;

  constructor(private dialogService: DialogService) {}

  onResponse(context: ResponseContext) {
    if (this.loggedIn && !this.dialogOpened && context.statusCode == 401) {
      this.dialogOpened = true;
      this.dialogService.open(Unauthorized, null, { cssClass: "confirmation-dialog" });
    }
  }
}
