import { inject, Navigator, Route } from "../fw";
import { AllowCheckRouting } from "shared/allow-check-routing";
import {CurrentOrganizationStore, NavBarNames} from "state/current-organization";
import { CurrentUserStore } from "state/current-user";

interface NavBarConfig {
    [key: string]: NavBarNames;
}

@inject
export class LandingPageOrFirstEnabled {
    constructor(
        private userStore: CurrentUserStore,
        private currentOrganizationStore: CurrentOrganizationStore,
        private nav: Navigator,
    ) {}

    private get customization() {
        return this.currentOrganizationStore.state.customization;
    }

    private navBarConfig: NavBarConfig = {
        "/": NavBarNames.Dashboard,
        "/reports": NavBarNames.Reports,
        "/contacts": NavBarNames.Contacts,
        "/applications": NavBarNames.Applications,
        "/marketing/bulk-messages": NavBarNames.Marketing,
        "/calendar": NavBarNames.Calendar,
        "/tasks": NavBarNames.Tasks,
        "/notifications": NavBarNames.Notifications,
        "/conversations": NavBarNames.Conversations,
    }

    checkIsEnabled(route: string) {
        return this.customization[`${this.navBarConfig[route || "/"]}Enabled`];
    }

    navigating(r: Route, fullRoute: string) {
        const { loggedIn } = this.userStore.state;

        if (!loggedIn) {
            return true;
        }

        const allowCheckRouting = AllowCheckRouting.getInstance();

        // @ts-ignore
        if (allowCheckRouting.isAllow && r.route[0] === "") {
            allowCheckRouting.disable();

            if (this.customization.landingPage) {
                const landingPageRoute = Object.keys(this.navBarConfig)
                    .find(item => this.navBarConfig[item] === this.customization.landingPage);
                this.nav.navigate(landingPageRoute);
                return false;
            }

            // @ts-ignore
            if (!this.checkIsEnabled(r.route[0])) {
                const enabledRoute = Object.keys(this.navBarConfig).find(item => this.checkIsEnabled(item));
                this.nav.navigate(enabledRoute);
                return false;
            }
        }

        allowCheckRouting.disable();

        return true;
    }
}