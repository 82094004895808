import { FormForType, formFor } from "fw-model";

import { ApplicationAttachment } from "models/application";

export type FormForApplicationAttachment = FormForType<ApplicationAttachment>;

export class DeleteApplicationAttachmentAction {
  constructor(public applicationId: string, public attachmentId: string) {}
}

export class AddApplicationAttachmentAction {
  constructor(
    public applicationId: string,
    public form: FormForApplicationAttachment,
  ) {}
}

export const applicationAttachmentFormCreator = formFor(
  ApplicationAttachment,
  s => {
    s.field(u => u.FileId, "File Id");
    s.field(u => u.Title, "Title");
  },
);
