import { inject, NetworkException } from "fw";
import { Store, handle } from "fw-state";
import { LogoutAction, StartAction } from "./actions";
import { MarketingClientRepository } from "network/marketing-client-repository";
import { MarketingClient } from "models/marketing-client";
import { UpdateMarketingClientAction } from "forms/marketing-client";
import { FormErrorHandling } from "./error-handling";

interface ClientShape {
  loaded: boolean;
  client: MarketingClient;
}

export class FetchMarketingClientAction {
  constructor() {}
}

@inject
export class MarketingClientStore extends Store<ClientShape> {
  constructor(private network: MarketingClientRepository) {
    super();
  }

  defaultState() {
    return {
      loaded: false,
      client: null
    };
  }

  @handle(StartAction)
  private async handleStart(action: StartAction) {
    this.setState(state => ({ ...this.defaultState() }));
    /* Note: start action was not designed for network calls. the intent was to load values entirely from the context.
             wrapping network call in a try/catch to prevent subsequent start actions from failing (i.e. marketing not wired-up to run locally)
    */
    try {
      await this.handleFetchMarketingClient(new FetchMarketingClientAction());
    } catch(exception) {
      if (exception instanceof NetworkException && exception.statusCode == 404) {
        console.warn("Marketing client store cannot be started; Status=404NotFound");
      } else {
        console.log("Unable to start marketing client store");
      }
    }
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(state => this.defaultState());
  }

  @handle(FetchMarketingClientAction)
  private async handleFetchMarketingClient(action: FetchMarketingClientAction) {
    if (this.state.client) return;

    const client = await this.network.show();

    this.setState(state => ({ ...state, client }));
  }

  @handle(UpdateMarketingClientAction, FormErrorHandling)
  private async handleUpdateMarketingClient(action: UpdateMarketingClientAction) {
    action.form.validate();

    const client = await this.network.put(action.form.updatedModel());

    this.setState(state => ({ ...state, client }));
  }
}
