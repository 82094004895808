import { prop, inject, ComponentEventBus } from "fw";
import type { IRelationship } from "models/contact";
import { PopoverService } from "service/popover";
import { ContactCardPopover, ContactCardPopoverOptions } from "./contact-card-popover";
import { ContactRepository } from "network/contact-repository";

@inject
export class RelationshipPill {
  @prop(undefined) public value!: IRelationship;
  @prop(false) showRemove!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private contactRepo: ContactRepository,
    private popoverService: PopoverService
  ) { }

  public async openContactCard() {
    const id: string = this.value && this.value.id;
    if (!id) {
      return;
    }

    try {
      const contact = await this.contactRepo.getById(id);
      this.popoverService.open(ContactCardPopover, <ContactCardPopoverOptions>{
        contact: contact
      });
    } catch (ex) { }
  }

  public remove() {
    this.ceb.dispatch("remove", this.value);
  }
}
