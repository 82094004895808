import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer, CeebRequestResult } from "../../models";

import type { CeebRequest } from "../../models";


@inject
export class CeebType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(null) requestCeeb!: CeebRequest;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledBy;


  private outerDiv: HTMLElement = null;

  constructor(private ecb: ComponentEventBus) { }

  async callRequestCeeb(e: MouseEvent) {
    if (this.requestCeeb == null) return;

    try {
      const res = await this.requestCeeb(this.question, e.srcElement || e.target);
      this.outerDiv.focus();
      if (res == null) return;

      this.answer.Data = res;
      this.change();
    } catch(err) { }
  }

  clear() {
    this.answer.Data = null;
    this.change();
  }

  change() {
    this.ecb.dispatch("answer-changed");
  }

  get ceebAddress() {
    if (this.answer == null) return "";

    const addressComponents: string[] = [ this.answer.Data["city"] ];

    const region = this.answer.Data["region"];
    const country = this.answer.Data["country"];

    if (region != null && region.trim().length > 0) addressComponents.push(region);
    if (country != null && country.trim().length > 0) addressComponents.push(country);

    return addressComponents.join(", ");
  }
}
