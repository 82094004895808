import { inject } from "fw";

@inject
export class ModulePopover {
  private router = null;

  constructor() {}

  activate(router) {
    this.router = router;
  }
}
