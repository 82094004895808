import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { StartAction, LogoutAction } from "./actions";
import { ReportResult } from "shared/report-runtime";

import { ReportResultRepository } from "network/report-repository";

export class EnsureReportResultAction {
  constructor(public reportResultId: string) { }
}

export class EnsureListAction {
  constructor(public reportDefId: string, public sort: string = null) { }
}

export class RefreshListAction { }
export class NextPageAction { }
export class PrevPageAction { }

export class ToggleReportResultsSortAction {
  constructor(public sort: string) { }
}

export class CreateResultAction {
    public created: ReportResult = null;
    constructor(public reportDefId: string, public name: string) { }
}

interface ReportResultShape {
  reportDefId: string;
  page: number;
  pageSize: number;
  sort: string;
  total: number;
  currentPage: ReportResult[];
  results: ReportResult[];
  loaded: boolean;
  loading: boolean;
}

@inject
export class ReportResultStore extends Store<ReportResultShape> {
  private seasonId: string = null;

  constructor(private reportResultRepo: ReportResultRepository) {
    super();
  }

  defaultState() {
    return {
      reportDefId: null,
      page: 1,
      pageSize: 10,
      sort: "-DateCreatedUtc",
      total: 0,
      currentPage: [],
      results: [],
      loaded: false,
      loading: false
    };
  }

  @handle(StartAction)
  private handleStartAction(s: StartAction) {
    const { Organization } = s.context;
    this.seasonId = Organization.ActiveSeasonId;
    this.setState(state => this.defaultState());
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.seasonId = null;
    this.setState(state => this.defaultState());
  }

  private async loadPage() {
    const { reportDefId, sort, page, pageSize, results, total } = this.state;
    const start = (page - 1) * pageSize;
    let res;

    if (start < results.length)
        res = { list: results.slice(start, Math.min(start + pageSize, results.length)), total: total };
    else {
        res = await this.reportResultRepo.list(this.seasonId, reportDefId, sort, page, pageSize);
        results.push(...res.list);
    }

    this.setState(state => ({
      ...state,
      currentPage: res.list,
      results: results,
      total: res.total,
      loading: false,
      loaded: true
    }));
  }

  @handle(EnsureListAction)
  private async handleEnsureListAction(action: EnsureListAction) {
    const { reportDefId, sort, loaded } = this.state;

    if (action.reportDefId == reportDefId && action.sort == sort && loaded)
      return;

    this.setState(state => ({
      ...state,
      reportDefId: action.reportDefId,
      sort: action.sort,
      page: 1,
      loading: true,
      loaded: false,
      results: []
    }));

    await this.loadPage();
  }

  @handle(NextPageAction)
  private async handleNextPageAction() {
    this.setState(state => ({
      ...state,
      page: state.page + 1,
      loading: true
    }));

    await this.loadPage();
  }

  @handle(PrevPageAction)
  private async handlePrevPageAction() {
    this.setState(state => ({
      ...state,
      page: state.page - 1,
      loading: true
    }));

    await this.loadPage();
  }

  @handle(RefreshListAction)
  private async handleRefreshListAction(action: RefreshListAction) {
    this.setState(state => ({
      ...state,
      results: [],
      loading: true,
      loaded: false
    }));

    await this.loadPage();
  }

  @handle(ToggleReportResultsSortAction)
  private async handleToggleReportResultsSortAction(ts: ToggleReportResultsSortAction) {
    const newSort = ts.sort == this.state.sort ? `-(${ts.sort})` : ts.sort;
    await dispatch(new EnsureListAction(this.state.reportDefId, newSort));
  }

  @handle(CreateResultAction)
  private async handleCreateResultAction(action: CreateResultAction) {
    const reportResult = await this.reportResultRepo.post(action.reportDefId, action.name);
    const { reportDefId, results, pageSize, page, total } = this.state;

    if (action.reportDefId == reportDefId) {
      results.unshift(reportResult);

      if (results.length > 1 && results.length % pageSize == 1)
        results.pop();

      const start = (page - 1) * pageSize;
      this.setState(state => ({
        ...state,
        results: results,
        total: total + 1,
        currentPage: results.slice(start, Math.min(start + pageSize, results.length))
      }));
    }

    action.created = reportResult;
  }
}
