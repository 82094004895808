import { fromClass, fromClassArray } from "fw-model";
import { CustomProperty } from "./custom-property";

export {
  CustomPropertyEnumerationOption,
  CustomPropertyTypeCode
} from "./custom-property";

export class ProgramSettingsMetaData {
  ManagedRefs: string[];
}

export class ProgramProperty extends CustomProperty {   
  IsPublic: boolean = false;
}

export class ProgramSettings {
  @fromClassArray(ProgramProperty) ProgramProperties: ProgramProperty[];
  @fromClass MetaData: ProgramSettingsMetaData;
}
