import { inject } from "fw";
import { PopoverController } from "service/popover";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { ContactTypeDefinition } from 'models/contact-organization';

export interface ChartContactTypeSelectorOptions {
  selectedContactType?: ContactTypeDefinition;
  updateOptionFocus?: Function;
}

@inject
export class ChartContactTypeSelectorPopover {
  public contact_types: ContactTypeDefinition[] = [];
  private searchTerm = "";
  public selectedContactType: ContactTypeDefinition = null;
  public updateOptionFocus: Function = () => {};

  constructor(
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private controller: PopoverController<ContactTypeDefinition>
  ) { }

  public activate(options: ChartContactTypeSelectorOptions) {
    this.contact_types = this.currentContactOrganizationStore.state.organization.contact_types?.sort((a, b) => a.plural_name.localeCompare(b.plural_name));
    this.selectedContactType = options?.selectedContactType;
    if (options?.updateOptionFocus) {
      this.updateOptionFocus = options?.updateOptionFocus;
    }
    if (this.selectedContactType) {
      this.updateOptionFocus(this.makeOptionId(this.selectedContactType));
    }
  }

  public makeOptionId(field: ContactTypeDefinition): string {
    const key = field?.key?.replace(/\./g, "");
    return !!key ? `${key}-ccts` : null;
  }

  private choose(contactType: ContactTypeDefinition) {
    this.selectedContactType = contactType;
    if (this.selectedContactType) {
      this.updateOptionFocus(this.makeOptionId(this.selectedContactType));
    }
    this.controller.ok(contactType);
  }


  public get search() {
    if (this.searchTerm.length) {
      return this.contact_types.filter(f => f.plural_name ? f.plural_name.toLowerCase().includes(this.searchTerm.toLowerCase()) : false);
    } else {
      return this.contact_types;
    }
  }
}
