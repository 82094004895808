import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import {
  StartAction,
  LogoutAction,
  ApplyFeatureFlagsAction,
  SwitchOrganizationAction
} from "./actions";

import { FeatureFlagSet } from "models/feature-flag-set";
import { FeatureFlagsRepository } from "network/feature-flags-repository";
import { wait } from "wait";

interface FeatureFlagsShape {
  refreshing: boolean;
  defaults: FeatureFlagSet;
  organization: FeatureFlagSet;
  organizationOverrides: FeatureFlagSet;
}

export class RefreshFlagsAction {
  constructor(public orgId: string = null) {}
}

export class UpdateDefaultsAction {
  constructor(public changeSet: FeatureFlagSet) {}
}

export class UpdateOrganizationFlagsAction {
  constructor(public orgId: string, public changeSet: FeatureFlagSet) {}
}

@inject
export class FeatureFlagsStore extends Store<FeatureFlagsShape> {
  constructor(private featureFlagsRepository: FeatureFlagsRepository) {
    super();
  }

  defaultState() {
    return {
      refreshing: false,
      defaults: null,
      organization: null,
      organizationOverrides: null
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    this.setState(state => ({
      ...state,
      organization: s.context.FeatureFlags
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(RefreshFlagsAction)
  private async handleRefreshFlagsAction(action: RefreshFlagsAction) {
    if (this.state.refreshing) return;

    this.setState(state => ({
      ...state,
      refreshing: true
    }));

    const defaults = await this.featureFlagsRepository.getDefaults();

    if (action.orgId != null) {
      const [organization, organizationOverrides] = await Promise.all([
        this.featureFlagsRepository.getOrgs(action.orgId),
        this.featureFlagsRepository.getOrgs(action.orgId, true)
      ]);

      this.setState(state => ({
        ...state,
        defaults,
        organization,
        organizationOverrides,
        refreshing: false
      }));
    } else {
      this.setState(state => ({
        ...state,
        defaults,
        refreshing: false
      }));
    }
  }

  @handle(UpdateDefaultsAction)
  private async handleUpdateDefaultsAction(action: UpdateDefaultsAction) {
    const defaults = await this.featureFlagsRepository.putDefaults(
      action.changeSet
    );

    this.setState(state => ({
      ...state,
      defaults
    }));
  }

  @handle(UpdateOrganizationFlagsAction)
  private async handleUpdateOrganizationFlagsAction(
    action: UpdateOrganizationFlagsAction
  ) {
    const organization = await this.featureFlagsRepository.putOrgs(
      action.orgId,
      action.changeSet
    );

    await dispatch(new SwitchOrganizationAction(action.orgId));

    await wait(2000);
    await dispatch(new RefreshFlagsAction(action.orgId));
    dispatch(new ApplyFeatureFlagsAction(this.state.organization));
  }
}
