import { inject, prop, needs } from "fw";

import { ApplicationProperty } from "models/application-settings";
import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { ApplicationPropertyFilter as ApplicationPropertyFilterModel } from "models/application-filters";
import { ApplicationSettingsStore } from "state/application-settings";
import { DataPolicyService } from "service/data-policy";
import { ValueField, ValueType } from "./value-field";
import { FeatureFlagService } from "service/feature-flag";
import { getFriendlyTiming } from "models/date-filter";

@inject
@needs(ValueField)
export class ApplicationPropertyFilter {
  @prop(null) filter!: ApplicationPropertyFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;
  @prop("left") opens: "center" | "left" | "right" | "inline";

  private fields: { text: string, value: string }[] = [];
  private fieldHash: { [key: string]: ApplicationProperty } = {};

  private valueTypes = ValueType;
  private valueType = ValueType.Text;

  private enumOptions: { text: string, value: string }[] = [];

  constructor(
    private appSettingsStore: ApplicationSettingsStore,
    private dataPolicy: DataPolicyService,
    private ffs: FeatureFlagService
  ) {
    this.setupFields();
  }

  attached() {
    this.fieldChanged(false);
  }

  private setupFields() {
    const newHash: { [key: string]: ApplicationProperty } = {};

    this.fields = [];
    const restrictions = this.dataPolicy.applicationPropertyPaths;
    for (const property of this.appSettingsStore.state.applicationSettings.ApplicationProperties) {
      if (restrictions.indexOf(property.Key) !== -1)
        continue;

      newHash[property.Key] = property;
      this.fields.push({ text: property.Label, value: property.Key });
    }

    this.fieldHash = newHash;
  }

  private fieldChanged(clearValue = true) {
    if (clearValue) {
      this.filter.timing = null;
      this.filter.value = null;
      this.filter.operator = "=";
    }

    if (this.filter.field == null) {
      this.valueType = ValueType.Text;
      return;
    }

    //  prevent removed properties and restrictions from blowing up the client
    if (!this.fieldHash[this.filter.field]) {
      return;
    }

    switch (this.fieldHash[this.filter.field].DataType) {
      case CalculatedFieldDataTypeCode.Number:
      case CalculatedFieldDataTypeCode.WeightedScore:
        this.filter.shouldQuoteValue = false;
        this.valueType = ValueType.Number;
        break;

      case CalculatedFieldDataTypeCode.Enumeration:
        this.filter.shouldQuoteValue = true;
        this.enumOptions = this.fieldHash[this.filter.field].EnumerationOptions.map(eo => ({ text: eo.Label, value: eo.Label }));
        if (this.useDropdownFilterAsKeyword) {
          if (this.filter.operator == "=")
            this.filter.operator = "==";
        }          
        
        this.valueType = ValueType.Dropdown;
        break;

      case CalculatedFieldDataTypeCode.Boolean:
        this.filter.shouldQuoteValue = false;
        this.enumOptions = [
          { text: "Yes", value: "true" },
          { text: "No", value: "false" },
        ];
        this.valueType = ValueType.Boolean;
        break;

      case CalculatedFieldDataTypeCode.File:
        this.filter.shouldQuoteValue = false;
        this.valueType = ValueType.AnsweredOnly;
        break;

      case CalculatedFieldDataTypeCode.Date:
        this.filter.shouldQuoteValue = false;
        this.filter.timing = this.filter.since ? this.filter.since : this.filter.timing;
        this.valueType = ValueType.Date;
        break;

      case CalculatedFieldDataTypeCode.String:
        this.filter.shouldQuoteValue = true;
        this.valueType = ValueType.Text;
        break;

      default:
        this.filter.shouldQuoteValue = false;
        this.valueType = ValueType.Text;
        break;
    }
  }

  get filterText() {
    if (this.isDate) {
      return getFriendlyTiming(this.filter);
    }
    
    switch (this.filter.operator) {
      case "~": return "is answered.";
      case "!~": return "is not answered.";
      case "==": return `= ${this.filter.value}`;
      case "!==": return `!= ${this.filter.value}`;
    }

    return `${this.filter.operator} ${this.filter.value}`;
  }

  get isDate() {
    return this.valueType == ValueType.Date;
  }

  get useDropdownFilterAsKeyword() {
    return this.ffs.isFeatureFlagEnabled("DropdownFilterAsKeyword8366");
  }
}
