import { inject, prop } from "fw";

import { RawFilter as RawFilterModel } from "models/filter-setup";

@inject
export class RawFilter {
  @prop(null)
  filter: RawFilterModel;

  @prop(() => ({})) validation;

  @prop(false)
  editing: boolean;
}
