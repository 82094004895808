import { inject } from "fw";
import { createFrom, createFromArray, cloneOf } from "fw-model";

import { ATS } from "./ats";
import { encodeApplicantPortal } from "./encode";

import { ApplicantPortal } from "models/applicant-portal";

@inject
export class ApplicantPortalRepository {
  constructor(private s: ATS) {}

  public async get(id: string) {
    const res = await this.s.get(`applicant-portal/${id}`);
    return createFrom(ApplicantPortal, res.body);
  }

  public async list() {
    const res = await this.s.get<ApplicantPortal[]>("applicant-portal");
    return createFromArray(ApplicantPortal, res.body);
  }

  public async delete(id: string) {
    await this.s.delete(`applicant-portal/${id}`);
  }

  public async post(portal: ApplicantPortal) {
    const res = await this.s.post("applicant-portal", encodeApplicantPortal(portal));
    return createFrom(ApplicantPortal, res.body);
  }

  public async put(portal: ApplicantPortal) {
    const res = await this.s.put<ApplicantPortal>(
      `applicant-portal/${portal.Id}`,
      encodeApplicantPortal(portal),
    );
    return createFrom(ApplicantPortal, res.body);
  }
}
