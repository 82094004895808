import { prop, inject } from "fw";
import { dispatch } from "fw-state";
import { EnsureUsersAction, UsersStore } from "state/users";

@inject
export class LazyUserNames {
  @prop(null) ids!: string[];
  @prop("Deleted User") missingText!: string;

  constructor(
    private userStore: UsersStore,
  ) { }

  get names() {
    if (this.ids == null) return null;

    const { userHash } = this.userStore.state;
    const haveAll = this.ids.every(i => userHash[i] != null);
    if (!haveAll) {
      dispatch(new EnsureUsersAction(this.ids));
      return null;
    }

    return this.ids.map(i => !userHash[i]?.Id ? this.missingText : `${userHash[i].FirstName} ${userHash[i].LastName}`).join(", ");
  }
}
