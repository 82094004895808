import { inject } from "fw";
import { orderBy } from "lodash-es";

import {
  ContactTypeDefinition,
  CustomFieldType,
  isObject,
  isPathExcluded,
} from "models/contact-organization";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { Category, PathAndLabel } from "views/components/path-chooser";
import { FeatureFlagService } from "./feature-flag";

@inject
export class ContactsClientFieldPaths {
  constructor(
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private ffs: FeatureFlagService,
  ) { }

  private get contactTypes() {
    return this.currentContactOrganizationStore.state.organization.contact_types;
  }

  private get fields() {
    return this.currentContactOrganizationStore.state.organization.fields;
  }

  public getCategoryByContactType(contactType: ContactTypeDefinition): Category {
    const category: Category = { category: contactType.plural_name, paths: [] };
    if (contactType.use_full_name) {
      category.paths.push({ Label: "Name", Path: "companyName", Sort: "company", Type: "String" });
    } else {
      category.paths.push({ Label: "First Name", Path: "firstName", Sort: "first", Type: "String" });
      category.paths.push({ Label: "Last Name", Path: "lastName", Sort: "last", Type: "String" });
      category.paths.push({ Label: "Full Name", Path: "displayName", Sort: "last first", Type: "String" });
    }
    category.paths.push({ Label: "Email", Path: "emailAddress", Sort: "email", Type: "String" });
    category.paths.push({ Label: "Tags", Path: "tags", Sort: "tags", Type: "Object" });
    category.paths.push({ Label: "Type", Path: "type", Sort: "type", Type: "String" });
    category.paths.push({ Label: "Engagement Score", Path: "engagementScore", Sort: "score", Type: "String" });
    
    if (this.currentContactOrganizationStore.state.organization.enable_contact_number_sequencing) {
      category.paths.push({ Label: "Contact Number", Path: "contactNumber", Sort: "contactNumber", Type: "String" });     
    }

    category.paths.push(...this.getFieldsByContactType(contactType));
    return category;
  }

  public getFieldsByCategory(): Category[] {
    const all: Category = {
      category: "All Contacts",
      paths: [
        { Label: "Email", Path: "emailAddress", Sort: "email", Type: "String" },
        { Label: "Tags", Path: "tags", Sort: "tags", Type: "Object" },
        { Label: "Type", Path: "type", Sort: "type", Type: "String" },
        { Label: "Engagement Score", Path: "engagementScore", Sort: "score", Type: "String" }
      ]
    };

    if (this.currentContactOrganizationStore.state.organization.enable_contact_number_sequencing){
      all.paths.push({ Label: "Contact Number", Path: "contactNumber", Sort: "contactNumber", Type: "String" });     
    }

    if (this.contactTypes.findIndex(t => !t.use_full_name) != -1) {
      all.paths.push({ Label: "First Name", Path: "firstName", Sort: "first", Type: "String" });
      all.paths.push({ Label: "Last Name", Path: "lastName", Sort: "last", Type: "String" });
      all.paths.push({ Label: "Full Name", Path: "displayName", Sort: "last first", Type: "String" });
    }

    if (this.contactTypes.findIndex(t => t.use_full_name) != -1) {
      all.paths.push({ Label: "Entity Name", Path: "companyName", Sort: "company", Type: "String" });
    }

    let categories: Category[] = [all];
    this.contactTypes.forEach(
      ct => {
        const paths: PathAndLabel[] = this.getFieldsByContactType(ct);
        if (paths && paths.length > 0) {
          categories.push({
            category: ct.plural_name,
            paths: paths
          });
        }
      }
    );

    return orderBy(categories, [c => c.category?.toLowerCase()]);
  }

  public getFieldsByContactTypeKey(contactTypeKey: string): PathAndLabel[] {
    const contactType = this.contactTypes.find(c => c.key === contactTypeKey);
    if (!contactType)
      return [];
    return this.getFieldsByContactType(contactType);
  }

  private getFieldsByContactType(contactType: ContactTypeDefinition): PathAndLabel[] {
    let paths: PathAndLabel[] = [];
    this.fields.forEach(f => {
      if (!f.is_system_field && contactType.key == f.contact_type) {
        switch (f.type) {
          case CustomFieldType.address:
            paths.push(
              {
                Label: f.display_name,
                Path: f.name,
                Sort: `${f.search_field}.country ${f.search_field
                  }.state ${f.search_field}.city ${f.search_field
                  }.postal_code`,
                Type: "Object",
              },
              {
                Label: `${f.display_name} - Address 1`,
                Path: `${f.name}.address1`,
                Sort: `${f.search_field}.address1`,
                Type: "String",
              },
              {
                Label: `${f.display_name} - Address 2`,
                Path: `${f.name}.address2`,
                Sort: `${f.search_field}.address2`,
                Type: "String",
              },
              {
                Label: `${f.display_name} - City`,
                Path: `${f.name}.city`,
                Sort: `${f.search_field}.city`,
                Type: "String",
              },
              {
                Label: `${f.display_name} - State/Region`,
                Path: `${f.name}.state`,
                Sort: `${f.search_field}.state`,
                Type: "String",
              },
              {
                Label: `${f.display_name} - Postal Code`,
                Path: `${f.name}.postalCode`,
                Sort: `${f.search_field}.postal_code`,
                Type: "String",
              },
              {
                Label: `${f.display_name} - Country`,
                Path: `${f.name}.country`,
                Sort: `${f.search_field}.country`,
                Type: "String",
              }
            );

            break;

          case CustomFieldType.link:
            paths.push(
              {
                Label: f.display_name,
                Path: f.name,
                Sort: `${f.search_field}.name ${f.search_field
                  }.url`,
                Type: "Object",
              },
              {
                Label: `${f.display_name} Name`,
                Path: `${f.name}.name`,
                Sort: `${f.search_field}.name`,
                Type: "String"
              },
              {
                Label: `${f.display_name} Url`,
                Path: `${f.name}.url`,
                Sort: `${f.search_field}.url`,
                Type: "String"
              }
            );

            break;

          case CustomFieldType.user:
            paths.push(
              {
                Label: f.display_name,
                Path: f.name,
                Sort: null,
                Type: "Object",
              },
              {
                Label: `${f.display_name} - Id`,
                Path: `${f.name}.id`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Email Address`,
                Path: `${f.name}.emailAddress`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - First Name`,
                Path: `${f.name}.firstName`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Last Name`,
                Path: `${f.name}.lastName`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Title`,
                Path: `${f.name}.title`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Department`,
                Path: `${f.name}.department`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Bio`,
                Path: `${f.name}.bio`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Office Phone`,
                Path: `${f.name}.officePhone`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Mobile Phone`,
                Path: `${f.name}.mobilePhone`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Address`,
                Path: `${f.name}.address`,
                Sort: null,
                Type: "Object"
              },
              {
                Label: `${f.display_name} - Address Line 1`,
                Path: `${f.name}.address.address1`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Address Line 2`,
                Path: `${f.name}.address.address2`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - City`,
                Path: `${f.name}.address.city`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Region`,
                Path: `${f.name}.address.region`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Postal Code`,
                Path: `${f.name}.address.postalCode`,
                Sort: null,
                Type: "String"
              },
              {
                Label: `${f.display_name} - Country`,
                Path: `${f.name}.address.country`,
                Sort: null,
                Type: "String"
              }
            );

            break;

          case CustomFieldType.date:
            paths.push({
              Label: f.display_name,
              Path: f.name,
              Sort: f.search_field,
              Type: "Date",
            });
            break;

          case CustomFieldType.attachment:
          case CustomFieldType.coursework:
            if (this.ffs.isFeatureFlagEnabled("ExportContactAttachments")) {
              paths.push(
              {
                Label: f.display_name,
                Path: f.name,
                Sort: f.search_field,
                Type: "Object"
              });
            }
            break;

          default:
            // excluded types (for now... maybe always)
            if (isPathExcluded(f.type))
              break;
            
            if (isObject(f.type)) {
              paths.push({
                Label: f.display_name,
                Path: f.name,
                Type: "Object",
              });

              break;
            }
            

            paths.push({
              Label: f.display_name,
              Path: f.name,
              Sort: f.search_field,
              Type: "String",
            });

            break;
        }
      }
    });

    return paths;
  }

  public fillMissingContactClientModelProperties(contactClone: unknown) {
    for(const field of this.fields) {
      if (field.is_system_field)
        continue;

      switch (field.type) {
        case CustomFieldType.multiselect:
        case CustomFieldType.tags:
        case CustomFieldType.relationship:
          const { [field.name]: multiselectValue = [] } = contactClone as any;
          contactClone[field.name] = multiselectValue;
          break;

        case CustomFieldType.address:
          const { [field.name]: { address1 = null, address2 = null, city = null, state = null, postalCode = null, country = null } = {} } = contactClone as any;
          contactClone[field.name] = { address1, address2, city, state, postalCode, country, ...contactClone[field.name] };
          break;

        case CustomFieldType.link:
          const { [field.name]: { name: friendlyName = null, url = null } = {} } = contactClone as any;
          contactClone[field.name] = { name: friendlyName, url, ...contactClone[field.name] };
          break;

        default:
          const { [field.name]: value = null } = contactClone as any;
          contactClone[field.name] = value;
          break;
      }
    }

    const { 
      firstName = null, 
      lastName = null,
      displayName = null,
      companyName = null,
      emailAddress = null,
      tags = [],
      engagementScore = null,
      ...customFields
    } = contactClone as any;

    return {
      firstName, lastName, displayName, companyName, emailAddress, tags, engagementScore, ...customFields
    };
  }
}
