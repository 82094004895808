import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { ExportDefinition } from "models/export-definition";

@inject
export class ExportDefinitionRepository {
  constructor(private s: ATS) { }

  async list() {
    const res = await this.s.get<any[]>("export-definition");
    return createFromArray(ExportDefinition, res.body);
  }

  async post(def: ExportDefinition, ownerId: string = null) {
    const res = await this.s.post("export-definition", def, { ownerId });
    return createFrom(ExportDefinition, res.body);
  }

  async put(def: ExportDefinition, newOwnerId: string = null) {
    const res = await this.s.put(`export-definition/${def.Id}`, def, { newOwnerId });
    return createFrom(ExportDefinition, res.body);
  }

  public async del(ids: string[]) {
    await this.s.post("export-definition/delete", ids);
  }
}
