import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { Store, dispatch, handle } from "fw-state";
import { LocalStorageCache } from "caching";
import { debounce, join } from "lodash-es";

import {
  LogoutAction,
  StartAction,
} from "./actions";

import { PaymentDetails } from "models/payments";

import { PaymentJournalRepository } from "network/payment-journal-repository";
import { GridColumn } from "models/grid-column";


export interface PaymentTransactionClientModel {
  invoiceId: string;
  applicationId: string;
  applicationStepGroupId: string;
  success: boolean;
  familyName: string;
  givenName: string;
  amount: number;
  transactionId: string;
}

export interface PaymentTransaction {
  id: string;
  clientModel: PaymentTransactionClientModel;
  stale: boolean;
}

interface PaymentJournalStoreState {
  organizationId: string;
  userId: string;
  firstPageLoaded: boolean;
  loaded: boolean;
  errorLoading: boolean;

  filter: string;
  additionalFilter: string;
  sort: string;
  payments: PaymentTransaction[];
  payment: PaymentDetails;

  total: number;
  previousPageToken: string;
  nextPageToken: string;
  currentPreviousPageToken: string;
  currentNextPageToken: string;
  pageSize: number;
  pageNumber: number;

  hasErrors: boolean;
}

export class EnsureIdsAction {
  constructor() { }
}

export class LoadPaymentDetailsAction {
  constructor(public id: string) { }
}

export class SetAddressPathAction {
  constructor(public path: string) { }
}

export class NextPageAction { }
export class PreviousPageAction { }

export class SetFilterAction {
  // clear others will set other surrounding state
  // to null so the filter container can stand alone
  constructor(public filter: string, public clearOthers = false) { }
}

export class SetAdditionalFilterAction {
  constructor(public additionalFilter: string) { }
}

export class ForceFullRefreshGridAction {
  constructor() { }
}

export class RefreshGridAction {
  constructor(public ids: string[]) { }
}

export class ToggleSortAction {
  constructor(public sort: string) { }
}

const DEFAULT_PAGE_SIZE = 20;

type SavedState = {
  filter: string;
  additionalFilter: string;
  sort: string;
  pageNumber: number;
  pageSize: number;
  previousPageToken: string;
  nextPageToken: string;
  currentPreviousPageToken: string;
  currentNextPageToken: string;
};

@inject
export class PaymentJournalStore extends Store<PaymentJournalStoreState> {
  private debouncedRefreshCurrentPageData = async () => {};

  public DEFAULT_COLUMNS = createFromArray(GridColumn, [
    {
      Label: "Applicant Name",
      Path: "familyName,givenName,applicationId",
      Sort: "familyName givenName"
    },
    // {
    //   Label: "Application Public ID",
    //   Path: "metaData.applicationPublicId",
    //   Sort: "metaData.applicationPublicId"
    // },
    {
      Label: "Program",
      Path: "metaData.program",
      Sort: "metaData.program"
    },
    {
      Label: "Stage",
      Path: "metaData.stage",
      Sort: "metaData.stage"
    },
    {
      Label: "Step Group",
      Path: "metaData.programStepGroupName",
      Sort: "metaData.programStepGroupName"
    },
    {
      Label: "Invoice Number",
      Path: "invoiceNumber",
      Sort: "invoiceNumber"
    },
    {
      Label: "Transaction",
      Path: "transactionNumber",
      Sort: "transactionNumber"
    },
    {
      Label: "Amount",
      Path: "amount",
      Sort: "amount"
    },
    {
      Label: "Success",
      Path: "success",
      Sort: "success"
    },
    {
      Label: "Time",
      Path: "metaData.dateCreatedUtc",
      Sort: "metaData.dateCreatedUtc"
    }
  ]);

  constructor(
    private paymentJournalRepo: PaymentJournalRepository,
    private cache: LocalStorageCache,
  ) {
    super();
    this.debouncedRefreshCurrentPageData = debounce(() => this.refreshCurrentPageData(), 2000, { maxWait: 5000 });
  }

  defaultState() {
    return {
      organizationId: null,
      userId: null,
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      total: 0,
      firstPageLoaded: false,
      loaded: false,
      errorLoading: false,
      payments: [],
      payment: new PaymentDetails(),
      filter: "",
      additionalFilter: "",
      sort: "",
      hasErrors: false,
      paymentJournalIds: []
    };
  }

  public async generateExport() {
    const response =  await this.paymentJournalRepo.export(this.getWholeFilter());

    let blob = new Blob([window.atob(response.body)], {
        type: 'text/csv'
    });
    return blob;
  }

  private saveState() {
    if (this.state.organizationId == null) {
      return;
    }

    this.cache.set<SavedState>(
      `${this.state.organizationId}:${this.state.userId}-payment-journal-store`,
      {
        filter: this.state.filter,
        additionalFilter: this.state.additionalFilter,
        sort: this.state.sort,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        previousPageToken: this.state.previousPageToken,
        nextPageToken: this.state.nextPageToken,
        currentPreviousPageToken: this.state.currentPreviousPageToken,
        currentNextPageToken: this.state.currentNextPageToken,
      },
    );
  }

  private restoreState() {
    if (this.state.organizationId == null) {
      return;
    }

    const savedState = this.cache.get<SavedState>(`${this.state.organizationId}:${this.state.userId}-payment-journal-store`);
    if (savedState == null) {
      return;
    }

    this.setState(state => ({
      ...state,
      filter: savedState.filter,
      additionalFilter: savedState.additionalFilter,
      sort: savedState.sort,
      pageNumber: savedState.pageNumber || 1,
      pageSize: savedState.pageSize || DEFAULT_PAGE_SIZE,
      previousPageToken: savedState.previousPageToken,
      nextPageToken: savedState.nextPageToken,
      currentPreviousPageToken: savedState.currentPreviousPageToken,
      currentNextPageToken: savedState.currentNextPageToken,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    const defaultState = this.defaultState();
    defaultState.organizationId = s.context.Organization.Id;
    defaultState.userId = s.context.Me.Id;

    this.setState(s => defaultState);
    this.restoreState();
  }

  @handle(EnsureIdsAction)
  private async handleEnsureIds(action: EnsureIdsAction) {
    this.saveState();
    if (this.state.loaded || this.state.firstPageLoaded) {
      return;
    }

    await this.loadStoreData();
  }

  @handle(LoadPaymentDetailsAction)
  private async handleLoadPaymentDetails(action: LoadPaymentDetailsAction) {
    this.saveState();

    await this.loadPaymentDetails(action.id);
  }

  @handle(SetFilterAction)
  private async handleSetFilter(s: SetFilterAction) {
    this.setState(state => ({
      ...state,
      firstPageLoaded: false,
      loaded: false,
      errorLoading: false,
      pageNumber: 1,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      singleProgramId: null,
      filter: s.filter,

      additionalFilter: s.clearOthers ? "" : state.additionalFilter,
    }));

    this.saveState();
    await this.loadStoreData();
  }

  @handle(SetAdditionalFilterAction)
  private async handleSetAdditionalFilter(s: SetAdditionalFilterAction) {
    this.setState(state => ({
      ...state,
      firstPageLoaded: false,
      loaded: false,
      errorLoading: false,
      pageNumber: 1,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      singleProgramId: null,
      additionalFilter: s.additionalFilter
    }));

    this.saveState();

    await this.loadStoreData();
  }

  @handle(NextPageAction)
  private async handleNextPage(s: NextPageAction) {
    const { nextPageToken, pageSize, pageNumber, total } = this.state;

    if (pageNumber * pageSize > total) {
      return;
    }

    this.setState(state => ({
      ...state,
      loaded: false,
      pageNumber: pageNumber + 1
    }));

    this.saveState();
    await this.loadPageData(null, nextPageToken);
  }

  @handle(PreviousPageAction)
  private async handlePreviousPage(s: PreviousPageAction) {
    const { pageSize, previousPageToken, pageNumber, total } = this.state;
    if (pageNumber <= 1) {
      return;
    }

    this.setState(state => ({
      ...state,
      loaded: false,
      pageNumber: pageNumber - 1
    }));

    this.saveState();
    await this.loadPageData(previousPageToken, null);
  }

  @handle(ToggleSortAction)
  private async handleToggleSort(ts: ToggleSortAction) {
    if (ts.sort == this.state.sort) {
      this.setState(state => ({ ...state, sort: `-(${ts.sort})` }));
    } else {
      this.setState(state => ({ ...state, sort: ts.sort }));
    }

    this.saveState();
    await dispatch(new SetFilterAction(this.state.filter));
  }

  private async loadStoreData() {
    const { currentPreviousPageToken, currentNextPageToken, pageSize, sort } = this.state;
    try {
      const clientModelResponse = await this.paymentJournalRepo.clientModelSearch(this.getWholeFilter(), sort, currentPreviousPageToken, currentNextPageToken, this.getClientModelFields(), pageSize);
      this.setState(state => ({
        ...state,
        firstPageLoaded: true,
        loaded: true,
        total: clientModelResponse.Total,
        previousPageToken: clientModelResponse.PreviousPageToken,
        nextPageToken: clientModelResponse.NextPageToken,
        payments: clientModelResponse.Results.map(cm => <PaymentTransaction>{ id: cm.id, clientModel: cm, stale: false })
      }));

    } catch (err) {
      this.setState(state => ({ ...state, errorLoading: true }));
    }
  }

  private async loadPaymentDetails(id: string) {
    try {
      const clientModelResponse: PaymentDetails = await this.paymentJournalRepo.getPaymentDetails(id);
      this.setState(state => ({
        ...state,
        payment: clientModelResponse
      }));

    } catch (err) {
      this.setState(state => ({ ...state, errorLoading: true }));
    }
  }

  private async loadPageData(previousPageToken: string, nextPageToken: string) {
    const { pageSize, sort } = this.state;
    const clientModelResponse = await this.paymentJournalRepo.clientModelSearch(this.getWholeFilter(), sort, previousPageToken, nextPageToken, this.getClientModelFields(), pageSize);
    this.setState(state => ({
      ...state,
      loaded: true,
      previousPageToken: clientModelResponse.PreviousPageToken,
      nextPageToken: clientModelResponse.NextPageToken,
      currentPreviousPageToken: previousPageToken,
      currentNextPageToken: nextPageToken,
      payments: clientModelResponse.Results.map(cm => <PaymentTransaction>{ id: cm.id, clientModel: cm, stale: false })
    }));
  }

  @handle(ForceFullRefreshGridAction)
  private async handleForceFullRefreshGridAction(action: ForceFullRefreshGridAction) {
    await dispatch(new SetFilterAction(this.state.filter));
  }

  @handle(RefreshGridAction)
  private async handleRefreshGrid(action: RefreshGridAction) {
    const { payments, loaded } = this.state;
    if (!loaded) {
      return;
    }

    if (action.ids?.length > 0) {
      const resolvedPayments = payments.filter(app => action.ids.some(id => app.id === id));
      if (resolvedPayments.length === 0) {
        return;
      }
    }

    await this.debouncedRefreshCurrentPageData();
  }

  private async refreshCurrentPageData(): Promise<void> {
    const { payments, loaded, sort } = this.state;
    if (!loaded) {
      return;
    }

    let filter: string = `${this.getWholeFilter()} (id:${payments.map(a => a.id).join(" OR id:")})`;
    let clientModelResponse = await this.paymentJournalRepo.clientModelSearch(filter, sort, null, null, this.getClientModelFields());
    for (const payment of payments) {
      const model = clientModelResponse.Results?.find(cm => cm.id === payment.id);
      if (model) {
        Object.assign(payment.clientModel, model);
      }
    }

    this.setState(state => state);
  }

  private getClientModelFields(): string {
    const columnMask = join(this.DEFAULT_COLUMNS.map(x => x.Path), ',');
    return `PreviousPageToken,NextPageToken,Total,Results(id,${columnMask})`;
  }

  private getWholeFilter() {
    let filter = this.state.filter;

    if (this.state.additionalFilter.trim().length > 0) {
      if (filter.length > 0)
        filter = `(${filter}) AND (${this.state.additionalFilter.trim()})`;
      else filter = this.state.additionalFilter.trim();
    }
    return filter;
  }
}
