import { inject, prop, ComponentEventBus, needs } from "fw";
import { DialogService } from "fw-dialog";

import {
  FormForReportChartDefinition,
  reportChartDefinitionFormCreator,
  FormForWidgetDefinition,
  widgetDefinitionFormCreator,
  FormForFieldGroup,
  fieldGroupFormCreator,
  FormForGridViewDefinition,
  gridColumnFormCreator,
} from "forms/report-definition";
import {
  ColumnChooser,
  ColumnChooserArgs,
  ColumnChooserResult
} from "views/components/column-chooser";
import { TasksFieldPaths } from "service/tasks-field-paths";

import {
  ReportChartDefinitionClientData,
  WidgetData,
  WidgetDefinition,
  ChartDataSource,
  isReportChartDefinition
} from "shared/report-runtime";
import { Command } from "./chart-options/chart-settings";
import { ChartFilter, ChartFilterArgs } from "./chart-filter";
import { GroupFilter } from "models/filter-setup";
import { AppRepository } from "network/app-repository";
import { AddTileDialog } from "./add-tile-dialog";
import { ReportChart } from "./report-chart";

import { PopoverService } from "service/popover";
import { FeatureFlagService } from "service/feature-flag";
import { ColumnSpanGroupCommand, ColumnSpanGroupOptions, IColumnSpanGroupOptions } from "views/settings/components/column-span-group-options";
import { AddColumn } from "views/components/add-column";
import { DraggableItem } from "views/components/draggable-item";

export type FieldGroupAndWidgetData = {
  fieldGroupForm: FormForFieldGroup;
  list: WidgetFormAndWidgetData[];
}

export type WidgetFormAndWidgetData = {
  data: WidgetData;
  dataLoading: boolean;
  widgetForm: FormForWidgetDefinition;
};

@inject
@needs(
  ReportChart,
  AddColumn,
  DraggableItem,
)
export class ChartsEditor {
  @prop(null) charts: WidgetFormAndWidgetData[];
  @prop(null) fieldGroups: FieldGroupAndWidgetData[];
  @prop(false) showAdd: boolean;
  @prop(false) isIndexing: boolean;
  @prop(null) reportFilter: string;
  @prop(true) sorting: boolean;
  public selectedGroupIndex = -1;
  public selectedSubIndex = -1;

  constructor(
    private dialogService: DialogService,
    private appRepo: AppRepository,
    private ceb: ComponentEventBus,
    private popover: PopoverService,
    private tasksFieldPaths: TasksFieldPaths,
    private ffs: FeatureFlagService,
  ) { }

  attached() {
    this.loadCharts(true);
  }

  loadCharts(init: boolean = false) {
    if (this.fieldGroups == null) return;

    const chartsWithNoData: WidgetFormAndWidgetData[] = [];
    for (const fg of this.fieldGroups) {
      const data = (init
        ? fg.list.filter(c => c.data == null)
        : fg.list);
      chartsWithNoData.push(...data);
    }

    this.chartChanged(...chartsWithNoData);
  }

  get fieldGroupsProxy() {
    return this.fieldGroups;
  }

  set fieldGroupsProxy(val) {
    this.ceb.dispatch("update:fieldGroups", val);
  }

  public isIndexingChanged() {
    if(!this.isIndexing)
      this.loadCharts();
  }

  async addChart(group?: FieldGroupAndWidgetData) {
    const res = await this.dialogService.open<WidgetDefinition>(
      AddTileDialog,
      null,
      {
        cssClass: "confirmation-dialog-wide",
        closeOnClick: true,
      },
    );
    if (res.canceled) return;

    const newChart = {
      widgetForm: widgetDefinitionFormCreator(res.result),
      data: null,
      dataLoading: false
    };

    if (group) {
      group.list.push(newChart);
    } else {
      this.chartsProxy = [...this.charts, newChart];
    }

    if (isReportChartDefinition(newChart.widgetForm)) {
      if (
        (newChart.widgetForm.PrimaryIndependentVariable != null &&
          newChart.widgetForm.PrimaryIndependentVariable.Path != null)
      ) {
        this.chartChanged(newChart);
      }
    } else {
      this.chartChanged(newChart);
    }
  }

  get chartsProxy() {
    return this.charts;
  }

  set chartsProxy(val) {
    this.ceb.dispatch("update:charts", val);
  }

  reportFilterChanged() {
    if (this.charts == null) return;
    this.chartChanged(...this.charts);
  }

  async chartColumnCommand(group: FieldGroupAndWidgetData, data: {
    chart: FormForReportChartDefinition;
    command: Command;
  }) {
    const cad = group.list.find(d => d.widgetForm == data.chart);
    if (cad == null) return;

    const idx = group.list.indexOf(cad);
    if (idx == -1) return;

    switch (data.command.command) {
      case "filter":
        this.chooseFilterForChart(cad);
        break;

      case "delete":
        group.list.splice(idx, 1);
        this.chartChanged(...group.list);

        break;

      case "duplicate":
        const newChartAndData: WidgetFormAndWidgetData = {
          widgetForm: reportChartDefinitionFormCreator(
            data.chart.updatedModel()
          ),
          data: null,
          dataLoading: false
        };

        group.list.splice(idx + 1, 0, newChartAndData);
        this.chartChanged(newChartAndData);

        break;

      case "choose-columns":
        await this.chooseColumnsForChart(cad);
        break;
    }
  }

  // just delete this one when FF is live
  chartCommand(data: {
    chart: FormForReportChartDefinition;
    command: Command;
  }) {
    const cad = this.charts.find(d => d.widgetForm == data.chart);
    if (cad == null) return;

    const idx = this.charts.indexOf(cad);
    if (idx == -1) return;

    switch (data.command.command) {
      case "filter":
        this.chooseFilterForChart(cad);
        break;

      case "delete":
        this.charts.splice(idx, 1);
        this.chartChanged(...this.charts);

        break;

      case "duplicate":
        const newChartAndData: WidgetFormAndWidgetData = {
          widgetForm: reportChartDefinitionFormCreator(
            data.chart.updatedModel()
          ),
          data: null,
          dataLoading: false
        };

        this.charts.splice(idx + 1, 0, newChartAndData);
        this.chartChanged(newChartAndData);

        break;
    }
  }

  async chooseFilterForChart(cad: WidgetFormAndWidgetData) {
    const chartDefinitionForm = cad.widgetForm as FormForReportChartDefinition;

    const jsonClientData: ReportChartDefinitionClientData = JSON.parse(
      chartDefinitionForm.ClientData
    ) || { filterContainer: null };

    const chartDefinition = chartDefinitionForm.updatedModel();
    chartDefinition.clean();

    const args: ChartFilterArgs = {
      groupFilter: jsonClientData.filterContainer,
      chartDefinition,
      data: cad.data,
      reportFilter: this.reportFilter
    };

    const res = await this.dialogService.open<GroupFilter>(ChartFilter, args);
    if (res.canceled)
      return;

    chartDefinitionForm.ChartFilter = res.result.toFilterString();

    jsonClientData.filterContainer = res.result;
    chartDefinitionForm.ClientData = JSON.stringify(jsonClientData);

    this.chartChanged(cad);
  }

  async chooseColumnsForChart(cad: WidgetFormAndWidgetData) {
    if (cad.widgetForm.DataSource === ChartDataSource.Tasks) {
      const gridViewDefinitionForm = cad.widgetForm as FormForGridViewDefinition;
      const gridViewDefinition = gridViewDefinitionForm.updatedModel();
      const args: ColumnChooserArgs = {
        allColumns: [await this.tasksFieldPaths.getCategory()],
        selectedColumns: gridViewDefinition.Columns,
        allowMarkAsDefaultChoice: false
      }

      const res = await this.dialogService.open<ColumnChooserResult>(ColumnChooser, args);
      if (res.canceled) return;

      gridViewDefinitionForm.Columns = res.result.columns.map(c => gridColumnFormCreator(c));
    }

    this.chartChanged(cad);
  }

  async chartChanged(...charts: WidgetFormAndWidgetData[]) {
    charts.forEach(c => {
      if (c.widgetForm.DataSource == ChartDataSource.Admissions) {
        c.dataLoading = true;
      } else {
        c.data = null;
      }
    });

    const updatedCharts = charts.map(c => {
      const cf = c.widgetForm;
      const um = cf.updatedModel();
      um.clean();

      return um;
    });
    try {
      const res = await this.appRepo.previewReportChartData(
        this.reportFilter,
        updatedCharts
      );

      // hopefully these all line up..
      for (let i = 0; i < res.length; i++) {
        const chartResult = res[i];

        charts[i].data = chartResult;
      }
    } catch (err) {
      console.error(err);
    }

    charts.forEach(c => (c.dataLoading = false));
  }

  async groupOption(fg: FieldGroupAndWidgetData) {
    const res = await this.popover.open<ColumnSpanGroupCommand>(ColumnSpanGroupOptions, <IColumnSpanGroupOptions>{
      columnSpan: fg.fieldGroupForm.ColumnSpan,
      showResize: true
    });

    if (res.canceled) return;

    const { command, data } = res.result;

    switch (command) {
      case "delete":
        this.fieldGroupsProxy = this.fieldGroupsProxy.filter(f => f != fg);
        break;

      case "colspan":
        fg.fieldGroupForm.ColumnSpan = data;
        this.chartChanged(...fg.list);
        break;
    }
  }

  addGroup(span: number) {
    const fg = fieldGroupFormCreator(null);

    fg.ColumnSpan = span;
    fg.Label = "";

    this.fieldGroupsProxy = [...this.fieldGroupsProxy, { fieldGroupForm: fg, list: [] }];
  }

  onGroupDragIconClick(index) {
    this.selectedGroupIndex = index;
    this.selectedSubIndex = -1;
  }
  onSubDragIconClick(groupIndex, subIndex) {
    this.selectedGroupIndex = groupIndex;
    this.selectedSubIndex = subIndex;
  }

  onDragIconFocus() { }
  onSubDragIconFocus() { }

  onDragIconBlur() {
    this.selectedGroupIndex = -1;
    this.selectedSubIndex = -1;
  }

  onDragIconKeyDown(e) {
    this.handleDragIconKeyEvent(e, "keyDown");
  }

  onDragIconKeyUp(e) {
    this.handleDragIconKeyEvent(e, "keyUp");
  }

  private handleDragIconKeyEvent(e: KeyboardEvent, type: string) {
    const up = 38, down = 40;
    const key = e.keyCode;

    if ((type === "keyUp" || type === "keyDown") && (key == up || key == down)) {
      e.preventDefault();

      if (type === "keyUp" && this.selectedGroupIndex != -1) {
        if (this.selectedSubIndex == -1) {
          let items = this.fieldGroupsProxy;
          let oldIndex = this.selectedGroupIndex;

          let newIndex = oldIndex;

          if (key == up && oldIndex > 0) {
            newIndex--;
          } else if (key == down && oldIndex < items.length - 1) {
            newIndex++;
          }

          if (newIndex != oldIndex) {
            items.splice(
              newIndex,
              0,
              items.splice(oldIndex, 1)[0]
            );

            this.selectedGroupIndex = newIndex;
          }
        } else {
          // in case it moved into a new group
          let oldGroupIndex = this.selectedGroupIndex;
          let oldSubIndex = this.selectedSubIndex;

          let newGroupIndex = oldGroupIndex;
          let newSubIndex = oldSubIndex;

          if (key == up) {
            if (oldSubIndex > 0) {
              newSubIndex--;
            } else if (oldGroupIndex > 0) {
              newGroupIndex--;
              newSubIndex = this.fieldGroupsProxy[newGroupIndex].list.length;
            }
          } else if (key == down) {
            if (oldSubIndex < this.fieldGroupsProxy[newGroupIndex].list.length - 1) {
              newSubIndex++;
            } else if (oldGroupIndex < this.fieldGroupsProxy.length - 1) {
              newGroupIndex++;
              newSubIndex = 0;
            }
          }

          if (newGroupIndex != oldGroupIndex || newSubIndex != oldSubIndex) {
            this.fieldGroupsProxy[newGroupIndex].list.splice(
              newSubIndex,
              0,
              this.fieldGroupsProxy[oldGroupIndex].list.splice(oldSubIndex, 1)[0]
            );

            this.selectedGroupIndex = newGroupIndex;
            this.selectedSubIndex = newSubIndex;
          }
        }
      }
    }
  }

  public get ffAccessibleDragDrop(): boolean {
    return this.ffs.isFeatureFlagEnabled("AccessibleDragDropOUT2055Nov2023");
  }

  public uniqueKeyForGroupList(groupList): string {
    const form = groupList.widgetForm;
    const primary = form.PrimaryIndependentVariable;

    return `${form.Name}-${primary?.Label}-${primary?.Path}`.replace(" ", "");
  }
}
