import { fromClass, fromClassArray } from "fw-model";
import { EmailTemplate } from "./email-template";

export class EmailTaskMetaData {
  OrganizationId: string;
  ActorType: string;
  ActorId: string;
  TaskRequestId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  IdCount: number;
}

export class EmailTask {
  Id: string;
  Ids: string[];

  @fromClass EmailTemplate: EmailTemplate;
  @fromClass MetaData: EmailTaskMetaData;
}
