import { Form, field, Validators } from "fw-model";
const { isEmail, required } = Validators;

import { User } from "models/user";

export class UpdateMyAccount extends Form {
  @field("First Name", required)
  FirstName: string = null;

  @field("Last Name", required)
  LastName: string = null;

  @field("Avatar") AvatarFileId: string = null;

  public static fromUser(user: User) {
    const f = new UpdateMyAccount();
    f.FirstName = user.FirstName;
    f.LastName = user.LastName;
    f.AvatarFileId = user.AvatarFileId;

    return f;
  }
}

// todo, add in the length stuff
export class UpdateMyPassword extends Form {
  @field("New Password", required)
  NewPassword: string = null;

  @field("Verify New Password", required, UpdateMyPassword.matchesNewPassword)
  VerifyNewPassword: string = null;

  @field("Current Password", required)
  CurrentPassword: string = null;

  private static matchesNewPassword(input: string, u: UpdateMyPassword) {
    if (input != u.NewPassword) return "Must match new password";
    return null;
  }
}

export class UpdateMyEmailAddress extends Form {
  @field("New Email Address", required, isEmail)
  NewEmailAddress: string = null;

  @field("Current Password", required)
  CurrentPassword: string = null;
}
