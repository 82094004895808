import { prop } from "fw";

export class Loader {
  @prop(200) private delay!: number;
  @prop(28) public size!: number;

  private show = false;

  public attached() {
    if (this.delay > 0) {
      setTimeout(() => this.show = true, this.delay);
    } else {
      this.show = true;
    }
  }

  get customStyle() {
    return {
      width: this.size + "px",
      height: this.size + "px",
    };
  }
}
