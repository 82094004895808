import { needs, prop, ComponentEventBus } from "fw";
import { Step } from "./step";

export type RegisterStepFn = (step: Step) => Promise<void>;
export type HasPrevFn = () => boolean;
export type HasNextFn = () => boolean;

export interface IStepsProvide {
  cancel: () => Promise<void>,
  done: () => Promise<void>,
  reset: () => Promise<void>,
  registerStep: RegisterStepFn,
  removeStep: RegisterStepFn,
  hasPrev: HasPrevFn,
  previousStep: () => Promise<void>,
  hasNext: HasNextFn,
  nextStep: () => Promise<void>,
  isControlsFixed: Boolean,
  showNav: Boolean
  showNavButton: Boolean
}

@needs(Step)
export class Steps {
  @prop(null) private cancel!: () => Promise<void>;
  @prop(null) private done!: () => Promise<void>;
  @prop(false) private isControlsFixed!: boolean;
  @prop(true) private showNav!: boolean;
  @prop(true) private showNavButton!: boolean;
  @prop(0) private startAtStep!: number;

  private steps: Step[] = [];
  private currentStep = 0;
  private isDetaching: boolean = false;

  constructor(private ceb: ComponentEventBus) {}

  public async attached() {
    this.currentStep = !isNaN(this.startAtStep) && this.startAtStep > 0 ? this.startAtStep : 0;
    await this.updateState();
    this.ceb.dispatch("reset", this.reset);
    this.ceb.dispatch("previous-step", this.goToPreviousStep);
    this.ceb.dispatch("next-step", this.goToNextStep);
    this.ceb.dispatch("update-step", this.currentStep);
  }

  public beforeDetach() {
    this.isDetaching = true;
  }

  private provide(): IStepsProvide {
    return {
      cancel: this.cancel,
      done: this.done,
      reset: this.reset,
      registerStep: async (step: Step) => {
        step.isHidden = true;
        step.isOpen = false;
        this.steps.push(step)
      },
      removeStep: async (step: Step) => {
        this.steps.splice(this.steps.indexOf(step), 1);
        if (!this.isDetaching && this.currentStep >= this.steps.length) {
          // if the last step is removed call done or go to last step.
          if (this.done) {
            await this.done();
          } else {
            await this.updateState();
          }
        }
      },
      hasPrev: () => this.currentStep > 0,
      previousStep: async () => await this.previousStep(),
      hasNext: () => this.steps.length > 0 ? this.currentStep < this.steps.length - 1 : false,
      nextStep: async() => this.advanceStep(),
      isControlsFixed: this.isControlsFixed,
      showNav: this.showNav,
      showNavButton: this.showNavButton
    };
  }

  private async updateState() {
    if (this.steps.length === 0) {
      return;
    } else if (this.currentStep >= this.steps.length) {
      this.currentStep = this.steps.length - 1;
    }

    // close all steps up to onStep
    for (let i = 0; i <= this.currentStep; i++) {
      const step = this.steps[i];
      step.isHidden = false;
      step.isOpen = false;
    }

    this.steps[this.currentStep].isOpen = true;

    for (let i = this.currentStep + 1; i < this.steps.length; i++) {
      this.steps[i].isHidden = true;
    }

    await this.steps[this.currentStep].onEnterStep();
  }

  public async reset(): Promise<void> {
    for (let i = 0; i <= this.currentStep; i++) {
      this.steps[i].onResetStep();
    }

    this.currentStep = 0;
    await this.updateState();
  }

  public async advanceStep(stepCount: number = 1) {
    this.currentStep += (this.currentStep + stepCount) < this.steps.length ? stepCount : this.steps.length - this.currentStep;
    await this.updateState();
  }

  public async previousStep(stepCount: number = 1) {
    this.currentStep -= this.currentStep >= stepCount ? stepCount : this.currentStep;
    await this.updateState();
  }

  private async goToPreviousStep() {
    await this.steps[this.currentStep].onPrevious();
    this.ceb.dispatch("update-step", this.currentStep);
  }

  private async goToNextStep() {
    await this.steps[this.currentStep].onNext();
    this.ceb.dispatch("update-step", this.currentStep);
  }

  private async moveToStep(stepId: number)
  {
    this.currentStep = stepId;
    
    await this.updateState();
  }
}
