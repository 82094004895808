import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer, AnswerOption } from "../../models";


@inject
export class RadioType {
  @prop(null) question!: Question;
  @prop(null) answerOptions?: AnswerOption[];
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(true) showOptions!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledby: string;

  public value: string = null;
  public writeInValue: string = "";
  public writeInInputRef: HTMLInputElement;

  public constructor(private ecb: ComponentEventBus) { }

  public attached() {
    if (this.question == null) return;
    this.answerChanged();
  }

  private answerChanged() {
    const { FormAnswerOption } = this.answer;

    if (FormAnswerOption != null) {
      if (FormAnswerOption.AnswerOptionId == null) { // write in
        this.writeInValue = FormAnswerOption.OptionText;
        this.value = this.getValueForWriteInOption();
      } else {
        this.value = FormAnswerOption.AnswerOptionId;
        this.writeInValue = "";
      }
    }
  }

  public change() {
    const option = this.question.AnswerOptions.find(o => o.Id == this.value);
    let OptionText = option != null ? option.Label : null;

    const AnswerOptionId = this.value == "writeIn" ? null : this.value;
    if (AnswerOptionId == null && this.writeInValue.trim().length > 0)
      OptionText = this.writeInValue;

    this.answer.FormAnswerOption = this.value != null ? { AnswerOptionId, OptionText } : null;
    this.ecb.dispatch("answer-changed");
  }

  public writeInKeyUp() {
    this.value = this.getValueForWriteInOption();
  }

  public writeInClick() {
    this.value = this.getValueForWriteInOption();
  }

  public clear() {
    this.value = null;
    this.writeInValue = "";
    this.change();
  }

  public writeInRadioBtnClick(event) {
    this.writeInInputRef.focus();
    this.value = this.getValueForWriteInOption();
    if (!this.value) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.change();
  }

  onClick(event) {
    this.writeInClick();
    const handleBlur = () => this.onBlur(
      () => event.target.removeEventListener("blur", handleBlur)
    );
    event.target.addEventListener("blur", handleBlur);
  }

  onBlur(cb)  {
    this.writeInValue = this.writeInValue?.trim();
    this.change();
    cb();
  }

  private getValueForWriteInOption(): string | null {
    return this.writeInValue.trim().length > 0 ? "writeIn" : null;
  }
}
