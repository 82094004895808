import { ComponentEventBus, inject, needs, prop } from "fw";

import type { FormForRegistrationStatusCountsDefinition } from "forms/report-definition";

import { CalendarEventSeries, CalendarEventTypes } from "models/calendar-event";
import { DateFilter, dateToFilterString } from "models/date-filter";
import { TimingFilterType } from "models/filter-timing";

import { PopoverService } from "service/popover";

import { ChartDataSource } from "shared/report-runtime";

import { CurrentOrganizationStore } from "state/current-organization";

import {
  EventSeriesPopover,
  EventSeriesPopoverOptions,
} from "views/calendar/event/components/event-series-popover";
import {
  DateRange,
  DEFAULT_RANGES,
  formatDate,
  RangeStamp,
} from "views/components/date-range";
import {
  OptionChoice,
  OptionChooserPopover
} from "views/reports/charts/option-chooser-popover";

let inputIdIterator: number = 0;
const defaultDateRangeFilter = TimingFilterType.ThisMonth;

@inject
@needs(DateRange)
export class RegistrationStatusCountsEditor {
  @prop(null) widgetDefinitionForm: FormForRegistrationStatusCountsDefinition;

  private timezone: string = "America/Chicago";
  public calendarEventTypes = CalendarEventTypes;
  public focusedOptionId: string = '';
  private gridViewEditorBaseInputId: string = "grid-view-editor-input";
  private gridViewEditorPopoverId: string = "grid-view-editor-popover";
  private listLimitOptions: { value: any; text: string }[] = [
    { text: 'Top 20', value: 20 },
    { text: 'Top 50', value: 50 },
    { text: 'Top 100', value: 100 },
    { text: 'Top 250', value: 250 },
  ];
  public popoverIsOpen: { [key: string]: boolean } = {
    series: false,
    listLimit: false,
    sort: false,
  };
  private sortFields: { Label: string; Sort: string }[] = [];

  private $refs: any;
  public dateFilter: DateFilter = {
    title: '',
    field: '',
    occurrence: '',
    timing: null,
    startDate: null,
    endDate: null,
    marker: [],
  };

  constructor(
    private ceb: ComponentEventBus,
    private currentOrganizationStore: CurrentOrganizationStore,
    private popoverService: PopoverService,
  ) {
    this.timezone = this.currentOrganizationStore.state.organization.Timezone || this.timezone;
  }

  async attached() {
    const dateFilter = this.getClientData('dateFilter');
    if (!!dateFilter) {
      this.dateFilter = dateFilter;
    } else {
      const defaultDateRange = DEFAULT_RANGES.find(r => r.value === defaultDateRangeFilter);
      this.setFilterLastReceivedAt({
        timing: defaultDateRangeFilter,
        marker: defaultDateRange?.getRange(formatDate, this.timezone) as string[],
        range: null,
      });
    }

    if (this.widgetDefinitionForm.DataSource === ChartDataSource.Events) {
      switch (this.widgetDefinitionForm.DataContext) {
        case CalendarEventTypes.Series:
          this.sortFields = this.widgetDefinitionForm.Columns
            .filter(c => 'Name' === c.Label)
            .map((c) => ({ Label: c.Label, Sort: c.Sort }));

          break;

        case CalendarEventTypes.Instance:
          this.sortFields = this.widgetDefinitionForm.Columns
            .filter(c => 'Date' === c.Label || 'Name' === c.Label)
            .map((c) => ({ Label: c.Label, Sort: c.Sort }));

          break;
      }
    }
  }

  chartChanged() {
    this.ceb.dispatch("changed");
  }

  get gridViewEditorInputId() {
    inputIdIterator++;
    return `${this.gridViewEditorBaseInputId}-${inputIdIterator}`;
  }

  public updateOptionFocus(optionId: string) {
    this.focusedOptionId = optionId;
  }

  public setClientData(clientData: any = {}) {
    const existingClientData = JSON.parse(this.widgetDefinitionForm.ClientData);
    this.widgetDefinitionForm.ClientData = JSON.stringify({ ...existingClientData, ...clientData });
  }

  public getClientData(prop: string) {
    if (!!this.widgetDefinitionForm.ClientData) {
      try {
        const clientData = JSON.parse(this.widgetDefinitionForm.ClientData);
        if (!!clientData[prop]) {
          return clientData[prop];
        }
      } catch (e) {
        console.error('Error parsing ClientData:', e);
      }
    }
  }

  // series selector
  get selectedEventSeriesName() {
    return this.getClientData('selectedSeriesName') || 'All Series';
  }

  async selectEventSeries() {
    const options = <EventSeriesPopoverOptions>{
      prependAllSeriesOption: true,
      sort: "display_name ASC",
      updateOptionFocus: this.updateOptionFocus,
    }

    this.popoverIsOpen.series = true;
    const res = await this.popoverService.open<CalendarEventSeries>(
      EventSeriesPopover,
      options,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.gridViewEditorPopoverId },
    )
    this.popoverIsOpen.series = false;

    if (res.canceled) return;

    this.widgetDefinitionForm.DataContextId = res?.result?.id;
    this.setClientData({ selectedSeriesName: res?.result?.display_name });
    this.chartChanged();
  }

  // date range selector
  private resetDateRange() {
    this.dateFilter = {
      title: '',
      field: '',
      occurrence: '',
      timing: null,
      startDate: null,
      endDate: null,
      marker: [],
    }
    const dateRangeElement = this.$refs.dateRange;
    dateRangeElement?.resetPicker();
  }

  public async clearDateFilters() {
    this.resetDateRange();
    this.widgetDefinitionForm.ChartFilter = null;
    this.setClientData({ dateFilter: this.dateFilter });
    this.chartChanged();
  }

  private syncDateRange() {
    this.dateFilter.marker = [
      this.dateFilter.startDate,
      this.dateFilter.endDate,
    ];

    const dateRangeElement = this.$refs.dateRange;
    dateRangeElement?.setPicker(this.dateFilter);
  }

  public setFilterLastReceivedAt(selectedDates: RangeStamp = null) {
    if ('empty' === selectedDates?.timing) {
      this.clearDateFilters();
      return;
    }

    this.dateFilter.startDate = null;
    this.dateFilter.endDate = null;
    if (selectedDates?.range) {
      const { startDate, endDate } = selectedDates.range;
      this.dateFilter.startDate = startDate;
      this.dateFilter.endDate = endDate;
    } else if (Array.isArray(selectedDates?.marker) && 2 === selectedDates.marker.length) {
      const [startDate, endDate] = selectedDates.marker;
      this.dateFilter.startDate = startDate.replace(/[^\w-]/g, '');
      this.dateFilter.endDate = endDate.replace(/[^\w-]/g, '');
    }

    this.syncDateRange();

    this.dateFilter.field = 'event_from_utc';
    this.dateFilter.timing = null;
    if (!!selectedDates?.timing) {
      this.dateFilter.timing = selectedDates.timing;
    }
    this.widgetDefinitionForm.ChartFilter = dateToFilterString({ ...this.dateFilter, ...selectedDates });

    this.setClientData({ dateFilter: this.dateFilter });
    this.chartChanged();
  }

  // sort selector
  get sort() {
    const sort = this.widgetDefinitionForm?.Sort;
    if (sort == null) return "Select Sort";

    const field = this.sortFields.find(f => sort === f.Sort || sort == `-${f.Sort}`);
    return field?.Label || "Select Sort";
  }

  get isAscending() {
    return this.widgetDefinitionForm?.Sort && this.widgetDefinitionForm.Sort?.indexOf("-") === -1
  }

  async selectSort() {
    const sortOptions = this.sortFields.map(f => { return { text: f.Label, value: f.Sort } });
    this.popoverIsOpen.sort = true;
    const res = await this.popoverService.open<OptionChoice<string>>(
      OptionChooserPopover,
      {
        options: sortOptions,
        searchHint: "Search Sortable",
        selectedOption: sortOptions.find(option => option.text === this.sort),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.gridViewEditorPopoverId },
    );
    this.popoverIsOpen.sort = false;

    if (res.canceled) {
      return;
    }

    this.widgetDefinitionForm.Sort = res.result.value;
    this.chartChanged();
  }

  toggleSort() {
    this.widgetDefinitionForm.Sort = this.widgetDefinitionForm.Sort.indexOf("-") === 0
      ? this.widgetDefinitionForm.Sort.substring(1)
      : `-${this.widgetDefinitionForm.Sort}`;
    this.chartChanged();
  }

  // limit selector
  get listLimit() {
    const opt = this.listLimitOptions.find(opt => opt.value == this.widgetDefinitionForm.ListLimit);
    return opt?.text;
  }

  async selectListLimit() {
    this.popoverIsOpen.listLimit = true;
    const res = await this.popoverService.open<OptionChoice<number>>(
      OptionChooserPopover,
      {
        options: this.listLimitOptions,
        selectedOption: this.listLimitOptions.find(option => option.text === this.listLimit),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.gridViewEditorPopoverId },
    );
    this.popoverIsOpen.listLimit = false;

    if (res.canceled) {
      return;
    }

    this.widgetDefinitionForm.ListLimit = res.result.value;
    this.chartChanged();
  }
}
