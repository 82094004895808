import { Validators } from "fw-model";

import parsePhoneNumberFromString, { AsYouType, CountryCode } from "libphonenumber-js";
import { getQuestion } from "./helpers";
import { Form, Question, FormAnswer, ValidationItem } from "./models";
import { QuestionType, NumberQuestionType  } from "./enums";

interface PipelineContext {
  question: Question;
  answer: FormAnswer;

  invalid(message: string, path?: string);
}

class PipelineContextWithCalled {
  private didCallInvalid = false;

  constructor(public question: Question, public answer: FormAnswer) { }

  invalid(message: string, path: string = null) {
    this.answer.MetaData.IsValid = false;
    this.answer.MetaData.ValidationSummary = this.answer.MetaData.ValidationSummary || [];

    const item = new ValidationItem();
    item.Message = message;
    item.Path = path || "Text";
    this.answer.MetaData.ValidationSummary.push(item);

    this.didCallInvalid = true;
  }

  get calledInvalid() { return this.didCallInvalid; }
}


export function isAnswered(question: Question, answer: FormAnswer): boolean {
  switch (question.Type) {
    case QuestionType.ShortText:
    case QuestionType.LongText:
    case QuestionType.Date:
    case QuestionType.EmailAddress:
    case QuestionType.PhoneNumber:
    case QuestionType.URL:
    case QuestionType.Encrypted:
      return answer.Text != null && answer.Text.trim().length > 0;

    case QuestionType.DropDown:
    case QuestionType.RadioButton:
      return answer.FormAnswerOption != null;

    case QuestionType.CheckBoxList:
      return answer.FormAnswerOptions != null && answer.FormAnswerOptions.length > 0;

    case QuestionType.File:
      return answer.FileId != null && answer.FileId.trim().length > 0;

    case QuestionType.Number:
    case QuestionType.Scale:
      return answer.Number != null;

    case QuestionType.ScaleGroup:
      if (answer.Data == null) return false;

      for (const row of question.Options.ScaleGroup.Items) {
        if (answer.Data[row.Key] != null) return true;
      }

      return false;

    case QuestionType.Table:
      if (answer.Rows == null) return false;

      let rowsWithData = 0;
      answer.Rows.forEach(row => {
        let rowHasData = false;

        if (row == null || row.length == 0) return;
        row.forEach(col => {
          if (col == null) return;

          // we only want rowHasData to change to true
          // so cant do something like: rowHasData = col.trim().length > 0;
          if (col.trim().length > 0) rowHasData = true;
        });

        if (rowHasData) rowsWithData += 1;
      });

      return rowsWithData > 0;

    case QuestionType.Address:
    case QuestionType.CEEBCode:
    case QuestionType.Name:
      if (answer.Data == null) return false;
      for (let key in answer.Data) {
        if (answer.Data[key] != null && answer.Data[key].trim().length > 0) return true;
      }
      return false;

    default:
      return false;
  }
}

function validateIsAnswered(c: PipelineContext) {
  if (c.question.IsRequired && !isAnswered(c.question, c.answer))
    c.invalid("Not Answered");
}

function validateMaxLength(c: PipelineContext) {
  if (!isAnswered(c.question, c.answer)) return;

  const { MaxLength } = c.question.Options;
  if (MaxLength == null || MaxLength == 0) return;

  let answer = "";
  switch (c.question.Type) {
    case QuestionType.ShortText:
    case QuestionType.LongText:
    case QuestionType.Date:
    case QuestionType.EmailAddress:
    case QuestionType.PhoneNumber:
    case QuestionType.URL:
    case QuestionType.Encrypted:
      if (c.answer.IsEncrypted) {
        answer = "";
      } else {
        answer = c.answer.Text || "";
      }
      break;

    case QuestionType.DropDown:
    case QuestionType.RadioButton:
      if (c.question.Options.AllowWriteIn && c.answer.FormAnswerOption.AnswerOptionId == null) {
        answer = c.answer.FormAnswerOption.OptionText || "";
      }
      break;

    case QuestionType.CheckBoxList:
      if (c.question.Options.AllowWriteIn) {
        const writeIn = c.answer.FormAnswerOptions.find(o => o.AnswerOptionId == null);
        if (writeIn != null) answer = writeIn.OptionText || "";
      }
      break;

    default: return true;
  }

  const isOverLength = answer.length > MaxLength;
  if (isOverLength) c.invalid("Too Long");
}

function validateEmailAddress(c: PipelineContext) {
  if (!isAnswered(c.question, c.answer)) return;
  if (c.question.Type != QuestionType.EmailAddress) return;

  const valid = Validators.isEmail(c.answer.Text) == null;
  if (!valid) c.invalid("Not a valid Email Address");
}

function validateUrl(c: PipelineContext) {
  if (!isAnswered(c.question, c.answer)) return;
  if (c.question.Type != QuestionType.URL) return;

  const valid = Validators.isUrl({ allowedProtocols: [ "http", "https", "ftp" ], requireProtocol: false, allowPath: true })(c.answer.Text) == null;
  if (!valid) c.invalid("Not a valid URL");
}

const dateRegex = /^\d{1,2}-\d{1,2}-\d{4}|\d{1,2}\/\d{1,2}\/\d{4}$/;
function validateDate(c: PipelineContext) {
  if (!isAnswered(c.question, c.answer)) return;
  if (c.question.Type != QuestionType.Date) return;

  const valid = dateRegex.test(c.answer.Text);
  if (!valid) c.invalid("Not a valid Date. Please enter a Date in formats \"mm/dd/yyyy\" or \"mm-dd-yyyy\"");
}

function validateTable(c: PipelineContext) {
  if (!isAnswered(c.question, c.answer)) return;
  if (c.question.Type != QuestionType.Table) return;

  const { MaxRowCount, MinRowCount } = c.question.Options.Table;

  // maybe need some better messages here??
  if (MaxRowCount != null && MaxRowCount > 0 && c.answer.Rows.length > MaxRowCount) {
    c.invalid("Too many rows");
  }

  if (MinRowCount != null && MinRowCount > 0 && c.answer.Rows.length < MinRowCount) {
    c.invalid("Not enough rows");
  }
}

function validateNumber(c: PipelineContext) {
  if (!isAnswered(c.question, c.answer)) return;
  if (c.question.Type != QuestionType.Number) return;

  if (c.answer.Text == null || c.answer.Text.trim().length == 0) return;

  if (Validators.isNumber(c.answer.Text) != null) {
    c.invalid("Not a number");
    return;
  }

  const { Type } = c.question.Options.Number;

  switch (Type) {
    case NumberQuestionType.Integer:
      if (c.answer.Text.indexOf(".") >= 0)
        c.invalid("Not an integer");
      break;
  }
}

function validatePhoneNumber(c: PipelineContext) {
  if (!isAnswered(c.question, c.answer)) return;
  if (c.question.Type != QuestionType.PhoneNumber) return;
  if (c.answer.Text == null || c.answer.Text.trim().length == 0) return;

  const phone = c.answer.Text;
  const dynamicPhoneType = new AsYouType();
  dynamicPhoneType.input(phone);
  const country: CountryCode = dynamicPhoneType.getCountry();

  const phoneNumber = parsePhoneNumberFromString(phone, country);
  if (!phoneNumber || !phoneNumber.isPossible()) {
    let errorMessage = "Invalid phone number";
    if (!country && '+' !== phone.charAt(0)) {
      errorMessage += ' (missing country selection)';
    }
    c.invalid(errorMessage);
  }
}

function validateAddress(c: PipelineContext) {
  if (!isAnswered(c.question, c.answer)) return;
  if (c.question.Type != QuestionType.Address) return;

  const requireField = (key, label) => {
    if (c.answer.Data[key] == null || c.answer.Data[key].trim().length == 0) {
      c.invalid(`${label} required`, key);
    }
  };

  if (c.question.IsRequired) {
    const isUS = c.answer.Data["country"] == "US";

    requireField("address1", "Address 1");
    requireField("city", "City");
    requireField("country", "Country");
    requireField("region", isUS ? "State" : "Region");
    requireField("postalCode", isUS ? "Zip" : "Postal Code")
  }
}

type pipelineFunction = (context: PipelineContext) => void;
const pipeline: pipelineFunction[] = [
  validateIsAnswered,
  validateMaxLength,
  validateEmailAddress,
  validateUrl,
  validateDate,
  validateTable,
  validateNumber,
  validatePhoneNumber,
  validateAddress,
];

// returns true if valid (it was able to run through the pipeline without calling invalid)
function runPipeline(form: Form, question: Question, answer: FormAnswer, results: boolean[] = []): boolean {
  answer.MetaData.ValidationSummary = [];
  answer.MetaData.IsValid = true;

  if (question == null && answer.SectionKey != null) {
    const allAnswers: FormAnswer[] = [];
    answer.SectionAnswers.forEach(sa => {
      sa.Answers.forEach(saa => {
        allAnswers.push(saa);
      });
    });

    results.push(validateAll(form, allAnswers));
  } else {
    for (let i = 0; i < pipeline.length; i++) {
      const context = new PipelineContextWithCalled(question, answer);
      pipeline[i](context);

      // push onto stack true if it did not call invalid
      results.push(!context.calledInvalid);
    }
  }

  return results.every(s => s);
}

// will return true if it is valid, false if it is not
export function validate(form: Form, question: Question, answer: FormAnswer): boolean {
  return runPipeline(form, question, answer);
}

// will return true is all are valid, false if any are not
export function validateAll(form: Form, answers: FormAnswer[] | { [key: string]: FormAnswer }): boolean {
  let theAnswers: FormAnswer[] = [];

  if (Array.isArray(answers)) {
    theAnswers = answers;
  } else {
    for (const key in answers) {
      theAnswers.push(answers[key]);
    }
  }

  return theAnswers.map(a => runPipeline(form, getQuestion(form, a.QuestionKey), a)).every(b => b);
}
