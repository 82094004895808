import { formFor, FormForType, Validators } from "fw-model";
import {
  ReviewPortalSocial,
  ReviewPortal,
  ReviewPortalContent,
} from "models/review-portal";

const { wrap, isUrl, required } = Validators;

export class CreateReviewPortalAction {
  newPortal: ReviewPortal;
  constructor(public form: ReviewPortalForm) {}
}

export class UpdateReviewPortalAction {
  constructor(public form: ReviewPortalForm) {}
}

export type ReviewPortalForm = FormForType<ReviewPortal>;

export const ReviewPortalContentFormCreator = formFor(
  ReviewPortalContent,
  (s) => {
    s.requiredField(
      (c) => c.OrganizationDisplayName,
      "Organization Display Name"
    );
    s.requiredField(
      (c) => c.OrganizationWebsiteUrl,
      "Organization Website Url"
    );
    s.field((c) => c.SupportEmailAddress, "Support Email Address");
    s.field((c) => c.SupportPhoneNumber, "Support Phone Number");
    s.field(
      (c) => c.WelcomeMessageBGImageFileId,
      "Login Welcome Background Image File Id"
    );
    s.field(
      (c) => c.WelcomeMessageBGImageUrl,
      "Login Welcome Background Image Url"
    );
    s.field((c) => c.WelcomeMessage, "Login Welcome Message (in HTML)");

    s.field(
      (c) => c.DashboardWelcomeBGImageFileId,
      "Dashboard Welcome Background Image File Id"
    );
    s.field(
      (c) => c.DashboardWelcomeBGImageUrl,
      "Dashboard Welcome Background Image Url"
    );
    s.field(
      (c) => c.DashboardWelcomeMessage,
      "Dashboard Welcome Message (in HTML)"
    );

    s.field((c) => c.FavIconFileId, "Fav Icon File Id");
    s.field((c) => c.FavIconUrl, "Fav Icon Url");

    s.field((c) => c.FullLogoFileId, "Full Logo File Id");
    s.field((c) => c.FullLogoUrl, "Full Logo Url");
    s.field((c) => c.CompactLogoFileId, "Compact Logo File Id");
    s.field((c) => c.CompactLogoUrl, "Compact Logo Url");

    s.field((c) => c.FullLogoLightFileId, "Full Logo File Id");
    s.field((c) => c.FullLogoLightUrl, "Full Logo Url");
    s.field((c) => c.CompactLogoLightFileId, "Compact Logo File Id");
    s.field((c) => c.CompactLogoLightUrl, "Compact Logo Url");

    s.requiredField((c) => c.PrimaryColor, "Primary Color");
    s.requiredField((c) => c.SecondaryColor, "Secondary Color");
    s.requiredField((c) => c.TertiaryColor, "Tertiary Color");
    s.requiredField((c) => c.NavBarColor, "Navigation Bar Color");
  }
);

export const ReviewPortalFormCreator = formFor(ReviewPortal, (s) => {
  s.requiredField((p) => p.Name, "Name");
  s.requiredField((p) => p.BrowserTitle, "Browser Title");
  s.requiredField(
    (p) => p.Domain,
    "Domain",
    wrap(isUrl({ allowPort: process.env.NODE_ENV == "development" }))
  );
  s.form((p) => p.Content, "Content", ReviewPortalContentFormCreator);
});
