import { prop, inject } from "fw";
import { ContactsOperatorFilter as ContactsOperatorFilterModel } from "models/contacts-filters";

@inject
export class ContactsOperatorFilter {
  @prop(null) filter!: ContactsOperatorFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  ops = [
    { value: "=", text: "is" },
    { value: "!=", text: "is not" },
    { value: "<", text: "is less than" },
    { value: ">", text: "is greater than" },
    { value: "<=", text: "is less than or equal to" },
    { value: ">=", text: "is greater than or equal to" }
  ];

  get operatorText() : string {
    if (!this.filter || !this.filter.operator)
      return null;

    return this.ops.find(o => o.value === this.filter.operator).text;
  }
}
