import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { Notification, NotificationProfile } from "models/notification";

@inject
export class NotificationRepository {
  constructor(private s: ATS) { }

  async list(f = null, s = null, sort = null, page = 1, pageSize = 20) {
    const res = await this.s.get<Notification[]>("notification", { page, pageSize, f, s, sort });
    return { list: createFromArray(Notification, res.body) as Notification<object>[], total: parseInt(res.headers["x-ats-total"], 10) };
  }

  async get(notificationId: string) {
    const res = await this.s.get(`notification/${notificationId}`);
    return createFrom(Notification, res.body) as Notification<object>;
  }

  async delete(notificationIds: string[]) {
    await this.s.post("notification/delete", notificationIds);
  }

  async markAsStarred(ids: string[], starred: boolean) {
    await this.s.post("notification/mark-as-starred", ids, { starred });
  }

  async markAsRead(ids: string[], read: boolean) {
    await this.s.post("notification/mark-as-read", ids, { read });
  }

  async markAllAsRead(onOrBeforeDate: Date, read: boolean) {
    await this.s.post("notification/mark-as-read", null, { read, onOrBeforeUtc: onOrBeforeDate.toUTCString() });
  }

  async profile() {
    const res = await this.s.get("notification/profile");
    return createFrom(NotificationProfile, res.body);
  }
}
