import { fromClass, fromClassArray } from "fw-model";
import { Workflow } from "./workflow";
import type { Trigger } from "./triggers";

export class WorkflowNodeRun {
  NodeId: string;
  DecisionKey: string;
  StartedUtc: string;
  CompletedUtc: string;
}


export enum WorkflowRunStatus {
  NotRun = 0,
  Waiting = 1,
  Complete = 2,

  Aborted = 3,
  Error = 4,
}

export class WorkflowRunMetaData {
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  ApplicationId: string;
  ObjectType: string;
  ObjectId: string;
  OrganizationId: string;
  SeasonId: string;
  WaitingNodeId: string;

  IsComplete: boolean;
  IsWaiting: boolean;
  Status: WorkflowRunStatus;
  ResumeUtc: string;
}

export class WorkflowRun {
  Id: string;
  @fromClass Workflow: Workflow;
  @fromClass Trigger: Trigger;
  @fromClassArray(WorkflowNodeRun) Run: WorkflowNodeRun[];

  @fromClass MetaData: WorkflowRunMetaData;
}
