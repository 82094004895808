import { prop, inject } from "fw";
import { ContactsTestScoreFilter as ContactsTestScoreFilterModel } from "models/contacts-filters";

@inject
export class ContactsTestScoreFilter {
  @prop(null) filter!: ContactsTestScoreFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  ops = [
    { value: "=", text: "is" },
    { value: "!=", text: "is not" },
    { value: "<", text: "is less than" },
    { value: ">", text: "is greater than" },
    { value: "<=", text: "is less than or equal to" },
    { value: ">=", text: "is greater than or equal to" }
  ];

  get operatorText() : string {
    if (!this.filter || !this.filter.operator)
      return null;

    return this.ops.find(o => o.value === this.filter.operator).text;
  }

  get description() : string {
    if (!this.filter || !this.filter.field) {
      return null;
    }

    const selected = this.filter.sections.find(m => m.search === this.filter.section);
    return !selected
      ? `${this.filter.field.display_name} ${this.operatorText} '${this.filter.value}'`
      : `${selected.name} ${this.operatorText} '${this.filter.value}'`;
  }


  get testScoreSections() {
    if (!this.filter || !this.filter.field) {
      return [];
    }
    return this.filter.sections.map(m => { return { text: m.name, value: m.search }; });;
  }

}
