import { formFor, FormForType, Validators } from "fw-model";
import { DecisionSettings, DecisionCode } from "models/decision-settings";
import { AddUpdateExportCodeSetModalResult } from 'views/settings/decisions/add-update-export-code-set-modal';

const { wrap, isNumber, isInteger } = Validators;

export class UpdateDecisionSettingsAction {
  constructor(public form: DecisionSettingsForm) {}
}

export type DecisionSettingsForm = FormForType<DecisionSettings>;

export const decisionSettingsFormCreator = formFor(DecisionSettings, s => {
   s.formArray(s => s.Codes, "Decision Codes", decisionCodeFormCreator);
});

export const decisionCodeFormCreator = formFor(DecisionCode, s => {
  s.requiredField(u => u.Code, "Code");
  s.requiredField(u => u.Color, "Color");
  s.field(u => u.Confetti, "Confetti");
  s.field(u => u.AnimationEffect, "Animation Effect", wrap(isNumber, isInteger));
});

export const exportCodeSetFormCreator = formFor(AddUpdateExportCodeSetModalResult, s => {
  s.requiredField(u => u.Name, "Name");
});
