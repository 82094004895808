import { ComponentEventBus, inject, prop } from 'fw';
import type { GoalView } from "state/goals";

const Colors: string[] = [
    "#5571AD",
    "#b4bd00",
    "#4eaf9c",
    "#f18eab",
    "#98a6ad",
    "#e78747",
    "#55b8d4",
    "#c98ad2",
    "#aebaf6",
    "#c6ca87",
    "#80cabb",
    "#e2afbe",
    "#bcc9d0",
    "#ecb18a",
    "#7cc8de",
    "#dbb0e0",
];

@inject
export class ListRow {
    @prop(null) goal!: GoalView;
    @prop(null) color!: string;

    constructor(private ceb: ComponentEventBus) {}

    public async edit() {
        this.ceb.dispatch("edit");
    }
}