import { fromClass } from "fw-model";
import { WorkflowNode } from "./workflow-node";
import type { Trigger } from "./triggers";

export type ObjectType = "Application" | "Contact";

export class WorkflowMetaData {
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  OrganizationId: string;
  SeasonId: string;
  ObjectType: ObjectType
}

export class ContactWorkflowSettings {
  ContactTypeKey: string = null;
}

export class Workflow {
  Id: string = null;

  Name = "";
  Active = false;

  Triggers: Trigger[] = [];
  Path: WorkflowNode[] = [];

  @fromClass MetaData: WorkflowMetaData;
  @fromClass ContactSettings: ContactWorkflowSettings;
}

export const defaultWorkflowPath = (): WorkflowNode[] => {
  return [
    {
      Id: "__exit",
      Type: "exit",
      Category: "exit",
    },
  ];
};
