import { fromClassArray } from "fw-model";
import { OptionChoice } from "../views/reports/charts/option-chooser-popover";

export class DecisionCode {
  Id: string = null;
  Code: string = null;
  Color: string = null;
  Confetti: boolean = false;
  AnimationEffect: number = 0;
  ExportCodeValues: string[] = [];
}

export class ExportCodeSet {
  Id: string = null;
  Name: string = null;
}

export class DecisionSettings {
  @fromClassArray(DecisionCode)
  Codes: DecisionCode[];

  @fromClassArray(ExportCodeSet)
  ExportCodeSets: ExportCodeSet[];
}
