import { fromClass, fromClassArray } from "fw-model";

import { Schedule } from "./scheduled-export";


export enum RuleStatusTypeCode {
  Requested = 0,
  Running = 1,
  Complete = 2,
  Error = 3,
}

export class AutomationRuleExecutionMetaData {
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  OrganizationId: string;
  SeasonId: string;
  RuleId: string;
}


export enum ActionTypes {
  SetPhase = "SetPhase",
  RunAssignmentRules = "RunAssignmentRules",
  SetStage = "SetStage",
  SendApplicantEmail = "SendApplicantEmail",
  SetPropertyValue = "SetPropertyValue",
  SetDecision = "SetDecision",
  GenerateDecisionLetter = "GenerateDecisionLetter"
}

export type SetPhaseParameters = {
  phaseId: string;
};

export type SetDecisionParameters = {
  decisionId: string;
};

export type SetPropertyValueParameters = {
  propertyValues: { [key: string]: any };
};

export type SetStageParameters = {
  stageName: string;
};

export type GenerateDecisionLetterParameters = {
  DecisionLetterTemplateSetId: string;
}

export type SendApplicantEmailParameters = {
  templateKey: string;
};

export class SendApplicantEmailAction {
  templateKey: string;
};

export class RuleTrigger {
  @fromClass Schedule: Schedule;
}

export class AutomatedAction {
  Type: ActionTypes = null;
  Parameters: object = {};
}

export class AutomationRuleMetaData {
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  OrganizationId: string;
  SeasonId: string;
  ReferencedSegmentIds: string[];
  LastRunDateUtc: string;
  NextRunDateUtc: string;
}

export class RoutedActionSet {
  Filter: string = null;
  FilterClientData: string = null;

  @fromClassArray(AutomatedAction) Actions: AutomatedAction[];
}

export class AutomationRule {
  Id: string;
  Name = "";
  Filter = "";
  FilterClientData = "";
  IsEnabled = true;

  @fromClass Trigger: RuleTrigger;
  @fromClassArray(RoutedActionSet) ActionSets: RoutedActionSet[];
  @fromClass MetaData: AutomationRuleMetaData;
}

export class AutomationRuleExecution {
  Id: string;
  Name: string;
  Filter: string;
  FilterClientData: string;
  TotalApplicationCount = 0;
  RunDateUtc: string;
  Status: RuleStatusTypeCode;

  @fromClass Trigger: RuleTrigger;
  @fromClassArray(RoutedActionSet) ActionSets: RoutedActionSet[];
  @fromClass MetaData: AutomationRuleExecutionMetaData;
}
