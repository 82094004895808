import { prop } from "@derekpitt/fw";

export class Icon {
  @prop(null) public name!: string;
  @prop(null) public ariaLabel?: string;
  @prop(null) public ariaHidden?: boolean;

  public attached() {
    const hasHiddenValue: boolean = this.ariaHidden === false || this.ariaHidden === true;
    if (hasHiddenValue && this.ariaHidden && this.ariaLabel) {
      console.warn("aria-hidden and aria-label cannot both be set.")
    }
  }

  public get isHidden() {
    // if label is set then it defaults to false.
    if (this.ariaLabel) {
      return false;
    }

    const hasHiddenValue: boolean = this.ariaHidden === false || this.ariaHidden === true;
    return !hasHiddenValue || this.ariaHidden === true;
  }
}
