import { FormForType, formFor, Validators } from "fw-model";
const { isInteger, wrap } = Validators;

import { isValidKey } from "./validators";

import {
  CollaborationModule,
  CollaborationModuleType,
} from "models/collaboration-module";

import { AggregateCalculatedFieldFormCreator } from "./calculated-field";
import { pinnedFieldGroupFormCreator } from "./application-settings";

export class CreateCollaborationModuleAction {
  constructor(
    public form: FormForCollaborationModule,
    public type = CollaborationModuleType.Form,
  ) {}
}

export class UpdateCollaborationModuleAction {
  constructor(public form: FormForCollaborationModule) {}
}

export class DeleteCollaborationModuleAction {
  constructor(public ids: string[]) {}
}

export type FormForCollaborationModule = FormForType<CollaborationModule>;

export const collaborationModuleFormCreator = formFor(
  CollaborationModule,
  s => {
    s.requiredField(a => a.Name, "Name");
    s.requiredField(a => a.Key, "Key", wrap(isValidKey));
    s.requiredField(a => a.FormId, "Form");
    s.field(a => a.SegmentQuery, "Query");
    s.field(a => a.SegmentClientData, "Client Data");

    s.field(a => a.CollaboratorRoleIds, "Role Ids");
    s.field(
      a => a.RequiredEvaluationCount,
      "Required # of Reviews",
      wrap(isInteger),
    );
    s.formArray(
      a => a.PinnedFieldGroups,
      "Summary Fields",
      pinnedFieldGroupFormCreator,
    );
    s.formArray(
      a => a.CalculatedFields,
      "Calculated Fields",
      AggregateCalculatedFieldFormCreator,
    );
  },
);
