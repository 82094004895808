import { FormForType, formFor, Validators } from "fw-model";
const { isEmail, wrap } = Validators;

import { NewUser, NewPassword } from "models/user";

export type AcceptInviteType = FormForType<NewUser>;

export class AcceptInviteAction {
  constructor(public form: AcceptInviteType, public token: string) {}
}

export const AcceptInviteForm = formFor(NewUser, s => {
  s.requiredField(u => u.EmailAddress, "Email Address", wrap(isEmail));
  s.requiredField(u => u.Password, "Password");
  s.requiredField(u => u.ConfirmPassword, "Confirm Password", v =>
    v.same(nu => nu.Password, "Must match new password"),
  );
  s.requiredField(u => u.FirstName, "First Name");
  s.requiredField(u => u.LastName, "Last Name");
});

export const newPasswordFormCreator = formFor(NewPassword, s => {
  s.requiredField(u => u.Password, "Password");
  s.requiredField(u => u.ConfirmPassword, "Confirm Password", v =>
    v.same(nu => nu.Password, "Must match new password"),
  );
});
