import { PopoverService } from "service/popover";
import { DropdownWithFilterPopover } from "../dropdown-with-filter-popover";
import { ComponentEventBus, prop, needs } from "fw";
import { Form } from "fw-model";
import { Popover } from "../popover";
import { IDropdownFieldOptions } from "../dropdown-field";

@needs(Popover)
export class DropdownWithFilterFormField {

  @prop(null) private form!: Form;
  @prop(false) private nullIsDirty;
  @prop(null) private label;
  @prop(false) public hideLabel!: boolean;
  @prop(null) private field!: string;
  @prop(false) private disabled;
  @prop(() => []) private options!: { value: any; text: string }[];
  @prop(null) private sortList;

  constructor(
    private popover: PopoverService,
    private ceb: ComponentEventBus
  ) {}

  private isFocused = false;

  private onFocus() { this.isFocused = true; }
  private onBlur() { this.isFocused = false; }

  private async openFilterSelector() {
    const res = await this.popover.open<{ result: string }>(DropdownWithFilterPopover, {
      selected: this.value,
      list: this.sortList ? this.sortOptionsList(this.options) : this.options
    });
    
    if (!res.canceled) {
      this.form[this.field] = res.result;
      this.change();
    }    
  }

  private sortOptionsList(list: IDropdownFieldOptions[]) {
    return list.filter(i => i.text).sort((a, b) => a.text.localeCompare(b.text));
  }

  public get name() {
    return this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;
  }

  public get value() {
    return this.form[this.field];
  }

  public get validation() {
    return this.form.validation[this.field];
  }

  public get selectedName() {
    return this.options.find(i => i.value === this.value)?.text;
  }

  private change() {
    this.ceb.dispatch("change");
  }

}
