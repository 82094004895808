import { inject, prop } from 'fw';
import { PopoverController } from "service/popover";

const DEFAULT_COLOR = {
    r: 255,
    g: 0,
    b: 255,
    a: 1,
    h: 3,
    s: 100,
    v: 100,
    hex: '#FF00FF',
    style: "rgba(255, 0, 255, 1)"
};

@inject
export class ColorPickerWrapper {

  public readonly initialColor = DEFAULT_COLOR;
  public colorObject = null;
  private initialColorObject = null;

  /**
   * constructor
   *
   * @param controller PopoverController
   */
  constructor(    
    private controller: PopoverController<any>,
  ) {
  }


/**
 * activate
 * 
 * @param params params.color string
 */
  async activate(params) {

    const input = this.getInitialColorFromValue(params.val);
    this.colorObject = input;
    this.initialColorObject = input;
  }  

  /**
   * isColorInitialColor
   * 
   * @returns bool true to disable apply button
   */
  public isColorInitialColor() {
    const initial: String = this.initialColorObject.hex;
    const current: String = this.colorObject.hex;
    return initial === current;
  }

  /**
   * acceptColor popover controller action for successful color pick
   */
  public acceptColor() {

    if (this.colorObject!=null) {

      let outputValue: String = this.colorObject.hex;
      if (outputValue.charAt(0)==='#') {
        outputValue = outputValue.substring(1);
      }

      this.controller.ok(outputValue);
    }
  }

  /**
   * getInitialColorFromValue
   * 
   * @param val 
   * 
   * @returns 
   */
   private getInitialColorFromValue(val) {
   
    if (val!=null) {
      return this.getRgbaFromValue(val);
    }
    return this.initialColor;

  }

  /**
   * getRGBAFromValue converts and RGB string into RGBA
   * 
   * @param val RGB string
   * 
   * @returns RBG object
   */
  private getRgbaFromValue(val) {
    const r = (Number) ( '0x' + val[0] + val[1]);
    const g = (Number) ( '0x' + val[2] + val[3]);
    const b = (Number) ( '0x' + val[4] + val[5]);

    const newColor = { r: r, b: b, g: g, a: 1, hex: '#'+val };

    return newColor;
  }

}
