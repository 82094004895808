import { inject } from "fw";
import { Store, handle } from "fw-state";
import { difference } from "lodash-es";

import { once } from "helpers/once";
import { LogoutAction, StartAction } from "./actions";
import { IdentityProvider, IdentityProviderAuthType } from "models/identity-provider";
import { IdentityProviderRepository } from "network/identity-provider-repository";

import {
  AddIdentityProviderAction,
  UpdateIdentityProviderAction
} from "forms/identity-provider";
import { FormErrorHandling } from "./error-handling";

interface IdentityProvidersStoreShape {
  identityProviders: IdentityProvider[];
}

export class EnsureIdentityProvidersStoreAction {
  constructor() { }
}

export class DeleteIdentityProvidersAction {
  constructor(public ids: string[]) { }
}

@inject
export class IdentityProvidersStore extends Store<IdentityProvidersStoreShape> {
  constructor(private identityRepo: IdentityProviderRepository) {
    super();
  }

  defaultState() {
    return {
      identityProviders: [],
    };
  }

  @handle(StartAction)
  @handle(LogoutAction)
  private handleStartAndLogoutActions() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureIdentityProvidersStoreAction)
  private async handleStart(action: EnsureIdentityProvidersStoreAction) {
    await once("ensure-identity-providers", async () => {
      const identityProviders = await this.identityRepo.list();

      this.setState(state => ({
        identityProviders: identityProviders,
      }));
    });
  }

  @handle(AddIdentityProviderAction, FormErrorHandling)
  private async handleAddIdentityProviderAction(
    action: AddIdentityProviderAction,
  ) {
    switch (action.authType) {
      case IdentityProviderAuthType.SAML2: action.form.OAuthSettings = null; break;
      case IdentityProviderAuthType.OAuth: action.form.SAML2Settings = null; break;
    }
    action.form.validate();

    const newIdentityProvider = await this.identityRepo.post(
      action.form.updatedModel(),
      action.authType
    );

    action.addedId = newIdentityProvider.Id;

    this.setState(state => ({
      identityProviders: [...state.identityProviders, newIdentityProvider],
    }));
  }

  @handle(UpdateIdentityProviderAction, FormErrorHandling)
  private async handleUpdateIdentityProviderAction(
    action: UpdateIdentityProviderAction,
  ) {
    switch (action.form.MetaData.AuthType) {
      case IdentityProviderAuthType.SAML2: action.form.OAuthSettings = null; break;
      case IdentityProviderAuthType.OAuth: action.form.SAML2Settings = null; break;
    }
    action.form.validate();

    const updatedProvider = await this.identityRepo.put(
      action.form.updatedModel(),
    );

    const existing = this.state.identityProviders.find(
      i => i.Id == updatedProvider.Id,
    );
    if (existing == null) return; // maybe even throw

    Object.assign(existing, updatedProvider);

    this.setState(s => s);
  }

  @handle(DeleteIdentityProvidersAction)
  private async handleDeleteIdentityProvidersAction(action: DeleteIdentityProvidersAction) {
    const idps = this.state.identityProviders.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.identityRepo.del(idps.map(t => t.Id));
    this.setState(state => ({
      identityProviders: difference(state.identityProviders, idps),
    }));
  }
}
