import { formFor, Validators, FormAsModel } from "fw-model";
const { required, wrap } = Validators;

import { WeightedScoreFieldInput, WeightedScoreSettings } from "models/calculated-field";

export const weightedScoreSettingsFormCreator = formFor(WeightedScoreSettings, s => {
  s.field(f => f.IgnoreNullFields, "Ignore Null Fields");
  s.formArray(f => f.Inputs, "Inputs", weightedScoreFieldInputFormCreator);
  s.requiredField(f => f.Scale, "Scale", wrap(integerRangeValueOperator(0, 1000)));
});

export const weightedScoreFieldInputFormCreator = formFor(WeightedScoreFieldInput, s => {
  s.requiredField(f => f.FieldKey, "FieldKey");
  s.requiredField(f => f.Label, "Label");
  s.requiredField(f => f.Weight, "Weight", wrap(rangeValueOperator(0, 5)));
});

const rangeValueOperator = (min: number, max: number) => {
  return (input, value: FormAsModel<WeightedScoreFieldInput>) => {
    if (input < min || input > max) {
      return "error range";
    }
    return required(input);
  };
};

const integerRangeValueOperator = (min: number, max: number) => {
  return (input, value: FormAsModel<WeightedScoreSettings>) => {
    if (input < min || input > max) {
      return "error range";
    }
    if (!Number.isInteger(parseFloat(input))) {
      return "value must be integer";
    }
    return required(input);
  };
};
