import { inject } from "fw";
import { CurrentOrganizationStore } from "state/current-organization";
import { CurrentUserStore } from "state/current-user";

@inject
export class HelpPopover {
  constructor(
    private currentOrganizationStore: CurrentOrganizationStore,
    private currentUserStore: CurrentUserStore
  ) {}

  activate() {}

  get orgName() {
    const { organization } = this.currentOrganizationStore.state;
    if (organization == null || organization.Name == null) return "";
    return organization.Name;
  }

  get mailSubject() {
    const { principal } = this.currentUserStore.state;
    return encodeURIComponent(
      `Admissions ticket from ${principal.FirstName} ${principal.LastName} for ${this.orgName}`
    );
  }

  get mailToUrl() {
    return `mailto:admissionssupport@liaisonedu.com?subject=${this.mailSubject}`;
  }

  public openChat() {
    // @ts-ignore
    if (window.adaEmbed) window.adaEmbed.toggle();
  }

  get customization() {
    return this.currentOrganizationStore.state.customization;
  }

  public getHelpCenterUrl() {
    return this.customization.helpCenterUrl?.length > 0 ? this.customization.helpCenterUrl : "https://help.liaisonedu.com/Outcomes";
  }
}
