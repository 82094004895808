import { ComponentEventBus, inject, prop } from "fw";
import { generateDataId } from "helpers/data-id-gen";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { ContactsOrganizationService } from "service/contacts-organization";
import { FieldMode } from "../field-mode";

@inject
export class LargestringType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(false) public showClear!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  public get localValue() {
    return this.value;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", value && value.trim());
  }

  public clear() {
    this.ceb.dispatch("update:value", null);
  }

  public get dataId() {
    return generateDataId(null, this.field.display_name);
  }

  public get metaDescription() {
    return this.contactOrganizationService.getFieldMetaDescription(this.field);
  }
}
