import { inject, needs, Navigator, RouterConfig } from "fw";
import { dispatch } from "fw-state";
import { debounce } from "lodash-es";

import { ContactTypeDefinition } from 'models/contact-organization';
import { GoalDefinition, GoalType } from "models/goal";
import { OrganizationModuleService } from "service/organization-module";
import { ContactStore } from "state/contacts";
import { GoalsStore, LoadGoalsAction, FilterGoalsAction, GoalView } from "state/goals";
import { SearchFilter } from "views/components/search-filter";
import { SearchNoResultsRow } from "views/components/search-no-results-row";
import { AddReportPopover } from "views/reports/add-report-popover";
import { ListRow } from "./list-row";

@inject
@needs(
  AddReportPopover,
  ListRow,
  SearchFilter,
  SearchNoResultsRow,
)
export class List {
  private static readonly Colors: string[] = [
    "#5571AD",
    "#b4bd00",
    "#4eaf9c",
    "#f18eab",
    "#98a6ad",
    "#e78747",
    "#55b8d4",
    "#c98ad2",
    "#aebaf6",
    "#c6ca87",
    "#80cabb",
    "#e2afbe",
    "#bcc9d0",
    "#ecb18a",
    "#7cc8de",
    "#dbb0e0"
  ];

  private sortFunc = (a, b) => {
    return a.definition.Name.localeCompare(b.definition.Name);
  };

  public router;
  private filter:string = null;

  constructor(
    private contactStore: ContactStore,
    private goalsStore: GoalsStore,
    private nav: Navigator,
    private organizationModuleService: OrganizationModuleService,
  ) {}

  registerRoutes(r: RouterConfig) {
    this.router = r;
  }

  public get goals(): GoalView[] {
    return this.goalsStore.state.goals
      .filter(g => !!g)
      .sort(this.sortFunc);
  }

  public get applicationSegmentGoals(): GoalView[] {
    return this.goalsStore.state.goals
      .filter(({ definition }: { definition: GoalDefinition }) => GoalType.Application === definition?.GoalType)
      .sort(this.sortFunc);
  }

  public get contactTypes(): ContactTypeDefinition[] {
    return this.contactStore.state.organization.contact_types.sort((a, b) => a.name.localeCompare(b.name));
  }

  public get contactSegmentGoals(): GoalView[] {
    return this.goalsStore.state.goals
      .filter(({ definition }: { definition: GoalDefinition }) => GoalType.Contact === definition?.GoalType)
      .sort(this.sortFunc);
  }

  public contactSegmentGoalsByType(contactTypeKey: string): GoalView[] {
    return this.goalsStore.state.goals.filter(({ definition }: { definition: GoalDefinition }) => {
        return GoalType.Contact === definition?.GoalType
          && contactTypeKey === definition.MetaData.ContactType;
      })
      .sort(this.sortFunc);
  }

  public async activate() {
    await dispatch(new LoadGoalsAction());
  }

  public editGoal(goalId: string): void {
    this.nav.navigate(`/analytics/goals/${goalId}`);
  }

  public newGoal(): void {
    this.nav.navigate("/analytics/goals/new");
  }

  public debouncedSearch = debounce(() => this.searchGoals(), 600);

  private async searchGoals(): Promise<void> {
    await dispatch(new FilterGoalsAction(this.filter));
  }

  public async refresh(): Promise<void> {
    await dispatch(new LoadGoalsAction(true));
  }

  public nextColor(id: string): string {
    return List.Colors[this.getHashCode(id) % List.Colors.length];
  }

  private getHashCode(value: string): number {
    let numbersOnlyValue = value.toString().replace(/a/g, "0");
    numbersOnlyValue = numbersOnlyValue.replace(/b/g, "1");
    numbersOnlyValue = numbersOnlyValue.replace(/c/g, "2");
    numbersOnlyValue = numbersOnlyValue.replace(/d/g, "3");
    numbersOnlyValue = numbersOnlyValue.replace(/e/g, "4");
    numbersOnlyValue = numbersOnlyValue.replace(/f/g, "5");

    return parseInt(numbersOnlyValue.substring(12));
  }

  public get isSearching(): boolean {
    return this.filter?.length > 0;
  }

  public clearFilters(): void {
    this.filter = null;
    dispatch(new FilterGoalsAction(this.filter));
  }
}
