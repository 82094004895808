import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { OrganizationPortal } from "models/organization";
import { Actor } from "models/app-organization-portal-context";

@inject
export class OrganizationPortalRepository {
  constructor(private s: ATS) {}

  public async get(id: string) {
    const res = await this.s.get("organization-portal");
    return createFrom(OrganizationPortal, res.body);
  }

  public async put(portal: OrganizationPortal) {
    const res = await this.s.put("organization-portal", portal);
    return createFrom(OrganizationPortal, res.body);
  }

  public async getActors(actors: Actor[]) {
    const res = await this.s.post<Actor[]>("app/organization-portal/get-actors", actors);
    return createFromArray(Actor, res.body);
  }
}
