import { inject, prop } from "fw"
import { ApplicationFilter as ApplicationFilterModel } from "models/application-filters"

@inject
export class ApplicationFilter
{
  @prop(null) filter!: ApplicationFilterModel;
  @prop(false) editing!: boolean;

  get options() {
    return [
      {
        value: true,
        text: "Has application",
      },
      {
        value: false,
        text: "Does not have application",
      }
    ]
  }

  get friendly() {
    return this.options.find((option) => option.value == this.filter.hasApplication)?.text;
  }
}
