import { inject, Navigator, needs } from "fw";

import { CurrentOrganizationStore } from "state/current-organization";

import { LoginForm } from "./login-form";
import { CurrentUserStore } from "state/current-user";

@inject
@needs(LoginForm)
export class Login {
  private redirectTo = "";
  private ssoRedirect = false;
  private showTwoFactor = false;
  // private domainLogoUrl: string = null; // null == not checked yet, "" = no domain logo

  constructor(
    private nav: Navigator,
    private orgStore: CurrentOrganizationStore,
    private currentUserStore: CurrentUserStore,
  ) {}

  async activate(data) {
    if (data && data.redirect) {
      this.redirectTo = data.redirect;
    }

    if (this.orgStore.state.portalContext != null) {
      const {
        OrganizationPortal: { AllowLocalLogin, Domain },
        IdentityProviders,
      } = this.orgStore.state.portalContext;

      // let's do some sso checking...
      if (AllowLocalLogin == false && IdentityProviders.length == 1) {
        this.ssoRedirect = true;
        window.location.href = `https://${Domain}/auth/${
          IdentityProviders[0].Id
        }/signin?domain=${encodeURIComponent(Domain)}`;
        return;
      }
    }
  }

  get logoutReason() {
    return this.currentUserStore.state.logoutReason;
  }

  loggedIn() {
    if (this.currentUserStore.state.loggedIn) {
      this.nav.navigate(this.redirectTo);
      return;
    }

    this.nav.navigate(`/choose-organization?redirect=${this.redirectTo}`);
  }
}
