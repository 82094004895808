import { inject, needs, prop } from "fw";
import { ContactsMultiselectFilter as ContactsMultiselectFilterModel }  from "models/contacts-filters";
import { Field } from 'views/contacts/components/field';

@inject
@needs(Field)
export class ContactsMultiselectFilter {
  @prop(null) filter!: ContactsMultiselectFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  get modeText() {
    return {
      any: "Any of the following",
      all: "All of the following",
      none: "None of the following",
    };
  }

  get modes() {
    return [
      { text: this.modeText["any"], value: "any" },
      { text: this.modeText["all"], value: "all" },
      { text: this.modeText["none"], value: "none" },
    ];
  }
}
