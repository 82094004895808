import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LogoutAction, StartAction } from "./actions";
import { MarketingOutreachItemRepository } from "network/marketing-outreach-item-repository";
import { MarketingOutreachItem } from "models/marketing-outreach-item";
import {
  CreateMarketingOutreachItemAction,
  UpdateMarketingOutreachItemAction,
  DeleteMarketingOutreachItemAction,
  CopyMarketingOutreachItemAction,
  MoveMarketingOutreachItemAction,
  ActivateMarketingOutreachItemAction,
  DeactivateMarketingOutreachItemAction
} from "forms/marketing-outreach-item";
import { FormErrorHandling } from "./error-handling";

interface OutreachItemShape {
  loadedTiers: number[];
  loadedTracks: number[];
  loadedTemplates: number[];
  collection: MarketingOutreachItem[];
}

export class FetchMarketingOutreachItemsAction {
  constructor(public tierId: number = 0, public trackId: number = 0, public templateId: number = 0) {}
}
export class FetchMarketingOutreachItemAction {
  constructor(public id: number) {}
}

@inject
export class MarketingOutreachItemStore extends Store<OutreachItemShape> {
  constructor(private network: MarketingOutreachItemRepository) {
    super();
  }

  defaultState() {
    return { loadedTiers: [], loadedTracks: [], loadedTemplates: [], collection: [] };
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    this.setState(state => ({ ...this.defaultState() }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(state => this.defaultState());
  }

  @handle(FetchMarketingOutreachItemsAction)
  private async handleFetchMarketingOutreachItems(action: FetchMarketingOutreachItemsAction) {
    let loadedItem = '', itemId = 0;

    if (action.tierId) {
      loadedItem = 'loadedTiers';
      itemId = action.tierId;
    } else if (action.trackId) {
      loadedItem = 'loadedTracks';
      itemId = action.trackId;
    } else if (action.templateId) {
      loadedItem = 'loadedTemplates';
      itemId = action.templateId;
    }

    if (this.state[loadedItem].find(i => i == itemId)) return;

    const newItems = await this.network.index(action.tierId, action.trackId, action.templateId);
    const loaded = this.state[loadedItem].slice();
    const collection = this.state.collection.slice();

    loaded.push(itemId);
    collection.push(...newItems.filter(i => ! collection.find(j => j.Id == i.Id)));
    if (action.tierId) this.setState(state => ({ ...state, loadedTiers: loaded, collection: collection }));
    else if (action.trackId) this.setState(state => ({ ...state, loadedTracks: loaded, collection: collection }));
    else this.setState(state => ({ ...state, loadedTemplates: loaded, collection: collection }));
  }

  @handle(FetchMarketingOutreachItemAction)
  private async handleFetchMarketingOutreachItem(action: FetchMarketingOutreachItemAction) {
    if (this.state.collection.find(i => i.Id == action.id)) return;

    const newItem = await this.network.show(action.id);
    const collection = this.state.collection.slice();

    collection.push(newItem);
    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(CreateMarketingOutreachItemAction, FormErrorHandling)
  private async handleCreateMarketingOutreachItem(action: CreateMarketingOutreachItemAction) {
    action.form.validate();

    const newItem = await this.network.post(action.form.updatedModel());
    const collection = this.state.collection.slice();

    collection.push(newItem);
    this.setState(state => ({ ...state, collection: collection }));
    action.created = newItem;
  }

  @handle(UpdateMarketingOutreachItemAction, FormErrorHandling)
  private async handleUpdateMarketingOutreachItem(action: UpdateMarketingOutreachItemAction) {
    action.form.validate();

    const updatedItem = await this.network.put(action.form.updatedModel());
    const collection = this.state.collection.slice();
    const currentItem = collection.find(i => i.Id == updatedItem.Id);

    collection[collection.indexOf(currentItem)] = updatedItem;
    this.setState(state => ({ ...state, collection: collection }));
    action.updated = updatedItem;
  }

  @handle(DeleteMarketingOutreachItemAction)
  private async handleDeleteMarketingOutreachItem(action: DeleteMarketingOutreachItemAction) {
    const item = this.state.collection.find(i => i.Id == action.id);
    if (! item) return;

    await this.network.destroy(action.id);
    this.state.collection.splice(this.state.collection.indexOf(item), 1);
  }

  @handle(CopyMarketingOutreachItemAction, FormErrorHandling)
  private async handleCopyMarketingOutreachItem(action: CopyMarketingOutreachItemAction) {
    if (! this.state.collection.find(i => i.Id == action.id)) return;

    const copiedItem = await this.network.copy(action.id);
    const collection = this.state.collection.slice();

    collection.push(copiedItem);
    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(MoveMarketingOutreachItemAction, FormErrorHandling)
  private async handleMoveMarketingOutreachItem(action: MoveMarketingOutreachItemAction) {
    const item = this.state.collection.find(i => i.Id == action.itemId);
    if (! item) return;

    const movedItem = await this.network.move(action.itemId, action.tierId);
    const collection = this.state.collection.slice();

    collection.push(movedItem);
    collection.splice(collection.indexOf(item), 1);

    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(ActivateMarketingOutreachItemAction, FormErrorHandling)
  private async handleActivateMarketingOutreachItem(action: ActivateMarketingOutreachItemAction) {
    if (! this.state.collection.find(i => i.Id == action.id)) return;

    const updatedItem = await this.network.activate(action.id);
    const collection = this.state.collection.slice();
    const currentItem = collection.find(i => i.Id == updatedItem.Id);

    collection[collection.indexOf(currentItem)] = updatedItem;
    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(DeactivateMarketingOutreachItemAction, FormErrorHandling)
  private async handleDeactivateMarketingOutreachItem(action: DeactivateMarketingOutreachItemAction) {
    if (! this.state.collection.find(i => i.Id == action.id)) return;

    const updatedItem = await this.network.deactivate(action.id);
    const collection = this.state.collection.slice();
    const currentItem = collection.find(i => i.Id == updatedItem.Id);

    collection[collection.indexOf(currentItem)] = updatedItem;
    this.setState(state => ({ ...state, collection: collection }));
  }
}
