import { prop,inject } from "fw";
import { PopoverController } from "service/popover";
import { AggregationType } from "shared/report-runtime";
import { ChartField } from '../../applicants/chart/field-selector';
import { CurrentContactOrganizationStore } from 'state/current-contact-organization';
import { CustomFieldType } from 'models/contact-organization';

export interface ChartFieldSelectorOptions {
  fields: ChartField[];
  selectedChartField?: ChartField;
  updateOptionFocus?: Function;
}

@inject
export class ChartContactsFieldSelector {
  public fields: ChartField[] = [];
  public selectedChartField: ChartField = null;
  public searchTerm: string = "";
  public updateOptionFocus: Function = () => {};
  
  constructor(
    private controller: PopoverController<ChartField>,
    private currentContactOrganizationStore: CurrentContactOrganizationStore
  ) { }

  public activate(options: ChartFieldSelectorOptions) {
    this.fields = options.fields;
    this.selectedChartField = options?.selectedChartField;
    if (options?.updateOptionFocus) {
      this.updateOptionFocus = options?.updateOptionFocus;
    }
    if (this.selectedChartField) {
      this.updateOptionFocus(this.makeOptionId(this.selectedChartField));
    }
  }

  public makeOptionId(field: ChartField): string {
    const pathAsId = field?.Path?.replace(/\./g, "");
    return !!pathAsId ? `${pathAsId}-ccfs` : null;
  }

  public selectField(field: ChartField) {
    this.selectedChartField = field;
    if (this.selectedChartField) {
      this.updateOptionFocus(this.makeOptionId(this.selectedChartField));
    }
    this.controller.ok(field);
  }

  public search(fields: ChartField[]) {
    if (this.searchTerm.length) {
      return fields.filter(f => f.Label ? f.Label.toLowerCase().includes(this.searchTerm.toLowerCase()) : false);
    } else {
      return fields;
    }
  }
}
