import { FormForType, formFor, Validators } from "fw-model";

import { ContentTemplate, ExternalData } from "models/content-template";
import { headerDataFromCreator } from "./file-provider";
import { isValidKey, noNewLines } from "./validators";

const { wrap, isUrl } = Validators;

export type FormForContentTemplate = FormForType<ContentTemplate>;

export class UpdateContentTemplateAction {
  constructor(public form: FormForContentTemplate) {}
}

export class AddContentTemplateAction {
  constructor(public form: FormForContentTemplate) {}
  public addedId: string = null;
}

export class DeleteContentTemplatesAction {
  constructor(public ids: string[]) {}
}

export type FormFormExternalData = FormForType<ExternalData>;
export const externalDataFormCreator = formFor(ExternalData, s => {
  s.requiredField(e => e.Key, "Key", wrap(isValidKey));
  s.requiredField(e => e.Method, "Method");
  s.requiredField(e => e.Url, "Url", wrap(noNewLines, isUrl({ requireProtocol: true, allowedProtocols: [ "https" ], allowPath: true })));

  s.formArray(e => e.Headers, "Headers", headerDataFromCreator);
});

export const contentTemplateFormCreator = formFor(ContentTemplate, s => {
  s.requiredField(cb => cb.Label, "Name");
  s.requiredField(cb => cb.Template , "Html Template");
  s.field(cb => cb.TransformFunction , "Transform Function");

  s.formArray(cb => cb.ExternalData, "External Data", externalDataFormCreator);
});
