import { inject, prop, needs } from "fw";

import { ApplicationProperty } from "models/application-settings";
import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { ProgramPropertyFilter as ProgramPropertyFilterModel } from "models/application-filters";
import { ProgramStore } from "state/program";
import { DataPolicyService } from "service/data-policy";
import { ValueField, ValueType } from "./value-field";
import { FeatureFlagService } from "service/feature-flag";

@inject
@needs(ValueField)
export class ProgramPropertyFilter {
  @prop(null) filter!: ProgramPropertyFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  private valueTypes = ValueType;
  private valueType = ValueType.Text;

  private enumOptions: { text: string, value: string }[] = [];

  constructor(private programStore: ProgramStore,private ffs: FeatureFlagService, private dataPolicy: DataPolicyService) { }

  attached() {
    this.fieldChanged(false);
  }

  propertyForEach(fn: (property: ApplicationProperty) => void) {
    const restrictions = this.dataPolicy.applicationPropertyPaths;
    for (const property of this.programStore.state.settings.ProgramProperties) {
      if (restrictions.indexOf(property.Key) !== -1)
        continue;

      fn(property);
    }
  }

  get fields() {
    const fields: { text: string, value: string }[] = [];

    this.propertyForEach(property => {
      fields.push({ text: property.Label, value: property.Key });
    });

    return fields;
  }

  get fieldHash() {
    const newHash: { [key: string]: ApplicationProperty } = {};

    this.propertyForEach(property => {
      newHash[property.Key] = property;
    });

    return newHash;
  }

  private fieldChanged(clearValue = true) {
    if (clearValue) {
      this.filter.value = null;
      this.filter.operator = "=";
    }

    if (this.filter.field == null) {
      this.valueType = ValueType.Text;
      return;
    }

    //  prevent removed properties and restrictions from blowing up the client
    if (!this.fieldHash[this.filter.field]) return;

    switch (this.fieldHash[this.filter.field].DataType) {
      case CalculatedFieldDataTypeCode.Number:
        this.filter.shouldQuoteValue = false;
        this.valueType = ValueType.Number;
        break;

      case CalculatedFieldDataTypeCode.Enumeration:
        this.filter.shouldQuoteValue = true;
        this.enumOptions = this.fieldHash[this.filter.field].EnumerationOptions.map(eo => ({ text: eo.Label, value: eo.Label }));
        this.valueType = ValueType.Dropdown;
        if (this.useDropdownFilterAsKeyword) {
          if (this.filter.operator == "=")
            this.filter.operator = "==";
        }  
        break;

      case CalculatedFieldDataTypeCode.Boolean:
        this.filter.shouldQuoteValue = false;
        this.enumOptions = [
          { text: "Yes", value: "true" },
          { text: "No", value: "false" },
        ];
        this.valueType = ValueType.Dropdown;
        break;

      case CalculatedFieldDataTypeCode.File:
        this.filter.shouldQuoteValue = false;
        this.valueType = ValueType.AnsweredOnly;
        break;

      case CalculatedFieldDataTypeCode.String:
        this.filter.shouldQuoteValue = true;
        this.valueType = ValueType.Text;
        break;

      default:
        this.filter.shouldQuoteValue = false;
        this.valueType = ValueType.Text;
        break;
    }
  }

  get filterText() {
    switch (this.filter.operator) {
      case "~": return "is answered.";
      case "!~": return "is not answered.";
    }

    return `${this.filter.operator} ${this.filter.value}`;
  }

  get useDropdownFilterAsKeyword() {
    return this.ffs.isFeatureFlagEnabled("DropdownFilterAsKeyword8366");
  }
}
