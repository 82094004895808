import { Validators, FormForType, formFor } from "fw-model";
const { isInteger, isEmail, required, wrap } = Validators;

import { isValidSegment } from "./validators";
import {
  ScheduledExport,
  Schedule,
  DailyScheduleOptions,
  WeeklyScheduleOptions,
  MonthlyScheduleOptions,
  EventNotification,
  CustomFrequencyScheduleOptions,
  ScheduleRecurrenceType,
  ExportRecordFrequency,
} from "models/scheduled-export";

import { ExportDeliveryOptions } from "models/export-task";
import { timeOfDayFormCreator } from "./calendar-event";

export type ScheduledExportFormType = FormForType<ScheduledExport>;
export type ExportDeliveryOptionsFormType = FormForType<ExportDeliveryOptions>;

export class UpdateScheduledExportAction {
  constructor(public form: ScheduledExportFormType, public newOwnerUserId: string, public hasStartEndDateSupport: boolean) { }
}

export class AddScheduledExportAction {
  public addedId: string;
  constructor(public form: ScheduledExportFormType, public ownerUserId: string, public hasStartEndDateSupport: boolean) { }
}

export class RunScheduledExportAction {
  constructor(public id: string) { }
}

export class DeleteScheduledExportsAction {
  constructor(public ids: string[]) { }
}

export const dailyScheduleOptionsFormCreator = formFor(
  DailyScheduleOptions,
  s => {
    s.field(f => f.HourTimes, "Hours");
  },
);

export const weeklyScheduleOptionsFormCreator = formFor(
  WeeklyScheduleOptions,
  s => {
    s.field(f => f.HourTimes, "Hours");
    s.field(f => f.DaysOfWeek, "Days Of Week");
  },
);

export const monthlyScheduleOptionsFormCreator = formFor(
  MonthlyScheduleOptions,
  s => {
    s.field(f => f.HourTimes, "Hours");
    s.field(f => f.Days, "Days Of Month");
  },
);

export const customFrequencyScheduleOptionsFormCreator = formFor(
  CustomFrequencyScheduleOptions,
  s => {
    s.field(f => f.Interval, "Interval (in minutes)");
    s.form(f => f.StartTime, "Start Time", timeOfDayFormCreator);
    s.form(f => f.EndTime, "End Time", timeOfDayFormCreator);
  }
)

export const scheduleFormCreator = formFor(Schedule, s => {
  s.requiredField(f => f.Type, "Type");
  s.field(f => f.StartDate, "Start Date", v => v.if(s => s.Type === ScheduleRecurrenceType.CustomFrequency, required));
  s.field(f => f.EndDate, "End Date", v => v.if(s => isRequiredField(s, s.StartDate), required));
  s.form(f => f.Daily, "Daily", dailyScheduleOptionsFormCreator);
  s.form(f => f.Weekly, "Weekly", weeklyScheduleOptionsFormCreator);
  s.form(f => f.Monthly, "Monthly", monthlyScheduleOptionsFormCreator);
  s.form(f => f.CustomFrequency, "Custom Frequency", customFrequencyScheduleOptionsFormCreator);
});

export const exportDeliveryOptionsFormCreator = formFor(ExportDeliveryOptions, s => {
  s.field(u => u.FileProviderId, "Provider");
  s.field(u => u.FileProviderFolder, "Folder");

  s.field(u => u.ShouldZipContents, "Zip");
  s.field(u => u.ZipFileCount, "", wrap(isInteger));
  s.field(u => u.ZipFileNameFormat, "Zip File Name Format");
});

export const eventNotificationFormCreator = formFor(EventNotification, s => {
  s.requiredField(f => f.EmailAddress, "Email Address", wrap(isEmail));
  s.field(f => f.SubscribedEvents, "Events");
});

export const scheduledExportFormCreator = formFor(ScheduledExport, s => {
  s.field(u => u.Name, "Name");
  s.field(u => u.IsEnabled, "Active");
  s.field(u => u.SegmentId, "Segment", wrap(isValidSegment));
  s.field(u => u.ExportDefinitionId, "Export");
  s.field(u => u.MetaData.ActorType, "OwnerType")
  s.field(u => u.MetaData.ActorId, "Owner");

  s.formArray(u => u.Notifications, "Notifications", eventNotificationFormCreator);

  s.form(u => u.Delivery, "Delivery", exportDeliveryOptionsFormCreator);
  s.form(u => u.Schedule, "Schedule", scheduleFormCreator);
  s.field(u => u.ExportFrequency, "Export Frequency");
  s.field(u => u.WatchFieldPath, "Watch Field Path", v => v.if(s => s.ExportFrequency === ExportRecordFrequency.OnChange, required));
});

const isRequiredField = (s: Schedule, date: string): boolean => {
  if (s.Type === ScheduleRecurrenceType.Once) {
    return false;
  }

  if (s.Type === ScheduleRecurrenceType.CustomFrequency) {
    return true;
  }

  return !!date;
}