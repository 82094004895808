import { prop, inject } from "fw";
import { dispatch } from "fw-state";
import { EnsureActorsAction, ActorsStore } from "state/actors";
import { Actor } from "models/app-organization-portal-context";

@inject
export class LazyActor {
  @prop(null) type!: string;
  @prop(null) id!: string;
  @prop("div") rootType!: string;

  private isLoading = false;

  constructor(private actorStore: ActorsStore) { }

  public get loading(): boolean {
    if (this.actor != null) return false;
    return this.isLoading;
  }

  public get name(): string {
    if (this.id == null) return null;
    const { actorHash } = this.actorStore.state;
    if (actorHash[this.id] == null) { return null; }
    return `${actorHash[this.id].FirstName} ${actorHash[this.id].LastName}`;
  }

  public get actor(): Actor {
    if (this.id == null) return null;

    const { actorHash } = this.actorStore.state;

    if (!actorHash[this.id]) {
      if (!this.isLoading) {
        this.isLoading = true;
        dispatch(new EnsureActorsAction([{ Type: this.type, Id: this.id }]));
      }
      return null;
    }

    return actorHash[this.id];
  }
}
