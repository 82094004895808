import { needs, prop, inject } from "fw";
import { ContactsDateFilter as ContactsDateFilterModel } from "models/contacts-filters";
import { getFriendlyTiming } from "models/date-filter";
import { ValueField, ValueType } from "../application-filters/value-field";

@inject
@needs(ValueField)
export class ContactsDateFilter {
  @prop(null) filter!: ContactsDateFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  valueType = ValueType.Date;

  get filterText() {
    if (this.filter == null) return "";
    return getFriendlyTiming(this.filter);
  }
}
