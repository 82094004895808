import { inject, prop } from "fw";
import { dispatch } from 'fw-state';
import { hashOn } from "hashing";
import { groupBy } from "lodash-es";
import { ContactSegmentStore, EnsureContactSegmentStoreAction } from "state/contact-segments";
import { ContactsSegmentFilter as ContactsSegmentFilterModel } from "models/contacts-filters";

@inject
export class ContactsSegmentFilter {
  @prop(null) public filter!: ContactsSegmentFilterModel;
  @prop(() => ({})) public validation;
  @prop(false) public editing!: boolean;

  constructor(private contactSegmentStore: ContactSegmentStore) { }

  public async attached() {
    await dispatch(new EnsureContactSegmentStoreAction());
  }

  get contactTypeSegments() {
    return this.filter && this.filter.contactType
      ? this.contactSegmentStore.state.segments.filter(s => s.contact_type === this.filter.contactType)
      : this.contactSegmentStore.state.segments;
  }

  get segmentNameHash() {
    return hashOn(this.contactTypeSegments, s => s.id, s => s.display_name);
  }

  get segments() {
    let segments = this.contactTypeSegments;
    if (this.filter != null && this.filter.category != null)
      segments = segments.filter(s => s.category == this.filter.category);

    return segments.map(s => ({ text: s.display_name, value: s.id }));
  }

  get categories() {
    const categories = groupBy(this.contactTypeSegments.filter(s => s.category != null), s => s.category);
    const c = Object.keys(categories).map(c => ({ text: c, value: c }));

    return [
      { text: "All", value: null },
      ...c
    ];
  }
}
