import { formFor, FormForType, Validators } from 'fw-model';
import { StudentPortal, StudentPortalContent, StudentPortalSocial } from 'models/student-portal';

const { wrap, isUrl, required } = Validators;

export class CreateStudentPortalAction {
  newPortal: StudentPortal;
  constructor(public form: StudentPortalForm) { }
}

export class UpdateStudentPortalAction {
  constructor(public form: StudentPortalForm) { }
}

export type StudentPortalForm = FormForType<StudentPortal>;
export type StudentPortalContentForm = FormForType<StudentPortalContent>;
export type StudentPortalSocialForm = FormForType<StudentPortalSocial>;

export const updateValue = {};

export const StudentPortalContentFormCreator = formFor(
  StudentPortalContent,
  s => {
    s.field(c => c.FavIconFileId, "Fav Icon File Id");
    s.field(c => c.FavIconUrl, "Fav Icon Url");
    s.field(c => c.OrganizationDisplayName, "Organization Display Name");
    s.field(c => c.OrganizationWebsiteUrl, "Organization Website Url");
    s.field(c => c.SupportEmailAddress, "Support Email Address");
    s.field(c => c.SupportPhoneNumber, "Support Phone Number");
    s.field(c => c.FullLogoFileId, "Full Logo File Id");
    s.field(c => c.FullLogoUrl, "Full Logo Url");
    s.field(c => c.CompactLogoFileId, "Compact Logo File Id");
    s.field(c => c.CompactLogoUrl, "Compact Logo Url");
    s.requiredField(c => c.PrimaryColor, "Primary Color");
    s.requiredField(c => c.SecondaryColor, "Secondary Color");
    s.requiredField(c => c.TertiaryColor, "Tertiary Color");
    s.requiredField(c => c.NavBarColor, "Navigation Bar Color");
    s.field(c => c.TermsAndConditionsMessage, "Terms And Conditions Message");
    s.field(c => c.DashboardWelcomeBGImageFileId, "Dashboard Welcome Background Image File Id");
    s.field(c => c.DashboardWelcomeBGImageUrl, "Dashboard Welcome Background Image Url");
    s.field(c => c.DashboardWelcomeMessage, "Dashboard Welcome Message (in HTML)");
    s.field(c => c.WelcomeMessageBGImageFileId, "Login Welcome Background Image File Id");
    s.field(c => c.WelcomeMessageBGImageUrl, "Login Welcome Background Image Url");
    s.field(c => c.WelcomeMessage, "Login Welcome Message (in HTML)");
    s.field(c => c.TrackingScript, "Tracking Script");
    //s.requiredField(c => c.ProgramsHeaderTitle, "Programs Header Title");
    s.field(c => c.ProgramsHeaderTitle, "Programs Header Title");
    s.field(c => c.ProgramsHeaderDescription, "Programs Header Description");
    // s.field(c => c.ProgramsHeaderImageFileId, "Programs Header Image File Id", wrap((i, m) => m.ProgramsHeaderImageUrl ? null : required(i)));
    // s.field(c => c.ProgramsHeaderImageUrl, "Programs Header Image Url", wrap((i, m) => m.ProgramsHeaderImageFileId ? null : required(i)));
    s.field(c => c.ProgramsHeaderImageFileId, "Programs Header Image File Id");
    s.field(c => c.ProgramsHeaderImageUrl, "Programs Header Image Url");
    s.field(c => c.FullLogoLightFileId, "Full Logo File Id");
    s.field(c => c.FullLogoLightUrl, "Full Logo Url");
    s.field(c => c.CompactLogoLightFileId, "Compact Logo File Id");
    s.field(c => c.CompactLogoLightUrl, "Compact Logo Url");
  },
);

export const StudentPortalSocialFormCreator = formFor(
  StudentPortalSocial,
  s => {
    s.field(c => c.FaceBookPageUrl, "FaceBook Page Url");
    s.field(c => c.LinkedInPageUrl, "LinkedIn Page Url");
    s.field(c => c.TwitterPageUrl, "Twitter Page Url");
    s.field(c => c.YouTubePageUrl, "YouTube Page Url");
    s.field(c => c.InstagramPageUrl, "Instagram Page Url");
    s.field(c => c.TikTokPageUrl, "TikTok Page Url");
    s.field(c => c.SnapchatPageUrl, "Snapchat Page Url");
    s.field(c => c.FlickrPageUrl, "Flickr Page Url");
  },
);


export const StudentPortalFormCreator = formFor(StudentPortal, s => {
  s.requiredField(p => p.Name, "Name");
  s.requiredField(p => p.Domain, "Domain", wrap(isUrl({ allowPort: process.env.NODE_ENV == "development" })));
  s.field(p=> p.EnableApplicationsModule, "Enable Applications Module");
  s.field(p=> p.EnableEventsModule, "Enable Events Module");
  s.field(p=> p.RelationshipFields, "Relationships");
  s.form(p => p.Content, "Content", StudentPortalContentFormCreator);
  s.form(p => p.Social, "Social", StudentPortalSocialFormCreator);
});
