import { inject } from "fw";
import { DialogService } from "fw-dialog";

import { CurrentUserStore } from "state/current-user";
import { PopoverController } from "service/popover";
import { FeatureFlagService } from "service/feature-flag";

@inject
export class OrgPopover {
  constructor(
    private ffs: FeatureFlagService,
    private currentUserStore: CurrentUserStore,
    private dialogService: DialogService,
    private controller: PopoverController<void>
  ) {}

  get nonImpersonatedUserAvailable() {
    return (
      this.currentUserStore.state.loggedIn &&
      this.currentUserStore.state.user != null &&
      this.currentUserStore.state.impersonating == false
    );
  }

  get isGlobal() {
    return (
      this.nonImpersonatedUserAvailable &&
      this.currentUserStore.state.isGlobalPrincipal
    );
  }

  async openAudits() {
    this.controller.close();

    const { AuditsDialog } = await import("views/global-admin/audits-dialog");
    this.dialogService.open(AuditsDialog);
  }
}
