import { prop } from "fw";

type DecisionLetterOption = { text: string, value: boolean };

const DECISION_LETTER_OPTIONS: DecisionLetterOption[] = [
  { text: "Has a Decision Letter", value: true },
  { text: "Does not have a Decision Letter", value: false },
];

export class DecisionLetterFilter {
  @prop(() => ({})) validation;
  @prop(null) filter;
  @prop(false) editing!: boolean;

  private decisionLetterOptions: DecisionLetterOption[] = DECISION_LETTER_OPTIONS;

  get selectedLabel(): string {
    return this.decisionLetterOptions
               .find(item => item.value === this.filter.hasDecisionLetter)?.text ?? "None";
  }
}

