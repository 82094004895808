import { inject, Route } from "fw";
import { UpdateRoutePath } from "state/route-path";
import { dispatch } from "fw-state";

@inject
export class RouterPath {
  navigating(r: Route, fullRoute: string) {
    // Save every route change inside route path store
    dispatch(new UpdateRoutePath(fullRoute));
    return true;
  }
}
