import { ComponentEventBus, inject, prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import type { IAddressData } from "models/contact";
import { FieldMode } from "../field-mode";
import { ContactsOrganizationService } from "service/contacts-organization";
import { generateDataId } from "helpers/data-id-gen";
import { ContactsFieldEditService } from "service/contacts-field-edit-service";
import { getFullContactAddress } from "helpers/address";

@inject
export class AddressType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: IAddressData;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(true) public setfocus!: boolean;
  @prop(false) public showClear!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService,
    private contactFieldEditService: ContactsFieldEditService
  ) {}

  public clear() {
    this.value = null;
    this.ceb.dispatch("update:value", null);
  }

  public get dataId() {
    return generateDataId(null, this.field.display_name);
  }

  public get metaDescription() {
    return this.contactOrganizationService.getFieldMetaDescription(this.field);
  }

  public get localAddress1(): string {
    return this.value && this.value.address1;
  }

  public set localAddress1(address: string) {
    this.update({
      ...this.value,
      address1: address && address.trim(),
    });
  }

  public get localAddress2(): string {
    return this.value && this.value.address2;
  }

  public set localAddress2(address: string) {
    this.update({
      ...this.value,
      address2: address && address.trim(),
    });
  }

  public get localCity(): string {
    return this.value && this.value.city;
  }

  public set localCity(city: string) {
    this.update({
      ...this.value,
      city: city && city.trim(),
    });
  }

  public get localState(): string {
    return this.value && this.value.state;
  }

  public set localState(state: string) {
    this.update({
      ...this.value,
      state: state && state.trim(),
    });
  }

  public get localPostalCode(): string {
    return this.value && this.value.postal_code;
  }

  public set localPostalCode(postalCode: string) {
    this.update({
      ...this.value,
      postal_code: postalCode && postalCode.trim(),
    });
  }

  public get localCountry(): string {
    return this.value && this.value.country;
  }

  public set localCountry(country: string) {
    this.update({
      ...this.value,
      country: country && country.trim(),
    });
  }

  public get gridValue(): string {
    return getFullContactAddress(this.value);
  }

  private isEmpty(address: IAddressData): boolean {
    if (!address) return true;

    return !address.address1 && !address.address2 && !address.city && !address.country && !address.postal_code;
  }

  private update(address: IAddressData) {
    if(this.isEmpty(address)) {
      this.clear();
      return;
    }
    
    this.ceb.dispatch("update:value", address);
  }
}
