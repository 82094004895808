import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS } from "./ats";

import { TaskRequest } from "models/task-request";
import { EntitySelection, EntitySelectionWithExportDefinition } from "models/application-client-model";
import { ExportDefinition } from "models/export-definition";

@inject
export class ExportRepository {
  constructor(private s: ATS) { }

  async json(
    selection: EntitySelection,
    exportDefinitionId: string = null,
    fileProviderId: string = null,
    fileProviderFolder: string = null,
    seasonId: string = null,
  ) {
    const path = exportDefinitionId == null
      ? "export/json"
      : `export/json/${exportDefinitionId}`;

    const res = await this.s.post(path, selection, {
      fileProviderId,
      fileProviderFolder,
      seasonId
    });

    return createFrom(TaskRequest, res.body);
  }

  async tabular(
    selection: EntitySelection,
    exportDefinitionId: string,
    exportDefinition: ExportDefinition,
    fileProviderId: string = null,
    fileProviderFolder: string = null,
    seasonId: string = null,
  ) {

    var entitySelectionWithExportDefinition: EntitySelectionWithExportDefinition = this.getEntitySelectionWithExportDefinition(selection, exportDefinition);

    const res = await this.s.post(
      exportDefinitionId ? `export/tabular/${exportDefinitionId}` : `export/tabular`,
      entitySelectionWithExportDefinition,
      {
        fileProviderId,
        fileProviderFolder,
        seasonId
      }
    );

    return createFrom(TaskRequest, res.body);
  }

  async pdf(
    selection: EntitySelection,
    exportDefinitionId = null,
    fileProviderId: string = null,
    fileProviderFolder: string = null,
    zipFileNameFormat: string = null,
    zipFileCount: number = null,
    seasonId: string = null
  ) {
    const path = exportDefinitionId == null
      ? "export/pdf"
      : `export/pdf/${exportDefinitionId}`;

    const res = await this.s.post(path, selection, {
      fileProviderId,
      fileProviderFolder,
      zipFileCount,
      zipFileNameFormat,
      seasonId
    });

    return createFrom(TaskRequest, res.body);
  }

  async files(
    selection: EntitySelection,
    exportDefinitionId: string,
    fileProviderId: string = null,
    fileProviderFolder: string = null,
    seasonId: string = null,
  ) {
    const res = await this.s.post(
      `export/files/${exportDefinitionId}`,
      selection,
      {
        fileProviderId,
        fileProviderFolder,
        seasonId
      },
    );

    return createFrom(TaskRequest, res.body);
  }

  async contactJson(
    contactTypeKey: string,
    selection: EntitySelection,
    exportDefinitionId: string = null,
  ) {
    const path = exportDefinitionId == null
      ? "export/contact-json"
      : `export/contact-json/${exportDefinitionId}`;

    const res = await this.s.post(path, selection, { contactTypeKey });

    return createFrom(TaskRequest, res.body);
  }

  async contactTabular(
    contactTypeKey: string,
    selection: EntitySelection,
    exportDefinition: ExportDefinition,
    exportDefinitionId: string = null,
  ) {
    const path = exportDefinitionId == null
      ? "export/contact-tabular"
      : `export/contact-tabular/${exportDefinitionId}`;

    var entitySelectionWithExportDefinition: EntitySelectionWithExportDefinition = this.getEntitySelectionWithExportDefinition(selection, exportDefinition);


    const res = await this.s.post(path, entitySelectionWithExportDefinition, { contactTypeKey });

    return createFrom(TaskRequest, res.body);
  }

  async userTabular(
    selection: EntitySelection,
    exportDefinitionId: string,
    exportDefinition: ExportDefinition,
    fileProviderId: string = null,
    fileProviderFolder: string = null,
    seasonId: string = null,
  ) {
    const path = exportDefinitionId == null
      ? `export/user-tabular`
      : `export/user-tabular/${exportDefinitionId}`;
    
    var entitySelectionWithExportDefinition: EntitySelectionWithExportDefinition = this.getEntitySelectionWithExportDefinition(selection, exportDefinition);

    const res = await this.s.post(
      path,
      entitySelectionWithExportDefinition,
      {
        fileProviderId,
        fileProviderFolder,
        seasonId
      }
    );

    return createFrom(TaskRequest, res.body);
  }

  async userJson(
    selection: EntitySelection,
    exportDefinitionId: string,
    exportDefinition: ExportDefinition,
    fileProviderId: string = null,
    fileProviderFolder: string = null,
    seasonId: string = null,
  ) {
    const path = exportDefinitionId == null
      ? `export/user-json`
      : `export/user-json/${exportDefinitionId}`;
    
    var entitySelectionWithExportDefinition: EntitySelectionWithExportDefinition = this.getEntitySelectionWithExportDefinition(selection, exportDefinition);

    const res = await this.s.post(
      path,
      entitySelectionWithExportDefinition,
      {
        fileProviderId,
        fileProviderFolder,
        seasonId
      }
    );

    return createFrom(TaskRequest, res.body);
  }

  private getEntitySelectionWithExportDefinition(selection: EntitySelection, exportDefinition: ExportDefinition) {
    var entitySelectionWithExportDefinition: EntitySelectionWithExportDefinition = new EntitySelectionWithExportDefinition();
    entitySelectionWithExportDefinition.ids = selection.ids;
    entitySelectionWithExportDefinition.excludedIds = selection.excludedIds;
    entitySelectionWithExportDefinition.filter = selection.filter;
    entitySelectionWithExportDefinition.exportDefinition = exportDefinition;
    return entitySelectionWithExportDefinition;
  }

}
