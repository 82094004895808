import { inject, prop } from "fw";
import { dispatch } from "fw-state";

import { EvaluationPhasesStore } from "state/evaluation-phases";
import { UsersStore, EnsureUsersAction } from "state/users";
import { TeamsStore } from "state/teams";
import { EvaluatedByFilter as EvaluatedByFilterModel  } from "models/application-filters";
import { PopoverService } from "service/popover";
import { SelectUserPopover } from "views/components/select-user-popover";

@inject
export class EvaluatedByFilter {
  @prop(null)
  filter: EvaluatedByFilterModel;

  @prop(() => ({}))
  validation;

  @prop(false)
  editing: boolean;

  constructor(
    private phaseStore: EvaluationPhasesStore,
    private teamsStore: TeamsStore,
    private usersStore: UsersStore,
    private popover: PopoverService,
  ) {}

  get phases() {
    return [
      { text: "Current Phase", value: "current" },
      ...this.phaseStore.state.phases.map(p => ({ text: p.Name, value: p.Key })),
    ];
  }

  get phaseNameHash() {
    const hash: { [key: string]: string } = {
      current: "Current Phase",
    };

    this.phaseStore.state.phases.forEach(p => hash[p.Key] = p.Name);
    return hash;
  }

  attached() {
    dispatch(new EnsureUsersAction([ this.filter.userId ]));
  }

  get selectedUser() {
    if (this.filter == null || this.filter.userId == null) return "Choose User";

    const u = this.usersStore.state.userHash[this.filter.userId];
    if (u == null) return "Loading...";

    return `${u.FirstName} ${u.LastName}`;
  }

  async selectUser() {
    const res = await this.popover.open<string>(SelectUserPopover);
    if (res.canceled) return;

    this.filter.userId = res.result;
    dispatch(new EnsureUsersAction([ res.result ]));
  }
}
