const numberRegex = /^-?[0-9]+(\.[0-9]+)?$/;

export const allNumbers = (arr: any[]) => arr.every(c => numberRegex.test(c));

export const fillColorPalette = [
  "#5571AD",
  "#b4bd00",
  "#4eaf9c",
  "#f18eab",
  "#98a6ad",
  "#e78747",
  "#55b8d4",
  "#c98ad2",
  "#aebaf6",
  "#c6ca87",
  "#80cabb",
  "#e2afbe",
  "#bcc9d0",
  "#ecb18a",
  "#7cc8de",
  "#dbb0e0"
];

export const funnelFillColorPalette = [
  "#7051C1",
  "#5B6FCC",
  "#6BA3DF",
  "#38A2C7",
  "#33B19E",
  "#2EBF74"
];

