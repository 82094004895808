import { inject, prop } from "fw";
import { dispatch } from "fw-state";
import { orderBy } from "lodash-es";
import { ActivitySourceFilter as ActivitySourceFilterModel } from "models/activity-filters";
import { ContactStore, EnsureContactStoreAction } from "state/contacts";
import { ContactsDataSourceInstanceStore, EnsureContactsDataSourceInstanceStoreAction} from "state/contacts-data-source-instance";
import { IDropdownFieldOptions } from "../dropdown-field";
import { ContactsDataSourceInstance } from "models/contacts-data-source-instance";
import { ContactsDataSource } from "models/contacts-data-source";
import { ContactActivityRepository } from "network/contact-activity-repository";
import { ViewActivityTemplate } from "models/contact-activity";

@inject
export class ActivitySourceFilter {
  @prop(null) public filter!: ActivitySourceFilterModel;
  @prop(() => ({})) public validation;
  @prop(false) public editing!: boolean;

  public dataSourceOptions: { text: string, value: string }[] = [];
  public dataSourceNameHash: { [id: string]: string } = {};
  public dataSourceInstanceOptions: { text: string, value: string }[] = [];
  public dataSourceInstanceNameHash: { [id: string]: string } = {};
  public activityTypeOptions: { text: string, value: string }[] = [];

  constructor(
    private activityRepo: ContactActivityRepository,
    private contactStore: ContactStore,
    private contactsDataSourceInstanceStore: ContactsDataSourceInstanceStore,
  ) { }

  public async attached() {
    await dispatch(new EnsureContactStoreAction());
    await dispatch(new EnsureContactsDataSourceInstanceStoreAction());

    this.loadDataSourceOptions();
    this.loadDataSourceInstanceOptions();
    await this.loadActivityTypeOptions();
  }

  private loadDataSourceOptions() {
    const names: { [id: string]: string } = {};
    const options: IDropdownFieldOptions[] = [];

    this.dataSources.forEach(ds => {
      options.push({ text: ds.name, value: ds.id });
      names[ds.id] = ds.name;
    });

    if (options.length === 0) {
      options.push({ text: "Any", value: "" });
    }

    this.dataSourceOptions = orderBy(options, [i => i.text]);
    this.dataSourceNameHash = names;
  }

  public async dataSourceChanged() {
    this.filter.datasourceinstance = "";
    this.filter.type = "";
    this.loadDataSourceInstanceOptions();
    await this.loadActivityTypeOptions();
  }

  private loadDataSourceInstanceOptions() {
    const options: IDropdownFieldOptions[] = [];
    const names: { [id: string]: string } = {};

    const instances = this.dataSourceInstances.filter(dsi => dsi.data_source_id === this.filter.datasource);

    instances.forEach(dsi => {
      options.push({ text: dsi.name, value: dsi.id });
      names[dsi.id] = dsi.name;
    });

    this.dataSourceInstanceOptions = orderBy(options, [dsi => dsi.text]);
    this.dataSourceInstanceOptions.unshift({ text: "Any", value: "" });

    this.dataSourceInstanceNameHash = names;
  }

  public async dataSourceInstanceChanged() {
    await this.loadActivityTypeOptions();
  }

  private async loadActivityTypeOptions() {
    const options: IDropdownFieldOptions[] = [];

    let templates: ViewActivityTemplate[] = [];
    if (this.filter.datasourceinstance) {
      templates = await this.activityRepo.getActivityTemplatesByDataSourceInstance(this.filter.datasourceinstance);
    } else if (this.filter.datasource) {
      templates = await this.activityRepo.getActivityTemplatesByDataSource(this.filter.datasource);
    }

    templates.forEach(template => {
      options.push({ text: template.type, value: template.type });
    });

    this.activityTypeOptions = orderBy(options, [t => t.text]);
    this.activityTypeOptions.unshift({ text: "Any", value: "" });
  }

  public activityTypeChanged() {
  }

  private get contactType(): string {
    return this.contactStore.state.selectedContactType?.key;
  }

  private get dataSources(): ContactsDataSource[] {
    const instances = this.dataSourceInstances;
    return this.contactsDataSourceInstanceStore.state.dataSources
      .filter(ds => "system" === ds.key || instances.find(dsi => dsi.data_source_key === ds.key));
  }

  private get dataSourceInstances(): ContactsDataSourceInstance[] {
    const contactType = this.contactType;
    return this.contactsDataSourceInstanceStore.state.dataSourceInstances
      .filter(dsi => !dsi.is_deleted && (!contactType || dsi.contact_type === contactType));
  }
}
