import { FormForType, formFor, Validators } from "fw-model";
import { RoleSettings, ContactTypeDataPolicy } from 'models/contact-organization';

export type ContactRoleSettingsFormType = FormForType<RoleSettings>;
export type ContactTypeDataPolicyFormType = FormForType<ContactTypeDataPolicy>;

export const contactTypeDataPolicyFormCreator = formFor(ContactTypeDataPolicy, s => {
  s.requiredField(f => f.contact_type, "Contact Type");
  s.field(f => f.is_hidden, "Is Hidden");
  s.field(f => f.segment_id, "Segment Id");
  s.field(f => f.restricted_field_ids, "Restricted Field Ids");
});

export const contactRoleSettingsFormCreator = formFor(RoleSettings, s => {
  s.requiredField(f => f.role_id, "Role Id");
  s.formArray(f => f.data_policies, "Data Policies", contactTypeDataPolicyFormCreator);
});
