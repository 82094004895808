import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { ChartingService, pieChart, ChartData } from "slideroom-charts";
import { fillColorPalette } from "../../util";

@inject
export class PieChart {
  @prop(null) data!: ChartData[];
  @prop(null) dataSourceLabel: string;
  @prop(false) interactive!: boolean;
  @prop("Pie chart") svgAlt: string;

  private svgElement: SVGElement = null;

  private height = 500;

  constructor(
    private ceb: ComponentEventBus,
    private charting: ChartingService,
  ) { }

  attached() {
    this.charting.setColors(fillColorPalette);
    this.buildChart();
    window.addEventListener("resize", this.buildChart);
  }

  detached() {
    window.removeEventListener("resize", this.buildChart);
  }

  buildChart() {
    this.charting.drawChart(
      pieChart,
      this.svgElement,
      this.data,
      this.interactive, (data) => {
        if (!this.interactive) return;
        this.ceb.dispatch("data-click", data);
      },
      {
        totalLabel: this.dataSourceLabel,
        description: this.svgAlt,
      }
    );
  }

  color(i: number) {
    return this.charting.color(i);
  }

  dataChanged() {
    this.buildChart();
  }

  isMissing(text: string) {
    return text == "__missing__";
  }
}
