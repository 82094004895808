import { Validators, formFor, FormForType } from "fw-model";
const { isUrl, wrap } = Validators;

import { OrganizationPortal } from "models/organization";

export class UpdateOrganizationPortalAction {
  constructor(public form: OrganizationPortalForm) {}
}

export type OrganizationPortalForm = FormForType<OrganizationPortal>;

export const organizationPortalFormCreator = formFor(OrganizationPortal, s => {
  s.requiredField(o => o.Domain, "Domain", wrap(isUrl({
    allowPort: true
  })));

  s.field(o => o.AllowLocalLogin, "Allow Local Login");
  s.field(o => o.IdentityProviderIds, "Identity Providers");
});
