import { fromClass } from "fw-model";

export class UserSeasonSettingsMetaData {
  UserId: string;
  SeasonId: string;
  OrganizationId: string;
}

export class UserSeasonEmailNotificationSettings {
  Enabled: boolean;
  Digest: boolean;
}

export class UserSeasonNotificationSettings {
  @fromClass Email: UserSeasonEmailNotificationSettings;
}

export class UserSeasonSettings {
  Id: string;
  IsDeleted: boolean;
  Settings: { [key: string]: any };

  @fromClass NotificationSettings: UserSeasonNotificationSettings;

  @fromClass MetaData: UserSeasonSettingsMetaData;
}
