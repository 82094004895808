import { AttachmentType } from "./attachment";
import { CourseworkType } from "./coursework";
import { NumberType } from "./number";
import { BooleanType } from "./boolean";
import { DateType } from "./date";
import { StringType } from "./string";
import { LargestringType } from "./largestring";
import { DropdownType } from "./dropdown";
import { FunnelType } from "./funnel";
import { AddressType } from "./address";
import { EmailType } from "./email";
import { PhoneType } from "./phone";
import { SocialType } from "./social";
import { LinkType } from "./link";
import { RelationshipType } from "./relationship";
import { TableType } from "./table";
import { SlideroomapplicationsType } from "./slideroomapplications";
import { MultiselectType } from "./multiselect";
import { TestscoreType } from "./testscore";
import { TagsType } from "./tags";
import { TagContactsPopover } from "../tag-contacts-popover";
import { UserType } from "./user";
import { CountryType } from "./country";
import { PostalCodeType } from "./postalcode";
import { ConcealedType } from "./concealed";
import { CustomFieldType } from "models/contact-organization";

const allTypes = [
  AttachmentType,
  CourseworkType,
  NumberType,
  BooleanType,
  DateType,
  StringType,
  LargestringType,
  DropdownType,
  FunnelType,
  AddressType,
  EmailType,
  PhoneType,
  SocialType,
  LinkType,
  RelationshipType,
  TableType,
  SlideroomapplicationsType,
  MultiselectType,
  TestscoreType,
  TagsType,
  TagContactsPopover,
  UserType,
  PostalCodeType,
  CountryType,
  ConcealedType,
];

export {
  allTypes,
  AttachmentType,
  CourseworkType,
  NumberType,
  BooleanType,
  DateType,
  StringType,
  LargestringType,
  DropdownType,
  FunnelType,
  AddressType,
  EmailType,
  PhoneType,
  SocialType,
  LinkType,
  RelationshipType,
  TableType,
  SlideroomapplicationsType,
  MultiselectType,
  TestscoreType,
  TagsType,
  TagContactsPopover,
  UserType,
  PostalCodeType,
  CountryType,
  ConcealedType,
}

export function getControlType(type: CustomFieldType): string {
  switch (type) {
    case CustomFieldType.attachment:
      return "attachment-type";
    case CustomFieldType.coursework:
      return "coursework-type";
    case CustomFieldType.number:
      return "number-type";
    case CustomFieldType.boolean:
      return "boolean-type";
    case CustomFieldType.date:
      return "date-type";
    case CustomFieldType.string:
      return "string-type";
    case CustomFieldType.largestring:
      return "largestring-type";
    case CustomFieldType.dropdown:
      return "dropdown-type";
    case CustomFieldType.funnel:
      return "funnel-type";
    case CustomFieldType.address:
      return "address-type";
    case CustomFieldType.email:
      return "email-type";
    case CustomFieldType.phone:
      return "phone-type";
    case CustomFieldType.social:
      return "social-type";
    case CustomFieldType.link:
      return "link-type";
    case CustomFieldType.relationship:
      return "relationship-type";
    case CustomFieldType.table:
      return "table-type";
    case CustomFieldType.slideroomapplications:
      return "slideroomapplications-type";
    case CustomFieldType.multiselect:
      return "multiselect-type";
    case CustomFieldType.tags:
      return "tags-type";
    case CustomFieldType.testscore:
      return "testscore-type";
    case CustomFieldType.user:
      return "user-type";
    case CustomFieldType.postalcode:
      return "postal-code-type";
    case CustomFieldType.country:
      return "country-type";
    case CustomFieldType.concealed:
      return "concealed-type";
    case CustomFieldType.coursework:
      return "coursework-type";
    default:
      return null;
  }
}
