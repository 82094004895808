import { ContainerInstance } from "fw";
import { Validators, FormForType, formFor } from "fw-model";

import { isValidSendingEmail } from "forms/validators";
import { EmailContact } from "models/email-contact";
import { FeatureFlagService } from "service/feature-flag";

export type EmailContactFormType = FormForType<EmailContact>;
const { required } = Validators;

let dmarcSendingDomainFF: boolean = false;
const isDmarcSendingDomainFF = (): boolean => {
  if (!!dmarcSendingDomainFF) return true;
  dmarcSendingDomainFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("MarketingSendingDomainsDmarcOUT2435Feb2024");
  return dmarcSendingDomainFF;
}

export const emailContactFormCreator = (addressRequired = false, nameRequired = false) => {
  return formFor(EmailContact, s => {
    s.field(u => u.Address, "Email Address", v => {
      if (addressRequired) v.use(required);
      else v.if(u => u.Name != null && u.Name.length > 0, required);
      if (isDmarcSendingDomainFF()) v.use(isValidSendingEmail);
    });
    s.field(u => u.Name, "Name", v => {
      if (nameRequired) v.use(required);
    });
  });
};
