import { inject } from "fw";
import { CurrentUserStore } from "state/current-user";
import {
  WidgetContext as WidgetContextModel
} from "shared/report-runtime";

@inject
export class WidgetContext {
  constructor(private currentUserStore: CurrentUserStore) {}

  provide() {
    const widgetContext: WidgetContextModel = {
      CurrentUserId: this.currentUserStore.state.user.Id,
    };

    return {
      widgetContext,
    };
  }
}
