import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { Activity } from "models/contact";
import { ActivityResult, ViewActivityTemplate } from "models/contact-activity";
import { EntitySelection } from "models/application-client-model";

@inject
export class ContactActivityRepository {
  constructor(private s: ATS) { }

  public async count(f: string = null, aggs: string = null, contactId: string = null) {
    return (contactId?.length > 0
      ? this.s.contacts.count(`activities/${contactId}/count`, f, aggs)
      : this.s.contacts.count("activities", f, aggs));
  }

  public async list(q: string = null, f: string = null, aggs: string = null, sort: string = null, page = 1, limit = 10, contactId: string = null, after: string = null) {
    return (contactId?.length > 0
      ? this.s.contacts.list(`activities/contact/${contactId}`, Activity, q, f, aggs, sort, page, limit, after)
      : this.s.contacts.list("activities", Activity, q, f, aggs, sort, page, limit, after));
  }

  public async post(result: ActivityResult) {
    const response = await this.s.contacts.post<Activity>("activities", result);
    return createFrom(Activity, response.body);
  }

  public async remove(id: string) {
    await this.s.contacts.delete<void>(`activities/${id}`);
  }

  public async getActivityTemplatesByDataSource(dataSourceId: string) {
    const response = await this.s.contacts.get<ViewActivityTemplate[]>(`data-sources/${dataSourceId}/templates`);
    return createFromArray(ViewActivityTemplate, response.body);
  }

  public async getActivityTemplatesByDataSourceInstance(dataSourceInstanceId: string) {
    const response = await this.s.contacts.get<ViewActivityTemplate[]>(`data-source-instances/${dataSourceInstanceId}/templates`);
    return createFromArray(ViewActivityTemplate, response.body);
  }


  public async bulkComment(selection: EntitySelection, activityResult: ActivityResult) {
    if (selection == null) {
      return;
    }

    return await this.s.contacts.post<any>("activities/bulk", { entity_selection: selection, activity_result:activityResult});
  }
}
