import { formFor, FormForType, Validators } from "fw-model";
import {
  ReferencePortal,
  ReferencePortalContent,
} from "models/reference-portal";

const { isUrl, wrap } = Validators;

export class CreateReferencePortalAction {
  newPortal: ReferencePortal;
  constructor(public form: ReferencePortalForm) {}
}

export class UpdateReferencePortalAction {
  constructor(public form: ReferencePortalForm) {}
}

export type ReferencePortalForm = FormForType<ReferencePortal>;
export type ReferencePortalContentForm = FormForType<ReferencePortalContent>;

export const ReferencePortalContentFormCreator = formFor(
  ReferencePortalContent,
  s => {
    s.field(content => content.HeaderHtml, "Header");
    s.field(content => content.FooterHtml, "Footer");
    s.field(content => content.Css, "Header/Footer CSS");
    s.field(content => content.GlobalCss, "Global CSS Override");
    s.field(content => content.FavIconUrl, "Favicon Url");
    s.field(content => content.SupportEmailAddress, "Support Email Address");
  },
);

export const ReferencePortalFormCreator = formFor(ReferencePortal, s => {
  s.requiredField(portal => portal.Name, "Name");
  s.requiredField(portal => portal.Domain, "Domain", wrap(isUrl()));

  s.form(
    portal => portal.Content,
    "Content",
    ReferencePortalContentFormCreator,
  );
});
