import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer } from "../../models";

import type { FileHash, FileService } from "../../models";


type FileState = "none" | "show-upload" | "uploading" | "processing" | "file";

enum ProcessStatusTypeCode {
  None = 0,
  Pending = 1,
  Processed = 2,
  Error = 3,
  Rejected = 4,
}

@inject
export class FileType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(null) fileHash!: FileHash;
  @prop(null) fileService!: FileService;
  @prop(false) readonly!: boolean;
  @prop(null) ariaLabelledBy;

  private pendingFile = null;

  constructor(private ecb: ComponentEventBus) { }

  get fileFromHash() {
    if (this.answer != null && this.fileHash != null) {
      return this.fileHash[this.answer.FileId];
    }

    return null;
  }

  get fileName() {
    if (this.fileFromHash) {
      return this.fileFromHash.ClientFileName;
    }

    if (this.pendingFile != null) return this.pendingFile.fileName;

    return null;
  }

  get fileUrl() {
    if (this.fileFromHash) {
      return this.fileFromHash.MetaData.Original.EphemeralUrl;
    }

    return null;
  }

  get fileState(): FileState {
    console.log("fileState", this.answer, this.pendingFile, this.fileHash, this.fileUrl);

    if (!this.readonly && this.answer && this.answer.FileId == null) {
      if (this.pendingFile != null) {
        return "uploading";
      }

      return "show-upload";
    }

    if (this.fileFromHash) {
      switch (this.fileFromHash.MetaData.Status) {
        case ProcessStatusTypeCode.Processed:
          return "file";

        //late doc delivery
        case ProcessStatusTypeCode.None:
          return "uploading";

        default:
          return "processing";
      }
    }

    if (this.pendingFile != null) {
      if (this.fileUrl != null) return "processing";

      return "uploading";
    }

    if (this.fileUrl != null) {
      return "file";
    }

    // if we get to here, that means that
    // they uploaded a file, it saved the
    // answer to localstorage, but never saved
    // the form.. so if it isn't readonly, lets
    // show the upload form again..
    if (!this.readonly) {
      return "show-upload";
    }

    return "none";
  }

  async chooseFile() {
    try {
      const file = await this.fileService.getFile();
      if (file == null) return;

      this.pendingFile = file.pendingFile;

      await this.fileService.enqueue(file);
      await file.pendingFile.waitUntilUploaded();

      this.answer.FileId = file.file.FileId;
      this.change();
    } catch (err) {
      this.pendingFile = null;
    }
  }

  change() {
    this.ecb.dispatch("answer-changed");
  }

  clear() {
    this.answer.FileId = null;
    this.pendingFile = null;
    this.change();
  }
}
