import { inject, prop, needs } from "fw";

import { ApplicationSettingsStore } from "state/application-settings";
import { AggregateCalculatedField, CalculatedField, CalculatedFieldDataTypeCode } from "models/calculated-field";
import { PhaseCalculatedFieldFilter as PhaseCalculatedFieldFilterModel } from "models/application-filters";
import { EvaluationPhasesStore } from "state/evaluation-phases";
import { FeatureFlagService } from "service/feature-flag";

@inject
export class PhaseCalculatedFieldFilter {
  @prop(null) filter!: PhaseCalculatedFieldFilterModel;
  @prop(false) editing!: boolean;
  @prop(() => ({})) validation;

  private phases: { text: string, value: string }[] = [];
  private phaseHash: { [key: string]: AggregateCalculatedField[] } = {};
  private phaseNameHash: { [key: string]: string } = {};

  constructor(
    private phaseStore: EvaluationPhasesStore,
    private ffs: FeatureFlagService
  ) { }

  attached() {
    this.updatePhases();
    this.phaseChanged();
    this.fieldChanged();
  }

  private phaseChanged() {
    if (this.filter.phaseKey == null) return;
    this.fields = this.phaseHash[this.filter.phaseKey].map(f => ({ text: f.Label, value: f.Key }));

    const newHash : { [key: string]: AggregateCalculatedField } = {};
    this.phaseHash[this.filter.phaseKey].forEach(f => newHash[f.Key] = f);
    this.fieldHash = newHash;
  }

  updatePhases() {
    const newPhaseHash: { [key: string]: AggregateCalculatedField[] } = {};
    const newNameHash: { [key: string]: string } = {};
    this.phaseStore.state.phases.forEach(s => {
      if (s.CalculatedFields.length > 0) this.phases.push({ text: s.Name, value: s.Key });
      newNameHash[s.Key] = s.Name;
      newPhaseHash[s.Key]  = s.CalculatedFields;
    });
    this.phaseNameHash = newNameHash;
    this.phaseHash = newPhaseHash;
  }

  private fields: { text: string, value: string }[] = [];
  private fieldHash: { [key: string]: AggregateCalculatedField } = {};
  private ops = [
    { text: "=", value: "=" },
    { text: ">", value: ">" },
    { text: ">=", value: ">=" },
    { text: "<=", value: "<=" },
    { text: "<", value: "<" },
  ];

  private canUseOps = false;

  private fieldChanged() {
    if (this.filter.field == null) {
      this.canUseOps = false;
      return;
    }

    this.canUseOps = this.fieldHash[this.filter.field].DataType == CalculatedFieldDataTypeCode.Number;
  }

  get defaultPhaseLabel() {
    return this.phaseStore.state.defaultPhaseLabel;
  }
}
