export class ActivityResult {
  contact_id: string;
  contact_type: string;
  contact_first_name: string;
  contact_last_name: string;
  utc_date: Date;
  email_address: string;
  type: string;
  business_value?: number;
  monetary_value?: number;
  description: string;
}

export class ViewActivityTemplate {
  organization_id: string;
  contact_type: string;
  data_source_id: string;
  data_source_key: string;
  data_source_name: string;
  data_source_icon_url: string;
  data_source_instance_id: string;

  type: string;
  business_value?: number;
  monetary_value?: number;
  template?: string;
}
