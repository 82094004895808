import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { ContactOrganizationRepository } from "network/contact-organization-repository";
import {
  LogoutAction,
  StartAction,
  ContactOrganizationModelChangedAction,
  RefreshContactOrganizationModelAction
} from "./actions";
import {
  ContactOrganization,
  ICustomFieldDefinition,
  EmptyContactOrganization,
  ViewDefinition
} from "models/contact-organization";
import { FormErrorHandling } from './error-handling';
import { compare } from 'fast-json-patch';
import { RemoveOperation, Operation } from 'fast-json-patch/lib/core';
import { nameof } from 'helpers/nameof';
import {
  AddContactTypeAction,
  UpdateContactTypeAction,
  DeleteContactTypeAction
} from "forms/contact-type";
import {
  EntityChanged,
  WebSocketMessageAction,
  filterEntityChangedMessage
} from './filter-websocket-message';

export class ContactTypeAddedAction {
  constructor(public key: string) {}
}

export class ContactTypeUpdatedAction {
  constructor(public key: string) {}
}

export class ContactTypeDeletedAction {
  constructor(public key: string) {}
}

export class AddContactFieldAction {
  constructor(public organizationId: string, public field: ICustomFieldDefinition) { }
}

export class ContactFieldAddedAction {
  constructor(public organizationId: string, public field: ICustomFieldDefinition) { }
}

export class UpdateContactFieldAction {
  constructor(public organizationId: string, public field: ICustomFieldDefinition, public operations: Operation[]) { }
}

export class UpdateContactOrganizationAction {
  constructor(public organizationId: string, public OrganizationVersion: string, public operations: Operation[]) { }
}

export class ContactFieldUpdatedAction {
  constructor(public organizationId: string, public field: ICustomFieldDefinition) { }
}

export class DeleteContactFieldAction {
  constructor(public organizationId: string, public field: ICustomFieldDefinition) { }
}

export class ContactFieldDeletedAction {
  constructor(public organizationId: string, public field: ICustomFieldDefinition) { }
}

export class AddContactViewAction {
  constructor(public organizationId: string, public view: ViewDefinition) { }
}

export class ContactViewAddedAction {
  constructor(public organizationId: string, public view: ViewDefinition) { }
}

export class UpdateContactViewAction {
  constructor(public organizationId: string, public view: ViewDefinition, public operations: Operation[]) { }
}

export class ContactViewUpdatedAction {
  constructor(public organizationId: string, public view: ViewDefinition) { }
}

export class DeleteContactViewAction {
  constructor(public organizationId: string, public view: ViewDefinition) { }
}

export class ContactViewDeletedAction {
  constructor(public organizationId: string, public view: ViewDefinition) { }
}

interface CurrentContactOrganizationShape {
  organization: ContactOrganization;
}

@inject
export class CurrentContactOrganizationStore extends Store<CurrentContactOrganizationShape> {
  constructor(
    private repository: ContactOrganizationRepository
  ) {
    super();
  }

  defaultState() {
    return {
      organization: EmptyContactOrganization,
    };
  }

  @handle(StartAction)
  private async handleStartAction(action: StartAction) {
    const defaultState = this.defaultState();

    defaultState.organization = action.context.ContactOrganization;

    this.setState(s => defaultState);
  }

  @handle(LogoutAction)
  handleLogoutAction(action: LogoutAction) {
    this.setState(s => this.defaultState());
  }

  @handle(UpdateContactOrganizationAction)
  private async handleUpdateContactOrganizationAction(action: UpdateContactOrganizationAction) {
    if (action.operations.length === 0) {
      return;
    }

    const contact = await this.repository.patch(action.organizationId, action.OrganizationVersion, action.operations);
    await this.update(contact);
  }

  @handle(AddContactTypeAction, FormErrorHandling)
  private async handleAddContactTypeAction(action: AddContactTypeAction) {
    action.form.validate();

    const newContactType = action.form.updatedModel();

    if (this.state.organization.contact_types.findIndex(item => item.key == newContactType.key) != -1) {
      action.form.validation["key"] = "This key is already in use.  Please select a different value.";
      action.form.isInvalid = true;
      throw new Error('Not Valid');
    }

    const clonedOrganization = JSON.parse(JSON.stringify(this.state.organization));
    clonedOrganization.contact_types.push(newContactType);

    const addPatch = compare(this.state.organization, clonedOrganization);
    const updatedOrganization = await this.repository.patch(clonedOrganization.id, clonedOrganization.version, addPatch);

    await this.update(updatedOrganization);
    await dispatch(new ContactTypeAddedAction(newContactType.key));
  }

  @handle(UpdateContactTypeAction, FormErrorHandling)
  private async handleUpdateContactTypeAction(action: UpdateContactTypeAction) {
    action.form.validate();

    const updatedContactType = action.form.updatedModel();
    const clonedOrganization = JSON.parse(JSON.stringify(this.state.organization));
    const existingContactType = clonedOrganization.contact_types.find(t => t.key == updatedContactType.key);
    if (existingContactType == null) return;

    Object.assign(existingContactType, updatedContactType);

    const updatePatch = compare(this.state.organization, clonedOrganization);
    // nothing changed
    if (updatePatch.length === 0)
      return;
    
    const updatedOrganization = await this.repository.patch(clonedOrganization.id, clonedOrganization.version, updatePatch);

    await this.update(updatedOrganization);
    await dispatch(new ContactTypeUpdatedAction(updatedContactType.key));
  }

  @handle(DeleteContactTypeAction)
  private async handleDeleteContactTypeAction(action: DeleteContactTypeAction) {
    const contactTypeToDeleteIndex = this.state.organization.contact_types.findIndex(t => t.key == action.key);
    if (contactTypeToDeleteIndex == -1) return;

    const removePatch = <RemoveOperation> {
      op: "remove",
      path: `/${nameof<ContactOrganization>("contact_types")}/${contactTypeToDeleteIndex}`
    };

    const updatedOrganization = await this.repository.patch(this.state.organization.id, this.state.organization.version, [removePatch]);
    await this.update(updatedOrganization);
    await dispatch(new ContactTypeDeletedAction(action.key));
  }

  @handle(AddContactFieldAction)
  private async handleAddContactFieldAction(action: AddContactFieldAction) {
    action.field = (await this.repository.addField([action.field]))[0];
    dispatch(new RefreshContactOrganizationModelAction(action.organizationId));
    await dispatch(new ContactFieldAddedAction(action.organizationId, action.field));
  }

  @handle(UpdateContactFieldAction)
  private async handleUpdateContactFieldAction(action: UpdateContactFieldAction) {
    if (action.operations.length === 0) {
      return;
    }

    action.field = await this.repository.saveField(action.field, action.operations);
    dispatch(new RefreshContactOrganizationModelAction(action.organizationId));
    await dispatch(new ContactFieldUpdatedAction(action.organizationId, action.field));
  }

  @handle(DeleteContactFieldAction)
  private async handleDeleteContactFieldAction(action: DeleteContactFieldAction) {
    await this.repository.deleteField(action.field);
    dispatch(new RefreshContactOrganizationModelAction(action.organizationId));
    await dispatch(new ContactFieldDeletedAction(action.organizationId, action.field));
  }

  @handle(AddContactViewAction)
  private async handleAddContactViewAction(action: AddContactViewAction) {
    action.view = (await this.repository.addView([action.view]))[0];
    dispatch(new RefreshContactOrganizationModelAction(action.organizationId));
    await dispatch(new ContactViewAddedAction(action.organizationId, action.view));
  }

  @handle(UpdateContactViewAction)
  private async handleUpdateContactViewAction(action: UpdateContactViewAction) {
    if (action.operations.length === 0) {
      return;
    }

    action.view = await this.repository.saveView(action.view, action.operations);
    dispatch(new RefreshContactOrganizationModelAction(action.organizationId));
    await dispatch(new ContactViewUpdatedAction(action.organizationId, action.view));
  }

  @handle(DeleteContactViewAction)
  private async handleDeleteContactViewAction(action: DeleteContactViewAction) {
    await this.repository.deleteView(action.view);
    dispatch(new RefreshContactOrganizationModelAction(action.organizationId));
    await dispatch(new ContactViewDeletedAction(action.organizationId, action.view));
  }

  @handle(RefreshContactOrganizationModelAction)
  private async handleRefreshContactOrganizationModelAction(action: RefreshContactOrganizationModelAction) {
    const organization = await this.repository.getById(action.organizationId);
    await this.update(organization);
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("OrganizationSettings"))
  private async handleEntityChangedAction(action: WebSocketMessageAction<EntityChanged>) {
    if (action.data.id) {
      await dispatch(new RefreshContactOrganizationModelAction(action.data.id));
    }
  }

  private async update(organization: ContactOrganization) {
    this.setState(state => ({
      ...state,
      organization: organization
    }));

    await dispatch(new ContactOrganizationModelChangedAction(organization));
  }
}
