import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { Function } from "models/function";

import { StartAction } from "./actions";
import { AddFunctionAction, UpdateFunctionAction } from "forms/function";
import { FunctionRepository } from "network/function-repository";
import { difference } from "lodash-es";

type FunctionStoreShape = {
  list: Function[];
  loading: boolean;
  loaded: boolean;
}

export class EnsureFunctionsAction {}

export class DeleteFunctionsAction {
  constructor(public ids: string[]) {}
}

@inject
export class FunctionStore extends Store<FunctionStoreShape> {
  constructor(private functionRepo: FunctionRepository) {
    super();
  }

  defaultState() {
    return {
      list: [],
      loading: false,
      loaded: false,
    };
  }

  @handle(StartAction)
  handleStartAction() {
    this.setState(_ => this.defaultState());
  }

  @handle(EnsureFunctionsAction)
  private async handleEnsureFunctionsAction() {
    const { loading, loaded } = this.state;
    if (loading || loaded) return;

    this.setState(state => ({
      ...state,
      loading: true,
    }));

    const list = await this.functionRepo.list();

    this.setState(state => ({
      ...state,
      loading: false,
      loaded: true,
      list,
    }));
  }

  @handle(AddFunctionAction)
  private async handleAddFunctionAction(action: AddFunctionAction) {
    action.form.validate();

    const res = await this.functionRepo.post(action.form.updatedModel());

    await dispatch(new EnsureFunctionsAction());
    this.setState(state => ({
      ...state,
      list: [ ...state.list, res ],
    }));
  }

  @handle(UpdateFunctionAction)
  private async handleUpdateFunctionAction(action: UpdateFunctionAction) {
    action.form.validate();
    const res = await this.functionRepo.put(action.form.updatedModel());

    await dispatch(new EnsureFunctionsAction());
    const existing = this.state.list.find(a => a.Id == action.form.Id);

    if (existing) {
      Object.assign(existing, res);
    }
  }

  @handle(DeleteFunctionsAction)
  private async handleDeleteFunctionsAction(
    action: DeleteFunctionsAction,
  ) {
    await dispatch(new EnsureFunctionsAction());
    const objects = this.state.list.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.functionRepo.del(objects.map(t => t.Id));
    this.setState(state => ({
      ...state,
      list: difference(state.list, objects),
    }));
  }
}
