import { prop } from "fw";

export class AddColumn {
  @prop("Add a column") public addText: string;
  @prop(null) public onClick: (span: number) => Promise<void>;

  public addHighlight: number = 0;

  public click(span: number) {
    if (this.onClick) this.onClick(span);
  }
}
