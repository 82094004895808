import { ContainerInstance } from "fw";
import { Form, QuestionType } from "models/form";
import { IntegrationsStore } from "state/integrations";
import { Completer, CompletionItem, getItems } from "../auto-complete";
import { CustomFieldType } from "../../models/contact-organization";
import { ProgramStore } from "../../state/program";
import { findKey, get } from "lodash-es";
import { CalculatedFieldDataTypeCode } from "../../models/calculated-field";
import { ProgramProperty } from "../../models/program-settings";
import { inject } from "fw";

// this takes a form and provides autocomplete source for
// form keys

export const getFormItems = (
  f: Form,
  questionsOnly = false,
  tableSectionKey = null
): CompletionItem[] => {
  const questions: CompletionItem[] = [];
  const isWriteIn: CompletionItem = { token: "isWriteIn", properties: [] };

  const integrationsStore = ContainerInstance.get(IntegrationsStore);

  for (const section of f.Sections) {
    // skip table section, unless it matches passed in key
    if (
      section.IsTableSection &&
      (tableSectionKey == null ||
        section.TableSectionOptions.Key != tableSectionKey)
    )
      continue;

    for (const q of section.Questions) {
      const item: CompletionItem = { token: q.Key, properties: [] };

      switch (q.Type) {
        case QuestionType.CheckBoxList:
        case QuestionType.RadioButton:
        case QuestionType.DropDown:
          isWriteIn.properties.push({ token: q.Key });
          break;
        case QuestionType.Name:
          item.properties.push(
            { token: "title" },
            { token: "given" },
            { token: "middle" },
            { token: "family" },
            { token: "suffix" },
            { token: "full" }
          );
          break;
        case QuestionType.Address:
          item.properties.push(
            { token: "address1" },
            { token: "address2" },
            { token: "city" },
            { token: "state" },
            { token: "postalCode" },
            { token: "country" }
          );
          break;
        case QuestionType.CEEBCode:
          item.properties.push(
            { token: "code" },
            { token: "name" },
            { token: "address" },
            { token: "region" },
            { token: "city" },
            { token: "country" },
            { token: "postalCode" }
          );

          if (integrationsStore.state.contacts.enabled) {
            const { CeebDataSettings } =
              integrationsStore.state.contacts.settings;
            if (
              CeebDataSettings.IsEnabled &&
              CeebDataSettings.FieldMappings.length > 0
            ) {
              item.properties.push(
                ...CeebDataSettings.FieldMappings.map((fm) => ({
                  token: fm.ATSPath,
                }))
              );
            }
          }

          break;
      }

      questions.push(item);
    }
  }

  const calc: CompletionItem[] = f.CalculatedFields.map((c) => ({
    token: c.Key,
  }));

  return questionsOnly
    ? questions
    : [
        { token: "dateFirstSubmitted" },
        { token: "dateSubmitted" },
        { token: "is_complete" },
        ...questions,
        ...calc,
        isWriteIn,
      ];
};

export const getPublicProgramProperties = (
  programProperties: ProgramProperty[]
): CompletionItem[] => {
  const publicProperties = programProperties
    .filter((p) => p.IsPublic)
    .map((i) => {
      const dataType = get(
        CalculatedFieldDataTypeCode,
        i.DataType,
        ""
      ).toLowerCase();
      const fieldType = findKey(
        CustomFieldType,
        (f) => String(f).toLowerCase() == dataType
      );

      return { token: i.Key, type: Number(fieldType) };
    });

  return [
    {
      token: "program",
      properties: [{ token: "publicProperties", properties: publicProperties }],
    },
  ];
};

@inject
export class FormKeysCompleter implements Completer {
  constructor(
    private form: Form,
    private programProperties?: ProgramProperty[]
  ) {}

  async getCompletions(token: string, word: string) {
    return getItems(
      [
        ...getFormItems(this.form, true),
        ...(this.programProperties &&
          getPublicProgramProperties(this.programProperties)),
      ],
      token,
      word
    );
  }
}

export class TableSectionFormKeysCompleter implements Completer {
  constructor(private form: Form, private tableSectionKey: string) {}

  async getCompletions(token: string, word: string) {
    return getItems(
      getFormItems(this.form, true, this.tableSectionKey),
      token,
      word
    );
  }
}
