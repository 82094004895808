import { prop, inject, ComponentEventBus } from "fw";

import { PopoverService } from "service/popover";
import type { FormForReportChartDefinition } from "forms/report-definition";
import { OptionChoice, OptionChooserPopover } from "../charts/option-chooser-popover";
import { EvaluationPhasesStore } from "state/evaluation-phases";

let inputIdIterator: number = 0;

export type ClientDataForProgress = {
  isProgress: boolean;
  phase: string;
  isTeam: boolean;
};

@inject
export class ProgressEditor {
  @prop(null)
  widgetDefinitionForm: FormForReportChartDefinition;
  private focusedOptionId: string = '';
  private phasePopoverIsOpen: boolean = false;
  private progressEditorBaseInputId: string = "progress-editor-input";
  private progressEditorPopoverId: string = "progress-editor-popover";
  private typePopoverIsOpen: boolean = false;

  constructor(private evalPhaseStore: EvaluationPhasesStore, private ceb: ComponentEventBus, private popoverService: PopoverService) { }

  chartChanged() {
    this.ceb.dispatch("changed");
  }

  get progressEditorInputId(): string {
    inputIdIterator++;
    return `${this.progressEditorBaseInputId}-${inputIdIterator}`;
  }

  get type(): string {
    if (this.clientData == null) return null;
    return this.clientData.isTeam ? "Team" : "User";
  }

  get clientData() {
    if (this.widgetDefinitionForm == null) return null;
    const j: ClientDataForProgress = JSON.parse(this.widgetDefinitionForm.ClientData);
    return j;
  }

  get phase(): string {
    if (this.clientData == null) return null;
    if (this.clientData.phase == "current") return "Current Phase";

    const p = this.evalPhaseStore.state.phases.find(p => p.Key == this.clientData.phase);

    return p ? p.Name : this.clientData.phase;
  }

  configureWidget(data: ClientDataForProgress) {
    this.widgetDefinitionForm.ClientData = JSON.stringify(data);

    this.widgetDefinitionForm.PrimaryIndependentVariable.Path = `phases.${data.phase}.${data.isTeam ? 'assigned_team_id' : 'assigned_users'}`
    this.widgetDefinitionForm.PrimaryIndependentVariable.LabelTransform = data.isTeam ? "team" : "username";

    if (data.isTeam) {
      this.widgetDefinitionForm.SecondaryIndependentVariable.Path = `phases.${data.phase}.evaluation_complete`
      this.widgetDefinitionForm.SecondaryIndependentVariable.LabelTransform = "boolean";
    } else {
      this.widgetDefinitionForm.SecondaryIndependentVariable.Path = `phases.${data.phase}.evals_completed_by`
      this.widgetDefinitionForm.SecondaryIndependentVariable.LabelTransform = null;
    }

    this.chartChanged();
  }

  public updateOptionFocus(optionId: string) {
    this.focusedOptionId = optionId;
  }

  async selectType() {
    const options = [
      { text: 'Team', value: true },
      { text: 'User', value: false },
    ]
    this.typePopoverIsOpen = true;
    const res = await this.popoverService.open<OptionChoice<boolean>>(
      OptionChooserPopover,
      {
        options,
        selectedOption: options.find(option => this.type === option.text),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.progressEditorPopoverId },
    );
    this.typePopoverIsOpen = false;

    if (res.canceled) return;

    const data = this.clientData;
    data.isTeam = res.result.value;

    this.configureWidget(data);
  }

  async selectPhase() {
    const options = [
      { text: "Current Phase", value: "current" },
      ...this.evalPhaseStore.state.phases.map(p => ({ text: p.Name, value: p.Key })),
    ];
    this.phasePopoverIsOpen = true;
    const res = await this.popoverService.open<OptionChoice<string>>(OptionChooserPopover,
      {
        options,
        selectedOption: options.find(option => this.phase === option.text),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.progressEditorPopoverId },
    );
    this.phasePopoverIsOpen = false;

    if (res.canceled) return;

    const data = this.clientData;
    data.phase = res.result.value;

    this.configureWidget(data);
  }
}
