import { inject, prop, needs } from "fw";
import { Contact, UnrestrictedContact } from "models/contact";

import { ContactsService } from "service/contacts";
import { ContactAvatar } from "../../components/contact-avatar";
import { ContactTypeName } from "./contact-type-name";

@inject
@needs(ContactAvatar, ContactTypeName)
export class ContactCard {
  @prop(null) public contact!: Contact | UnrestrictedContact;
  @prop(null) public remove!: () => Promise<boolean>;

  constructor(
    private contactsService: ContactsService,
  ) { }

  public get emailAddress() {
    return this.contactsService.getEmailAddress(this.contact);
  }
}
