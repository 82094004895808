import { fromClassArray, fromClass } from "fw-model";
import { AggregateCalculatedField } from "./calculated-field";

export class AssignmentResult {
  TeamCount: number;
}

export enum TeamDistributionMethod {
  WholeTeam = 0,
  Balanced = 1,
}

export class DistributionOptions {
  FieldPath: string = null;
}

export enum ApplicationUserMatchOperator {
  In = 0,
}

export class ApplicationUserMatchingRule {
  ApplicationFieldPath: string = null;
  UserProperty: string = "PreferredCEEBCodes";
  ApplicationUserMatchOperator = ApplicationUserMatchOperator.In;
}

export class AssignmentRule {
  SegmentId: string = null;
  TeamId: string = null;
  RoutingTableId: string = null;
  IsFallThrough = false;
  UserIds: string[] = [];

  TeamDistributionMethod = TeamDistributionMethod.WholeTeam;
  @fromClass DistributionOptions: DistributionOptions;
  @fromClassArray(ApplicationUserMatchingRule) MatchingRules: ApplicationUserMatchingRule[];
}

export class CollaborationModuleAssignmentRuleSet {
  Id: string;
  ModuleId: string = null;
  RequiredEvaluationCount: number = 0;
  @fromClassArray(AssignmentRule) AssignmentRules: AssignmentRule[];
}

export class EvaluationPhase {
  Id: string;
  Name: string = null;
  Key: string = null;
  FormId: string = null;
  GroupName: string = null;
  RequiredEvaluationCount: number = 0;

  @fromClassArray(AggregateCalculatedField) CalculatedFields: AggregateCalculatedField[];
  @fromClassArray(AssignmentRule) AssignmentRules: AssignmentRule[];
  @fromClassArray(CollaborationModuleAssignmentRuleSet) CollaborationModuleAssignmentRuleSets: CollaborationModuleAssignmentRuleSet[];
}

export class EvaluationPhasesWithCategories {
  category: string;
  @fromClassArray(EvaluationPhase) options: EvaluationPhase[];
}
