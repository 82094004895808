import { inject } from "fw";
import { dispatch } from "fw-state";

import { LogoutAction } from "state/actions";
import { UserRepository } from "network/user-repository";


@inject
export class ConfirmEmailChange {
  private sending = false;
  private didError = false;

  constructor(private userRepo: UserRepository) { }

  async activate(params) {
    this.sending = true;

    await dispatch(new LogoutAction());

    try {
      await this.userRepo.confirmEmailChange(params.token);
    } catch(err) {
      this.didError = true;
    }

    this.sending = false;
  }
}
