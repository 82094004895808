import { fromClass } from 'fw-model';

export class StudentPortalMetaData {
  OrganizationId: string = null;
  SeasonId: string = null;
  ScopedCss: string = null;
}

export class StudentPortalContent {
  FavIconFileId: string = null;
  FavIconUrl: string = null;
  CompactLogoFileId: string = null;
  CompactLogoUrl: string = null;
  FullLogoFileId: string = null;
  FullLogoUrl: string = null;
  OrganizationDisplayName: string = null;
  OrganizationWebsiteUrl: string = null;
  PrimaryColor: string = null;
  SecondaryColor: string = null;
  TertiaryColor: string = null;
  NavBarColor: string = null;
  SupportEmailAddress: string = null;
  SupportPhoneNumber: string = null;
  TermsAndConditionsMessage: string = null;
  DashboardWelcomeBGImageFileId: string = null;
  DashboardWelcomeBGImageUrl: string = null;
  DashboardWelcomeMessage: string = null;
  WelcomeMessageBGImageFileId: string = null;
  WelcomeMessageBGImageUrl: string = null;
  WelcomeMessage: string = null;
  TrackingScript: string = null;
  ProgramsHeaderTitle: string = null;
  ProgramsHeaderDescription: string = null;
  ProgramsHeaderImageFileId: string = null;
  ProgramsHeaderImageUrl: string = null;
  CompactLogoLightFileId: string = null;
  CompactLogoLightUrl: string = null;
  FullLogoLightFileId: string = null;
  FullLogoLightUrl: string = null;
}
export class StudentPortalSocial {
  FaceBookPageUrl: string = null;
  LinkedInPageUrl: string = null;
  TwitterPageUrl: string = null;
  YouTubePageUrl: string = null;
  InstagramPageUrl: string = null;
  TikTokPageUrl: string = null;
  SnapchatPageUrl: string = null;
  FlickrPageUrl: string = null;
}
export class StudentPortal {
  Id: string = null;
  Name: string = null;
  Domain: string = null;
  EnableApplicationsModule: boolean = true;
  EnableEventsModule: boolean = true;
  RelationshipFields: string[] = [];

  @fromClass Content: StudentPortalContent = null;

  @fromClass MetaData: StudentPortalMetaData = null;

  @fromClass Social: StudentPortalSocial = null;
}
