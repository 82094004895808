import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { FileSource, FileSourceManagedS3PostResult } from "models/file-source";

import { AddFileSourceAction, UpdateFileSourceAction } from "forms/file-source";
import { FileSourceRepository } from "network/file-source-repository";
import { difference } from "lodash-es";
import { StartAction } from "./actions";

type FileSourceStoreShape = {
  list: FileSource[];
  loading: boolean;
  loaded: boolean;
}

export class EnsureFileSourcesAction {}

export class AddS3AccessKeyAction {
  public keyResult: FileSourceManagedS3PostResult;

  constructor(public source: FileSource) {}
}

export class DeleteFileSourcesAction {
  constructor(public ids: string[]) {}
}

export class DeleteS3AccessKeyAction {
  constructor(public source: FileSource, public key: string) {}
}

@inject
export class FileSourceStore extends Store<FileSourceStoreShape> {
  constructor(private fileSourceRepo: FileSourceRepository) {
    super();
  }

  defaultState() {
    return {
      list: [],
      loading: false,
      loaded: false,
    };
  }

  @handle(StartAction)
  handleStartAction() {
    this.setState(_ => this.defaultState());
  }

  @handle(EnsureFileSourcesAction)
  private async handleEnsureFileSourcesAction() {
    const { loading, loaded } = this.state;
    if (loading || loaded) return;

    this.setState(state => ({
      ...state,
      loading: true,
    }));

    const list = await this.fileSourceRepo.list();

    this.setState(state => ({
      ...state,
      loading: false,
      loaded: true,
      list,
    }));
  }

  @handle(AddFileSourceAction)
  private async handleAddFileSourceAction(action: AddFileSourceAction) {
    action.form.validate();

    const res = await this.fileSourceRepo.post(action.form.updatedModel(), action.type);
    action.result = res;

    await dispatch(new EnsureFileSourcesAction());
    this.setState(state => ({
      ...state,
      list: [ ...state.list, res.FileSource ],
    }));
  }

  @handle(UpdateFileSourceAction)
  private async handleUpdateFileSourceAction(action: UpdateFileSourceAction) {
    action.form.validate();
    const res = await this.fileSourceRepo.update(action.form.updatedModel());
    action.updated = res;

    await dispatch(new EnsureFileSourcesAction());
    const existing = this.state.list.find(a => a.Id == action.form.Id);

    if (existing) {
      Object.assign(existing, res);
    }
  }

  @handle(AddS3AccessKeyAction)
  private async handleAddS3AccessKeyAction(action: AddS3AccessKeyAction) {
    const res = await this.fileSourceRepo.addAccessKey(action.source);

    action.keyResult = res;
    await dispatch(new EnsureFileSourcesAction());
    const existing = this.state.list.find(a => a.Id == action.source.Id);

    if (existing) {
      existing.MetaData.ManagedS3.AccessKeyIds.push(res.AccessKeyId);
    }
  }

  @handle(DeleteS3AccessKeyAction)
  private async handleDeleteS3AccessKeyAction(action: DeleteS3AccessKeyAction) {
    await this.fileSourceRepo.deleteAccessKey(action.source, action.key);

    await dispatch(new EnsureFileSourcesAction());
    const existing = this.state.list.find(a => a.Id == action.source.Id);

    if (existing) {
      existing.MetaData.ManagedS3.AccessKeyIds = existing.MetaData.ManagedS3.AccessKeyIds.filter(a => a != action.key);
    }
  }

  @handle(DeleteFileSourcesAction)
  private async handleDeleteFileSourcesAction(
    action: DeleteFileSourcesAction,
  ) {
    await dispatch(new EnsureFileSourcesAction());
    const objects = this.state.list.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.fileSourceRepo.del(objects.map(t => t.Id));
    this.setState(state => ({
      ...state,
      list: difference(state.list, objects),
    }));
  }
}
