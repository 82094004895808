import { inject } from "fw";
import { createFrom } from "fw-model";
import { MemoryCache } from "caching";

import {
  ClientModelSearchResult,
} from "models/application";

import { ATS } from "./ats";
import { PaymentDetails } from "../models/payments";

@inject
export class PaymentJournalRepository {
  constructor(private s: ATS, private cache: MemoryCache) { }

  public async clientModelSearch(
    filter: string,
    sort: string = null,
    previousPageToken: string = null,
    nextPageToken: string = null,
    fieldsPaths: string = null,
    pageSize: number = 200
  ) {
    const res = await this.s.get<ClientModelSearchResult>("payment-journal", {
      filter,
      sort,
      pageSize,
      previousPageToken,
      nextPageToken,
      fieldsPaths
    });
    return createFrom(ClientModelSearchResult, res.body);
  }

  public async export(filter: string) {
    const res = await this.s.get<any>("payment-journal/export", {filter});
    return res;
  }

  public async getPaymentDetails(
    id: string,
  ) {
    const res = await this.s.get(`payment-journal/${id}`);
    return createFrom(PaymentDetails, res.body);
  }
}
