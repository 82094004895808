import { inject, prop } from "fw";

import { EvaluationPhasesStore } from "state/evaluation-phases";
import { FeatureFlagService } from "service/feature-flag";
import { PopoverService } from "service/popover";
import {
  CategoryOptionChoice,
  OptionChoice,
  OptionChooserWithCategories
} from "views/components/option-chooser-with-categories";

@inject
export class PhaseFilterWithCategories {
  @prop(() => ({})) validation;
  @prop(null) filter;
  @prop(false) editing!: boolean;

  private phases: CategoryOptionChoice<string>[] = [];
  private phaseNameHash: { [id: string]: string } = {};
  private phaseId: string = null;

  constructor(
    private phaseStore: EvaluationPhasesStore,
    private ffs: FeatureFlagService,
    private popover: PopoverService,
  ) {}

  attached() {
    const newHash: { [id: string]: string } = {};
    const phasesData: { [name: string]: CategoryOptionChoice<string> } = {};

    phasesData[this.defaultPhaseLabel] = {
      category: '',
      options: [{ text: this.defaultPhaseLabel, value: null }]
    };

    this.phaseStore.state.phases.forEach((s, i) => {
      if (s.GroupName && phasesData[s.GroupName]) phasesData[s.GroupName].options.push({ text: s.Name, value: s.Id });
      if ((s.GroupName && !phasesData[s.GroupName]) || !s.GroupName) {
        phasesData[s.GroupName ? s.GroupName : `${s.Name}-${i}`] = {
          category: s.GroupName ?? '',
          options: [{ text: s.Name, value: s.Id }]
        };
      }

      newHash[s.Id] = s.Name;
    });

    this.phases = Object.values(phasesData);

    this.phaseNameHash = newHash;
  }

  get defaultPhaseLabel() {
    return this.phaseStore.state.defaultPhaseLabel;
  }

  async chooseField() {
    const res = await this.popover.open<OptionChoice<string>>(OptionChooserWithCategories, this.phases);

    if (res.canceled) return;

    this.phaseId = res.result.value;
    this.filter.phaseId = this.phaseId;
  }
}
