import { ComponentEventBus, inject, needs, prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import type { IRelationship } from "models/contact";
import { EntitySearchViewer } from "../entity-search-viewer"
import { FieldMode } from "../field-mode";
import { RelationshipSelector } from "../relationship-selector";
import { RelationshipField } from "../relationship-field";

@inject
@needs(EntitySearchViewer, RelationshipSelector, RelationshipField)
export class RelationshipType {
  @prop(null) public field!: ICustomFieldDefinition;
  @prop(undefined) public value!: IRelationship[];
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public isFilter!: boolean;

  constructor(private ceb: ComponentEventBus) { }

  public get localValue() {
    return this.value;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", value && value);
  }

  public get gridValue(): string {
    return (this.value || []).map(r => this.displayName(r)).filter(f => f).join(", ");
  }

  private displayName(relationship: IRelationship): string {
    if (!relationship) {
      return null;
    }

    return relationship.display_name;
  }
}
