import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LogoutAction, StartAction } from "./actions";
import { MarketingEmailTemplateDesignRepository } from "network/marketing-email-template-design-repository";
import { MarketingEmailTemplateDesign } from "models/marketing-email-template";

interface MarketingEmailTemplateDesignShape {
  loaded: boolean;
  collection: MarketingEmailTemplateDesign[];
}

export class FetchMarketingEmailTemplateDesignsAction {}

@inject
export class MarketingEmailTemplateDesignStore extends Store<MarketingEmailTemplateDesignShape> {
  constructor(private network: MarketingEmailTemplateDesignRepository) {
    super();
  }

  defaultState() {
    return { loaded: false, collection: [] };
  }

  @handle(StartAction)
  private handleStart() {
    this.setState(s => this.defaultState());
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(FetchMarketingEmailTemplateDesignsAction)
  private async handleFetchMarketingEmailTemplateDesigns() {
    if (this.state.loaded) return;
    const collection = await this.network.index();
    this.setState(state => ({ ...state, loaded: true, collection }));
  }
}
