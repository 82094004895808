export class MarketingClient {
  Id: number = null;
  AtsOrgId: string = null;
  SendingEmailAddress: string = null;
  SendingLabel: string = null;
  SendingDomain: string = null;
  SendingPhoneNumber: string = null;
  MailingAddress: string = null;
  WebsiteAddress: string = null;
  MainPhoneNumber: string = null;
  MainEmailAddress: string = null;
  MainLabel: string = null;
  PrivacyPolicyLink: string = null;
  AutoAssign: number = 0;
}
