import { inject, needs } from "fw";
import { DialogController } from "fw-dialog";

import { gridColumnFormCreator, GridColumnForm } from "forms/grid-column";
import { GridColumn } from 'models/grid-column';
import { PathChooser, Category } from "views/components/path-chooser";
import { Permissions } from 'service/permission';

export interface ColumnChooserArgs {
  allColumns: Category[];
  selectedColumns: GridColumn[];
  allowMarkAsDefaultChoice: boolean;
  save?: (result: ColumnChooserResult) => Promise<void>;
}

export class ColumnChooserResult {
  columns: GridColumn[];
  markAsDefault: boolean = false;
}

@inject
@needs(PathChooser)
export class ColumnChooser {
  private allColumns: Category[] = [];
  private selectedColumns: GridColumnForm[] = [];
  private allowMarkAsDefaultChoice: boolean = false;
  private markAsDefault: boolean = false;

  private externalSave: (result: ColumnChooserResult) => Promise<void> = null;
  private saving = false;

  constructor(private controller: DialogController<ColumnChooserResult>, private permissions: Permissions) { }

  activate(args: ColumnChooserArgs) {
    this.allColumns = args.allColumns;
    this.selectedColumns = (args.selectedColumns || []).map(gc => gridColumnFormCreator(gc));
    this.externalSave = args.save;
    this.allowMarkAsDefaultChoice = args.allowMarkAsDefaultChoice;
  }

  addColumn(col) {
    const newColumn = gridColumnFormCreator(col);
    this.selectedColumns.push(newColumn);
  }

  cancel() {
    if (this.saving) return;

    this.controller.cancel();
  }

  async apply() {
    this.selectedColumns.forEach(sc => sc.validate());

    const cols = this.selectedColumns.map(sc => sc.updatedModel());

    const result = <ColumnChooserResult>{
      columns: cols,
      markAsDefault: this.markAsDefault
    };

    if (this.externalSave != null) {
      this.saving = true;

      try {
        await this.externalSave(result);
      } catch (err) { console.error(err); }

      this.saving = false;
    }

    this.controller.ok(result);
  }
}
