import { kebab } from "@derekpitt/fw"

import { BarChart } from "./bar-chart";
import { LineChart } from "./line-chart";
import { PieChart } from "./pie-chart";
import { ChevronChart } from "./chevron-chart";
import { StackBarChart } from "./stack-bar-chart";
import { TableChart } from "./table-chart";
import { ProgressChart } from "./progress";
import { PhasePipeline } from "./phase-pipeline";
import { CountChart } from "./count-chart";
import { AssignmentChart } from "./assignment-chart";
import { FunnelChart } from "./funnel-chart";

import { ReportChartType, WidgetDefinition, isReportChartDefinition } from "../../models";
import { GoalsChart } from "./goals-chart";
import { TasksChart } from "./tasks-chart";
import { GridViewChart } from "./grid-view-chart";
import { RegistrationStatusCountsChart } from "./registration-status-counts-chart";

const chartComponents = [
  { component: BarChart, chartType: ReportChartType.Bar },
  { component: LineChart, chartType: ReportChartType.Line },
  { component: PieChart, chartType: ReportChartType.Pie },
  { component: ChevronChart, chartType: ReportChartType.Chevron },
  { component: StackBarChart, chartType: ReportChartType.Stack },
  { component: TableChart, chartType: ReportChartType.Table },
  { component: ProgressChart, chartType: ReportChartType.Progress },
  { component: PhasePipeline, chartType: ReportChartType.Pipeline },
  { component: CountChart, chartType: ReportChartType.Count },
  { component: FunnelChart, chartType: ReportChartType.Funnel },
];

export const widgetComponents = [
  ...chartComponents.map(a => a.component),
  AssignmentChart,
  GoalsChart,
  TasksChart,
  GridViewChart,
  RegistrationStatusCountsChart,
];

export const getComponent = (def: WidgetDefinition) => {
  if (def == null) return null;

  if (isReportChartDefinition(def)) {
    const c = chartComponents.find(a => a.chartType == def.ChartType);
    if (c == null) return null;
    return kebab(c.component.name);
  }

  switch (def.WidgetType) {
    case "assignment":
      return kebab(AssignmentChart.name);
    case "goals":
      return kebab(GoalsChart.name);
    case "tasks":
      return kebab(TasksChart.name);
    case "grid-view":
      return kebab(GridViewChart.name);
    case "registration-status-counts":
      return kebab(RegistrationStatusCountsChart.name);
  }

  return null;
};
