export class GridColumn {
  Label: string = '';
  Path: string = '';
  Sort: string = '';

  constructor(obj = {}) {
    Object.assign(this, obj);
  }
}

export class GridColumnWithMeta extends GridColumn {
  DataSource: number = null;
  DataType: number = null;
  LabelTransform: string = null; 
}
