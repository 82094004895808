import { TimingFilterType } from "models/filter-timing";
import { ContactsAddressFilter, ContactsDateFilter, ContactsLinkFilter, ContactsMultiselectFilter, ContactsOperatorFilter, ContactsTagsFilter, ContactsTestScoreFilter } from "./contacts-filters";

export const escapeTerm = (term: string): string => {
  return (term || "").replace(/\"/g, "\\\"")
}

export const getDateFilter = (filter: ContactsDateFilter): string | string[] => {
  const filters = [];
  const searchField = filter.field.search_field;
  const timing = filter.since || filter.timing;

  switch (timing) {
    case TimingFilterType.Any:
      return `_exists_:${searchField}`
    case TimingFilterType.Empty:
      return `_missing_:${searchField}`
    case TimingFilterType.Today:
      filters.push(`${searchField}:[now/d TO now+1d/d]`);
      break;
    case TimingFilterType.Yesterday:
      filters.push(`${searchField}:[now-1d/d TO now/d]`);
      break;
    case TimingFilterType.Tomorrow:
      filters.push(`${searchField}:[now+1d/d TO now+2d/d]`);
      break;
    case TimingFilterType.Last7:
    case TimingFilterType.Last7Days:
      filters.push(`${searchField}:[now-7d/d TO now]`);
      break;
    case TimingFilterType.Month:
    case TimingFilterType.CurrentMonth:
      filters.push(`${searchField}:>=now/M`);
      break;
    case TimingFilterType.CurrentYear:
      filters.push(`${searchField}:>=now/y`);
      break;
    case TimingFilterType.LastX:
    case TimingFilterType.LastXDays:
      filters.push(`${searchField}:[now-${filter.sinceDays}d/d TO now]`);
      break;
    case TimingFilterType.DayRange:
      if (filter.daysTo) {
        filters.push(`${searchField}:[now-${filter.daysFrom}d/d TO now-${filter.daysTo}d/d]`);
      } else {
        filters.push(`${searchField}:>=now-${filter.daysFrom}d/d`);
      }
      break;
    case TimingFilterType.DateRange:
      if (filter.startDate && filter.endDate) {
        return `(${searchField}:[${filter.startDate} TO ${filter.endDate}])`;
      }
      break;
  }

  return filters;
}

export const getOperatorFilters = (data: ContactsOperatorFilter): string => {
  let preOp = "";
  let op = "=";
  switch (data.operator) {
    case "!=":
      preOp = "-";
      op = "";
      break;
    case "=":
      op = "";
      preOp = "";
      break;
    default:
      op = data.operator;
      preOp = "";
      break;
  }

  return `(${preOp}${data.field.search_field}:${op}${data.value})`;
}

export const getMultiselectFilters = (data: ContactsMultiselectFilter): string => {
  if (data.values == null || data.values.length == 0) {
    return "";
  }

  const filterStr = data.values.map(v => `${data.field.search_field}:"${escapeTerm(v)}"`)
    .join(data.mode === "all" ? " AND " : " OR ");

  return data.mode === "none" ? `-(${filterStr})` : `(${filterStr})`;
}

export const getTagsFilters = (data: ContactsTagsFilter): string => {
  if (data.wildcardValue && data.wildcardValue.length && data.mode == "wildcard") {
    return `${data.field.search_field}:"${escapeTerm(data.wildcardValue)}"`;
  } else if (data.wildcardValue && data.wildcardValue.length && data.mode == "wildcardExclude") {
    return `-${data.field.search_field}:"${escapeTerm(data.wildcardValue)}"`;
  }

  if (data.values == null || data.values.length == 0) {
    return "";
  }

  const filterStr = data.values.map(v => `${data.field.search_field}:"${escapeTerm(v)}"`)
    .join(data.mode === "all" ? " AND " : " OR ");

  return data.mode === "none" ? `-(${filterStr})` : `(${filterStr})`;
}

export const getAddressFilters = (data: ContactsAddressFilter): string => {
  let filter: string = "";
  if (!data.country && !data.state && !data.city && !data.postalCode) {
    return `(_missing_:${
      data.field.search_field
    }.country OR ${
      data.field.search_field
    }.country:"") AND (_missing_:${
      data.field.search_field
    }.city OR ${
      data.field.search_field
    }.city:"") AND (_missing_:${
      data.field.search_field
    }.state OR ${
      data.field.search_field
    }.state:"") AND (_missing_:${
      data.field.search_field
    }.postal_code OR ${
      data.field.search_field
    }.postal_code:"")`;
  }

  if (data.country) {
    filter = `${data.field.search_field}.country:"${escapeTerm(data.country)}"`;
  }
  if (data.state) {
    filter += `${filter ? " AND " : ""}${data.field.search_field}.state:"${escapeTerm(data.state)}"`;
  }
  if (data.city) {
    filter += `${filter ? " AND " : ""}${data.field.search_field}.city:"${escapeTerm(data.city)}"`;
  }
  if (data.postalCode) {
    filter += `${filter ? " AND " : ""}${data.field.search_field}.postal_code:"${escapeTerm(data.postalCode)}"`;
  }
  return filter;
}

export const getTestScoreFilters = (data: ContactsTestScoreFilter): string => {
  let preOp = "";
  let op = "=";
  switch (data.operator) {
    case "!=":
      preOp = "-";
      op = "";
      break;
    case "=":
      op = "";
      preOp = "";
      break;
    default:
      op = data.operator;
      preOp = "";
      break;
  }

  return data.section && data.value
    ? `(${preOp}${data.field.search_field}.${data.section}:${op}${data.value})`
    : "";
}

export const getLinkFilters = (data: ContactsLinkFilter): string => {
  let filter: string = "";
  if (!data.name && !data.url) {
    filter = `_missing_:${data.field.search_field}.url`
  }

  if (data.name) {
    filter = `${data.field.search_field}.name:"${escapeTerm(data.name)}"`;
  }

  if (data.url) {
    filter += `${filter ? " AND " : ""}${data.field.search_field}.url:"${escapeTerm(data.url)}"`;
  }

  return filter;
}
