import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { encodeEmailTemplate } from "./encode";

import { EmailTemplate, CustomEmailTemplateTypeCode, EmailTemplatePreview } from "models/email-template";

@inject
export class EmailTemplateRepository {
  constructor(private s: ATS) {}

  async list() {
    const res = await this.s.get<any[]>("email-template");
    return createFromArray(EmailTemplate, res.body);
  }

  async applicationEmailTemplatesList() {
    const res = await this.s.get<any[]>("email-template", {
      type: CustomEmailTemplateTypeCode.Application,
    });
    return createFromArray(EmailTemplate, res.body);
  }

  async contactEmailTemplatesList() {
    const res = await this.s.get<any[]>("email-template", {
      type: CustomEmailTemplateTypeCode.Contact,
    });
    return createFromArray(EmailTemplate, res.body);
  }

  async put(template: EmailTemplate, contactType?: string) {
    const encoded = encodeEmailTemplate(template);
    let path = `email-template/${template.Key}`;
    if (contactType) {
      path = `email-template/${template.Key}?type=${CustomEmailTemplateTypeCode.Contact}&contactType=${contactType}`;
    }

    const res = await this.s.put<EmailTemplate>(path, encoded);

    return createFrom(EmailTemplate, res.body);
  }

  async deleteCustom(template: EmailTemplate) {
    await this.s.delete(`email-template/${template.Key}`);
  }

  async send(args: EmailTemplateArgs, templateKey: string = null, seasonId: string = null, type: CustomEmailTemplateTypeCode | null = null) {
    const { FromName, FromAddress, ReplyToName, ReplyToAddress, ...originalArgs } = args;
    const sendEmailArgs: SendEmailArgs = {
      ...originalArgs,
      From: {
        Name: FromName,
        Address: FromAddress,
      },
      ReplyTo: {
        Name: ReplyToName,
        Address: ReplyToAddress,
      },
    };
    await this.s.post("email-template/send", sendEmailArgs, { templateKey, seasonId, type });
  }

  async previewTypedEmail(template: EmailTemplate, type: CustomEmailTemplateTypeCode, objectId: string) {
    const encoded = encodeEmailTemplate(template);
    const res = await this.s.post("email-template/preview-typed-email", encoded, {
      type,
      objectId,
    });

    return createFrom(EmailTemplatePreview, res.body);
  }
}

interface EmailAddressInfo {
  Name: string;
  Address: string;
}

interface SendEmailArgs {
  To: string;
  From: EmailAddressInfo;
  ReplyTo: EmailAddressInfo;
  Cc: string;
  Bcc: string;
  Subject: string;
  Text: string;
  Html: string;
}

interface EmailTemplateArgs {
  To: string;
  FromName: string;
  FromAddress: string;
  ReplyToName: string;
  ReplyToAddress: string;
  Cc: string;
  Bcc: string;
  Subject: string;
  Text: string;
  Html: string;
}
