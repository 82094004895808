import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS } from "./ats";
import { MarketingClient } from "models/marketing-client";

@inject
export class MarketingClientRepository {
  constructor(private network: ATS) {}

  async show() {
    const res = await this.network.marketing.get<MarketingClient>(`clients`);
    return createFrom(MarketingClient, res.body);
  }

  async put(client: MarketingClient) {
    const res = await this.network.marketing.put<MarketingClient>(`clients`, client);
    return createFrom(MarketingClient, res.body);
  }
}
