import { fromClassArray, fromClass } from "fw-model";

import { EncryptedValuePair, HeaderData } from "./file-provider";

export class ExternalData {
  Key: string = null;
  Method = "GET";
  Url: string = null;

  @fromClassArray(HeaderData) Headers: HeaderData[];
}

export class ContentTemplate {
  Id: string = null;
  Label: string = null;
  Template: string = null;
  TransformFunction: string = null;

  @fromClassArray(ExternalData) ExternalData: ExternalData[];
}
