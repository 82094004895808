import { inject } from "fw";
import { CurrentUserStore } from "state/current-user";

@inject
export class LoggedOut {
  constructor(
    private currentUserStore: CurrentUserStore,
  ) { }

  get logoutReason() {
    return this.currentUserStore.state.logoutReason;
  }
}
