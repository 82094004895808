import { fromClass } from "fw-model";

export class ApplicationSegmentMetaData {
  OrganizationId: string = null;
}

export class ApplicationSegment {
  Id: string = null;
  Label: string = null;
  Category: string = null;
  SegmentQuery: string = null;
  ClientData: string = null;

  @fromClass MetaData: ApplicationSegmentMetaData = new ApplicationSegmentMetaData();
}
