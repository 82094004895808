import { ComponentEventBus, inject, prop } from "fw";
import { generateDataId } from "helpers/data-id-gen";
import { WeightedScoreFieldInput } from "models/calculated-field";

@inject
export class WeightSelector {
  @prop(null) public label: string;
  @prop(null) public value: number;
  @prop(5) public max!: number;
  @prop(false) public disabled!: boolean;

  constructor(
    private ceb: ComponentEventBus,
  ) {}

  public get localValue () {
    return this.value;
  }

  public set localValue(value: number) {
    this.ceb.dispatch("update:value", value);
    this.ceb.dispatch("change", value);
  }

  public get dataId() {
    return generateDataId('weight-selector-', this.label);
  }

  public getWeightSliderMarks(value) {
    return ({
      label: value,
      labelStyle: {
        visibility: value === 0 ||  value === 5 ? 'visible' : 'hidden',
      }
    })
  }
}
