import { FormForType, formFor, Validators } from "fw-model";
import { MarketingOutreachCampaign } from "models/marketing-outreach-campaign";
import { noNewLines, isValidDate, isValidCompaingFilter } from "./validators";

const { wrap } = Validators;

export type FormForMarketingOutreachCampaign = FormForType<MarketingOutreachCampaign>;

export class CreateMarketingOutreachCampaignAction {
  public created: MarketingOutreachCampaign = null;
  constructor(public form: FormForMarketingOutreachCampaign) { }
}

export class UpdateMarketingOutreachCampaignAction {
  public updated: MarketingOutreachCampaign = null;
  constructor(public form: FormForMarketingOutreachCampaign) { }
}

export class DeleteMarketingOutreachCampaignAction {
  constructor(public ids: number | number[], public all?: boolean, public toggle?: boolean, public search?: string) { }
}

export class CopyMarketingOutreachCampaignAction {
  constructor(public ids: number | number[], public all?: boolean, public toggle?: boolean, public search?: string) { }
}

export class ActivateMarketingOutreachCampaignAction {
  constructor(public ids: number | number[], public all?: boolean, public toggle?: boolean, public search?: string) { }
}

export class DeactivateMarketingOutreachCampaignAction {
  constructor(public ids: number | number[], public all?: boolean, public toggle?: boolean, public search?: string) { }
}

export const createMarketingOutreachCampaignFormCreator = formFor(MarketingOutreachCampaign, s => {
  s.requiredField(f => f.Name, "Name");
  s.field(f => f.Description, "Description", wrap(noNewLines));
  s.requiredField(f => f.EndDate, "End Date");
  s.field(f => f.Archived, "Campaign Active / Inactive");
});

export const createMarketingOutreachCampaignNewFormCreator = formFor(MarketingOutreachCampaign, s => {
  s.requiredField(f => f.Name, "Name");
  s.field(f => f.Description, "Description", wrap(noNewLines));
  s.requiredField(f => f.EndDate, "End Date");
  s.requiredField(f => f.StartDate, "Start Date");
  s.field(f => f.RunAs, "Run Campaign As");
  s.field(f => f.Archived, "Campaign Active / Inactive");
  s.field(f => f.Filter, "Filter", wrap(isValidCompaingFilter));
});

export const updateMarketingOutreachCampaignFormCreator = formFor(MarketingOutreachCampaign, s => {
  s.requiredField(f => f.Id, "Id");
  s.requiredField(f => f.Name, "Name");
  s.field(f => f.Description, "Description", wrap(noNewLines));
  s.requiredField(f => f.EndDate, "End Date", wrap((s: string) => s === '0000-00-00' ? null : isValidDate(s)));
  s.field(f => f.Archived, "Campaign Active / Inactive")
});

export const updateMarketingOutreachCampaignNewFormCreator = formFor(MarketingOutreachCampaign, s => {
  s.requiredField(f => f.Id, "Id");
  s.requiredField(f => f.Name, "Name");
  s.field(f => f.Description, "Description", wrap(noNewLines));
  s.requiredField(f => f.EndDate, "End Date", wrap((s: string) => s === '0000-00-00' ? null : isValidDate(s)));
  s.requiredField(f => f.StartDate, "Start Date", wrap((s: string) => s === '0000-00-00' ? null : isValidDate(s)));
  s.field(f => f.RunAs, "Run Campaign As");
  s.field(f => f.Archived, "Campaign Active / Inactive");
  s.field(f => f.Filter, "Filter", wrap(isValidCompaingFilter));
});

