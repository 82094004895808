import { inject, prop } from "fw";

@inject
export class Currency {
  @prop(null) private amount!: number;
  @prop('en-US') private region!: string;
  @prop('USD') private currencyType!: string;

  private get formattedAmount(): string {
    return this.toCurrency(this.amount, this.region, this.currencyType);
  }

  private toCurrency(amount: string | number, region: string, currencyType: string): string {
    if (!amount) return '-';
  
    const formatter = new Intl.NumberFormat(region, {
      style: 'currency',
      currency: currencyType
    });
  
    return formatter.format(Number(amount));
  };
}