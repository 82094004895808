import { escapeTerm, getOps } from "helpers/filter";
import { registerFilter } from "./filter-setup";
import { log } from "util";

export class CalendarEventLocationFilter {
  location: string = null;
  operator: string = "=";
}

export class CalendarEventDescriptionFilter {
  description: string = null;
  operator: string = "=";
}

export class CalendarEventTypeFilter {
  calendar_event_type_id: string[] = [];
  _missing_: string = null;
  operator: string = "=";
}

export class CalendarEventTagsFilter {
  tags: string[] = [];
  operator: string = "=";
}

export class CalendarEventStatusFilter {
  status: string[] = [];
  operator: string = "=";
}

export class CalendarEventDepartmentFilter {
  department: string = null;
  operator: string = "=";
}

export class CalendarEventVisibilityFilter {
  isPrivate: boolean | null = null;
  operator: string = "=";
}

export class CalendarEventDateFilter {
  date: string = null;
  operator: string = "=";
}

export const registerCalendarEventFilters = () => {
  registerFilter(CalendarEventLocationFilter, f => {
    f.toFilterString(data => {
      if (!data.location) {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      return `${preOp}location:${op}"${escapeTerm(data.location)}"`;
    });
  });

  registerFilter(CalendarEventDescriptionFilter, f => {
    f.toFilterString(data => {
      if (!data.description) {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      return `${preOp}description:${op}"${escapeTerm(data.description)}"`;
    });
  });

  registerFilter(CalendarEventTypeFilter, f => {
    f.toFilterString(data => {
      if (!data.calendar_event_type_id?.length) return "";

      const { preOp, op } = getOps(data.operator);

      const filterChunks = data.calendar_event_type_id.map(id => {
        if (!id) return `${preOp}_missing_:${escapeTerm("calendar_event_type_id")}`;
        return `${preOp}calendar_event_type_id:${op}"${escapeTerm(id)}"`;
      });

      return filterChunks.length > 1 ? `(${filterChunks.join(" OR ")})` : filterChunks[0];
    });
  });

  registerFilter(CalendarEventTagsFilter, f => {
    f.toFilterString(data => {
      if (!data.tags?.length) {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      const filterChunks = data.tags.map(tag => `${preOp}tags:${op}"${escapeTerm(tag)}"`);

      return filterChunks.length > 1 ? `(${filterChunks.join(" OR ")})` : filterChunks[0];
    });
  });

  registerFilter(CalendarEventStatusFilter, f => {
    f.toFilterString(data => {
      if (!data.status?.length) return "";

      const { preOp, op } = getOps(data.operator);

      const filterChunks = data.status.map(status => `${preOp}status:${op}"${escapeTerm(status)}"`);

      return filterChunks.length > 1 ? `(${filterChunks.join(" OR ")})` : filterChunks[0];
    });
  });

  registerFilter(CalendarEventDepartmentFilter, f => {
    f.toFilterString(data => {
      if (!data.department) {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      return `${preOp}department:${op}"${escapeTerm(data.department)}"`;
    });
  });

  
  registerFilter(CalendarEventVisibilityFilter, f => {
    f.toFilterString(data => {
      if (data.isPrivate == null) {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      return `${preOp}is_private:${op}"${data.isPrivate}"`;
    });
  });

  registerFilter(CalendarEventDateFilter, f => {
    f.toFilterString(data => {
      if (!data.date) {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      return `${preOp}date:${op}"${escapeTerm(data.date)}"`;
    });
  });
};
