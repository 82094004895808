import { fromClass } from "fw-model";

export class ApplicantPortalMetaData {
  OrganizationId: string = null;
  SeasonId: string = null;
  ScopedCss: string = null;
}

export class ApplicantPortalContent {
  HeaderHtml: string = null;
  FooterHtml: string = null;
  Css: string = null;
  GlobalCss: string = null;
  FavIconUrl: string = null;
  SupportEmailAddress: string = null;
}

export class ApplicantPortal {
  Id: string = null;
  Name: string = null;
  Domain: string = null;

  @fromClass Content: ApplicantPortalContent = null;

  @fromClass MetaData: ApplicantPortalMetaData = null;
}
