import { inject } from "fw";

import { ConditionInfo } from "models/form";
import { isEqual } from "lodash-es";
import { UtilityRepository } from "network/utility-repository";

@inject
export class ConditionBuilderService {
  constructor(private utilityRepo: UtilityRepository) { }

  public async generateExpression(originalCondition: string, original: ConditionInfo, updated: ConditionInfo): Promise<string> {
    if (isEqual(original, updated)) {
      return originalCondition;
    }

    const conditions = updated?.Conditions?.Conditions;
    if (!conditions || conditions.length === 0) {
      return null;
    };

    try {
      return await this.utilityRepo.generateConditionScript(updated?.Conditions)
    } catch {
      return originalCondition;
    }
  }
}
