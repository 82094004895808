import { inject } from "fw";
import { Store, handle } from "fw-state";
import { FormErrorHandling } from "./error-handling";
import { EmailContact } from "models/email-contact";
import { LogoutAction, StartAction } from "./actions";

interface OrganizationSettingsShape {
  smtp: {
    loading: boolean;
    loaded: boolean;
    defaults: {
      from: EmailContact;
      replyTo: EmailContact;
    };
  };
  contacts: {
    appScope: string;
    baseApiUrl: string;
  };
  google: {
    mapsApiKey: string;
  };
  filestack: {
    apiKey: string;
  };
}

export class EnsureCeebSettings {}

@inject
export class OrganizationSettingsStore extends Store<
  OrganizationSettingsShape
> {

  defaultState() {
    return {
      smtp: {
        loading: false,
        loaded: false,
        settings: null,
        defaults: {
          from: new EmailContact(),
          replyTo: new EmailContact()
        }
      },
      contacts: {
        appScope: null,
        baseApiUrl: null
      },
      google: {
        mapsApiKey: null
      },
      filestack: {
        apiKey: null
      }
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    const { SystemConfig } = s.context;
    const ds = this.defaultState();

    this.setState(s => ({
      ...ds,
      smtp: {
        ...ds.smtp,
        defaults: {
          from: SystemConfig.DefaultSmtpFrom || new EmailContact(),
          replyTo: SystemConfig.DefaultSmtpReplyTo || new EmailContact()
        }
      },
      contacts: {
        appScope: SystemConfig.ContactsAppScope,
        baseApiUrl: SystemConfig.ContactsBaseApiUrl
      },
      google: {
        mapsApiKey: SystemConfig.MapsApiKey
      },
      filestack: {
        apiKey: SystemConfig.FileStackApiKey
      }
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }
}
