import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { UserTask, TaskSummaryResult } from "models/user-task";

@inject
export class UserTaskRepository {
  constructor(private s: ATS) { }

  public async count(f = null, s = null, aggs = null) {
    const res = await this.s.get<any>("task/count", { f, s, aggs });
    return res.body;
  }

  public async summary(f = null, s = null) {
    const res = await this.s.get<TaskSummaryResult>("task/summary", { f, s });
    return res.body;
  }

  async search(f = null, s = null, sort = null, page = 1, pageSize = 20) {
    const res = await this.s.get<UserTask[]>("task", { f, s, sort, page, pageSize });
    return { list: createFromArray(UserTask, res.body), total: parseInt(res.headers["x-ats-total"], 10) };
  }

  async get(id: string) {
    const res = await this.s.get(`task/${id}`);
    return createFrom(UserTask, res.body);
  }

  async listByIds(ids: string[]) {
    const res = await this.s.post<UserTask[]>("task/list-by-ids", { ids });
    return createFromArray(UserTask, res.body);
  }

  async post(userTask: UserTask) {
    const res = await this.s.post("task", userTask);
    return createFrom(UserTask, res.body);
  }

  async put(userTask: UserTask) {
    const res = await this.s.put(`task/${userTask.Id}`, userTask);
    return createFrom(UserTask, res.body);
  }

  public async delete(ids: string[]) {
    if (!ids || ids.length === 0)
      return;
    return await this.s.post<void>("task/delete", ids);
  }

  public async deleteAll(f = null, s = null) {
    return await this.s.post<void>("task/delete-all", null, { f, s });
  }

  public async setCompleted(isCompleted: boolean, ids: string[]) {
    if (!ids || ids.length === 0)
      return;
    return await this.s.post<void>("task/set-completed", ids, { isCompleted });
  }

  public async setCompletedAll(isCompleted: boolean, f = null, s = null) {
    return await this.s.post<void>("task/set-completed-all", null, { isCompleted, f, s });
  }

  public async assign(assignToUserId: string, ids: string[]) {
    if (!ids || ids.length === 0)
      return;
    return await this.s.post<void>("task/assign", ids, { assignToUserId });
  }

  public async assignAll(assignToUserId: string, f = null, s = null) {
    return await this.s.post<void>("task/assign-all", null, { assignToUserId, f, s });
  }
}
