import { isValidSendingEmail, noNewLines } from "forms/validators";
import { ContainerInstance } from "fw";
import { FormForType, Validators, formFor } from "fw-model";
import { MarketingOutreachItem } from "models/marketing-outreach-item";
import { FeatureFlagService } from "service/feature-flag";

const { wrap } = Validators;

export type FormForMarketingOutreachItem = FormForType<MarketingOutreachItem>;

export class CreateMarketingOutreachItemAction {
  public created: MarketingOutreachItem = null;
  constructor(public form: FormForMarketingOutreachItem) {}
}

export class UpdateMarketingOutreachItemAction {
  public updated: MarketingOutreachItem = null;
  constructor(public form: FormForMarketingOutreachItem) {}
}

export class DeleteMarketingOutreachItemAction {
  constructor(public id: number) {}
}

export class CopyMarketingOutreachItemAction {
  constructor(public id: number) {}
}

export class MoveMarketingOutreachItemAction {
  constructor(public itemId: number, public tierId: number) {}
}

export class ActivateMarketingOutreachItemAction {
  constructor(public id: number) {}
}

export class DeactivateMarketingOutreachItemAction {
  constructor(public id: number) {}
}

let dmarcSendingDomainFF: boolean = false;
const isDmarcSendingDomainFF = (): boolean => {
  if (!!dmarcSendingDomainFF) return true;
  dmarcSendingDomainFF = ContainerInstance.get(FeatureFlagService).isFeatureFlagEnabled("MarketingSendingDomainsDmarcOUT2435Feb2024");
  return dmarcSendingDomainFF;
}

export const marketingOutreachItemFormCreator = formFor(MarketingOutreachItem, s => {
  s.field(f => f.Id, "Id");
  s.field(f => f.OutreachTierId, "Outreach Tier Id");
  s.field(f => f.Type, "Type");
  s.field(f => f.EmailTemplateId, "Email Template");
  s.field(f => f.Subject, "Subject", wrap(noNewLines));
  s.field(f => f.Html, "Content");
  s.field(f => f.Text, "Text Content");
  s.field(f => f.Markup, "Markup Content");
  s.field(f => f.Name, "Name");
  s.field(f => f.DeliveryAddress, "Delivery Address");
  s.field(f => f.OpenRating, "Open Rating");
  s.field(f => f.ClickRating, "Click Rating");
  s.field(f => f.Replies, "Reply Management");
  s.field(f => f.SegmentId, "Segment");
  s.field(f => f.FromAddress, "From Address");
  s.field(f => f.FromName, "From Name");
  s.field(f => f.ReplyToAddress, "Reply To Address");
  s.field(f => f.ReplyToName, "Reply To Name");
  s.field(f => f.Filter, "Filter");
  s.field(f => f.attachments, "Attachments");
});

export const createMarketingOutreachEmailFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.OutreachTierId, "Outreach Tier Id");
  s.requiredField(f => f.Type, "Type");
  s.requiredField(f => f.EmailTemplateId, "Email Template");
  s.requiredField(f => f.Subject, "Subject", wrap(noNewLines));
  s.requiredField(f => f.Html, "Content");
  s.field(f => f.HtmlFile, "Content");
  s.field(f => f.Text, "Text Content");
  s.field(f => f.TextFile, "Text Content");
  s.field(f => f.Markup, "Markup Content");
  s.field(f => f.MarkupFile, "Markup Content");
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.requiredField(f => f.Active, "Active");
  s.requiredField(f => f.OpenRating, "Open Rating");
  s.requiredField(f => f.ClickRating, "Click Rating");
  s.requiredField(f => f.Replies, "Reply Management");
  s.field(f => f.SegmentId, "Segment");
  if (isDmarcSendingDomainFF()) {
    s.requiredField(f => f.FromAddress, "From Address", wrap(isValidSendingEmail));
    s.requiredField(f => f.ReplyToAddress, "Reply To Address", wrap(isValidSendingEmail));
  } else {
    s.requiredField(f => f.FromAddress, "From Address");
    s.requiredField(f => f.ReplyToAddress, "Reply To Address");
  }
  s.requiredField(f => f.FromName, "From Name");
  s.requiredField(f => f.ReplyToName, "Reply To Name");
  s.field(f => f.Filter, "Filter");
  s.field(f => f.attachments, "Attachments");
});

export const createMarketingOutreachNewEmailFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.OutreachTierId, "Outreach Tier Id");
  s.requiredField(f => f.Type, "Type");
  s.requiredField(f => f.EmailTemplateId, "Email Template");
  s.requiredField(f => f.Subject, "Subject", wrap(noNewLines));
  s.requiredField(f => f.Html, "Content");
  s.field(f => f.HtmlFile, "Content");
  s.field(f => f.Text, "Text Content");
  s.field(f => f.TextFile, "Text Content");
  s.field(f => f.Markup, "Markup Content");
  s.field(f => f.MarkupFile, "Markup Content");
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.requiredField(f => f.Active, "Active");
  s.requiredField(f => f.OpenRating, "Open Rating");
  s.requiredField(f => f.ClickRating, "Click Rating");
  s.requiredField(f => f.Replies, "Reply Management");
  s.field(f => f.SegmentId, "Segment");
  if (isDmarcSendingDomainFF()) {
    s.requiredField(f => f.FromAddress, "From Address", wrap(isValidSendingEmail));
    s.requiredField(f => f.ReplyToAddress, "Reply To Address", wrap(isValidSendingEmail));
  } else {
    s.requiredField(f => f.FromAddress, "From Address");
    s.requiredField(f => f.ReplyToAddress, "Reply To Address");
  }
  s.requiredField(f => f.FromName, "From Name");
  s.requiredField(f => f.ReplyToName, "Reply To Name");
  s.field(f => f.Filter, "Filter");
  s.field(f => f.attachments, "Attachments");
});

export const updateMarketingOutreachNewEmailFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.Id, "Id");
  s.field(f => f.OutreachTierId, "Outreach Tier Id");
  s.field(f => f.Type, "Type");
  s.requiredField(f => f.EmailTemplateId, "Email Template");
  s.requiredField(f => f.Subject, "Subject", wrap(noNewLines));
  s.requiredField(f => f.Html, "Content");
  s.field(f => f.HtmlFile, "Content");
  s.field(f => f.Text, "Text Content");
  s.field(f => f.TextFile, "Text Content");
  s.field(f => f.Markup, "Markup Content");
  s.field(f => f.MarkupFile, "Markup Content");
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.requiredField(f => f.Active, "Active");
  s.requiredField(f => f.OpenRating, "Open Rating");
  s.requiredField(f => f.ClickRating, "Click Rating");
  s.requiredField(f => f.Replies, "Reply Management");
  s.field(f => f.SegmentId, "Segment");
  if (isDmarcSendingDomainFF()) {
    s.requiredField(f => f.FromAddress, "From Address", wrap(isValidSendingEmail));
    s.requiredField(f => f.ReplyToAddress, "Reply To Address", wrap(isValidSendingEmail));
  } else {
    s.requiredField(f => f.FromAddress, "From Address");
    s.requiredField(f => f.ReplyToAddress, "Reply To Address");
  }
  s.requiredField(f => f.FromName, "From Name");
  s.requiredField(f => f.ReplyToName, "Reply To Name");
  s.field(f => f.Filter, "Filter");
  s.field(f => f.attachments, "Attachments");
});

export const updateMarketingOutreachEmailFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.Id, "Id");
  s.field(f => f.OutreachTierId, "Outreach Tier Id");
  s.field(f => f.Type, "Type");
  s.requiredField(f => f.EmailTemplateId, "Email Template");
  s.requiredField(f => f.Subject, "Subject", wrap(noNewLines));
  s.requiredField(f => f.Html, "Content");
  s.field(f => f.HtmlFile, "Content");
  s.field(f => f.Text, "Text Content");
  s.field(f => f.TextFile, "Text Content");
  s.field(f => f.Markup, "Markup Content");
  s.field(f => f.MarkupFile, "Markup Content");
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.requiredField(f => f.Active, "Active");
  s.requiredField(f => f.OpenRating, "Open Rating");
  s.requiredField(f => f.ClickRating, "Click Rating");
  s.requiredField(f => f.Replies, "Reply Management");
  s.field(f => f.SegmentId, "Segment");
  if (isDmarcSendingDomainFF()) {
    s.requiredField(f => f.FromAddress, "From Address", wrap(isValidSendingEmail));
    s.requiredField(f => f.ReplyToAddress, "Reply To Address", wrap(isValidSendingEmail));
  } else {
    s.requiredField(f => f.FromAddress, "From Address");
    s.requiredField(f => f.ReplyToAddress, "Reply To Address");
  }
  s.requiredField(f => f.FromName, "From Name");
  s.requiredField(f => f.ReplyToName, "Reply To Name");
  s.field(f => f.Filter, "Filter");
  s.field(f => f.attachments, "Attachments");
});

export const createMarketingOutreachTextMessageFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.OutreachTierId, "Outreach Tier Id");
  s.requiredField(f => f.Type, "Type");
  s.field(f => f.Text, "Text Content");
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.field(f => f.SegmentId, "Segment");
});

export const updateMarketingOutreachTextMessageFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.Id, "Id");
  s.field(f => f.OutreachTierId, "Outreach Tier Id");
  s.field(f => f.Type, "Type");
  s.field(f => f.Text, "Text Content");
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.field(f => f.SegmentId, "Segment");
});

export const createMarketingOutreachPrintPieceContentFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.EmailTemplateId, "Print Template");
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.field(f => f.SegmentId, "Segment");
});

export const updateMarketingOutreachPrintPieceContentFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.Id, "Id");
  s.requiredField(f => f.EmailTemplateId, "Print Template");
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.field(f => f.SegmentId, "Segment");
});

export const createMarketingOutreachVoiceMessageContentFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.requiredField(f => f.VoiceMessageType, "Message Type");
  s.requiredField(f => f.VoiceMessageVoice, "Voice");
  s.field(f => f.Text, "Voice Content");
  s.field(f => f.VoiceMessageUrl, "Message URL");
  s.field(f => f.SegmentId, "Segment");
});

export const updateMarketingOutreachVoiceMessageContentFormCreator = formFor(MarketingOutreachItem, s => {
  s.requiredField(f => f.Id, "Id");
  s.requiredField(f => f.Name, "Name");
  s.requiredField(f => f.DeliveryAddress, "Delivery Address");
  s.requiredField(f => f.VoiceMessageType, "Message Type");
  s.requiredField(f => f.VoiceMessageVoice, "Voice");
  s.field(f => f.Text, "Voice Content");
  s.field(f => f.VoiceMessageUrl, "Message URL");
  s.field(f => f.SegmentId, "Segment");
});
