import { inject, needs } from "fw";

import {
  ApplicationRestriction,
  ApplicationRestrictionType,
} from "models/role";

import { PopoverController } from "service/popover";
import { getDataRestrictionType, dataRestrictionComponents } from "./data-restriction-types";

@inject
@needs(...dataRestrictionComponents)
export class RestrictionSelectorPopover {
  private getDataRestrictionType = getDataRestrictionType;
  private selectedType: ApplicationRestrictionType = null;
  private activeRestrictions: ApplicationRestriction[] = [];

  constructor(private controller: PopoverController<string[]>) { }

  activate(activeRestrictions: ApplicationRestriction[]) {
    this.activeRestrictions = activeRestrictions || [];
  }

  done(result) {
    this.controller.ok(result);
  }

}
