import { prop, inject } from "fw";
import { dispatch } from "fw-state";

import { ApplicationSourceFilter as ApplicationSourceFilterModel } from "models/application-filters";
import { ApplicationSourceStore, EnsureApplicationSourcesAction } from "state/application-source";

@inject
export class ApplicationSourceFilter {
  @prop(null) filter!: ApplicationSourceFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  constructor(private applicationSourceStore: ApplicationSourceStore) {}

  created() {
    dispatch(new EnsureApplicationSourcesAction());
  }

  get selectedApplicationSource() {
    const appSource = this.applicationSourceStore.state.list.find(a => a.Id == this.filter.applicationSourceId);
    return appSource?.Name || "";
  }

  get sources() {
    return this.applicationSourceStore.state.list.map(a => ({ text: a.Name, value: a.Id }));
  }
}
