import { fromClass } from "fw-model";
import { PortalMetaData, PortalContent, PortalSocial } from "./portal";

export class CustomPortalContent extends PortalContent {}
export class CustomPortalMetaData extends PortalMetaData {}
export class CustomPortalSocial extends PortalSocial {}

export class CustomPortal {
  Id: string = null;
  Name: string = null;
  Domain: string = null;
  PrimaryContactType: string = null;
  BrowserTitle: string = null;

  @fromClass Content: CustomPortalContent = null;

  @fromClass MetaData: CustomPortalMetaData = null;

  @fromClass Social: CustomPortalSocial = null;
}
