import { inject } from "fw";
import { Store, handle } from "fw-state";
import { difference } from "lodash-es";

import { once } from "helpers/once";
import { LogoutAction, StartAction } from "./actions";
import { Notification, NotificationProfile } from "models/notification";
import { NotificationRepository } from "network/notification-repository";
import { wait } from "wait";
import {
  EntityChanged,
  WebSocketMessageAction,
  filterEntityChangedMessage
} from './filter-websocket-message';

interface NotificationsStoreShape {
  filter: string;
  search: string;
  page: number;
  pageSize: number;
  sort: string;
  total: number;

  notifications: Notification[];
  profile: NotificationProfile;
  loading: boolean;
  loaded: boolean;
}

export class EnsureNotificationsStoreAction {
  constructor() {}
}

export class FilterNotificationsListAction {
  constructor(public search: string) {}
}

export class SortNotificationsListAction {
  constructor(public sort: string) {}
}

export class RefreshNotificationsStoreAction {
  constructor() {}
}

export class DeleteNotificationsAction {
  constructor(public ids: string[]) {}
}

export class MarkAsReadNotificationsAction {
  constructor(public ids: string[], public isRead: boolean) {}
}

export class MarkAllAsReadNotificationsAction {
  constructor(public onOrBeforeUtc: Date, public isRead: boolean) {}
}

export class MarkAsStarredNotificationsAction {
  constructor(public ids: string[], public isStarred: boolean) {}
}

@inject
export class NotificationsStore extends Store<NotificationsStoreShape> {
  constructor(private notificationRepo: NotificationRepository) {
    super();
  }

  defaultState() {
    return {
      filter: null,
      search: "",
      page: 1,
      pageSize: 25,
      sort: "-metaData.dateCreatedUtc",
      total: 0,

      notifications: [],
      profile: null,
      loaded: false,
      loading: false,
    };
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    this.setState(s => ({
      ...this.defaultState(),
      profile: action.context.NotificationProfile,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  private async loadNotifications() {
    this.setState(state => ({
      ...state,
      loading: true,
    }));
    const { filter, search, sort, page, pageSize } = this.state;

    const { total, list } = await this.notificationRepo.list(
      filter,
      search,
      sort,
      page,
      pageSize,
    );

    this.setState(state => ({
      ...state,
      notifications: list,
      total: total,
      loading: false,
      loaded: true,
    }));
  }

  @handle(EnsureNotificationsStoreAction)
  private async ensureNotifications(action: EnsureNotificationsStoreAction) {
    if (this.state.loading || this.state.loaded) return;
    await this.loadNotifications();
  }

  @handle(FilterNotificationsListAction)
  private async filterNotifications(action: FilterNotificationsListAction) {
    if (this.state.loading) return;
    this.state.search = action.search;
    await this.loadNotifications();
  }

  @handle(SortNotificationsListAction)
  private async handleToggleUsersSortAction(
    action: SortNotificationsListAction,
  ) {
    this.state.sort =
      action.sort == this.state.sort ? `-(${action.sort})` : action.sort;
    await this.loadNotifications();
  }

  @handle(RefreshNotificationsStoreAction)
  private async refreshNotifications(action: RefreshNotificationsStoreAction) {
    if (this.state.loading) return;
    await this.loadNotifications();
  }

  @handle(DeleteNotificationsAction)
  private async handleDeleteNotificationsAction(
    action: DeleteNotificationsAction,
  ) {
    const ids = this.state.notifications.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.notificationRepo.delete(ids.map(t => t.Id));
    await wait(1000);
    const profile = await this.notificationRepo.profile();

    this.setState(state => ({
      ...state,
      notifications: difference(state.notifications, ids),
      profile,
    }));
  }

  @handle(MarkAsReadNotificationsAction)
  private async handleMarkAsReadNotificationsAction(
    action: MarkAsReadNotificationsAction,
  ) {
    const notifications = this.state.notifications.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.notificationRepo.markAsRead(action.ids, action.isRead);
    await wait(1000);
    const profile = await this.notificationRepo.profile();

    notifications.forEach(n => (n.MetaData.IsRead = action.isRead));
    this.setState(state => ({
      ...state,
      profile,
    }));
  }

  @handle(MarkAllAsReadNotificationsAction)
  private async handleMarkAllAsReadNotificationsAction(
    action: MarkAllAsReadNotificationsAction,
  ) {
    await this.notificationRepo.markAllAsRead(
      action.onOrBeforeUtc,
      action.isRead,
    );
    this.state.notifications.forEach(n => (n.MetaData.IsRead = action.isRead));
    await wait(1000);
    const profile = await this.notificationRepo.profile();

    this.setState(state => ({
      ...state,
      profile,
    }));
  }

  @handle(MarkAsStarredNotificationsAction)
  private async handleMarkAsStarredNotificationsAction(
    action: MarkAsStarredNotificationsAction,
  ) {
    const notifications = this.state.notifications.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.notificationRepo.markAsStarred(action.ids, action.isStarred);

    notifications.forEach(n => (n.MetaData.IsStarred = action.isStarred));
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("Notification"))
  private async handleWebSocketMessageAction(
    action: WebSocketMessageAction<EntityChanged>,
  ) {
    const profile = await this.notificationRepo.profile();

    this.setState(state => ({
      ...state,
      profile,
    }));

    // reload if looking at the page or has been loaded
    if (this.state.loaded) {
      await this.loadNotifications();
    }
  }
}
