import { FormForType, formFor } from "fw-model";

import { Workflow, ContactWorkflowSettings } from "models/workflow";

export type WorkflowFormType = FormForType<Workflow>;

export class UpdateWorkflowAction {
  public saved: Workflow;

  constructor(public form: WorkflowFormType) {}
}

export class AddWorkflowAction {
  public saved: Workflow;

  constructor(public form: WorkflowFormType, public objectType?: string) {}
}

export class DeleteWorkflowsAction {
  constructor(public ids: string[]) {}
}

export const contactWorkflowSettingsFormCreator = formFor(ContactWorkflowSettings, s => {
  s.field(u => u.ContactTypeKey, "Contact Type");
});

export const workflowFormCreator = formFor(Workflow, s => {
  s.requiredField(u => u.Name, "Name");
  s.field(u => u.Active, "Active");
  s.field(u => u.Path, "Path");
  s.field(u => u.Triggers, "Triggers");
  s.form(u => u.ContactSettings, "ContactSettings", contactWorkflowSettingsFormCreator)
});
