import { inject } from "fw";

import { Permissions } from "service/permission";
import { UserPermissionService } from "./user";

@inject
export class EventPermissionService {
  constructor(
    private permissions: Permissions,
    private userPermissionService: UserPermissionService,
  ) { }

  public get isGlobalUser(): boolean {
    return this.userPermissionService.isGlobalUser;
  }

  public get isImpersonating(): boolean {
    return this.userPermissionService.isImpersonating;
  }

  public get currentUserId(): string {
    return this.userPermissionService.currentUserId;
  }

  public get canView(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ViewEvents"]);
  }

  public get canManage(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ManageEvents"]);
  }

  public get canManageEmails(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ManageEventEmails"]);
  }

  public get canManageRegistrants(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ManageEventRegistrations"]);
  }

  public get canManageTypes(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ManageEventTypes"]);
  }
}
