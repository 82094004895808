import { inject } from "fw";
import { DialogController } from "fw-dialog";


export enum NotificationType {
  "error",
  "success",
  "warning",
  "default",
}
export interface NotificationDialogArgs {
  title?: string;
  text: string | string[];
  type: NotificationType
}

@inject
export class NotificationDialog {
  private title: string = "";
  private text: string | string[] = "";
  private type: NotificationType = null;
  private closeButtonEl: HTMLElement;

  constructor(private controller: DialogController<void>) { }

  attached() {
    this.closeButtonEl.focus();
  }

  get containerClass() {
    return "c-notification-dialog-" + NotificationType[this.type];
  }

  activate(args: NotificationDialogArgs) {
    this.title = args.title;
    this.text = args.text;
    this.type = args.type;
  }

  get isArrayOfMessages() {
    return Array.isArray(this.text);
  }

  close() {
    this.controller.cancel();
  }
}
