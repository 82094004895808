import { fromClass } from "fw-model";
import { MarketingOutreachItemAttachment } from "./marketing-outreach-item-attachment";
import { ApplicationSegment } from "./application-segment";
import { MarketingOutreachSegment } from "./marketing-outreach-segment";
import { VoiceMessageType } from "./marketing-outreach-sent";

export enum MarketingOutreachItemPrettyTypes {
  email = 'Email',
  print = 'Print',
  text = 'Text',
  voice = 'Voice',
  phone = 'Phone',
}

export enum MarketingOutreachItemIcons {
  email = 'email',
  print = 'print',
  text = 'textsms',
  voice = 'mic',
  phone = 'phone_android',
}

export enum MarketingOutreachItemColors {
  email = '#3978ac',
  print = '#35836b',
  text = '#8364aa',
  voice = '#6c777f',
  phone = '#6c777f',
}

export enum MarketingOutreachItemManagePermissions {
  email = 'ManageEmails',
  print = 'ManagePrintPieces',
  text = 'ManageTextMessages',
  voice = 'ManageVoiceMessages',
  phone = 'ManageVoiceMessages',
}

export enum MarketingOutreachItemSendPermissions {
  email = 'SendEmails',
  print = 'SendPrintPieces',
  text = 'SendTextMessages',
  voice = 'SendVoiceMessages',
  phone = 'SendVoiceMessages',
}

export class MarketingOutreachItem {
  Id: number = null;
  OutreachTierId?: number = null;
  Type: 'email' | 'print' | 'text' | 'voice' = null;
  Name: string = null;
  Subject: string = null;
  Html: string = null;
  Text: string = null;
  Markup: string = null;
  HtmlFile: string = null;
  TextFile: string = null;
  MarkupFile: string = null;
  EmailTemplateId: number = null;
  SegmentId: string = null;
  Segment: MarketingOutreachSegment = null;
  DeliveryAddress: string = null;
  Active: number = 0;
  OpenRating: number = 5;
  ClickRating: number = 10;
  Stats: string = null;

  // temporarily set reply management to 'self' and hide: https://liaison-intl.atlassian.net/browse/ATS-985
  // Replies: string = 'system';
  Replies: string = 'self';

  FromName: string = null;
  FromAddress: string = null;
  ReplyToName: string = null;
  ReplyToAddress: string = null;
  VoiceMessageType: VoiceMessageType = null;
  VoiceMessageVoice: string = null;
  VoiceMessageUrl: string = null;
  AudienceType: string = null;
  Filter: string = null;
  LastSentAt: Date = null;
  attachments: Array<MarketingOutreachItemAttachment> = [];
  @fromClass segment: ApplicationSegment;
}

