import { prop, inject } from "fw";
import { ContactsDataSourceInstanceFilter as ContactsDataSourceInstanceFilterModel } from "models/contacts-filters";

@inject
export class ContactsDataSourceInstanceFilter {
  @prop(null) filter!: ContactsDataSourceInstanceFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  public searchTerm = "";

  private get searching(): boolean {
    return this.searchTerm && this.searchTerm.length > 0;
  }

  public showIntegration(name: string): boolean {
    return !this.searching || name.toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase()) >= 0;
  }

  public get modeText() {
    return {
      any: "Any of the following",
      all: "All of the following",
      none: "None of the following",
    };
  }

  public get modes() {
    return [
      { text: this.modeText["any"], value: "any" },
      { text: this.modeText["all"], value: "all" },
      { text: this.modeText["none"], value: "none" },
    ];
  }

  public get names() : string[] {
    if (!this.filter || !this.filter.integrations || this.filter.integrations.length == 0) {
      return [];
    }
    return this.filter.integrations.filter(i => this.filter.selectedIds.includes(i.id)).map(i => i.name);
  }
}
