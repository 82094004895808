import { prop } from "fw";
import { ContactsCountryFilter as ContactsCountryFilterModel } from "models/contacts-filters";

export class ContactsCountryFilter {
  @prop(null) filter!: ContactsCountryFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  public get valueText() {
    const value = !this.filter.value || this.filter.value === "__missing__" 
      ? "is empty" 
      : `= "${this.filter.value}"`;
    return value;
  }
}
