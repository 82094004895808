import { inject } from "fw";

import { CurrentUserStore } from "state/current-user";

@inject
export class UserPermissionService {
  constructor(
    private currentUserStore: CurrentUserStore,
  ) { }

  public get isGlobalUser(): boolean {
    return this.currentUserStore.state.isGlobalPrincipal;
  }

  public get isImpersonating(): boolean {
    return this.currentUserStore.state?.impersonating;
  }

  public get currentUserId(): string {
    return this.currentUserStore.state?.user?.Id;
  }
}
