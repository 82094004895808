import { prop, inject, needs, ComponentEventBus } from "fw";
import { PopoverService } from "service/popover";
import { LazyUserNames } from "views/components/lazy-user-names";

@inject
@needs(LazyUserNames)
export class UserPill {
  @prop(undefined) public value!: string;
  @prop(false) showRemove!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private popoverService: PopoverService
  ) { }

  public async openContactCard() {
    const id: string = this.value;
    if (!id) {
      return;
    }

    try {
      // const contact = await this.contactRepo.getById(id);
      // this.popoverService.open(ContactCardPopover, <ContactCardPopoverOptions>{
      //   contact: contact
      // });
    } catch (ex) { }
  }

  public remove() {
    this.ceb.dispatch("remove", this.value);
  }
}
