import moment from "moment";
import { filesize } from "filesize";

const dateFormatShort: string = "MMMM D, YYYY";

export const filters = {
  install: Vue => {

    Vue.filter("formatToShortDate", value => {
      if (!value) return;

      if (typeof value === "number" || typeof value === "string") {
        return moment(new Date(value)).format(dateFormatShort);
      }

      if (typeof value === "object" && typeof value.getMonth === "function") {
        return moment(value).format(dateFormatShort);
      }

      return value;
    })

    Vue.filter("filesize", value => {
      if (typeof value !== "number") {
        return value;
      }

      return filesize(value);
    })
  }
};

export default filters;
