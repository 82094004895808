import { inject, Navigator, needs } from "fw";
import { dispatch } from "fw-state";
import { isEqual } from "lodash-es";

import { GoalType } from "models/goal";
import { GoalFormType, goalFormCreator, AddGoalAction, UpdateGoalAction, DeleteGoalAction } from "forms/goal";
import { Confirm } from "service/confirm";
import { Notification } from "service/notification";
import { Permissions } from "service/permission";
import { PopoverService } from "service/popover";
import { ApplicationSegmentStore } from "state/application-segments";
import { ContactSegmentStore, EnsureContactSegmentStoreAction } from "state/contact-segments";
import { GoalsStore, GoalView, LoadGoalHistoryAction, LoadGoalsAction } from "state/goals";
import { SegmentSelectorPopover, SegmentSelectorPopoverResponse } from "views/applicants/segment-selector-popover";
import { ActionHeader } from "views/header/action-header";
import { GoalProgressChart } from "./goal-progress-chart";

interface EditGoalParams {
  goalId?: string;
  context?: string;
}

@inject
@needs(ActionHeader, GoalProgressChart)
export class EditGoal {
  private selectedSegmentName: string = 'Choose a Segment';
  private goalTypeForm: GoalFormType = null;
  private isNewGoal: boolean = false;
  private isSaving: boolean = false;
  private originalGoal: GoalView = null;
  private context: string = null;

  constructor(
    private appSegmentStore: ApplicationSegmentStore,
    private confirm: Confirm,
    private contactSegmentStore: ContactSegmentStore,
    private goalsStore: GoalsStore,
    private nav: Navigator,
    private notifier: Notification,
    private permissions: Permissions,
    private popoverService: PopoverService,
  ) { }

  get hasPermission() {
    return this.permissions.all("ManageGoals");
  }

  get title() {
    return this.isNewGoal ? "Create Goal" : this.originalGoal && this.originalGoal.definition.Name;
  }

  get startDate() {
    return this.originalGoal && this.originalGoal.definition.StartDateUtc;
  }

  get endDate() {
    return this.originalGoal && this.originalGoal.definition.EndDateUtc;
  }

  private countType(goalType) {
    switch (goalType)
    {
      case GoalType.Application: return "application";
      case GoalType.Contact: return "contact";
      default: return "";
    }
  }

  private generateBackUrl() {
    return (this.context != 'dashboard')
      ? '#/analytics/goals'
      : '#/';
  }

  private generateBackText() {
    return (this.context != 'dashboard')
      ? 'Goals'
      : 'Dashboard';
  }

  private async activate(params: EditGoalParams) {
    if (params && params.goalId) {
      await Promise.all([
        dispatch(new EnsureContactSegmentStoreAction()),
        dispatch(new LoadGoalsAction()),
      ]);

      const goalId = params.goalId;
      this.originalGoal = this.goalsStore.state.goals.find(goal => goal.definition.Id == goalId);      
      await dispatch(new LoadGoalHistoryAction(goalId));      
    }

    if (this.originalGoal) {
      this.isNewGoal = false;
      this.goalTypeForm = goalFormCreator(this.originalGoal?.definition);
      this.goalTypeForm.Id = params.goalId;

      if (this.goalTypeForm.GoalType === GoalType.Application) {
        this.selectedSegmentName = this.resolveApplicationSegmentName(this.originalGoal.definition.SegmentId);
      } else if (this.goalTypeForm.GoalType === GoalType.Contact) {
        this.selectedSegmentName = this.resolveContactSegmentName(this.originalGoal.definition.SegmentId);
      }
      this.context = (params.context != null)
        ? params.context
        : null;
    } else {
      this.isNewGoal = true;
      this.goalTypeForm = goalFormCreator(null);      
    }
  }

  private checkDirty() {
    return (this.isNewGoal)
      ? false
      : !isEqual(this.originalGoal.definition, this.goalTypeForm.updatedModel());
  }

  private async chooseSegment() {
    const res = await this.popoverService.open<SegmentSelectorPopoverResponse>(SegmentSelectorPopover);
    if (res.canceled) return;
    const { id, type } = res.result;
    this.goalTypeForm.SegmentId = id;

    if ('ApplicationSegment' === type) {
      this.selectedSegmentName = this.resolveApplicationSegmentName(id);
      this.goalTypeForm.GoalType = GoalType.Application;
    } else if ('ContactSegment' === type) {
      this.selectedSegmentName = this.resolveContactSegmentName(id);
      this.goalTypeForm.GoalType = GoalType.Contact;
    }    
  }

  private resolveApplicationSegmentName(segmentId: string) {
    return this.appSegmentStore.state.segments.find(segment => segment.Id == segmentId)?.Label;
  }

  private resolveContactSegmentName(segmentId: string) {
    return this.contactSegmentStore.state.segments.find(segment => segment.id == segmentId)?.display_name;
  }

  public async del() {
    if (await this.confirm.confirmDelete(`Delete the ${this.goalTypeForm.Name} goal? <div style="font-weight:normal;margin:8px 0 12px">This will remove it from current reports and dashboards. Report snapshots will be unaffected.</div>`)) {
      await dispatch(new DeleteGoalAction(this.goalTypeForm.Id));
      this.notifier.notify(`Deleted goal: ${this.goalTypeForm.Name}`);
      this.nav.navigate("/analytics/goals");
    }
  }

  public async save() {
    this.isSaving = true;

    const action = this.isNewGoal
      ? new AddGoalAction(this.goalTypeForm)
      : new UpdateGoalAction(this.goalTypeForm);

    const existingGoalId = this.isNewGoal
      ? null
      : this.originalGoal && this.originalGoal.definition.Id;

    let throwErr = null;

    try {
      await dispatch(action);
      if (this.isNewGoal) {
        this.nav.navigate("/analytics/goals");
      } else {
        this.originalGoal = this.goalsStore.state.goals.find(goal => goal.definition.Id === existingGoalId);
      }
    } catch (err) {
      this.notifier.error('An error occurred while saving your goal');
      throwErr = err;
    }

    if (throwErr != null) {
      this.isSaving = false;
      throw throwErr;
    }  else {
      this.notifier.notify(`${this.isNewGoal ? "Created a" : "Updated the"} goal for ${this.selectedSegmentName}`);
      window.setTimeout(() => {
        this.originalGoal = null;
        this.originalGoal = this.goalsStore.state.goals.find(goal => goal.definition.Id === existingGoalId);
        this.isSaving = false;
      }, 400);
    }
  }
}
