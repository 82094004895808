import { inject } from "fw";
import { createFrom, createFromArray, cloneOf } from "fw-model";

import { ATS } from "./ats";
import { encodeInquiryPortal } from "./encode";

import { InquiryPortal } from "models/inquiry-portal";

@inject
export class InquiryPortalRepository {
  constructor(private s: ATS) {}

  public async get(id: string) {
    const res = await this.s.get(`inquiry-portal/${id}`);
    return createFrom(InquiryPortal, res.body);
  }

  public async list() {
    const res = await this.s.get<any[]>("inquiry-portal");
    return createFromArray(InquiryPortal, res.body);
  }

  public async delete(id: string) {
    await this.s.delete(`inquiry-portal/${id}`);
  }

  public async post(portal: InquiryPortal) {
    const res = await this.s.post("inquiry-portal", encodeInquiryPortal(portal));
    return createFrom(InquiryPortal, res.body);
  }

  public async put(portal: InquiryPortal) {
    const res = await this.s.put<InquiryPortal>(
      `inquiry-portal/${portal.Id}`,
      encodeInquiryPortal(portal),
    );
    return createFrom(InquiryPortal, res.body);
  }
}
