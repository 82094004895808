import { prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { FieldMode } from "../field-mode";

export class TableType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
}
