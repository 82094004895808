import { needs, inject } from "fw";
import moment from "moment";
import { PopoverController } from "service/popover";
import { CurrentOrganizationStore } from "state/current-organization";
import { CalendarEventRepository } from "network/calendar-event-repository";
import { CalendarEvent } from "models/calendar-event";
import { SearchResults } from "views/components/item-searcher";
import { EventOccurrenceLoadDataContext, EventOccurrenceSearcher } from "./event-occurrence-searcher";

export interface EventOccurrencePopoverOptions {
  series: string;
  sort: string,
}

const dateFormat: string = "MMM D, YYYY [at] h:mm a";

@inject
@needs(EventOccurrenceSearcher)
export class EventOccurrencePopover {
  public series: string = null;
  public sort: string = null;

  constructor(
    private controller: PopoverController<CalendarEvent>,
    private calendarEventRepo: CalendarEventRepository,
    private currentOrganizationStore: CurrentOrganizationStore,
  ) {}

  public activate(options: EventOccurrencePopoverOptions) {
    if (!options) return;

    this.series = options.series;
    this.sort = options.sort;
  }

  public select(calendarEvent: CalendarEvent) {
    this.controller.ok(calendarEvent);
  }

  public async loadData(context: EventOccurrenceLoadDataContext): Promise<SearchResults<CalendarEvent>> {
    const { from, to, page, pageSize } = context;

    if (!from || !to || !this.series) return null;

    const result = await this.calendarEventRepo.list(
      from,
      to,
      this.currentOrganizationStore.state.organization.Timezone,
      null,
      this.series,
      this.sort,
      page,
      pageSize
    );

    return { total: result.total, list: result.results };
  }

  public occurrenceToString(calendarEvent: CalendarEvent) {
    if (!calendarEvent) return null;

    return moment.tz(
      calendarEvent.from_utc,
      this.currentOrganizationStore.state.organization.Timezone
    ).format(dateFormat);
  }
}
