import { Validators } from "fw-model";
import { AsYouType, CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";

const keyRegex = /^[a-z]\w*$/;
const startsWithLower = /^[a-z]/;
const pageSlugRegex = /^[a-z0-9A-Z]+(?:-[a-z0-9A-Z]+)*$/;

export const isValidKey = (input: string) => {
  if (!startsWithLower.test(input)) return "Must start with a lowercase letter";
  if (!keyRegex.test(input)) return "Invalid Key Format";
  return null;
};

export const isValidOptionalKey = (input: string) => {
  if (input === null || !input.length) return null;

  return isValidKey(input);
};

export const isValidPageSlug = (input: string) => {
  if (!pageSlugRegex.test(input)) return "Invalid Format";
  return null;
};

const newLineRegex = /[\n\r]/gi;

export const noNewLines = (input: string): string => {
  if (input == null || input.length == 0) return null;
  return newLineRegex.test(input) ? "No new line characters allowed" : null;
};

export const isValidCompaingFilter = (input: string): string => {
  if (input === null || input.length === 0 || !input.includes("@include:"))
    return "Filter must include or exclude a segment";
  return null;
};

const emailValidCharsRegex = /^[0-9a-zA-Z_.-]+$/;
const domainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;

export const isValidSendingEmail = (input: string): string => {
  if (input == null || input.length == 0) return null;
  const [username] = input.split("@");
  if (!emailValidCharsRegex.test(username)) return "Please remove invalid character(s)";
  return Validators.isEmail(input);
};

export const isValidDomain = (input: string): string => {
  if (input == null || input.length == 0) return null;
  if (!emailValidCharsRegex.test(input)) return "Please remove invalid character(s)";
  if (!domainRegex.test(input)) return "Must be valid domain name";
  return null;
};

export const isValidSegment = (input: string) => {
  if (input == null) return null;
  if (input.trim().length == 0) return "Required";
  return null;
};

export const isValidDate = (date: string | Date) => {
  if (!date) return null;

  const parsedDate = new Date(date);
  // check to see if date was parsed
  if (isNaN(parsedDate.getDate())) {
    return "Invalid date";
  }

  return null;
};

export const eachRequired = (values: string[] | Date[]) => {
  if (!values || !values.length) return "Required";

  let message: string = null;
  values.forEach((value) => {
    if (Validators.required(value)) {
      message = "Required";
    }
  });

  return message;
};

export const isEachValidDate = (dates: string[] | Date[]) => {
  if (!dates || !dates.length) return null;

  let message: string = null;
  dates.forEach((date) => {
    if (isValidDate(date)) {
      message = "Invalid date";
    }
  });

  return message;
};

export const isValidPhoneNumberCountry = (phoneNumber: string) => {
  const dynamicPhoneType = new AsYouType();
  dynamicPhoneType.input(phoneNumber);
  return !!dynamicPhoneType.getCountry();
};

export const isValidPhoneNumber = ({ phone, country }: { phone: string; country: CountryCode }): string => {
  if (!phone) {
    return null;
  }

  const phoneNumber = parsePhoneNumberFromString(phone, country);
  if (!phoneNumber || !(phoneNumber.isPossible() && isValidPhoneNumberCountry(phone))) {
    let errorMessage = "Invalid phone number";
    if (!(country || "+" === phone.charAt(0))) {
      errorMessage += " (missing country selection)";
    }
    return errorMessage;
  }

  return null;
};

export const isValidJavascript = (input: any): string => {
  try {
    new Function(input);
  } catch {
    return "Invalid javascript in condition.";
  }

  return null;
};

export const requiredTextInHtml = (value: string) => {
  const tmp = document.createElement("div");
  tmp.innerHTML = value;
  const textValue = tmp.textContent || tmp.innerText || "";

  if (Validators.required(textValue)) {
    return "Required";
  }

  return null;
};
