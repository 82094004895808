import { inject, ComponentEventBus } from "fw";

let id = 0;

@inject
export class Alert {
  uniqueId = 'alert-' + id++;

  constructor(private ceb: ComponentEventBus) {}

  close() {
    this.ceb.dispatch("close");
  }
}

