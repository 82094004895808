import { ContainerInstance, inject } from "fw";
import { cloneOf, createFrom } from "fw-model";
import { Store, dispatch, handle } from "fw-state";
import { LocalStorageCache } from "caching";
import { debounce } from "lodash-es";

import {
  LogoutAction,
  StartAction,
  ApplyDeleteApplicationAction,
  TagApplicationsAction,
  ApplyTagApplicationsAction,
  WatchTaskAction,
  RefreshFileProviderAction,
  TaskFinishedAction,
  RefreshClientModelAction
} from "./actions";

import { ApplicationRepository } from "network/application-repository";
import { MarkerPoint, DEFAULT_MAPSTATE, MapState, getPrecisionFromZoom, boundingBoxToElasticFilter } from "models/map-state";
import { UpdateApplicationSegmentAction } from "forms/application-segment";
import { ExportRepository } from "network/export-repository";

import { ApplicationClientModel, EntitySelection } from "models/application-client-model";
import { TaskRequest, TaskRequestStatusTypeCode } from "models/task-request";
import { FilterContext, GroupFilter } from "models/filter-setup";
import { GridColumn } from "models/grid-column";
import { ExportDefinition, ExportFormatTypeCode } from "models/export-definition";
import { ApplicationSettingsService } from "service/application-settings";
import { GridColumnsChangedAction } from "forms/application-settings";
import {
  EntityChanged,
  WebSocketMessageAction,
  filterWebsocketMessage
} from './filter-websocket-message';
import { TaskService } from "service/task";
import { DataDictionaryRefreshedAction, DataDictionaryStore } from "./data-dictionary";
import { FeatureFlagService } from "service/feature-flag";
import { ApplicationSegmentsDeletedAction } from "./application-segments";
import { EnsureContactReferencesAction } from "./entity-reference";
import { DataDictionaryFieldDataSource, DataDictionaryIndexStatus, SystemFieldType } from "models/data-dictionary";
import { DataDictionaryService } from "service/data-dictionary";
import { wait } from "wait";

export interface Application {
  id: string;
  clientModel: ApplicationClientModel;
  stale: boolean;
}

interface Term {
  label: string;
  value: number;
}

export interface GridChart {
  loading: boolean;
  fieldKey: string;
  fieldName: string;
  terms: Term[];
  maxTermTotal: number;
}

export type ApplicationMapPoint = {
  id: string;
  name: string;
  program: string;
  address: string;
};

interface MapShape {
  loaded: boolean;
  addressPath: string;
  addressRequiresReindex: boolean,
  markers: MarkerPoint<ApplicationMapPoint>[];
  state: MapState;
}

interface QueryIdsShape {
  aggs: string,
  grid: string,
  map: string,
  mapApplicants: string
}

const DEFAULT_QUERY_IDS_STATE: QueryIdsShape = {
  aggs: null,
  grid: null,
  map: null,
  mapApplicants: null
};

export type ApplicantView = "list" | "map";

interface ApplicantStoreShape {
  organizationId: string;
  userId: string;
  firstPageLoaded: boolean;
  loaded: boolean;
  errorLoading: boolean;

  filterRequiresReindex: boolean;
  filter: GroupFilter;

  addlFilter: string;
  sort: string;
  programId: string;
  segmentId: string;
  applications: Application[];
  gridChart: GridChart;

  phaseCounts: { [phaseId: string]: number };
  phaseIds: string[];

  selection: EntitySelection;

  total: number;
  previousPageToken: string;
  nextPageToken: string;
  currentPreviousPageToken: string;
  currentNextPageToken: string;
  pageSize: number;
  pageNumber: number;

  discardPartialAggUpdates: boolean; // This allows partial updates of async aggs but only for the very first aggregation. This prevents numbers jumping around for refreshes.
  refreshingAggs: boolean; // This allows any async updates to know we should do a refresh instead of full load of grid data.
  hasErrors: boolean;
  singleProgramId: string; // if the result of the search contains only one program id, this will be filled out
  singlePhaseId: string; // if the result of the search contains only one program id, this will be filled out
  view: ApplicantView;
  map: MapShape;
  queryIds: QueryIdsShape;
  applicationInPreview: Application;
}

export class EnsureIdsAction {
  constructor(public programId: string, public clearSegment = false) { }
}

export class ToggleApplicantsViewAction {
  constructor(public v: ApplicantView = null) { }
}

export class SetAddressPathAction {
  constructor(public path: string) { }
}

export class UpdateMapStateAction {
  constructor(public s: MapState) { }
}

export class ExportApplicationsAction {
  public taskId: string = null;

  constructor(
    public selection: EntitySelection,
    public exportDefinitionId: string = null,
    public exportDefinition: ExportDefinition = null,
    public format = ExportFormatTypeCode.JSON,
    public fileProviderId: string = null,
    public fileProviderFolder: string = null,
  ) { }
}

export class NextPageAction { }
export class PreviousPageAction { }

export class SetFilterAction {
  // clear others will set other surrounding state
  // to null so the filter container can stand alone
  constructor(public filter: GroupFilter, public clearOthers = false) { }
}
export class SetApplicationInPreview {
  constructor(public application: Application) { }
}

export class SetAdditionalFilterAction {
  constructor(public addlFilter: string) { }
}

export class SetPhaseFilterAction {
  constructor(public phaseIds: string[]) { }
}

export class ForceFullRefreshGridAction {
  constructor() { }
}

export class RefreshGridAction {
  constructor(public ids: string[], public immediate: boolean = false) { }
}

export class ToggleSortAction {
  constructor(public sort: string) { }
}

export class SelectSegmentAction {
  constructor(public segmentId: string, public clearOthers = false, public clearProgram = false) { }
}

export class LoadGridChartAction {
  constructor(
    public selection: EntitySelection,
    public fieldKey: string,
    public fieldName: string
  ) { }
}

export class UpdateApplicationPropertyAction {
  public taskId: string = null;

  constructor(
    public selection: EntitySelection,
    public fieldKey: string,
    public value: any,
  ) { }
}

export class AddToSelectionAction {
  constructor(public ids: string[] = []) {}
}

export class RemoveFromSelectionAction {
  constructor(public ids: string[] = []) { }
}

const DEFAULT_PAGE_SIZE = 20;
type SavedState = {
  programId: string;
  segmentId: string;
  filter: GroupFilter;
  addlFilter: string;
  filterRequiresReindex: boolean;
  phaseIds: string[];
  sort: string;
  pageNumber: number;
  pageSize: number;
  previousPageToken: string;
  nextPageToken: string;
  currentPreviousPageToken: string;
  currentNextPageToken: string;

  view: ApplicantView,
  addressPath: string;
  mapState: MapState;
  applicationInPreview: Application;
};

@inject
export class ApplicantStore extends Store<ApplicantStoreShape> {
  private debouncedAggregationsWithPageData = debounce(async() => {
      await this.refreshAggregations();
      await this.refreshCurrentPageData()
    }, 1000, { maxWait: 3000 });
  private debouncedAggregations = debounce(() => this.refreshAggregations(), 1000, { maxWait: 3000 });

  constructor(
    private applicationRepo: ApplicationRepository,
    private exportRepo: ExportRepository,
    private cache: LocalStorageCache,
    private taskService: TaskService,
    private ffs: FeatureFlagService,
  ) {
    super();
  }

  defaultState(): ApplicantStoreShape {
    const emptyFilterContainer = createFrom(GroupFilter, {
      operation: "AND",
      filters: [],
    });

    return {
      organizationId: null,
      userId: null,
      selection: new EntitySelection(),
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: 1,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      total: 0,
      firstPageLoaded: false,
      loaded: false,
      errorLoading: false,
      applications: [],
      programId: null,
      segmentId: null,
      phaseCounts: {},
      phaseIds: null,
      gridChart: {
        loading: false,
        fieldKey: null,
        fieldName: null,
        terms: null,
        maxTermTotal: 0
      },
      filter: emptyFilterContainer,
      filterRequiresReindex: false,
      addlFilter: "",
      sort: "applicant.familyName applicant.givenName",
      discardPartialAggUpdates: false,
      refreshingAggs: false,
      singleProgramId: null,
      singlePhaseId: null,
      hasErrors: false,
      view: "list" as ApplicantView,
      map: {
        loaded: false,
        addressPath: null,
        addressRequiresReindex: false,
        markers: [],
        state: DEFAULT_MAPSTATE,
      },
      queryIds: DEFAULT_QUERY_IDS_STATE,
      applicationInPreview: null
    };
  }

  private saveState() {
    if (this.state.organizationId == null) {
      return;
    }

    this.cache.set<SavedState>(
      `${this.state.organizationId}:${this.state.userId}-applicants-store`,
      {
        programId: this.state.programId,
        segmentId: this.state.segmentId,
        filter: this.state.filter,
        addlFilter: this.state.addlFilter,
        filterRequiresReindex: this.state.filterRequiresReindex,
        phaseIds: this.state.phaseIds,
        sort: this.state.sort,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        previousPageToken: this.state.previousPageToken,
        nextPageToken: this.state.nextPageToken,
        currentPreviousPageToken: this.state.currentPreviousPageToken,
        currentNextPageToken: this.state.currentNextPageToken,
        view: this.state.view,
        addressPath: this.state.map.addressPath,
        mapState: this.state.map.state,
        applicationInPreview: this.state.applicationInPreview
      },
    );
  }

  private restoreState() {
    if (this.state.organizationId == null) {
      return;
    }

    const savedState = this.cache.get<SavedState>(`${this.state.organizationId}:${this.state.userId}-applicants-store`);
    if (savedState == null) {
      return;
    }

    const filter = createFrom(GroupFilter, savedState.filter);
    this.setState(state => ({
      ...state,
      programId: savedState.programId,
      segmentId: savedState.segmentId,
      filter: filter,
      addlFilter: savedState.addlFilter,
      filterRequiresReindex: savedState.filterRequiresReindex,
      phaseIds: savedState.phaseIds,
      sort: savedState.sort,
      pageNumber: savedState.pageNumber || 1,
      pageSize: savedState.pageSize || DEFAULT_PAGE_SIZE,
      previousPageToken: savedState.previousPageToken,
      nextPageToken: savedState.nextPageToken,
      currentPreviousPageToken: savedState.currentPreviousPageToken,
      currentNextPageToken: savedState.currentNextPageToken,
      view: savedState.view || "list",
      map: {
        ...state.map,
        addressPath: savedState.addressPath,
        addressRequiresReindex: this.mapRequiresReindexing(savedState.addressPath),
        state: savedState.mapState || DEFAULT_MAPSTATE
      }
    }));
  }

  @handle(LogoutAction)
  private async handleLogout() {
    await this.cancelRefreshAsyncId();
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private handleStart(s: StartAction) {
    const defaultState = this.defaultState();
    defaultState.organizationId = s.context.Organization.Id;
    defaultState.userId = s.context.Me.Id;

    this.setState(s => defaultState);
    this.restoreState();
  }

  @handle(ApplicationSegmentsDeletedAction)
  private async handleApplicationSegmentsDeletedAction(action: ApplicationSegmentsDeletedAction) {
    if (action.segmentIds.includes(this.state.segmentId)) {
      this.setState(state => ({
        ...state,
        segmentId: null,
      }));
    }
  }

  @handle(ToggleApplicantsViewAction)
  private async handleToggleApplicantsViewAction(action: ToggleApplicantsViewAction) {
    let newView = action.v;
    if (newView == null) {
      switch (this.state.view) {
        case "list": newView = "map"; break;
        case "map": newView = "list"; break;
      }
    }

    this.setState(state => ({
      ...state,
      view: newView,
    }));

    this.saveState();

    const { map } = this.state
    if (newView === "map" && !map.loaded) {
      await this.loadMapData();
    }
  }

  private async loadMapData() {
    const { filterRequiresReindex, map, queryIds } = this.state
    if (filterRequiresReindex || map.addressRequiresReindex) {
      return;
    }

    const path = map.addressPath;
    if (path == null) {
      return;
    }

    let filter = this.getWholeFilter();

    const bounding = map.state.bounding;
    if (bounding != null) {
      const bFilter = boundingBoxToElasticFilter(bounding);

      if (filter.length > 0) {
        filter = `(${filter}) AND ${path}:${bFilter}`;
      } else {
        filter = `${path}:${bFilter}`;
      }
    }
    if (queryIds.mapApplicants) {
      // process applicant map records as it was triggered after the previous async search result.
      await this.loadMapDataApplicants(filter, path);
      return;
    }

    const geoResponse = await this.applicationRepo.search(filter, null, null, null, null, `geogrid:${path}~${getPrecisionFromZoom(map.state.zoom)}`, true, this.ffElectiveIndexing, queryIds.map);
    const points: MarkerPoint<any>[] = [];
    if (!geoResponse.AsyncQueryId) {
      if (geoResponse.Total <= 20) {
        await this.loadMapDataApplicants(filter, path);
        return;
      } else {
        const aggs = geoResponse.Aggregations[`geogrid_${path}`];
        if (aggs) {
          for (const i of aggs.Items) {
            points.push({ total: i.Total, lat: i.Aggregations.avg_lat.Value, long: i.Aggregations.avg_lon.Value });
          }
        }
      }
    }

    this.setState(state => ({
      ...state,
      map: {
        ...state.map,
        loaded: !geoResponse.AsyncQueryId,
        markers: points
      },
      queryIds: {
        ...state.queryIds,
        map: geoResponse.AsyncQueryId,
        mapApplicants: null
      }
    }));
    this.scheduleRefreshAsyncId();
  }

  private async loadMapDataApplicants(filter: string, path: string) {
    const { mapApplicants } = this.state.queryIds;

    const splitPath = path.split(".");
    if (splitPath[splitPath.length - 1] == "geo") {
      splitPath.splice(splitPath.length - 1, 1);
    }
    const points: MarkerPoint<any>[] = [];
    const formFieldMask = splitPath.map((i, idx) => `${i}${idx == splitPath.length - 1 ? "" : "("}`).join("") + ")".repeat(splitPath.length - 1);
    const applicants = await this.applicationRepo.clientModelSearch(filter, null, null, null, null, `Results(id,program(name),applicant(name),${formFieldMask})`, 200, this.ffElectiveIndexing, mapApplicants);
    for (const cm of applicants.Results) {
      let geo = cm;
      for (const path of [...splitPath, "geo"]) {
        if (geo[path]) geo = geo[path];
      }

      const addressComponents: string[] = [];
      if (geo.city) addressComponents.push(geo.city);
      if (geo.geoLevel1) addressComponents.push(geo.geoLevel1);
      if (geo.postalCode) addressComponents.push(geo.postalCode);

      const point: MarkerPoint<ApplicationMapPoint> = {
        data: {
          id: cm.id,
          name: cm.applicant.name,
          program: cm.program.name,
          address: addressComponents.join(", "),
        },
        lat: geo.latitude || geo.lat,
        long: geo.longitude || geo.long,
        total: 1,
      };
      points.push(point);
    }

    this.setState(state => ({
      ...state,
      map: {
        ...state.map,
        loaded: !applicants.AsyncQueryId,
        markers: points
      },
      queryIds: {
        ...state.queryIds,
        map: null,
        mapApplicants: applicants.AsyncQueryId
      }
    }));

    this.scheduleRefreshAsyncId();
  }

  @handle(SetAddressPathAction)
  private async handleSetAddressPathAction(action: SetAddressPathAction) {
    await this.cancelRefreshAsyncId();

    this.setState(state => ({
      ...state,
      map: {
        ...state.map,
        loaded: false,
        addressPath: action.path,
        addressRequiresReindex: this.mapRequiresReindexing(action.path),
      },
      queryIds: DEFAULT_QUERY_IDS_STATE
    }));

    this.saveState();
    await this.loadMapData();
  }

  @handle(UpdateMapStateAction)
  private async handleUpdateMapStateAction(action: UpdateMapStateAction) {
    await this.cancelRefreshAsyncId();

    this.setState(state => ({
      ...state,
      map: {
        ...state.map,
        loaded: false,
        state: action.s
      },
      queryIds: DEFAULT_QUERY_IDS_STATE
    }));

    this.saveState();
    await this.loadMapData();
  }

  @handle(EnsureIdsAction)
  private async handleEnsureIds(action: EnsureIdsAction) {
    if (this.state.programId != action.programId) {
      await this.cancelRefreshAsyncId();
      this.setState(state => ({
        ...state,
        loaded: false,
        errorLoading: false,
        firstPageLoaded: false,
        discardPartialAggUpdates: false,
        refreshingAggs: false,
        programId: action.programId,
        pageNumber: 1,
        previousPageToken: null,
        nextPageToken: null,
        currentPreviousPageToken: null,
        currentNextPageToken: null,
        singleProgramId: null,
        applicationInPreview: null,
        selection: new EntitySelection(),
        segmentId: action.clearSegment ? null : state.segmentId,
        queryIds: DEFAULT_QUERY_IDS_STATE
      }));
    }

    this.saveState();
    if (this.state.loaded || this.state.firstPageLoaded) {
      return;
    }

    await this.loadStoreData();
  }

  @handle(SetApplicationInPreview)
  private async handleSetApplicationInPreview(action: SetApplicationInPreview) {
    this.setState(state => ({
      ...state,
      applicationInPreview: action.application
    }));
  }

  @handle(SetFilterAction)
  private async handleSetFilter(s: SetFilterAction) {
    await this.cancelRefreshAsyncId();

    this.setState(state => ({
      ...state,
      firstPageLoaded: false,
      discardPartialAggUpdates: false,
      refreshingAggs: false,
      loaded: false,
      errorLoading: false,
      pageNumber: 1,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      singleProgramId: null,
      selection: new EntitySelection(),
      applicationInPreview: null,

      filter: cloneOf(GroupFilter, s.filter),
      filterRequiresReindex: this.filterRequiresReindexing(s.filter),
      addlFilter: s.clearOthers ? "" : state.addlFilter,
      programId: s.clearOthers ? null : state.programId,
      segmentId: s.clearOthers ? null : state.segmentId,
      phaseIds: s.clearOthers ? null : state.phaseIds,
      queryIds: DEFAULT_QUERY_IDS_STATE
    }));

    this.saveState();
    await this.loadStoreData();
  }

  @handle(SetAdditionalFilterAction)
  private async handleSetAdditionalFilter(s: SetAdditionalFilterAction) {
    await this.cancelRefreshAsyncId();
    this.setState(state => ({
      ...state,
      firstPageLoaded: false,
      discardPartialAggUpdates: false,
      refreshingAggs: false,
      loaded: false,
      errorLoading: false,
      pageNumber: 1,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      singleProgramId: null,
      selection: new EntitySelection(),
      addlFilter: s.addlFilter,
      queryIds: DEFAULT_QUERY_IDS_STATE
    }));

    this.saveState();
    await this.loadStoreData();
  }

  @handle(SetPhaseFilterAction)
  private async handleSetPhaseFilterAction(s: SetPhaseFilterAction) {
    await this.cancelRefreshAsyncId();
    this.setState(state => ({
      ...state,
      firstPageLoaded: false,
      discardPartialAggUpdates: false,
      refreshingAggs: false,
      loaded: false,
      errorLoading: false,
      pageNumber: 1,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      singleProgramId: null,
      applicationInPreview: null,
      selection: new EntitySelection(),
      phaseIds: s.phaseIds,
      queryIds: DEFAULT_QUERY_IDS_STATE
    }));

    this.saveState();
    await this.loadStoreData();
  }

  @handle(GridColumnsChangedAction)
  private async handleGridColumnsChangedAction(s: GridColumnsChangedAction) {
    await this.cancelRefreshAsyncId();
    this.setState(state => ({
      ...state,
      firstPageLoaded: false,
      discardPartialAggUpdates: false,
      refreshingAggs: false,
      loaded: false,
      errorLoading: false,
      pageNumber: 1,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      singleProgramId: null,
      queryIds: DEFAULT_QUERY_IDS_STATE
    }));

    this.saveState();
    await this.loadStoreData();
  }

  @handle(NextPageAction)
  private async handleNextPage(s: NextPageAction) {
    const { nextPageToken, pageSize, pageNumber, total } = this.state;

    if (pageNumber * pageSize > total) {
      return;
    }

    await this.cancelRefreshAsyncId();
    this.setState(state => ({
      ...state,
      loaded: false,
      pageNumber: pageNumber + 1,
      queryIds: DEFAULT_QUERY_IDS_STATE
    }));

    this.saveState();
    await this.loadPageData(null, nextPageToken);
  }

  @handle(PreviousPageAction)
  private async handlePreviousPage(s: PreviousPageAction) {
    const { pageSize, previousPageToken, pageNumber, total } = this.state;
    if (pageNumber <= 1) {
      return;
    }

    await this.cancelRefreshAsyncId();
    this.setState(state => ({
      ...state,
      loaded: false,
      pageNumber: pageNumber - 1,
      queryIds: DEFAULT_QUERY_IDS_STATE
    }));

    this.saveState();
    await this.loadPageData(previousPageToken, null);
  }

  @handle(ToggleSortAction)
  private async handleToggleSort(ts: ToggleSortAction) {
    if (ts.sort == this.state.sort) {
      this.setState(state => ({ ...state, sort: `-(${ts.sort})` }));
    } else {
      this.setState(state => ({ ...state, sort: ts.sort }));
    }

    this.saveState();
    await dispatch(new SetFilterAction(this.state.filter));
  }

  @handle(SelectSegmentAction)
  private async handleSelectSegmentAction(action: SelectSegmentAction) {
    const filter = action.clearOthers ? createFrom(GroupFilter, { operation: "AND", filters: [] }) : this.state.filter;

    this.setState(state => ({
      ...state,
      segmentId: action.segmentId,
      filter: filter,
      filterRequiresReindex: this.filterRequiresReindexing(filter),
      addlFilter: action.clearOthers ? "" : state.addlFilter,
      programId: action.clearOthers || action.clearProgram ? null : state.programId,
      phaseIds: action.clearOthers ? null : state.phaseIds
    }));

    this.saveState();
    await dispatch(new SetFilterAction(this.state.filter));
  }

  @handle(UpdateApplicationSegmentAction)
  private async handleUpdateApplicationSegmentAction(f: UpdateApplicationSegmentAction) {
    // reset state if the current loaded segment is loaded..
    // so you can go back and forth
    if (this.state.segmentId == f.form.Id) {
      await this.cancelRefreshAsyncId();
      this.setState(state => ({
        ...state,
        loaded: false,
        firstPageLoaded: false,
        discardPartialAggUpdates: false,
        refreshingAggs: false,
        pageNumber: 1,
        errorLoading: false,
        previousPageToken: null,
        nextPageToken: null,
        currentPreviousPageToken: null,
        currentNextPageToken: null,
        singleProgramId: null,
        selection: new EntitySelection(),
        queryIds: DEFAULT_QUERY_IDS_STATE
      }));
    }
  }

  @handle(LoadGridChartAction)
  private async handleLoadGridChart(action: LoadGridChartAction) {
    if (action.fieldKey == null) {
      return;
    }

    this.setState(state => ({
      ...state,
      gridChart: {
        ...state.gridChart,
        loading: true,
        fieldKey: action.fieldKey,
        fieldName: action.fieldName,
        terms: [],
      },
    }));

    const filters: string[] = [];
    if (action.selection.ids.length > 0) {
      filters.push(`(id:${action.selection.ids.join(" OR id:")})`);
    } else if (action.selection.filter) {
      filters.push(action.selection.filter);
    }

    if (action.selection.excludedIds.length > 0) {
      filters.push(`-(id:${action.selection.excludedIds.join(" OR id:")})`);
    }

    const res = await this.applicationRepo.search(
      filters.join(" AND "),
      null,
      null,
      null,
      null,
      `terms:${action.fieldKey}~100`,
      true
    );
    const aggTerms = res.Aggregations[`terms_${action.fieldKey}`];

    const terms: Term[] = [];

    for (let i = 0; i < aggTerms.Items.length; i++) {
      const term = aggTerms.Items[i];
      terms.push({ label: term.Key, value: term.Total });
    }

    this.setState(state => ({
      ...state,
      gridChart: {
        ...state.gridChart,
        loading: false,
        terms,
        maxTermTotal: Math.max(...terms.map(t => t.value)),
      }
    }));
  }

  @handle(ApplyDeleteApplicationAction)
  private handleApplyDeleteApplicationAction(action: ApplyDeleteApplicationAction) {
    const application = this.state.applications.find(
      a => a.id == action.applicationId,
    );
    if (application != null) {
      const idx = this.state.applications.indexOf(application);
      this.state.applications.splice(idx, 1);
      this.setState(s => s);
    }
    if (this.state.applicationInPreview?.id == action.applicationId) {
      dispatch(new SetApplicationInPreview(null));
    }
  }

  @handle(ExportApplicationsAction)
  private async handleExportApplicationsAction(action: ExportApplicationsAction) {
    let task: TaskRequest = null;
    switch (action.format) {
      case ExportFormatTypeCode.Tabular:
        task = await this.exportRepo.tabular(
          action.selection,
          action.exportDefinitionId,
          action.exportDefinition,
          action.fileProviderId,
          action.fileProviderFolder,
        );
        break;
      case ExportFormatTypeCode.PDF:
        task = await this.exportRepo.pdf(
          action.selection,
          action.exportDefinitionId,
          action.fileProviderId,
          action.fileProviderFolder,
        );
        break;
      case ExportFormatTypeCode.Files:
        task = await this.exportRepo.files(
          action.selection,
          action.exportDefinitionId,
          action.fileProviderId,
          action.fileProviderFolder,
        );
        break;
      default:
        task = await this.exportRepo.json(
          action.selection,
          action.exportDefinitionId,
          action.fileProviderId,
          action.fileProviderFolder,
        );
        break;
    }

    action.taskId = task.Id;

    if (
      action.fileProviderId != null &&
      action.fileProviderFolder != null &&
      action.fileProviderFolder.length > 0
    ) {
      await dispatch(new RefreshFileProviderAction(action.fileProviderId));
    }

    await dispatch(new WatchTaskAction(task, "Application Export"));
  }

  @handle(TagApplicationsAction)
  private async handleTagApplicationsAction(action: TagApplicationsAction) {
    const task = await this.applicationRepo.tag(action.selection, action.addTags, action.removeTags);
    action.taskId = task.Id;

    await dispatch(new ApplyTagApplicationsAction(action.selection, action.addTags, action.removeTags));
  }

  @handle(UpdateApplicationPropertyAction)
  private async handleUpdateApplicationPropertyAction(action: UpdateApplicationPropertyAction) {
    const task = await this.applicationRepo.putPropertyValues(action.selection, {
      [action.fieldKey]: action.value,
    });

    action.taskId = task.Id;
  }

  @handle(AddToSelectionAction)
  private async handleAddToSelectionAction(action: AddToSelectionAction) {
    const { selection } = this.state;
    if (action.ids.length === 0) {
      // select all.
      selection.filter = this.getWholeFilter();
      selection.ids = [];
      selection.excludedIds = [];
    } else if (selection.excludedIds.length > 0) {
      for (const id of action.ids) {
        const index = selection.excludedIds.indexOf(id);
        if (index > -1) {
          selection.excludedIds.splice(index, 1);
        }
      }
    } else if (!selection.filter) {
      selection.ids = [...new Set([...selection.ids, ...action.ids])];
    }

    this.setState(state => ({
      ...state,
      selection: selection
    }));
  }

  @handle(RemoveFromSelectionAction)
  private async handleRemoveFromSelectionAction(action: RemoveFromSelectionAction) {
    const { selection } = this.state;
    if (action.ids.length === 0) {
      // unselect all.
      selection.filter = null;
      selection.ids = [];
      selection.excludedIds = [];
    } else if (selection.ids.length > 0) {
      for (const id of action.ids) {
        const index = selection.ids.indexOf(id);
        if (index > -1) {
          selection.ids.splice(index, 1);
        }
      }
    } else if (selection.filter !== null) {
      selection.excludedIds = [...new Set([...selection.excludedIds, ...action.ids])];
    }

    this.setState(state => ({
      ...state,
      selection: selection
    }));
  }

  private async loadStoreData() {
    const { discardPartialAggUpdates, currentPreviousPageToken, currentNextPageToken, filterRequiresReindex, pageSize, sort, view, queryIds } = this.state;
    if (filterRequiresReindex) {
      await this.cancelRefreshAsyncId();

      this.setState(state => ({
        ...state,
        selection: new EntitySelection(),
        pageNumber: 1,
        previousPageToken: null,
        nextPageToken: null,
        currentPreviousPageToken: null,
        currentNextPageToken: null,
        total: 0,
        firstPageLoaded: false,
        loaded: false,
        errorLoading: false,
        applications: [],
        phaseCounts: {},
        phaseIds: null,
        gridChart: {
          ...state.gridChart,
          loading: false,
          terms: null,
          maxTermTotal: 0
        },
        discardPartialAggUpdates: false,
        refreshingAggs: false,
        singleProgramId: null,
        singlePhaseId: null,
        hasErrors: false,
        map: {
          ...state.map,
          loaded: false,
          markers: []
        },
        queryIds: DEFAULT_QUERY_IDS_STATE
      }));

      return;
    }

    const hasAsyncQueryId: boolean = !!queryIds.aggs || !!queryIds.grid;
    try {
      const clientModelResponse = !this.ffElectiveIndexing || !hasAsyncQueryId || queryIds.grid
        ? await this.applicationRepo.clientModelSearch(this.getWholeFilter(), sort, currentPreviousPageToken, currentNextPageToken, this.clientModelAggs, this.getClientModelFields(), pageSize, this.ffElectiveIndexing, queryIds.grid)
        : null;
      const contactIds = [...new Set(clientModelResponse?.Results.map(clientModel => clientModel?.applicant?.id))];
      if (contactIds.length > 0) {
        dispatch(new EnsureContactReferencesAction(contactIds));
      }
      const phaseCountsResponse = !this.ffElectiveIndexing || !hasAsyncQueryId || queryIds.aggs
        ? await this.applicationRepo.search(this.getWholeFilter(false), null, null, null, null, "terms:phaseId~100", true, this.ffElectiveIndexing, queryIds.aggs)
        : null;

      const aggsQueryId: string = phaseCountsResponse ? phaseCountsResponse.AsyncQueryId : queryIds.aggs;
      const gridQueryId: string = clientModelResponse ? clientModelResponse.AsyncQueryId : queryIds.grid;

      let { singleProgramId, singlePhaseId, hasErrors } = clientModelResponse ? this.processProgramPhaseAggs(clientModelResponse.Aggregations) : this.state;
      // NOTE: Only update aggs if it's the first ever async query partial result.
      if (discardPartialAggUpdates && aggsQueryId) {
        singleProgramId = this.state.singleProgramId;
        singlePhaseId = this.state.singlePhaseId;
        hasErrors = this.state.hasErrors;
      }

      let phaseCounts = phaseCountsResponse ? this.processPhaseAggs(phaseCountsResponse) : this.state.phaseCounts;
      if (discardPartialAggUpdates && aggsQueryId) {
        phaseCounts = this.state.phaseCounts;
      }

      this.setState(state => ({
        ...state,
        firstPageLoaded: true,
        loaded: !gridQueryId,
        discardPartialAggUpdates: discardPartialAggUpdates || !aggsQueryId,
        phaseCounts,
        singleProgramId,
        singlePhaseId,
        hasErrors,
        total: clientModelResponse ? clientModelResponse.Total : state.total,
        previousPageToken: clientModelResponse ? clientModelResponse.PreviousPageToken : state.previousPageToken,
        nextPageToken: clientModelResponse ? clientModelResponse.NextPageToken : state.nextPageToken,
        applications: clientModelResponse ? clientModelResponse.Results.map(cm => <Application>{ id: cm.id, clientModel: cm, stale: false }) : state.applications,
        map: {
          ...state.map,
          loaded: false
        },
        queryIds: {
          ...state.queryIds,
          aggs: aggsQueryId,
          grid: gridQueryId
        }
      }));

      if (view === "map") {
        await this.loadMapData();
      }
    } catch (err) {
      this.setState(state => ({ ...state, errorLoading: true }));
    }

    this.scheduleRefreshAsyncId();
  }

  private async loadPageData(previousPageToken: string, nextPageToken: string) {
    const { filterRequiresReindex, pageSize, sort, queryIds } = this.state;
    if (filterRequiresReindex) {
      return;
    }

    const clientModelResponse = await this.applicationRepo.clientModelSearch(this.getWholeFilter(), sort, previousPageToken, nextPageToken, null, this.getClientModelFields(), pageSize, this.ffElectiveIndexing, queryIds.grid);
    this.setState(state => ({
      ...state,
      loaded: !clientModelResponse.AsyncQueryId,
      previousPageToken: clientModelResponse.PreviousPageToken,
      nextPageToken: clientModelResponse.NextPageToken,
      currentPreviousPageToken: previousPageToken,
      currentNextPageToken: nextPageToken,
      applications: clientModelResponse.Results.map(cm => <Application>{ id: cm.id, clientModel: cm, stale: false }),
      queryIds: {
        ...state.queryIds,
        grid: clientModelResponse.AsyncQueryId
      }
    }));

    const contactIds = [...new Set(clientModelResponse?.Results.map(clientModel => clientModel?.applicant?.id))];
    if (contactIds.length > 0) {
        dispatch(new EnsureContactReferencesAction(contactIds));
    }

    this.scheduleRefreshAsyncId();
  }

  @handle(DataDictionaryRefreshedAction)
  private async handleDataDictionaryRefreshedAction(action: DataDictionaryRefreshedAction) {
    const { filter, filterRequiresReindex, map } = this.state;

    // Handles debouncing extra searches if the filter status hasn't changed.
    // NOTE: The data dictionary may not be initialized the first time search is loaded.
    if (filterRequiresReindex !== this.filterRequiresReindexing(filter)) {
      await dispatch(new SetFilterAction(this.state.filter));
    }

    if (map.addressRequiresReindex !== this.mapRequiresReindexing(map.addressPath)) {
      await dispatch(new SetAddressPathAction(map.addressPath));
    }
  }

  @handle(ForceFullRefreshGridAction)
  private async handleForceFullRefreshGridAction(action: ForceFullRefreshGridAction) {
    await dispatch(new SetFilterAction(this.state.filter));
  }

  @handle(RefreshGridAction)
  private async handleRefreshGrid(action: RefreshGridAction) {
    const { applications, loaded, filterRequiresReindex, refreshingAggs, queryIds, applicationInPreview } = this.state;
    if (!loaded || filterRequiresReindex) {
      return;
    }

    // Don't refresh if any async work is in progress otherwise we will be refreshing incomplete data.
    if (refreshingAggs || queryIds.aggs || queryIds.grid) {
      return;
    }

    const applicationInPreviewChanged = action.ids?.length > 0 && applicationInPreview && action.ids.some(id => applicationInPreview?.id === id);
    const gridRequiresRefresh = !action.ids?.length || applicationInPreviewChanged || (action.ids?.length > 0
      && applications.filter(app => action.ids.some(id => app.id === id))?.length > 0);

    if (gridRequiresRefresh) {
      await this.debouncedAggregationsWithPageData();
    } else {
      await this.debouncedAggregations();
    }

    if (action.immediate) {
      await gridRequiresRefresh
        ? this.debouncedAggregationsWithPageData.flush()
        : this.debouncedAggregations.flush();
    }
  }

  private async refreshCurrentPageData(): Promise<void> {
    const { applications, loaded, filterRequiresReindex, sort, applicationInPreview } = this.state;
    if (!loaded || filterRequiresReindex) {
      return;
    }

    const staleApplications: Application[] = [];
    const applicationsToCheck = [...applications];
    const applicationInPreviewMissingOnGrid = applicationInPreview && !applications.find(app => app.id === applicationInPreview.id);
    if (applicationInPreviewMissingOnGrid) {
      applicationsToCheck.push(applicationInPreview);
    }
    let filter: string = `${this.getWholeFilter()} (id:${applicationsToCheck.map(app => app.id).join(" OR id:")})`;
    let clientModelResponse = await this.applicationRepo.clientModelSearch(filter, sort, null, null, null, this.getClientModelFields());
    for (const application of applicationsToCheck) {
      // deleted applications will not be updated.
      const model = clientModelResponse.Results?.find(cm => cm.id === application.id);
      if (model) {
        Object.assign(application.clientModel, model);
      } else {
        application.stale = true;
        if (!(applicationInPreview?.id === application.id && applicationInPreviewMissingOnGrid)) {
          staleApplications.push(application);
        }
      }
    }

    // Try an update stale applications so grid content is refreshed but is not in the current filter.
    if (staleApplications.length > 0) {
      filter = `id:${applications.map(a => a.id).join(" OR id:")}`;
      clientModelResponse = await this.applicationRepo.clientModelSearch(filter, sort, null, null, null, this.getClientModelFields());
      for (const application of applications) {
        const model = clientModelResponse.Results?.find(cm => cm.id === application.id);
        if (model) {
          Object.assign(application.clientModel, model);
        }
      }
    }

    this.setState(state => state);
  }

  private async refreshAggregations(): Promise<void> {
    const { discardPartialAggUpdates, loaded, previousPageToken, currentNextPageToken, filterRequiresReindex, nextPageToken, queryIds } = this.state;
    if (!loaded || filterRequiresReindex) {
      return;
    }

    const hasAsyncQueryId: boolean = !!queryIds.aggs || !!queryIds.grid;
    try {
      const clientModelResponse = !this.ffElectiveIndexing || !hasAsyncQueryId || queryIds.grid
        ? await this.applicationRepo.clientModelSearch(this.getWholeFilter(), null, null, null, this.clientModelAggs, this.getClientModelFields(), 0, this.ffElectiveIndexing, queryIds.grid)
        : null;
      const phaseCountsResponse = !this.ffElectiveIndexing || !hasAsyncQueryId || queryIds.aggs
        ? await this.applicationRepo.search(this.getWholeFilter(false), null, null, null, null, "terms:phaseId~100", true, this.ffElectiveIndexing, queryIds.aggs)
        : null;

      const aggsQueryId: string = phaseCountsResponse ? phaseCountsResponse.AsyncQueryId : queryIds.aggs;
      const gridQueryId: string = clientModelResponse ? clientModelResponse.AsyncQueryId : queryIds.grid;
      const isAsyncQueryRunning: boolean = !!aggsQueryId || !!gridQueryId;

      let { singleProgramId, singlePhaseId, hasErrors } = clientModelResponse ? this.processProgramPhaseAggs(clientModelResponse.Aggregations) : this.state;
      // NOTE: Only update aggs if it's the first ever async query partial result.
      if (discardPartialAggUpdates && aggsQueryId) {
        singleProgramId = this.state.singleProgramId;
        singlePhaseId = this.state.singlePhaseId;
        hasErrors = this.state.hasErrors;
      }

      let phaseCounts = phaseCountsResponse ? this.processPhaseAggs(phaseCountsResponse) : this.state.phaseCounts;
      if (discardPartialAggUpdates && aggsQueryId) {
        phaseCounts = this.state.phaseCounts;
      }

      const gridQueryFinished: boolean = !gridQueryId && !!clientModelResponse;
      this.setState(state => ({
        ...state,
        phaseCounts,
        singleProgramId,
        singlePhaseId,
        hasErrors,
        discardPartialAggUpdates: discardPartialAggUpdates || !isAsyncQueryRunning,
        refreshingAggs: isAsyncQueryRunning,
        previousPageToken: !gridQueryFinished || clientModelResponse.Total > 0 ? previousPageToken : null,
        currentNextPageToken: !gridQueryFinished || clientModelResponse.Total > 0 ? currentNextPageToken : null,
        nextPageToken: !gridQueryFinished || clientModelResponse.Total > 0 ? nextPageToken : null,
        total: gridQueryFinished ? clientModelResponse.Total : state.total,
        queryIds: {
          ...state.queryIds,
          aggs: aggsQueryId,
          grid: gridQueryId
        }
      }));
    } catch(err) {
      console.log(err);
    }

    this.scheduleRefreshAsyncId();
  }

  private getClientModelFields(): string {
    const service = ContainerInstance.get(ApplicationSettingsService);
    let gridColumns = [...service.gridColumns];
    if (gridColumns.find(column => 'applicant.name' === column?.Path)) {
      // applicant.name is more than just a name: fetch extra data for this one-off column
      gridColumns.push(new GridColumn({ Path: 'publicId' }));
    }
    if (!gridColumns.find(column => 'applicant.id' === column?.Path)) {
      //applicant id is always needed for preview panel to work, so we'll just ensure it here.
      gridColumns.push(new GridColumn({ Path: 'applicant.id' }));
    }
    const columnMask = service.toFieldMask(gridColumns);
    return `Aggregations,PreviousPageToken,NextPageToken,AsyncQueryId,Total,Results(id,${columnMask})`;
  }

  private get clientModelAggs(): string {
    return "terms:programId terms:(phaseId~100 @missing:_in_progress) sum:errorCount";
  }

  public getWholeFilter(includePhase = true) {
    const dataDictionaryStore = ContainerInstance.get(DataDictionaryStore);
    const filterContext: FilterContext = {
      fields: dataDictionaryStore.state.fields
    };
    let filter = this.state.filter.toFilterString(filterContext);

    if (this.state.segmentId != null) {
      if (filter.length > 0)
        filter = `(@include:${this.state.segmentId}) AND (${filter})`;
      else filter = `@include:${this.state.segmentId}`;
    }

    if (this.state.programId != null) {
      if (filter.length > 0)
        filter = `(programId:${this.state.programId}) AND (${filter})`;
      else filter = `programId:${this.state.programId}`;
    }

    if (includePhase && this.state.phaseIds != null && this.state.phaseIds.length > 0) {
      const phaseFilter =
        this.state.phaseIds.length == 1 && this.state.phaseIds[0] == "in-progress"
          ? "_missing_:phaseId"
          : this.state.phaseIds.map(p => `phaseId:${p}`).join(" OR ");

      if (filter.length > 0) filter = `(${phaseFilter}) AND (${filter})`;
      else filter = phaseFilter;
    }

    if (this.state.addlFilter.trim().length > 0) {
      if (filter.length > 0)
        filter = `(${filter}) AND (${this.state.addlFilter.trim()})`;
      else filter = this.state.addlFilter.trim();
    }

    return filter;
  }

  private processProgramPhaseAggs(aggregations): { singleProgramId: string, singlePhaseId: string, hasErrors: boolean } {
    let singleProgramId: string = null;
    let singlePhaseId: string = null;
    let hasErrors = false;

    if (aggregations != null) {
      if (
        aggregations["terms_programId"] != null &&
        aggregations["terms_programId"].Items.length == 1
      ) {
        singleProgramId = aggregations["terms_programId"].Items[0].Key;
      }

      if (
        aggregations["terms_phaseId"] != null &&
        aggregations["terms_phaseId"].Items.length == 1
      ) {
        singlePhaseId = aggregations["terms_phaseId"].Items[0].Key;
      }

      if (aggregations["sum_errorCount"] != null) {
        hasErrors = aggregations["sum_errorCount"].Value > 0;
      }
    }

    return { singleProgramId, singlePhaseId, hasErrors };
  }

  private processPhaseAggs(response): { [phaseId: string]: number } {
    const phaseCounts: { [id: string]: number } = {};
    let remaining = response.Total;

    const { Aggregations } = response;
    if (Aggregations != null && Aggregations["terms_phaseId"] != null) {
      Aggregations["terms_phaseId"].Items.forEach(i => {
        if (phaseCounts[i.Key] == null) {
          phaseCounts[i.Key] = 0;
        }

        phaseCounts[i.Key] += i.Total;
        remaining -= i.Total;
      });
    }

    phaseCounts["in-progress"] = remaining;
    return phaseCounts;
  }

  @handle(WebSocketMessageAction, filterWebsocketMessage("EntityChanged"))
  private async handleEntityChangedAction(action: WebSocketMessageAction<EntityChanged>) {
    const { applications, loaded } = this.state;
    if (!applications || !loaded) {
      return;
    }

    switch (action.data.type) {
      case "ApplicationPortable":
        await dispatch(new RefreshGridAction([action.data.id]));
        if (action.data.id)
          await dispatch(new RefreshClientModelAction(action.data.id));
        break;
    }
  }

  @handle(TaskFinishedAction)
  private async handleTaskFinishedAction(action: TaskFinishedAction) {
    if (action.taskRequest.Status == TaskRequestStatusTypeCode.Error) {
      return;
    }

    const { applications, loaded } = this.state;
    if (!applications || !loaded) {
      return;
    }

    switch (action.taskRequest.Type) {
      case "CollaborationAssignment":
      case "DirectPhaseEvaluationAssignment":
      case "DeleteApplications":
      case "PatchApplicationValues":
      case "Reindex": // Triggered when tags are updated (no ids).
      case "ReindexApplications": // no ids
      case "RunAssignmentRules":
      case "DecisionLetter":
      case "PutPhase":
        const ids: string[] = this.taskService.getApplicationIds(action.taskRequest);
        //  add 1 second delay to handle the potential Elastic latency
        await wait(1000);
        await dispatch(new RefreshGridAction(ids, true));
        break;
    }
  }

  private async refreshQueryIdData() {
    const { view, refreshingAggs, queryIds } = this.state;
    if (!queryIds.aggs && !queryIds.grid && !queryIds.map && !queryIds.mapApplicants) {
      return;
    }

    try {
      if (refreshingAggs) {
        await this.refreshAggregations();
      } else if (queryIds.aggs || queryIds.grid) {
        await this.loadStoreData();
      }

      // map view may not be shown but data is requested.
      if (view !== "map" && (queryIds.map || queryIds.mapApplicants)) {
        await this.loadMapData();
      }
    } finally {
      this.scheduleRefreshAsyncId();
    }
  }

  private _timeoutId = null;
  private scheduleRefreshAsyncId(): void {
    clearTimeout(this._timeoutId);

    const { aggs, grid, map, mapApplicants } = this.state.queryIds;
    if (!aggs && !grid && !map && !mapApplicants) {
      return;
    }

    this._timeoutId = setTimeout(async () => await this.refreshQueryIdData(), 1000);
  }

  private async cancelRefreshAsyncId() {
    clearTimeout(this._timeoutId);

    const { aggs, grid, map, mapApplicants } = this.state.queryIds;
    for (const asyncQueryId of [aggs, grid, map, mapApplicants].filter(id => !!id)) {
      await this.applicationRepo.cancelSearch(asyncQueryId);
    }
  }

  private filterRequiresReindexing(filter: GroupFilter): boolean {
    if (!filter || !this.ffElectiveIndexing) {
      return false;
    }

    const dataDictionaryStore = ContainerInstance.get(DataDictionaryStore);
    const terms = filter.toFilterTerms({ fields: dataDictionaryStore.state.fields });
    return terms.some(t => t.requiresIndexing);
  }

  private mapRequiresReindexing(mapAddressField: string): boolean {
    if (!mapAddressField || !this.ffElectiveIndexing) {
      return false;
    }

    const dataDictionaryStore = ContainerInstance.get(DataDictionaryStore);
    const field =  dataDictionaryStore.state.fields
      ?.find(f => (f.SearchPath === mapAddressField || f.Path === mapAddressField)
        && f.DataSource === DataDictionaryFieldDataSource.SystemFieldType
        && f.DataType == SystemFieldType.Geo);

    if (!field) {
      return false;
    }

    return field.IndexStatus !== DataDictionaryIndexStatus.Indexed;
  }

  private get ffElectiveIndexing(): boolean {
    return this.ffs.isFeatureFlagEnabled("ElectiveIndexing");
  }
}
