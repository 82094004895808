import { inject } from "fw";
import { createFrom } from "fw-model";

import { DecisionSettings } from "models/decision-settings";

import { ATS } from "./ats";

@inject
export class DecisionSettingsRepository {
  constructor(private s: ATS) {}

  public async put(settings: DecisionSettings) {
    const res = await this.s.put<DecisionSettings>(
      `decision-settings`,
      settings
    );

    return createFrom(DecisionSettings, res.body);
  }
}
