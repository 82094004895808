import { prop } from "fw";

interface ObjectWithAvatar {
  MetaData: {
    ProfileColor: string;
    AvatarEphemeralUrl: string;
  };
}

export class Avatar {
  @prop(null) name!: string;
  @prop(null) object!: ObjectWithAvatar;
  @prop(null) overrideImageUrl!: string; // to be used while editing an object and you upload it

  get initials() {
    if (this.name == null) return "";

    const whitespaceCollapsed = this.name.replace(/[^\w]/g, " ").replace(/\s+/g, " ").trim();
    if (whitespaceCollapsed.indexOf(" ") > 0) {
      const split = whitespaceCollapsed.split(" ");
      return (split[0][0] + split[1][0]).toUpperCase();
    } else {
      return whitespaceCollapsed.substring(0, 2).toUpperCase();
    }
  }

  get imageUrl() {
    if (this.overrideImageUrl != null) return this.overrideImageUrl;

    if (this.object == null || this.object.MetaData == null) return null;
    return this.object.MetaData.AvatarEphemeralUrl;
  }

  get backgroundColor() {
    if (this.object == null || this.object.MetaData == null) return "#767676";
    return this.object.MetaData.ProfileColor || "#767676";
  }
}
