import { LocalStorageCache } from "caching";
import { AppOrganizationPortalContext } from "models/app-organization-portal-context";

export function checkImageExists(
  url: string,
  callback: (exists: boolean) => void
): void {
  const img = new Image();
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = url;
}

export function defineDomainLogoUrl(
  portalContext: AppOrganizationPortalContext,
  localStorageCache: LocalStorageCache
) {
  let domainLogoUrl = null;

  // we will only support domain based logos when the organization is unknown
  if (portalContext?.OrganizationId !== null) {
    domainLogoUrl = "";
    return domainLogoUrl;
  }

  let baseUrl = "";
  let domain = "";

  if (portalContext === null) {
    baseUrl = localStorageCache.get("custom-domain-content-base-url");
    domain = localStorageCache.get("domain");
  } else {
    baseUrl = portalContext.CustomDomainContentBaseUrl;
    domain = portalContext.OrganizationPortal.Domain;
  }

  if (!baseUrl || !domain) return;
  baseUrl = baseUrl.endsWith("/") ? baseUrl : `${baseUrl}/`;
  if (!baseUrl.includes("https://")) baseUrl = `https://${baseUrl}`;

  const urlPath = `${baseUrl}${domain}/ats.login.logo`;
  const svgUrl = `${urlPath}.svg`;
  const pngUrl = `${urlPath}.png`;

  this.checkImageExists(pngUrl, (exists) => {
    if (exists) {
      domainLogoUrl = pngUrl;
    } else {
      this.checkImageExists(svgUrl, (exists) => {
        domainLogoUrl = exists ? svgUrl : "";
      });
    }
  });

  return domainLogoUrl;
}
