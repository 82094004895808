import { fromClass, fromClassArray } from "fw-model";

export class TeamMember {
  UserId: string;
}

export enum TeamType {
  Evaluation = 0,
  Collaboration = 1
}

export class TeamMetaData {
  OrganizationId: string;
  SeasonId: string;
  TeamType: TeamType;

  DateCreatedUtc: string;
  DateUpdatedUtc: string;

  AvatarKey: string;
  AvatarEphemeralUrl: string = null;
  ProfileColor: string;
}

export class Team {
  Id: string;
  Name: string = "";
  Category: string = "";
  WorkspaceIds: string[] = [];
  AvatarFileId: string = null;

  @fromClass MetaData: TeamMetaData;
  @fromClassArray(TeamMember) Members: TeamMember[];
  @fromClassArray(TeamMember) Managers: TeamMember[];
}
