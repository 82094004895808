import { fromClass } from "fw-model";

export enum FileContextType {
  Applicant = "Applicant",
  Organization = "Organization",
  Reference = "Reference",
  Application = "Application",
  TemplateImage = "TemplateImage",
  CalendarEvent = "CalendarEvent",
  Email = "Email",
}

export enum PreviewStatus {
  None = 0,
  Ready = 1,
  Sent = 2,
  Complete = 3,
  Error = 4,
}

export enum ProcessStatusTypeCode {
  None = 0,
  Pending = 1,
  Processed = 2,
  Error = 3,
  Rejected = 4,
}

export class MediaVariant {
  Key: string;
  Height: number;
  Width: number;
  Duration: number;
  EphemeralUrl: string;
  FileSize: number;
}

export class FileMetaData {
  OrganizationId: string;

  Status: ProcessStatusTypeCode;
  SecondaryProcessingStatus: ProcessStatusTypeCode;

  @fromClass Original: MediaVariant;
  @fromClass DisplayVariant: MediaVariant;
  @fromClass ThumbVariant: MediaVariant;
  EphemeralDownloadUrl: string;

  UrlExpirationUTC: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  RoleId: string;
}

export class File {
  Id: string;
  MediaType: MediaTypeCode;

  ContentType: string;
  ContextType: string;
  ContextId: string;
  Source: string;

  ClientFileName: string;
  FileName: string;

  @fromClass MetaData: FileMetaData;
}

export enum MediaTypeCode {
  Image = 0,
  Video = 1,
  Audio = 2,
  Document = 3,
  Other = 4,
  Model = 5,
  Office = 6,
}

export class FilePostResult {
  FileId: string;
  Filename: string;
  Url: string;
  PutUrl: string;
  MediaType: MediaTypeCode;
}

export class FileCopyResult {
  FileId: string;
  Filename: string;
  GetUrl: string;
  MediaType: MediaTypeCode;
}

export interface IFileGetOptions {
  multiple?: boolean;
  allowedMediaTypes?: MediaTypeCode[];
  allowedExtensions?: string[];
  maxFileSize?: number;
}

export interface IFileGetProps {
  contextType: string;
  contextId: string;
  options: IFileGetOptions;
}
