import { inject } from "fw";

import { PopoverController } from "service/popover";

export type OptionChoice<T> = { text: string; value: T; };

export type CategoryOptionChoice<T> = {
  category: string;
  options: { text: string; value: T; }[];
};

@inject
export class OptionChooserWithCategories {
  private options: CategoryOptionChoice<any>[] = [];

  constructor(private controller: PopoverController<OptionChoice<any>>) {}

  activate(options) {
    this.options = options || [];
  }

  selectOption(o) {
    this.controller.ok(o);
  }
}
