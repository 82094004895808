import { FormForType, formFor } from "fw-model";

import { Function } from "models/function";

export type FunctionFormType = FormForType<Function>;

export class UpdateFunctionAction {
  constructor(public form: FunctionFormType) {}
}

export class AddFunctionAction {
  constructor(public form: FunctionFormType) {}
}

export const functionFormCreator = formFor(Function, s => {
  s.requiredField(f => f.Name, "Name");
  s.field(f => f.Contents, "Contents");
  s.field(f => f.IncludeFunctionIds, "Include Functions");
  s.field(f => f.IsGlobal, "Is Global");
});
