import { inject, prop } from "fw";

import { FeatureFlagService } from "service/feature-flag";

@inject
export class FeatureFlag {
  @prop(null) flag!: string;
  @prop("span") rootType!: string;

  constructor(private featureFlagService: FeatureFlagService) {}

  get isFeatureFlagEnabled() {
    return this.featureFlagService.isFeatureFlagEnabled(this.flag);
  }
}
