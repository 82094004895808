import { formFor, FormForType, Validators } from "fw-model";
import { IdentityProvider, SAML2Settings, OAuthSettings, IdentityProviderAuthType } from "models/identity-provider";
import { EncryptedValuePair } from 'models/file-provider';
import { noNewLines } from './validators';

const { required, wrap, isUrl } = Validators;


export class AddIdentityProviderAction {
  public addedId: string;

  constructor(public form: IdentityProviderForm, public authType: IdentityProviderAuthType) {}
}

export class UpdateIdentityProviderAction {
  constructor(public form: IdentityProviderForm) {}
}

export type IdentityProviderForm = FormForType<IdentityProvider>;
export type Saml2SettingsForm = FormForType<SAML2Settings>;
export type OAuthSettingsForm = FormForType<OAuthSettings>;

export const saml2SettingsFormCreator = formFor(SAML2Settings, s => {

  s.field(f => f.SignAuthnRequest, "Sign Authentication Request");
  s.field(f => f.ForceAuthn, "Force Authentication");

  s.requiredField(f => f.UniqueIdAttribute, "Unique ID Attribute *");
  s.field(f => f.EmailAttribute, "Email Attribute");
  s.requiredField(f => f.GivenNameAttribute, "Given Name Attribute *");
  s.requiredField(f => f.SurnameAttribute, "Surname Attribute *");

  s.field(f => f.IsFederation, "Is Federation");
  s.requiredField(f => f.MetadataUrl, "Metadata URL *");
});

export const encryptedValuePairFormCreator = formFor(EncryptedValuePair, s => {
  s.field(f => f.PlainTextValue, "Value", wrap(noNewLines));
});

export const oauthSettingsFormCreator = formFor(OAuthSettings, s => {
  s.requiredField(f => f.ClientId, "Client ID *");
  s.form(f => f.ClientSecret, "Client Secret", encryptedValuePairFormCreator);
  s.field(f => f.Scope, "Scope");
  s.requiredField(f => f.AuthorizationUrl, "Authorization URL (https) *", wrap(isUrl({ allowedProtocols: ["https"], requireProtocol: true, allowPath: true })));
  s.requiredField(f => f.TokenUrl, "Token URL (https) *", wrap(isUrl({ allowedProtocols: ["https"], requireProtocol: true, allowPath: true })));

  s.requiredField(f => f.TokenTransformers, "Token Transformers *");

  s.requiredField(f => f.UniqueIdAttribute, "Unique ID Attribute *");
  s.field(f => f.EmailAttribute, "Email Attribute");
  s.requiredField(f => f.GivenNameAttribute, "Given Name Attribute *");
  s.requiredField(f => f.SurnameAttribute, "Surname Attribute *");
});

export const identityProviderFormCreator = formFor(IdentityProvider, s => {
  s.field(i => i.EntityId, "Entity ID");
  s.requiredField(i => i.Name, "Name");

  s.form(i => i.SAML2Settings, "SAML 2 Settings", saml2SettingsFormCreator);
  s.form(i => i.OAuthSettings, "OAuth Settings", oauthSettingsFormCreator);
});
