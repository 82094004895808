import { inject } from "fw";
import { createFrom } from "fw-model";

import {
  userFilter,
  teamFilter,
  putInGroup,
  phaseFilter
} from "helpers/filter-creators";
import {
  DataClickEvent,
  isReportChartDefinition,
  ReportChartType,
  ChartDataSource,
  WidgetDataChartData,
} from "shared/report-runtime";
import { ChartDataNormalized } from "slideroom-charts";

import { GroupFilter, RawFilter, createFilter, Filter } from "models/filter-setup";
import {
  ApplicationPropertyFilter,
  FormFieldFilter,
  TagFilter,
  PhaseFilter,
  EvaluatedByFilter,
  PhaseCalculatedFieldFilter,
  getOp,
  ProgramStageFilter,
  DecisionFilter,
  ProgramFilter,
  SubmissionStatusFilter,
  PaymentStatusFilter,
} from "models/application-filters";
import {
  ContactsTagsFilter,
  ContactsNameFilter,
  ContactsStringFilter,
  ContactsEmailFilter,
  ContactsKeyValueFilter,
  ContactsAddressFilter,
  ContactsOperatorFilter,
  ContactsOptionFilter,
  ContactsLinkFilter,
  ContactsMultiselectFilter,
  ContactsRelationshipFilter,
  ContactsSlideroomAdmissionsApplicationsFilter,
  ContactsSlideroomApplicationsFilter,
  ContactsTestScoreFilter,
  ContactsPostalCodeFilter,
  ContactsCountryFilter
} from "models/contacts-filters";
import { type ICustomFieldDefinition, CustomFieldType } from "models/contact-organization";
import { EvaluationPhasesStore } from "state/evaluation-phases";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";

import { FeatureFlagService } from "./feature-flag";
import { PathInspector, PathResult } from "./path-inspector";
import { ProgramStore } from "state/program";
import { DecisionSettingsStore } from "state/decision-settings";
import { SubmissionFields } from "views/components/application-filters/submission-status-filter";
import { PaymentFields } from "views/components/application-filters/payment-status-filter";
import { TimingFilterType } from "models/filter-timing";

@inject
export class ChartDataClickService {
  constructor(
    private pathInspector: PathInspector,
    private evalPhaseStore: EvaluationPhasesStore,
    private currentContactsOrgStore: CurrentContactOrganizationStore,    
    private decisionStore: DecisionSettingsStore,
    private programStore: ProgramStore,
    private ffs: FeatureFlagService
  ) { }

  process(event: DataClickEvent): GroupFilter {
    const mainFilter = this.getFilterFromEvent(event);
    if (mainFilter == null) {
      return null;
    }

    const { ChartFilter, ClientData } = event.WidgetData.Definition;
    try {
      const parsed = JSON.parse(ClientData);

      if (ChartFilter != null && ChartFilter.trim().length > 0 && parsed.filterContainer && parsed.filterContainer.filters && parsed.filterContainer.filters.length > 0) {
        const gf = createFrom(GroupFilter, parsed.filterContainer);
        const gf2 = putInGroup(gf);
        mainFilter.filters.push(gf2.filters[0]);
      }
    } catch (err) { }

    return mainFilter;
  }

  private getFilterFromEvent(event: DataClickEvent): GroupFilter {
    switch (event.WidgetData.Definition.DataSource) {
      case ChartDataSource.Admissions:
        return this.getATSFilterFromEvent(event);
      case ChartDataSource.Contacts:
        return this.getContactsFilterFromEvent(event);
      case ChartDataSource.Tasks:
      default:
        return null;
    }
  }


  private getContactsFilterFromEvent(event: DataClickEvent): GroupFilter {
    if (!isReportChartDefinition(event.WidgetData.Definition)) {
      return null;
    }

    const {
      ContactType,
      PrimaryIndependentVariable,
      SecondaryIndependentVariable
    } = event.WidgetData.Definition;
    const primaryField = this.resolveContactField(ContactType, PrimaryIndependentVariable.Path);
    const groupFilter = new GroupFilter();

    groupFilter.filters.push(
      ...this.buildFilterFromContactField(primaryField, event.Data, event.WidgetData.Data)
    );

    if (
      event.Data.data != null &&
      event.Data.data.length == 1 &&
      SecondaryIndependentVariable != null
    ) {
      const secondaryField = this.resolveContactField(ContactType, SecondaryIndependentVariable.Path);
      groupFilter.filters.push(
        ...this.buildFilterFromContactField(secondaryField, event.Data.data[0], event.WidgetData.Data)
      );
    }

    return putInGroup(groupFilter);
  }

  private resolveContactField(contactType: string, searchField: string): ICustomFieldDefinition {
    const organization = this.currentContactsOrgStore.state.organization;
    if (searchField === "engagement_score") {
      return <ICustomFieldDefinition>{
        contact_type: contactType,
        is_system_field: true,
        name: "engagement_score",
        display_name: "Engagement Score",
        search_field: "score",
        type: CustomFieldType.number
      };
    }

    const contactTypeFields = organization.fields.filter(f => f.contact_type === contactType);

    let field = contactTypeFields.find(f => f.search_field === searchField);
    if (field) {
      return field;
    }

    // NOTE: This check is here to apply the lookup logic for code that's defined in ChartContactsFieldSelector.
    return contactTypeFields.find(f => {
      if (!f.is_system_field) {
        return false;
      }

      switch (searchField) {
        case "first_name":
          return f.search_field.toLowerCase() === "firstname";
        case "last_name":
          return f.search_field.toLowerCase() === "lastname";
        case "company_name":
          return f.search_field.toLowerCase() === "companyname";
        case "tags":
          return f.search_field.toLowerCase() === "tags";
      }
    })
  }

  private buildFilterFromContactField(field: ICustomFieldDefinition, data: ChartDataNormalized, chartData: WidgetDataChartData[]) {
    const hasData = data.labelData !== "__missing__";

    switch (field.type) {
      case CustomFieldType.string:
      case CustomFieldType.largestring:
        if (field.is_system_field && (field.name === "FirstName" || field.name === "LastName" || field.name === "CompanyName")) {
          const contactType = this.currentContactsOrgStore.state.organization.contact_types.find(ct => ct.key === field.contact_type);
          return [createFilter(ContactsNameFilter, s => {
            s.first = field.name === "FirstName" && hasData ? data.labelData : null,
            s.last = field.name === "LastName" && hasData ? data.labelData : null,
            s.name = field.name === "CompanyName" && hasData ? data.labelData : null,
            s.useFullName = contactType ? contactType.use_full_name : false
          })
          ];
        }

        return [createFilter(ContactsStringFilter, s => {
          s.field = field,
          s.value = data.labelData
        })];
      case CustomFieldType.email:
        // TODO: Figure out how to handle blank (no data)
        return [createFilter(ContactsEmailFilter, s => {
          s.email = data.labelData
        })
        ];
      case CustomFieldType.phone:
      case CustomFieldType.social:
        return [createFilter(ContactsKeyValueFilter, s => {
          s.field = field,
          s.value = data.labelData
        })
        ];
      case CustomFieldType.address:
        return [createFilter(ContactsAddressFilter, s => {
          s.field = field,
          s.city = null,
          s.state = null,
          s.country = null
        })
        ];
      case CustomFieldType.date:
      case CustomFieldType.number:
        const buildFilter = (op: string, value: string) => {
          return createFilter(
            ContactsOperatorFilter,
            s => {
              s.field = field;
              s.operator = op;
              s.value = value;
            });
        };

        return this.makeFilterWithBounds(data, data.label, (v, op) => buildFilter(op, v));
      case CustomFieldType.dropdown:
      case CustomFieldType.funnel:                    
        return [createFilter(ContactsOptionFilter, s => {
          s.field = field,
          s.value = data.labelData,
          s.options = field.options.map(o => { return { text: o.name, value: o.name }; })
        })
        ];
      case CustomFieldType.boolean:
        const filters = [createFilter(ContactsOptionFilter, s => {
          s.field = field,
          s.value = data.label == "Yes",
          s.options = [{ text: "Yes", value: true }, { text: "No", value: false }]
        })]

        if(data.label == "Yes") {
          return filters;
        }

        filters.push(createFilter(ContactsOptionFilter, s => {
          s.field = field,
          s.value = "__missing__",
          s.options = [{ text: "Yes", value: true }, { text: "No", value: false }]
        }));

        return [createFilter(GroupFilter, s => {
          s.operation = "OR",
          s.filters = filters;
        })];
      case CustomFieldType.link:
        return [createFilter(ContactsLinkFilter, s => {
          s.field = field,
          s.name = "",
          s.url = data.labelData
        })
        ];
      case CustomFieldType.multiselect:
        return [createFilter(ContactsMultiselectFilter, s => {
          s.field = field,
          s.mode = hasData ? "any" : "none",
          s.values = this.resolveValues(hasData, field, data),
          s.options = field.options.map(o => o.name)
        })
        ];
      case CustomFieldType.tags: // TODO: figure out if there is a better way to pass in a missing filter
        return [createFilter(ContactsTagsFilter, s => {
          s.field = field,
          s.mode = hasData ? "any" : "none",
          s.values = this.resolveValues(hasData, field, data)
        })
        ];
      case CustomFieldType.relationship:
        return [createFilter(ContactsRelationshipFilter, s => {
          s.field = field,
          s.mode = "",
          s.values = []
        })
        ];
      case CustomFieldType.slideroomadmissionsapplications:
        return [createFilter(ContactsSlideroomAdmissionsApplicationsFilter, s => {
          s.field = field,
          s.program = "",
          s.phase = "",
          s.hasSubmitted = false
        })
        ];
      case CustomFieldType.slideroomapplications:
        return [createFilter(ContactsSlideroomApplicationsFilter, s => {
          s.field = field,
          s.program = "",
          s.status = "",
          s.hasSubmitted = false
        })
        ];
      case CustomFieldType.testscore:
        return [createFilter(ContactsTestScoreFilter, s => {
          s.field = field,
          s.sections = [],
          s.section = "",
          s.operator = "",
          s.value = parseInt(data.labelData)
        })
        ];
      case CustomFieldType.postalcode:
        return [createFilter(ContactsPostalCodeFilter, s => {
          s.field = field,
          s.value = data.labelData
        })];
      case CustomFieldType.country:
        return [createFilter(ContactsCountryFilter, s => {
          s.field = field,
          s.value = data.labelData
        })];
      case CustomFieldType.table:
      default:
        return [];
    }
  }

  private resolveValues(hasData, field: ICustomFieldDefinition, data: ChartDataNormalized) {
    const values = [];
    if (hasData) {
      values.push(data.labelData);
    } else if (field.options) {
      values.push(...field.options.map(o => o.name));
    }
    return values;
  }

  private getATSFilterFromEvent(event: DataClickEvent): GroupFilter {
    if (isReportChartDefinition(event.WidgetData.Definition)) {
      switch (event.WidgetData.Definition.ChartType) {
        case ReportChartType.Pipeline:
          return putInGroup(phaseFilter(event.Data));

        case ReportChartType.Progress:
          const clientData = JSON.parse(
            event.WidgetData.Definition.ClientData || "{}"
          ) as {
            phase: string;
            isTeam: boolean;
          };

          let completed: boolean = null;

          switch (event.Data.type) {
            case "all":
              break;
            case "done":
              completed = true;
              break;
            case "ready":
              completed = false;
              break;
          }

          const filter = clientData.isTeam
            ? teamFilter(
              event.Data.data.id,
              completed,
              clientData.phase || "current"
            )
            : userFilter(
              event.Data.data.id,
              completed,
              clientData.phase || "current"
            );

          return putInGroup(filter);

        default:
          const groupFilter = new GroupFilter();

          const {
            PrimaryIndependentVariable,
            SecondaryIndependentVariable
          } = event.WidgetData.Definition;

          const primaryRes = this.pathInspector.inspect(
            PrimaryIndependentVariable.Path
          );

          groupFilter.filters.push(
            ...this.buildFromPathResult(primaryRes, event.Data)
          );

          if (
            event.Data.data != null &&
            event.Data.data.length == 1 &&
            SecondaryIndependentVariable != null
          ) {
            const secRes = this.pathInspector.inspect(
              SecondaryIndependentVariable.Path
            );
            groupFilter.filters.push(
              ...this.buildFromPathResult(secRes, event.Data.data[0])
            );
          }
          
          return groupFilter;
      }
    }

    switch (event.WidgetData.WidgetType) {
      case "assignment": {
        // TODO:...
        let completed: boolean = null;

        switch (event.Data.type) {
          case "all":
            break;
          case "done":
            completed = true;
            break;
          case "ready":
            completed = false;
            break;
        }

        const filter = event.Data.data.teamId
          ? teamFilter(
            event.Data.data.teamId,
            completed,
            event.Data.data.phaseKey
          )
          : userFilter(
            event.Data.data.userId,
            completed,
            event.Data.data.phaseKey
          );

        return putInGroup(filter);
      }
    }

    return null;
  }

  private buildFromPathResult(result: PathResult, data: ChartDataNormalized) {
    switch (result.kind) {
      case "application-meta": {
        const buildFilter = (field: string, op: string, value: string) => {
          return createFilter(
            RawFilter,
            s => (s.filter = `application.metaData.${field}${getOp(op)}${value}`)
          );
        };

        let field: string = null;

        switch (result.type) {
          case "date-started":
            field = "dateStartedUTC";
            break;
          case "date-submitted":
            field = "dateSubmittedUTC";
            break;
        }

        return this.makeFilterWithBounds(data, data.label, (v, op) => buildFilter(field, op, v));
      }

      case "phase-calculated": {
        const buildFilter = (op: string, value: string) => {
          return createFilter(
            PhaseCalculatedFieldFilter,
            s => {
              s.field = result.field.Key;
              s.operator = op;
              s.phaseKey = result.phase;
              s.value = value;
            });
        };

        return this.makeFilterWithBounds(data, data.label, (v, op) => buildFilter(op, v));
      }

      case "application": {
        const buildFilter = (
          op: string,
          value: string,
          shouldQuoteValue = true
        ) => {
          return createFilter(ApplicationPropertyFilter, s => {
            s.field = result.key;
            s.operator = op;
            s.shouldQuoteValue = shouldQuoteValue;
            s.value = value;
          });
        };

        return this.makeFilterWithBounds(data, data.labelData, (v, op, isB) => {
          if (!isB) {
            const newOp = data.labelData == "__missing__" ? "!~" : "=";
            return buildFilter(newOp, v);
          } else {
            return buildFilter(op, v, false);
          }
        });
      }

      case "form": {
        const buildFilter = (
          op: string,
          value: string,
          shouldQuoteValue = true
        ) => {
          return createFilter(FormFieldFilter, s => {
            s.formKey = result.formKey;
            s.field = result.key;
            s.operator = op;
            s.shouldQuoteValue = shouldQuoteValue;
            s.value = value;

            if (result.subKeys != null && result.subKeys.length == 1) {
              s.value = null;
              s.dataFields = {
                [result.subKeys[0]]: {
                  op,
                  value
                }
              };
            }
          });
        };

        return this.makeFilterWithBounds(data, data.labelData, (v, op, isB) => {
          if (!isB) {
            const newOp = data.labelData == "__missing__" ? "!~" : "=";
            return buildFilter(newOp, v);
          } else {
            return buildFilter(op, v, false);
          }
        });
      }

      case "tag":
        return [
          createFilter(TagFilter, s => {
            s.mode = "any";
            s.tags = [data.label];
          })
        ];

      case "phase":
        const phase = this.evalPhaseStore.state.phases.find(
          f => f.Name == data.label
        );

        return [
          createFilter(PhaseFilter, s => {
            s.phaseId = phase ? phase.Id : null;
          })
        ];

      case "program":
        switch (result.key) {
          case "name": {
            const program = this.programStore.state.programs.find(p => p.Name === data.label);
            return [
              createFilter(ProgramFilter, s => {
                s.programId = program?.Id ?? null;
              })
            ]
          }
          case "publicName": {
            const program = this.programStore.state.programs.find(p => p.PublicName === data.label);
            return [
              createFilter(RawFilter, s => {
                s.filter = `program.publicName=${program ? program.PublicName : null}`;
              })
            ]
          }
        }


      case "stage":
        return [
          createFilter(ProgramStageFilter, s => {
            s.stage = data.label
          })
        ]; 
        
      case "decision":
        const decision = this.decisionStore.state.decisionSettings.Codes.find(
          f => f.Code == data.label
        );

        return [
          createFilter(DecisionFilter, s => {
            s.decisionId = decision?.Id
          })
        ]; 

      case "user-assignment":
        const uf = userFilter(data.labelData, null, result.phase);
        return uf.filters;

      case "user-evaluated":
        return [
          createFilter(EvaluatedByFilter, s => {
            s.phaseKey = result.phase;
            s.userId = data.labelData;
          })
        ];

      case "team-assignment":
        const tf = teamFilter(data.labelData, null, result.phase);
        return tf.filters;

      case "step-group": {
        switch (result.field) {
          case SubmissionFields.IsSubmitted:
            return [createFilter(SubmissionStatusFilter, s => {
              s.timing = null;
              s.field = result.field;
              s.stepGroupKey = result.key;
              s.value = Boolean(data.labelData);
            })];
          case SubmissionFields.DateSubmitted:
            return [createFilter(SubmissionStatusFilter, s => {
              s.timing = TimingFilterType.DateRange
              s.startDate = data.label;
              s.endDate = data.label;
              s.field = result.field;
              s.stepGroupKey = result.key;
            })];
          case PaymentFields.FeeWaived:
            return [createFilter(RawFilter, s => s.filter = `${result.key}.${result.field}:${data.labelData}`)]
          case PaymentFields.WaivedBy:
            if (data.label === "No Data") {
              return [createFilter(RawFilter, s => s.filter = `_missing_:${result.key}.${result.field}`)]
            }
            
            return [createFilter(PaymentStatusFilter, s => {
              s.field = result.field;
              s.timing = null;
              s.stepGroupKey = result.key;
              s.value = data.labelData;
            })];
          case PaymentFields.AmountPaid:
          case PaymentFields.PaymentStatus:
            if (data.label === "No Data") {
              return [createFilter(RawFilter, s => s.filter = `_missing_:${result.key}.${result.field}`)]
            }
            return [createFilter(PaymentStatusFilter, s => {
              s.field = result.field;
              s.timing = null;
              s.stepGroupKey = result.key;
              s.value = Number(data.labelData);
            })];
          
          case PaymentFields.WaivedOn:
          case PaymentFields.PaymentDate:
            return this.makeFilterWithBounds(data, data.label, (v, op) => createFilter(
              PaymentStatusFilter,
              s => {
                s.timing = TimingFilterType.DateRange
                s.startDate = data.label;
                s.endDate = data.label;
                s.field = result.field;
                s.stepGroupKey = result.key;
              }));
        }
      }

      default:
        return [];
    }
  }

  private makeFilterWithBounds(data: ChartDataNormalized, value: any, fb: (value: any, op: "=" | ">=" | "<", isBounds: boolean) => Filter) {
    if (data.bounds == null) {
      return [fb(value, "=", false)];
    }

    const filters: Filter[] = [fb(data.bounds.min, ">=", true)];

    if (data.bounds.max) {
      filters.push(fb(data.bounds.max, "<", true));
    }

    return filters;
  }
}
