import { inject } from "fw";
import { Store, handle } from "fw-state";
import createActivityDetector from "activity-detector";

export type ActivityState = "idle" | "active";

export type ActivityDetectorInstance = {
  on: (eventName: ActivityState, listener: () => void) => void;
  stop: () => void;
  init: (firstState: ActivityState) => {};
};

type CurrentActivityStoreShape = {
  isIdle: boolean;
  showActivityAlert: boolean;
  activityTracker: ActivityDetectorInstance;
};

const CROSS_TAB_ACTIVITY_KEY = "ats-tab-active";

enum ActivityType {
  Idle = "idle",
  Active = "active",
}

export class InitActivityTrackerAction {
  constructor(
    public duration: number // in milliseconds
  ) {}
}

export class ToggleActivityAlert {
  constructor(
    public showActivityAlert: boolean
  ) {}
}

export class StopActivityTrackerAction {}

@inject
export class ActivityStore extends Store<CurrentActivityStoreShape> {
  constructor() {
    super();
  }

  defaultState() {
    return {
      isIdle: false,
      showActivityAlert: false,
      activityTracker: null
    };
  }

  @handle(InitActivityTrackerAction)
  private handleInitActivityTracker({ duration }: InitActivityTrackerAction) {
    if (this.state.activityTracker) this.state.activityTracker.stop();

    window.addEventListener("storage", this.multipleTabActivityHandler);

    const activityTracker =  createActivityDetector({
      timeToIdle: duration, // seconds of inactivity to consider the user idle
      autoInit: false, // must call .init() when autoInit is false
      inactivityEvents: [] // inactivityEvents can set idle flag immediately, bypassing timeToIdle
    });

    // timeToIdle expired without activity events on the window object
    activityTracker.on(ActivityType.Idle, () => {
      localStorage.setItem(CROSS_TAB_ACTIVITY_KEY, ActivityType.Idle);
      this.setState(() => ({
        ...this.state,
        isIdle: true
      }))
    });

    // window 'click', 'mousemove', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'focus'
    activityTracker.on(ActivityType.Active, () => {
      localStorage.setItem(CROSS_TAB_ACTIVITY_KEY, ActivityType.Active);
      this.setState(() => ({
        ...this.state,
        isIdle: false
      }))
    });

    activityTracker.init();

    this.setState(() => ({
      ...this.state,
      activityTracker
    }));
  }

  @handle(ToggleActivityAlert)
  private toggleIdleStatus({ showActivityAlert }: ToggleActivityAlert) {
    this.setState(() => ({
      ...this.state,
      showActivityAlert
    }))
  }

  @handle(StopActivityTrackerAction)
  private handleStopActivityTracker(){
    if (this.state.activityTracker) this.state.activityTracker.stop();

    localStorage.removeItem(CROSS_TAB_ACTIVITY_KEY);
    window.removeEventListener("storage", this.multipleTabActivityHandler);

    this.setState(() => ({
      ...this.defaultState()
    }));
  }

  private multipleTabActivityHandler = (event: StorageEvent) => {
    this.handleMultipleTabActivity(event)    
  };
  
  private handleMultipleTabActivity(event: StorageEvent){
    if (event.key !== CROSS_TAB_ACTIVITY_KEY) return;

    if (event.newValue === ActivityType.Active && this.state.isIdle === true) {
      this.setState(() => ({
        ...this.state,
        isIdle: false
      }));
    }

    if (event.newValue === ActivityType.Idle) {
      this.setState(() => ({
        ...this.state,
        isIdle: true
      }));
    }

    setTimeout(() => localStorage.removeItem(CROSS_TAB_ACTIVITY_KEY),  100);
  }
}
