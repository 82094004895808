import { dispatch } from "fw-state";

import { EnsureTaskRequestAction } from "state/actions";
import { NetworkResponseMiddleware, ResponseContext } from "fw";

export const TASK_HEADER_NAME = "x-ats-task-request";

export const parseHeader = (header: string) => {
  let taskRequestId = header;
  let friendlyName: string = null;

  const idx = taskRequestId.indexOf(",");
  if (idx >= 0) {
    friendlyName = taskRequestId.slice(idx + 1).trim();
    taskRequestId = taskRequestId.slice(0, idx);
  }

  return {
    taskRequestId,
    friendlyName,
  };
};

export class TaskRequestMiddleware implements NetworkResponseMiddleware {
  onResponse(context: ResponseContext) {
    if (context.headers[TASK_HEADER_NAME] == null) {
      return;
    }

    const data = parseHeader(context.headers[TASK_HEADER_NAME]);
    dispatch(new EnsureTaskRequestAction([data.taskRequestId], data.friendlyName));
  }
}
