export class StringService {
  public pluckInitials(...words: string[]): string {
    return words.map((w) => w?.charAt(0).toUpperCase()).join('');
  }

  public uppercaseFirstLetter(s: string): string {
    return `${s?.charAt(0).toUpperCase()}${s?.slice(1)}`;
  }

  public getPastTense(str: string): string {
    return str.slice(-1).toLowerCase() === 'e' ? `${str}d` : `${str}ed`;
  }

  public stripNonnumericCharacters(chars: string): string {
    if (chars) {
      return chars.replace(/\D/g, '');
    }
  }

  public generateSimpleHash(str: string): string {
    let hash = 0;
    for (const i of str.split("").keys()) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash &= hash;
    }
    return (hash >>> 0).toString(36);
  }
}
