import { inject, prop, ComponentEventBus  } from "@derekpitt/fw"

import { ChartingService, ChartData, horizonalChart } from "slideroom-charts";
import { fillColorPalette } from "../../util";

@inject
export class BarChart {
  @prop(null) data!: ChartData[];
  @prop(false) interactive!: boolean;
  @prop("Bar chart") svgAlt: string;
  @prop(null) dataSourceLabel: string;
  @prop(3) columnSpan: number;
  @prop(false) hideCount: boolean;

  private svgElement: SVGElement = null;

  constructor(private charting: ChartingService, private ceb: ComponentEventBus) { }

  attached() {
    this.charting.setColors(fillColorPalette);
    this.dataChanged();
  }

  dataChanged() {
    this.charting.drawChart(horizonalChart, this.svgElement, this.data, this.interactive, (data) => {
        if (!this.interactive) return;
        this.ceb.dispatch("data-click", data);
      }, {
      totalLabel: this.dataSourceLabel,
      columnSpan: this.columnSpan,
      hideCount: this.hideCount,
      description: this.svgAlt
    });
  }
}
