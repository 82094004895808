
export enum TranslationTableDataType {
  Number = 0,
  Boolean = 1,
  Date = 2,
  Text = 3
}

export enum ScoringOperations {
  equals,
  plus,
  minus,
  multiply,
  divide,
  fn
}

export enum TranslationTableOutputDataType {
  Number = 0,
  Boolean = 1,
  Date = 2,
  Text = 3
}

export type FieldDataType = { text: string; value: number };

export class TranslationTableRowInput {
  public StringValue: string;
}

export class TranslationTableRow {
  public Input: TranslationTableRowInput;
  public IsRegularExpression?: boolean;
  public Output: string;
  public ScoringOperation?: ScoringOperations;

  constructor(init?: Partial<TranslationTableRow>) {
    Object.assign(this, init);
  }
}

export class TranslationTable {
  public Id: string;
  public Category: string;
  public Name: string;
  public InputType: TranslationTableDataType;
  public OutputType: TranslationTableOutputDataType;
  public DefaultOutput: string;
  public Rows: TranslationTableRow[];
}

export class TranslationTableCategory {
  Category: string;
  TableCount: number;
}
