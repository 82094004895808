import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { IdentityProvider, IdentityProviderAuthType } from "models/identity-provider";

@inject
export class IdentityProviderRepository {
  constructor(private s: ATS) { }

  public async list() {
    const res = await this.s.get<any[]>("identity-provider");
    return createFromArray(IdentityProvider, res.body);
  }

  public async post(provider: IdentityProvider, authType: IdentityProviderAuthType) {
    const res = await this.s.post("identity-provider", provider, { authType });
    return createFrom(IdentityProvider, res.body);
  }

  public async put(provider: IdentityProvider) {
    const res = await this.s.put(`identity-provider/${provider.Id}`, provider);
    return createFrom(IdentityProvider, res.body);
  }

  public async del(ids: string[]) {
    await this.s.delete(`identity-provider/${ids.join(",")}`);
  }
}
