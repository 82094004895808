export class MarketingOutreachArchive {
  Id: number = null;
  OutreachId: number = null;
  OutreachSentId: number = null;
  AtsRecordType: 'applications' | 'contacts' | 'users' = null;
  AtsRecordId: string = null;
  Test: number = 0; // Email, Text Message, Voice Message
  PhoneNumber: number = null; // Text Message, Voice Message
  Status: '' | 'success' | 'no answer' = null; // Voice Message
  ArchiveId: string = null; // Email
  EmailAddress: string = null; // Email
  Open: number = 0; // Email
  ProcessedAt: Date = null; // Print Piece
  MailedAt: Date = null; // Print Piece
  Deleted: number = 0; // Print Piece
}
