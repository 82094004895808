import { prop, inject } from "fw";
import { ContactsKeyValueFilter as ContactsKeyValueFilterModel } from "models/contacts-filters";

@inject
export class ContactsKeyValueFilter {
  @prop(null) filter!: ContactsKeyValueFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  public get valueText() {
    const value = this.filter.value === null || this.filter.value === "__missing__" 
      ? "is empty" 
      : `= "${this.filter.value}"`;
    return value;
  }
}
