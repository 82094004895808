import { inject, prop } from "fw";
import { FeatureFlagService } from "service/feature-flag";
import { getContrastingColor } from "helpers/accessibility";
import { Contact } from "models/contact";

@inject
export class ContactAvatar {
  @prop(null) contact!: Contact;

  constructor(
    private ffs: FeatureFlagService,
  ) { }

  public get style() {
    return {
      background: this.contact?.profile_image_url
        ? `url(${this.contact?.profile_image_url}) #ccc no-repeat center /cover`
        : this.backgroundColor,
      color: this.initialsColor,
    };
  }

  public get backgroundColor() {
    return this.contact?.profile_color ?? "#8E9AAB";
  }

  public get initialsColor() {
    return this.contact?.profile_image_url
      ? "inherit"
      : getContrastingColor(this.backgroundColor);
  }

  public get ffApplicantPhotosSignals(): boolean {
    return this.ffs.isFeatureFlagEnabled("ApplicantPhotosSignals");
  }
}
