import {
  CreateApplicantPortalAction,
  UpdateApplicantPortalAction,
} from "forms/applicant-portal";
import {
  CreateCollaborationPortalAction,
  DeleteCollaborationPortalsAction,
  UpdateCollaborationPortalAction,
} from "forms/collaboration-portal";
import {
  CreateInquiryPortalAction,
  DeleteInquiryPortalAction,
  UpdateInquiryPortalAction,
} from "forms/inquiry-portal";
import {
  CreateReferencePortalAction,
  UpdateReferencePortalAction,
} from "forms/reference-portal";
import {
  CreateStudentPortalAction,
  UpdateStudentPortalAction,
} from "forms/student-portal";
import {
  CreateCustomPortalAction,
  UpdateCustomPortalAction,
} from "forms/custom-portal";
import {
  CreateReviewPortalAction,
  UpdateReviewPortalAction,
} from "forms/review-portal";
import { inject } from "fw";
import { handle, Store } from "fw-state";
import { once } from "helpers/once";
import { difference } from "lodash-es";
import { ApplicantPortal } from "models/applicant-portal";
import { CollaborationPortal } from "models/collaboration-portal";
import { InquiryPortal } from "models/inquiry-portal";
import { ReferencePortal } from "models/reference-portal";
import { StudentPortal } from "models/student-portal";
import { CustomPortal } from "models/custom-portal";
import { ReviewPortal } from "models/review-portal";
import { ApplicantPortalRepository } from "network/applicant-portal-repository";
import { CollaborationPortalRepository } from "network/collaboration-portal-repository";
import { InquiryPortalRepository } from "network/inquiry-portal-repository";
import { ReferencePortalRepository } from "network/reference-portal-repository";
import { StudentPortalRepository } from "network/student-portal-repository";
import { ReviewPortalRepository } from "network/review-portal-repository";

import { LogoutAction, StartAction } from "./actions";
import { FormErrorHandling } from "./error-handling";
import { CustomPortalRepository } from "../network/custom-portal-repository";

interface PortalsStoreShape {
  applicantPortals: ApplicantPortal[];
  inquiryPortals: InquiryPortal[];
  referencePortals: ReferencePortal[];
  collaborationPortals: CollaborationPortal[];
  studentPortals: StudentPortal[];
  customPortals: CustomPortal[];
  reviewPortals: ReviewPortal[];
}

export class EnsurePortalsStoreAction {
  constructor() {}
}

export class EnsureReviewPortalStoreAction {
  constructor() {}
}

@inject
export class PortalsStore extends Store<PortalsStoreShape> {
  constructor(
    private inquiryPortalRepo: InquiryPortalRepository,
    private applicantPortalRepo: ApplicantPortalRepository,
    private referencePortalRepo: ReferencePortalRepository,
    private collabPortalRepo: CollaborationPortalRepository,
    private studentPortalRepo: StudentPortalRepository,
    private customPortalRepo: CustomPortalRepository,
    private reviewPortalRepo: ReviewPortalRepository
  ) {
    super();
  }

  defaultState() {
    return {
      inquiryPortals: [],
      applicantPortals: [],
      referencePortals: [],
      collaborationPortals: [],
      studentPortals: [],
      customPortals: [],
      reviewPortals: [],
    };
  }

  @handle(StartAction)
  private async handleStart(action: StartAction) {
    this.setState((state) => ({
      ...state,
      collaborationPortals: action.context.CollaborationPortals,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState((s) => this.defaultState());
  }

  @handle(EnsureReviewPortalStoreAction)
  private async handleEnsureReviewPortalStoreAction(
    action: EnsureReviewPortalStoreAction
  ) {
    await once("ensure-review-portal", async () => {
      const reviewPortals = await this.reviewPortalRepo.list();

      this.setState((state) => ({
        ...state,
        reviewPortals,
      }));
    });
  }

  @handle(EnsurePortalsStoreAction)
  private async handleEnsurePortalsStoreAction(
    action: EnsurePortalsStoreAction
  ) {
    await once("ensure-portals", async () => {
      const [
        applicantPortals,
        inquiryPortals,
        referencePortals,
        studentPortals,
        customPortals,
        reviewPortals,
      ] = await Promise.all([
        this.applicantPortalRepo.list(),
        this.inquiryPortalRepo.list(),
        this.referencePortalRepo.list(),
        this.studentPortalRepo.list(),
        this.customPortalRepo.list(),
        this.reviewPortalRepo.list(),
      ]);

      this.setState((state) => ({
        ...state,
        applicantPortals,
        inquiryPortals,
        referencePortals,
        studentPortals,
        customPortals,
        reviewPortals,
      }));
    });
  }

  @handle(CreateApplicantPortalAction, FormErrorHandling)
  private async handleCreateApplicantPortalAction(
    action: CreateApplicantPortalAction
  ) {
    action.form.validate();
    const newPortal = await this.applicantPortalRepo.post(
      action.form.updatedModel()
    );

    this.setState((state) => ({
      ...state,
      applicantPortals: [...state.applicantPortals, newPortal],
    }));

    action.newPortal = newPortal;
  }

  @handle(UpdateApplicantPortalAction, FormErrorHandling)
  private async handleUpdateApplicantPortalAction(
    action: UpdateApplicantPortalAction
  ) {
    action.form.validate();
    const updatedPortal = await this.applicantPortalRepo.put(
      action.form.updatedModel()
    );

    const oldPortal = this.state.applicantPortals.find(
      (p) => p.Id == updatedPortal.Id
    );
    if (oldPortal == null) return;

    Object.assign(oldPortal, updatedPortal);
    this.setState((s) => s);
  }

  @handle(CreateReviewPortalAction, FormErrorHandling)
  private async handleCreateReviewPortalAction(
    action: CreateReviewPortalAction
  ) {
    action.form.validate();
    const newPortal = await this.reviewPortalRepo.post(
      action.form.updatedModel()
    );

    this.setState((state) => ({
      ...state,
      reviewPortals: [...state.reviewPortals, newPortal],
    }));

    action.newPortal = newPortal;
  }

  @handle(UpdateReviewPortalAction, FormErrorHandling)
  private async handleUpdateReviewPortalAction(
    action: UpdateReviewPortalAction
  ) {
    action.form.validate();
    const updatedPortal = await this.reviewPortalRepo.put(
      action.form.updatedModel()
    );

    const oldPortal = this.state.reviewPortals.find(
      (p) => p.Id == updatedPortal.Id
    );
    if (!oldPortal) return;

    Object.assign(oldPortal, updatedPortal);
    this.setState((s) => s);
  }

  @handle(CreateCustomPortalAction, FormErrorHandling)
  private async handleCustomPortalAction(action: CreateCustomPortalAction) {
    action.form.validate();
    const newPortal = await this.customPortalRepo.post(
      action.form.updatedModel()
    );

    this.setState((state) => ({
      ...state,
      customPortals: [...state.customPortals, newPortal],
    }));

    action.newPortal = newPortal;
  }

  @handle(UpdateCustomPortalAction, FormErrorHandling)
  private async handleUpdateCustomPortalAction(
    action: UpdateCustomPortalAction
  ) {
    action.form.validate();
    const updatedPortal = await this.customPortalRepo.put(
      action.form.updatedModel()
    );

    const oldPortal = this.state.customPortals.find(
      (p) => p.Id == updatedPortal.Id
    );
    if (!oldPortal) return;

    Object.assign(oldPortal, updatedPortal);
    this.setState((s) => s);
  }

  @handle(CreateStudentPortalAction, FormErrorHandling)
  private async handleCreateStudentPortalAction(
    action: CreateStudentPortalAction
  ) {
    const newPortal = await this.studentPortalRepo.post(
      action.form.updatedModel()
    );

    this.setState((state) => ({
      ...state,
      studentPortals: [...state.studentPortals, newPortal],
    }));

    action.newPortal = newPortal;
  }

  @handle(UpdateStudentPortalAction, FormErrorHandling)
  private async handleUpdateStudentPortalAction(
    action: UpdateStudentPortalAction
  ) {
    action.form.validate();
    const updatedPortal = await this.studentPortalRepo.put(
      action.form.updatedModel()
    );

    const oldPortal = this.state.studentPortals.find(
      (p) => p.Id == updatedPortal.Id
    );
    if (oldPortal == null) return;

    Object.assign(oldPortal, updatedPortal);
    this.setState((s) => s);
  }

  @handle(CreateInquiryPortalAction, FormErrorHandling)
  private async handleCreateInquiryPortalAction(
    action: CreateInquiryPortalAction
  ) {
    action.form.validate();
    const newPortal = await this.inquiryPortalRepo.post(
      action.form.updatedModel()
    );

    this.setState((state) => ({
      ...state,
      inquiryPortals: [...state.inquiryPortals, newPortal],
    }));

    action.newPortal = newPortal;
  }

  @handle(UpdateInquiryPortalAction, FormErrorHandling)
  private async handleInquiryPortalAction(action: UpdateInquiryPortalAction) {
    action.form.validate();
    const updatedPortal = await this.inquiryPortalRepo.put(
      action.form.updatedModel()
    );

    const oldPortal = this.state.inquiryPortals.find(
      (p) => p.Id == updatedPortal.Id
    );
    if (oldPortal == null) return;

    Object.assign(oldPortal, updatedPortal);
    this.setState((s) => s);
  }

  @handle(DeleteInquiryPortalAction)
  private async handleDeleteInquiryPortalAction(
    action: DeleteInquiryPortalAction
  ) {
    await this.inquiryPortalRepo.delete(action.id);

    const deleted = this.state.inquiryPortals.find((u) => u.Id == action.id);

    this.setState((state) => ({
      ...state,
      inquiryPortals: difference(state.inquiryPortals, [deleted]),
    }));
  }

  @handle(CreateReferencePortalAction, FormErrorHandling)
  private async handleCreateReferencePortalAction(
    action: CreateReferencePortalAction
  ) {
    action.form.validate();
    const newPortal = await this.referencePortalRepo.post(
      action.form.updatedModel()
    );

    this.setState((state) => ({
      ...state,
      referencePortals: [...state.referencePortals, newPortal],
    }));

    action.newPortal = newPortal;
  }

  @handle(UpdateReferencePortalAction, FormErrorHandling)
  private async handleUpdateReferencePortalAction(
    action: UpdateReferencePortalAction
  ) {
    action.form.validate();
    const updatedPortal = await this.referencePortalRepo.put(
      action.form.updatedModel()
    );

    const oldPortal = this.state.referencePortals.find(
      (p) => p.Id == updatedPortal.Id
    );
    if (oldPortal == null) return;

    Object.assign(oldPortal, updatedPortal);
    this.setState((s) => s);
  }

  @handle(CreateCollaborationPortalAction, FormErrorHandling)
  private async handleCreateCollaborationPortalAction(
    action: CreateCollaborationPortalAction
  ) {
    action.form.validate();
    const newPortal = await this.collabPortalRepo.post(
      action.form.updatedModel()
    );

    this.setState((state) => ({
      ...state,
      collaborationPortals: [...state.collaborationPortals, newPortal],
    }));

    action.newPortal = newPortal;
  }

  @handle(UpdateCollaborationPortalAction, FormErrorHandling)
  private async handleUpdateCollaborationPortalAction(
    action: UpdateCollaborationPortalAction
  ) {
    action.form.validate();
    const updatedPortal = await this.collabPortalRepo.put(
      action.form.updatedModel()
    );

    const oldPortal = this.state.collaborationPortals.find(
      (p) => p.Id == updatedPortal.Id
    );
    if (oldPortal == null) return;

    Object.assign(oldPortal, updatedPortal);
    this.setState((s) => s);
  }

  @handle(DeleteCollaborationPortalsAction)
  private async handleDeleteCollaborationPortalsAction(
    action: DeleteCollaborationPortalsAction
  ) {
    await this.collabPortalRepo.del(action.ids);

    const deleted = this.state.collaborationPortals.filter((u) =>
      action.ids.some((id) => id == u.Id)
    );

    this.setState((state) => ({
      ...state,
      collaborationPortals: difference(state.collaborationPortals, deleted),
    }));
  }
}
