import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LogoutAction } from "./actions";

interface PathShape {
  currentPath: string;
}

export class UpdateRoutePath {
  constructor(
    private currentPath: string
  ) {}
}

@inject
export class RoutePathStore extends Store<PathShape> {
  constructor() {
    super();
  }

  defaultState() {
    return {
      currentPath: null
    };
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(UpdateRoutePath)
  private async updateRoutePath(data) {
    this.setState(state => ({
      ...state,
      currentPath: data.currentPath
    }));
  }

}
