import { DateTiming, TimingFilterType } from "./filter-timing";
import { ContactsDateFilter } from "./contacts-filters";
import { TaskDateRangeFilter } from "./task-filters";
import { FilterTermsResult } from "./filter-setup";

export class DateFilter {
  title: string = "";
  field: string = "";
  occurrence: string = "";
  timing: DateTiming = null;
  sinceDays?: number = null;
  startDate?: string = null;
  endDate?: string = null;
  daysFrom?: number = null;
  daysTo?: number = null;
  since?: string = null;
  marker?: Array<string> | string;
}

export const getFriendlyTiming = (model: DateFilter | ContactsDateFilter | TaskDateRangeFilter) => {

  const timing = model.since || model.timing;

  if (timing == null) return "";

  switch (timing) {
    case TimingFilterType.Empty: return "Empty";
    case TimingFilterType.Any: return "Any";
    case TimingFilterType.Overdue: return "Overdue";
    case TimingFilterType.DueToday: return "Due Today";
    case TimingFilterType.DueSoon: return "Due Soon";
    case TimingFilterType.Today: return "Today";
    case TimingFilterType.Tomorrow: return "Tomorrow";
    case TimingFilterType.Yesterday: return "Since yesterday";
    case TimingFilterType.Last7: return "In last 7 days";
    case TimingFilterType.Last7Days: return "In last 7 days";
    case TimingFilterType.LastX: return `In last ${model.sinceDays} day${model.sinceDays === 1 ? '' : 's'}`;
    case TimingFilterType.LastXDays: return `In last ${model.sinceDays} day${model.sinceDays === 1 ? '' : 's'}`;
    case TimingFilterType.Month: return "In current month";
    case TimingFilterType.CurrentMonth: return "In current month";
    case TimingFilterType.Year: return "In current calendar year";
    case TimingFilterType.CurrentYear: return "In current calendar year";
    case TimingFilterType.DateRange: return `From ${model.startDate} to ${model.endDate}`;
    case TimingFilterType.DayRange: return `From ${model.daysFrom} to ${model.daysTo} day${model.daysTo == 1 ? '' : 's'} ago`;

    default: return "Any";
  }
};

export const dateToFilterString = (filter: DateFilter) => {
  const timing = filter.since || filter.timing;
  switch (timing) {
    case TimingFilterType.Any:
      return `_exists_:${filter.field}`;

    case TimingFilterType.Empty:
      return `_missing_:${filter.field}`;

    case TimingFilterType.Today:
      return `${filter.field}:[now/d TO now+1d/d]`;

    case TimingFilterType.Tomorrow:
      return `${filter.field}:[now+1d/d TO now+2d/d]`;

    case TimingFilterType.Overdue:
      return `isCompleted:false dateDueUtc:<now/d`;

    case TimingFilterType.DueToday:
      return `isCompleted:false dateDueUtc:>=now/d dateDueUtc:<now+1d/d`;

    case TimingFilterType.DueSoon:
      return `isCompleted:false dateDueUtc:>=now/d dateDueUtc:<now+7d/d`;

    case TimingFilterType.Yesterday:
      return `${filter.field}:[now-1d/d TO now/d]`;

    case TimingFilterType.Last7:
    case TimingFilterType.Last7Days:
      return `${filter.field}:>=now-7d/d`;

    case TimingFilterType.Next7Days:
      // return `${filter.field}:[now/d TO now+7d/d]`;              // not working at the moment
      // return `${filter.field}:[now/d TO now||+7d/d]`;            // ...or maybe it's this one
      return `${filter.field}:[${filter.startDate} TO ${filter.endDate}]`;

    case TimingFilterType.Next30Days:
      // return `${filter.field}:[now/d TO now+30d/d]`;             // not working at the moment
      // return `${filter.field}:[now/d TO now||+30d/d]`;           // ...or maybe it's this one
      return `${filter.field}:[${filter.startDate} TO ${filter.endDate}]`;

    case TimingFilterType.Month:
    case TimingFilterType.CurrentMonth:
      return `${filter.field}:>=now/M`;

    case TimingFilterType.ThisMonth:
      return `${filter.field}:[${filter.startDate} TO ${filter.endDate}]`;

    case TimingFilterType.CurrentYear:
      return `${filter.field}:>=now/y`;

    case TimingFilterType.ThisYear:
      return `${filter.field}:[${filter.startDate} TO ${filter.endDate}]`;

    case TimingFilterType.LastX:
    case TimingFilterType.LastXDays:
    case TimingFilterType.DayRange:
      // when daysFrom is blanked out it will be typeof string instead of number
      if (typeof filter.daysFrom == "number" && filter.daysFrom >= 0) {
        if (filter.daysTo > 0) {
          return `${filter.field}:[now-${filter.daysFrom}d/d TO now-${filter.daysTo}d/d}`;
        } else {
          return `${filter.field}:>=now-${filter.daysFrom}d/d`;
        }
      }
      break;

    case TimingFilterType.DateRange:
      if (filter.startDate && filter.endDate) {
        return `${filter.field}:[${filter.startDate} TO ${filter.endDate}]`;
      }
      break;
  }

  return "";
};

export const dateToFilterPaths = (filter: DateFilter): FilterTermsResult[] => {
  if (filter.marker?.length) {
    return [{ term: filter.field }];
  }

  const timing = filter.since || filter.timing;
  switch (timing) {
    case TimingFilterType.Any:
    case TimingFilterType.Empty:
    case TimingFilterType.Today:
    case TimingFilterType.Tomorrow:
      return [{ term: filter.field }];

    case TimingFilterType.Overdue:
    case TimingFilterType.DueToday:
    case TimingFilterType.DueSoon:
      return [{ term: "isCompleted" }, { term: "dateDueUtc" }];

    case TimingFilterType.Yesterday:
    case TimingFilterType.Last7:
    case TimingFilterType.Last7Days:
    case TimingFilterType.Month:
    case TimingFilterType.CurrentMonth:
    case TimingFilterType.CurrentYear:
      return [{ term: filter.field }];

    case TimingFilterType.LastX:
    case TimingFilterType.LastXDays:
    case TimingFilterType.DayRange:
      // when daysFrom is blanked out it will be typeof string instead of number
      if (typeof filter.daysFrom == "number" && filter.daysFrom >= 0) {
        return [{ term: filter.field }];
      }
      break;

    case TimingFilterType.DateRange:
      if (filter.startDate && filter.endDate) {
        return [{ term: filter.field }];
      }
      break;
  }

  return [];
};

export const contactTimeRangeFilter = (searchField, filter) => {
  const time = filter.marker[1] ? `${filter.marker[0]} TO ${filter.marker[1]}` : `:${filter.marker[0]}`;
  return `(${searchField}${time})`;
}

export const timeRangeFilter = (filter, field = null) => {
  const time = `${filter.marker[0]} TO ${filter.marker[1]}`;
  return `(${field ? field : filter.field}${time})`;
}
