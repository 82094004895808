import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS } from "./ats";
import { CollaborationModule, CollaborationModuleType } from "models/collaboration-module";
import { AssignmentArgs } from "./args";
import { TaskRequest } from "models/task-request";

@inject
export class CollaborationModuleRepository {
  constructor(private s: ATS) {}

  public async post(collaborationModule: CollaborationModule, type: CollaborationModuleType) {
    const res = await this.s.post("collaboration-module", collaborationModule, { type });
    return createFrom(CollaborationModule, res.body);
  }

  public async put(collaborationModule: CollaborationModule) {
    const res = await this.s.put(`collaboration-module/${collaborationModule.Id}`, collaborationModule);
    return createFrom(CollaborationModule, res.body);
  }

  public async assign(collaborationModuleId: string, args: AssignmentArgs): Promise<TaskRequest> {
    const res = await this.s.post(`collaboration-module/${collaborationModuleId}/assign`, args);
    return createFrom(TaskRequest, res.body);
  }

  public async del(ids: string[]) {
    await this.s.post("collaboration-module/delete", ids);
  }
}
