import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { ApiKey } from "models/api-key";

@inject
export class ApiKeyRepository {
  constructor(private s: ATS) {}

  async list() {
    const res = await this.s.get<any[]>("api-key");
    return createFromArray(ApiKey, res.body);
  }

  async post(roleId: string) {
    const res = await this.s.post("api-key", null, { roleId });
    return createFrom(ApiKey, res.body);
  }

  async put(apiKey: ApiKey) {
    const res = await this.s.put(`api-key/${apiKey.Id}`, apiKey);
    return createFrom(ApiKey, res.body);
  }

  async putActive(ids: string[], isActive: boolean) {
    await this.s.put("api-key/active", ids, { isActive });
  }

  async del(ids: string[]) {
    await this.s.post("api-key/delete", ids);
  }
}
