import { ApplicationRestriction } from "models/role";

import { ApplicantIdentityRestriction } from "./applicant-identity-restriction";
import { ApplicantProfileImageRestriction } from "./applicant-profile-image-restriction";
import { ApplicationPropertyRestriction } from "./application-property-restriction";
import { FormRestriction } from "./form-restriction";
import { ProgramStepRestriction } from "./program-step-restriction";

export const getRestrictionComponentType = (r: ApplicationRestriction) => {
  switch (r && r.Type) {
    case "ApplicantIdentity": return "applicant-identity-restriction";
    case "ApplicantProfileImage": return "applicant-profile-image-restriction";
    case "ApplicationProperty": return "application-property-restriction";

    case "DocumentStep":
    case "PortfolioStep":
      return "program-step-restriction";

    case "FormStep":
    case "Evaluation":
    case "ReferenceForm":
      return "form-restriction";

    default: return null;
  }
};

export const restrictionComponents = [
  ApplicantIdentityRestriction,
  ApplicantProfileImageRestriction,
  ApplicationPropertyRestriction,
  FormRestriction,
  ProgramStepRestriction,
];
