import { RouterConfig } from "fw";

import { HideChrome } from "router-middleware/hide-chrome";
import GoalsRoutes from "./goals/goals-routes";
import Home from "views/home/home";

export class AnalyticsRoutes {
  loading = true;

  async registerRoutes(r: RouterConfig) {
    r.addMiddleware(HideChrome);
    this.loading = true;

    r.add("", Home);
    r.add("goals", GoalsRoutes);
    this.loading = false;
  }
}
