import { inject, prop, ComponentEventBus } from "fw";

@inject
export class DestroyAlert {
  @prop(false) working: boolean;
  @prop("Delete") actionText: string;
  @prop("Keep") cancelText: string;
  @prop("Deleting...") workingText: string;

  constructor(private ceb: ComponentEventBus) {}

  close() {
    this.ceb.dispatch("close");
  }

  destroy() {
    this.ceb.dispatch("destroy");
  }
}
