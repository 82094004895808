import { inject } from "fw";
import { DialogController } from "fw-dialog";

export interface SystemMessageDialogArgs {
  text: string;
  infos: string[];
  okButtonText?: string;
}


@inject
export class SystemMessageDialog {
  private text = "";
  private infos:string[] = [];
  private okButtonText = "Ok";

  constructor(private controller: DialogController<void>) { }

  activate(args: SystemMessageDialogArgs) {
    const { okButtonText, text, infos } = args;

    this.text = text;
    this.infos = infos;
    this.okButtonText = okButtonText || this.okButtonText;
  }

  ok() {
    this.controller.ok(null);
  }

  cancel() {
    this.controller.cancel();
  }
}
