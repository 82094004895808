import { inject } from "fw";
import { CustomFieldType, IdentifierMode } from "models/contact-organization";
import { Contact } from "models/contact";
import { compare } from "fast-json-patch";
import { IFieldContext } from "./contacts-field-paths";
import { Notification } from "service/notification";
import { dispatch } from "fw-state";
import { UpdateContactPropertiesAction } from "state/current-contact";
import { Permissions } from "service/permission";
import { DialogResult } from "fw-dialog";
import { SuccessResult } from "models/success-result";
import { truncate } from "lodash-es";

@inject
export class ContactsFieldEditService {
  constructor(
    private notification: Notification,
    private permissions: Permissions
  ) {}

  public canEdit(contact: Contact, fieldContext: IFieldContext) {
    if (
      fieldContext.isRestricted ||
      fieldContext.definition === null ||
      fieldContext.definition.is_read_only ||
      fieldContext.definition.is_calculated ||
      contact === null ||
      contact.is_deleted ||
      !this.permissions.all("EditContacts") ||
      fieldContext.definition.identifier_mode === IdentifierMode.Instance ||
      (fieldContext.definition.type === CustomFieldType.concealed && !this.permissions.all("ViewEncryptedFields"))
    ) {
      return false;
    }

    const readonlyFieldTypes = [
      CustomFieldType.slideroomapplications,
      CustomFieldType.testscore,
    ];
    if (readonlyFieldTypes.includes(fieldContext.definition.type)) {
      return false;
    }

    if (fieldContext.definition.identifier_mode === IdentifierMode.Email) {
      return true;
    }

    if (fieldContext.definition.identifier_mode !== IdentifierMode.None) {
      return !fieldContext.value;
    }

    return !fieldContext.definition.is_read_only;
  }

  public async save(
    contact: Contact,
    fieldContext: IFieldContext,
    originalValue: any,
    validate: (value: any) => boolean | string
  ): Promise<SuccessResult> {
    if (validate) {
      const validationResult = validate(fieldContext.value);
      if (validationResult === false || typeof validationResult === "string") {
        const result: SuccessResult = {
          success: false,
          message: validationResult || "Property value is not valid",
        };
        return result;
      }
    }

    const operations = this.calculatePatchOperations(
      originalValue,
      fieldContext.value
    );
    operations.forEach((o) => {
      let path = o.path.replace("/value", "");
      if (fieldContext.path) {
        path = !!path ? `${fieldContext.path}${path}` : fieldContext.path;
      }

      o.path = path;
    });

    await dispatch(new UpdateContactPropertiesAction(contact, operations));
    const result: SuccessResult = {
      success: true,
      message: "Properties Updated",
    };
    return result;
  }

  private calculatePatchOperations(originalValue, newValue) {
    if (originalValue === null && newValue === null) {
      return [];
    }

    return compare({ value: originalValue }, { value: newValue });
  }
}
