import { inject, prop } from "fw"
import { CommentFilter as CommentFilterModel } from "models/application-filters"

@inject
export class CommentFilter
{
    @prop(null) filter!: CommentFilterModel;
    @prop(false) editing!: boolean;

    get options() {
        return [
            {
                value: true,
                text: "Has comments",
            },
            {
                value: false,
                text: "Does not have comments",
            }
        ]
    }

    get friendly() {
        return this.options.find((option) => option.value == this.filter.hasComments)?.text;
    }
}
