import { inject, Network, NVP, NetworkException } from "fw";
import { createFromArray, makerOf } from "fw-model";
import { LocalStorageCache } from "caching";
import { parseJwt } from "helpers/parse-jwt";

import { AtsConfig } from "config/ats-config";

import { NetworkAuthMiddleware } from "./middleware/network-auth-middleware";
import { TaskRequestMiddleware } from "./middleware/task-request-middleware";
import { UnauthorizedMiddleware } from "./middleware/unauthorized-middleware";
import { Operation } from "fast-json-patch";
import { ReplaceTokenMiddleware } from "./middleware/replace-token-middleware";
import { ToggleActivityAlert } from "state/activity";
import { dispatch } from "fw-state";

const tokenKeyName = "ats-bearer-token";
const impersonationTokenKeyName = "ats-impersonation-bearer-token";
const tokenExpDateName = "ats-bearer-token-expiration-date";
const tokenLifespanName = "ats-bearer-token-lifespan";

@inject
export class ATS {
  private _tokenLifespan: number = 0;

  constructor(
    private network: Network,
    private cache: LocalStorageCache,
    private networkAuth: NetworkAuthMiddleware,
    private unauthMiddleware: UnauthorizedMiddleware,
    private config: AtsConfig
  ) {
    this.network.addMiddleware(NetworkAuthMiddleware);
    this.network.addMiddleware(TaskRequestMiddleware);
    this.network.addMiddleware(UnauthorizedMiddleware);
    this.network.addMiddleware(ReplaceTokenMiddleware);

    const tokenLifespan = this.cache.get<number>(tokenLifespanName);
    if (tokenLifespan) this.setTokenLifespan(tokenLifespan);

    const token = this.cache.get<string>(tokenKeyName);
    if (token) this.setToken(token);

    const impersonationToken = this.cache.get<string>(impersonationTokenKeyName);
    if (impersonationToken) this.setImpersonationToken(impersonationToken);

    window.addEventListener("storage", (event) => this.handleStorageChange(event));
  }

  private async handleStorageChange(event: StorageEvent) {
    if (event.key === tokenKeyName) {
      this.networkAuth.token = this.cache.get<string>(tokenKeyName);
      if (this.networkAuth.token) {
        await dispatch(new ToggleActivityAlert(false));
      }
    } else if (event.key === tokenLifespanName) {
      this._tokenLifespan = this.cache.get<number>(tokenLifespanName) || 0;
    } else if (event.key === impersonationTokenKeyName) {
      this.networkAuth.impersonationToken = this.cache.get<string>(
        impersonationTokenKeyName
      );
      if (this.networkAuth.impersonationToken) {
        await dispatch(new ToggleActivityAlert(false));
      }
    }
  }

  public setTokenLifespan(lifespan: number) {
    this._tokenLifespan = lifespan;
    if (lifespan > 0) this.cache.set(tokenLifespanName, lifespan);
    else this.cache.remove(tokenLifespanName);
  }

  public setToken(token: string) {
    this.networkAuth.token = token;
    this.cache.set(tokenKeyName, token);
    this.cache.set(tokenExpDateName, new Date(parseJwt(token).exp * 1000));
  }

  public setImpersonationToken(impersonationToken: string) {
    this.networkAuth.impersonationToken = impersonationToken;
    this.cache.set(impersonationTokenKeyName, impersonationToken);
  }

  public setOverrideToken(overrideToken: string) {
    this.networkAuth.overrideToken = overrideToken;
  }

  public get hasToken() {
    return this.networkAuth.token != null;
  }

  public getTokenExpDate(): string {
    return this.cache.get(tokenExpDateName) ?? "";
  }

  public get hasImpersonationToken() {
    return this.networkAuth.impersonationToken != null;
  }

  public get token() {
    return this.networkAuth.token;
  }

  public get tokenLifespan() {
    return this._tokenLifespan;
  }

  public get impersonationToken() {
    return this.networkAuth.impersonationToken;
  }

  public deleteToken() {
    this.networkAuth.token = null;
    this.unauthMiddleware.loggedIn = false;
    this.cache.remove(tokenKeyName);
    this.cache.remove(tokenExpDateName);
  }

  public deleteImpersonationToken() {
    this.networkAuth.impersonationToken = null;
    this.cache.remove(impersonationTokenKeyName);
  }

  public get<T>(url: string, params?: any, baseUrl = this.config.apiUrl) {
    return this.network.get<T>(baseUrl + url, params);
  }

  public post<T>(
    url: string,
    data: any,
    params?: any,
    baseUrl = this.config.apiUrl
  ) {
    return this.network.post<T>(baseUrl + url, data, params);
  }

  public put<T>(
    url: string,
    data: any,
    params?: any,
    baseUrl = this.config.apiUrl
  ) {
    return this.network.put<T>(baseUrl + url, data, params);
  }

  public patch<T>(
    url: string,
    data: any,
    params?: any,
    baseUrl = this.config.apiUrl
  ) {
    return this.network.patch<T>(baseUrl + url, data, params);
  }

  public delete<T>(url: string, params?: any, baseUrl = this.config.apiUrl) {
    return this.network.delete<T>(baseUrl + url, params);
  }

  // shortcuts to the above methods with the contactsApiUrl as base url
  get contacts() {
    return {
      get: <T>(url: string, params?: any) =>
        this.get<T>(url, params, this.config.contactsApiUrl),
      post: <T>(url: string, data: any, params?: any) =>
        this.post<T>(url, data, params, this.config.contactsApiUrl),
      put: <T>(url: string, data: any, params?: any) =>
        this.put<T>(url, data, params, this.config.contactsApiUrl),
      patch: <T>(url: string, data: any, params?: any) =>
        this.patch<T>(url, data, params, this.config.contactsApiUrl),
      delete: <T>(url: string, params?: any) =>
        this.delete<T>(url, params, this.config.contactsApiUrl),
      count: async (url: string, f: string = null, aggs: string = null) => {
        const res = await this.get<ICountResult>(
          url,
          { aggs, f },
          this.config.contactsApiUrl
        );
        return res.body;
      },
      list: async <T>(
        url: string,
        dataType: makerOf<T>,
        q: string = null,
        f: string = null,
        aggs: string = null,
        sort: string = null,
        page = 1,
        limit = 10,
        after: string = null
      ) => {
        const params = getParams(q, f, aggs, sort, page, limit, null, after);
        const res = await this.get<IListResults<T>>(
          url,
          params,
          this.config.contactsApiUrl
        );

        const results: IPaginatedResults<T> = {
          aggregations: res.body.aggregations,
          results: createFromArray(dataType, res.body.results),
          total: parseInt(res.headers["x-result-count"], 10),
          after: res.headers["x-searchaftertoken"],
          before: res.headers["x-searchbeforetoken"],
        };
        return results;
      },
    };
  }

  get marketing() {
    return {
      get: <T>(url: string, params?: any) =>
        this.get<T>(url, params, this.config.marketingApiUrl),
      post: <T>(url: string, data: any, params?: any) =>
        this.post<T>(url, data, params, this.config.marketingApiUrl),
      put: <T>(url: string, data: any, params?: any) =>
        this.put<T>(url, data, params, this.config.marketingApiUrl),
      patch: <T>(url: string, data: any, params?: any) =>
        this.patch<T>(url, data, params, this.config.marketingApiUrl),
      delete: <T>(url: string, params?: any) =>
        this.delete<T>(url, params, this.config.marketingApiUrl),
      count: async (url: string, f: string = null, aggs: string = null) => {
        const res = await this.get<ICountResult>(
          url,
          { aggs, f },
          this.config.marketingApiUrl
        );
        return res.body;
      },
      list: async <T>(
        url: string,
        dataType: makerOf<T>,
        q: string = null,
        f: string = null,
        aggs: string = null,
        sort: string = null,
        page = 1,
        limit = 10,
        filter: any = null
      ) => {
        const params = getParams(q, f, aggs, sort, page, limit, filter);
        const res = await this.get<IListResults<T>>(
          url,
          params,
          this.config.marketingApiUrl
        );

        const results: IListResults<T> = {
          aggregations: res.body.aggregations,
          results: createFromArray(dataType, res.body.results),
          total: parseInt(res.headers["x-result-count"], 10),
        };

        return results;
      },
      listOffset: async <T>(
        url: string,
        dataType: makerOf<T>,
        q: string = null,
        f: string = null,
        aggs: string = null,
        sort: string = null,
        offset = 0,
        limit = 10,
        filter: any = null
      ) => {
        const params = getParams(
          q,
          f,
          aggs,
          sort,
          null,
          limit,
          filter,
          null,
          offset
        );
        const res = await this.get<IPaginatedOffsetResults<T>>(
          url,
          params,
          this.config.marketingApiUrl
        );

        const results: IPaginatedOffsetResults<T> = {
          aggregations: res.body.aggregations,
          results: createFromArray(dataType, res.body.results),
          total: 0,
          nextOffset: parseInt(res.headers["x-next-offset"], 10),
        };

        return results;
      },
    };
  }
}

export const getParams = (
  q: string = null,
  f: string = null,
  aggs: string = null,
  sort: string = null,
  page = 1,
  limit = 10,
  filter: any = null,
  after: string = null,
  offset = 0
) => {
  return {
    page,
    limit: limit || 10,
    ...(sort && { sort }),
    ...(q && { q }),
    ...(f && { f }),
    ...(aggs && { aggs }),
    ...(filter && filter),
    ...(after && { after }),
    ...(offset && { offset }),
  };
};

export interface ValidationResultItem {
  message: string;
  name: string;
  type: string;
  code?: string;
  key?: string;
}

export interface ValidationResult {
  data: any;
  validation_result: ValidationResultItem[];
}

export interface IUsageProfileResult<T> {
  Message: string;
  Data: {
    UsageProfiles: T[];
  };
}

export function isValidationResult(d): d is NetworkException<ValidationResult> {
  return d.statusCode == 400 && d.result && d.result.validation_result;
}

export interface IListResults<T> {
  results: T[];
  total: number;
  aggregations?: { [key: string]: IAggregationResult };
}

export interface IPaginatedResults<T> extends IListResults<T> {
  after?: string;
  before?: string;
}

export interface IPaginatedOffsetResults<T> extends IListResults<T> {
  nextOffset?: number;
}

export interface ICountResult {
  total?: number;
  aggregations?: { [key: string]: IAggregationResult };
}

export interface IAggregationResult {
  total?: number;
  value?: number;
  items?: IBucketResult[];
  aggregations?: { [key: string]: IAggregationResult };
}

export interface IBucketResult {
  key: string | number | boolean | Date;
  key_as_string?: string;
  value?: number;
  total?: number;
  aggregations?: { [key: string]: IAggregationResult };
}

export interface IEntitySelection {
  contact_type?: string;
  ids?: string[];
  filter?: string;
  query?: string;
  columns?: string[];
}

export interface IEntitySelectionPatches {
  ids?: string[];
  filter?: string;
  query?: string;
  columns?: string[];
  patch: Operation[];
}

export class ContactModelActionResults {
  success?: string[] = [];
  failure?: ContactPermissionResult[] = [];
}

export class ContactPermissionResult {
  allowed: boolean = false;
  id: string = null;
  message: string = null;
  status_code: number;
}

export function getHttpStatus(code: number | HttpStatusCode): string {
  if (!code) return null;
  return HttpStatusCode[code] || "Status code is invalid";
}

// HTTP status codes as per RFC 2616.
enum HttpStatusCode {
  // Informational 1xx
  Continue = 100,
  SwitchingProtocols = 101,
  Processing = 102,
  EarlyHints = 103,

  // Successful 2xx
  OK = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultiStatus = 207,
  AlreadyReported = 208,

  IMUsed = 226,

  // Redirection 3xx
  MultipleChoices = 300,
  Ambiguous = 300,
  MovedPermanently = 301,
  Moved = 301,
  Found = 302,
  Redirect = 302,
  SeeOther = 303,
  RedirectMethod = 303,
  NotModified = 304,
  UseProxy = 305,
  Unused = 306,
  TemporaryRedirect = 307,
  RedirectKeepVerb = 307,
  PermanentRedirect = 308,

  // Client Error 4xx
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  RequestEntityTooLarge = 413,
  RequestUriTooLong = 414,
  UnsupportedMediaType = 415,
  RequestedRangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  // From https://github.com/dotnet/runtime/issues/15650:
  // "It would be a mistake to add it to .NET now. See golang/go#21326,
  // nodejs/node#14644, requests/requests#4238 and aspnet/HttpAbstractions#915".
  // ImATeapot = 418

  MisdirectedRequest = 421,
  UnprocessableEntity = 422,
  Locked = 423,
  FailedDependency = 424,

  UpgradeRequired = 426,

  PreconditionRequired = 428,
  TooManyRequests = 429,

  RequestHeaderFieldsTooLarge = 431,

  UnavailableForLegalReasons = 451,

  // Server Error 5xx
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HttpVersionNotSupported = 505,
  VariantAlsoNegotiates = 506,
  InsufficientStorage = 507,
  LoopDetected = 508,

  NotExtended = 510,
  NetworkAuthenticationRequired = 511,
}
