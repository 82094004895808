import { inject } from "fw";
import { Store, dispatch, handle } from "fw-state";

import { EvaluationPhasesRepository } from "network/evaluation-phases-repository";
import { FormForEvaluationPhase } from "forms/evaluation-phase";
import { EvaluationPhase } from "models/evaluation-phase";
import {
  LogoutAction,
  StartAction,
  ApplyEvaluationPhasesAction,
} from "./actions";
import { FeatureFlagService } from 'service/feature-flag';
import { EntitySelection } from "models/application-client-model";

export class UpdateDefaultPhaseLabelAction {
  constructor(public label: string) { }
}

export class UpdateEvaluationPhasesAction {
  constructor(public forms: FormForEvaluationPhase[]) { }
}

interface EvaluationPhasesStoreShape {
  defaultPhaseLabel: string;
  phases: EvaluationPhase[];
}

export class RunRulesAction {
  constructor(public phaseId: string, public selection: EntitySelection = null) { }
}

export class ReRunRulesAction {
  constructor(public phaseId: string, public selection: EntitySelection = null) { }
}

@inject
export class EvaluationPhasesStore extends Store<EvaluationPhasesStoreShape> {
  constructor(private evaluationPhasesRepo: EvaluationPhasesRepository, private featureFlagService: FeatureFlagService) {
    super();
  }

  defaultState() {
    return {
      defaultPhaseLabel: "In-Progress",
      phases: [],
    };
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private handleStartAction(s: StartAction) {
    const { Season } = s.context;

    this.setState(state => ({
      defaultPhaseLabel: Season.DefaultPhaseLabel,
      phases: Season.EvaluationPhases || [],
    }));
  }

  @handle(UpdateDefaultPhaseLabelAction)
  private async handleUpdateDefaultPhaseLabelAction(action: UpdateDefaultPhaseLabelAction) {
    const label = await this.evaluationPhasesRepo.setDefaultLabel(action.label);

    this.setState(state => ({
      ...state,
      defaultPhaseLabel: label,
    }));
  }

  @handle(UpdateEvaluationPhasesAction)
  private async handleUpdateEvaluationPhasesAction(
    action: UpdateEvaluationPhasesAction,
  ) {
    let shouldThrow = false;

    for (let i = 0; i < action.forms.length; i++) {
      const f = action.forms[i];

      try {
        f.validate();
      } catch (err) {
        shouldThrow = true;
      }
    }

    if (shouldThrow) throw new Error("Invalid");

    const phases = await this.evaluationPhasesRepo.put(
      action.forms.map(f => f.updatedModel()),
    );

    this.setState(state => ({
      ...state,
      phases,
    }));

    dispatch(new ApplyEvaluationPhasesAction(phases));
  }

  @handle(RunRulesAction)
  private handleRunRulesAction(action: RunRulesAction) {
    if (action.phaseId) {
      return this.evaluationPhasesRepo.runRules(action.phaseId, action.selection);
    }
  }

  @handle(ReRunRulesAction)
  private handleReRunRulesAction(action: RunRulesAction) {
    if (action.phaseId) {
      return this.evaluationPhasesRepo.rerunRules(action.phaseId, action.selection);
    }
  }
}
