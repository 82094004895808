import { inject, needs } from "fw";
import { DialogController } from "fw-dialog";
import { NotificationRepository } from "network/notification-repository";
import { Notification } from "models/notification";

import { NotificationView } from "./notification-view";
import { MarkAsReadNotificationsAction } from "state/notifications";
import { dispatch } from "fw-state";

export interface NotificationDialogOptions {
  notification: Notification;
}

@inject
@needs(NotificationView)
export class NotificationDialog {
  private notification: Notification = null;

  constructor(private controller: DialogController<void>,
    private notificationRepo: NotificationRepository,
  ) { }

  async activate(params: NotificationDialogOptions) {
    this.notification = params.notification;
    this.ensureMarkedRead();
  }

  async ensureMarkedRead() {
    if (this.notification.MetaData.IsRead) return;
    await dispatch(new MarkAsReadNotificationsAction([ this.notification.Id ], true));
  }

  close() {
    this.controller.cancel();
  }
}
