import { inject } from "fw";
import { groupBy, orderBy, transform } from "lodash-es";

import { ApplicationSegmentStore } from "state/application-segments";
import { ContactStore } from "state/contacts";
import { ContactSegmentStore, EnsureContactSegmentStoreAction } from "state/contact-segments";
import { ProgramStore } from "state/program";
import { PopoverController } from "service/popover";
import { ApplicationSegment } from "models/application-segment";
import { ContactSegment } from "models/contact-segment";
import { Program } from "models/program";
import { dispatch } from "fw-state";

export interface SegmentSelectorPopoverParams {
  showAll?: boolean;
  showPrograms?: boolean;
  excludeApplicationSegments?: string[]
  excludeContactSegments?: string[]
}

export interface SegmentSelectorPopoverResponse {
  type?: 'Program' | 'ApplicationSegment' | 'ContactSegment';
  id: string;
}

enum SegmentType {
  None = 0,
  Application = 1,
  Contact = 2,
}

@inject
export class SegmentSelectorPopover {
  public searchTerm: string = '';
  public selectedApplicationCategory: string = null;
  public selectedContactType: string = null;
  public selectedContactTypeKey: string = null;
  public selectedContactCategory: string = null;
  public isProgramsSelected: boolean = false;
  public showAll: boolean = false;
  public showPrograms: boolean = false;
  public applicationSegmentsWithNoCategory: ApplicationSegment[] = [];
  public programs: Program[] = [];
  private gridFilterRef: HTMLElement;
  private excludeApplicationSegments: Set<string> = new Set([]);
  private excludeContactSegments: Set<string> = new Set([]);
  private SegmentTypes = SegmentType;
  private segmentType: SegmentType = SegmentType.None;

  constructor(
    private applicationSegmentStore: ApplicationSegmentStore,
    private contactStore: ContactStore,
    private contactSegmentStore: ContactSegmentStore,
    private programStore: ProgramStore,
    private controller: PopoverController<SegmentSelectorPopoverResponse>
  ) { }

  public async activate(params: SegmentSelectorPopoverParams) {
    await dispatch(new EnsureContactSegmentStoreAction());

    this.showAll = params?.showAll || false;
    this.showPrograms = params?.showPrograms || false;

    this.excludeApplicationSegments = new Set(params?.excludeApplicationSegments || []);
    this.excludeContactSegments = new Set(params?.excludeContactSegments || []);
    this.applicationSegmentsWithNoCategory = this.applicationSegmentStore.state.segments.filter(s => {
      return !this.excludeApplicationSegments.has(s.Id) && s.Category == null;
    });

    if (this.showPrograms) {
      this.programs = orderBy(
        this.programStore.state.programs.filter(p => p.IsHidden !== true),
        [p => p.Name.toLowerCase()]
      );
    }
  }

  get applicationCategories() {
    return this.excludeApplicationSegments?.size == 0
    ? this.applicationSegmentStore.state.categories
    : transform(this.applicationSegmentStore.state.categories, (result, value, key) => {
      const filteredSegments = value.filter(s => !this.excludeApplicationSegments?.has(s.Id));
      if (filteredSegments.length > 0) {
        result[key] = filteredSegments;
      }
    });
  }

  get contactTypes() {
    const contactCategories = this.contactSegmentStore.state.categories;
    const { contact_types } = this.contactStore.state.organization;
    let contactTypes = {};
    for (let i=0 ; i<contact_types.length; i++) {
      const contactType = contact_types[i];
      for (const categoryName in contactCategories) {
        const contactSegments = contactCategories[categoryName];
        for (let j=0 ; j<contactSegments.length; j++) {
          const contactSegment = contactSegments[j];
          if (!this.excludeContactSegments?.has(contactSegment.id)
            && contactType.key === contactSegment.contact_type) {
            contactTypes[contactType.name] = (contactTypes[contactType.name])
              ? [...contactTypes[contactType.name], categoryName]
              : [categoryName];
          }
        };
      }
    }
    return contactTypes;
  }

  get contactCategories() {
    return transform(this.contactSegmentStore.state.categories, (result, value, key) => {
      const filteredSegments = value.filter(s => {
        return !this.excludeContactSegments?.has(s.id)
          && this.selectedContactTypeKey === s.contact_type;
      });
      if (filteredSegments.length > 0) {
        result[key] = filteredSegments;
      }
    });
  }

  get contactSegmentsWithNoCategory(): ContactSegment[] {
    return this.contactSegmentStore.state.segments.filter(s => {
      return !this.excludeContactSegments?.has(s.id)
        && s.category == null
        && this.selectedContactTypeKey === s.contact_type;
    });
  }

  public selectSegmentType(segmentType: SegmentType) {
    this.segmentType = segmentType;
  }

  public selectApplicationCategory(category: string) {
    this.searchTerm = '';
    this.selectedApplicationCategory = category;
  }

  public selectContactType(name: string) {
    this.searchTerm = '';
    this.selectedContactType = name;
    this.selectedContactTypeKey = this.contactStore.state.organization.contact_types.find(type => name === type.name)?.key || null;
  }

  public selectContactCategory(category: string) {
    this.searchTerm = '';
    this.selectedContactCategory = category;

    // search can skip over contact type selection, so select it if it's missing
    if (!this.selectedContactType) {
      const contactCategorySegments = this.contactSegmentStore.state.categories[category];
      const segmentContactType = contactCategorySegments[0]?.contact_type;
      const contactType = this.contactStore.state.organization.contact_types.find(type => type.key === segmentContactType)
      this.selectContactType(contactType?.name);
    }
  }

  public selectProgram(programId: string) {
    this.controller.ok(<SegmentSelectorPopoverResponse>{ type: 'Program', id: programId });
  }

  public selectApplicationSegment(applicationSegmentId: string) {
    this.controller.ok(<SegmentSelectorPopoverResponse>{ type: 'ApplicationSegment', id: applicationSegmentId });
  }

  public selectContactSegment(contactSegmentId: string) {
    this.controller.ok(<SegmentSelectorPopoverResponse>{ type: 'ContactSegment', id: contactSegmentId });
  }

  public toggleIsProgramsSelected() {
    this.isProgramsSelected = !this.isProgramsSelected;
  }

  public get searchedApplicationCategories(): { [categoryName: string]: ApplicationSegment[] } {
    if (!!this.selectedApplicationCategory || this.segmentType == SegmentType.Contact) {
      return {};
    }
    const search = this.searchTerm.toLowerCase();
    const filtered = this.applicationSegmentStore.state.segments.filter(s => {
      return !this.excludeApplicationSegments.has(s.Id) && s.Category != null && s.Category.toLowerCase().indexOf(search) != -1;
    });
    return groupBy(filtered, s => s.Category);
  }

  public get searchedContactCategories(): { [categoryName: string]: ContactSegment[] } {
    if (!!this.selectedContactCategory || this.segmentType == SegmentType.Application) {
      return {};
    }
    const search = this.searchTerm.toLowerCase();
    const filtered = this.contactSegmentStore.state.segments.filter(s => {
      return !this.excludeContactSegments?.has(s.id)
        && s.category != null
        && s.category.toLowerCase().indexOf(search) != -1
        && (!this.selectedContactTypeKey || this.selectedContactTypeKey === s.contact_type);
    });
    return groupBy(filtered, s => s.category);
  }

  public get searchedApplicationSegments(): ApplicationSegment[] {
    if (this.segmentType == SegmentType.Contact) {
      return [];
    }
    const list = this.selectedApplicationCategory != null ? this.applicationCategories[this.selectedApplicationCategory] : this.applicationSegmentStore.state.segments;
    const search = this.searchTerm.toLowerCase();
    return orderBy(
      list.filter(i => !this.excludeApplicationSegments.has(i.Id) && i.Label.toLowerCase().indexOf(search) != -1),
      [s => s.Label.toLowerCase()]
    );
  }

  public get searchedContactSegments(): ContactSegment[] {
    if (this.segmentType == SegmentType.Application) {
      return [];
    }
    const list = this.selectedContactCategory != null ? this.contactCategories[this.selectedContactCategory] : this.contactSegmentStore.state.segments;
    const search = this.searchTerm.toLowerCase();
    return orderBy(
      list.filter(s => {
        return !this.excludeContactSegments?.has(s.id)
          && s.display_name.toLowerCase().indexOf(search) != -1
          && (!this.selectedContactTypeKey || this.selectedContactTypeKey === s.contact_type);
      }),
      [s => s.display_name.toLowerCase()]
    );
  }

  public get searchedPrograms(): Program[] {
    if (this.showPrograms) {
      const list = this.programStore.state.programs;
      const search = this.searchTerm.toLowerCase();
      return orderBy(
        list.filter(i => i.Name.toLowerCase().indexOf(search) != -1),
        [p => p.Name.toLowerCase()]
      );
    }
    return [];
  }

  public clearSearch() {
    this.searchTerm = '';
    this.gridFilterRef.focus();
  }

  public get searchPlaceholder(): string {
    let placeholder = 'Search ';

    if (this.isProgramsSelected) {
      placeholder += 'Programs';
    } else if (this.showPrograms) {
      if (!this.selectedApplicationCategory) {
        placeholder += 'Programs and Application Segments';
      } else if (!this.selectedContactCategory) {
        placeholder += 'Programs and Contact Segments';
      }
    } else if (this.selectedContactType) {
      placeholder += `${this.selectedContactType} Segments`;
    } else if (this.segmentType === SegmentType.Application) {
      placeholder += 'Application Segments';
    } else if (this.segmentType === SegmentType.Contact) {
      placeholder += 'Contact Segments';
    } else {
      placeholder += 'Segments';
    }

    if (!this.isProgramsSelected) {
      if (this.selectedApplicationCategory) {
        placeholder += ` in ${this.selectedApplicationCategory}`;
      } else if (this.selectedContactCategory) {
        placeholder += ` in ${this.selectedContactCategory}`;
      }
    }

    return placeholder + '...';
  }

  public get searchedApplicationCategoriesLength(): number {
    return Object.keys(this.searchedApplicationCategories).length;
  }

  public get searchedContactCategoriesLength(): number {
    return Object.keys(this.searchedContactCategories).length;
  }

  public get totalSearchResultCount(): number {
    let total = 0;

    if (null == this.selectedApplicationCategory && null == this.selectedContactCategory) {
      total += this.searchedPrograms.length;
    }

    if (!this.isProgramsSelected) {
      total += this.searchedApplicationCategoriesLength + this.searchedApplicationSegments.length
             + this.searchedContactCategoriesLength + this.searchedContactSegments.length;
    }

    return total;
  }
}
