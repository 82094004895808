import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS } from "./ats";
import { CollaboratorRole } from "models/collaborator-role";

@inject
export class CollaboratorRoleRepository {
  constructor(private s: ATS) {}

  async post(role: CollaboratorRole) {
    const res = await this.s.post("collaborator-role", role);
    return createFrom(CollaboratorRole, res.body);
  }

  async put(role: CollaboratorRole) {
    const res = await this.s.put(`collaborator-role/${role.Id}`, role);
    return createFrom(CollaboratorRole, res.body);
  }

  async del(id: string) {
    await this.s.delete(`collaborator-role/${id}`);
  }
}
