import { fromClass, fromClassArray } from "fw-model";
import { AggregateCalculatedField } from "./calculated-field";
import { PinnedFieldGroup } from "./application-settings";

export enum CollaborationModuleType {
  Form = 0,
  Interview = 1,
}

export class CollaborationModuleMetaData {
  OrganizationId: string;
  SeasonId: string;
  Type: CollaborationModuleType;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

export class CollaborationModule {
  Id: string = null;
  Name: string = null;
  Key: string = null;
  FormId: string = null;
  RequiredEvaluationCount = 0;
  CollaboratorRoleIds: string[] = [];

  SegmentClientData: string = null;
  SegmentQuery: string = null;

  @fromClassArray(AggregateCalculatedField) CalculatedFields: AggregateCalculatedField[];
  @fromClassArray(PinnedFieldGroup) PinnedFieldGroups: PinnedFieldGroup[];

  IsDeleted: boolean;

  @fromClass MetaData: CollaborationModuleMetaData;
}
