import { inject, prop, needs } from "fw";

import { ValueType, ValueField } from "views/components/application-filters/value-field";
import { getFriendlyTiming } from "models/date-filter";
import { SubmissionStatusFilter as SubmissionStatusFilterModel } from "models/application-filters";
import { hashOn } from "hashing";
import { IDropdownFieldOptions } from "../dropdown-field";
import { dispatch } from "fw-state";
import { TimingFilterType } from "models/filter-timing";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";

export enum SubmissionFields {
  IsSubmitted = "isSubmitted",
  DateSubmitted = "dateSubmitted"
}

@inject
@needs(ValueField)
export class SubmissionStatusFilter {
  @prop(null) filter!: SubmissionStatusFilterModel;
  @prop(false) editing!: boolean;
  @prop(() => ({})) validation;

  public valueTypes = ValueType;

  public fields = [
    { text:"Is Submitted", value: SubmissionFields.IsSubmitted },
    { text:"Date Submitted", value: SubmissionFields.DateSubmitted },
  ];
  public fieldsHash = hashOn(this.fields, y => y.value, f => f);

  public stepGroupKeys: IDropdownFieldOptions[] = [];
  public stepGroupKeysHash: {} = {};

  constructor(
    private dataDictionaryStore: DataDictionaryStore,
  ) {}

  public async attached() {
    await dispatch(new EnsureDataDictionaryFieldsAction(false));

    this.stepGroupKeys = this.dataDictionaryStore.state.fields
      .filter(f => f.Category === "Program" && f.JsonDataType === "object" && f.Path.startsWith("stepGroups."))
      .map(f => ({ text: f.Path.replace("stepGroups.", ""), value: f.Path }));

    this.stepGroupKeysHash = hashOn(this.stepGroupKeys, y => y.value, f => f);
  }

  public onFieldChange() {
    if(this.valueType === ValueType.Date) {
      this.filter.timing = this.filter.timing ?? TimingFilterType.Today;
    } else {
      this.filter.timing = null;
      this.filter.value = null; 
    }
  }

  public get friendlyExpression() {
    const fieldName = this.fieldsHash[this.filter.field]?.text;
    switch(this.filter.field) {
      case SubmissionFields.IsSubmitted:
        return `${this.filter.value === true? 'Submitted': "Not Submitted"}`;
      case SubmissionFields.DateSubmitted:
        return `${fieldName} ${getFriendlyTiming(this.filter)}`;
      default:
        return ``;
    }
  }

  public get options() {
    switch(this.filter.field) {
      case SubmissionFields.IsSubmitted:
        return [
            { text:"Yes", value: true },
            { text:"No", value: false },
        ];
      default:
        return [];
    }
  }

  public get valueType()  {
    switch(this.filter.field) {
      case SubmissionFields.IsSubmitted:
        return ValueType.Boolean;
      case SubmissionFields.DateSubmitted:
        return ValueType.Date;
      default:
        return ValueType.Text;
    }
  }
}
