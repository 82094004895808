import { NetworkRequestMiddleware, RequestContext } from "fw";

export class NetworkAuthMiddleware implements NetworkRequestMiddleware {
  public token: string = null;
  public impersonationToken: string = null;
  public overrideToken: string = null;

  onRequest(context: RequestContext) {
    if (this.overrideToken != null)
      context.addHeader("Authorization", `Bearer ${this.overrideToken + ((window as any).invalidate ? 'a' : '')}`);
    else if (this.impersonationToken != null)
      context.addHeader("Authorization", `Bearer ${this.impersonationToken + ((window as any).invalidate ? 'a' : '')}`);
    else if (this.token != null)
      context.addHeader("Authorization", `Bearer ${this.token + ((window as any).invalidate ? 'a' : '')}`);
  }
}
