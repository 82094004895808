import { inject } from "fw";

import { CurrentUserStore } from "state/current-user";
import { allOf, anyOf } from "helpers/permission";

@inject
export class Permissions {
  constructor(private currentUserStore: CurrentUserStore) { }

  public get isGlobalPrincipal() {
    return this.currentUserStore.state.isGlobalPrincipal;
  }

  public all(perms: string[] | string, features: string[] | string = null): boolean {
    return this.check(perms, features);
  }

  public any(perms: string[] | string, features: string[] | string = null): boolean {
    return this.check(perms, features, true);
  }

  private check(perms: string[] | string, features: string[] | string = null, any: boolean = false): boolean {
    const { loggedIn, isGlobalPrincipal, permissions } = this.currentUserStore.state;
    if (!loggedIn) {
      return false;
    }

    let hasFeature = true;
    if (features != null) {
      hasFeature = any ? anyOf(permissions, features) : allOf(permissions, features);
    }

    return (isGlobalPrincipal || (any ? anyOf(permissions, perms) : allOf(permissions, perms))) && hasFeature;
  }
}
