import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { FormErrorHandling } from "./error-handling";
import { TeamRepository } from "network/team-repository";

import { Team, TeamMember, TeamType } from "models/team";
import { AddTeamAction, UpdateTeamAction } from "forms/team";

import { LogoutAction, StartAction, RemoveUsersFromTeams } from "./actions";
import { wait } from "wait";
import { UpdateUserTeamIdsAction } from "./users";
import { EntityChanged, WebSocketMessageAction, filterEntityChangedMessage } from "./filter-websocket-message";
import { ChangeType } from "./message-broker-actions";

interface TeamList {
  filter: string;
  search: string;
  page: number;
  pageSize: number;
  sort: string;
  total: number;
  selectedCategory: string;

  currentPage: Team[];

  loaded: boolean;
  loading: boolean;
}

interface TeamsShape {
  teamLists: { [type: number]: TeamList }
  teamHash: { [id: string]: Team };

  teams: Team[]; // legacy
  selectedCategory: string;
}

export class DeleteTeamsAction {
  constructor(public teamIds: string[]) { }
}

export class EnsureTeamsAction {
  constructor(public teamIds: string[]) { }
}

export class EnsureUserInsideTeamAction {
  constructor(
    public teamIds: string[],
    public userId: string
  ) { }
}

export class EnsureListAction {
  constructor(
    public teamType: TeamType,
    public filter: string,
    public search: string,
    public sort: string = null,
    public category: string = null,
  ) { }
}

export class RefreshListAction {
  constructor(public teamType: TeamType) { }
}
export class NextPageAction {
  constructor(public teamType: TeamType) { }
}
export class PrevPageAction {
  constructor(public teamType: TeamType) { }
}
export class ToggleTeamsSortAction {
  constructor(public teamType: TeamType, public sort: string) { }
}
export class UpdateUserTeamsAction {
  constructor(
    public team: Team,
    public userId: string,
    public seasonId: string,
    public type: "update" | "remove"
  ) { }
}

// outside of TeamsStore to prevent vue from tracking..
let idsToLoad: string[] = [];
let idsToLoadLoading: string[] = [];

export class SelectCurrentTeamsCategoryAction {
  constructor(public teamType: TeamType, public selectedCategory: string) { }
}

export class TeamsCategorizeAction {
  constructor(public teamIds: string[], public category: string) { }
}

@inject
export class TeamsStore extends Store<TeamsShape> {

  constructor(private teamRepo: TeamRepository) {
    super();
  }

  defaultListState(): TeamList {
    return {
      filter: null,
      search: null,
      page: 1,
      pageSize: 20,
      sort: "name",
      loaded: false,
      loading: false,
      total: 0,
      selectedCategory: null,

      currentPage: [],
    }
  }

  defaultState() {
    return {
      teamLists: {
        [TeamType.Evaluation]: this.defaultListState(),
        [TeamType.Collaboration]: this.defaultListState()
      },
      teamHash: {},
      teams: [],
      selectedCategory: null,
    };
  }

  private getLoadedTeams(teamType: TeamType, filter: (t: Team) => boolean = null): Team[] {
    const teams: Team[] = [];

    if (teamType === TeamType.Collaboration || teamType == null) {
      teams.push(...
        filter != null
          ? this.state.teamLists[TeamType.Collaboration].currentPage.filter(t => filter(t))
          : this.state.teamLists[TeamType.Collaboration].currentPage
      );
    }

    if (teamType === TeamType.Evaluation || teamType == null) {
      teams.push(...
        filter != null
          ? this.state.teamLists[TeamType.Evaluation].currentPage.filter(t => filter(t))
          : this.state.teamLists[TeamType.Evaluation].currentPage
      );
    }

    for (let id in this.state.teamHash) {
      if (filter == null || filter(this.state.teamHash[id]))
        teams.push(this.state.teamHash[id]);
    }

    return teams;
  }

  private async loadPage(teamType: TeamType) {
    const { filter, search, sort, page, pageSize, selectedCategory } = this.state.teamLists[teamType];

    const fullFilter = selectedCategory == null ? filter : `(${filter}) AND category:${selectedCategory}`;

    const res = await this.teamRepo.list(fullFilter, search, sort, page, pageSize);

    const teamLists = {...this.state.teamLists, [teamType]: {
      ...this.state.teamLists[teamType],
      loading: false,
      loaded: true,
      currentPage: res.list,
      total: res.total
    }};
    
    const teamHash = res.list.reduce((hash, team) => {hash[team.Id] = team; return hash;}, {});

    this.setState(s => ({
      ...s,
      teamLists: teamLists,
      teamHash: teamHash
    }));
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    const { Teams } = s.context;
    this.setState(state => ({
      ...state,
      ...this.defaultState(),
      teams: Teams || [],
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(AddTeamAction, FormErrorHandling)
  private async handleAddTeamAction(action: AddTeamAction) {
    action.form.validate();

    const newTeam = await this.teamRepo.post(action.form.updatedModel(), action.teamType);
    // Update members for newly created team
    this.updateUsers(null, newTeam);

    await wait(1000);
    dispatch(new RefreshListAction(action.teamType));
  }

  @handle(UpdateTeamAction, FormErrorHandling)
  private async handleUpdateTeamAction(action: UpdateTeamAction) {
    action.form.validate();

    const updated = await this.teamRepo.put(action.form.updatedModel());
    const existingTeams = this.getLoadedTeams(null, t => t.Id == updated.Id);
    for (let team of existingTeams) {
      this.updateUsers(team, updated);
      Object.assign(team, updated)
    }

    this.setState(s => s);
  }

  private async updateUsers(original: Team, modified: Team) {
    const seasonId = modified?.MetaData.SeasonId || original?.MetaData.SeasonId;
    if (!seasonId)
      return;
    const originalIds = original?.Members.map(m => m.UserId) || [];
    const modifiedIds = modified?.Members.map(m => m.UserId) || [];
    let toRemove = originalIds.filter(i => !modifiedIds.includes(i));
    let toAdd = modifiedIds.filter(i => !originalIds.includes(i));

    for (const removeId of toRemove) {
      await dispatch(new UpdateUserTeamsAction(modified, removeId, seasonId, "remove"));
    }
    for (const addId of toAdd) {
      await dispatch(new UpdateUserTeamsAction(modified, addId, seasonId, "update"));
    }
  }

  @handle(DeleteTeamsAction)
  private async handleDeleteTeamsAction(action: DeleteTeamsAction) {

    await this.teamRepo.del(action.teamIds);

    action.teamIds.forEach(id => {
      if (this.state.teamHash[id] != null)
        delete this.state.teamHash[id];
    });

    dispatch(new RefreshListAction(TeamType.Collaboration));
    dispatch(new RefreshListAction(TeamType.Evaluation));

    await wait(1000);
    this.loadPage(TeamType.Collaboration);
    this.loadPage(TeamType.Evaluation);

  }

  @handle(SelectCurrentTeamsCategoryAction)
  private async handleSelectCurrentTeamsCategoryAction(action: SelectCurrentTeamsCategoryAction) {
    if (action.teamType != null) {
      const { filter, search, sort } = this.state.teamLists[action.teamType];
      await dispatch(new EnsureListAction(action.teamType, filter, search, sort, action.selectedCategory));
    } else {
      this.setState(state => ({
        ...state,
        selectedCategory: action.selectedCategory,
      }));
    }
  }

  @handle(TeamsCategorizeAction)
  private async handleTeamsCategorizeAction(
    action: TeamsCategorizeAction,
  ) {

    await this.teamRepo.putCategory(action.teamIds, action.category);

    action.teamIds.forEach(id => {
      const teams = this.getLoadedTeams(null, t => t.Id == id);
      teams.forEach(t => t.Category = action.category);
    });

    this.setState(s => s);
  }

  @handle(RemoveUsersFromTeams)
  private handleRemoveUsersFromTeams(action: RemoveUsersFromTeams) {
    const teams: Team[] = [];

    teams.push(...
      action.collabOnly
        ? this.getLoadedTeams(TeamType.Collaboration)
        : this.getLoadedTeams(null)
    );

    // apply
    for (const team of teams) {
      const leftOverMembers = team.Members.filter(m => action.userIds.every(au => au != m.UserId))
      const leftOverManagers = team.Managers.filter(m => action.userIds.every(au => au != m.UserId))

      team.Members = leftOverMembers;
      team.Managers = leftOverManagers;
    }

    this.setState(s => s);
  }

  @handle(EnsureTeamsAction)
  private async handleEnsureTeamsAction(action: EnsureTeamsAction) {
    if (action.teamIds == null) return;

    const idsToFetch = action.teamIds.filter(
      id => id != null &&
      this.state.teamHash[id] == null &&
      idsToLoad.indexOf(id) == -1 &&
      idsToLoadLoading.indexOf(id) == -1
    );

    if (idsToFetch.length == 0) {
      return;
    }

    if (idsToLoad.length > 0) {
      // push it on to this thing and return..
      idsToLoad.push(...idsToFetch);
      return
    }

    idsToLoad.push(...idsToFetch);

    await wait(10); // this is the buffer time, so that multiple components can all get in here
    idsToLoadLoading.push(...idsToLoad);
    idsToLoad = [];
    const res = await this.teamRepo.getIds(idsToLoadLoading);

    const newHash: { [id: string]: Team } = {};

    for (const team of res) {
      newHash[team.Id] = team;

      const idx = idsToLoadLoading.indexOf(team.Id);
      if (idx >= 0) idsToLoadLoading.splice(idx, 1);
    }

    this.state.teamHash = {
      ...this.state.teamHash,
      ...newHash,
    };
  }

  @handle(RefreshListAction)
  private async handleRefreshListAction(action: RefreshListAction) {

    this.state.teamLists[action.teamType].loading = true;

    this.setState(s => s);
    this.loadPage(action.teamType);
  }

  @handle(EnsureListAction)
  private async handleEnsureListAction(action: EnsureListAction) {
    const { filter, search, sort, loaded, selectedCategory } = this.state.teamLists[action.teamType];
    if (action.filter == filter && action.search == search && action.sort == sort && action.category == selectedCategory && loaded) return;

    this.state.teamLists[action.teamType] = {
      ...this.state.teamLists[action.teamType],
      filter: action.filter,
      search: action.search,
      sort: action.sort,
      selectedCategory: action.category,
      page: 1,
      loading: true
    };

    this.setState(s => s);

    this.loadPage(action.teamType);
  }

  @handle(NextPageAction)
  private async handleNextPageAction(action: NextPageAction) {
    this.state.teamLists[action.teamType].page++;
    this.state.teamLists[action.teamType].loading = true;
    this.setState(s => s);
    this.loadPage(action.teamType);
  }

  @handle(PrevPageAction)
  private async handlePrevPageAction(action: PrevPageAction) {
    this.state.teamLists[action.teamType].page = Math.max(0, this.state.teamLists[action.teamType].page - 1);
    this.state.teamLists[action.teamType].loading = true;
    this.setState(s => s);

    this.loadPage(action.teamType);
  }

  @handle(ToggleTeamsSortAction)
  private async handleToggleTeamsSortAction(ts: ToggleTeamsSortAction) {
    const { filter, search, sort } = this.state.teamLists[ts.teamType];
    const newSort = ts.sort == sort ? `-(${ts.sort})` : ts.sort;
    await dispatch(new EnsureListAction(ts.teamType, filter, search, newSort));
  }

  @handle(EnsureUserInsideTeamAction)
  private async handleReloadTeamStatsAction(action: EnsureUserInsideTeamAction) {
    

    const teamHash = this.state?.teamHash;
    const userId = action.userId;
    const userTeamIdHash = action.teamIds.reduce((acc,id) => { acc[id] = true; return acc; }, {});

    const userMember = new TeamMember();
    userMember.UserId = action.userId;

    for (let teamId in teamHash) {

      const team = teamHash[teamId];
      
      const shouldBeInTeam = userTeamIdHash[teamId] || false;
      const managerInTeam = team.Managers.find(m => m.UserId == userId);
      const memberInTeam = team.Members.find(m => m.UserId == userId);

      if (shouldBeInTeam && managerInTeam == null && memberInTeam == null) {
        // user should be in team, but isn't, so we'll add to members
        team.Members.push(userMember);
      }
      else if (!shouldBeInTeam && (managerInTeam != null || memberInTeam != null)) {
        // user should not be in team, but is, so remove
        team.Managers = team.Managers.filter(m => m.UserId !== userId);
        team.Members = team.Members.filter(m => m.UserId !== userId);
      }
    }

    this.state.teamHash = {
      ...this.state.teamHash,
      ...teamHash,
    };
    
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("Team"))
  private async handleWebSocketMessageAction(action: WebSocketMessageAction<EntityChanged>) {
    switch (action.data.changeType) {
      case ChangeType.Added:
      case ChangeType.Removed:
        await dispatch(new RefreshListAction(TeamType.Evaluation));
        await dispatch(new RefreshListAction(TeamType.Collaboration));
        break;
    } 
  }

  @handle(UpdateUserTeamsAction)
  private async handleUpdateUserTeamsAction(action: UpdateUserTeamsAction) {
    const team = this.state?.teamHash[action.team?.Id] || action.team;
    if (!team) return;

    const existing = team.Members.find(i => i.UserId === action.userId);
    if (action.type === "update" && !existing) {
      const userMembership = new TeamMember();
      userMembership.UserId = action.userId;
      team.Members.push(userMembership);
    } else if (action.type === "remove" && existing) {
      team.Members = team.Members.filter(m => m != existing);
    }

    this.state.teamHash[action.team.Id] = team;

    await dispatch(new UpdateUserTeamIdsAction(action.team.Id, action.userId, action.seasonId, action.type));
  }
}
