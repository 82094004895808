import { inject, prop } from "fw";
import { Form } from "fw-model";
import { preventLeadingSpaces } from "service/keyboard-press";
import { CurrentOrganizationStore } from "state/current-organization";

import moment from "moment";
import "moment-timezone";
import { DateService } from "service/date-service";

@inject
export class TimeFormField {
  @prop(null) private form!: Form;
  @prop(null) private field;
  @prop("MM/DD/YYYY") private format!: string;
  @prop("toISOString") private syncFormat!: string;
  @prop(true) private useTimezone!: boolean;
  @prop(null) private floatingLabel;
  @prop(null) private autocomplete;
  @prop(null) private label;
  @prop(false) public required: boolean;
  @prop(false) public disabled: boolean;

  private name = "";
  private formValue = "";
  private timezone = "America/Chicago";

  constructor(
    private currentOrganizationStore: CurrentOrganizationStore,
    private dateService: DateService) {
    this.timezone = this.currentOrganizationStore.state.organization.Timezone || this.timezone;
  }

  public attached() {
    this.formChanged();
  }

  onKeyEvent(e: KeyboardEvent) {
    preventLeadingSpaces(e, this.formValue);
  }

  private formChanged() {
    this.name = this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;

    if (this.form) {
      let date = moment(this.form[this.field], moment.ISO_8601);
      if (this.useTimezone) date = date.tz(this.timezone);
      this.formValue = date.isValid() ? date.format(this.format) : "";
    }
  }

  private valueChange() {
    const value = this.formValue.trim();
    const parsed = this.dateService.parseDateOnly(value);
    if (parsed) {
      const date = moment.tz(parsed, this.timezone);
      if (this.form && date.isValid()) {
        this.formValue = date.format(this.format);
        this.form[this.field] = this.syncFormat == "toISOString" ? date.toISOString() : date.format(this.syncFormat);
        return;
      }
    }

    this.form[this.field] = value;
  }
}
