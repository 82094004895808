
import { LocalStorageCache } from 'caching';
import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { once } from "helpers/once";
import {
  LogoutAction,
  StartAction,
  SelectedContactTypeChangedAction,
  ContactOrganizationModelChangedAction
} from "state/actions";

import { Contact, IDuplicateContactResult } from 'models/contact';
import { ContactOrganization, ContactTypeDefinition } from "models/contact-organization";
import { GridColumn } from 'models/grid-column';

import { ContactRepository } from "network/contact-repository";
import { IEntitySelection } from "network/ats";
import { ContactsService } from "service/contacts";

import { capitalize, throttle } from "lodash-es";
import { WebSocketMessageAction } from 'state/web-socket-message';
import { filterEntityChangedMessage } from 'state/filter-websocket-message';
import { FeatureFlagService } from "service/feature-flag";

interface PotentialDuplicateContactStoreShape {
  organization: ContactOrganization;
  userId: string;
  selectedContactType: ContactTypeDefinition;
  potentialDuplicates: IDuplicateContactResult[];
  selectedPotentialDuplicate: IDuplicateContactResult;
  contactsForSelectedPotentialDuplicate: Contact[];
  columns: GridColumn[];
  sort: string;
  loading: boolean;
}

export class EnsurePotentialDuplicateContactStoreAction {
  constructor() { }
}

export class RefreshPotentialDuplicateContactStoreAction {
  constructor() { }
}

export class PotentialDuplicateSelectedAction {
  constructor(
    public potentialDuplicate: IDuplicateContactResult
  ) { }
}

export class MergeDuplicateContactsAction {
  constructor(
    public contactIds: string[]
  ) { }
}

export class RefreshPotentialDuplicatesAction { }

export class PotentialDuplicatesGridColumnsChangedAction {
  constructor(
    public columns: GridColumn[]
  ) { }
}

export class TogglePotentialDuplicateContactsSortAction {
  constructor(
    public sort: string
  ) { }
}

@inject
export class PotentialDuplicateContactStore extends Store<PotentialDuplicateContactStoreShape> {
  public static DEFAULT_SORT: string = 'last first company';

  private throttledUpdate: Function = () => { };

  constructor(
    private contactRepo: ContactRepository,
    private cache: LocalStorageCache,
    private contactsService: ContactsService,
    private ffs: FeatureFlagService,
  ) {
    super();
    this.throttledUpdate = throttle(() => dispatch(new RefreshPotentialDuplicatesAction()), 60000);
  }

  get defaultColumns() {
    return this.contactsService
      ? this.contactsService.defaultGridColumns
      : [];
  }

  protected defaultState() {
    return {
      organization: null,
      userId: null,
      selectedContactType: null,
      potentialDuplicates: [],
      selectedPotentialDuplicate: null,
      contactsForSelectedPotentialDuplicate: null,
      columns: this.defaultColumns,
      sort: PotentialDuplicateContactStore.DEFAULT_SORT,
      loading: false
    };
  }

  @handle(StartAction)
  private async handleStartAction(action: StartAction) {

    this.setState(state => ({
      ...this.defaultState(),
      organization: action.context.ContactOrganization,
      userId: action.context.Me.Id
    }));

    // load previously selected contact type from user settings
    const contactTypeKey = action.context.UserSeasonSettings.Settings["contactsTypeSelected"];

    // load the previously configured columns from user settings
    const columns = action.context.UserSeasonSettings.Settings[`potentialDuplicatesGridColumns${capitalize(contactTypeKey)}`]

    // prepare the state for the selected contact type
    this.setContactType(contactTypeKey, columns);
  }

  @handle(ContactOrganizationModelChangedAction)
  async handleContactOrganizationModelChangedAction(action: ContactOrganizationModelChangedAction) {
    this.setState(state => ({
      ...state,
      organization: action.organization,
    }));
    this.setContactType(this.state.selectedContactType?.key, this.state.columns);
  }

  @handle(EnsurePotentialDuplicateContactStoreAction)
  private async handleEnsurePotentialDuplicateContactStoreAction(action: EnsurePotentialDuplicateContactStoreAction) {
    // when potential duplicates is null, we assume that we have not tried to load the potential duplicates yet
    if (this.state.potentialDuplicates.length === 0 && !this.state.loading) {
      this.setState(state => ({...state, loading: true }));
      await this.loadPotentialDuplicates();
      this.setState(state => ({...state, loading: false }));
    }
  }

  @handle(RefreshPotentialDuplicateContactStoreAction)
  private async handleRefreshPotentialDuplicateContactStoreAction(action: RefreshPotentialDuplicateContactStoreAction) {
    await this.loadPotentialDuplicates();
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.setState(s => this.defaultState());
  }

  // don't love that these stored potential duplicates-specific settings have to be pushed here from
  // from the user-settings store because it means there is a leak of potential duplicate concerns pushed outside
  // of this store.  It might be better if we instead have the user-setting store publish the entire settings object
  // and this store pulls the specific keys it needs from there.  That way, this store owns the name of those keys.
  @handle(SelectedContactTypeChangedAction)
  async handleSelectedContactTypeChangedAction(action: SelectedContactTypeChangedAction) {
    const { selectedContactType } = this.state;
    if (action.type == selectedContactType?.key)
      return;

    this.setContactType(action.type, action.dedupeColumns);
  }

  @handle(PotentialDuplicatesGridColumnsChangedAction)
  private async handlePotentialDuplicatesGridColumnsChangedAction(action: PotentialDuplicatesGridColumnsChangedAction) {
    this.setState(state => ({
      ...state,
      columns: action.columns
    }));
  }

  @handle(TogglePotentialDuplicateContactsSortAction)
  private async handleTogglePotentialDuplicateContactsSortAction(action: TogglePotentialDuplicateContactsSortAction) {
    // resolve desired sort
    const sort = (action.sort && action.sort == this.state.sort)
      ? `-(${action.sort})`
      : action.sort;

    // cache the sort
    const cacheKey = this.createSortCacheKey(this.state.organization.id, this.state.userId, this.state.selectedContactType?.key);
    this.cache.set<string>(cacheKey, sort);

    // update the state with the new sort
    this.setState(state => ({
      ...state,
      sort: sort,
    }));

    // reload the potential duplicate contacts
    await this.loadPotentialDuplicateContacts();
  }

  @handle(PotentialDuplicateSelectedAction)
  private async handlePotentialDuplicateSelectedAction(action: PotentialDuplicateSelectedAction) {
    this.setState(state => ({
      ...state,
      selectedPotentialDuplicate: action.potentialDuplicate,
      contactsForSelectedPotentialDuplicate: null,
    }));
    await this.loadPotentialDuplicateContacts();
  }

  @handle(MergeDuplicateContactsAction)
  private async handleMergeDuplicateContactsAction(action: MergeDuplicateContactsAction) {
    const args = <IEntitySelection>{ contact_type: this.state.selectedContactType?.key, ids: action.contactIds };
    await this.contactRepo.mergeSelection(args);
    await dispatch(new RefreshPotentialDuplicatesAction());
  }

  @handle(RefreshPotentialDuplicatesAction)
  private async handleRefreshPotentialDuplicatesAction(action: RefreshPotentialDuplicatesAction) {
    await this.loadPotentialDuplicates();
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("PersistentContact"))
  private async handleEntityChangedAction(action: WebSocketMessageAction) {
    this.throttledUpdate();
  }

  private createSortCacheKey(organizationId: string, userId: string, contactTypeKey: string): string {
    return `${organizationId}:${userId}-potential-duplicates-${contactTypeKey}-sort`;
  }

  private async setContactType(contactTypeKey:string, columns:GridColumn[]) {

    // match the contact type key to the actual contact type to ensure it's valid
    const contactType: ContactTypeDefinition = this.state.organization.contact_types.find(t => t.key === contactTypeKey);

    // if the contact type is valid, set the state to the selected contact type and settings
    // reset the potential duplicates and selected potential duplicate to null so that
    // they won't be populated until the ensure action is called

    if (contactType != null) {
      const cacheKey = this.createSortCacheKey(this.state.organization.id, this.state.userId, contactTypeKey);
      this.setState(state => ({
        ...state,
        selectedContactType: contactType,
        sort: this.cache.get<string>(cacheKey) || state.sort,
        columns: columns || this.defaultColumns,
        potentialDuplicates: [],
        selectedPotentialDuplicate: null,
        contactsForSelectedPotentialDuplicate: null
      }));
    } else {
      this.setState(state => ({
        ...state,
        selectedContactType: null,
        sort: PotentialDuplicateContactStore.DEFAULT_SORT,
        columns: this.defaultColumns,
        potentialDuplicates: [],
        selectedPotentialDuplicate: null,
        contactsForSelectedPotentialDuplicate: null,
      }));
    }
  }

  private async loadPotentialDuplicates() {

    const { selectedContactType } = this.state;
    let { selectedPotentialDuplicate } = this.state;

    if (!selectedContactType)
      return;

    // get fresh list of potential duplicates
    const potentialDuplicates = await this.contactRepo.listDuplicates(selectedContactType.key);

    // ensure that previously selected potential duplicate is still in the list
    selectedPotentialDuplicate = selectedPotentialDuplicate
      ? potentialDuplicates.find(duplicate => duplicate.matching_key == selectedPotentialDuplicate.matching_key)
      : null;

    // if it's not, select the first one in the list
    if (!selectedPotentialDuplicate && potentialDuplicates.length > 0) {
      selectedPotentialDuplicate = potentialDuplicates[0];
    }

    this.setState(state => ({
      ...state,
      potentialDuplicates,
      selectedPotentialDuplicate,
      contactsForSelectedPotentialDuplicate: null
    }));

    await this.loadPotentialDuplicateContacts();
  }

  private async loadPotentialDuplicateContacts() {
    const { selectedContactType: selectedContactType, selectedPotentialDuplicate, sort } = this.state;
    if (!selectedPotentialDuplicate) return;
    
    const queryString = `${selectedPotentialDuplicate.field}:"${selectedPotentialDuplicate.matching_key}"`;
    const f = !selectedContactType.key ? null : `type:${selectedContactType.key}`;
    const contacts = await this.contactRepo.list(queryString, f, null, sort, 1, selectedPotentialDuplicate.total, selectedContactType.key);
    this.setState(state => ({
      ...state,
      contactsForSelectedPotentialDuplicate: contacts.results
    }));
  }
}
