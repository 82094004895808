import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { LocalStorageCache } from "caching";

import { AtsConfig } from "config/ats-config";
import { ATS } from "./ats";
import { encodePdfExportTemplateSet, encodePortalPreviewArgs, encodeEmailTemplate } from "./encode";
import { ExternalData } from "models/content-template";
import { EmailTemplate, EmailTemplatePreview } from "models/email-template";

import { ApplicationContextCacheService } from "service/application-context-cache-service";
import { AppOrganizationContext } from "models/app-organization-context";
import { AppApplicationContext } from "models/app-application-context";
import { ApplicationClientModel, EntitySelection } from "models/application-client-model";
import {
  ReportChartData,
  WidgetDefinition,
  FieldGroupWithData,
  FieldGroup,
} from "shared/report-runtime";
import { AppOrganizationPortalContext } from "models/app-organization-portal-context";
import { ExportHtmlResult, PdfExportTemplateSet } from "models/pdf-export-template";

import { PortalPreviewArgs, PortalPreviewResult } from "models/app-portal-preview";
import { InviteContext } from "models/portal-invitation";
import { OrganizationPortal } from "models/organization";

export enum ContextType{
  direct = "direct",
  collab ="collab",
}

@inject
export class AppRepository {
  constructor(
    private s: ATS,
    private localStorageCache: LocalStorageCache,
    private config: AtsConfig,
    private appContextCache: ApplicationContextCacheService,
  ) {}

  public async handleError(domain: string, customDomainContentBaseUrl?: string) {
    try {
      const res = await fetch(`${customDomainContentBaseUrl}/${domain}/organization-portal.json`);
      const defaultPortalContext = new AppOrganizationPortalContext();

      const organizationPortal = await res.json() as OrganizationPortal;

      return createFrom(AppOrganizationPortalContext, {
        ...defaultPortalContext,
        OrganizationPortal: {
          ...new OrganizationPortal(),
          ...organizationPortal,
        },
        CustomDomainContentBaseUrl: customDomainContentBaseUrl,
      });
    } catch {
      return null;
    }
  }

  public async organizationPortalContext(domain: string) {
    try {
      const res = await this.s.get(`app/organization-portal/portal-context/${domain}/`);

      const context = createFrom(AppOrganizationPortalContext, res.body);

      this.localStorageCache.set("ats-portal-id", context?.OrganizationPortal.Id);

      return context;
    } catch (err) {
      if (err.statusCode === 404 && err.result?.Data?.CustomDomainContentBaseUrl) {
        this.localStorageCache.set(
          "custom-domain-content-base-url",
          err.result?.Data?.CustomDomainContentBaseUrl
        );
        this.localStorageCache.set("domain", domain);

        return this.handleError(domain, err.result?.Data?.CustomDomainContentBaseUrl);
      }

      return null;
    }
  }

  public async organizationContext() {
    const res = await this.s.get<AppOrganizationContext>("app/organization-portal/organization-context");
    const context = createFrom(AppOrganizationContext, res.body);

    if (!this.config.contactsApiUrlSticky) {
      this.config.contactsApiUrl = context.SystemConfig.ContactsBaseApiUrl + "/api/v1/";
    } else {
      console.warn(
        `not using contactsApiUrl from server context! (check contactsApiUrlSticky to change this)`
      );
    }

    if (!this.config.marketingApiUrlSticky) {
      const marketingBaseApiUrl = context.SystemConfig.MarketingBaseApiUrl?.trim();
      this.config.marketingApiUrl = marketingBaseApiUrl ? `${marketingBaseApiUrl}/` : "";
    } else {
      console.warn(
        `not using marketingApiUrl from server context! (check marketingApiUrlSticky to change this)`
      );
    }

    if (!this.config.marketingTrackApiUrlSticky) {
      const marketingTrackApiUrl = context.SystemConfig.MarketingTrackApiUrl?.trim();
      this.config.marketingTrackApiUrl = marketingTrackApiUrl ? `${marketingTrackApiUrl}/` : "";
    } else {
      console.warn(
        `not using marketingTrackApiUrl from server context! (check marketingTrackApiUrlSticky to change this)`
      );
    }

    return context;
  }

  public async applicationContext(id: string) {
    const cached = this.appContextCache.getContext(id);
    if (cached != null) return cached;

    const res = await this.s.get(`app/organization-portal/application-context/${id}`);
    const context = createFrom(AppApplicationContext, res.body);

    this.appContextCache.setContext(context);
    return context;
  }

  public async applicationList(ids: string[]) {
    let notCachedIds: string[] = [];
    let cachedResults: AppApplicationContext[] = [];

    ids.forEach((id) => {
      const cachedVal = this.appContextCache.getContext(id);

      if (cachedVal == null) {
        notCachedIds.push(id);
      } else {
        cachedResults.push(cachedVal);
      }
    });

    let nonCachedResults: AppApplicationContext[] = [];

    if (notCachedIds.length > 0) {
      const res = await this.s.get<any[]>(
        `app/organization-portal/application-list/${notCachedIds.join(",")}`
      );
      nonCachedResults = createFromArray(AppApplicationContext, res.body);
      nonCachedResults.forEach((res) => {
        this.appContextCache.setContext(res);
      });
    }

    // now to merge them
    const results: AppApplicationContext[] = [];
    ids.forEach((id) => {
      // look in cached, then in noncached

      results.push(
        cachedResults.find((i) => i.Application.Id == id) ||
          nonCachedResults.find((i) => i.Application.Id == id)
      );
    });

    return results;
  }

  public async clientModelList(ids: string[]) {
    let notCachedIds: string[] = [];
    let cachedResults: ApplicationClientModel[] = [];

    ids.forEach((id) => {
      const cachedVal = this.appContextCache.getClientModel(id);

      if (cachedVal == null) {
        notCachedIds.push(id);
      } else {
        cachedResults.push(cachedVal);
      }
    });

    let nonCachedResults: ApplicationClientModel[] = [];

    if (notCachedIds.length > 0) {
      const res = await this.s.post<ApplicationClientModel[]>(
        "app/organization-portal/client-model-list",
        notCachedIds
      );
      nonCachedResults = res.body;
      res.body.forEach((res) => {
        this.appContextCache.setClientModel(res);
      });
    }

    // now to merge them
    const results: ApplicationClientModel[] = [];
    ids.forEach((id) => {
      // look in cached, then in noncached

      results.push(cachedResults.find((i) => i.id == id) || nonCachedResults.find((i) => i.id == id));
    });

    return results;
  }

  public async previewFieldGroupReportChartData(reportFilter: string, fieldGroups: FieldGroup[]) {
    const res = await this.s.post<any[]>("app/organization-portal/field-group-chart-preview", fieldGroups, {
      reportFilter,
    });
    return createFromArray(FieldGroupWithData, res.body);
  }

  public async previewReportChartData(reportFilter: string, chartDefs: WidgetDefinition[]) {
    const res = await this.s.post<any[]>("app/organization-portal/chart-preview", chartDefs, {
      reportFilter,
    });
    return createFromArray(ReportChartData, res.body);
  }

  async previewEmailTemplate(template: EmailTemplate, applicationId: string) {
    const encoded = encodeEmailTemplate(template);
    const res = await this.s.post("app/organization-portal/email-template-preview", encoded, {
      applicationId,
    });

    return createFrom(EmailTemplatePreview, res.body);
  }

  async previewContentTemplate(args: ContentPreviewArgs, applicationId: string) {
    const res = await this.s.post<ContentPreviewResult>(
      "app/organization-portal/content-preview",
      <ContentPreviewArgs>{
        ...args,
        Template: encodeURIComponent(args.Template),
      },
      { applicationId }
    );
    return res.body;
  }

  async previewJsExpression(args: JsExpressionPreviewArgs) {
    const res = await this.s.post<JsExpressionPreviewResult>(
      "app/organization-portal/js-expression-preview",
      <JsExpressionPreviewArgs>{
        ...args,
        Expression: encodeURIComponent(args.Expression),
      }
    );
    return res.body;
  }

  async pdfExportPreview(templateSet: PdfExportTemplateSet, applicationId: string) {
    const res = await this.s.post(
      "app/organization-portal/pdf-export-preview",
      encodePdfExportTemplateSet(templateSet),
      { applicationId }
    );
    return createFrom(ExportHtmlResult, res.body);
  }

  async portalPreview(portalArgs: PortalPreviewArgs) {
    const res = await this.s.post(
      "app/organization-portal/portal-preview",
      encodePortalPreviewArgs(portalArgs)
    );
    return createFrom(PortalPreviewResult, res.body);
  }

  public async collaborationModuleAssignmentContext(moduleId: string, selection: EntitySelection) {
    const res = await this.s.post<{ [userId: string]: number }>(
      `app/organization-portal/collaboration-assignment-context/${moduleId}`,
      selection
    );

    return res.body;
  }

  public async directAssignmentContext(phaseId: string, selection: EntitySelection) {
    const res = await this.s.post<{ [userId: string]: number }>(
      `app/organization-portal/phase-direct-assignment-context/${phaseId}`,
      selection
    );

    return res.body;
  }

  public async tagsContext(selection: EntitySelection) {
    const res = await this.s.post<{ [tag: string]: number }>(
      "app/organization-portal/application-tags-context",
      selection
    );
    return res.body;
  }

  async applicationPropertyUsage(props: string[]) {
    const res = await this.s.post<{ [propName: string]: boolean }>(
      "app/organization-portal/application-property-usage-profile",
      props
    );
    return res.body;
  }

  async resolvedDashboardId() {
    const res = await this.s.get<string>("app/organization-portal/resolved-dashboard");
    return res.body;
  }

  async inviteContext(token: string) {
    const res = await this.s.get(`app/organization-portal/invite-context/${token}`);
    return createFrom(InviteContext, res.body);
  }

  public async allowedUserOrganizations() {
    const res = await this.s.get<UserOrganizations>(`app/organization-portal/allowed-user-organizations`);
    return res.body;
  }

  public async exchangeLoginTicket(ticket: string) {
    const res = await this.s.get<TicketResult>(`app/organization-portal/exchange-login-ticket/${ticket}`);
    return res.body;
  }

  public async linkTransferPlanner() {
    const res = await this.s.get<TransferPlanerResult>(`app/organization-portal/oo/csu-program-sso`);
    return res.body;
  }

  public async linkImpersonateTransferPlanner(contactId: string) {
    const res = await this.s.get<TransferPlanerImpersonateResult>(
      `app/organization-portal/oo/tp-impersonate?contactId=${contactId}`
    );
    return res.body;
  }
}

export interface UserOrganizations {
  OrganizationWithDomainViews: OrganizationWithDomainView[];
}

export interface OrganizationWithDomainView {
  OrganizationId: string;
  Name: string;
  Domain: string;
}

export interface ContentPreviewArgs {
  Template: string;
  ExternalData?: ExternalData[];
  TransformFunction?: string;
}

export interface ContentPreviewResult {
  TemplateData: any;
  Result: string;
}

export interface JsExpressionPreviewArgs {
  Expression: string;
  Context: object;
}

export interface JsExpressionPreviewResult {
  Result: object;
}

export interface TicketResult {
  BearerToken: string;
  ImpersonationBearerToken: string;
}

export interface TransferPlanerResult {
  SsoUrl: string;
}

export interface TransferPlanerImpersonateResult {
  ImpersonateUrl: string;
}
