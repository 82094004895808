import { inject, prop, ComponentEventBus } from "fw";

import { ReportChartDefinitionClientData } from "shared/report-runtime";

@inject
export class TableOptions {
  @prop(null) clientData!: string;

  constructor(private ceb: ComponentEventBus) { }

  private options = {
    heatMap: true,
  };
  private $emit: any;

  attached() {
    if (this.clientData == null) {
      this.clientData = "{}";
    }
    this.clientDataChanged();
  }

  clientDataChanged() {
    if (this.clientData == null) return;

    const options = JSON.parse(this.clientData || "{}") as ReportChartDefinitionClientData;
    if (options.tableOptions == null)
      options.tableOptions = { heatmap: true };

    this.options.heatMap = options.tableOptions.heatmap;
  }

  toggleHeatmap() {
    const options = JSON.parse(this.clientData) as ReportChartDefinitionClientData;
    if (options.tableOptions == null)
      options.tableOptions = { heatmap: true };

    options.tableOptions.heatmap = !this.options.heatMap;

    this.$emit('update:clientData', JSON.stringify(options));
  }

}
