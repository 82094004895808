import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ApplicantSettings } from "models/applicant-settings";

import { ATS } from "./ats";

@inject
export class ApplicantSettingsRepository {
  constructor(private s: ATS) {}

  public async put(settings: ApplicantSettings) {
    const res = await this.s.put<ApplicantSettings>(
      `applicant-settings`,
      settings,
    );
    return createFrom(ApplicantSettings, res.body);
  }
}
