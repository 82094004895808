import { inject, prop, ComponentEventBus } from "fw";

import { ContactsActivityGroupFilter as ContactsActivityGroupFilterModel } from "models/contacts-filters";
import { TimingFilterType } from "models/filter-timing";

@inject
export class ContactsActivityGroupFilter {
  @prop(null)
  filter: ContactsActivityGroupFilterModel;

  @prop(false)
  editing: boolean;

  constructor(private ceb: ComponentEventBus) { }

  public get timingFilterType() {
    return TimingFilterType;
  }

  public get proxyFilter() {
    return this.filter;
  }

  public set proxyFilter(val) {
    this.ceb.dispatch("update:filter", val);
  }

  public strDays(numDays) {
    switch (numDays) {
      case 0:
        return "today";
      case 1:
        return "yesterday";
      default:
        return numDays + " days ago";
    }
  }
}
