import { prop, needs, inject, ComponentEventBus } from "fw";
import type { ConditionExpressionFormType } from "forms/condition-info";
import { Completer } from "helpers/auto-complete";
import { CodeEditorFormField } from "./form/code-editor-form-field";

@inject
@needs(CodeEditorFormField)
export class ConditionBuilderExpression {
  @prop(null) public condition: ConditionExpressionFormType;
  @prop(null) public completer: Completer | Completer[];
  @prop(false) public disabled: boolean;
  @prop(true) public canDelete: boolean;
  @prop(true) public showFunctionSelector: boolean;
  @prop(true) public showFieldDictionaryLink: boolean;

  constructor(private ceb: ComponentEventBus) { }

  public deleteSelf() {
    this.ceb.dispatch("delete-condition");
  };

  public onChanged() {
    this.ceb.dispatch("change");
  }
}
