import { inject, prop } from "fw";
import { CurrentContactOrganizationStore } from "../../../state/current-contact-organization";
import {
  ContactTypeDefinition,
  ContactOrganization,
} from "../../../models/contact-organization";
import { getContrastingColor } from "helpers/accessibility";

@inject
export class ContactTypeName {
  @prop(null) public contactType!: string;
  @prop(null) public contactTypes!: ContactTypeDefinition[];
  @prop(false) public isPlural!: boolean;
  @prop(false) public useColor!: boolean;
  @prop("point") viewMode: "point" | "fill";

  constructor(
    private currentContactOrganizationStore: CurrentContactOrganizationStore
  ) {}

  public get name(): string {
    const ct = this.definition;
    if (!ct) {
      return this.contactType;
    }

    return this.isPlural ? ct.plural_name : ct.name;
  }

  public get color(): string {
    return this.useColor && this.definition?.color
      ? `#${this.definition?.color}`
      : "inherit";
  }

  public get fillColor(): string {
    return this.viewMode == "point" ? "inherit" : this.color;
  }

  public get textColor(): string {
    return this.viewMode == "point" || this.color == "inherit"
      ? "inherit"
      : getContrastingColor(this.color);
  }

  private get definition(): ContactTypeDefinition {
    const contactTypes: ContactTypeDefinition[] =
      this.contactTypes != null
        ? this.contactTypes
        : this.organization.contact_types;
    return contactTypes.find((ct) => ct.key === this.contactType);
  }

  private get organization(): ContactOrganization {
    return this.currentContactOrganizationStore.state.organization;
  }
}
