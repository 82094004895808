import { fromClass, fromClassArray } from "fw-model";
import { CalculatedFieldDataTypeCode, WeightedScoreSettings } from "./calculated-field";
import { ConditionInfo } from "./form";

export enum CustomPropertyTypeCode {
  Standard = 0,
  Calculated = 1,
  System = 2,
}

export class CustomPropertyEnumerationOption {
  Id: string;
  Label: string = null;
  ColorHexCode: string = null;
}

export class CustomProperty {
  Label: string = null;
  Key: string = null;
  Expression: string = null;
  @fromClass WeightedScoreSettings: WeightedScoreSettings;
  @fromClass BooleanExpressionInfo: ConditionInfo;
  DataType: CalculatedFieldDataTypeCode = CalculatedFieldDataTypeCode.Number;
  PropertyType = CustomPropertyTypeCode.Standard;

  @fromClassArray(CustomPropertyEnumerationOption)
  EnumerationOptions: CustomPropertyEnumerationOption[];
}
