import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { DateFormatter } from "../../date-formatter";
import { type WidgetContext, RegistrationStatusCountsWidgetData } from "../../models";

@inject
export class RegistrationStatusCountsChart {
  @prop(null) clientData!: string;
  @prop(null) data!: RegistrationStatusCountsWidgetData;
  @prop(null) context!: WidgetContext;
  @prop(false) interactive!: boolean;

  private dateFormatter: DateFormatter = new DateFormatter();

  constructor(
    private ceb: ComponentEventBus,
  ) { }

  attached() {
    this.dataChanged();
  }

  get headers() {
    return this.data?.Definition?.Columns || [];
  }
  get rows() {
    return this.data?.Rows || [];
  }

  dataChanged() {
    if (this.data == null) return;
  }

  format(column) {
    switch (column.Type) {
      case "date":
        return this.dateFormatter.formatDate(column.Value, {
          fromNow: false,
          dateOnly: true,
          useTimezone: true,
          timezone: this.data.Timezone,
        });
      case "datetime":
        return this.dateFormatter.formatDate(column.Value, {
          format: "MMM D, YYYY h:mma",
          useTimezone: true,
          timezone: this.data.Timezone,
        });
      default:
        return column.Value;
    }
  }

  dataClick(row) {
    if (!this.interactive || row.Data == null) return;
    this.ceb.dispatch("data-click", row.Data);
  }
}
