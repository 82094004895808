import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { ATS, IListResults } from "./ats";

import { CalendarEventType } from "models/calendar-event";

@inject
export class CalendarEventTypeRepository {
  constructor(private network: ATS) {}

  async index() {
    const res = await this.network.contacts.get<CalendarEventType[]>("calendar-event-types");
    return createFromArray(CalendarEventType, res.body);
  }

  async list(
    q: string = null,
    f: string = null,
    aggs: string = null,
    sort: string = null,
    page: number = 1,
    limit: number = 10
  ): Promise<IListResults<CalendarEventType>> {
    return this.network.contacts.list("calendar-event-types", CalendarEventType, q, f, aggs, sort, page, limit);
  }

  async show(id: string) {
    const res = await this.network.contacts.get<CalendarEventType>(`calendar-event-types/${id}`);
    return createFrom(CalendarEventType, res.body);
  }

  async post(eventType: CalendarEventType) {
    const res = await this.network.contacts.post<CalendarEventType>("calendar-event-types", eventType);
    return createFrom(CalendarEventType, res.body);
  }

  async put(eventType: CalendarEventType) {
    const res = await this.network.contacts.put<CalendarEventType>(`calendar-event-types/${eventType.id}`, eventType);
    return createFrom(CalendarEventType, res.body);
  }

  async destroy(ids: string | string[], all: boolean = false, search: string = "") {
    const idsStr = Array.isArray(ids) ? ids.join() : ids;

    if (!all && !search)
      await this.network.contacts.delete(`calendar-event-types/${idsStr}`);
    else
      await this.network.contacts.post("calendar-event-types/delete", {
        ids: idsStr,
        all: all,
        search: search
      });
  }
}
