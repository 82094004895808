import { prop, provided } from "fw";
import { type RegisterStepFn, type HasNextFn, type HasPrevFn } from "./steps";

export class Step {
  @prop(null) public previous!: () => Promise<boolean|undefined>;
  @prop(null) public next!: () => Promise<boolean | undefined>;
  @prop(null) public onEnter!: () => Promise<boolean | undefined>;
  @prop(null) public onEnterError!: (error: Error) => Promise<string | undefined>;
  @prop(null) public onReset!: () => Promise<void>;
  @prop(false) public isHiddenControl: boolean;

  @provided(undefined) public cancel: () => Promise<void>;
  @provided(undefined) public done: () => Promise<void>;
  @provided(undefined) public reset: () => Promise<void>;
  @provided(undefined) public registerStep: RegisterStepFn;
  @provided(undefined) public removeStep: RegisterStepFn;
  @provided(undefined) public hasPrev: HasPrevFn;
  @provided(undefined) public previousStep: () => Promise<void>;
  @provided(undefined) public hasNext: HasNextFn;
  @provided(undefined) public nextStep: () => Promise<void>;
  @provided(undefined) public isControlsFixed!: boolean;
  @provided(undefined) public showNav!: boolean;
  @provided(undefined) public showNavButton!: boolean;

  public isOpen: boolean = false;
  public isHidden: boolean = false;
  public loading: boolean = false;
  public isActivated: boolean = false;
  public enterError: string = null;

  constructor() { }

  public async attached() {
    await this.registerStep(this);
  }

  public async detached() {
    await this.removeStep(this);
  }

  public async onResetStep() {
    if (this.onReset) {
      try {
        await this.onReset();
      } catch (ex) {
        console.log(ex);
      }
    }
  }

  public async onEnterStep() {
    this.enterError = null;

    if (this.onEnter) {
      this.loading = true;
      try {
        await this.onEnter();
      } catch (ex) {
        console.log(ex);
        const message: string = await this.onEnterStepError(ex);
        this.enterError = message || "An error occurred, please try again.";
      } finally {
        this.loading = false;
        this.isActivated = true;
      }
    }

    this.isActivated = true;
  }

  public async onEnterStepError(error: Error): Promise<string> {
    if (this.onEnterError) {
      try {
        return await this.onEnterError(error) || null;
      } catch (ex) {
        console.log(ex);
      }
    }

    return null;
  }

  public async onPrevious() {
    if (!this.hasPrev()) {
      return;
    }

    if (this.previous) {
      try {
        if (await this.previous() === false) {
          return;
        }
      } catch (ex) {
        console.log(ex);
        return;
      }
    }

    await this.previousStep();
  }

  public async onNext() {
    if (this.next) {
      try {
        this.loading = true;
        if (await this.next() === false) {
          return;
        }
      } catch (ex) {
        console.log(ex);
        return;
      } finally {
        this.loading = false;
      }
    }

    if (this.hasNext()) {
      await this.nextStep();
    } else if (this.done) {
      try {
        this.loading = true;
        await this.done();
      } catch (ex) {
        console.log(ex);
        return;
      } finally {
        this.loading = false;
      }
    }
  }

  public async onCancel() {
    if (this.cancel) {
      try {
        await this.cancel();
      } catch (ex) {
        console.log(ex);
        return;
      }
    }
  }
}
