import { inject } from "fw";
import { createFrom, createFromArray, cloneOf } from "fw-model";

import { ATS } from "./ats";
import { ProgramProperty } from "models/program-settings";

@inject
export class ProgramSettingsRepository {
  constructor(private s: ATS) { }

  async putProperties(properties: ProgramProperty[]) {
    const res = await this.s.put<ProgramProperty[]>(
      "program-settings/properties",
      properties
    );
    return createFromArray(ProgramProperty, res.body);
  }

}
