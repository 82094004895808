import { fromClass, fromClassArray } from "fw-model";

export class InquiryPortalMetaData {
  OrganizationId: string = null;
  SeasonId: string = null;
  ScopedCss: string = null;
}

export class InquiryPortalForm {
  FormId: string = null;
  PageSlug = "";
}

export class InquiryPortalContent {
  HeaderHtml: string = null;
  FooterHtml: string = null;
  HomeHtml: string = null;
  ConfirmationHtml: string = null;
  Css: string = null;
  GlobalCss: string = null;
  FavIconUrl: string = null;
  SupportEmailAddress: string = null;
}

export class InquiryPortal {
  Id: string = null;
  Name: string = null;
  Domain: string = null;

  @fromClassArray(InquiryPortalForm) Forms: InquiryPortalForm[];
  @fromClass Content: InquiryPortalContent;
  @fromClass MetaData: InquiryPortalMetaData;
}

