import { inject, ComponentEventBus, NetworkException, prop } from "fw";
import { dispatch } from "fw-state";

import { LoginForm as LoginFormModel } from "forms/login";
import { CurrentOrganizationStore } from "state/current-organization";

import { AccountLockOutType } from "models/user";
import moment from "moment";
import { LocalStorageCache } from "caching";

@inject
export class LoginForm {
  @prop("") emailAddress!: string;
  @prop(null) token!: string;
  @prop("") redirectTo!: string;

  loginForm: LoginFormModel = null;
  loggingIn = false;
  showTwoFactor = false;
  hideValidation = false;
  private lockOutData: { lockOutType: AccountLockOutType; seconds: number; message: string } = null;

  constructor(
    private orgStore: CurrentOrganizationStore,
    private ceb: ComponentEventBus,
    private cache: LocalStorageCache
  ) {}

  created() {
    const { portalContext } = this.orgStore.state;
    const orgId = portalContext != null ? portalContext.OrganizationId : null;

    this.loginForm = new LoginFormModel(this.token, orgId, this.redirectTo);
    this.loginForm.EmailAddress = this.emailAddress;
  }

  get temporaryLockoutTime() {
    if (this.lockOutData == null) return null;
    if (this.lockOutData.lockOutType == AccountLockOutType.Temporary) {
      return moment.duration(this.lockOutData.seconds, "seconds").humanize();
    }

    return null;
  }

  get lockMessage() {
    if (this.lockOutData == null) return null;
    return this.lockOutData.message || null;
  }

  get resetRequired() {
    if (this.lockOutData == null) return false;
    return this.lockOutData.lockOutType == AccountLockOutType.ResetRequired;
  }

  get currentYear() {
    const d = new Date();
    return d.getFullYear();
  }

  private async login() {
    this.loginForm.EmailAddress = this.loginForm.EmailAddress?.trim();
    this.loggingIn = true;
    this.lockOutData = null;
    this.cache.remove("use-organization-switcher");

    try {
      await dispatch(this.loginForm);

      this.ceb.dispatch("logged-in", true);
    } catch (err) {
      if (err instanceof NetworkException) {
        if (err.result?.data == "MFA") {
          this.hideValidation = true;
          this.showTwoFactor = true;

          this.ceb.dispatch("two-factor", true);

          this.loggingIn = false;
          return;
        }

        this.hideValidation = false;

        switch (err.result?.Code) {
          case "NOTFOUND":
            this.loginForm.validationMessages = ["The email address or password you provided is incorrect"];
            this.loginForm.isInvalid = true;
            break;

          case "FORBIDDEN":
            if (err.result?.lockOutType != null) {
              this.lockOutData = err.result.Data;
            }
            this.loginForm.validationMessages = [`${err.result.Message}`];
            this.loginForm.isInvalid = true;

            break;
        }
      }
    }

    this.loggingIn = false;
  }
}
