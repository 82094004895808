import { inject } from "fw";
import { CurrentUserStore } from "state/current-user";

@inject
export class PrincipalAvatar {
  constructor(private currentUserStore: CurrentUserStore) { }

  get avatarObject() {
    if (this.currentUserStore.state.user?.Id != null) {
      return this.currentUserStore.state.user;
    } else {
      return this.currentUserStore.state.principal;
    }
  }

  get name() {
    if (!this.currentUserStore.state.loggedIn)
      return null;

    return this.avatarObject?.FirstName + ' ' + this.avatarObject?.LastName;
  }
}
