import { ComponentEventBus, inject, prop } from "fw";
import { dispatch } from 'fw-state';

import { PopoverService } from "service/popover";
import type { FormForGoalsDefinition } from "forms/report-definition";
import { GoalsStore, LoadGoalsAction } from 'state/goals';
import { SelectGoalsPopover, SelectGoalsPopoverArgs } from '../charts/select-goals-popover';
import { GoalType } from "models/goal";
import { ChartDataSource } from "shared/report-runtime";

let inputIdIterator: number = 0;

@inject
export class GoalsEditor {
  @prop(null) widgetDefinitionForm: FormForGoalsDefinition;
  private goalsEditorBaseInputId: string = "goals-editor-input";
  private goalsEditorPopoverId: string = "goals-editor-popover";
  private popoverIsOpen: boolean = false;

  constructor(
    private ceb: ComponentEventBus,
    private popoverService: PopoverService,
    private goalsStore: GoalsStore
  ) { }

  public async attached() {
    await dispatch(new LoadGoalsAction());
  }

  get goalCount() {
    if (this.widgetDefinitionForm == null || this.widgetDefinitionForm.GoalIds == null)
      return 0;

    return this.widgetDefinitionForm.GoalIds.length;
  }

  get isValid() {
    return this.widgetDefinitionForm && this.widgetDefinitionForm.validation && this.widgetDefinitionForm.validation['GoalIds'];
  }

  get goalsEditorInputId() {
    inputIdIterator++;
    return `${this.goalsEditorBaseInputId}-${inputIdIterator}`;
  }

  private async selectGoals() {
    this.popoverIsOpen = true;
    const res = await this.popoverService.open<string[]>(
      SelectGoalsPopover,
      <SelectGoalsPopoverArgs>{
        selectedGoalIds: this.widgetDefinitionForm.GoalIds,
        goalType: this.widgetDefinitionForm.DataSource === ChartDataSource.Admissions ? GoalType.Application : GoalType.Contact,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.goalsEditorPopoverId },
    );
    this.popoverIsOpen = false;

    if (res.canceled) {
      return;
    }

    this.widgetDefinitionForm.GoalIds = res.result;

    this.chartChanged();
  }

  private chartChanged() {
    this.ceb.dispatch("changed");
  }
}
