import { inject } from "fw";
import { dispatch, Store, handle } from "fw-state";
import { capitalize } from "lodash-es";

import { GroupFilter } from "models/filter-setup";
import { GridColumn } from "models/grid-column";
import { UserSeasonSettings } from "models/user-season-settings";
import { UpdateUserNotificationSettingsAction } from "forms/user";
import { UserRepository } from "network/user-repository";
import {
  StartAction,
  LogoutAction,
  SelectedContactTypeChangedAction,
  SelectedContactTypeFilterChangedAction
} from "./actions";
import { ContactsGridColumnsChangedAction } from "./contacts";
import { ContactTypeUpdatedAction } from './current-contact-organization';
import { PotentialDuplicatesGridColumnsChangedAction } from './potential-duplicate-contacts';
import { ContactsFilter } from 'service/contacts-filter';
import { ContactsService } from "service/contacts";
import { FeatureFlagService } from "service/feature-flag";

interface CurrentUserSettingsShape {
  organizationId: string;
  userId: string;
  seasonId: string;
  userSeasonSettings: UserSeasonSettings;
}

export class SetGridColumnsAction {
  constructor(public columns: GridColumn[], public selectedContactType: string) { }
}

export class SetPotentialDuplicatesGridColumnsAction {
  constructor(public columns: GridColumn[], public selectedContactType: string) { }
}

export class SetSelectedContactTypeAction {
  constructor(public type: string, public filter: GroupFilter, public segmentId?: string) { }
}

export class SetSeasonSettingAction {
  constructor(public key: string, public value: any) { }
}

export class SetSeasonSettingsAction {
  constructor(public patchSet: { [key: string]: any }) { }
}

export class SetCurrentSelectedContactTypeFilterAction {
  constructor(public filter: GroupFilter) { }
}

@inject
export class CurrentUserSettingsStore extends Store<CurrentUserSettingsShape> {
  constructor(
    private userRepo: UserRepository,
    private contactsFilter: ContactsFilter,
    private contactsService: ContactsService,
    private ffs: FeatureFlagService,
  ) {
    super();
  }


  defaultState() {
    return {
      organizationId: null,
      userId: null,
      seasonId: null,
      userSeasonSettings: null,
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    this.setState(state => ({
      organizationId: s.context.Organization.Id,
      userId: s.context.Me.Id,
      seasonId: s.context.Season.Id,
      userSeasonSettings: s.context.UserSeasonSettings,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(SetGridColumnsAction)
  private async handleSetGridColumnsAction(action: SetGridColumnsAction) {
    let value = null;

    if (!action.selectedContactType)
      return;
    value = `contactsGridColumns${capitalize(action.selectedContactType)}`;

    await this.saveSeasonSetting(this.state.seasonId, value, action.columns);
    await dispatch(new ContactsGridColumnsChangedAction(action.columns));
  }

  @handle(SetPotentialDuplicatesGridColumnsAction)
  private async handleSetPotentialDuplicatesGridColumnsAction(action: SetPotentialDuplicatesGridColumnsAction) {
    await this.saveSeasonSetting(this.state.seasonId, `potentialDuplicatesGridColumns${capitalize(action.selectedContactType)}`, action.columns);
    await dispatch(new PotentialDuplicatesGridColumnsChangedAction(action.columns));
  }

  @handle(SetSelectedContactTypeAction)
  private async handleSetSelectedContactTypeAction(action: SetSelectedContactTypeAction) {
    if (!action.type)
      return;

    const selectedContactType = this.state.userSeasonSettings.Settings["contactsTypeSelected"];
    if (action.type == selectedContactType)
      return;

    let userSeasonSettings = await this.saveSeasonSetting(this.state.seasonId, "contactsTypeSelected", action.type);
    const typeName = capitalize(action.type);
    const defaultColumns = this.contactsService?.defaultGridColumns || [];
    const key = `contactsGridColumns${typeName}` ;
    const contactGridColumns = userSeasonSettings.Settings[key] || defaultColumns;
    const potentialDuplicateColumns = action.type && userSeasonSettings.Settings[`potentialDuplicatesGridColumns${typeName}`] || defaultColumns;
    this.contactsFilter.setSelectedContactTypeFor(this.state.organizationId, this.state.userId, action.type);
    this.contactsFilter.setFilterFor(this.state.organizationId, this.state.userId, action.type, action.filter);
    await dispatch(new SelectedContactTypeChangedAction(action.type, action.filter, contactGridColumns, potentialDuplicateColumns, action.segmentId));
  }

  @handle(SetCurrentSelectedContactTypeFilterAction)
  private async handleSetCurrentSelectedContactTypeFilterAction(action: SetCurrentSelectedContactTypeFilterAction) {
    const organizationId = this.state.organizationId;
    const userId = this.state.userId;
    const selectedContactTypeKey = this.state.userSeasonSettings.Settings["contactsTypeSelected"];
    this.contactsFilter.setFilterFor(organizationId, userId, selectedContactTypeKey, action.filter);
    await dispatch(new SelectedContactTypeFilterChangedAction(selectedContactTypeKey, action.filter));
  }

  @handle(ContactTypeUpdatedAction)
  private handleContactTypeUpdatedAction(action: ContactTypeUpdatedAction) {
    this.contactsFilter.removeFilterFor(this.state.organizationId, this.state.userId, action.key);
  }

  @handle(SetSeasonSettingAction)
  private async handleSetSeasonSettingAction(action: SetSeasonSettingAction) {
    return await this.saveSeasonSetting(this.state.seasonId, action.key, action.value);
  }

  @handle(SetSeasonSettingsAction)
  private async handleSetSeasonSettingsAction(action: SetSeasonSettingsAction) {
    return await this.saveSeasonSettings(this.state.seasonId, action.patchSet);
  }

  private async saveSeasonSetting(seasonId: string, key: string, value: any) {
    const settings = await this.userRepo.setSeasonSetting(seasonId, key, value);
    this.setState(state => ({
      ...state,
      userSeasonSettings: settings,
    }));
    return settings;
  }

  private async saveSeasonSettings(seasonId: string, patchSet: { [key: string]: any }) {
    const settings = await this.userRepo.setSeasonSettings(seasonId, patchSet);
    this.setState(state => ({
      ...state,
      userSeasonSettings: settings,
    }));
    return settings;
  }

  @handle(UpdateUserNotificationSettingsAction)
  private async handleUpdateUserNotificationSettings(action: UpdateUserNotificationSettingsAction) {
    const notificationSettings = await this.userRepo.putUserSeasonNotificationSettings(this.state.seasonId, action.userSeasonNotificationSettings);
    this.setState(state => ({
      ...state,
      userSeasonSettings: {
        ...state.userSeasonSettings,
        NotificationSettings: notificationSettings,
      },
    }));
    return notificationSettings;
  }
}
