import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { UserInvitation } from "models/user";

@inject
export class InviteRepository {
  constructor(private s: ATS) {}

  public async delete(ids: string[]) {
    await this.s.post("invite/delete", ids);
  }

  public async post(EmailAddresses: string[], RoleId: string, CollaboratorRoleIds: string[], CollaboratorTeamIds: string[]) {
    const res = await this.s.post<any[]>("invite", { EmailAddresses, RoleId, CollaboratorRoleIds, CollaboratorTeamIds });
    return createFromArray(UserInvitation, res.body);
  }

  public async put(invite: UserInvitation) {
    const res = await this.s.put(`invite/${invite.Id}`, invite);
    return createFrom(UserInvitation, res.body);
  }

  public async resend(inviteIds: string[]) {
    await this.s.post("invite/resend", inviteIds);
  }
}
