import { TaskRequest } from "models/task-request";
import { createFromArray } from "fw-model";
import { AssignmentModel } from "models/assignment-model";

export class TaskService {
  public getApplicationIds(taskRequest: TaskRequest): string[] {
    switch (taskRequest.Type) {
      case "CollaborationAssignment":
      case "DirectPhaseEvaluationAssignment":
        try {
          const deletes = createFromArray(AssignmentModel, JSON.parse(taskRequest.Data["delete"]) || []).map(a => a.ApplicationId);
          const adds = createFromArray(AssignmentModel, JSON.parse(taskRequest.Data["add"]) || []).map(a => a.ApplicationId);
          return this.ensureUniqueValues([...deletes, ...adds]);
        } catch (err) { }

        break;
      case "DeleteApplications":
      case "DecisionLetter":
        return this.ensureUniqueValues(JSON.parse(taskRequest.Data["ids"]));
      case "PatchApplicationValues":
        const ids = [];
        const patches: { Selection: { Ids: string[], ExcludedIds: string[], Filter: string }, PropertyValues: { [key: string]: any } }[] = JSON.parse(taskRequest.Data["patches"]) || [];
        for (const patch of patches) {
          if (patch.Selection?.Ids?.length > 0) {
            ids.push(...patch.Selection.Ids);
          }

          if (patch.Selection?.ExcludedIds?.length > 0) {
            ids.push(...patch.Selection.ExcludedIds);
          }
        }

        return this.ensureUniqueValues(ids);
      case "RunAssignmentRules":
        return this.ensureUniqueValues((taskRequest.Data["batchApplicationIds"] || "").split(","));
    }

    return [];
  }

  private ensureUniqueValues(ids: string[]): string[] {
    if (!ids) {
      return [];
    }

    return [...new Set(ids.filter(id => !!id))]
  }
}
