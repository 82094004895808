import { prop, inject, ComponentEventBus } from "@derekpitt/fw"

import { type WidgetData, ReportChartData } from "../../models";
import { ChartData } from "slideroom-charts";

export interface AssignmentCounts {
  id: string;
  label: string;
  total: number;
  completed: number;
}

const getAssignments = (data: ReportChartData, isUser = false) => {
  const teamAssignments: AssignmentCounts[] = [];

  const j: { isTeam: boolean } = JSON.parse(data.Definition.ClientData);

  for (const datum of data.Data) {
    const teamAssignment: AssignmentCounts = {
      id: (datum as any).LabelData,
      label: datum.Label,
      total: datum.Count,
      completed: 0,
    };

    if (teamAssignment.id == "__missing__") continue;

    if (j.isTeam) {
      const completedCount = datum.Data.find(a => a.Label == "Yes");
      if (completedCount) teamAssignment.completed = completedCount.Count;
    } else {
      const completedCount = datum.Data.find(a => a.LabelData == teamAssignment.id);
      if (completedCount) teamAssignment.completed = completedCount.Count;
    }

    teamAssignments.push(teamAssignment);
  }

  return teamAssignments;
};

@inject
export class ProgressChart {
  @prop(null) data!: WidgetData;
  @prop(false) interactive!: boolean;

  constructor(private ceb: ComponentEventBus) { }

  get assignments() {
    if (this.data == null) return [];
    return getAssignments(this.data as ReportChartData);
  }

  formatNumber(d: number) {
    return d;
  }

  click(data: AssignmentCounts, type: string) {
    if (!this.interactive) return;
    this.ceb.dispatch("data-click", { data, type });
  }
}
