import { inject, needs, prop } from "fw";
import { dispatch } from "fw-state";

import { FormStore } from "state/forms";
import { Form, FormTypeCode, QuestionType } from "models/form";
import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { fieldOperatorRequiresIndexing, FormFieldFilter as FormFieldFilterModel } from "models/application-filters";

import { FeatureFlagService } from "service/feature-flag";
import { flatten, isEqual } from "lodash-es";
import { FormFieldSelector } from "./form-field-selector";
import { ValueField, ValueType } from "./value-field";
import { getFriendlyTiming } from "models/date-filter";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";
import { DataDictionaryField } from "models/data-dictionary";
import { IndexStatusIcon } from "./index-status-icon";
import { TimingFilterType } from "models/filter-timing";
import { mergeFilterData } from "../../../../../form-runtime/src/helpers";
import { countries, regions } from "../../../../../countries/index";
import { OptionChoice } from "views/reports/charts/option-chooser-popover";

type DataField = { key: string; label: string; canUseOps?: boolean; dependsOn?: string, optionsFunc?: (value, dependsOnValue) => OptionChoice<string>[] };

const countriesGetter = (
  value: string,
  dependsOnValue: string
) => {
  return mergeFilterData(countries, value);
}

const regionsGetter = (
  value: string,
  dependsOnValue: string
) => {
  return mergeFilterData(regions[dependsOnValue], value);
}

const ADDRESS_DATA_FIELDS: DataField[] = [
  { key: "country", label: "Country", optionsFunc: countriesGetter },
  { key: "region", label: "State/Region", dependsOn: "country", optionsFunc: regionsGetter },
  { key: "city", label: "City" },
];

const CEEB_DATA_FIELDS: DataField[] = [
  { key: "code", label: "Code" },
  { key: "name", label: "Name" },
  { key: "region", label: "State/Region", dependsOn: "country", optionsFunc: regionsGetter },
  { key: "city", label: "City" },
  { key: "postalCode", label: "Postal Code" },
  { key: "country", label: "Country", optionsFunc: countriesGetter },
];

const NAME_DATA_FIELDS: DataField[] = [
  { key: "given", label: "First/Given" },
  { key: "family", label: "Last/Family" },
];

@inject
@needs(IndexStatusIcon, FormFieldSelector, ValueField)
export class FormFieldFilter {
  @prop(null) public filter: FormFieldFilterModel;
  @prop(() => ({})) public validation;
  @prop(false) public editing: boolean;
  @prop(true) public showRuntimeFieldsWarning!: boolean;

  private form: Form = null;
  public dataFields: DataField[] = null;
  public valueTypes = ValueType;
  public valueType = ValueType.Text;
  public formFieldPath: string = null;
  private formFieldLabel: string = null;
  public valueOptions: { value: any; text: string }[] = [];

  public ops = [
    { text: "=", value: "=" },
    { text: ">", value: ">" },
    { text: ">=", value: ">=" },
    { text: "<=", value: "<=" },
    { text: "<", value: "<" },
  ];
  private canUseOps: boolean = false;

  constructor(
    private dataDictionaryStore: DataDictionaryStore,
    private formStore: FormStore,
    private ffs: FeatureFlagService
  ) { }

  public async attached() {
    if (this.filter.formKey != null && this.filter.field != null) {
      this.formFieldPath = this.filter.sectionKey != null
        ? `forms.${this.filter.formKey}.${this.filter.sectionKey}.${this.filter.field}`
        : `forms.${this.filter.formKey}.${this.filter.field}`;
    }

    this.fieldChanged(false);

    if (this.ffs.isFeatureFlagEnabled("ElectiveIndexing")) {
      await dispatch(new EnsureDataDictionaryFieldsAction(false));
    }
  }



  public get formField(): DataDictionaryField {
    const { fields } = this.dataDictionaryStore.state;
    return fields.find(f => f.SearchPath === this.formFieldPath || f.Path === this.formFieldPath);
  }

  public setFormField(path: string) {
    this.filter.sectionKey = null;
    this.formFieldPath = path;
    const segments = this.formFieldPath.split(".");
    this.filter.formKey = segments[1];
    
    this.filter.field = segments[2];

    switch (segments.length) {
      case 6:
        // table section field with named rows
        this.filter.field = `${segments[3]}.${segments[4]}.${segments[5]}`;
        this.filter.sectionKey = segments[2];
        break;
      case 5:
        // table section field with unnamed rows
        this.filter.field = `${segments[3]}.${segments[4]}`;
        this.filter.sectionKey = segments[2];
        break;
      case 4:
        this.filter.field = segments[3];
        break;
    }
    this.fieldChanged();

    // Set the default operator to smart defaults.
    switch (this.valueType) {
      case ValueType.AnsweredOnly:
        this.filter.operator = "~"; // is answered
        break;

      case ValueType.DataFields:

      case ValueType.IsOnly:
      case ValueType.Number:
        this.filter.operator = "="; // is
        break;

      case ValueType.Dropdown:
        if (this.ffs.isFeatureFlagEnabled("DropdownFilterAsKeyword8366"))
          this.filter.operator = "=="; // is exactly
        else
          this.filter.operator = "="; // is
        break;

      case ValueType.Date:
        this.filter.timing = TimingFilterType.Any;
        this.filter.operator = null;
        break;

      case ValueType.Text:
        this.filter.operator = "=="; // is exactly
        break;
    }

    this.validation.formKey = null;
    this.validation.field = null;
    this.validation.value = null;
  }

  private fieldChanged(clearValue = true) {
    if (this.filter == null || this.filter.formKey == null)
      return;

    this.form = this.formStore.state.forms.find(f => f.Key == this.filter.formKey);
    if (this.form == null)
      return;

    if (clearValue) {
      this.filter.value = null;
    }

    this.canUseOps = false;

    const q = flatten(this.form.Sections.map(s => s.Questions)).find(
      q => q.Key == this.filter.field || `rows[].${q.Key}` == this.filter.field || `rows.${q.Key}` == this.filter.field,
    );
    if (q == null) {
      this.dataFields = [];
      this.filter.dataFields = null;
      this.valueType = ValueType.Text;

      const calcField = this.form.CalculatedFields.find(cf => cf.Key == this.filter.field);
      if (calcField != null) {
        switch (calcField.DataType) {
          case CalculatedFieldDataTypeCode.Number:
            this.canUseOps = true;
            this.filter.shouldQuoteValue = false;
            this.valueType = ValueType.Number;
            break;
          default:
            this.filter.shouldQuoteValue = true;
            this.valueType = ValueType.Text;
            break;
        }
      } else {
        this.filter.shouldQuoteValue = true;
      }

      return;
    }

    this.formFieldLabel = q.Label;

    switch (q.Type) {
      case QuestionType.Table:
      case QuestionType.Encrypted:
      case QuestionType.File:
      case QuestionType.PhoneNumber:
        this.dataFields = null;
        this.filter.dataFields = null;
        this.valueType = ValueType.AnsweredOnly;
        break;
      case QuestionType.Number:
      case QuestionType.Scale:
        this.dataFields = [];
        this.filter.dataFields = null;
        this.valueType = ValueType.Number;
        break;
      case QuestionType.Date:
        this.dataFields = null;
        this.filter.dataFields = null;
        this.valueType = ValueType.Date;
        break;
      case QuestionType.CheckBoxList:
      case QuestionType.DropDown:
      case QuestionType.RadioButton:
        this.filter.dataFields = null;
        this.dataFields = [];
        this.valueOptions = q.AnswerOptions.map(ao => ({
          value: ao.Label,
          text: ao.Label,
        }));
        this.valueType = ValueType.Dropdown;
        break;

      case QuestionType.Address:
        this.dataFields = ADDRESS_DATA_FIELDS;

        {
          const newDataFields = {};
          ADDRESS_DATA_FIELDS.forEach(df => {
            const currentField = this.filter.dataFields ? this.filter.dataFields[df.key] : null;
            newDataFields[df.key] = currentField || { value: "", op: "=" };
          });

          if(!isEqual(this.filter.dataFields, newDataFields)) {
            this.filter.dataFields = newDataFields;
          }
        }

        this.valueType = ValueType.DataFields;
        break;

      case QuestionType.CEEBCode:
        this.dataFields = CEEB_DATA_FIELDS;

        {
          const newDataFields = {};
          CEEB_DATA_FIELDS.forEach(df => {
            const currentField = this.filter.dataFields ? this.filter.dataFields[df.key] : null;
            newDataFields[df.key] = currentField || { value: "", op: "=" };
          });

          if(!isEqual(this.filter.dataFields, newDataFields)) {
            this.filter.dataFields = newDataFields;
          }
        }

        this.valueType = ValueType.DataFields;
        break;
      case QuestionType.Name:
        this.dataFields = NAME_DATA_FIELDS;
        {
          const newDataFields = {};
          NAME_DATA_FIELDS.forEach(df => {
            const currentField = this.filter.dataFields ? this.filter.dataFields[df.key] : null;
            newDataFields[df.key] = currentField || { value: "", op: "=" };
          });

          if(!isEqual(this.filter.dataFields, newDataFields)) {
            this.filter.dataFields = newDataFields;
          }
        }

        this.valueType = ValueType.DataFields;
        break;
      case QuestionType.ScaleGroup:
        this.dataFields = [];
        q.Options.ScaleGroup.Items.forEach(i =>
          this.dataFields.push({ key: i.Key, label: i.Label, canUseOps: true }),
        );
        this.dataFields.push({ key: "sum", label: "Sum", canUseOps: true });
        this.dataFields.push({
          key: "average",
          label: "Average",
          canUseOps: true,
        });

        {
          const newDataFields = {};
          this.dataFields.forEach(df => {
            const currentField = this.filter.dataFields ? this.filter.dataFields[df.key] : null;
            newDataFields[df.key] = currentField || { value: "", op: "=" };
          });

          if(!isEqual(this.filter.dataFields, newDataFields)) {
            this.filter.dataFields = newDataFields;
          }
        }

        this.valueType = ValueType.DataFields;
        break;

      default:
        this.dataFields = [];
        this.filter.dataFields = null;
        this.valueType = ValueType.Text;
    }

    if (q.Type == QuestionType.Number || q.Type == QuestionType.Scale || q.Type == QuestionType.ScaleGroup || q.Type == QuestionType.Date) {
      this.canUseOps = true;
      this.filter.shouldQuoteValue = false;
    } else {
      this.filter.shouldQuoteValue = true;
    }
  }

  public get fieldName() {    
    const segments = this.filter.field?.split(".") || [];
    if (segments.length >= 2) {
      switch (segments[0]) {
        case "rows":
        case "rows[]":
          return segments.length > 2 
            ? `Column "${segments[2]}" in row "${segments[1]}" of table section "${this.filter.sectionKey}"`
            : `Any "${segments[1]}" in table section "${this.filter.sectionKey}"`;
      }
    }    

    return this.formFieldLabel || this.filter.field;
  }

  public get filterText() {
    switch (this.filter.operator) {
      case "~": return "is answered.";
      case "!~": return "is not answered.";
    }

    if (this.valueType === ValueType.Date) {
      return getFriendlyTiming(this.filter);
    }

    return `${this.filter.operator} ${this.filter.value}`;
  }

  public get showStatusIcon(): boolean {
    if (this.showRuntimeFieldsWarning) {
      return true;
    }

    return this.formField?.Path && fieldOperatorRequiresIndexing(this.formField, this.filter?.operator);
  }

  public getOptions(
    dataField: DataField,
    filterFields: { [key: string]: { value: string; op: string } }
  ) {
    if (!dataField.optionsFunc) return [];

    return dataField.optionsFunc(filterFields[dataField.key].value, filterFields[dataField.dependsOn]?.value)
  }
}
