import { needs, inject } from "fw";

import { PopoverController } from "service/popover";

import { SelectOrganization } from "./select-organization";
import { OrganizationWithDomainView } from "network/app-repository";

export interface ISelectOrganizationPopoverOptions {
  userOrganizations: OrganizationWithDomainView[];
}

@inject
@needs(SelectOrganization)
export class SelectOrganizationPopover {
  public userOrganizations: any = null;

  constructor(private controller: PopoverController<string>) {}

  public activate(options: ISelectOrganizationPopoverOptions) {
    this.userOrganizations = options.userOrganizations;
  }

  public selectOrganization(organizationId) {
    this.controller.ok(organizationId);
  }
}
