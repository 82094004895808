import { inject, prop } from "@derekpitt/fw"

import { ChartData } from "../../models";
import { select, scaleOrdinal, scaleLinear, max, line, axisBottom } from "d3";

@inject
export class LineChart {
  @prop(null) data!: ChartData[];
  @prop("Line chart") svgAlt: string;

  private svgElement: SVGElement = null;

  private margin = 20;

  private width = 400;
  private height = 250;

  constructor() { }

  attached() {
    this.width = this.svgElement.clientWidth;

    this.dataChanged();
  }

  dataChanged() {
    this.buildChart();
  }

  buildChart() {
    if (this.data == null) return;

    const w = this.width - this.margin;
    const h = this.height - this.margin;

    const labels = this.data.map(d => d.Label);
    const counts = this.data.map(d => d.Count);

    const x = scaleOrdinal().domain(labels).range([0, w]) as any;
    const y = scaleLinear().domain([0, max(this.data.map(d => d.Count))]).range([h, 0]) as any;
    const theLine = line<number>()
                      .x(d => x(d))
                      .y(d => y(d));

    const svg = select(this.svgElement);

    svg.append("g")
       .attr("class", "line")
       .append("path")
         .attr("d", theLine(counts));


    const xAxis = axisBottom(x);

    svg.append("g")
       .attr("class", "axis x")
       .attr("transform", `translate(0, ${h})`)
       .call(xAxis);
  }
}

