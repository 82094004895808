import { inject } from "fw";
import { createFrom } from "fw-model";
import { Store, handle, dispatch } from "fw-state";
import { difference, union } from "lodash-es";

import { LogoutAction, StartAction } from "./actions";
import { GroupFilter } from "models/filter-setup";
import { UserTaskForm } from "forms/user-task";
import { UserTask } from "models/user-task";
import { TaskListDueDateFilter } from "models/task-filters";
import { UserTaskRepository } from "network/user-task-repository";
import { EnsureEntityReferencesAction } from "state/entity-reference";
import { EnsureUsersAction } from "state/users";
import { FormErrorHandling } from "./error-handling";

const DEFAULT_PAGE_SIZE = 20;

interface UserTaskStoreShape {
  currentUserId: string,
  listType: TaskListType,
  filter: GroupFilter;
  dueDateFilter: string;
  search: string;
  sort: string;

  userTasks: UserTask[];
  loading: boolean;
  loaded: boolean;
  errorLoading: boolean;
  errorMessage: string;

  counts: any[];
  summaryLoading: boolean;
  summaryLoaded: boolean;
  errorLoadingSummary: boolean;
  summaryErrorMessage: string;

  page: number;
  total: number;
  pageSize: number;

  selectAll: boolean;
  selectedIds: string[];
}

export class EnsureUserTaskStoreAction {
  constructor() { }
}

export class EnsureUserTasksAction {
  constructor(public ids: string[]) { }
}

export class ChangeTaskFiltersAction {
  // clear others will set other surrounding state
  // to null so the filter container can stand alone
  constructor(public filter: GroupFilter = null, public dueDateFilter: string = null, public listType: TaskListType = null, public clearOthers = false) { }
}

export class SetTaskFilterAction {
  // clear others will set other surrounding state
  // to null so the filter container can stand alone
  constructor(public filter: GroupFilter, public clearOthers = false) { }
}

export class SetTaskDueDateFilterAction {
  constructor(public dueDateFilter: string) { }
}

export class SetTaskSearchAction {
  constructor(public search: string) { }
}

export class SortUserTasksListAction {
  constructor(public sort: string) { }
}

export class RefreshUserTaskStoreAction {
  constructor() { }
}

export class UserTaskNextPage { }

export class UserTaskPreviousPage { }

export class ToggleUserTaskSortAction {
  constructor(public sort: string) { }
}

export class DeleteSelectedUserTasksAction {
  constructor() { }
}

export class SetCompletedSelectedUserTasksAction {
  constructor(public isCompleted: boolean) { }
}

export class AssignUserToSelectedUserTasksAction {
  constructor(public userId: string) { }
}

export class ToggleUserTaskSelectAllAction {
  constructor(public selectAll: boolean) { }
}

export class UserTaskIdsSelectedAction {
  constructor(public ids: string[]) { }
}

export class SetTaskListTypeAction {
  constructor(public type: TaskListType) { }
}

export class AddUserTaskAction {
  constructor(public form: UserTaskForm) { }
}

export class UpdateUserTaskAction {
  constructor(public form: UserTaskForm) { }
}

export enum TaskListType {
  All = 0,
  MyOpen = 1,
  MyCompleted = 2,
  AllOpen = 3,
  AllCompleted = 4
}

@inject
export class UserTaskStore extends Store<UserTaskStoreShape> {
  constructor(private userTaskRepo: UserTaskRepository) {
    super();
  }

  defaultState() {
    const emptyFilterContainer = createFrom(GroupFilter, {
      operation: "AND",
      filters: [],
    });

    return {
      currentUserId: null,
      listType: TaskListType.All,
      filter: emptyFilterContainer,
      dueDateFilter: "",
      search: "",
      sort: "dateDueUtc",

      userTasks: [],
      loaded: false,
      loading: false,
      errorLoading: false,
      errorMessage: null,

      page: 1,
      total: 0,
      pageSize: DEFAULT_PAGE_SIZE,

      selectAll: false,
      selectedIds: [],

      counts: [],
      summaryLoading: false,
      summaryLoaded: false,
      errorLoadingSummary: false,
      summaryErrorMessage: null,
    };
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    const defaultState = this.defaultState();
    defaultState.currentUserId = action.context.Me.Id;
    this.setState(s => defaultState);
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  private async loadUserTasks() {
    this.setState(state => ({
      ...state,
      loading: true,
    }));

    const { search, sort, page, pageSize } = this.state;
    const filter = this.getWholeFilter();
    let errorLoading = false;
    let errorMessage = null;
    let searchResult = { list: [], total: 0 };

    try {
      searchResult = await this.userTaskRepo.search(
        filter,
        search,
        sort,
        page,
        pageSize,
      );

      const applicationIds: string[] = [];
      const contactIds: string[] = [];
      const userIds: string[] = [];

      for (const task of searchResult.list) {
        if (task.TargetType === "Application" && task.TargetId && applicationIds.indexOf(task.TargetId) === -1)
          applicationIds.push(task.TargetId);
        if (task.TargetType === "Contact" && task.TargetId && contactIds.indexOf(task.TargetId) === -1)
          contactIds.push(task.TargetId);
        if (task.AssignedToUserId)
          userIds.push(task.AssignedToUserId);
      }

      if (applicationIds.length > 0 || contactIds.length > 0) {
        dispatch(new EnsureEntityReferencesAction(applicationIds, contactIds));
      }
      if (userIds.length > 0) {
        dispatch(new EnsureUsersAction(userIds));
      }

    } catch {
      errorLoading = true;
      errorMessage = "error searching tasks";
    }

    this.setState(state => ({
      ...this.state,
      errorLoading,
      errorMessage,
      loading: false,
      loaded: true,
      userTasks: searchResult.list,
      total: searchResult.total,
    }));
  }

  private async loadSummary() {
    this.setState(state => ({
      ...state,
      summaryLoading: true,
    }));

    const { search } = this.state;
    let summaryResult = { Overdue: 0, DueToday: 0, DueSoon: 0 };
    let counts = [];
    let errorLoadingSummary = false;
    let summaryErrorMessage = null;

    try {
      summaryResult = await this.userTaskRepo.summary(this.getSummaryFilter(), search);

      counts = [
        { text: "Overdue", count: summaryResult.Overdue, filter: TaskListDueDateFilter.Overdue },
        { text: "Due Today", count: summaryResult.DueToday, filter: TaskListDueDateFilter["Due Today"] },
        { text: "Due Soon", count: summaryResult.DueSoon, filter: TaskListDueDateFilter["Due Soon"] }
      ];

    } catch {
      errorLoadingSummary = true;
      summaryErrorMessage = "error retrieving summaries";
    }

    this.setState(state => ({
      ...this.state,
      errorLoadingSummary,
      summaryErrorMessage,
      summaryLoading: false,
      summaryLoaded: true,
      counts: counts
    }));
  }

  @handle(EnsureUserTaskStoreAction)
  private async ensureUserTasks(action: EnsureUserTaskStoreAction) {
    if (this.state.loading || this.state.loaded) return;
    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(EnsureUserTasksAction)
  private async handleEnsureUserTasksAction(action: EnsureUserTasksAction) {
    const { userTasks } = this.state;
    const ids = userTasks.map(t => t.Id);
    const tasksToGet = difference(
      action.ids,
      ids,
    );

    if (tasksToGet.length > 0) {
      const newTasks = await this.userTaskRepo.listByIds(tasksToGet)
      this.setState(state => ({
        ...state,
        userTasks: union(userTasks, newTasks),
      }));
    }
  }

  @handle(SetTaskListTypeAction)
  private async handleSetTaskListTypeAction(action: SetTaskListTypeAction) {
    this.setState(state => ({
      ...state,
      listType: action.type,
    }));
    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(AddUserTaskAction, FormErrorHandling)
  private async handleAddApplicationSourceAction(action: AddUserTaskAction) {
    action.form.validate();

    let newTask = action.form.updatedModel();
    newTask.MetaData = null;

    const res = await this.userTaskRepo.post(newTask);

    this.setState(state => ({
      ...state,
      userTasks: [...state.userTasks, res],
      total: state.total + 1,
    }));

    if (!this.state.selectAll && this.state.selectedIds.length === 0)
      await dispatch(new RefreshUserTaskStoreAction());
  }

  @handle(UpdateUserTaskAction, FormErrorHandling)
  private async handleAddUserAction(action: UpdateUserTaskAction) {
    action.form.validate();
    const res = await this.userTaskRepo.put(action.form.updatedModel());

    if (!this.state.selectAll && this.state.selectedIds.length === 0) {
      await dispatch(new RefreshUserTaskStoreAction());
    }
    else {
      const existing = this.state.userTasks.find(t => t.Id == action.form.Id);
      if (existing) {
        Object.assign(existing, res);
      }
    }
  }

  @handle(SetTaskSearchAction)
  private async handleSetTaskSearchAction(action: SetTaskSearchAction) {
    if (this.state.loading) return;

    this.setState(state => ({
      ...state,
      loaded: false,
      loading: false,
      summaryLoaded: false,
      summaryLoading: false,
      page: 1,
      total: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      search: action.search
    }));

    // this.saveState();

    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(SetTaskFilterAction)
  private async handleSetTaskFilterAction(action: SetTaskFilterAction) {
    if (action.clearOthers === false && this.state.loading) return;

    this.setState(state => ({
      ...state,
      loaded: false,
      loading: false,
      summaryLoaded: false,
      summaryLoading: false,
      page: 1,
      total: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      filter: action.filter,
      search: action.clearOthers ? "" : state.search
    }));

    // this.saveState();

    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(ChangeTaskFiltersAction)
  private async handleChangeTaskFiltersAction(action: ChangeTaskFiltersAction) {
    if (action.clearOthers) {
      this.setState(state => ({
        ...state,
        loaded: false,
        loading: false,
        page: 1,
        total: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        filter: action.filter,
        search: "",
        dueDateFilter: action.dueDateFilter,
        listType: action.listType || TaskListType.All
      }));

    } else if (this.state.loading) {
      return;
    }

    const { filter, dueDateFilter, listType } = this.state;
    this.setState(state => ({
      ...state,
      loaded: false,
      loading: false,
      summaryLoaded: false,
      summaryLoading: false,
      page: 1,
      total: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      filter: action.filter || filter,
      dueDateFilter: action.dueDateFilter || dueDateFilter,
      listType: action.listType || listType
    }));

    // this.saveState();

    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(SetTaskDueDateFilterAction)
  private async handleSetTaskDueDateFilterAction(action: SetTaskDueDateFilterAction) {
    if (this.state.loading) return;
    this.setState(state => ({
      ...state,
      dueDateFilter: action.dueDateFilter,
      page: 1,
    }));
    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(RefreshUserTaskStoreAction)
  private async refreshUserTasks(action: RefreshUserTaskStoreAction) {
    if (this.state.loading) return;
    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(DeleteSelectedUserTasksAction)
  private async handleDeleteSelectedUserTasksAction(action: DeleteSelectedUserTasksAction) {
    if (this.state.selectAll) {
      const filter = this.getWholeFilter();
      await this.userTaskRepo.deleteAll(filter, this.state.search);
      this.state.page = 1;
    } else {
      await this.userTaskRepo.delete(this.state.selectedIds);
      const totalLeft = this.state.total - this.state.selectedIds.length;
      const lastPage = Math.ceil(totalLeft / this.state.pageSize);
      if (this.state.page > lastPage) {
        this.state.page = lastPage;
      }
    }

    this.setState(state => ({
      ...state,
      selectAll: false,
      selectedIds: [],
      loaded: false,
      summaryLoaded: false,
    }));

    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(SetCompletedSelectedUserTasksAction)
  private async handleSetCompleteSelectedUserTasksAction(action: SetCompletedSelectedUserTasksAction) {
    const { selectAll, selectedIds, search } = this.state;
    if (selectAll) {
      const filter = this.getWholeFilter();
      await this.userTaskRepo.setCompletedAll(action.isCompleted, filter, search);
    } else {
      await this.userTaskRepo.setCompleted(action.isCompleted, selectedIds);
    }

    this.setState(state => ({
      ...state,
      page: this.state.selectAll ? 1 : this.state.page,
      loaded: false,
      summaryLoaded: false,
    }));

    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(AssignUserToSelectedUserTasksAction)
  private async handleAssignUserToSelectedUserTasksAction(action: AssignUserToSelectedUserTasksAction) {
    const { selectAll, selectedIds, search } = this.state;
    if (selectAll) {
      const filter = this.getWholeFilter();
      await this.userTaskRepo.assignAll(action.userId, filter, search);
    } else {
      await this.userTaskRepo.assign(action.userId, selectedIds);
    }

    this.setState(state => ({
      ...state,
      page: this.state.selectAll ? 1 : this.state.page,
      loaded: false,
      summaryLoaded: false,
    }));

    await this.loadUserTasks();
    await this.loadSummary();
  }

  @handle(SortUserTasksListAction)
  private async handleToggleUsersSortAction(action: SortUserTasksListAction) {
    this.state.sort = action.sort == this.state.sort ? `-(${action.sort})` : action.sort;
    await this.loadUserTasks();
  }

  @handle(ToggleUserTaskSortAction)
  async handleToggleUserTaskSortAction(action: ToggleUserTaskSortAction) {
    if (action.sort && action.sort == this.state.sort) {
      this.setState(state => ({ ...state, sort: `-(${action.sort})` }));
    } else {
      this.setState(state => ({ ...state, sort: action.sort }));
    }
    await this.loadUserTasks();
  }

  @handle(UserTaskNextPage)
  async handleUserTaskNextPage() {
    const page = this.state.page + 1;
    this.setState(state => ({
      ...state,
      page,
      loaded: false,
    }));

    await this.loadUserTasks();
  }

  @handle(UserTaskPreviousPage)
  async handleUserTaskPreviousPage() {
    let page = this.state.page - 1;
    if (page < 0) page = 0;

    this.setState(state => ({
      ...state,
      page,
      loaded: false,
    }));

    await this.loadUserTasks();
  }

  @handle(ToggleUserTaskSelectAllAction)
  async handleToggleUserTaskSelectAllAction(action: ToggleUserTaskSelectAllAction) {
    this.setState(state => ({
      ...state,
      selectAll: action.selectAll
    }));
  }

  @handle(UserTaskIdsSelectedAction)
  handleUserTaskIdsSelectedAction(action: UserTaskIdsSelectedAction) {
    this.setState(state => ({
      ...state,
      selectedIds: action.ids
    }));
  }

  private getListTypeFilter() {
    switch (this.state.listType) {
      case TaskListType.MyOpen:
        return `isCompleted:false assignedToUserId:${this.state.currentUserId}`;
      case TaskListType.MyCompleted:
        return `isCompleted:true assignedToUserId:${this.state.currentUserId}`;
      case TaskListType.AllOpen:
        return "isCompleted:false";
      case TaskListType.AllCompleted:
        return "isCompleted:true";
      default:
        return "";
    }
  }

  private getWholeFilter(): string {
    const filter = this.getSummaryFilter();
    const dueDateFilter = this.state.dueDateFilter;

    return [
      filter && filter.length > 0 ? `(${filter})` : "",
      dueDateFilter && dueDateFilter.length > 0 ? `(${dueDateFilter})` : ""
    ].filter(Boolean).join(" AND ");
  }

  private getSummaryFilter(): string {
    const filter = this.state.filter?.toFilterString();
    const listTypeFilter = this.getListTypeFilter();

    return [
      filter && filter.length > 0 ? `(${filter})` : "",
      listTypeFilter.length > 0 ? `(${listTypeFilter})` : ""
    ].filter(Boolean).join(" AND ");
  }
}
