export const getContrastingColor = (color?: string) => {
  if (!color) {
    return "inherit";
  }

  let hex = color.charAt(0) === "#" ? color.substring(1, 7) : color;

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw Error(
      `Failed parsing color: '${color}' while calculating contrasting color`
    );
  }

  const red = parseInt(hex.slice(0, 2), 16);
  const green = parseInt(hex.slice(2, 4), 16);
  const blue = parseInt(hex.slice(4, 6), 16);

  const uicolors = [red / 255, green / 255, blue / 255];
  const colors = uicolors.map((c) =>
    c <= 0.03928 ? c / 12.92 : ((c + 0.055) / 1.055) ** 2.4
  );
  const luminance =
    0.2126 * colors[0] + 0.7152 * colors[1] + 0.0722 * colors[2];

  return luminance > 0.179 ? "black" : "white";
};

function getLuminanceOf(color: string) {
  const r = Number("0x" + color[0] + color[1]) / 255;
  const g = Number("0x" + color[2] + color[3]) / 255;
  const b = Number("0x" + color[4] + color[5]) / 255;

  const luminance =
    0.2126 * linearize(r) + 0.7152 * linearize(g) + 0.0722 * linearize(b);

  return luminance;
}

function linearize(colorChannel: number) {
  if (colorChannel <= 0.04045) {
    return colorChannel / 12.92;
  } else {
    return Math.pow((colorChannel + 0.055) / 1.055, 2.4);
  }
}

export function colorContrast(color1: string, color2: string) {
  const luminance1 = getLuminanceOf(color1);
  const luminance2 = getLuminanceOf(color2);

  if (luminance1 > luminance2) {
    return (luminance1 + 0.05) / (luminance2 + 0.05);
  }

  return (luminance2 + 0.05) / (luminance1 + 0.05);
}
