import { inject, prop } from "fw";

import { EvaluationPhasesStore } from "state/evaluation-phases";
import { MyIncompletedEvaluationsFilter as MyIncompletedEvaluationsFilterModel  } from "models/application-filters";
import { CurrentUserStore } from "state/current-user";

@inject
export class MyIncompletedEvaluationsFilter {
  @prop(null)
  filter: MyIncompletedEvaluationsFilterModel;

  @prop(() => ({}))
  validation;

  @prop(false)
  editing: boolean;

  constructor(
    private currentUserStore: CurrentUserStore,
  ) {}

  async attached() {
    this.filter.userId = this.currentUserStore.state.user.Id;
  }
}
