import { ComponentEventBus, inject, prop, needs } from "fw";
import { generateDataId } from "helpers/data-id-gen";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { ContactsOrganizationService } from "service/contacts-organization";
import { FieldMode } from "../field-mode";
import { SocialImage } from "./social-image";

@inject
@needs(SocialImage)
export class SocialType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string;
  @prop("Handle") placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(true) public setfocus!: boolean;
  @prop(false) public showClear!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  private socialWebsites = {
    twitter: "https://twitter.com/",
    facebook: "https://www.facebook.com/",
    github: "https://github.com/",
    linkedin: "https://www.linkedin.com/",
    googleplus: "https://plus.google.com/",
    gravatar: "https://en.gravatar.com/",
  };

  public get dataId() {
    return generateDataId(null, this.field.display_name);
  }

  public get metaDescription() {
    return this.contactOrganizationService.getFieldMetaDescription(this.field);
  }

  public get isSocialType(): boolean {
    return this.socialType != "social";
  }

  public get socialType(): string {
    return this.field && this.field.data && this.field.data.social_type;
  }

  public get socialLink(): string {
    return this.socialWebsites[this.socialType] + this.value;
  }

  public get localValue() {
    return this.value;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", value && value.trim());
  }

  public clear() {
    this.value = null;
    this.ceb.dispatch("update:value", null);
  }
}
