export enum CalculatedFieldDataTypeCode {
  Number = 0,
  String = 1,
  Date = 2,
  Boolean = 3,
  File = 4,
  Enumeration = 5,
  Encrypted = 6,
  Json = 7,
}

export enum FormTypeCode {
  Applicant = 0,
  Recommender = 1,
  Evaluation = 2,
  Inquiry = 3,
}

export enum QuestionType {
  ShortText = 1,
  LongText = 2,
  RadioButton = 3,
  DropDown = 4,
  CheckBoxList = 5,
  File = 6,
  Date = 7,
  CEEBCode = 8,
  EmailAddress = 9,
  PhoneNumber = 10,
  URL = 11,
  Number = 12,
  Scale = 13,
  Table = 14,
  Address = 15,
  Name = 16,
  ScaleGroup = 17,
  Encrypted = 18,
}

export enum QuestionScaleMode {
  Range = 0,
  Labels = 1,
}

export enum NumberQuestionType {
  Integer,
  Decimal,
}

export enum CEEBSourceFileType {
  College = 0,
  HighSchoolAndScholarship = 1,
}
