import { inject } from "fw";
import { MemoryCache, cacheFor } from "caching";

import { ApplicationClientModel } from "models/application-client-model";
import { AppApplicationContext } from "models/app-application-context";
import { ApplicationEvaluation } from "models/application-evaluation";

@inject
export class ApplicationContextCacheService {
  constructor(private cache: MemoryCache) { }

  private getContextCacheKey(id: string) { return `app-context:${id}`; }
  private getClientModelCacheKey(id: string) { return `client-model:${id}`; }

  isContextCached(id: string) {
    return this.cache.get(this.getContextCacheKey(id)) != null;
  }

  isClientModelCached(id: string) {
    return this.cache.get(this.getClientModelCacheKey(id)) != null;
  }

  getContext(id: string) {
    return this.cache.get<AppApplicationContext>(this.getContextCacheKey(id));
  }

  getClientModel(id: string) {
    return this.cache.get<ApplicationClientModel>(this.getClientModelCacheKey(id));
  }

  setContext(model: AppApplicationContext) {
    this.cache.set(this.getContextCacheKey(model.Application.Id), model, cacheFor(5).minutes);
  }

  setClientModel(model: ApplicationClientModel) {
    this.cache.set(this.getClientModelCacheKey(model.id), model, cacheFor(5).minutes);
  }

  deleteContext(model: AppApplicationContext | string) {
    if (typeof model === 'string') {
      this.cache.remove(this.getContextCacheKey(model));
    } else {
      this.cache.remove(this.getContextCacheKey(model.Application.Id));
    }
  }

  deleteClientModel(model: ApplicationClientModel | string) {
    if (typeof model === 'string') {
      this.cache.remove(this.getClientModelCacheKey(model));
    } else {
      this.cache.remove(this.getClientModelCacheKey(model.id));
    }
  }

  updateClientModelIfCached(id: string, clientModel: ApplicationClientModel) {
    if (this.isClientModelCached(id)) {
      this.setClientModel(clientModel);
    }
  }

  // other convience methods
  updateEvalIfCached(id: string, appEval: ApplicationEvaluation) {
    if (this.isContextCached(id) == false) return;

    const val = this.getContext(id);

    if (val.Evaluations == null) {
      val.Evaluations = [];
    }

    if (appEval.Id == null || val.Evaluations.length == 0) {
      val.Evaluations.push(appEval);
    } else {
      const existing = val.Evaluations.find(e => e.Id == appEval.Id);
      if (existing != null) {
        Object.assign(existing, appEval);
      }
    }

    this.setContext(val);
  }
}
