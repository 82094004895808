import { inject, prop } from "fw";
import { ItemState } from "./item-assignment-context";
import { UserAssignment } from "models/application-evaluation-container ";

@inject
export class  ItemAssignmentUserList{
  @prop(null) items:any[];
  @prop(null) itemStateHash!: { [userId: string]: ItemState };
  @prop(null) assignmentContext!: { [userId: string]: number };
  @prop(null) totalIds!: number;
  @prop(null) toggleItemClick: any;

  toggleItem(itemId: string, item: any) {
    this.toggleItemClick(itemId, item);
  }

  public showUserAssignmentStatus(item: any) {
    return (this.itemStateHash[item.id] === 'none' && this.assignmentContext[item.id] !== null)|| !item?.isDirect
  }

  public showIcon(item: any) {
    return this.itemStateHash[item.id] === 'add' || (this.assignmentContext[item.id] > 0 && this.itemStateHash[item.id] !== 'remove') || !item.isDirect
  }

  private isRemove(itemId)
  {
      return this.itemStateHash[itemId] === 'remove';
  }

  private isAdd(itemId)
  {
      return this.itemStateHash[itemId] === 'add';
  }
}
