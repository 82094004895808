import { FormForType, formFor, Validators } from "fw-model";
const { wrap } = Validators;
import { GoalDefinition } from 'models/goal';
import { isValidDate } from "./validators";

export type GoalFormType = FormForType<GoalDefinition>;

export class AddGoalAction {
  constructor(public form: GoalFormType) { }
}

export class UpdateGoalAction {
  constructor(public form: GoalFormType) { }
}

export class DeleteGoalAction {
  constructor(public id: string) { }
}

export const goalFormCreator = formFor(GoalDefinition, s => {
  s.requiredField(goal => goal.SegmentId, "Segment Id");
  s.requiredField(goal => goal.Name, "Name");
  s.requiredField(goal => goal.TargetNumber, "Target Number");
  s.requiredField(goal => goal.GoalType, "Goal Type");
  s.requiredField(goal => goal.StartDateUtc, "Start Date", wrap(isValidDate));
  s.requiredField(goal => goal.EndDateUtc, "End Date", wrap(isValidDate));
});
