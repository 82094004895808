import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { Function } from "models/function";

@inject
export class FunctionRepository {
  constructor(private s: ATS) { }

  public async list() {
    const res = await this.s.get<any[]>("function");
    return createFromArray(Function, res.body);
  }

  public async post(func: Function) {
    const res = await this.s.post("function", func);
    return createFrom(Function, res.body);
  }

  public async put(func: Function) {
    const res = await this.s.put(`function/${func.Id}`, func);
    return createFrom(Function, res.body);
  }

  public async del(ids: string[]) {
    await this.s.post("function/delete", ids);
  }
}
