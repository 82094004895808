import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import {
  Conversation,
  ConversationFilterType,
  ConversationNotificationCounts,
  ConversationStatus,
  ConversationTypeCounts,
  Message,
} from "models/conversation";
import { ATS } from "./ats";

const DEFAULT_PAGE_SIZE = 20;

@inject
export class ConversationRepository {
  constructor(private s: ATS) { }

  async search(
    type: ConversationFilterType = ConversationFilterType.Text,
    status: ConversationStatus[] = null,
    assignedToUserId = null,
    receivedDateFrom = null,
    receivedDateTo = null,
    search = null,
    sort = null,
    contactId = null,
    page = 1,
    pageSize = DEFAULT_PAGE_SIZE,
  ) {
    const res = await this.s.marketing.get<any>(
      "conversations",
      { type, status, assignedToUserId, receivedDateFrom, receivedDateTo, sort, contactId, page, limit: pageSize, search }
    );
    const conversations: Conversation[] = res.body?.results;
    const counts: ConversationTypeCounts = res.body?.counts;

    return {
      counts,
      list: createFromArray(Conversation, conversations),
      total: parseInt(res.headers["x-result-count"], 10),
    };
  }

  async get(id: string) {
    const res = await this.s.marketing.get<any>(`conversations/${id}`);
    return createFrom(Conversation, res.body);
  }

  async getTimeline(
    conversationId: string,
    search = null,
    sort = 'CreatedAt DESC',
    page = 1,
    limit = DEFAULT_PAGE_SIZE,
  ) {
    const res = await this.s.marketing.get<any>(`conversations/timeline/${conversationId}`, { sort, page, limit, search });
    return {
      messages: createFromArray(Message, res.body?.results),
      total: parseInt(res.headers["x-result-count"], 10)
    };
  }

  public async assign(ids: string | string[], userId: string, comment?: string) {
    if (!ids || ids.length === 0 || !userId) {
      return;
    }

    return await this.s.marketing.post<void>("conversations/assign", {
      Ids: Array.isArray(ids) ? ids.join() : ids.toString(),
      UserId: userId,
      Comment: comment
    });
  }

  public async setStatus(ids: string | string[], status: ConversationStatus) {
    if (!ids || ids.length === 0) {
      return;
    }

    return await this.s.marketing.post<void>("conversations/status", {
      Ids: Array.isArray(ids) ? ids.join() : ids,
      Status: status
    });
  }

  public async save(
    type: ConversationFilterType,
    deliveryAddress: string,
    contactId: string,
    assignedToUserId: string,
  ) {
    const res = await this.s.marketing.post<Conversation>("conversations", {
      Type: type,
      DeliveryAddress: deliveryAddress,
      ContactId: contactId,
      AssignedToUserId: assignedToUserId,
    });
    return res.body;
  }

  public async sendMessage(conversationId: string, message: string) {
    const res = await this.s.marketing.post<void>("conversations/send_message", {
      ConversationId: conversationId,
      Message: message,
    });
    return res.body;
  }

  public async getNotificationCounts() {
    const res = await this.s.marketing.get<any>("conversations/notification_counts");
    return createFrom(ConversationNotificationCounts, res.body?.counts);
  }
}
