import { FormForType, formFor, Validators } from "fw-model";
const { wrap, isNumber } = Validators;

import { PdfExportTemplate, PdfExportTemplateSetOptions, PdfExportTemplateSet } from "models/pdf-export-template";

export type FormForPdfExportTemplate = FormForType<PdfExportTemplate>;
export type FormForPdfExportTemplateSetOptions = FormForType<PdfExportTemplateSetOptions>;
export type FormForPdfExportTemplateSet = FormForType<PdfExportTemplateSet>;


export type FormForPdfExportTemplateHash = {
  [key: string]: FormForPdfExportTemplate;
};

export const pdfExportTemplateSetOptionsFormCreator = formFor(PdfExportTemplateSetOptions, s => {
  s.requiredField(f => f.MarginInches, "Margin", wrap(isNumber));
});

export const pdfExportTemplateFormCreator = formFor(PdfExportTemplate, s => {
  s.field(et => et.Template, "Template");
});

export const pdfExportTemplateSetFormCreator = formFor(PdfExportTemplateSet, s => {
  s.form(f => f.Options, "Options", pdfExportTemplateSetOptionsFormCreator);
  s.formProperty(f => f.Templates, "Templates", pdfExportTemplateFormCreator);
});


export class UpdatePdfExportTemplateSetAction {
  constructor(
    public setKey: string,
    public form: FormForPdfExportTemplateSet
  ) {}
}
