import { inject, prop, ComponentEventBus } from "@derekpitt/fw"
import { DateFormatter } from "../../date-formatter";

import { type WidgetContext, GridViewWidgetData } from "../../models";

const palette = [
  // "#E1BEE7",
  // "#D1C4E9",
  // "#C5CAE9",
  // "#BBDEFB",
  // "#B2EBF2",
  // "#B2DFDB",
  // "#C8E6C9",
  // "#C5E1A5",
  // "#E6EE9C",
  // "#FFF59D",
  "rgba(31, 119, 180, .5)",
  "rgba(174, 199, 232, .5)",
  "rgba(255, 127, 14, .5)",
  "rgba(255, 187, 120, .5)",
  "rgba(44, 160, 44, .5)",
  "rgba(152, 223, 138, .5)",
  "rgba(214, 39, 40, .5)",
  "rgba(255, 152, 150, .5)",
  "rgba(148, 103, 189, .5)",
  "rgba(197, 176, 213, .5)",
  "rgba(140, 86, 75, .5)",
  "rgba(196, 156, 148, .5)",
  "rgba(227, 119, 194, .5)",
  "rgba(247, 182, 210, .5)",
  "rgba(127, 127, 127, .5)",
  "rgba(188, 189, 34, .5)",
  "rgba(219, 219, 141, .5)",
  "rgba(23, 190, 207, .5)",
  "rgba(158, 218, 229, .5)",
];

@inject
export class GridViewChart {
  @prop(null) clientData!: string;
  @prop(null) data!: GridViewWidgetData;
  @prop(null) context!: WidgetContext;
  @prop(false) interactive!: boolean;

  private dateFormatter: DateFormatter = new DateFormatter();

  constructor(private ceb: ComponentEventBus) {
  }

  attached() {
    this.dataChanged();
  }

  get headers() {
    return this.data?.Definition?.Columns || [];
  }
  get rows() {
    return this.data?.Rows || [];
  }

  get hasUser() {
    return this.data?.Definition?.UserId || this.context;
  }

  dataChanged() {
    if (this.data == null) return;
  }

  format(column) {
    switch (column.Type) {
      case "date":
        return this.dateFormatter.formatDate(column.Value, {
          fromNow: true,
          dateOnly: true,
          useTimezone: true,
          timezone: this.data.Timezone
        });
      case "datetime":
        return this.dateFormatter.formatDate(column.Value, {
          useTimezone: true,
          timezone: this.data.Timezone
        });
      case "tags":
        return column.Value?.join(", ");
      case "array":
        return column.Value?.map(j => JSON.stringify(j))?.join(", ");
      default:
        return column.Value;
    }
  }

  backgroundColor(tag): string {
    if (!tag)
      return palette[0];

    let num = 0;
    for (let i = 0; i < tag.length; i++) {
      num += tag.charCodeAt(i)
    }
    return palette[num % palette.length];
  }

  get color(): string {
    return "#000";
  }

  dataClick(row) {
    if (!this.interactive || row.Data == null) return;
    this.ceb.dispatch("data-click", row.Data);
  }
}
