import { ComponentEventBus, inject, prop } from "fw";
import type { ICustomFieldDefinition, ICustomFieldOption } from "models/contact-organization";
import { FieldMode } from "views/contacts/components/field-mode";
import { IDropdownFieldOptions } from "views/components/dropdown-field";

import { orderBy } from "lodash-es";
import { ContactsOrganizationService } from "service/contacts-organization";
import { FeatureFlagService } from "service/feature-flag";
import { ContactFieldOptionsResolverService } from "views/contacts/contact-field-options-resolver-service";
import { FieldContext } from "../field";

@inject
export class DropdownType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(false) public showClear!: boolean;
  @prop(null) private context!: FieldContext;
  @prop(false) private isFilter!: boolean;

  public options: IDropdownFieldOptions[] = [];

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService,
    private ffs: FeatureFlagService,
    private contactFieldOptionsResolver: ContactFieldOptionsResolverService
  ) {}

  public async attached() {
    await this.processOptions();
  }

  private async processOptions() {
    if (this.mode !== FieldMode.Edit) {
      return;
    }

    if (!this.field || !this.field.options) {
      return;
    }

    let fieldOptions = orderBy(this.field.options, "display_order").filter(
      (o) => o !== null
    );

    if (
      !this.isFilter &&
      this.context?.contact
    ) {
      fieldOptions = await this.contactFieldOptionsResolver.resolveOptions(
        this.context.contact,
        fieldOptions
      );
    }

    const dropdownFieldOptions = fieldOptions.map(
      (o) => <IDropdownFieldOptions>{ value: o.name, text: o.name }
    );

    this.options = dropdownFieldOptions.length
      ? dropdownFieldOptions
      : [<IDropdownFieldOptions>{ value: null, text: "No values", disabled: true }];
  }

  public async fieldChanged() {
    await this.processOptions();
  }

  public get metaDescription() {
    return this.showMeta
      ? this.contactOrganizationService.getFieldMetaDescription(this.field)
      : null;
  }

  public get label() {
    return this.showLabel ? this.field.display_name : null;
  }

  public get localValue() {
    return this.value;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", value);
  }
}
