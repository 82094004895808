import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer, AnswerOption } from "../../models";

@inject
export class DropdownType {
  @prop(null) question!: Question;
  @prop(null) answerOptions:AnswerOption[];
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledby;

  @prop(null) requestCeeb;
  @prop(null) fileHash;
  @prop(null) fileService;
  @prop(null) showOptions;

  private value = null;
  private valueText = null;

  constructor(private ecb: ComponentEventBus) { }

  attached() {
    this.answerChanged();
  }

  answerChanged() {
    const { FormAnswerOption } = this.answer;
    this.value = FormAnswerOption != null ? FormAnswerOption.AnswerOptionId : null;

    const option = this.question.AnswerOptions.find(o => o.Id == this.value);
    this.valueText = option != null ? option.Label : null;
  }

  change() {
    const option = this.question.AnswerOptions.find(o => o.Id == this.value);
    this.valueText = option != null ? option.Label : null;

    this.answer.FormAnswerOption = this.value != null ? { AnswerOptionId: this.value, OptionText: this.valueText } : null;
    this.ecb.dispatch("answer-changed");
  }

}
