import { ComponentEventBus, inject, needs, prop } from "fw";

import { PopoverService } from "service/popover";
import { ApplicationStore } from "state/application";
import { CurrentOrganizationStore } from "state/current-organization";
import { CurrentUserStore } from "state/current-user";
import { ExportDownloadStore } from "state/export-download";

import { FeatureFlagService } from "service/feature-flag";
import { OrganizationModuleService } from "service/organization-module";
import { ConversationPermissionService } from "service/permissions/conversation";
import { EventPermissionService } from "service/permissions/event";
import { ConversationStore } from "state/conversations";
import { MarketingPermissionService } from "service/permissions/marketing";
import { NotificationsStore } from "state/notifications";
import { UserNavLink } from "views/components/user-nav-link";
import { HelpPopover } from "./help-popover";
import { ModulePopover } from "./module-popover";
import { OrgPopover } from "./org-popover";
import { TaskStatus } from "./task-status";
import { MyToolsPopover } from "./my-tools-popover";
import { EnsureInterviewPortalAction, InterviewPortalStore } from "state/interview-portal";
import { dispatch } from "fw-state";
import { EnsurePortalsStoreAction, EnsureReviewPortalStoreAction, PortalsStore } from "state/portals";
import { NavDefaultLogo } from "./nav-default-logo";

interface NavBarConfig {
  [key: string]: {name:string, route: string, enabled: boolean};
}
@inject
@needs(
  TaskStatus,
  UserNavLink,
  NavDefaultLogo
)
export class AtsHeader {
  @prop(null) private router;
  @prop(false) public forceCollapsed: boolean;
  private currentModule:
    | "analytics"
    | "recruitment"
    | "admissions"
    | "payment-journal"
    | "marketing"
    | "calendar"
    | "reports"
    | "tasks"
    | "settings" = null;

  public forceWide: boolean = false;
  private mouseTimer = null;

  constructor(
    private popover: PopoverService,
    private ffs: FeatureFlagService,
    private currentUser: CurrentUserStore,
    private currentOrganizationStore: CurrentOrganizationStore,
    private portalStore: PortalsStore,
    private exportDownloadStore: ExportDownloadStore,
    private appStore: ApplicationStore,
    private organizationModuleService: OrganizationModuleService,
    private notificationsStore: NotificationsStore,
    private conversationStore: ConversationStore,
    private ceb: ComponentEventBus,
    private conversationPermissionService: ConversationPermissionService,
    private eventPermissionService: EventPermissionService,
    private marketingPermissionService: MarketingPermissionService,
    private interviewPortalStore: InterviewPortalStore,
  ) { }

  public async attached() {
    if (this.currentOrganizationStore.hasReviewPortalFeatureEnabled) {
      await dispatch(new EnsureReviewPortalStoreAction());
    }

    if (this.isInterviewFeatureEnabled) {
      await dispatch(new EnsureInterviewPortalAction());
    }
  }

  get navbarLogoUrl() {
    return this.currentOrganizationStore.state.portalContext?.OrganizationPortal.Content.CompactLogoUrl;
  }

  get whichModule() {
    if (this.router != null) {
      switch (this.router.current) {
        case "report-routes":
        case "analytics-routes":
        case "home":
          this.currentModule = "analytics";
          break;
        case "contacts-routes":
          this.currentModule = "recruitment";
          break;
        case "applicant-routes":
          this.currentModule = "admissions";
          break;
        case "marketing-routes":
          this.currentModule = "marketing";
          break;
        case "calendar-routes":
          this.currentModule = "calendar";
          break;
        case "tasks-routes":
          this.currentModule = "tasks";
          break;
        case "settings":
          this.currentModule = "settings";
          break;
      }
    }
    return this.currentModule;
  }

  clickCollapse() {
    this.forceWide = false;
    this.ceb.dispatch("nav-collapsed", true);
  }

  clickExpand() {
    this.ceb.dispatch("nav-collapsed", false);
    this.forceWide = true;
  }

  public mouseEnterNav() {
    clearTimeout(this.mouseTimer);
  }

  private openOrgPopover() {
    this.popover.open(OrgPopover);
  }

  private popHelpMenu() {
    this.popover.open(HelpPopover);
  }

  private popModuleMenu() {
    this.popover.open(ModulePopover, this.router);
  }

  public popMyTools() {
    this.popover.open(MyToolsPopover, this.router);
  }

  public canShowMyTools(trigger:any): boolean {
    return this.allowInterviewPortalSwitch || this.allowReviewPortalSwitch();
  }

  private get allowInterviewPortalSwitch(): boolean {

    const { Enabled:portalEnabled = false, InterviewerUserIds:interviewerIds = [], ProgramAssistantUserIds:assistantIds = [], ProgramDirectorUserIds:directorIds = [] } = this.interviewPortalStore.state?.interviewPortal || {};
    const userIsAssociated = [...interviewerIds, ...assistantIds, ...directorIds].includes(this.currentUser.state.user.Id);

    return this.isInterviewFeatureEnabled && portalEnabled && userIsAssociated;
  }

  private get isInterviewFeatureEnabled(): boolean {
    const ffInterviewManagement = this.ffs.isFeatureFlagEnabled("InterviewManagement");
    const ffViewInterviewPortalOnSidebar = this.ffs.isFeatureFlagEnabled("ViewInterviewPortalOnSidebar");
    const haveInterviewOrgFeature = !!this.currentOrganizationStore.state.organization?.Features?.InterviewPortal;
    return ffInterviewManagement && ffViewInterviewPortalOnSidebar && haveInterviewOrgFeature;
  }

  private allowReviewPortalSwitch(): boolean {
    const reviewPortalEnabled = this.currentOrganizationStore.hasReviewPortalFeatureEnabled;
    const reviewPortal = this.portalStore.state.reviewPortals?.length > 0 ? this.portalStore.state.reviewPortals[0] : null;
    const associated = reviewPortal && !!this.currentUser.state.portalIds?.includes(reviewPortal.Id);
    return reviewPortalEnabled && reviewPortal && associated;
  }

  get orgName() {
    const { organization } = this.currentOrganizationStore.state;
    if (organization == null || organization.Name == null)
      return "";
    return organization.Name;
  }

  get newTaskIds() {
    if (this.exportDownloadStore.state.newIds == null) return 0;
    return this.exportDownloadStore.state.newIds.length;
  }

  get globalPrincipalMode() {
    return this.appStore.state.globalPrincipalMode;
  }

  get featureBulkMessages() {
    return this.marketingPermissionService.canAccess;
  }

  get featureCalendarModule() {
    return this.ffs.isFeatureFlagEnabled("CalendarModule")
      && this.eventPermissionService.canView;
  }

  get featureSMSConversationsInbox() {
    return this.ffs.isFeatureFlagEnabled("SMSConversationsInbox")
      && this.conversationPermissionService.canAccess;
  }

  get featureHelpLink() {
    return this.ffs.isFeatureFlagEnabled("ShowHelpLink");
  }

  get canAccessBulkMessages() {
    return this.marketingPermissionService.canAccessBulkMessages && this.customization.quickMessagesEnabled;
  }

  get canAccessCampaigns() {
    return this.marketingPermissionService.canAccessCampaigns && this.customization.campaignBuilderEnabled;
  }

  get canAccessTemplates() {
    return this.marketingPermissionService.canAccessTemplates && this.customization.marketingTemplatesEnabled;
  }

  get unreadNotifications() {
    const { profile } = this.notificationsStore.state;
    return profile != null ? profile.Unread : 0;
  }

  get historyCount() {
    return this.unreadNotifications + this.newTaskIds;
  }

  get conversationNotification(): string {
    const open: number = this.conversationStore.state?.notificationCounts?.OpenConversations || 0;
    const unassigned: number = this.conversationStore.state?.notificationCounts?.UnassignedConversations || 0;
    let output = '0';
    if (unassigned > 0) {
      output = '!';
    } else if (open > 9) {
      output = '9+';
    } else if (open > 0) {
      output = `${open}`;
    }
    return output;
  }

  get isMarketingRoute() {
    return this.router.current === "marketing-routes";
  }

  public getNavLabel(labelType: string) {
    return this.customization.getNavLabel(labelType);
  }

  get customization() {
    return this.currentOrganizationStore.state.customization;
  }

  public getBadge(count) {
    return count > 99 ? '99+' : count
  }

  public getBadgeTitle(count) {
    return count > 99 ? count : null
  }
}
