import { prop } from 'fw';
import type { GoalView } from 'state/goals';
import * as d3 from 'd3';
import { GoalHistoryChart } from 'models/goal-history';
import addDays from "date-fns/addDays"

export class GoalProgressChart {
  private readonly width: number = 900;
  private readonly height: number = 400;
  private readonly margin = {
    top: 20,
    bottom: 30,
    left: 45,
    right: 20,
  };

  @prop(null) view!: GoalView;
  private chartEl: SVGElement;

  public attached() {
    this.render();
  }

  private render() {
    // derived
    const width = this.width - this.margin.left - this.margin.right;
    const height = this.height - this.margin.top - this.margin.bottom;
    const goalStartDate = new Date(this.view.definition.StartDateUtc);
    const goalEndDate = new Date(this.view.definition.EndDateUtc);
    const maxPercent = d3.max([...(this.view.history || []).map(h => h.percent), 100])
    const goalFulfilledLineHeight = height * (1 - (100 / maxPercent)) + 0.5;
    const percentCompleteLineHeight = height * (1 - (this.view.completionPercentage / maxPercent)) + 0.5;
    const totalDays: number = this.view.daysElapsed + this.view.daysRemaining;
    // parse dates
    const data: GoalHistoryChart[] = (this.view.history || []).map(d => {
      d.d3Date = d3.timeParse('%Y-%m-%dT%H:%M:%S.%fZ')(d.date);
      return d;
    });

    // configure axis scales
    const xScale = d3.scaleTime()
      .domain([goalStartDate, goalEndDate])
      .range([0, width])
    const yScale = d3.scaleLinear()
      .domain([0, maxPercent])
      .range([height, 0])

    // dynamically render time ticks along x-axis
    let timeTicksPer: d3.CountableTimeInterval = d3.timeYear;
    let tickFormat = d3.timeFormat('%Y');
    if (totalDays < 21) {
      timeTicksPer = d3.timeDay;
      tickFormat = d3.timeFormat('%a %d');
    } else if (totalDays < 90) {
      timeTicksPer = d3.timeWeek;
      tickFormat = d3.timeFormat('%b %d');
    } else if (totalDays < 730) {
      timeTicksPer = d3.timeMonth;
      tickFormat = d3.timeFormat('%b');
    }

    // add svg to page
    const svg = d3.select(this.chartEl)
      .attr('width', this.width)
      .attr('height', this.height)
      .append('g')
      .attr('transform',
        `translate(${this.margin.left}, ${this.margin.top})`)

    // add x-axis (time)
    svg.append('g')
      .attr('transform', `translate(0, ${height})`)
      .attr('class', 'axis')
      .call(g => {
        g.call(d3
          .axisBottom(xScale)
          .ticks(timeTicksPer)
          .tickFormat(tickFormat)
        )
        g.selectAll('.tick text')
          .style('fill', '#666')
          .style('font-size', '12px')
      })

    // add goal history chart
    const areaData: GoalHistoryChart[] = [
      {
        percent: this.view.completionPercentage,
        d3Date: goalStartDate,
        date: goalStartDate.toUTCString(),
        count:  0,
      },
      {
        percent: this.view.completionPercentage,
        d3Date:  goalEndDate,
        date: goalStartDate.toUTCString(),
        count:  0,
      }
    ];

    svg.append('path')
      .datum(areaData as unknown)
      .attr('class', 'area')
      .attr('d', d3.area()
        .x((d: unknown) => xScale((d as GoalHistoryChart).d3Date))
        .y0(yScale(0))
        .y1((d: unknown) => yScale((d as GoalHistoryChart).percent))
      )
      .style('fill', '#d3e3fa')
      .style('stroke', '#3397dc')
      .style('stroke-width', 1.5)

    // add y-axis (percent)
    svg.append('g')
      .call(g => {
        g.call(d3
          .axisLeft(yScale)
        )
        g.selectAll('.tick text')
          .style('fill', '#666')
          .style('font-size', '12px')
      })

    // add trendline
    svg.append('line')
      .attr('x1', width)
      .attr('y1', goalFulfilledLineHeight)
      .attr('x2', 0)
      .attr('y2', height)
      .style('stroke', 'green')
      .style('stroke-dasharray', ('5, 5'))

    // define tooltip div
    const tooltipDiv = d3.select('body')
      .append('div')
      .attr('class', 'd3-tooltip')
      .style('opacity', 0)

    // add goal history hover points
    svg.selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
        .attr('cx', (d: GoalHistoryChart) => xScale(d.d3Date))
        .attr('cy', (d: GoalHistoryChart) => yScale(d.percent))
        .attr('r', 10)
        .style('fill', 'rgba(0, 0, 0, 0)')
        .style('stroke', 'none')
        .on('mouseover', (d: GoalHistoryChart) => {
          tooltipDiv.transition()
            .duration(200)
            .style('opacity', .9)

          tooltipDiv.html(`
            ${new Date(d.date).toLocaleDateString('en-us', {year:'numeric', month:'short', day:'numeric', timeZone:'UTC'})}
            <br/>
            ${d.count} Application${1 !== d.count ? 's' : ''}
           `)
            .style('left', (d3.event.pageX - 120) + 'px')
            .style('top', (d3.event.pageY - 65) + 'px')
        })
        .on('mouseout', (d: GoalHistoryChart) => {
          tooltipDiv.transition()
            .duration(500)
            .style('opacity', 0)
        })

    // add horizontal percentage line
    svg.append('line')
      .attr('x1', 0)
      .attr('y1', percentCompleteLineHeight)
      .attr('x2', width)
      .attr('y2', percentCompleteLineHeight)
      .style('stroke', '#0ca7d2')

    // add horizontal percentage line text
    svg.append('text')
      .attr('x', width - this.margin.right - 8)
      .attr('y', percentCompleteLineHeight - 8)
      .attr('dy', '.35em')
      .style('font-size', '12px')
      .style('fill', '#0ca7d2')
      .text(`${this.view.completionPercentage}%`)
  }
}
