import { inject } from "fw";
import { Store, handle } from "fw-state";

import { PortalInvitation } from "models/portal-invitation";
import { PortalInvitationRepository } from "network/portal-invitation-repository";
import { StartAction, LogoutAction, UpdateInviteCountAction, PortalInvitationsRefreshListAction } from './actions';

export class PortalInvitationsNextPageAction {}
export class PortalInvitationsPrevPageAction {}

export class PortalInvitationsEnsureListAction {
  constructor(public filter: string, public search: string, public forceReload: boolean = false) {}
}

export class PortalInvitationsDeleteAction {
  constructor(public portalInvitationIds?: string[]) {}
}
export class PortalInvitationsDeleteAllAction {
  constructor(public filter: string, public search: string) {}
}

export class PortalInvitationsSendAction{
  constructor(public portalInvitationIds?: string[]) {}
}

export class PortalInvitationsSendAllAction {
  constructor(public filter: string, public search: string) {}
}

export interface PortalInvitationShape {
  organizationTotal: number;
  invitations: PortalInvitation[];
  totalCount: number;
  loading: boolean;
  loaded: boolean;
  errorLoading: boolean;
  pageSize: number;
  currentPage: number;
  filter: string;
  search: string;

  sendingTaskId: string;
}

const combineFilters = (filters: string[], op = "AND") => {
  const nonNullFilters = filters.filter(a => a != null && a.trim().length > 0);
  return nonNullFilters.map(a => `(${a})`).join(` ${op} `)
};

@inject
export class PortalInvitationsStore extends Store<PortalInvitationShape> {
  constructor(
    private portalInvitationRepository: PortalInvitationRepository,
  ) {
    super();
  }

  defaultState() {
    return {
      organizationTotal: 0,
      invitations: [],
      totalCount: 0,
      loaded: false,
      loading: false,
      errorLoading: false,
      pageSize: 10,
      currentPage: 1,
      filter: null,
      search: null,
      sendingTaskId: null,
    };
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    const { PortalInvitationCount } = action.context;

    this.setState(state => ({
      ...this.defaultState(),
      organizationTotal: PortalInvitationCount,
    }));
  }

  @handle(UpdateInviteCountAction)
  private async handleUpdateInviteCountAction() {
    const res = await this.portalInvitationRepository.getOrganizationCount();

    this.setState(state => ({
      ...state,
      organizationTotal: res,
    }));
  }

  @handle(PortalInvitationsDeleteAction)
  async handlePortalInvitationsDeleteAction(action: PortalInvitationsDeleteAction) {
    await this.portalInvitationRepository.deleteByIds(action.portalInvitationIds);
    await this.update();
  }

  @handle(PortalInvitationsDeleteAllAction)
  async handlePortalInvitationsDeleteAllAction(action: PortalInvitationsDeleteAllAction) {
    const f = combineFilters([ action.filter, this.state.filter ]);
    await this.portalInvitationRepository.deleteAll(f, action.search);
    await this.update();
  }

  @handle(PortalInvitationsSendAction)
  async handlePortalInvitationsSendAction(action: PortalInvitationsSendAction) {
    await this.portalInvitationRepository.send(action.portalInvitationIds);
    await this.update();
  }

  @handle(PortalInvitationsSendAllAction)
  async handlePortalInvitationsSendAllAction(action: PortalInvitationsSendAllAction) {
    const f = combineFilters([ action.filter, this.state.filter ]);
    const res = await this.portalInvitationRepository.sendAll(f, action.search);
    this.setState(state => ({
      ...state,
      sendingTaskId: res.taskRequestId,
    }));
    await this.update();
  }

  @handle(PortalInvitationsNextPageAction)
  private async handlePortalInvitationsNextPageAction() {
    const currentPage = this.state.currentPage + 1;
    this.setState(state => ({
      ...state,
      currentPage,
      loading: true,
      selectAll: false,
    }));

    await this.update();
  }

  @handle(PortalInvitationsPrevPageAction)
  private async handlePortalInvitationsPrevPageAction() {
    let currentPage = this.state.currentPage - 1;
    if (currentPage < 0) currentPage = 0;

    this.setState(state => ({
      ...state,
      currentPage,
      loading: true,
      selectAll: false,
    }));

    await this.update();
  }

  @handle(PortalInvitationsRefreshListAction)
  private async handlePortalInvitationsRefreshListAction() {
    this.setState(state => ({
      ...state,
      loading: true
    }));

    await this.update();
  }

  @handle(PortalInvitationsEnsureListAction)
  async handlePortalInvitationsEnsureListAction(action: PortalInvitationsEnsureListAction) {
    const { filter, search, loaded  } = this.state;
    if (!action.forceReload && action.filter == filter && action.search == search && loaded) return;

    this.setState(state => ({
      ...state,
      filter: action.filter,
      search: action.search,
      page: 1,
      loading: true,
      loaded: false
    }));

    await this.update();
  }

  private async update() {
    try {
      const { filter, search, currentPage, pageSize } = this.state;
      const res = await this.portalInvitationRepository.list(filter, search, "metaData.lastName metaData.firstName", currentPage, pageSize);
      const invitations = res.list;

      const organizationTotal = await this.portalInvitationRepository.getOrganizationCount();

      this.setState(state => ({
        ...this.state,
        errorLoading: false,
        loading: false,
        loaded: true,
        organizationTotal,
        invitations,
        totalCount: res.total,
        sendingTaskId: organizationTotal == 0 ? null : state.sendingTaskId,
      }));

    } catch (err) {
      this.setState(state => ({ ...state, errorLoading: true }));
    }
  }
}
