import { inject } from "fw";
import { FeatureFlagService } from 'service/feature-flag';
import { PopoverController } from "service/popover";

@inject
export class PermissionDescriptionPopover {
  private allowedPages: string[];

  constructor(
    private controller: PopoverController<string>,
  ) {}

  activate(params: {allowedPages: string[]}) {
    this.allowedPages = params.allowedPages;
  }

  selectType(r: string) {
    this.controller.ok(r);
  }

  close() {
    this.controller.close();
  }
}
