export class GetImageUploadUrlResult {
  id: string;
  type: string;
  upload_url?: string;
}

export class ImageInfo {
  id: string;
  type: string;
  path: string;
  url: string;
}
