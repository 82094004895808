import { inject, needs } from "fw";

import { PopoverController } from "service/popover";
import { TaskRequestStore } from "state/task-request";

import { TaskProgress } from "./task-progress";

@inject
@needs(TaskProgress)
export class TaskListingPopover {
  constructor(
    private controller: PopoverController<any>,
    private taskRequestStore: TaskRequestStore
  ) { }
}
