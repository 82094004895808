import { ComponentEventBus, inject, prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { FieldMode } from "../field-mode";
import { DateService } from "service/date-service";
import { ContactsOrganizationService } from "service/contacts-organization";
import { IDropdownFieldOptions } from "views/components/dropdown-field";
import { generateDataId } from "helpers/data-id-gen";

@inject
export class DateType {
  @prop(null) public field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(false) public showClear!: boolean;

  public day: number = null;
  public monthIndex: number = null;
  public year: number = null;
  public monthOptions: IDropdownFieldOptions[] = [
    { value: 0, text: "JAN" },
    { value: 1, text: "FEB" },
    { value: 2, text: "MAR" },
    { value: 3, text: "APR" },
    { value: 4, text: "MAY" },
    { value: 5, text: "JUN" },
    { value: 6, text: "JUL" },
    { value: 7, text: "AUG" },
    { value: 8, text: "SEP" },
    { value: 9, text: "OCT" },
    { value: 10, text: "NOV" },
    { value: 11, text: "DEC" },
  ];

  constructor(
    private ceb: ComponentEventBus,
    private dateService: DateService,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  public attached() {
    this.valueChanged();
  }

  public valueChanged() {
    if (this.value && this.mode == FieldMode.Edit) {
      const date = this.dateService.extractDateOnly(this.value);
      if(!date) return;
      this.year = date.getFullYear();
      this.monthIndex = date.getMonth();
      this.day = date.getDate();
    } else {
      this.year = null;
      this.day = null;
      this.monthIndex = null;
    }
  }

  public get dayOptions(): IDropdownFieldOptions[] {
    const currentYear = this.year || new Date().getFullYear();
    const numberOfDays = new Date(
      currentYear,
      this.monthIndex + 1,
      0
    ).getDate();
    return Array.from(Array(numberOfDays).keys()).map((d) => ({
      value: ++d,
      text: d.toString(),
    }));
  }

  public get dayOnly(): string {
    return this.value && this.dateService.parseDateOnly(this.value);
  }

  public dateChanged() {
    if (!this.year || this.monthIndex === null || !this.day) {
      return;
    }

    let date = new Date(Date.UTC(this.year, this.monthIndex, this.day || 1));
    if (!isNaN(date.getDate())) {
      this.ceb.dispatch("update:value", date.toISOString().substring(0, 10));
    }
  }

  public get metaDescription() {
    return this.showMeta
      ? this.contactOrganizationService.getFieldMetaDescription(this.field)
      : null;
  }

  public get dataId() {
    return generateDataId(null, this.field.display_name);
  }

  public clear() {
    this.ceb.dispatch("update:value", null);
    this.year = null;
    this.day = null;
    this.monthIndex = null;
  }

  public get valueFilled(): boolean {
    return this.year !== null || this.day !== null || this.monthIndex !== null;
  }

}
