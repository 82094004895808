import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { FileProvider } from "models/file-provider";

@inject
export class FileRepositoryRepository {
  constructor(private s: ATS) { }

  async list() {
    const res = await this.s.get<any[]>("file-provider");
    return createFromArray(FileProvider, res.body);
  }

  async post(fileProvider: FileProvider) {
    const res = await this.s.post(`file-provider`, fileProvider);
    return createFrom(FileProvider, res.body);
  }

  async getById(fileProviderId: string) {
    const res = await this.s.get(`file-provider/${fileProviderId}`);
    return createFrom(FileProvider, res.body);
  }

  async put(fileProvider: FileProvider) {
    const res = await this.s.put(
      `file-provider/${fileProvider.Id}`,
      fileProvider,
    );
    return createFrom(FileProvider, res.body);
  }

  public async del(ids: string[]) {
    await this.s.delete(`file-provider/${ids.join(",")}`);
  }

  public async deleteFolder(id: string, folder: string) {
    const res = await this.s.post(`file-provider/${id}/delete-folder`, null, {
      folder,
    });
    return createFrom(FileProvider, res.body);
  }
}
