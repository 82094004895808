import { inject, NetworkException } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { FormErrorHandling } from "./error-handling";
import { RoleRepository } from "network/role-repository";

import { Role } from "models/role";
import { AddRoleAction, UpdateRoleAction, DeleteRoleAction } from "forms/role";

import { LogoutAction, StartAction } from "./actions";
import { difference } from "lodash-es";
import { EnsureListAction } from "./users";

interface RolesShape {
  roles: Role[];
}

@inject
export class RolesStore extends Store<RolesShape> {
  constructor(private roleRepo: RoleRepository) {
    super();
  }

  defaultState() {
    return {
      roles: [],
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    const { Roles } = s.context;

    this.setState(state => ({
      roles: Roles,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(AddRoleAction, FormErrorHandling)
  private async handleAddRoleAction(action: AddRoleAction) {
    action.form.validate();

    const newModel = action.form.updatedModel();
    if (!newModel.Permissions.includes("ViewContacts")) {
      newModel.Permissions.push("ViewContacts");
    }
    try {
      const newRole = await this.roleRepo.post(newModel);
      action.added = newRole;

      this.setState(state => ({
        roles: [...state.roles, newRole],
      }));

    } catch(err) {
      if (err instanceof NetworkException) {
        if (err.result.Message && err.result.Message.length) {
          action.form.validationMessages = [err.result.Message];
          action.form.isInvalid = true;
        }
      }
      throw err;
    }
  }

  @handle(UpdateRoleAction, FormErrorHandling)
  private async handleUpdateRoleAction(action: UpdateRoleAction) {
    action.form.validate();

    const newModel = action.form.updatedModel();
    if (!newModel.Permissions.includes("ViewContacts")) {
      newModel.Permissions.push("ViewContacts");
    }

    try {
      const updatedRole = await this.roleRepo.put(newModel, action.force);
      action.updated = updatedRole;

      const existingRole = this.state.roles.find(t => t.Id == updatedRole.Id);
      if (existingRole == null) return;

      Object.assign(existingRole, updatedRole);

      this.setState(s => s);
    } catch (err) {
      if (err instanceof NetworkException) {
        if (err.headers["x-ats-supports-force"] == "true") {
          action.canForce = true;
        } else if (err.result.Message && err.result.Message.length) {
          action.form.validationMessages = [err.result.Message];
          action.form.isInvalid = true;
        }
      }
      throw err;
    }

  }

  @handle(DeleteRoleAction)
  private async handleDeleteRoleAction(action: DeleteRoleAction) {
    const role = this.state.roles.find(r => r.Id == action.roleId);
    if (role == null) return;

    await this.roleRepo.del(action.roleId);
    this.setState(state => ({
      roles: difference(state.roles, [role]),
    }));

    // Reload users list (currently filtered by recently deleted id)
    await dispatch(new EnsureListAction(null, null));
  }
}
