import { prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { ISlideRoomApplication } from "models/contact";
import { FieldMode } from "../field-mode";

import { orderBy } from "lodash-es";

export class SlideroomapplicationsType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: ISlideRoomApplication[];
  @prop(FieldMode.View) public mode!: FieldMode;

  private sortOrderAsc: "asc" = "asc";
  private sortOrderDesc: "desc" = "desc";

  public selectedSort: string = 'submitted';
  public selectedSortDirection: "asc" | "desc" = this.sortOrderAsc;

  get statuses(): string[] {
    return this.value
      ? this.value.map((value) => value.status).filter((item, index, items) => item && items.indexOf(item) == index)
      : [];
  }

  public get gridValue() : string {
    if (!this.value || this.value.length == 0)
      return null;

    let value: string = `${this.value.length} application${this.value.length === 1 ? "" : "s"}`;

    const statuses = this.statuses;
    if (statuses.length === 1) {
      return `${value} (${statuses[0]})`;
    }

    return value;
  }

  public applicationLink(application: ISlideRoomApplication): string {
    return `https://review.slideroom.com/#/application/${application.application}`;
  }

  public orderApplications(applications: ISlideRoomApplication[]): ISlideRoomApplication[] {
    switch (this.selectedSort) {
      case "program":
        return orderBy(applications, [a => (a.program || "").toLowerCase()], this.selectedSortDirection);
      case "submitted":
        return orderBy(applications, [a => a.submitted ? new Date(a.submitted) : a.submitted], this.selectedSortDirection);
      case "status":
        return orderBy(applications, [a => !isNaN(parseInt(a.status)) ? parseInt(a.status) : a.status], this.selectedSortDirection);
    }

    return orderBy(applications, this.selectedSort, this.selectedSortDirection);
  }

  public setSort(sort: string) {
    if (sort !== this.selectedSort) {
      this.sort(sort, this.sortOrderAsc);
    } else {
      this.sort(sort, this.selectedSortDirection === this.sortOrderDesc ? this.sortOrderAsc : this.sortOrderDesc);
    }
  }

  private sort(sort: string, direction: "asc" | "desc") {
    this.selectedSort = sort;
    this.selectedSortDirection = direction;
  }
}
