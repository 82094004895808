import { inject, needs } from "fw";

import { Dashboard } from "./dashboard";

@inject
@needs(Dashboard)
export class Home {
  constructor() {}
}

export default Home;
