import { RouterConfig, needs, inject } from "fw";
import { dispatch } from "fw-state";

import { ReportRoutes } from "views/reports/reports-routes";
import { Settings } from "views/settings/settings";

import { AtsHeader } from "views/header/ats-header";

import { Login } from "views/login/login";
import { ChooseOrganization } from "views/login/choose-organization/choose-organization";
import { NoPermission } from "views/login/no-permission";
import { LoggedOut } from "views/login/logged-out";
import { AcceptInvite } from "views/invite/accept-invite";
import { ForgotPassword } from "views/login/forgotpassword";
import { ResetPassword } from "views/login/resetpassword";
import { ConfirmEmailChange } from "views/login/confirm-change-email";

import { RequireAuth } from "router-middleware/require-auth";
import { RequireGlobal } from "router-middleware/require-global";
import { HideChrome } from "router-middleware/hide-chrome";
import { RequirePermission } from "router-middleware/require-permission";
import { RouterPath } from "router-middleware/router-path";
import { LandingPageOrFirstEnabled } from "router-middleware/landing-page-or-first-enabled";
import { RequireOrganizationPortalContext } from "router-middleware/require-organization-portal-context";

import { ApplicationStore } from "state/application";
import { CurrentOrganizationStore } from "state/current-organization";
import { CurrentUserStore, UpdateLastPageAction } from "state/current-user";

import { OrganizationModuleService } from "service/organization-module";

import { AnalyticsRoutes } from "views/analytics/analytics-routes";
import { SwitchOrganizations } from "views/account/switch-organizations";
import Notifications from "views/notifications/notifications";
import { ActivityAlert } from "views/components/activity-alert";

@inject
@needs(AtsHeader, SwitchOrganizations, ActivityAlert)
export class App {
  private router: RouterConfig;
  public navCollapsed = true;

  constructor(
    private appStore: ApplicationStore,
    private currentUser: CurrentUserStore,
    private orgStore: CurrentOrganizationStore,
    private organizationModuleService: OrganizationModuleService
  ) {}

  attached() {
    this.locationHashChanged();
    window.addEventListener("hashchange", this.locationHashChanged);
    if (this.orgStore.state.portalContext?.OrganizationPortal.BrowserTitle) {
      document.title = this.orgStore.state.portalContext.OrganizationPortal.BrowserTitle;
    }
  }

  locationHashChanged() {
    const hash = window.location.hash;
    if (hash && !hash.startsWith("#/settings") && !hash.startsWith("#settings")) {
      dispatch(new UpdateLastPageAction(hash));
    }
  }

  get welcomeMessageBGImageStyle() {
    if (this.orgStore.state.isLoading) {
      return {
        "background-image": "none",
      };
    }
    const welcomeMessageBGImageUrl =
      this.orgStore.state.portalContext?.OrganizationPortal.Content.WelcomeMessageBGImageUrl;

    return !this.currentUser.state.loggedIn && welcomeMessageBGImageUrl
      ? {
          "background-image": `url(${welcomeMessageBGImageUrl})`,
        }
      : {};
  }

  get fadeTransition() {
    return {
      functional: true,
      render(h, context) {
        return h("transition-group", { props: { name: "fade-shrink-in", mode: "out-in" } }, context.children);
      },
    };
  }

  get showLeftNav() {
    return this.router.current != "settings" && this.currentUser.state.loggedIn;
  }

  onNavCollapsed(isCollapsed: boolean) {
    this.navCollapsed = isCollapsed;
  }

  registerRoutes(router: RouterConfig) {
    router.addMiddleware(RequireAuth);
    router.addMiddleware(RequireGlobal);
    router.addMiddleware(HideChrome);
    router.addMiddleware(RequirePermission);
    router.addMiddleware(RouterPath);
    router.addMiddleware(LandingPageOrFirstEnabled);
    router.addMiddleware(RequireOrganizationPortalContext);

    router.add("", () => import("views/home/home"), { auth: true });
    router.add("account", () => import("views/account/account"), {
      auth: true,
    });
    router.add("applicants", () => import("views/applicants/applicants-routes"), { auth: true });
    router.add("applications", () => import("views/applicants/applicants-routes"), { auth: true });

    router.add("reports", ReportRoutes, { auth: true });
    router.add("analytics", AnalyticsRoutes, { auth: true });

    router.add("notifications/:id", Notifications, {
      auth: true,
    });

    router.add("notifications", Notifications, {
      auth: true,
    });

    router.add("downloads", () => import("views/downloads/downloads"), {
      auth: true,
    });

    router.add("emails", () => import("views/email/emails"), {
      auth: true,
    });

    router.add("settings", Settings, {
      auth: true,
    });
    router.add("organizations", SwitchOrganizations, {
      auth: true,
      global: true,
    });

    router.add("login", Login, { orgPortalContext: true });
    router.add("choose-organization", ChooseOrganization);
    router.add("no-permission", NoPermission);
    router.add("logged-out", LoggedOut);
    router.add("forgot-password", ForgotPassword);
    router.add("reset-password/:token", ResetPassword, { orgPortalContext: true });
    router.add("stop-impersonating", () => import("./views/login/stop-impersonating"));
    router.add("feature-flags", () => import("views/global-admin/feature-flags"), { auth: true });

    router.add("change-email/:token", ConfirmEmailChange, { orgPortalContext: true });

    router.add("accept-invite/:token", AcceptInvite, { orgPortalContext: true });

    router.add("contacts", () => import("views/contacts/contacts-routes"), {
      auth: true,
    });

    router.add("tasks", () => import("views/tasks/tasks-routes"), {
      auth: true,
    });

    router.add("conversations", () => import("views/conversations/conversations-routes"), {
      auth: true,
    });

    router.add("switch-organizations", SwitchOrganizations, {
      auth: true,
    });

    router.add("marketing", () => import("views/marketing/routes"), {
      auth: true,
    });

    router.add("calendar", () => import("views/calendar/calendar-routes"), {
      auth: true,
    });

    this.router = router;
  }
}
