import { needs, inject, prop } from "fw";

import { ItemSearcher, LoadDataContext, SearchResults } from "./item-searcher";
import { UserRepository } from "network/user-repository";
import { User } from "models/user";

@inject
@needs(ItemSearcher)
export class SelectUser {
    @prop(() => ({})) public select!: Function;
    @prop(null) public selectedIds!: string[];
    @prop(null) public excludedIds!: string[];
    @prop(false) public allowUnassigned!: boolean;
    @prop(null) public unassignedPlaceholder!: string;

    public constructor(public userRepo: UserRepository) {}

    public get userFilter() {
        return this.excludedIds?.map((id: string) => `!id:${id}`).join(" AND ");
    }

    public loadData(context: LoadDataContext): Promise<SearchResults<User>> {
        const { filter, search, sort, page, pageSize } = context;
        return this.userRepo.list(filter, search, sort, page, pageSize);
    }
}
