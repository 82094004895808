import { inject } from "fw";
import { Store, handle } from "fw-state";
import { createFrom } from "fw-model";
import { StartAction, LogoutAction } from "./actions";
import { FormErrorHandling } from "./error-handling";

import { CalendarEventTypeRepository } from "network/calendar-event-type";
import { CalendarEventType } from "models/calendar-event";
import { GroupFilter } from "models/filter-setup";
import {
  CreateCalendarEventTypeAction,
  UpdateCalendarEventTypeAction,
  DeleteCalendarEventTypeAction
} from "forms/calendar-event-type";

const DEFAULT_PAGE_SIZE = 20;
const MAX_SKIP = 2000;

interface CalendarEventTypeShape {
  loaded: boolean;
  eventTypes: CalendarEventType[];
  totalCount: number;
  filter: GroupFilter;
  search: string;
  sort: string;
  selectAll: boolean;

  pageSize: number;
  currentPage: number;
  lastPage: number;
  pageStartNumber: number;
  pageEndNumber: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  canAdvancePage: boolean;
}

export class FetchCalendarEventTypesAction {
  constructor(public refresh: boolean = false) {}
}
export class FetchCalendarEventTypeAction {
  constructor(public id: string) {}
}

export class CalendarEventTypesSetFilterAction {
  constructor(public filter: GroupFilter, public clearOthers = false) { }
}

export class CalendarEventTypesSetSearchTerm {
  constructor(public searchTerm: string) {}
}

export class CalendarEventTypesNextPage { }

export class CalendarEventTypesPreviousPage { }

@inject
export class CalendarEventTypeStore extends Store<CalendarEventTypeShape>  {
  constructor(private network: CalendarEventTypeRepository) {
    super();
  }

  defaultState() {
    const emptyFilterContainer = createFrom(GroupFilter, {
      operation: "AND",
      filters: [],
    });

    return {
      loaded: false,
      eventTypes: [] as Array<CalendarEventType>,
      totalCount: 0,
      filter: emptyFilterContainer,
      search: '',
      sort: 'name ASC',
      selectAll: false,

      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      lastPage: 1,
      pageStartNumber: 1,
      pageEndNumber: DEFAULT_PAGE_SIZE,
      hasPreviousPage: false,
      hasNextPage: false,
      canAdvancePage: false
    };
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    this.setState(state => ({ ...this.defaultState() }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(state => this.defaultState());
  }

  @handle(FetchCalendarEventTypesAction)
  private async handleFetchCalendarEventTypes(action: FetchCalendarEventTypesAction) {
    this.setState(state => ({
      ...state,
      loaded: action.refresh ? false : state.loaded
    }));

    await this.loadEventTypes();
  }

  @handle(FetchCalendarEventTypeAction)
  private async handleFetchCalendarEventType(action: FetchCalendarEventTypeAction) {
    if (!action.id) return;

    const item = this.state.eventTypes.find(i => i.id == action.id);
    if (item) return;

    const newItem = await this.network.show(action.id);
    const eventTypes = this.state.eventTypes.slice();

    eventTypes.push(newItem);
    this.setState(state => ({ ...state, eventTypes: eventTypes }));
  }

  @handle(CalendarEventTypesSetFilterAction)
  private async handleCalendarEventTypesSetFilterAction(action: CalendarEventTypesSetFilterAction) {
    this.setState(state => ({
      ...state,
      loaded: false,
      currentPage: 1,
      filter: action.filter,
      search: action.clearOthers ? "" : state.search
    }));

    await this.loadEventTypes();
  }

  @handle(CalendarEventTypesSetSearchTerm)
  private handleCalendarEventTypesSetSearchTerm(action: CalendarEventTypesSetSearchTerm) {
    this.setState(state => ({
      ...state,
      currentPage: 1,
      search: action.searchTerm
    }));
  }

  @handle(CalendarEventTypesNextPage)
  private async handleCalendarEventTypesNextPage() {
    const currentPage = this.state.currentPage + 1;

    this.setState(state => ({
      ...state,
      currentPage,
      loaded: false,
    }));

    await this.loadEventTypes();
  }

  @handle(CalendarEventTypesPreviousPage)
  private async handleCalendarEventTypesPreviousPage() {
    let currentPage = this.state.currentPage - 1;
    if (currentPage < 0) currentPage = 0;

    this.setState(state => ({
      ...state,
      currentPage,
      loaded: false,
    }));

    await this.loadEventTypes();
  }

  @handle(CreateCalendarEventTypeAction, FormErrorHandling)
  private async handleCreateCalendarEventType(action: CreateCalendarEventTypeAction) {
    action.form.validate();

    const newItem = await this.network.post(action.form.updatedModel());
    const eventTypes = this.state.eventTypes.slice();

    eventTypes.push(newItem);

    this.setState(state => ({ ...state, eventTypes: eventTypes }));

    action.created = newItem;
  }

  @handle(UpdateCalendarEventTypeAction, FormErrorHandling)
  private async handleUpdateCalendarEventType(action: UpdateCalendarEventTypeAction) {
    action.form.validate();

    const updatedItem = await this.network.put(action.form.updatedModel());

    const existingItem = this.state.eventTypes.find(
      t => t.id == updatedItem.id,
    );
    if (existingItem == null) return;

    Object.assign(existingItem, updatedItem);

    this.setState(s => s);

    action.updated = existingItem;
  }

  @handle(DeleteCalendarEventTypeAction)
  private async handleDeleteCalendarEventType(action: DeleteCalendarEventTypeAction) {
    const idsArr: string[] = Array.isArray(action.ids) ? action.ids : [action.ids];
    const items = this.state.eventTypes.filter(i => idsArr.indexOf(i.id) !== -1);
    if (items.length !== idsArr.length) return;

    await this.network.destroy(idsArr, action.all, action.search);

    items.forEach(i => this.state.eventTypes.splice(this.state.eventTypes.indexOf(i), 1));
  }

  private async loadEventTypes() {
    if (this.state.loaded) return;

    const { search, filter, sort, pageSize } = this.state;
    let { currentPage } = this.state;

    const res = await this.network.list(search, filter.toFilterString(), null, sort, currentPage, pageSize);
    const totalCount = res.total;

    const lastPage = Math.ceil(totalCount / pageSize);
    currentPage = Math.min(currentPage, lastPage);

    const pageStartNumber = ((currentPage - 1) * pageSize) + 1;
    const pageEndNumber = pageStartNumber + (pageSize - 1);
    const hasNextPage = pageEndNumber < totalCount;
    const hasPreviousPage = pageStartNumber > 1;
    const canAdvancePage = pageEndNumber + 1 < MAX_SKIP;

    this.setState(state => ({
      ...state,
      loaded: true,
      eventTypes: res.results,
      totalCount,
      currentPage,
      pageStartNumber: Math.max(pageStartNumber, 0),
      pageEndNumber: Math.min(pageEndNumber, totalCount),
      hasNextPage,
      hasPreviousPage,
      canAdvancePage,
      lastPage
    }));
  }
}
