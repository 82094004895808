import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { cleanSchedule, ScheduledExport, ScheduleRecurrenceType } from "models/scheduled-export";
import {
  AddScheduledExportAction,
  UpdateScheduledExportAction,
  DeleteScheduledExportsAction,
  RunScheduledExportAction
} from "forms/scheduled-export";

import { ScheduledExportRepository } from "network/scheduled-export-repository";

import {
  LogoutAction,
  StartAction,
  RefreshFileProviderAction,
} from "./actions";
import { difference } from "lodash-es";

interface ScheduledExportsShape {
  loaded: boolean;
  schedules: ScheduledExport[];
}

export class EnsureScheduledExportsAction { }

@inject
export class ScheduledExportsStore extends Store<ScheduledExportsShape> {
  constructor(private scheduledExportsRepo: ScheduledExportRepository) {
    super();
  }

  defaultState() {
    return {
      loaded: false,
      schedules: [],
    };
  }

  @handle(StartAction)
  private handleStart(s: StartAction) {
    this.setState(s => this.defaultState());
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureScheduledExportsAction)
  private async handleEnsureScheduledExportsAction() {
    if (this.state.loaded) {
      return;
    }

    const schedules = await this.scheduledExportsRepo.list();
    this.setState(state => ({
      loaded: true,
      schedules,
    }));
  }

  @handle(AddScheduledExportAction)
  private async handleAddScheduledExportAction(action: AddScheduledExportAction) {
    action.form.validate();
    const model = action.form.updatedModel();
    cleanSchedule(model.Schedule, action.hasStartEndDateSupport);

    model.Id = null;

    const newDef = await this.scheduledExportsRepo.post(model, action.ownerUserId);

    action.addedId = newDef.Id;

    await dispatch(new EnsureScheduledExportsAction());

    if (
      action.form.Delivery.FileProviderId != null &&
      action.form.Delivery.FileProviderFolder != null &&
      action.form.Delivery.FileProviderFolder.length > 0
    ) {
      await dispatch(new RefreshFileProviderAction(action.form.Delivery.FileProviderId));
    }

    this.setState(state => ({
      ...state,
      schedules: [...state.schedules, newDef],
    }));
  }

  @handle(UpdateScheduledExportAction)
  private async handleUpdateScheduledExportAction(
    action: UpdateScheduledExportAction,
  ) {
    action.form.validate();

    await dispatch(new EnsureScheduledExportsAction());

    const model = action.form.updatedModel();
    cleanSchedule(model.Schedule, action.hasStartEndDateSupport);

    const updatedDef = await this.scheduledExportsRepo.put(model, action.newOwnerUserId);

    const existingDef = this.state.schedules.find(t => t.Id == updatedDef.Id);
    if (existingDef == null) {
      return;
    }

    Object.assign(existingDef, updatedDef);

    if (
      action.form.Delivery.FileProviderId != null &&
      action.form.Delivery.FileProviderFolder != null &&
      action.form.Delivery.FileProviderFolder.length > 0
    ) {
      await dispatch(new RefreshFileProviderAction(action.form.Delivery.FileProviderId));
    }

    this.setState(s => s);
  }

  @handle(RunScheduledExportAction)
  private async handleRunScheduledExportAction(action: RunScheduledExportAction) {
    if (!action.id) {
      return;
    }

    await dispatch(new EnsureScheduledExportsAction());

    const updated = await this.scheduledExportsRepo.runNow(action.id);
    const existing = this.state.schedules.find(t => t.Id == updated.Id);
    if (existing == null) {
      return;
    }

    Object.assign(existing, updated);
    this.setState(s => s);
  }

  @handle(DeleteScheduledExportsAction)
  private async handleDeleteScheduledExportsAction(
    action: DeleteScheduledExportsAction,
  ) {
    await dispatch(new EnsureScheduledExportsAction());

    const schedulesToDelete = this.state.schedules.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.scheduledExportsRepo.del(schedulesToDelete.map(t => t.Id));

    this.setState(state => ({
      ...state,
      schedules: difference(state.schedules, schedulesToDelete),
    }));
  }
}
