import { inject, prop, needs } from "fw";

import { AggregateCalculatedField, CalculatedField, CalculatedFieldDataTypeCode } from "models/calculated-field";
import { CollaborationCalculatedFieldFilter as CollaborationCalculatedFieldFilterModel } from "models/application-filters";
import { CollaborationModulesStore } from "state/collaboration-modules";
import { hashOn } from "hashing";

@inject
export class CollaborationCalculatedFieldFilter {
  @prop(null) filter!: CollaborationCalculatedFieldFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  private ops = [
    { text: "=", value: "=" },
    { text: ">", value: ">" },
    { text: ">=", value: ">=" },
    { text: "<=", value: "<=" },
    { text: "<", value: "<" },
  ];

  constructor(private moduleStore: CollaborationModulesStore) { }

  get moduleNameHash() {
    return hashOn(this.moduleStore.state.modules, m => m.Key, m => m.Name);
  }

  get modules() {
    return this.moduleStore.state.modules.filter(m => m.CalculatedFields.length > 0).map(m => ({ text: m.Name, value: m.Key }));
  }

  get fields() {
    if (this.filter == null || this.filter.moduleKey == null) return [];
    const m = this.moduleStore.state.modules.find(m => m.Key == this.filter.moduleKey);
    if (m == null) return [];

    return m.CalculatedFields.map(cf => ({
      text: cf.Label,
      value: cf.Key,
    }));
  }

  get fieldHash() {
    if (this.filter == null || this.filter.moduleKey == null) return {};
    const m = this.moduleStore.state.modules.find(m => m.Key == this.filter.moduleKey);
    if (m == null) return {};

    return hashOn(m.CalculatedFields, cf => cf.Key, cf => cf);
  }

  get canUseOps() {
    if (this.filter == null || this.filter.field == null) {
      return false;
    }

    return this.fieldHash[this.filter.field].DataType == CalculatedFieldDataTypeCode.Number;
  }
}
