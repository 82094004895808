// use like: <div v-in-view="{ enter: fn, leave: fn }"></div>

import { debounce } from "lodash-es";

const isElementInViewport = (el: HTMLElement): boolean => {
  const rect = el.getBoundingClientRect();

  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left < (window.innerWidth || document.documentElement.clientWidth)
  );
};

interface Tracked {
  enter?: Function;
  leave?: Function;
  element: HTMLElement;
  didEnter: boolean;
}

const tracking: Tracked[] = [];

const track = (t: Tracked) => {
  if (isElementInViewport(t.element)) {
    if (t.enter && t.didEnter == false) t.enter();

    t.didEnter = true;
  } else if (t.didEnter && t.leave) {
    t.leave();
    t.didEnter = false;
  }
};

const trackAll = debounce(() => {
  tracking.forEach(track);
}, 250);

["DOMContentLoaded", "load", "scroll", "resize", "popstate"].forEach(function(
  event
) {
  window.addEventListener(event, trackAll, false);
});

type Binding = { value: { enter?: Function; leave?: Function } };

const updateTracking = (el, binding: Binding) => {
  const item = tracking.find(t => t.element == el);

  if (item == null) {
    const t: Tracked = {
      element: el,
      enter: binding.value.enter,
      leave: binding.value.leave,
      didEnter: false
    };

    tracking.push(t);
    setTimeout(() => track(t), 10);
  } else {
    item.enter = binding.value.enter;
    item.leave = binding.value.leave;
    item.didEnter = false;
    track(item);
  }
};

export const plugin = {
  install: Vue => {
    Vue.directive("in-view", {
      update: function(el, binding: Binding) {
        updateTracking(el, binding);
      },
      bind: function(el, binding: Binding) {
        updateTracking(el, binding);
      },
      unbind: function(el) {
        const item = tracking.find(t => t.element == el);
        if (item == null) return;

        const idx = tracking.indexOf(item);
        if (idx >= 0) tracking.splice(idx, 1);
      }
    });
  }
};

export default plugin;
