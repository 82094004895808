import { prop, inject, ComponentEventBus } from "@derekpitt/fw"
import { ChartData } from "slideroom-charts";

type ChartDataAndLabelData = ChartData & { LabelData: any };
type PhaseData = {
  isGroup: boolean;
  phaseGroup: string;
  count: number;
  phaseIds: string[]
};

@inject
export class PhasePipeline {
  @prop(null) data!: ChartDataAndLabelData[];
  @prop(false) interactive!: boolean;
  @prop(1) columnSpan!: number;

  constructor(private ceb: ComponentEventBus) {}

  get total() {
    if (this.data == null) return 100;
    let totalCount = 0;
    for (let p of this.data) {
      totalCount += p.Count;
    }
    return totalCount;
  }

  percentOfTotal(count) {
    return this.total ? Math.round((count / this.total) * 100) : 0 + "%";
  }

  get maxValue() {
    if (this.data == null) return 100;
    let max = 0;
    for (let p of this.data) {
      if (p.Count > max) max = p.Count;
    }
    return max;
  }

  get phases() {
    if (this.data == null) return [];

    const inProgress = this.data.find(a => a.LabelData == null);

    const groupedByGrouped: PhaseData[] = [];

    for (const p of this.data) {
      if (p.LabelData == null) continue;

      if (p.LabelData.GroupName == null) {
        groupedByGrouped.push({ count: p.Count, phaseGroup: p.Label, isGroup: false, phaseIds: [ p.LabelData.Id ] });
      } else {
        const existingGroup = groupedByGrouped.find(a => a.isGroup && a.phaseGroup == p.LabelData.GroupName);

        if (existingGroup != null) {
          existingGroup.count += p.Count;
          existingGroup.phaseIds.push(p.LabelData.Id);
        } else {
          groupedByGrouped.push({ count: p.Count, phaseGroup: p.LabelData.GroupName, isGroup: true, phaseIds: [ p.LabelData.Id ] });
        }
      }
    }

    return [
      { phaseGroup: inProgress.Label, count: inProgress.Count, isGroup: false, phaseIds: [null] },
      ...groupedByGrouped,
    ];
  }

  formatNumber(d: number) {
    return d;
  }

  click(data: PhaseData) {
    if (!this.interactive) return;
    this.ceb.dispatch("data-click", data.phaseIds);
  }
}
