import { inject, prop } from "fw";

import { ProgramStore } from "state/program";
import { orderBy, uniq } from "lodash-es";
import { ProgramStageFilter as ProgramStageFilterModel } from "models/application-filters";


@inject
export class ProgramStageFilter {
  @prop(null) filter!: ProgramStageFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  constructor(private programStore: ProgramStore) { }

  get stages() {
    const stages: string[] = [];

    for (const program of this.programStore.state.programs) {
      for (const stage of program.Stages) {
        stages.push(stage.Name);
      }
    }

    return uniq(stages).map(s => ({ text: s, value: s }));
  }

  get selectedStage() {
    return this.filter.stage;
  }
}
