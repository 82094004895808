import { FormForType, formFor } from "fw-model";

import { CollaboratorRole } from "models/collaborator-role";

export type CollaboratorRoleFormType = FormForType<CollaboratorRole>;

export class UpdateCollaboratorRoleAction {
  constructor(public form: CollaboratorRoleFormType) {}
}

export class AddCollaboratorRoleAction {
  public addedId: string = null;

  constructor(public form: CollaboratorRoleFormType) {}
}

export class DeleteCollaboratorRolesAction {
  constructor(public id: string) {}
}

export const collaboratorRoleFormCreator = formFor(CollaboratorRole, s => {
  s.requiredField(u => u.Name, "Name");
});
