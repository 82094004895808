import { ComponentEventBus, inject, prop } from "fw";
import { isEmpty } from "lodash-es";

import { type ContactRestrictionType, ContactTypeDefinition, ICustomFieldDefinition } from "models/contact-organization";
import type { ContactTypeDataPolicyFormType } from "forms/contact-organization";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";

@inject
export class ContactFieldRestrictions {
  @prop(null) type!: ContactRestrictionType;
  @prop(null) form!: ContactTypeDataPolicyFormType;

  private loading: boolean = true;
  private searchTerm = "";
  public contactType: ContactTypeDefinition = null;
  public fields: ICustomFieldDefinition[] = [];
  public existingHash: { [id: string]: boolean } = {};
  public selectedHash: { [id: string]: boolean } = {};

  constructor(
    private ceb: ComponentEventBus,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
  ) { }

  public async attached() {
    const { fields, contact_types } = this.currentContactOrganizationStore.state.organization;
    this.contactType = contact_types.find(c => c.key === this.form.contact_type);
    this.fields = fields.filter(f => f.contact_type === this.form.contact_type && !f.is_system_field) || [];

    const existingHash = {};
    for (const fieldId of this.form.restricted_field_ids || []) {
      existingHash[fieldId] = true;
    }
    this.existingHash = existingHash;
    this.loading = false;
  }

  public get localType() {
    return this.type;
  }
  public set localType(type: ContactRestrictionType) {
    this.ceb.dispatch("update:type", type);
  }

  get hasSelectedValues() {
    if (!this.selectedHash || isEmpty(this.selectedHash))
      return false;

    const keys = Object.keys(this.selectedHash);
    return keys && keys.length > 0;
  }

  public get filtered() {
    const fields = this.fields;
    if (!fields || fields.length === 0)
      return [];

    if (!this.searchTerm || this.searchTerm.length === 0) {
      return fields;
    }

    const st = this.searchTerm.toLowerCase();
    return fields.filter(p => p.display_name.toLowerCase().indexOf(st) != -1);
  }

  ensureField(checked: boolean, id: string) {
    if (!checked) {
      delete this.selectedHash[id];

      const keys = Object.keys(this.selectedHash);
      if (!keys || keys.length === 0) {
        this.selectedHash = {};
      }
    }
  }

  done() {
    const ids = Object.keys(this.selectedHash);
    this.form.restricted_field_ids.push(...ids);
    this.ceb.dispatch("done", this.form);
  }
}
