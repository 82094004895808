import { prop, ComponentEventBus, inject } from "fw";
import { DateFilter as DateFilterModel, getFriendlyTiming } from "models/date-filter";
import {
  TimingFilterType,
  OccurrenceOptions,
  TimingOptions, DateTiming
} from "models/filter-timing";

@inject
export class DateFilter {
  @prop(null) filter!: DateFilterModel;
  @prop(false) editing!: boolean;
  @prop(() => ({})) validation;
  @prop("=") operator!: string;

  constructor(private ceb: ComponentEventBus) { }

  public occurrenceOptions = OccurrenceOptions;
  public timingOptions = TimingOptions;

  public get timingFilterType() {
    return TimingFilterType;
  }

  public get occurrence() {
    return this.filter.occurrence;
  }

  public set occurrence(occurrence: string) {
    this.ceb.dispatch("update:filter", { ...this.filter, occurrence: occurrence });
    this.ceb.dispatch("filter-changed", this.filter);
  }

  public get timing() {
    return this.filter.timing;
  }

  public set timing(timing: DateTiming) {
    const filter = { ...this.filter, timing, daysFrom: "", daysTo: "", startDate: "", endDate: "" , sinceDays: ""};
    this.ceb.dispatch("update:filter", filter);
    if (!(filter.timing == TimingFilterType.LastXDays || filter.timing == TimingFilterType.DayRange || filter.timing == TimingFilterType.DateRange)) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get startDate() {
    return this.filter.startDate;
  }

  public set startDate(startDate: string) {
    const filter = { ...this.filter, startDate };
    this.ceb.dispatch("update:filter", filter);
    if (filter.startDate && filter.endDate) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get endDate() {
    return this.filter.endDate;
  }

  public set endDate(endDate: string) {
    const filter = { ...this.filter, endDate };
    this.ceb.dispatch("update:filter", filter);
    if (filter.startDate && filter.endDate) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get daysFrom() {
    return this.filter.daysFrom;
  }

  public set daysFrom(daysFrom: number) {
    const filter = { ...this.filter, daysFrom };
    this.ceb.dispatch("update:filter", filter);
    // when daysFrom is blanked out it will be typeof string instead of number
    if (typeof daysFrom == "number" && filter.daysFrom >= 0 && filter.daysTo >= 0) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  public get daysTo() {
    return this.filter.daysTo;
  }

  public set daysTo(daysTo: number) {
    const filter = { ...this.filter, daysTo };
    this.ceb.dispatch("update:filter", filter);
    if (filter.daysFrom >= 0 && filter.daysTo >= 0) {
      this.ceb.dispatch("filter-changed", filter);
    }
  }

  get friendlyTiming() {
    if (this.filter == null) return "";
    return getFriendlyTiming(this.filter);
  }
}
