import { inject, prop } from "fw";
import { groupBy } from "lodash-es";
import { ReferenceCountFilter as ReferenceCountFilterModel } from "models/application-filters";
import { DataDictionaryService } from "service/data-dictionary";

@inject
export class ReferenceCountFilter {
  @prop(null)
  public filter: ReferenceCountFilterModel;

  @prop(() => ({})) public validation;

  @prop(false)
  public editing: boolean;

  public operationType = [
    { text: "At least", value: "at-least" },
    { text: "Exactly", value: "exactly" },
  ];

  public typeOptions = [
    { text: "Completed", value: "completed" },
    { text: "Pending", value: "pending" },
  ];

  public steps: { text: string; value: string }[] = [];


  constructor(
    private dataDictionaryService: DataDictionaryService
  ) { }

  public async attached() {
    await this.editingChanged();
  }

  

  public async editingChanged() {
    if (!this.editing || this.steps.length > 0) {
      return;
    }

    const maxStepLabelsToShow: number = 3;
    const referenceSteps = await this.dataDictionaryService.getReferenceSteps();
    const referenceStepsByPath = groupBy(referenceSteps, f => f.Path);
    const steps = [];
    for (const step in referenceStepsByPath) {
      const groupedSteps = referenceStepsByPath[step];
      let stepNames: string = groupedSteps.map(f => f.Label).slice(0, maxStepLabelsToShow).join(", ");
      if (groupedSteps.length > maxStepLabelsToShow) {
        stepNames += `+ ${groupedSteps.length - maxStepLabelsToShow} more`;
      }

      const stepKey: string = step.split(".").pop();
      steps.push({ text: `${stepKey} (${stepNames})`, value: stepKey });
    }

    this.steps = steps;
    if (this.steps.length == 1) {
      this.filter.referenceStepKey = this.steps[0].value;
    }
  }
}
