import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { ScheduledExport } from "models/scheduled-export";

@inject
export class ScheduledExportRepository {
  constructor(private s: ATS) { }

  public async list(exportDefinitionId: string = null): Promise<ScheduledExport[]> {
    const res = await this.s.get<any[]>("scheduled-export",
      exportDefinitionId ? { exportDefinitionId } : null
    );
    return createFromArray(ScheduledExport, res.body);
  }

  public async post(scheduledExport: ScheduledExport, ownerUserId: string): Promise<ScheduledExport> {
    const res = await this.s.post(
      "scheduled-export",
      scheduledExport,
      ownerUserId ? { ownerUserId } : null
    );
    return createFrom(ScheduledExport, res.body);
  }

  public async put(scheduledExport: ScheduledExport, newOwnerUserId: string): Promise<ScheduledExport> {
    const res = await this.s.put(
      `scheduled-export/${scheduledExport.Id}`,
      scheduledExport,
      newOwnerUserId ? { newOwnerUserId } : null
    );
    return createFrom(ScheduledExport, res.body);
  }

  public async runNow(scheduledExportId): Promise<ScheduledExport> {
    const res = await this.s.post(`scheduled-export/${scheduledExportId}/run-now`, null);
    return createFrom(ScheduledExport, res.body);
  }

  public async del(ids: string[]) {
    await this.s.post("scheduled-export/delete", ids);
  }
}
