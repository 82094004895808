import { inject, Route } from "fw";
import { seedOrganizationPortal } from "helpers/organization-portal-context";

@inject
export class RequireOrganizationPortalContext {
  navigating(r: Route, fullRoute: string) {
    const requiresOrganizationPortalContext = r.data?.orgPortalContext;

    if (requiresOrganizationPortalContext) {
      seedOrganizationPortal();
    }

    return true;
  }
}