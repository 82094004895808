import { fromClass } from "fw-model";
import { PaymentProviderTypeCode } from "./organization";

export type ProviderStaticDataType = { key: any; label: string, imageKey: string; };

export const PROVIDER_STATIC_DATA: ProviderStaticDataType[] = [
  { key: PaymentProviderTypeCode.AuthorizeDotNet, label: 'Authorize.Net', imageKey: 'authorizenet' },
  { key: PaymentProviderTypeCode.Stripe, label: 'Stripe', imageKey: 'stripe' },
  { key: PaymentProviderTypeCode.Cybersource, label: 'Cyber Source', imageKey: 'cybersource' },
  { key: PaymentProviderTypeCode.CybersourceSA, label: 'Cyber Source Secure Acceptance', imageKey: 'cybersource' },
  { key: PaymentProviderTypeCode.TouchNet, label: 'TouchNet', imageKey: 'touchnet' },
];

export type PaymentDataType = { key: string; label: string; path?: string, pathIdKey?: string};

export const PAYMENT_DATA_FIELDS: PaymentDataType[] = [
  { key: "Application", label: "Application", path: 'applications/', pathIdKey: 'ApplicationId' },
  { key: "MetaData.ProgramStepGroupName", label: "Application Step" },
  { key: "TransactionNumber", label: "Transaction Number" },
  { key: "InvoiceNumber", label: "Invoice Number" },
  { key: "Amount", label: "Amount" },
  { key: "MetaData.DateUpdatedUtc", label: "Time" },
  { key: "MetaData.Program", label: "Program", path: 'settings/programs/', pathIdKey: 'MetaData.ProgramId' },
  { key: "MetaData.Stage", label: "Stage" },
  { key: "Provider", label: "Provider" },
];

export class PaymentDetailsMetaData {
  OrganizationId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
  SeasonId: null;
  ProgramStepGroupName: string;
  ProgramStepGroupId: string;
  ProgramId: string;
  Program: string;
  Stage: string;
  StageId: string;
  ApplicationPublicId: string;
}

export class PaymentDetails {
  Amount: string;
  ApplicationId: string;
  ApplicationStepGroupId: string;
  FamilyName: string;
  GivenName: string;
  Id: string;
  InvoiceNumber: string;
  StepGroupId: string;
  Success: boolean;
  TransactionNumber: string;
  Data: any
  @fromClass MetaData: PaymentDetailsMetaData;
}

