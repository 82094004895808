import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { ReportResult } from "shared/report-runtime";

@inject
export class ReportResultRepository {
  constructor(private s: ATS) {}

  public async get(id: string) {
    const res = await this.s.get(`report/${id}`);
    return createFrom(ReportResult, res.body);
  }

  public async list(seasonId: string, reportDefinitionId: string, sort = null, page = 1, pageSize = 10) {
    const res = await this.s.get<any[]>("report", { reportDefinitionId, seasonId, sort, page, pageSize });
    return { list: createFromArray(ReportResult, res.body), total: parseInt(res.headers["x-ats-total"], 10) };
  }

  public async post(reportDefinitionId: string, name: string) {
    const res = await this.s.post("report", null, { reportDefinitionId, name });
    return createFrom(ReportResult, res.body);
  }

  public async share(id: string, password: string, revoke = false) {
    const res = await this.s.post(`report/${id}/share`, password, { revoke });
    return createFrom(ReportResult, res.body);
  }
}
