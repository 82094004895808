import { inject, needs, prop, ComponentEventBus } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { SelectUser } from "views/components/select-user";
import { UserField } from "./user-field";
import { User } from "models/user";
import { difference } from "lodash-es";
import { UserCard } from "views/components/user-card";

@inject
@needs(SelectUser, UserField, UserCard)
export class UserSelector {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(null) public value!: string[];
  @prop(true) public showRemove!: boolean;
  @prop(false) public isFilter!: boolean;

  public displaySearch: boolean = false;

  constructor(private ceb: ComponentEventBus) {}

  public chooseUser(user: User) {
    this.ceb.dispatch("update:value", [user.Id]);
  }

  public removeUser() {
    this.ceb.dispatch("update:value", []);
  }

  public addToFilter(user: User) {
    if (!this.value.includes(user.Id)) {
      this.ceb.dispatch("update:value", [...this.value, user.Id]);
    }
  }

  public removeFilter(userId) {
    this.ceb.dispatch("update:value", difference(this.value, [userId]));
  }

  public get selectedIds() {
    return this.value || [];
  }
}
