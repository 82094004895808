import { inject } from "fw";

import { ApplicationSettingsStore } from "state/application-settings";
import { CurrentOrganizationStore } from "state/current-organization";
import { translatePath } from 'helpers/translate-path';
import { GridColumn } from "models/grid-column";
import { ApplicationProperty } from "models/application-settings";
import { DataDictionaryStore } from "state/data-dictionary";
import { hashOn } from "hashing";
import { DataDictionaryIndexStatus } from "models/data-dictionary";

@inject
export class ApplicationSettingsService {
  constructor(
    private applicationSettingsStore: ApplicationSettingsStore,
    private dataDictionaryStore: DataDictionaryStore,
    private currentOrgStore: CurrentOrganizationStore,
  ) { }

  public getApplicationPropertyFromPath(path: string): ApplicationProperty {
    if (!path) {
      return null;
    }

    const key: string = path.startsWith("properties.") ? path.substring(11) : path;
    if (!key || key.length === 0) {
      return null;
    }

    const { ApplicationProperties } = this.applicationSettingsStore.state.applicationSettings;
    const properties = !ApplicationProperties || ApplicationProperties.length === 0 ? [] : ApplicationProperties;
    return properties.find(f => f.Key == key);
  }

  public get gridColumns(): GridColumn[] {
    const applicationSettings = this.applicationSettingsStore.state.applicationSettings;
    const columns = applicationSettings.GridColumns;
    const resolvedColumns = columns && columns.length > 0 ? columns : applicationSettings.DefaultGridColumns;

    // when feature flag "ElectiveIndexing" is or was turned ON during some time, some fields may become indexed
    // so we need to update customized grid columns on the flow to enable sorting by those fields
    const fieldsMap = hashOn(this.dataDictionaryStore.state.fields, x => x.Path, y => y);
    resolvedColumns.forEach(column => {
      const field = fieldsMap[column.Path];
      // NOTE: sorting by unindexed columns should work as we would only support keyword sort anyway, and the backend has
      // been updated to use runtime fields for sort
      // if (field?.IndexStatus == DataDictionaryIndexStatus.Indexed) {
      //   column.Sort = field?.Sort ?? null;
      // } else if (field) {
      //   column.Sort = null;
      // }
      column.Sort = field?.Sort ?? null
    });

    return resolvedColumns;
  }

  // converts program.id,program.name to program(id,name)
  public toFieldMask(columns: GridColumn[]): string {
    const rootNode = new TreeNode();
    for (const col of columns) {
      let currentNode = rootNode;
      const parts = col.Path.split(".");
      let lastPart: string = null;
      for (const part of parts) {        
        currentNode = currentNode.findOrAdd(part, lastPart == part);
        lastPart = part;
      }
    }

    return this.buildFieldMask(rootNode);
  }

  private buildFieldMask(node: TreeNode): string {
    if (node.descendants.length > 0) {
      const masks = node.descendants.map(n => this.buildFieldMask(n)).join(",");
      return node.id ? `${node.id}(${masks})` : masks;
    }

    return node.id;
  }
}

class TreeNode {
  constructor(public id: string = null, public descendants: TreeNode[] = []) {}

  public find(id: string): TreeNode {
    if (this.id === id)
      return this;

    return this.descendants.find(d => d.id === id);
  }

  public findOrAdd(id: string, forceAdd:boolean = false): TreeNode {
    var node = this.find(id);
    if (!node || forceAdd) {
      node = new TreeNode(id);
      this.descendants.push(node);
    }

    return node;
  }
}
