import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { PasswordStrengthProfile } from "models/password-strength-profile";
import { ConditionGroup } from "models/form";

import { ATS } from "./ats";

@inject
export class UtilityRepository {
  constructor(private s: ATS) {}

  public async saml2Metadata(url: string) {
    const res = await this.s.get("utility/saml2/metadatainfo", { url });
    return createFrom(SAML2MetadataInfoResult, res.body);
  }

  public async pgpInfo(keyText: string) {
    const res = await this.s.post<PgpPublicKeyInfo[]>("utility/pgpinfo", keyText);
    return createFromArray(PgpPublicKeyInfo, res.body);
  }

  async decrypt(data: string) {
    const res = await this.s.post<string>("utility/decrypt", data);
    return res.body;
  }

  async decryptPayloads(payloads: string[]) {
    const res = await this.s.post<string[]>("utility/decrypt-payload", payloads);
    return res.body;
  }

  async checkPasswordStrength(password: string) {
    const res = await this.s.post<PasswordStrengthProfile>("utility/check-password-strength", password);
    return res.body;
  }

  async getServerTime() {
    const res = await this.s.get("utility/server-time");
    return res.body;
  }

  public async generateConditionScript(group: ConditionGroup): Promise<string> {
    const res = await this.s.post<string>("utility/generate-condition-script", group);
    return res.body;
  }

  public async getRenderedTemplate(content: string, template: string): Promise<RenderTemplate> {
    const args: RenderTemplate = { content, template };
    const res = await this.s.contacts.post<RenderTemplate>("render-template", args);
    return createFrom(RenderTemplate, res.body);
  }
}

export class RenderTemplate { content: string; template: string; rendered?: string; }

export class SAML2MetadataInfoEntity {
  EntityId: string;
  Name: string;
}

export class SAML2MetadataInfoResult {
  MetadataUrl: string;
  IsFederation: boolean;
  EntityIds: string[];
  Entities: SAML2MetadataInfoEntity[];
}

export class PgpPublicKeyInfo {
  KeyId: string;
  UserIds: string[];
  Expires?: Date;
}

export class PgpPrivateKeyInfo {
  KeyId: string;
  UserIds: string[];
}
