import { ComponentEventBus, inject, needs, prop } from "fw";
import { ConditionBuilderGroup } from "views/components/condition-builder-group";
import type { ConditionInfoFormType } from "forms/condition-info";
import { DataDictionaryField } from "models/data-dictionary";
import { Completer } from "helpers/auto-complete";

@inject
@needs(ConditionBuilderGroup)
export class ConditionBuilder {
  @prop(null) public fields: DataDictionaryField[];
  @prop(null) public conditionInfo: ConditionInfoFormType;
  @prop(null) public completer: Completer | Completer[];
  @prop(false) public disabled: boolean;
  @prop(true) public showClear: boolean;
  @prop(true) public showFunctionSelector: boolean;
  @prop(true) public showFieldDictionaryLink: boolean;
  @prop(false) public expressionModeOnly!: boolean;

  constructor(private ceb: ComponentEventBus) {}

  public onChanged() {
    this.ceb.dispatch("change", this.conditionInfo);
  }
}
