import { RouterConfig } from "fw";

import { HideChrome } from "router-middleware/hide-chrome";
import { ReportPageMode } from "shared/report-runtime";

export class ReportRoutes {
  loading = true;

  async registerRoutes(r: RouterConfig) {
    r.addMiddleware(HideChrome);

    this.loading = true;
    const {
      EditReportDefinition,
      Reports,
      ViewReport,
      ViewSnapshot,
    } = await import("./lazy-reports-routes");

    r.add("", Reports);
    r.add("new", EditReportDefinition, { hideChrome: true, reportPageMode: ReportPageMode.New });

    r.add("snapshot/:id", ViewSnapshot, { hideChrome: true });

    r.add(":id/edit", EditReportDefinition, { hideChrome: true, reportPageMode: ReportPageMode.Edit });
    r.add(":id/copy", EditReportDefinition, { hideChrome: true, reportPageMode: ReportPageMode.Copy });
    r.add(":id", ViewReport, { hideChrome: true });

    this.loading = false;
  }
}
