import { MarketingOutreachListObject } from "state/marketing-outreach-list";
import { EventFrequencies } from "models/calendar-event";
import { EventMessageTypes } from "views/calendar/calendar.constants";

export class MarketingOutreachCampaign implements MarketingOutreachListObject {
  Id: number = null;
  AtsOrgId: string = null;
  Archived: number = null;
  BulkMessage: number = null;
  DirectMessage: number = null;
  ConversationMessage: number = null;
  EventSeriesId: string = null;
  EventOccurrenceId: string = null;
  EventMessageType: EventMessageTypes = null;
  EventFrequency: EventFrequencies = null;
  Name: string = null;
  Description: string = null;
  EndDate: string = null;
  StartDate: string = null;
  RunAs: string = null;
  Filter: string = null;
}

export class AudienceItem {
  constructor(public Type: string, public Id: string) {
  }
}
