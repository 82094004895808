import { inject } from "fw";
import { Contact } from "models/contact";
import {
  ICustomFieldOption,
} from "models/contact-organization";
import { CodeRunner } from "shared/code-runner";
import { ContactsClientFieldPaths } from "service/contacts-client-field-paths";
import { Notification } from "service/notification";
import { ContactRepository } from "network/contact-repository";

@inject
export class ContactFieldOptionsResolverService {
  constructor(
    private codeRunner: CodeRunner,
    private contactsClientFieldPaths: ContactsClientFieldPaths,
    private notification: Notification,
    private contactRepository: ContactRepository,
  ) {}

  public async resolveOptions(contact: Contact, options: ICustomFieldOption[]): Promise<ICustomFieldOption[]> {
    const optionsToEvaluate = options.filter((op) => op.condition);
    if(!optionsToEvaluate.length) {
      return options;
    }
    
    const codes = optionsToEvaluate.map((op) => op.condition);
    const clientModel = await this.contactRepository.getClientModelById(contact.id);
    
    const context = this.generateContext(clientModel);
    const results = await this.codeRunner.runMultipleJs(codes, context);
    const optionsToExclude = new Set<string>();
    for (
      let optionIndex = 0;
      optionIndex < optionsToEvaluate.length;
      optionIndex++
    ) {
      if (results[optionIndex].error || !results[optionIndex].result) {
        optionsToExclude.add(optionsToEvaluate[optionIndex].id);
      }

      if (results[optionIndex].error) {
        this.notification.notifyWarning(`Error occured while processing condition of '${optionsToEvaluate[optionIndex].name}'`);
      }
    }

    return options.filter((op) => !optionsToExclude.has(op.id));
  }

  private generateContext(contact: Contact) {
    const filledClientModel = this.contactsClientFieldPaths.fillMissingContactClientModelProperties(contact);

    return { source: filledClientModel };
  }
}
