export const wait = (n: number) => new Promise<void>(res => setTimeout(res, n));

export const waitAtleast = async <T>(n: number, fn: () => Promise<T>): Promise<T> => {
  const res = await Promise.all([
    wait(n),
    fn(),
  ]);

  return res[1];
};
