import { fromClass } from "fw-model";

export class ApplicantMetaData {
  OrganizationId: string = null;
  DateActivatedUTC: string = null;
  DateLastLoginUTC: string = null;
  IsActivated: boolean = null;
  ContactsId: string = null;
  ApplicantAccountId: string = null;
  UpdatedUtc: string = null;
  CreatedUtc: string = null;
}

export interface IImpersonationTarget {
  Id: string
  GivenName: string;
  FamilyName: string;
}

export class Applicant implements IImpersonationTarget {
  Id: string = null;
  EmailAddress: string = null;
  GivenName: string = null;
  FamilyName: string = null;
  Address1: string = null;
  Address2: string = null;
  City: string = null;
  Region: string = null;
  PostalCode: string = null;
  CountryCode: string = null;
  PhoneNumber: string = null;

  @fromClass MetaData: ApplicantMetaData = null;
}
