import { inject } from "fw";
import { createFromArray, createFrom } from "fw-model";

import { ATS } from "./ats";
import { RoutingTable, AssignmentType } from "models/routing-table";

@inject
export class RoutingTableRepository {
  constructor(private s: ATS) {}

  async list() {
    const res = await this.s.get<any[]>("routing-table");
    return createFromArray(RoutingTable, res.body);
  }

  async post(table: RoutingTable, type: AssignmentType) {
    const res = await this.s.post(`routing-table`, table, { type });
    return createFrom(RoutingTable, res.body);
  }

  async put(table: RoutingTable) {
    const res = await this.s.put(`routing-table/${table.Id}`, table);
    return createFrom(RoutingTable, res.body);
  }

  public async del(ids: string[]) {
    await this.s.delete(`routing-table/${ids.join(",")}`);
  }
}
