import { inject } from "fw";
import { get } from "lodash-es";

import { Completer, CompletionItem, getItems } from "helpers/auto-complete";
import { FunctionStore } from "state/function";
import { FeatureFlagService } from "service/feature-flag";
import { FunctionType, ParsedFunction } from "models/function";

@inject
export class FunctionClientModelCompleter implements Completer {
  constructor(
    private functionStore: FunctionStore,
    private ffs: FeatureFlagService,
  ) { }

  public getProperties(types: FunctionType[], handlebars: boolean) {
    const p: CompletionItem[] = [];
    
    this.functionStore.state.list.filter(i => i.MetaData.IncludeByDefault)
    .forEach(i =>
      i.MetaData.ParsedContents?.ParsedFunctions?.forEach(j =>
        p.push({ token: this.format(j, handlebars), type: FunctionType.default })
      )
    );

    p.push({
      token: this.format({ Name: "translate", Parameters: ["field_key", "table_key"] }, handlebars),
      type: FunctionType.translate
    });    

    if (types) {
      const filterTypes = (a: CompletionItem) => {
        if (get(a, "properties", []).length) {
          a.properties = a.properties.filter(b => filterTypes(b));
          return a.properties.length;
        } else return types.indexOf(get(a, "type", null)) !== -1;
      };

      return p.filter(f => filterTypes(f));
    }

    return p;
  }

  private format(fn: ParsedFunction, handlebars: boolean) {
    if (handlebars) return fn.Name + " " + fn.Parameters?.join(" ");
    else return fn.Name + "(" + fn.Parameters?.join(",") + ")";
  }

  public async getCompletions(token: string, word: string, types: FunctionType[], _contactType: string, handlebars: boolean) {
    return getItems(this.getProperties(types, handlebars), token, word);
  }
}
