import { ComponentEventBus, prop, inject, needs } from "fw";

import { TagPill } from "./tag-pill";

@inject
@needs(TagPill)
export class MultiselectField {
  @prop(undefined) public value!: string[];
  @prop(false) public randomizeColor!: boolean;
  @prop(false) showRemove!: boolean;
  @prop(false) public withOverflowCount!: boolean;

  public container: HTMLElement;
  public counterRef: HTMLElement;
  private resizeObserver: ResizeObserver = null;
  public overflowCount: number = 0;

  constructor(private ceb: ComponentEventBus) {}

  public attached() {
    if (!this.withOverflowCount) {
      return;
    }

    this.resizeObserver = this.initObserver();
    this.resizeObserver.observe(this.container.children[0]);
  }

  public get overflownItemsAriaLabel() {
    return `Show all items. ${this.overflowCount} ${
      this.overflowCount == 1 ? "is" : "are"
    } hidden.`;
  }

  public detached() {
    if (!this.withOverflowCount) {
      return;
    }

    this.resizeObserver.unobserve(this.container.children[0]);
  }

  public valueChanged() {
    if (!this.withOverflowCount) {
      return;
    }

    this.resizeObserver.unobserve(this.container.children[0]);
    this.resizeObserver.observe(this.container.children[0]);
  }

  private initObserver(): ResizeObserver {
    const observer = new ResizeObserver((entries) => {
      this.overflowCount = 0;
      this.counterRef.style.visibility = "hidden";
      const observedEl = entries[0];
      if (!observedEl) {
        return;
      }

      if (observedEl.contentRect.height > this.container.clientHeight) {
        const tagPills = this.container.getElementsByClassName("tag-pill");
        let leftOffsetForCounter = 0;
        let topOffsetForCounter = 0;
        for (let index = 0; index < tagPills.length; index++) {
          const tagPill = tagPills[index] as HTMLElement;

          if (tagPill.offsetTop >= this.container.clientHeight) {
            this.overflowCount++;

            if (!leftOffsetForCounter && index > 0) {
              const lastNotOverflown = tagPills[index - 1] as HTMLElement;
              leftOffsetForCounter =
                lastNotOverflown.offsetLeft + lastNotOverflown.offsetWidth;
              topOffsetForCounter = lastNotOverflown.offsetTop;
            }
          }
        }

        this.counterRef.style.left = `${leftOffsetForCounter}px`;
        this.counterRef.style.top = `${topOffsetForCounter}px`;
        if (this.overflowCount > 0) {
          this.counterRef.style.visibility = "visible";
        }
      }
    });

    return observer;
  }

  public get hiddenItemsAriaLabel() {
    return `Show ${this.overflowCount} hidden item${
      this.overflowCount > 1 ? "s" : ""
    }`;
  }

  public remove(value: string) {
    this.ceb.dispatch("remove", value);
  }

  public onOverflowClick() {
    this.ceb.dispatch("overflow-click");
  }
}
