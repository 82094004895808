import { fromClass, fromClassArray } from "fw-model";

export class EncryptedValuePair {
  PlainTextValue: string = null;
  EncryptedValue: string = null;
}

export class HeaderData {
  Header: string = null;
  @fromClass Value: EncryptedValuePair;
}

export class WebhookDeliveryContext {
  Url: string = null;
  BearerToken: string = null;

  Method: string = "POST";
  @fromClassArray(HeaderData) Headers: HeaderData[];
}

export class SftpDeliveryContextMetaData {
  HasPrivateKey: boolean = false;
}

export class SftpDeliveryContext {
  Host: string = null;
  Port: number = 22;
  Username: string = null;
  Password: string = null;
  SshPrivateKey: string = null;
  @fromClass MetaData: SftpDeliveryContextMetaData;
}

export class S3DeliveryContext {
  AccessKey: string = null;
  SecretKey: string = null;
  Bucket: string = null;
  RootPath: string = null;
}

export class FileProviderMetaData {
  OrganizationId: string = null;
  SeasonId: string = null;
  DateCreatedUtc: string = null;
  DateUpdatedUtc: string = null;
  ConsecutiveFailureCount: number = null;

  FolderList: string[] = null;
}

export type ProviderType = "webhook" | "sftp" | "s3";

export class FileProvider {
  Id: string = null;
  Name: string = null;

  ProviderType: ProviderType = "webhook";
  @fromClass WebhookContext: WebhookDeliveryContext;
  @fromClass SftpContext: SftpDeliveryContext;
  @fromClass S3Context: S3DeliveryContext;

  IsDeleted: boolean = false;
  IsEnabled: boolean = true;
  @fromClass MetaData: FileProviderMetaData;

  WorkspaceIds: string[] = [];
  VaultKeyId: string = null;
  PgpPublicKey: string = null;
  PgpPublicKeyId: string = null;

  get FriendlyProviderType(): string {
    switch (this.ProviderType) {
      case 'webhook':
        return 'Webhook';
      case 'sftp':
        return 'SFTP';
      case 's3':
        return 'S3';
      default:
        return '';
    }
  }
}
