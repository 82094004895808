import { inject, prop, needs } from "fw";

import { debounce } from "lodash-es";
import { PopoverService } from "service/popover";
import { PasswordHint } from "./password-hint";
import { UtilityRepository } from "network/utility-repository";
import { PasswordStrengthProfile } from "models/password-strength-profile";

@inject
@needs(PasswordHint)
export class PasswordStrengthMeter {
  @prop(null)
  password: string;

  @prop(false) inlineHint!: boolean;

  private passwordStrengthProfile: PasswordStrengthProfile = null;
  private debouncedCheckStrength = null;
  private showInlineHint = false;

  constructor(
    private popoverService: PopoverService,
    private utilityRepo: UtilityRepository,
  ) {}

  attached() {
    this.debouncedCheckStrength = debounce(this.checkStrength.bind(this), 150);
  }

  passwordChanged() {
    this.debouncedCheckStrength();
  }

  async checkStrength() {
    if (!this.password || this.password.trim().length < 3) {
      this.passwordStrengthProfile = null;
      return;
    }

    this.passwordStrengthProfile = await this.utilityRepo.checkPasswordStrength(this.password);
  }

  get description() {
    if (!this.password) {
      return "";
    }
  
    switch (this.strength) {
      case 0:
      case 1:
        return "Weak Password";
      case 2:
        return "Okay Password";
      case 3:
        return "Good Password";
      case 4:
        return "Strong Password";
      default:
        return "";
    }
  }

  passwordHint() {
    if (this.inlineHint) {
      this.showInlineHint = true;
      return;
    }

    this.popoverService.open(PasswordHint);
  }

  get showHint() {
    if (this.strength == null) return false;
    if (this.strength >= 0 && this.strength < 3) return true;
    return false;
  }

  get strength(): number {
    return this.passwordStrengthProfile ? this.passwordStrengthProfile.Score : 0;
  }

  get passwordFeedback(): string[] {
    return this.passwordStrengthProfile?.Feedback;
  }
}
