import { inject } from "fw";
import { Store, handle } from "fw-state";
import { once } from "helpers/once";
import { TranslationTableRepository } from 'network/translation-table-repository';
import {
  LogoutAction,
  StartAction,
} from "./actions";
import { TranslationTableForm } from 'forms/translation-tables';
import { TranslationTable, TranslationTableCategory, TranslationTableOutputDataType } from 'models/translation-tables';
import { wait } from 'wait';
import { FormErrorHandling } from "./error-handling";

export class EnsureTranslationTableStoreAction {
  constructor() { }
}

export class CreateTranslationTableAction {
  constructor(public form: TranslationTableForm) { }
}

export class UpdateTranslationTableAction {
  constructor(public form: TranslationTableForm) { }
}

export class DeleteTranslationTableAction {
  constructor(public translationTableId: string) { }
}

export class SortTranslationTablesAction {
  constructor(public sort: string) { }
}

export class FilterTranslationTablesAction {
  constructor(public filter: string) { }
}

interface TranslationTableStoreShape {
  pageIndex: number;
  pageSize: number;
  list: TranslationTable[];
  total: number;
  categories: TranslationTableCategory[];
  sortBy: string;
  filter: string;
}

@inject
export class TranslationTableStore extends Store<TranslationTableStoreShape> {

  constructor(private translationTableRepo: TranslationTableRepository) {
    super();
  }

  protected defaultState() {
    return {
      pageIndex: 0,
      pageSize: 999,
      list: [],
      total: 0,
      categories: [],
      sortBy: null,
      filter: null
    };
  }

  public async getById(translationTableId: string) {
    const selectedTable = this.state.list.find(table => table.Id == translationTableId);
    return (selectedTable == null)
      ? await this.translationTableRepo.get(translationTableId)
      : selectedTable;
  }

  @handle(StartAction)
  @handle(LogoutAction)
  private handleStartAndLogoutActions() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureTranslationTableStoreAction)
  private async handleEnsureTranslationTableStoreAction(action: EnsureTranslationTableStoreAction) {
    await once("ensure-translation-tables", async () => {
      const defaultState = this.defaultState();
      const tables = await this.translationTableRepo.list(defaultState.pageIndex, defaultState.pageSize);
      const categories = await this.translationTableRepo.categories();
      this.setState(state => ({
        ...defaultState,
        list: tables.list,
        total: tables.total,
        pageIndex: tables.pageIndex,
        pageSize: tables.pageSize,
        categories: categories
      }));
    });
  }

  @handle(CreateTranslationTableAction, FormErrorHandling)
  private async handleCreateTranslationTableAction(action: CreateTranslationTableAction) {
    action.form.validate();

    const newModel = action.form.updatedModel();

    await this.translationTableRepo.post(newModel);
    await this.refresh();
  }

  @handle(UpdateTranslationTableAction, FormErrorHandling)
  private async handleUpdateTranslationTableAction(action: UpdateTranslationTableAction) {
    await action.form.validate();

    const updatedModel = action.form.updatedModel();

    await this.translationTableRepo.put(updatedModel);
    await this.refresh();
  }

  @handle(DeleteTranslationTableAction)
  private async handleDeleteTranslationTableAction(action: DeleteTranslationTableAction) {
    await this.translationTableRepo.del([action.translationTableId]);
    await this.refresh();
  }

  @handle(FilterTranslationTablesAction)
  private async handleFilterTranslationTablesAction(action: FilterTranslationTablesAction) {
    await this.refresh(0);
    const term = action.filter.toLowerCase();
    const filteredTables = this.state.list.filter(table => {
      if (table.Name.toLowerCase().includes(term) ||
        (table.Category != null && table.Category.toLowerCase().includes(term))
      ) {
        return table;
      }
    });
    this.setState((state) => ({
      ...state,
      list: this.performSort(this.state.sortBy, filteredTables),
      pageIndex: 0,
      total: filteredTables.length,
      filter: action.filter
    }));
  }

  @handle(SortTranslationTablesAction)
  private async handleSortTranslationTablesAction(action: SortTranslationTablesAction) {
    //this.refresh();
    const sortedList = this.performSort(action.sort, this.state.list);
    this.setState((state) => ({
      ...state,
      list: sortedList,
      sortBy: action.sort
    }));
  }

  private performSort(sort: string, tables: Array<TranslationTable>): Array<TranslationTable> {
    return (sort == null)
      ? tables
      : tables.sort((a, b) => {
        switch (sort) {
          case 'name': { return this.compareTranslationTableName(a, b); }
          case '-(name)': { return -this.compareTranslationTableName(a, b); }
          case 'category': { return this.compareTranslationTableCategory(a, b); }
          case '-(category)': { return -this.compareTranslationTableCategory(a, b); }
          case 'input': { return this.compareTranslationTableInput(a, b); }
          case '-(input)': { return -this.compareTranslationTableInput(a, b); }
          case 'output': { return this.compareTranslationTableOutput(a, b); }
          case '-(output)': { return -this.compareTranslationTableOutput(a, b); }
        }
      });
  }

  private compareTranslationTableName(t1: TranslationTable, t2: TranslationTable) {
    if (t1.Name > t2.Name) { return 1; }
    else if (t1.Name < t2.Name) { return -1; }
    else { return 0; }
  }

  private compareTranslationTableCategory(t1: TranslationTable, t2: TranslationTable) {
    if ((t1.Category == null) && (t2.Category != null)) {
      return 1;
    } else if ((t1.Category != null) && (t2.Category == null)) {
      return -1;
    }

    if (t1.Category > t2.Category) { return 1; }
    else if (t1.Category < t2.Category) { return -1; }
    else { return 0; }
  }

  private compareTranslationTableInput(t1: TranslationTable, t2: TranslationTable) {
    if (t1.InputType.toString() > t2.InputType.toString()) { return 1; }
    else if (t1.InputType.toString() < t2.InputType.toString()) { return -1; }
    else { return 0; }
  }

  private compareTranslationTableOutput(t1: TranslationTable, t2: TranslationTable) {
    if (t1.OutputType.toString() > t2.OutputType.toString()) { return 1; }
    else if (t1.OutputType.toString() < t2.OutputType.toString()) { return -1; }
    else { return 0; }
  }

  private async refresh(delay: number = 1000) {
    if (delay > 0) {
      await wait(delay);
    }

    const tables = await this.translationTableRepo.list(this.state.pageIndex, this.state.pageSize);
    const categories = await this.translationTableRepo.categories();
    this.setState(state => ({
      ...state,
      list: tables.list,
      total: tables.total,
      pageIndex: tables.pageIndex,
      pageSize: tables.pageSize,
      categories: categories
    }));
  }
}
