import { inject, prop } from "fw";

import { EvaluationPhasesStore } from "state/evaluation-phases";

@inject
export class EvaluationCompleteFilter {
  @prop(null) filter;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  private phases: { text: string, value: string }[] = [];
  private phaseNameHash: { [key: string]: string } = {};

  constructor(private phaseStore: EvaluationPhasesStore) { }

  attached() {
    this.updatePhases();
  }

  updatePhases() {
    const newHash: { [key: string]: string } = {};

    this.phases.push({ text: "Current", value: "current" });
    newHash["current"] = "Current Phase";

    this.phaseStore.state.phases.forEach(s => {
      this.phases.push({ text: s.Name, value: s.Key });
      newHash[s.Key] = s.Name;
    });

    this.phaseNameHash = newHash;
  }
}
