import { OrganizationPortal } from "./organization";

export enum PortalType {
  Organization = 0,
  Applicant = 1,
  Reference = 2,
  Collaboration = 3,
  Inquiry = 4,
  Student = 5,
  Custom = 6,
  Review = 7,
}

export class AppOrganizationPortalContext {
  OrganizationId: string;
  OrganizationName: string;
  OAuthRelayDomain: string;
  OrganizationPortal: OrganizationPortal;
  IdentityProviders: { Id: string; Name: string; }[] = [];
  CustomDomainContentBaseUrl: string;
}

export class Actor {
  Type: string;
  Id: string;
  FirstName?: string;
  LastName?: string;
  EmailAddress?: string;
}
