import { MultiJsResultItem, Result } from "./code-runner.worker";

const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

export class CodeRunner {
  private worker: Worker;
  private resolverHash: {
    [id: string]: {
      resolver: (s: any) => void,
      reject: (err: any) => void
    }
  } = {};

  constructor() {
    this.worker = new Worker(new URL('./code-runner.worker', import.meta.url));
    this.worker.onmessage = this.onMessage.bind(this);
  }

  private onMessage(e: MessageEvent) {
    const { id, result, error } = e.data as Result;

    if (error != null) {
      this.resolverHash[id].reject(new Error(error));
    } else {
      this.resolverHash[id].resolver(result);
    }

    delete this.resolverHash[id];
  }

  private generateWorkerId() {
    return s4() + s4() + "-" + s4() + s4();
  }

  // need to run some javascript? ok! here you go!
  public async runJs<T>(code: string, context: any): Promise<T> {
    const id = this.generateWorkerId();

    const promise = new Promise<T>((resolver, reject) => {
      this.resolverHash[id] = { resolver, reject };
    });

    this.worker.postMessage({ id, code, context });

    return promise;
  }

  public async runMultipleJs<T>(codes: string[], context: any): Promise<MultiJsResultItem<T>[]> {
    const id = this.generateWorkerId();

    const promise = new Promise<MultiJsResultItem<T>[]>((resolver, reject) => {
      this.resolverHash[id] = { resolver, reject };
    });

    this.worker.postMessage({ id, codes, context });

    return promise;
  }
}
