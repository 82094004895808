import { inject, prop } from "fw";

export enum AlertType {
  error = "error",
  info = "info",
  success = "success",
  warning = "warning",
}

@inject
export class AlertMessage {
  @prop(AlertType.info) type?: AlertType;
  private alertType = AlertType;
  private alertIcons: Record<AlertType, string> = {
    error: "icon-alert-critical",
    info: "icon-info-square",
    success: "icon-check-circle",
    warning: "icon-alert-triangle",
  }

  constructor() {}

  public get alertIcon () {
    return this.alertIcons[this.type];
  }
}

