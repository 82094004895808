import { fromClassArray } from "fw-model";
import { GridColumn } from './grid-column';

export class CollaborationPortal {
  Id: string;

  Name: string = null;
  Domain: string = null;
  RoleIds: string[] = [];
  TeamCategories: string[] = [];

  AllowLocalLogin = true;
  IdentityProviderIds: string[] = [];

  @fromClassArray(GridColumn) GridColumns: GridColumn[];
}
