import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { ChartDataNormalized } from "slideroom-charts";
import { ChartData, TableChartOptions, ReportChartDefinitionClientData } from "../../models";
import { scaleLinear, min, max, interpolateHcl } from "d3";
import { allNumbers } from "../../util";

type TableCell = {
  chartData: ChartDataNormalized;
  value: string;
  title: string;
  color: string;
  backgroundColor: string;
};

@inject
export class TableChart {
  @prop(null) clientData!: string;
  @prop(null) data!: ChartData[];
  @prop(false) interactive!: boolean;

  private cols: string[] = [];
  private table: TableCell[][] = [];

  constructor(private ceb: ComponentEventBus) { }

  attached() {
    this.dataChanged();
  }

  get isHeatmap() {
    if (this.clientData == null) return true;
    const cd = JSON.parse(this.clientData) as ReportChartDefinitionClientData;
    if (cd.tableOptions) {
      return !!cd.tableOptions.heatmap;
    }

    return true;
  }

  dataChanged() {
    if (this.data == null) return;

    const rows = this.data.map(d => d.Label);
    let cols: string[] = [];

    for (const row of rows) {

      const rowData = this.data.find(d => d.Label == row);

      if (!rowData?.Data) return;

      for (const colData of rowData.Data) {
        const extCol = cols.find(c => c == colData.Label);
        if (extCol == null) cols.push(colData.Label);
      }
    }

    // lets see if everything is the cols is a number
    if (allNumbers(cols)) {
      cols.sort((a, b) => parseFloat(a) - parseFloat(b));
    }

    // now lets do the data
    const table: TableCell[][] = [];

    const allValues: number[] = [];

    this.data.forEach(d1 => {
      if (d1.Data != null)
        d1.Data.forEach(d2 => allValues.push(d2.Count));
    });

    const maxVal = max(allValues);

    const backgroundColorScale = scaleLinear().domain([0, maxVal]).range(["#f2faff", "#57c0ff"] as any);

    const getTableValue = (count: number, title: string, data: ChartDataNormalized): TableCell => {
      return {
        backgroundColor: backgroundColorScale(count) as any,
        color: "#000",
        value: `${count}`,
        title: title,
        chartData: data,
      };
    };

    for (const rowLabel of rows) {

      const rowData = this.data.find(d => d.Label == rowLabel);

      const tableRow: TableCell[] = [ {
        backgroundColor: "inherit",
        color: "inherit",
        value: rowLabel,
        title: '',
        chartData: null,
      }];

      // seed the row
      for (const col of cols) {
        const dd: ChartDataNormalized = {
          count: rowData.Count,
          label: rowData.Label,
          labelData: (rowData as any).LabelData,
          style: null,
          data: [
            {
              count: 0,
              label: col,
              labelData: col,
              style: null,
            },
          ],
        };

        tableRow.push(getTableValue(0, '', dd));
      }

      for (const colData of rowData.Data) {

        const colIdx = cols.indexOf(colData.Label);

        if (colIdx >= 0) {
          const rd: ChartDataNormalized = {
            count: rowData.Count,
            label: rowData.Label,
            labelData: (rowData as any).LabelData,
            style: null,
            data: [
              {
                count: colData.Count,
                label: colData.Label,
                labelData: (colData as any).LabelData,
                style: null,
              },
            ],
          };

          tableRow[colIdx + 1] = getTableValue(colData.Count, colData.LabelData, rd);
        }

      }

      table.push(tableRow);
    }

    this.table = table;
    this.cols = cols;
  }

  isMissing(text: string) {
    return text == "__missing__" || text == "NaN";
  }

  dataClick(row: TableCell) {
    if (!this.interactive || row.chartData == null) return;
    this.ceb.dispatch("data-click", row.chartData);
  }
}
