import { inject } from "fw";

export interface MultiselectPopoverOptions {
  getValue: () => string[];
  options: string[];
  onChange: (option: string[]) => void;
  validate: (option: string[]) => Promise<string | null>;
  entityName: string;
  showAdd: boolean;
}

@inject
export class MultiselectSelectorPopover {
  public entityName: string;
  private getValue: () => string[];
  private options: string[];
  private showAdd: boolean;
  public validation: string | null = null;
  private validate!: (option: string[]) => Promise<string | null>;

  public filter: string = "";
  public filteredOptions: string[] = [];
  private onChange: (options: string[]) => void;

  constructor() {}

  public activate(params: MultiselectPopoverOptions) {
    this.entityName = params.entityName || "option";
    this.options = params.options;
    this.onChange = params.onChange;
    this.showAdd = params.showAdd;
    this.getValue = params.getValue;
    this.validate = params.validate;

    this.filterOptions();
  }

  private get value() {
    return this.getValue() || [];
  }

  public get searchText() {
    return `Search ${this.entityName}s...`;
  }

  public get createNewText() {
    return `Create new ${this.entityName}`;
  }

  public get noOptionsText() {
    return `No ${this.entityName}s available`;
  }

  public get noAvailableOptionsText() {
    return `All ${this.entityName}s used`;
  }

  public get noFilteredOptionsText() {
    return `No ${this.entityName}s found`;
  }

  public addOption(option: string) {
    if (this.value && this.containsOption(this.value, option)) {
      return;
    }

    this.filter = "";
    this.onChange([...this.value, option]);
    setTimeout(this.filterOptions, 100);
  }

  public get showCreate(): boolean {
    if (!this.showAdd) {
      return false;
    }

    const option = this.filter.trim();
    if (!option) {
      return false;
    }

    if (
      this.filteredOptions.length &&
      this.containsOption(this.filteredOptions, option)
    ) {
      return false;
    }

    return !this.containsOption(this.value, option);
  }

  public get showNoOptionsText(): boolean {
    return this.options.length === 0;
  }

  public get showNoAvailableOptionsText(): boolean {
    return this.filteredOptions.length === 0;
  }

  public get showNoFilteredOptionsText(): boolean {
    return (
      !this.showAdd &&
      this.filteredOptions.length === 0 &&
      this.filter.trim().length > 0
    );
  }

  public async createOptionFromFilter() {
    const option = this.filter.trim();
    if (option.length <= 0) {
      return;
    }

    this.validation = null;
    if (this.validate) {
      this.validation = await this.validate([...this.getValue(), option]);
    }

    if (this.validation == null) {
      this.addOption(option);
    }
  }

  private filterOptions(): void {
    if (this.filter.length === 0) {
      this.filteredOptions = this.value.length
        ? this.options.filter((o) => !this.containsOption(this.value, o))
        : this.options;
    } else {
      const lowerFilter = this.filter.toLowerCase();
      this.filteredOptions = this.options.filter((o) => {
        if (o.toLowerCase().includes(lowerFilter)) {
          return this.value == null
            ? true
            : !this.containsOption(this.value, o);
        }

        return false;
      });
    }
  }

  public onKeyUp() {
    this.validation = null;
    this.filterOptions();
  }

  private containsOption(options: string[], option: string): boolean {
    if (!options || !option) {
      return false;
    }

    return options
      .map((o) => o.toLocaleLowerCase())
      .includes(option.toLocaleLowerCase());
  }
}
