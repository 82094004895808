const DIFF_TYPES: Map<string, number> = new Map()
  .set('ms', 1)
  .set('s', 1000)
  .set('min', 1000 * 60)
  .set('h', 1000 * 60 * 60)
  .set('days', 1000 * 60 * 60 * 24);


export const dateDiff = (start: string | Date = new Date(), end: string | Date = new Date(), type: string = 'ms') => {
  start = new Date(start);
  end = new Date(end);
  const startDate = Date.UTC(
    start.getUTCFullYear(),
    start.getUTCMonth(),
    start.getUTCDate(),
    start.getUTCHours(),
    start.getUTCMinutes(),
    start.getUTCSeconds())
  ;
  const endDate = Date.UTC(
    end.getUTCFullYear(),
    end.getUTCMonth(),
    end.getUTCDate(),
    end.getUTCHours(),
    end.getUTCMinutes(),
    end.getUTCSeconds()
  );

  return Math.floor((endDate - startDate) / DIFF_TYPES.get(type));
};
