import { fromClass, fromClassArray, cloneOf } from "fw-model";
import { MediaTypeCode } from "./file";
import { ApplicationPropertyValue } from "models/application-property-value";
import { ConditionInfo } from "./form";

export enum ProgramPageMode {
  New,
  Edit,
  Copy,
}

export enum ProgramStepTypeCode {
  Form = 0,
  Document = 1,
  Portfolio = 2,
  ReferenceSet = 3,
  Content = 4,
  DecisionLetter = 5,
}

export enum ReferencesCountConditionTypeCode {
  Exactly = 0,
  AtLeast = 1,
  AtMost = 2,
}

export class ProgramStepMetaData {
  OrganizationId: string;
  SeasonId: string;
  ProgramId: string;
}

export class FormSettings {
  FormId: string = null;
}

export class ContentSettings {
  Content: string = null;
}

export enum MediaFieldTypeCode {
  ShortText = 1,
  LongText = 2,
}

export class MediaTypeFieldInfo {
  Name: string = null;
  IsRequired: boolean = false;
  FieldType: MediaFieldTypeCode = MediaFieldTypeCode.ShortText;
}

export class MediaTypeConfiguration {
  Name: string = null;
  MediaType: MediaTypeCode = null;
  Enabled: boolean = true;
  ExternalProviders: string[] = [];
  AllowedExtensions: string[] = [];

  @fromClassArray(MediaTypeFieldInfo) Fields: MediaTypeFieldInfo[];
}

export class EnhancedMediaTypeConfiguration {
  Name: string = null;
  MediaType: MediaTypeCode = null;
  Enabled: boolean = true;
  ExternalProviders: string[] = [];
  AllowedExtensions: string[] = [];
  FormId: string = null;
}

export class PortfolioSettings {
  MinimumItems: number = null;
  MaximumItems: number = null;

  @fromClassArray(MediaTypeConfiguration) MediaTypes: MediaTypeConfiguration[];
}

export class FolderSettings {
  ApplicantSupplied: boolean;
  MinimumItems: number;
  MaximumItems: number;

  @fromClassArray(EnhancedMediaTypeConfiguration)
  MediaTypes: EnhancedMediaTypeConfiguration[];
}

export class DecisionLetterSettings {
  Placeholder: string =
    "Your decision has not been released yet. We will contact you once it is available, but please feel free to check here periodically or contact us with any questions.";
}

export class DocumentSource {
  Key: string = null;
  Types: string[] = [];
}

export class DocumentSettings {
  @fromClassArray(DocumentSource) AllowedDocumentSources: DocumentSource[];
}

export class ReferenceSetSettings {
  AllowWaiveRight = false;
  ReferencesCount = 0;
  ReferencesCountConditionType = ReferencesCountConditionTypeCode.AtLeast;
  FormId: string = null;
}

export enum DeadlineTypeCode {
  Hard = 0,
  Soft = 1,
}

export class ProgramStepGroupHistory {
  Id: string;
  Name: string;
  Key: string;
  RootStepGroupId: string;
  StartDateUTC: string;
  EndDateUTC: string;
  DeadlineUTC: string;
  DeadlineType: DeadlineTypeCode;
  GracePeriod = 0;
  Condition: string;
  @fromClass ConditionInfo: ConditionInfo;
  Fee: number;
  SubmitContentTemplate: string;
  SendCustomEmailOnSubmit: boolean;
  CustomEmailKeyOnSubmit: string;
  StepIdList: string[];
}

export class ProgramStep {
  Id: string;
  @fromClass MetaData: ProgramStepMetaData;
  StageId: string;

  StepType: ProgramStepTypeCode = null;

  IsRequired = true;

  Name: string = null;
  Instructions: string = null;
  Key: string = null;
  RootStepId: string;
  Condition: string = null;
  @fromClass ConditionInfo: ConditionInfo;

  UpdatedUtc: string;
  CreatedUtc: string;

  @fromClass FormSettings: FormSettings;
  @fromClass PortfolioSettings: PortfolioSettings;
  @fromClass ReferenceSetSettings: ReferenceSetSettings;
  @fromClass ContentSettings: ContentSettings;
  @fromClass DocumentSettings: DocumentSettings;
  @fromClass FolderSettings: FolderSettings;
  @fromClass DecisionLetterSettings: DecisionLetterSettings;
}

export class ProgramStepGroupMetaData {
  OrganizationId: string;
  SeasonId: string;
  ProgramId: string;
  StageId: string;
  HasBeenOpened: boolean;
}

export class ProgramStepGroup {
  Id: string = null;
  Name: string = "";
  Key: string = null;
  StartDateUTC: string = null;
  DeadlineUTC: string = null;
  DeadlineType: DeadlineTypeCode = null;
  Fee: number = null;
  WaiveFeeCondition: string = null;
  @fromClass WaiveFeeConditionInfo: ConditionInfo;
  GracePeriod: number = 0;
  Condition: string = null;
  @fromClass ConditionInfo: ConditionInfo;
  SubmitContentTemplate: string = null;
  SendCustomEmailOnSubmit = false;
  CustomEmailKeyOnSubmit: string = null;
  PreStartInstructions: string = null;
  PostDeadlineInstructions: string = null;
  RootStepGroupId: string = null;
  @fromClass MetaData: ProgramStepGroupMetaData;
  @fromClassArray(ProgramStep) StepList: ProgramStep[];
}

export class ContentBlock {
  ContentTemplateId: string = null;
  Condition: string = null;
  @fromClass ConditionInfo: ConditionInfo;
}

export class ProgramStageTemplateMetaData {
  OrganizationId: string;
  SeasonId: string;
  Version: string;
  DateUpdatedUtc: string;
  DateCreatedUtc: string;
}

export class ProgramStageTemplate {
  Id: string = null;
  TemplateName: string = null;
  AddToNewPrograms: boolean = false;
  PriorityAddOrder: number = 0;
  Name: string = null;
  IntroductionContent: string = null;
  @fromClassArray(ProgramStepGroup) StepGroups: ProgramStepGroup[];
  @fromClassArray(ContentBlock) ContentBlocks: ContentBlock[];
  @fromClass MetaData: ProgramStageTemplateMetaData;
}

export class ProgramStageMetaData {
  ProgramStageTemplateVersion: string;
}

export class ProgramStage {
  Id: string = null;
  ProgramStageTemplateId: string = null;
  Name: string = null;
  IntroductionContent: string = null;
  @fromClassArray(ProgramStepGroup) StepGroups: ProgramStepGroup[];
  @fromClassArray(ContentBlock) ContentBlocks: ContentBlock[];

  @fromClass MetaData: ProgramStageMetaData;
}

export class ProgramStageData {
  Name: string = null;
  IntroductionContent: string = null;
  @fromClass MetaData: ProgramStageMetaData | ProgramStageTemplateMetaData;
  @fromClassArray(ProgramStepGroup) StepGroups: ProgramStepGroup[];
  @fromClassArray(ContentBlock) ContentBlocks: ContentBlock[];
}

export class ProgramMetaData {
  OrganizationId: string;
  SeasonId: string;
  PrivateId: string;
  IsManaged: boolean = false;
  Version: string;
}

export class Program {
  Id: string;
  @fromClass MetaData: ProgramMetaData;

  Name: string = null;
  PublicName: string = null;
  Description: string = null;
  ExternalStartLink: string = null;
  StartDateUTC: string = null;
  EndDateUTC: string = null;
  IsActive: boolean;
  IsDeleted: boolean;
  IsPrivate: boolean;
  IsHidden: boolean;

  StartingStageName: string;

  @fromClassArray(ProgramStage) Stages: ProgramStage[];
  @fromClassArray(ApplicationPropertyValue)
  PropertyValues: ApplicationPropertyValue[];
}

export const cleanProgram = (p: Program): Program => {
  const np = cloneOf(Program, p);

  for (let stage of np.Stages) {
    for (let stepGroup of stage.StepGroups) {
      if (stepGroup.SendCustomEmailOnSubmit == false) {
        stepGroup.CustomEmailKeyOnSubmit = null;
      }

      for (let step of stepGroup.StepList) {
        if (step.StepType != ProgramStepTypeCode.Form) {
          step.FormSettings = null;
        }
        if (step.StepType != ProgramStepTypeCode.ReferenceSet) {
          step.ReferenceSetSettings = null;
        }
        if (step.StepType != ProgramStepTypeCode.Content) {
          step.ContentSettings = null;
        }

        if (step.StepType != ProgramStepTypeCode.DecisionLetter) {
          step.DecisionLetterSettings = null;
        }

        step.PortfolioSettings = null;
        step.DocumentSettings = null;
        if (
          step.StepType != ProgramStepTypeCode.Portfolio &&
          step.StepType != ProgramStepTypeCode.Document
        ) {
          step.FolderSettings = null;
        }
      }
    }
  }

  np.PropertyValues = np.PropertyValues.filter((a) => a.Value !== null);

  return np;
};

export const cleanStageTemplate = (
  p: ProgramStageTemplate
): ProgramStageTemplate => {
  const np = cloneOf(ProgramStageTemplate, p);
  if (!np.AddToNewPrograms) {
    np.PriorityAddOrder = 0;
  }

  for (let stepGroup of np.StepGroups) {
    if (stepGroup.SendCustomEmailOnSubmit == false) {
      stepGroup.CustomEmailKeyOnSubmit = null;
    }

    for (let step of stepGroup.StepList) {
      if (step.StepType != ProgramStepTypeCode.Form) {
        step.FormSettings = null;
      }
      if (step.StepType != ProgramStepTypeCode.ReferenceSet) {
        step.ReferenceSetSettings = null;
      }
      if (step.StepType != ProgramStepTypeCode.Content) {
        step.ContentSettings = null;
      }
      if (step.StepType != ProgramStepTypeCode.DecisionLetter) {
        step.DecisionLetterSettings = null;
      }

      step.PortfolioSettings = null;
      step.DocumentSettings = null;
      if (
        step.StepType != ProgramStepTypeCode.Portfolio &&
        step.StepType != ProgramStepTypeCode.Document
      ) {
        step.FolderSettings = null;
      }
    }
  }
  return np;
};
