import moment from "moment-timezone";

export class DateFormatContext {
  fromUtc?: boolean = null;
  fromNow?: boolean = null;
  useTimezone?: boolean = null;
  timezone?: string = null;
  dateOnly?: boolean = null;
  calendar?: boolean = null;
  format?: string = null;
  withSuffix?: boolean = null;
  timezoneAbbr?: boolean = null;
}

export const removeTime = (formattedDate: string): string => {
  if (!formattedDate) return null;
  const idx = formattedDate.indexOf(" at ");
  return idx === -1 ? formattedDate : formattedDate.substring(0, idx);
}

export class DateFormatter {
  private fromUtc: boolean = false;
  private fromNow: boolean = false;
  private useTimezone: boolean = true;
  private timezone: string = "America/Chicago";
  private dateOnly: boolean = false;
  private calendar: boolean = false;
  private format: string = "MMM D, YYYY";
  private withSuffix: boolean = false;
  private timezoneAbbr: boolean = false;

  constructor(defaults: DateFormatContext = null) {
    if (defaults) {
      if (defaults.fromUtc != null)
        this.fromUtc = defaults.fromUtc;

      if (defaults.fromNow != null)
        this.fromNow = defaults.fromNow;

      if (defaults.useTimezone != null)
        this.useTimezone = defaults.useTimezone;

      if (defaults.timezone != null)
        this.timezone = defaults.timezone;

      if (defaults.dateOnly != null)
        this.dateOnly = defaults.dateOnly;

      if (defaults.calendar != null)
        this.calendar = defaults.calendar;

      if (defaults.format != null)
        this.format = defaults.format;

      if (defaults.withSuffix != null)
        this.withSuffix = defaults.withSuffix;

      if (defaults.timezoneAbbr)
        this.timezoneAbbr = defaults.timezoneAbbr;
    }
  }

  public formatDate(date: number | number[] | string | Date | moment.Moment | Object, context: DateFormatContext = null, defaultValue = "") {
    if (typeof date == 'undefined' || date == null || date == '0000-00-00')
      return defaultValue;

    const fromUtc = context?.fromUtc == null ? this.fromUtc : context.fromUtc;
    const fromNow = context?.fromNow == null ? this.fromNow : context.fromNow;
    const useTimezone = context?.useTimezone == null ? this.useTimezone : context.useTimezone;
    const timezone = context?.timezone == null ? this.timezone : context.timezone;
    const dateOnly = context?.dateOnly == null ? this.dateOnly : context.dateOnly;
    const calendar = context?.calendar == null ? this.calendar : context.calendar;
    const format = context?.format == null ? this.format : context.format;
    const timezoneAbbr = context?.timezoneAbbr == null ? this.timezoneAbbr : context.timezoneAbbr;


    let m = fromUtc ? moment.utc(date) : moment(date);
    if (!m.isValid())
      return "";

    if (useTimezone) m = m.tz(timezone);

    if (fromNow) {
      const formatted = this.optimizedFormat(m, context);
      return dateOnly ? removeTime(formatted) : formatted;
    }

    if (calendar) {
      const formatted = m.calendar();
      return dateOnly ? removeTime(formatted) : formatted;
    }

    if(timezoneAbbr)
      return `${m.format(format)} ${moment.tz(timezone).zoneAbbr()}`;

    return m.format(format);
  }

  private optimizedFormat(m: moment.Moment, context: DateFormatContext = null) {
    const useTimezone = context?.useTimezone == null ? this.useTimezone : context.useTimezone;
    const timezone = context?.timezone == null ? this.timezone : context.timezone;
    const withSuffix = context?.withSuffix == null ? this.withSuffix : context.withSuffix;

    let now = moment();
    if (useTimezone) now.tz(timezone);
    if (Math.abs(now.clone().startOf('year').diff(m.clone().startOf('year'), "years")) > 0) {
      return (Math.abs(now.diff(m, "hours")) <= 24) ?
        m.calendar() :
        m.format("MMM D, YYYY [at] h:mm a");
    } else if (Math.abs(now.clone().startOf('day').diff(m.clone().startOf('day'), "hours")) > 24) {
      return m.format("MMM D [at] h:mm a");
    } else if (Math.abs(now.diff(m, "hours")) > 4) {
      return m.calendar();
    } else {
      return m.fromNow(!withSuffix);
    }
  }
}
