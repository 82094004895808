import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { Workflow } from "models/workflow";
import { WorkflowRun } from "models/application-workflow-run";

@inject
export class WorkflowRepository {
  constructor(private s: ATS) {}

  async get(id: string) {
    const res = await this.s.get(`workflow/${id}`);
    return createFrom(Workflow, res.body);
  }

  async list(f = null, s = null, sort = null, page = 1, pageSize = 20) {
    const res = await this.s.get<any[]>("workflow", {
      f,
      s,
      sort,
      page,
      pageSize
    });
    return {
      list: createFromArray(Workflow, res.body),
      total: parseInt(res.headers["x-ats-total"], 10)
    };
  }

  async post(workflow: Workflow, objectType?: string) {
    const res = await this.s.post("workflow", workflow,{ objectType: objectType});
    return createFrom(Workflow, res.body);
  }

  async put(workflow: Workflow) {
    const res = await this.s.put(`workflow/${workflow.Id}`, workflow);
    return createFrom(Workflow, res.body);
  }

  async del(ids: string[]) {
    await this.s.delete(`workflow/${ids.join(",")}`);
  }

  async enroll(workflowId: string, objectId: string, objectType?: string) {
    if (!objectType)
    {
      const res = await this.s.put(
        `workflow/enroll/${workflowId}/${objectId}`,
        null
      );
      return createFrom(WorkflowRun, res.body);
    }
    else
    {
      const res = await this.s.put(
        `workflow/enroll/${workflowId}/${objectType}/${objectId}`,
        null
      );
      return createFrom(WorkflowRun, res.body);
    }
  }

  async skipWait(runId: string, waitNodeId: string) {
    const res = await this.s.put(`workflow/skip/${runId}/${waitNodeId}`, null);
    return createFrom(WorkflowRun, res.body);
  }

  async verifyBooleanExpression(expression: string) {
    await this.s.post("workflow/verify-boolean-expression", expression);
  }

  async listRuns(objectType: string, objectId: string) {
    const res = await this.s.get<any[]>("workflow/run",{ objectType: objectType, objectId: objectId});
    return createFromArray(WorkflowRun, res.body);
  }
}
