export const copy = async (text: string): Promise<boolean> => {
  const permissionName = "clipboard-write" as PermissionName;
  try {
    const permissionResult = await navigator.permissions.query({ name: permissionName });      
    if (permissionResult.state === "denied") {
      return false;
    }
  } catch { }
  
  try {
    navigator.clipboard.writeText(text);
    return true;
  } catch {
      return false;
  }
};