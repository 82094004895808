import { inject } from "fw";

import { Permissions } from "service/permission";
import { PopoverService } from "service/popover";
import { LinkOptionPopover, LinkOptionPopoverOptionsType } from "views/components/link-option-popover";

@inject
export class AddReportPopover {
  constructor(
    private popoverService: PopoverService,
    private permissions: Permissions,
  ) { }

  public get addPopupOptions(): LinkOptionPopoverOptionsType[] {
    var options: LinkOptionPopoverOptionsType[] = [];

    if (this.permissions.all("ManageReports")) {
      options.push(<LinkOptionPopoverOptionsType>{
        value: true,
        text: "Create Report",
        url: "#/reports/new",
        icon: "equalizer",
        dataId: "create-report",
      });
    }

    if (this.permissions.all("ManageGoals")) {
      options.push(<LinkOptionPopoverOptionsType>{
        value: true,
        text: "Create Goal",
        url: "#/analytics/goals/new",
        icon: "bubble_chart",
        dataId: "button-create-goal",
      });
    }

    return options;
  }

  public async openAddPopup() {
    const options = this.addPopupOptions;

    await this.popoverService.open<boolean>(LinkOptionPopover, { options });
  }
}
