import { prop, needs, ComponentEventBus } from "fw";

import { Completer, CompletionItem, VariablePicked } from "helpers/auto-complete";
import { FunctionClientModelCompleter } from "helpers/completers/function-client-model";
import { CustomFieldType } from "models/contact-organization";
import { FunctionType } from "models/function";
import { FeatureFlagService } from "service/feature-flag";
import { PopoverService } from "service/popover";
import { CodeEditor } from "./code-editor";
import { EditorPopover } from "./editor-popover";

@needs(CodeEditor, EditorPopover)
export class CodeEditorField {
  @prop(null) public value!: string;
  @prop(null) public label!: string;
  @prop(null) public validation!: string;
  @prop(false) public disabled;
  @prop("htmlmixed") public mode!: string;
  @prop(true) public lineNumbers!: boolean;
  @prop(null) public completer!: Completer | Completer[];
  @prop(null) public contactType: string;
  @prop(null) public completionTypes: Array<CustomFieldType | FunctionType>;
  @prop(false) public handlebars: boolean;
  @prop(null) public changingValue!: string;
  @prop(true) public showFunctionSelector: boolean;
  @prop(true) public showFieldDictionaryLink: boolean;

  private functions: CompletionItem[] = null;
  private localValue: string = null;

  constructor(
    private ceb: ComponentEventBus,
    private ffs: FeatureFlagService,
    private popover: PopoverService,
  ) { }

  public async attached() {
    this.valueChanged();
    const completer = (!Array.isArray(this.completer) ? [this.completer] : this.completer)
      .find(i => i instanceof FunctionClientModelCompleter);
    this.functions = await completer?.getCompletions("", "", this.completionTypes, this.contactType, this.handlebars);
  }

  valueChanged() {
    this.localValue = this.value;
  }

  public get name() {
    return this.label;
  }

  private onChange() {
    this.ceb.dispatch("update:value", this.localValue);
    this.ceb.dispatch("change");
  }
  

  private async selectFunction() {
    const fnPicked = await this.popover.open<VariablePicked>(
      EditorPopover, { variables: this.functions }
    );
    if (!fnPicked.result) return;

    const fn = fnPicked.result.variable;
    this['$refs'].codeEditor.insertValue(fn.token);
  }
}
