import { prop, inject } from "fw";
import { OriginFilter as OriginFilterModel } from "models/application-filters";
import { ApplicationRepository } from "network/application-repository";

@inject
export class OriginFilter {
  @prop(null) filter!: OriginFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  constructor(private applicationRepo: ApplicationRepository) {}

  origins: { name: string, count: number }[] = [];
  originsFetched = false;

  editingChanged() {
    if (this.editing) this.updateOrigins();
  }

  created() {
    if (this.editing) this.updateOrigins();
  }

  // this is per filter... but only happens
  // when editing a filter..
  // so if we have multiple origin filters, it isn't
  // efficent since it fetches it everytime..
  // but, this will be ok for now.. we can always
  // move this to state somewhere if it gets out
  // of hand...
  async updateOrigins() {
    this.originsFetched = true;

    const res = await this.applicationRepo.search(null, null, null, null, null, "terms:origin", true);

    const terms: { name: string, count: number }[] = res.Aggregations.terms_origin.Items.map(i => ({ name: i.Key, count: i.Total }));

    if (!terms.find(a => a.name.toLowerCase() == "portal")) {
      terms.push({ name: "portal", count: 0 });
    }

    if (!terms.find(a => a.name.toLowerCase() == "manual")) {
      terms.push({ name: "manual", count: 0 });
    }

    if (!terms.find(a => a.name.toLowerCase() == "copy")) {
      terms.push({ name: "copy", count: 0 });
    }

    this.origins = terms;
  }

  selectOrigin(name: string) {
    this.filter.origin = name;
  }
}
