import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { ExportTaskRepository } from "network/export-task-repository";
import { TaskRequestRepository } from "network/task-request-repository";

import {
  LogoutAction,
  StartAction,
  TaskUpdatedAction,
  TaskFinishedAction,
  RetryExportAction,
  WatchTaskAction,
} from "./actions";
import { ExportTask } from "models/export-task";
import { difference } from "lodash-es";

const MAX_SKIP = 2000;
const DEFAULT_PAGE_SIZE = 20;

interface ScheduledExportHistoryShape {
  loaded: boolean;
  collection: ExportTask[];
  total: number;
  filter: string;
  search: string;
  sort: string;
  selectAll: boolean;

  seasonId: string;
  exportDefinitionId: string;
  scheduledExportId: string;

  pageSize: number;
  currentPage: number;
  lastPage: number;
  pageStartNumber: number;
  pageEndNumber: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  canAdvancePage: boolean;
  collapsedItem: string;
}

export class EnsureScheduledExportHistoryAction {
  constructor(public exportDefinitionId: string = null, public scheduledExportId: string = null) { }
}

export class ToggleScheduledExportHistorySortAction {
  constructor(public sort: string) { }
}

export class SearchAction {
  constructor(public term: string) { }
}

export class NextPageAction { }
export class PrevPageAction { }
export class CollapseItemAction {
  constructor(
    public id: string
  ) {}
}

export class ResendScheduledExportById {
  constructor(
    public id: string
  ) {}
}

@inject
export class ScheduledExportHistoryStore extends Store<ScheduledExportHistoryShape> {
  constructor(
    private exportTaskRepo: ExportTaskRepository
  ) {
    super();
  }

  defaultState() {
    return {
      loaded: false,
      collection: [],
      total: 0,
      filter: null,
      search: null,
      sort: null,
      selectAll: false,

      seasonId: null,
      exportDefinitionId: null,
      scheduledExportId: null,

      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      lastPage: 1,
      pageStartNumber: 1,
      pageEndNumber: DEFAULT_PAGE_SIZE,
      hasPreviousPage: false,
      hasNextPage: false,
      canAdvancePage: false,
      collapsedItem: ''
    };
  }

  @handle(LogoutAction)
  @handle(StartAction)
  private handleStartAction(s: StartAction) {
    this.setState(s => this.defaultState());
  }

  @handle(CollapseItemAction)
  private handleCollapseItemAction(s: CollapseItemAction) {
    this.setState(state => ({
      ...state,
      collapsedItem: s.id,
    }));
  }

  @handle(ResendScheduledExportById)
  private async resendScheduledExportById(s: ResendScheduledExportById) {
    if (!s.id) return;

    await this.exportTaskRepo.put(s.id, true);
    await this.refresh();
  }

  @handle(EnsureScheduledExportHistoryAction)
  private async handleEnsureScheduledExportHistoryAction(action: EnsureScheduledExportHistoryAction) {
    if (this.state.exportDefinitionId == action.exportDefinitionId
      && this.state.scheduledExportId == action.scheduledExportId
      && this.state.loaded)
      return;

    this.setState(state => ({
      ...state,
      currentPage: 1,
      exportDefinitionId: action.exportDefinitionId,
      scheduledExportId: action.scheduledExportId,
    }));

    await this.refresh();
  }

  @handle(ToggleScheduledExportHistorySortAction)
  private async handleToggleScheduledExportHistorySortAction(action: ToggleScheduledExportHistorySortAction) {
    if (this.state.sort == action.sort && this.state.loaded)
      return;

    this.setState(state => ({
      ...state,
      sort: action.sort,
    }));

    await this.refresh();
  }

  @handle(SearchAction)
  private async handleSearchAction(action: SearchAction) {
    this.setState(state => ({
      ...state,
      currentPage: 1,
      search: action.term,
      loaded: false
    }));

    await this.refresh();
  }

  @handle(NextPageAction)
  private async handleNextPageAction() {
    this.setState(state => ({
      ...state,
      currentPage: state.currentPage + 1,
      loaded: false
    }));

    await this.refresh();
  }

  @handle(PrevPageAction)
  private async handlePrevPageAction() {
    this.setState(state => ({
      ...state,
      currentPage: state.currentPage - 1,
      loaded: false
    }));

    await this.refresh();
  }

  private async refresh() {
    const { list, total } = await this.exportTaskRepo.scheduled(
      this.state.seasonId,
      this.state.exportDefinitionId,
      this.state.scheduledExportId,
      this.state.currentPage,
      this.state.pageSize,
      this.state.filter,
      this.state.search,
      this.state.sort,
      "Id,Definition(Name,Format,DataType),MetaData(DateCreatedUtc,DateUpdatedUtc,IdCount,Status,StatusMessage,TaskRequestId,FailedDeliveries(FileId,DeliveryFailureMessage,AlternateFileName),FilesToDeliver(FileId,AlternateFileName),DeliveredFiles(FileId,AlternateFileName)),Delivery(FileProviderId),definition(name,format,dataType),metaData(dateUpdatedUtc,idCount,status,statusMessage,taskRequestId,failedDeliveries(fileId,deliveryFailureMessage,alternateFileName),filesToDeliver(fileId,alternateFileName),deliveredFiles(fileId,alternateFileName)),delivery(fileProviderId)"
    );

    const lastPage = Math.ceil(total / this.state.pageSize);
    let currentPage = Math.min(this.state.currentPage, lastPage);
    const pageStartNumber = ((currentPage - 1) * this.state.pageSize) + 1;
    const pageEndNumber = pageStartNumber + (this.state.pageSize - 1);
    const hasNextPage = pageEndNumber < total;
    const hasPreviousPage = pageStartNumber > 1;
    const canAdvancePage = pageEndNumber + 1 < MAX_SKIP;

    this.setState(state => ({
      ...state,
      loaded: true,
      collection: list,
      total,
      currentPage,
      pageStartNumber: Math.max(pageStartNumber, 0),
      pageEndNumber: Math.min(pageEndNumber, total),
      hasNextPage,
      hasPreviousPage,
      canAdvancePage,
      lastPage
    }));
  }
}
