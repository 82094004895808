import { prop, inject, ComponentEventBus, needs } from "fw";

import { FeatureFlagService } from "service/feature-flag";
import { DataDictionaryField, DataDictionaryFieldCategory, DataDictionaryFieldDataSource, SystemFieldType } from "models/data-dictionary";
import { gridColumnFormCreator } from "forms/grid-column";
import { groupBy, set } from "lodash-es";
import { QuestionType } from "../../../../form-runtime/src/enums";
import { CalculatedFieldDataTypeCode } from "models/calculated-field";
import { CustomFieldType } from "models/contact-organization";
import { DraggableItem } from "./draggable-item";

export type ExportCodeOptions = { name: string; id: string }
export type PathAndLabel = { Path: string; Label: string; Sort?: string; Type: PathType; [others: string]: any; };
export type Category = { category: string; paths: PathAndLabel[]; exportCodeOptions?: ExportCodeOptions[] };
export type PathType = "String" | "Number" | "Boolean" | "Array" | "Object" | "Tags" | "TableSection" | "File" | "Date" | "Enumeration";

export const toPathType = (field: DataDictionaryField): PathType => {
  if (field.DataType === undefined || field.DataType === null)
    return toDefaultFieldType(field);

  switch (field.DataSource) {
    case DataDictionaryFieldDataSource.SystemFieldType:
      return toSystemFieldPathType(field);
    case DataDictionaryFieldDataSource.ContactsFieldType:
      return toContactsFieldPathType(field);
    case DataDictionaryFieldDataSource.CalculatedFieldDataTypeCode:
      return toCalculatedFieldPathType(field);
    case DataDictionaryFieldDataSource.QuestionTypeCode:
      return toQuestionPathType(field);
    default:
      return toDefaultFieldType(field);
  }
}

const toDefaultFieldType = (field: DataDictionaryField): PathType => {
  if (field.IsTableSection) {
    return "TableSection";
  }

  switch (field.JsonDataType) {
    case "string": return "String";
    case "number": return "Number";
    case "boolean": return "Boolean";
    case "object": return "Object";
    case "array": return field.Path === "tags" && field.Label === "Tags" ? "Tags" : "Array";
    default: return null;
  }
}

const toSystemFieldPathType = (field: DataDictionaryField): PathType => {
  switch (field.DataType) {
    case SystemFieldType.Boolean: return "Boolean";
    case SystemFieldType.Date: return "Date";
    case SystemFieldType.Number: return "Number";
    case SystemFieldType.String: return "String";
    case SystemFieldType.File: return "File";
    case SystemFieldType.Tags: return "Tags";
    case SystemFieldType.Geo: return "Object";
  }
  return "Object";
}

const toCalculatedFieldPathType = (field: DataDictionaryField): PathType => {
  switch (field.DataType) {
    case CalculatedFieldDataTypeCode.Boolean: return "Boolean";
    case CalculatedFieldDataTypeCode.Date: return "Date";
    case CalculatedFieldDataTypeCode.Encrypted: return "String";
    case CalculatedFieldDataTypeCode.Enumeration: return "Enumeration";
    case CalculatedFieldDataTypeCode.File: return "File";
    case CalculatedFieldDataTypeCode.Json: return "Object";
    case CalculatedFieldDataTypeCode.Number: return "Number";
    case CalculatedFieldDataTypeCode.WeightedScore: return "Number";
    case CalculatedFieldDataTypeCode.String: return "String";
  }
  return "Object";
}

const toContactsFieldPathType = (field: DataDictionaryField): PathType => {
  switch (field.DataType) {
    case CustomFieldType.attachment:
    case CustomFieldType.address:
    case CustomFieldType.link:
    case CustomFieldType.relationship:
    case CustomFieldType.slideroomapplications:
    case CustomFieldType.slideroomadmissionsapplications:
    case CustomFieldType.table:
    case CustomFieldType.testscore:
      return "Object";
    case CustomFieldType.multiselect:
    case CustomFieldType.tags:
      return "Array";
    case CustomFieldType.number:
      return "Number";
    case CustomFieldType.boolean:
      return "Boolean";
    case CustomFieldType.date:
      return "Date";
    case CustomFieldType.dropdown:
    case CustomFieldType.email:
    case CustomFieldType.phone:
    case CustomFieldType.largestring:
    case CustomFieldType.social:
    case CustomFieldType.string:
    case CustomFieldType.user:
    case CustomFieldType.concealed:
    case CustomFieldType.funnel:
    case CustomFieldType.postalcode:
    case CustomFieldType.country:
      return "String";
  }

  return "Object";
}

const toQuestionPathType = (field: DataDictionaryField): PathType => {
  switch (field.DataType) {
    case QuestionType.Address: return "Object";
    case QuestionType.CEEBCode: return "Object";
    case QuestionType.CheckBoxList: return "Array";
    case QuestionType.Date: return "Date";
    case QuestionType.DropDown: return "String";
    case QuestionType.EmailAddress: return "String";
    case QuestionType.Encrypted: return "String";
    case QuestionType.File: return "File";
    case QuestionType.LongText: return "String";
    case QuestionType.Name: return "Object";
    case QuestionType.Number: return "Number";
    case QuestionType.PhoneNumber: return "String";
    case QuestionType.RadioButton: return "String";
    case QuestionType.Scale: return "Number";
    case QuestionType.ScaleGroup: return "Object";
    case QuestionType.ShortText: return "String";
    case QuestionType.Table: return "Object";
    case QuestionType.URL: return "String";
  }

  return "Object";
};

export const toPathAndLabel = (field: DataDictionaryField): PathAndLabel => {
  return { Label: field.Label, Path: field.ExportPath ?? field.Path, Sort: field.Sort, Type: toPathType(field) };
};

// If fields are filtered by data type or routable the first item may not be a placeholder.
export const toCategory = (fields: DataDictionaryField[], firstItemIsCategoryPlaceHolder: boolean = true): Category[] => {
  const categories: Category[] = [];
  toCompleterStructure(fields);

  const fieldsByCategory = groupBy(fields, f => f.Category);
  const structure = [];
  const completerStructure = {};
  for (const category in fieldsByCategory) {
    const [categoryFieldPlaceHolder, ...categoryFields] = fieldsByCategory[category];
    const paths = firstItemIsCategoryPlaceHolder && !categoryFieldPlaceHolder.Path ? categoryFields : fieldsByCategory[category];

    structure.push(...paths.map(item => item.Path?.split('.'))) ;
    categories.push(<Category>{
      category: categoryFieldPlaceHolder.Category,
      paths: paths.map(c => toPathAndLabel(c)),
    });
  }

  structure.forEach(arr => {
    if (arr.length === 1) {
      completerStructure[arr[0]] = '';
      return;
    }

    set(completerStructure, arr, '')
  });

  return categories.filter(c => c.paths.length > 0);
};


export const toCompleterStructure = (fields: DataDictionaryField[], filterPrefix: string = null) => {
  const flatStructure = [];
  const completerStructure = {};

  fields.map(item => item.Path && flatStructure.push(item.Path?.replace(filterPrefix, '').split('.')));

  flatStructure.forEach(arr => {
    if (arr.length === 1) return completerStructure[arr[0]] = '';

    set(completerStructure, arr, '')
  });

  return completerStructure
};

export const filterCategoriesByType = (categories: Category[], types: PathType[]): Category[] => {
  if (!types) {
    return categories;
  }

  const filteredCategories: Category[] = [];
  for (const category of categories) {
    const paths = filterPathsByType(category.paths, types);
    if (paths.length > 0) {
      filteredCategories.push(<Category>{
        ...category,
        paths
      })
    }
  }

  return filteredCategories;
};

export const filterCalculatedNumberCategories = (categories: Category[], types: PathType[]): Category[] => {
  if (!types) {
    return categories;
  }

  const filteredCategories: Category[] = [];
  for (const category of categories.filter(c => c.category === DataDictionaryFieldCategory.Application)) {
    const paths = filterPathsByType(category.paths, types);
    if (paths.length > 0) {
      filteredCategories.push(<Category>{
        ...category,
        paths
      })
    }
  }

  return filteredCategories;
};

export const filterPathsByType = (paths: PathAndLabel[], types: PathType[]): PathAndLabel[] => {
  return paths.filter(p => types.includes(p.Type));
};

@inject
@needs(
  DraggableItem,
)
export class PathChooser {
  @prop(() => ([])) public availableColumns!: Category[];
  @prop(() => ([])) public selectedColumns!: PathAndLabel[];

  public searchTerm: string = null;
  public assistiveText: string = "";

  constructor(
    private ceb: ComponentEventBus,
    private ffs: FeatureFlagService,
  ) { }

  private get searching(): boolean {
    return this.searchTerm && this.searchTerm.length > 0;
  }

  public showLabel(label: string): boolean {
    return !this.searching || label.toLocaleLowerCase().indexOf(this.searchTerm.toLocaleLowerCase()) >= 0;
  }

  public showCategory(category: Category): boolean {
    return !this.searching || category.paths.some(p => this.showLabel(p.Label));
  }

  public isAdded(path: string) {
    return this.selectedColumns.find(sc => sc.Path == path) != null;
  }

  private addColumn(col: PathAndLabel) {
    if (this.isAdded(col.Path)) return;

    this.ceb.dispatch("added-column", col);
  }

  public removeColumn(col: PathAndLabel) {
    const item = this.selectedColumns.find(sc => sc.Path == col.Path);
    if (item == null) {
      return;
    }

    const idx = this.selectedColumns.indexOf(item);
    if (idx >= 0) {
      this.selectedColumns.splice(idx, 1);
      this.assistiveText = `Removed ${item.Label} field`;
    }
  }

  public cloneField(item) {
    if (item == null) {
      return;
    }

    return gridColumnFormCreator(item);
  }

  public getAriaLabel(col: PathAndLabel): string {
    const label: string = (col.Label || "").replace("_", "").replace(" ", "").trim();
    const path: string = (col.Path || "").replace("_", "").replace(" ", "").trim();
    if (label.toLocaleLowerCase() === path.toLocaleLowerCase()) {
      return "Column Label";
    }

    return `${path} Column Label`
  }

  public get ffAccessibleDragDrop(): boolean {
    return this.ffs.isFeatureFlagEnabled("AccessibleDragDropOUT2055Nov2023");
  }

  public onShowOnGridKeyUp($event, field: PathAndLabel) {
    if (this.isAdded(field.Path)) {
      this.assistiveText = `${field.Label} field is already added to list of fields shown on grid`;
      return;
    }
    
    this.addColumn(field)
    this.assistiveText = `${field.Label} field moved to list of fields shown on grid.`
  }

  public onFieldSelected(field: PathAndLabel) {
    if (this.isAdded(field.Path)) {
      this.assistiveText = `${field.Label} field cannot be selected because it's already added to list of fields shown on grid`;
      return;
    }

    this.assistiveText = `${field.Label} field selected.`
  }

  public onCategorySelected() {
    this.assistiveText = "Use Up and Down Arrows to navigate, Enter or Spacebar to move selected field to list of shown fields on grid."
  }
}
