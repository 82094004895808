import { ComponentEventBus, prop, inject, needs } from "fw";

import { UserPill } from "./user-pill";

@inject
@needs(UserPill)
export class UserField {
  @prop(undefined) public value!: string[];
  @prop(false) showRemove!: boolean;

  constructor(private ceb: ComponentEventBus) { }

  remove(value: string) {
    this.ceb.dispatch("remove", value);
  }
}
