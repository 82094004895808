import { ComponentEventBus, inject, needs, prop } from "fw";
import {
  type ICustomFieldDefinition,
  ICustomFieldOption,
} from "models/contact-organization";

import { FieldMode } from "../field-mode";
import { MultiselectSelector } from "views/components/multiselect-selector";

import { orderBy } from "lodash-es";
import { isValidTags } from "views/settings/contact-types/validators";
import { deepClone } from "fast-json-patch";
import { Permissions } from "service/permission";
import { LocalStorageCache } from "caching";
import { CurrentUserStore } from "state/current-user";
import { ContactsOrganizationService } from "service/contacts-organization";
import { generateDataId } from "helpers/data-id-gen";

@inject
@needs(MultiselectSelector)
export class TagsType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string[];
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public isFilter!: boolean;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(false) public showClear!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private permissions: Permissions,
    private cache: LocalStorageCache,
    private currentUserStore: CurrentUserStore,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  public get options(): string[] {
    if (!this.field || !this.field.options) {
      return [];
    }

    return orderBy(this.field.options, "display_order").map((o) => o.name);
  }

  public get dataId() {
    return generateDataId(null, this.field.display_name);
  }

  public get metaDescription() {
    return this.contactOrganizationService.getFieldMetaDescription(this.field);
  }

  public get localValue(): string[] {
    return this.value;
  }

  public set localValue(value: string[]) {
    this.ceb.dispatch("update:value", value);
  }

  public get editable(): boolean {
    return this.field && !this.field.is_read_only && !this.disabled;
  }

  public get allowWriteInLocalValue(): boolean {
    return (
      this.field && this.field.data && this.field.data.automatically_add_values
    );
  }

  public get hasPermission(): boolean {
    return this.permissions.all("EditContacts");
  }

  public get showAdd(): boolean {
    return (
      this.editable &&
      this.allowWriteInLocalValue &&
      this.hasPermission &&
      !this.isFilter
    );
  }

  public async validate(tags: string[]): Promise<string | null> {
    const field: ICustomFieldDefinition = deepClone(this.field);
    field.options = tags.map((tag) => ({ name: tag } as ICustomFieldOption));

    return isValidTags(field);
  }

  public clear() {
    this.ceb.dispatch("update:value", []);
  }
}
