import { inject, needs } from "fw";

import { ContactRestrictionType } from "models/contact-organization";
import { ContactTypeDataPolicyFormType } from "forms/contact-organization";

import { PopoverController } from "service/popover";
import { getContactDataRestrictionType, dataRestrictionComponents } from "./contact-data-restriction-types";

@inject
@needs(...dataRestrictionComponents)
export class ContactDataRestrictionPopover {
  private getContactDataRestrictionType = getContactDataRestrictionType;
  private selectedType: ContactRestrictionType = null;
  private form: ContactTypeDataPolicyFormType = null;

  constructor(private controller: PopoverController<ContactTypeDataPolicyFormType>) { }

  activate({ form, selectedType }: { form: ContactTypeDataPolicyFormType, selectedType: ContactRestrictionType }) {
    // only one type (for now); just set it
    this.selectedType = selectedType || "FieldDefinition";
    this.form = form;
  }

  done(result) {
    this.controller.ok(result);
  }
}
