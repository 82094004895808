import { inject } from "fw";
import { Store, handle } from "fw-state";
import { once } from "helpers/once";
import { FormErrorHandling } from "./error-handling";
import {
  ExportDefinition,
  ExportFormatTypeCode,
} from "models/export-definition";
import {
  AddExportDefinitionAction,
  DeleteExportDefinitionsAction,
  UpdateExportDefinitionAction,
} from "forms/export-definition";

import { ExportDefinitionRepository } from "network/export-definition-repository";

import { LogoutAction, StartAction } from "./actions";
import { difference } from "lodash-es";

interface ExportShape {
  definitions: ExportDefinition[];
}

const cleanOptions = (model: ExportDefinition) => {
  switch (model.Format) {
    case ExportFormatTypeCode.JSON:
      model.TabularOptions = null;
      model.PdfOptions = null;
      model.FileOptions = null;
      break;

    case ExportFormatTypeCode.Tabular:
      model.JsonOptions = null;
      model.PdfOptions = null;
      model.FileOptions = null;
      break;

    case ExportFormatTypeCode.PDF:
      model.JsonOptions = null;
      model.TabularOptions = null;
      model.FileOptions = null;
      break;

    case ExportFormatTypeCode.Files:
      model.JsonOptions = null;
      model.TabularOptions = null;
      model.PdfOptions = null;
      if (model.FileOptions.IndexOptions != null && model.FileOptions.IndexOptions.Fields.length == 0) model.FileOptions.IndexOptions = null;
      break;
  }

  return model;
};

export class EnsureExportStoreAction {
  constructor() { }
}

@inject
export class ExportStore extends Store<ExportShape> {
  constructor(private exportDefRepo: ExportDefinitionRepository) {
    super();
  }

  defaultState() {
    return {
      definitions: []
    };
  }

  @handle(StartAction)
  private handleStart(s: StartAction) {
    this.setState(s => this.defaultState());
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureExportStoreAction)
  private async handleEnsureExportStoreAction(action: EnsureExportStoreAction) {
    await once("ensure-export-definitions", async () => {
      const exportDefinitions = await this.exportDefRepo.list();

      this.setState(state => ({
        ...state,
        definitions: exportDefinitions,
      }));
    });
  }

  @handle(AddExportDefinitionAction, FormErrorHandling)
  private async handleAddExportDefinitionAction(
    action: AddExportDefinitionAction,
  ) {
    action.form.validate();    
    const model = cleanOptions(action.form.updatedModel());
    model.Id = null;
    const newDef = await this.exportDefRepo.post(model, action.ownerId);

    action.addedId = newDef.Id;

    this.setState(state => ({
      ...state,
      definitions: [...state.definitions, newDef],
    }));
  }

  @handle(UpdateExportDefinitionAction, FormErrorHandling)
  private async handleUpdateExportDefinitionAction(
    action: UpdateExportDefinitionAction,
  ) {
    action.form.validate();

    const model = cleanOptions(action.form.updatedModel());
    const updatedDef = await this.exportDefRepo.put(model, action.newOwnerId);

    const existingDef = this.state.definitions.find(t => t.Id == updatedDef.Id);
    if (existingDef == null) return;

    Object.assign(existingDef, updatedDef);

    this.setState(s => s);
  }

  @handle(DeleteExportDefinitionsAction)
  private async handleDeleteExportDefinitionsAction(
    action: DeleteExportDefinitionsAction,
  ) {
    const defs = this.state.definitions.filter(t =>
      action.exportDefinitionIds.some(id => id == t.Id),
    );

    await this.exportDefRepo.del(defs.map(t => t.Id));
    this.setState(state => ({
      ...state,
      definitions: difference(state.definitions, defs),
    }));
  }
}
