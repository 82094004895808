import { inject, prop, needs } from "fw";

import { ProgramStore } from "state/program";
import { ProgramFilter as ProgramFilterModel } from "models/application-filters";
import { orderBy } from "lodash-es";
import { ValueField, ValueType } from "./value-field";

@inject
@needs(ValueField)
export class ProgramFilter {
  @prop(null) filter: ProgramFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  public programs: { text: string, value: string }[] = [];
  public programNameHash: { [id: string]: string } = {};
  public valueType = ValueType.IsOnly;

  constructor(private programStore: ProgramStore) { }

  public attached() {
    const newHash: { [id: string]: string } = {};

    let programs = [];

    this.programStore.state.programs.filter(f => f.IsHidden !== true).forEach(p => {
      programs.push({ text: p.Name, value: p.Id });
      newHash[p.Id] = p.Name;
    });

    this.programs = orderBy(programs, [p => p.text]);

    this.programNameHash = newHash;
  }

  public get filterText() {
    switch (this.filter.operator) {
      case "=": return "is";
      case "!=": return "is not";
    }

    return this.filter.operator;
  }
}
