import { inject, prop } from "fw";

import { TaskRequestStore } from "state/task-request";
import { PopoverService } from "service/popover";

import { TaskListingPopover } from "./task-listing-popover";

@inject
export class TaskStatus {
  @prop(false) circular: boolean;
  private $refs: any;

  constructor(private taskRequestStore: TaskRequestStore,
    private popover: PopoverService) { }

  showTaskPopover() {
    this.popover.open(TaskListingPopover, null, null, null, null, this.$refs.progressBarEl.$el);
  }

  public get isInProgress() {
    return this.taskRequestStore.state.runningTasks.length > 0;
  }

  public get percentage() {
    return ((this.taskRequestStore.state.runningStats.completed / this.taskRequestStore.state.runningStats.total) * 100)
  }
}
