import { inject } from "fw";
import { Store, handle } from "fw-state";
import { createFrom, createFromProperties } from "fw-model";

import { FormErrorHandling } from "./error-handling";
import { LogoutAction, StartAction } from "./actions";
import {
  PdfExportTemplate,
  PdfExportTemplateSet,
  PdfExportTemplateHash,
} from "models/pdf-export-template";

import { PdfExportTemplateSetRepository } from "network/pdf-export-template-set-repository";
import {
  FormForPdfExportTemplate,
  FormForPdfExportTemplateHash,
  UpdatePdfExportTemplateSetAction,
} from "forms/pdf-export-template";

export interface TemplateSetContainer {
  orgTemplateSet: PdfExportTemplateSet;
  defaultTemplateSet: PdfExportTemplateSet;

  mergedTemplates: PdfExportTemplateHash;

  totalEditedTemplates: number;
  totalTemplates: number;
}

interface PdfExportTemplateShape {
  loaded: boolean;
  applicationExport: TemplateSetContainer;
  applicantExport: TemplateSetContainer;
}

const mergeTemplates = (
  originalTemplates: PdfExportTemplateHash,
  editedTemplates: PdfExportTemplateHash,
) => {
  const aCopy = createFromProperties(
    PdfExportTemplate,
    JSON.parse(JSON.stringify(originalTemplates)),
  );
  const bCopy = createFromProperties(
    PdfExportTemplate,
    JSON.parse(JSON.stringify(editedTemplates)),
  );

  Object.assign(aCopy, bCopy);

  return {
    totalTemplates: Object.keys(aCopy).length,
    totalEditedTemplates: Object.keys(bCopy).length,
    mergedTemplates: aCopy,
  };
};

export class EnsurePdfExportTemplatesAction {}

@inject
export class PdfExportTemplateStore extends Store<PdfExportTemplateShape> {
  constructor(private pdfExportTemplateRepo: PdfExportTemplateSetRepository) {
    super();
  }

  defaultState() {
    return {
      loaded: false,
      applicationExport: {
        orgTemplateSet: null,
        defaultTemplateSet: null,

        mergedTemplates: null,
        totalTemplates: 0,
        totalEditedTemplates: 0,
      },
      applicantExport: {
        orgTemplateSet: null,
        defaultTemplateSet: null,

        mergedTemplates: null,
        totalTemplates: 0,
        totalEditedTemplates: 0,
      },
    };
  }

  @handle(LogoutAction)
  @handle(StartAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsurePdfExportTemplatesAction)
  private async handleEnsurePdfExportTemplatesAction() {
    if (this.state.loaded) return;

    const res = await Promise.all([
      this.pdfExportTemplateRepo.get("Default"),
      this.pdfExportTemplateRepo.get("Default", true),
      this.pdfExportTemplateRepo.get("Applicant"),
      this.pdfExportTemplateRepo.get("Applicant", true),
    ]);

    const orgTemplateSet = res[0];
    const defaultTemplateSet = res[1];

    const applicantTemplateSet = res[2];
    const applicantDefaultTemplateSet = res[3];

    const {
      mergedTemplates,
      totalEditedTemplates,
      totalTemplates,
    } = mergeTemplates(defaultTemplateSet.Templates, orgTemplateSet.Templates);

    const {
      mergedTemplates: applicantMergedTemplates,
      totalEditedTemplates: applicantTotalEditedTemplates,
      totalTemplates: applicantTotalTemplates,
    } = mergeTemplates(
      applicantDefaultTemplateSet.Templates,
      applicantTemplateSet.Templates,
    );

    this.setState(state => ({
      loaded: true,
      applicantExport: {
        defaultTemplateSet: applicantDefaultTemplateSet,
        orgTemplateSet: applicantTemplateSet,

        mergedTemplates: applicantMergedTemplates,
        totalEditedTemplates: applicantTotalEditedTemplates,
        totalTemplates: applicantTotalTemplates,
      },
      applicationExport: {
        defaultTemplateSet,
        orgTemplateSet,

        mergedTemplates,
        totalEditedTemplates,
        totalTemplates,
      },
    }));
  }

  @handle(UpdatePdfExportTemplateSetAction, FormErrorHandling)
  private async handleUpdatePdfExportTemplateSetAction(
    action: UpdatePdfExportTemplateSetAction,
  ) {
    const templateSetContainer: TemplateSetContainer = this.state[
      action.setKey
    ];
    if (templateSetContainer == null) return;

    action.form.validate();

    // for (const key in action.templateSetForm.Templates) {
    //   action.templateSetForm.Templates[key].validate();
    // }

    // const modelSet: PdfExportTemplateSet = createFrom(PdfExportTemplateSet, null);
    // for (const key in action.formSet)
    //   modelSet.Templates[key] = action.formSet[key].updatedModel();

    let setName = "Default";
    switch (action.setKey) {
      case "applicantExport":
        setName = "Applicant";
        break;
    }

    const updatedTemplateSet = await this.pdfExportTemplateRepo.put(
      setName,
      action.form.updatedModel(),
    );
    const {
      mergedTemplates,
      totalEditedTemplates,
      totalTemplates,
    } = mergeTemplates(
      templateSetContainer.defaultTemplateSet.Templates,
      updatedTemplateSet.Templates,
    );

    this.setState(state => ({
      ...state,
      [action.setKey]: {
        ...state[action.setKey],
        orgTemplateSet: updatedTemplateSet,

        mergedTemplates,
        totalEditedTemplates,
        totalTemplates,
      },
    }));
  }
}
