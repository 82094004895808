import { inject } from "fw";

import { FeatureFlagsStore } from "state/feature-flags";

@inject
export class FeatureFlagService {
  constructor(private featureFlagsStore: FeatureFlagsStore) { }

  public isFeatureFlagEnabled(flag: string) {
    if (flag == null) return false;

    const { organization } = this.featureFlagsStore.state;
    if (organization == null || organization[flag] == null) return false;

    return organization[flag];
  }
}
