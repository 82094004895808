import { prop, inject, ComponentEventBus } from "fw";
import { Form } from "fw-model";

@inject
export class DropdownFormField {
  @prop(null) private form!: Form;
  @prop(false) private nullIsDirty;
  @prop(null) private label;
  @prop(false) public hideLabel!: boolean;
  @prop(null) private field!: string;
  @prop(false) private disabled;
  @prop(null) private required;
  @prop(() => []) private options!: { value: any; text: string }[];
  @prop(null) private enumeration;

  constructor(private ceb: ComponentEventBus) { }

  get optionsFromEnum(): { value: any; text: string }[] {
    return Object.values(this.enumeration)
      .filter(key => !isNaN(key as any))
      .map(key => ({ value: key, text: this.enumeration[key as string] }));
  }

  get name() {
    return this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;
  }

  change() {
    this.ceb.dispatch("change");
  }
}
