import { formFor, FormForType } from "fw-model";
import { ContactsDataSource } from "./contacts-data-source";

export enum ImportType {
  both = 0,
  contact = 1,
  activity = 2
}

export class ContactsDataSourceInstance {
  id: string;
  name: string;
  organization_id: string;
  data_source_id: string;
  data_source_name: string;
  data_source_key: string;
  contact_type: string;
  import_type: ImportType;
  requires_configuration: boolean;
  is_enabled: boolean;
  is_deleted: boolean;
  data: { [key: string]: any; };
  created_utc: string;
  updated_utc: string;
}

export type ContactsDataSourceInstanceFormType = FormForType<ContactsDataSourceInstance>;

export const contactsDataSourceInstanceFormCreator = formFor(ContactsDataSourceInstance, s => {
  s.field(f => f.id, "Id");
  s.requiredField(f => f.name, "Name");
  s.field(f => f.organization_id, "Organization Id");
  s.requiredField(f => f.contact_type, "Contact Type");
  s.requiredField(f => f.data_source_id, "Data Source Id");
  s.requiredField(f => f.data_source_name, "Data Source Name");
  s.requiredField(f => f.data_source_key, "Data Source Key");
  s.requiredField(f => f.import_type, "Import Type");
  s.field(f => f.requires_configuration, "Requires Configuration");
  s.field(f => f.is_enabled, "Enabled");
  s.field(f => f.is_deleted, "Deleted");
  s.field(f => f.data, "Data");
  s.field(f => f.created_utc, "Created");
  s.field(f => f.updated_utc, "Updated");
});

export interface IUpdateDataSourceInstance {
  name?: string;
  is_enabled?: boolean;
  import_type?: ImportType;
  data?: { [key: string]: any; };
}

export interface INewDataSourceInstance {
  name: string;
  is_enabled: boolean;
  is_deleted: boolean;
  data_source_id: string;
  import_type: ImportType;
  contact_type: string;
  data?: { [key: string]: any; };
}

export interface IExpandedDataSourcesResult extends ContactsDataSource {
  instances: ContactsDataSourceInstance[];
}

export interface IFileUploadConfiguration {
  contact_type: string;
  mapping_configuration: IConfiguredCustomMapping;
}

export interface IConfiguredCustomMapping {
  is_first_row_headers: boolean;
  columns: IConfiguredCustomMappingColumn[];
}

export interface IConfiguredCustomMappingColumn {
  from: string;
  from_script: string;
  to: string;
  unique: boolean;
  translation_table_id: string;
}

export interface IMapField {
  display_name: string;
  value: string;
  type: string;
  sample_data?: string[];
  data?: { [key: string]: any };
}

export enum WebhookPayloadType
{
  Ids = 0,
  Models = 1,
  Script = 2
}
