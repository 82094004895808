import { fromClass } from "fw-model";

import { ApplicationSegment } from "models/application-segment";
import { EventFrequencies } from "models/calendar-event";
import { ContactSegment } from "models/contact-segment";
import { MarketingOutreachItemAttachment } from "models/marketing-outreach-item-attachment";
import { MarketingOutreachSent, VoiceMessageType } from "models/marketing-outreach-sent";
import { MarketingOutreachTier } from "models/marketing-outreach-tier";
import { EventMessageTypes } from "views/calendar/calendar.constants";

export enum MarketingBulkMessageStageTypes {
  details,
  message,
  schedule
}

export enum MarketingBulkMessageDetailsStageTypes {
  summary,
  recipients,
  engagement
}

export enum MarketingBulkMessagePrettyTypes {
  email = "Email",
  text = "Text",
  voice = "Voice"
}

export enum MarketingBulkMessageTypes {
  email = "email",
  text = "text",
  voice = "voice"
}

export enum MarketingBulkMessageIcons {
  email = "email",
  text = "textsms",
  voice = "mic"
}

export enum MarketingBulkMessageColors {
  email = "#3978ac",
  text = "#8364aa",
  voice = "#6c777f"
}

export enum MarketingBulkMessageManagePermissions {
  email = "ManageEmails",
  text = "ManageTextMessages",
  voice = "ManageVoiceMessages"
}

export enum MarketingBulkMessageSendPermissions {
  email = "SendEmails",
  text = "SendTextMessages",
  voice = "SendVoiceMessages"
}

export enum MarketingBulkMessageSendOptions {
  draft,
  now,
  later
}

export enum MarketingBulkMessagePrettyStatus {
  sent = "Sent",
  scheduled = "Scheduled",
  draft = "Draft",
  inactive = "Inactive",
  not_sent = "Not Sent",
}

export enum MarketingBulkMessageStatus {
  sent = "sent",
  scheduled = "scheduled",
  draft = "draft",
  inactive = "inactive",
  not_sent = "not_sent",
}

export const MESSAGE_STATUS_OPTIONS: { [key in MarketingBulkMessageStatus]: { color: string, label: string } } = {
  [MarketingBulkMessageStatus.sent]: {
    color: "#1976D2",
    label: "Sent",
  },
  [MarketingBulkMessageStatus.scheduled]: {
    color: "#2b8530",
    label: "Scheduled",
  },
  [MarketingBulkMessageStatus.draft]: {
    color: "#A56800",
    label: "Draft",
  },
  [MarketingBulkMessageStatus.inactive]: {
    color: "#9E1208",
    label: "Inactive",
  },
  [MarketingBulkMessageStatus.not_sent]: {
    color: "#9E1208",
    label: "Not Sent",
  },
};

export enum AudienceTypes {
  onePerPerson = "applicant",
  onePerApplication = "applications"
}

export enum SegmentType {
  applications = "applications",
  contacts = "contacts"
}

export enum MarketingBulkMessageActiveStatus {
  inactive,
  active
}

export type MessageType = { text: MarketingBulkMessagePrettyTypes; value: MarketingBulkMessageTypes };
export const MESSAGE_TYPES: MessageType[] = [
  {
    text: MarketingBulkMessagePrettyTypes[MarketingBulkMessageTypes.email],
    value: MarketingBulkMessageTypes.email,
  },
  {
    text: MarketingBulkMessagePrettyTypes[MarketingBulkMessageTypes.text],
    value: MarketingBulkMessageTypes.text,
  },
  {
    text: MarketingBulkMessagePrettyTypes[MarketingBulkMessageTypes.voice],
    value: MarketingBulkMessageTypes.voice,
  },
];

export type MarketingBulkMessageFilters = {
  Active?: MarketingBulkMessageActiveStatus,
  CreatedBy?: string,
  SendDateFrom?: string,
  SendDateTo?: string,
  Type?: MarketingBulkMessageTypes,
  AudienceType?: string,
  Status?: MarketingBulkMessageStatus[]
}

export const EMPTY_FILTER_CONTAINER: MarketingBulkMessageFilters = {
  Active: null,
  CreatedBy: null,
  SendDateFrom: null,
  SendDateTo: null,
  Type: null,
  AudienceType: null,
  Status: [],
};

export type AudienceType = { text: string; value: AudienceTypes }
export const AUDIENCE_TYPES: AudienceType[] = [
  {
    text: "Applicant (one per person) ",
    value: AudienceTypes.onePerPerson,
  },
  {
    text: "Applicant (one per application)",
    value: AudienceTypes.onePerApplication,
  },
];

export class MarketingBulkMessage {
  Id: number = null;
  AudienceType: string = AudienceTypes.onePerPerson;
  Type: MarketingBulkMessageTypes = null;
  OpenRating: number = 5;
  ClickRating: number = 10;
  Name: string = null;
  Subject: string = null;
  Html: string = null;
  Text: string = null;
  Markup: string = null;
  HtmlFile: string = null;
  TextFile: string = null;
  MarkupFile: string = null;
  EmailTemplateId: number = null;
  SegmentType: string = AudienceTypes.onePerPerson;
  SegmentId: string = null;
  Segment: any = null;
  DeliveryAddress: string = null;
  Active: number = 0;
  Stats: string = null;
  Replies: string = "self";
  SendDate: string = null;
  SendDays: string = null;
  SendTime: string = null;
  SentId: number = null;
  CreatedBy: string = null;
  SentBy: string = null;
  Status: MarketingBulkMessageStatus = null;
  SendNow: number = 0;

  EventSeriesId: string = null;
  EventOccurrenceId: string = null;
  EventMessageType: EventMessageTypes = null;
  EventFrequency: EventFrequencies = null;

  FromName: string = null;
  FromAddress: string = null;
  ReplyToName: string = null;
  ReplyToAddress: string = null;
  VoiceMessageType: VoiceMessageType = null;
  VoiceMessageVoice: string = null;
  VoiceMessageUrl: string = null;
  Filter: string = null;
  LastSentAt: string = null;
  attachments: Array<MarketingOutreachItemAttachment> = [];
  @fromClass segment: ApplicationSegment | ContactSegment;
  @fromClass tier: MarketingOutreachTier;
  @fromClass lastSent: MarketingOutreachSent;
  @fromClass lastSentOccurrence: MarketingOutreachSent;
}

export type MarketingBulkMessageEventMeta = Pick<MarketingBulkMessage, 'EventMessageType' | 'EventOccurrenceId' | 'EventSeriesId'>;
