import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { Document } from "models/document";

@inject
export class DocumentRepository {
  constructor(private s: ATS) {}

  public async list(ids: string[]) {
    const res = await this.s.get<any[]>(`documents/${ids.join(",")}`);
    return createFromArray(Document, res.body);
  }
}
