export const escapeTerm = (term: string): string => {
  return (term || "").replace(/\"/g, "\\\"");
}

export const getOps = (operator: string) => {
  let preOp = "";
  let op = "=";
  switch (operator) {
    case "!=":
      preOp = "-";
      op = "";
      break;
    case "=":
      preOp = "";
      op = "";
      break;
    default:
      preOp = "";
      op = operator;
      break;
  }
  return { preOp, op };
};
