import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { ATS } from "./ats";
import { TranslationTable, TranslationTableCategory } from 'models/translation-tables';

export class TranslationTablePagedList {
  pageIndex: number;
  pageSize: number;
  total: number;
  list: TranslationTable[];
}

@inject
export class TranslationTableRepository {
  constructor(private s: ATS) {}

  public async get(translationTableId: string) : Promise<TranslationTable> {
    const res = await this.s.get<any[]>(`translation-table/${translationTableId}`);
    return createFrom(TranslationTable, res.body);
  }

  public async put(table: TranslationTable) : Promise<TranslationTable> {
    const res = await this.s.put<TranslationTable>(`translation-table/${table.Id}`, table);
    return createFrom(TranslationTable, res.body);
  }

  public async post(table: TranslationTable) : Promise<TranslationTable>{
    const res = await this.s.post<TranslationTable>('translation-table', table);
    return createFrom(TranslationTable, res.body);
  }

  public async list(pageIndex: number, pageSize: number) : Promise<TranslationTablePagedList> {
    const res = await this.s.get<any[]>('translation-table', {
      page: pageIndex,
      pageSize: pageSize
    });
    return <TranslationTablePagedList>{
      pageIndex: pageIndex,
      pageSize: pageSize,
      list: createFromArray(TranslationTable, (res.body as any).Results),
      total: (res.body as any).Total
    };
  }

  public async del(ids: string[]) {
    await this.s.post('translation-table/delete', ids);
  }

  public async categories() : Promise<TranslationTableCategory[]> {
    const result = await this.s.get<any[]>('translation-table/categories');
    return result.body.map(value => <TranslationTableCategory>{
      Category: value.Item1,
      TableCount: value.Item2
    });
  }
}
