import { prop } from "fw";

export class CircularProgressbar {
  @prop(0) public percent: number;
  @prop(5) public strokeWidth: number;
  @prop(100) public size: number;
  @prop('#43A047') public color: string;
  @prop('#AFBBCC') public backgroundColor: string;

  public get radius() {
    return (this.size - this.strokeWidth) / 2;
  }

  public get center() {
    return this.size / 2;
  }

  public get circumference() {
    return 2 * Math.PI * this.radius;
  }

  public get offset() {
    return this.circumference * (1 - this.percent / 100);
  }
}
