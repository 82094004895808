import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { MarketingOutreachTier } from "models/marketing-outreach-tier";

@inject
export class MarketingOutreachTierRepository {
  constructor(private network: ATS) {}

  async index(trackId: number = 0) {
    const res = await this.network.marketing.get<any[]>(`outreach_tiers/index/${trackId}`);
    return createFromArray(MarketingOutreachTier, res.body);
  }

  async show(id: number) {
    const res = await this.network.marketing.get<MarketingOutreachTier>(`outreach_tiers/${id}`);
    return createFrom(MarketingOutreachTier, res.body);
  }

  async post(tier: MarketingOutreachTier) {
    const res = await this.network.marketing.post<MarketingOutreachTier>("outreach_tiers", tier);
    return createFrom(MarketingOutreachTier, res.body);
  }

  async put(tier: MarketingOutreachTier) {
    const res = await this.network.marketing.put<MarketingOutreachTier>(`outreach_tiers/${tier.Id}`, tier);
    return createFrom(MarketingOutreachTier, res.body);
  }

  async destroy(id: number) {
    await this.network.marketing.delete<MarketingOutreachTier>(`outreach_tiers/${id}`);
  }

  async copy(id: number) {
    const res = await this.network.marketing.post<MarketingOutreachTier>("outreach_tiers/copy", {Id: id});
    return createFrom(MarketingOutreachTier, res.body);
  }
}
