import { inject } from "fw";
import { Store, handle } from "fw-state";


import { LogoutAction, StartAction } from "./actions";
import { ServerDecisionLetterTemplateSet } from "models/decision-letter-template-set";
import { DecisionLetterTemplateSetRepository } from "network/decision-letter-template-set-repository";
import { LocalStorageCache } from "caching";
import { Notification } from "service/notification";
import { union } from "lodash-es";

const DEFAULT_PAGE_SIZE = 20;

interface PdfDecisionLetterTemplateShape {
  organizationId: string;
  userId: string;
  loaded: boolean;
  errorLoading: boolean;
  list: ServerDecisionLetterTemplateSet[];

  total: number;

  search: string;
  sort: string;
  ids: string[];

  previousPageToken: string;
  nextPageToken: string;
  currentPreviousPageToken: string;
  currentNextPageToken: string;
  pageSize: number;
  pageNumber: number;
}

export class NextPageAction {
}

export class PreviousPageAction {
}

export class FetchListAction {
  constructor(
    public search: string = null,
    public sort: string = "",
    public ids: string[] = null
  ) {
  }
}

export class EnsureIdsAction {
  constructor(
    public ids: string[] = null
  ) {
  }
}

export class GeneratePdfAction {
  public taskId: string = null;

  constructor(
    public id: string = null,
    public applicationIds: string[] = null
  ) {
  }
}

export class DeleteSetAction {
  constructor(
    public ids: string[] = []
  ) {
  }
}

type SavedState = {
  sort: string;
  pageNumber: number;
  pageSize: number;
  previousPageToken: string;
  nextPageToken: string;
  currentPreviousPageToken: string;
  currentNextPageToken: string;
};

@inject
export class DecisionLetterStore extends Store<PdfDecisionLetterTemplateShape> {
  constructor(
    private network: DecisionLetterTemplateSetRepository,
    private cache: LocalStorageCache,
    private notification: Notification,
  ) {
    super();
  }

  defaultState() {
    return {
      organizationId: null,
      userId: null,
      loaded: false,
      errorLoading: false,
      list: [],
      total: 0,
      search: null,
      sort: null,
      ids: null,

      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: 1,
    }
  }

  private get cacheKey(): string {
    return `${this.state.organizationId}:${this.state.userId}-decision-letter-template-store`
  }

  private saveState() {
    if (this.state.organizationId == null) {
      return;
    }

    this.cache.set<SavedState>(
      this.cacheKey,
      {
        sort: this.state.sort,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        previousPageToken: this.state.previousPageToken,
        nextPageToken: this.state.nextPageToken,
        currentPreviousPageToken: this.state.currentPreviousPageToken,
        currentNextPageToken: this.state.currentNextPageToken,
      },
    );
  }


  private restoreState() {
    if (this.state.organizationId == null) return;

    const savedState = this.cache.get<SavedState>(this.cacheKey);
    if (savedState == null) return;

    this.setState(state => ({
      ...state,
      sort: savedState.sort,
      pageNumber: savedState.pageNumber || 1,
      pageSize: savedState.pageSize || DEFAULT_PAGE_SIZE,
      previousPageToken: savedState.previousPageToken,
      nextPageToken: savedState.nextPageToken,
      currentPreviousPageToken: savedState.currentPreviousPageToken,
      currentNextPageToken: savedState.currentNextPageToken,
    }));
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private handleStartAction(s: StartAction) {
    this.setState(s => this.defaultState());

    const defaultState = this.defaultState();
    defaultState.organizationId = s.context.Organization.Id;
    defaultState.userId = s.context.Me.Id;

    this.setState(s => defaultState);
    this.restoreState();
  }

  @handle(EnsureIdsAction)
  private async handleEnsureIdsAction(action: EnsureIdsAction) {
    const currentIds = this.state.ids ?? [];
    const combinedIds = union(currentIds, action.ids.filter(id => !!id));
    if (combinedIds.length === currentIds.length) return;

    this.setState(() => ({
      ...this.state,
      ids: combinedIds,
      loaded: false,
    }));

    await this.loadPageList();
  }

  @handle(FetchListAction)
  private async handleFetchListAction(action: FetchListAction) {

    this.setState(() => ({
      ...this.state,
      search: action.search,
      sort: action.sort,
      ids: action.ids,
      loaded: false,
      collapsedLoaded: false,
      collapsedItem: '',
      errorLoading: false,
      previousPageToken: null,
      nextPageToken: null,
      currentPreviousPageToken: null,
      currentNextPageToken: null,
      pageNumber: this.state.search !== action.search ? 1 : this.state.pageNumber,
    }));

    await this.loadPageList();
  }

  @handle(NextPageAction)
  private async handleNextPageAction(action: NextPageAction) {
    const { nextPageToken, pageSize, pageNumber, total } = this.state;

    if (pageNumber * pageSize > total) return;

    this.setState(state => ({
      ...state,
      loaded: false,
      pageNumber: pageNumber + 1
    }));

    await this.loadPageData(null, nextPageToken);
  }

  @handle(PreviousPageAction)
  private async handlePreviousPageAction(action: NextPageAction) {
    const { previousPageToken, pageNumber } = this.state;
    if (pageNumber <= 1) return;

    this.setState(state => ({
      ...state,
      loaded: false,

      pageNumber: pageNumber - 1
    }));

    await this.loadPageData(previousPageToken, null);
  }

  @handle(DeleteSetAction)
  private async handleDeleteSetAction(action: DeleteSetAction) {
    try {
      await this.network.delete(action.ids);
    } catch (e) {
      this.setState(state => ({ ...state, loaded: true, errorLoading: true }));
    }
  }

  private async loadPageList() {
    await this.loadPageData(this.state?.currentPreviousPageToken, this.state?.currentNextPageToken);
  }

  private async loadPageData(previousPageToken: string, nextPageToken: string) {
    const { pageSize, sort, search, ids } = this.state;


    try {
      const clientModelResponse = await this.network.get(ids, null, search, sort, previousPageToken, nextPageToken, pageSize);

      this.setState(state => ({
        ...state,
        loaded: true,
        previousPageToken: clientModelResponse.PreviousPageToken,
        nextPageToken: clientModelResponse.NextPageToken,
        currentPreviousPageToken: previousPageToken,
        currentNextPageToken: nextPageToken,
        list: clientModelResponse.Results,
        total: clientModelResponse.Total,
      }));

      this.saveState();
    } catch (err) {
      this.setState(state => ({ ...state, loaded: true, errorLoading: true }));
    }
  }

  @handle(GeneratePdfAction)
  private async handleGeneratePdfAction(action: GeneratePdfAction) {

    try {
      const task = await this.network.generatePdf(action.id, action.applicationIds);
      action.taskId = task.Id;
      return this.notification.notify(`PDF${action.applicationIds.length > 1 ? '\`s' : '' } generation successfully finished`);
    } catch (err) {
      this.setState(state => ({ ...state, loaded: true, errorLoading: true }));
      return this.notification.error("Failed to generate PDF${action.ids.length > 1 ?? '`s' ");
    }
  }
}
