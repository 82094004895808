import { ComponentEventBus, inject, prop } from "fw";
import { CurrentContactOrganizationStore } from "state/current-contact-organization";
import { IDropdownFieldOptions } from "../../components/dropdown-field";
import { ContactTypeDefinition, ContactOrganization } from "models/contact-organization";

@inject
export class ContactTypeDropdown {
  @prop(null) public contactType!: string;
  @prop(null) public contactTypes!: ContactTypeDefinition[];
  @prop(null) public validation!: string;
  @prop(null) public disabled!: boolean;
  @prop(null) public required!: boolean;
  @prop(null) public nullIsDirty!: boolean;
  @prop("Contact Type") public label!: string;
  @prop(null) public hideLabel!: boolean;

  private contactTypeOptions: IDropdownFieldOptions[] = [];
  private previousContactTypes: ContactTypeDefinition[] = [];

  constructor(
    private ceb: ComponentEventBus,
    private currentContactOrganizationStore: CurrentContactOrganizationStore) { }

  public get options(): IDropdownFieldOptions[] {
    const contactTypes: ContactTypeDefinition[] = this.contactTypes != null ? this.contactTypes : this.organization.contact_types;

    if(this.previousContactTypes === contactTypes) {
      return this.contactTypeOptions
    }

    this.previousContactTypes = contactTypes;

    this.contactTypeOptions = contactTypes?.length
      ? contactTypes.sort((a, b) => a.name.localeCompare(b.name)).map(ct => <IDropdownFieldOptions>{ value: ct.key, text: ct.name })
      : [];

    return this.contactTypeOptions;
  }

  public get localValue() {
    return this.contactType;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:contactType", value);
  }

  private get organization(): ContactOrganization {
    return this.currentContactOrganizationStore.state.organization;
  }
}
