import { inject } from "fw";
import { Store, handle } from "fw-state";

import { LogoutAction, StartAction } from "./actions";
import { EmailTemplate } from "models/email-template";
import { EmailTemplateRepository } from "network/email-template-repository";
import {
  UpdateEmailTemplateAction,
  DeleteCustomTemplateAction,
  SendEmailTemplateAction,
} from "forms/email-template";
import { FormErrorHandling } from "./error-handling";

interface EmailTemplateShape {
  loaded: boolean;
  emailTemplates: EmailTemplate[];
  customEmailTemplates: EmailTemplate[];
}

export class EnsureEmailTemplatesAction {}

@inject
export class EmailTemplateStore extends Store<EmailTemplateShape> {
  constructor(private emailTemplateRepo: EmailTemplateRepository) {
    super();
  }

  defaultState() {
    return {
      loaded: false,
      emailTemplates: [],
      customEmailTemplates: [],
    };
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    this.setState((state) => ({
      ...this.defaultState(),
      customEmailTemplates: action.context.CustomEmailTemplates,
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState((s) => this.defaultState());
  }

  @handle(EnsureEmailTemplatesAction)
  private async handleEnsureEmailTemplates() {
    if (this.state.loaded) return;

    const res = await this.emailTemplateRepo.list();
    const emailTemplates = res.filter((r) => !r.IsCustomKey);

    this.setState((state) => ({
      ...state,
      loaded: true,
      emailTemplates,
    }));
  }

  @handle(DeleteCustomTemplateAction)
  private async handleDeleteCustomTemplateAction(action: DeleteCustomTemplateAction) {
    const templateToBeDeleted = this.state.customEmailTemplates.find((t) => t.Key == action.templateKey);
    if (templateToBeDeleted == null) return;

    await this.emailTemplateRepo.deleteCustom(templateToBeDeleted);

    const idx = this.state.customEmailTemplates.indexOf(templateToBeDeleted);
    this.state.customEmailTemplates.splice(idx, 1);

    this.setState((s) => s);
  }

  @handle(UpdateEmailTemplateAction, FormErrorHandling)
  private async handleUpdateEmailTemplate(action: UpdateEmailTemplateAction) {
    action.form.validate();

    const updatedTemplate = await this.emailTemplateRepo.put(action.form.updatedModel());

    if (!updatedTemplate.IsCustomKey) {
      const templates = this.state.emailTemplates.slice();
      const currentTemplate = templates.find((e) => e.Key == updatedTemplate.Key);

      if (currentTemplate == null) return;

      templates[templates.indexOf(currentTemplate)] = updatedTemplate;

      this.setState((state) => ({
        ...state,
        emailTemplates: templates,
      }));
    } else {
      const templates = this.state.customEmailTemplates.slice();
      const currentTemplate = templates.find((e) => e.Key == updatedTemplate.Key);

      if (currentTemplate == null) {
        templates.push(updatedTemplate);
      } else {
        templates[templates.indexOf(currentTemplate)] = updatedTemplate;
      }

      this.setState((state) => ({
        ...state,
        customEmailTemplates: templates,
      }));
    }
  }

  @handle(SendEmailTemplateAction)
  private async handleSendEmailTemplateAction(action: SendEmailTemplateAction) {
    action.form.validate();

    await this.emailTemplateRepo.send(
      action.form.updatedModel(),
      null, // templateKey
      null, // seasonId
      action.emailTemplateType
    );
  }
}
