export enum PrincipalType {
    GlobalAdmin,
    GlobalSupport,
    OrganizationUser
}

export class Principal {
    Type: PrincipalType = null;
    Id: string = null;
    EmailAddress: string = null;
    FirstName: string = null;
    LastName: string = null;
}