import { ComponentEventBus, inject, needs, prop } from "fw";
import { RelationshipField } from "./relationship-field";
import { RelationshipPill } from "./relationship-pill";
import { IRelationship, Contact } from "models/contact";
import { IListResults } from "network/ats";
import { difference, throttle } from "lodash-es";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { ContactRepository } from "network/contact-repository";
import { ContactsService } from "service/contacts";
import { FeatureFlagService } from "service/feature-flag";

@inject
@needs(RelationshipField, RelationshipPill)
export class RelationshipSelector {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(null) public value!: IRelationship[];
  @prop(null) public excluded!: string[]; // Ids to excluded from search, this could be that they are already existing relationships/add only mode.
  @prop(null) public maxValues!: number | null;
  @prop(false) public primary!: boolean | null; // controls what side of the relationship to look at.
  @prop(false) public showRemove!: boolean;
  @prop(false) public isFilter!: boolean;

  public displaySearch: boolean = false;
  public filter: string = "";
  public searching: boolean = true;
  public searchResults: IListResults<Contact> = { results: [], total: 0 };
  private throttledSearch: Function = () => { };

  constructor(
    private contactRepo: ContactRepository,
    private ceb: ComponentEventBus,
    private contactsService: ContactsService,
    private ffs: FeatureFlagService,
  ) { }

  public async attached() {
    this.throttledSearch = throttle(() => this.loadSearch(), 250);
  }

  public get canAdd(): boolean {
    if (this.maxValues == null) {
      return true;
    }

    const current: number = (this.value && this.value.length) || 0
    return current < this.maxValues;
  }

  public add(contact: Contact) {
    const relationship = <IRelationship>{ id: contact.id, display_name: contact.display_name };
    try {
      if (this.value && this.value.indexOf(relationship) > -1) {
        this.closeSearch();
        return;
      }

      this.filter = "";
      this.ceb.dispatch("update:value", [...(this.value || []), relationship]);
    } catch (err) {
    }
  }

  public remove(value: IRelationship) {
    try {
      this.closeSearch();
      this.ceb.dispatch("update:value", difference(this.value, [value]));
    } catch (err) {
    }
  }

  public closeSearch() {
    this.displaySearch = false;
  }

  public searchFilterChanged() {
    if (this.filter.length >= 2) {
      this.displaySearch = true;
      this.throttledSearch();
    } else {
      this.searchResults = { results: [], total: 0 };
    }

    return true;
  }

  private valueChanged() {
    this.searchFilterChanged();
  }

  private async loadSearch() {
    try {
      this.searching = true;

      const contactType = this.primary === false ? this.field?.contact_type : this.field?.data?.contact_type;
      const ids: string[] = [...(this.excluded || []), ...(this.value || []).map(r => r.id) ];
      let f = ids?.length > 0 ? `(${ids.map(id => `-id:${id}`).join(" AND ")})` : null;
      
      this.searchResults = await this.contactRepo.list(this.filter, f, null, null, 1, 100, contactType);
    } catch (err) {
      this.searchResults = { results: [], total: 0 };
    }

    this.searching = false;
  }
}
