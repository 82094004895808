import { NetworkException } from "fw";
import { ValidationResult, IUsageProfileResult } from "network/ats";

import { get, toPath, take } from "lodash-es";

interface formLike {
  isInvalid: boolean;
  validationMessages: string[];
  validation: {
    [key: string]: string;
  };

  clearValidation();
  validate();
}

interface embeddedFormLike {
  form: formLike;
}

interface embeddedFormLikeArray {
  form: formLike[];
}

function isConflict(err): err is NetworkException<{ Message: string }> {
  return (
    err &&
    err.statusCode == 409 &&
    err.result &&
    (err.result.Code == "EMAILCONFLICT" || err.result.Code == "CONFLICT")
  );
}

export function isUsageConflict<T>(err): err is NetworkException<IUsageProfileResult<T>> {
  return (
    err &&
    err.statusCode == 409 &&
    err.result &&
    err.result.Code == "CONFLICT" &&
    err.result.Data && err.result.Data.UsageProfiles
  );
}

export const isValidationResult = (err): err is NetworkException<ValidationResult> => {
  return (
    err && err.statusCode == 400 && err.result && err.result.validation_result
  );
};

function isFormLike(obj): obj is formLike {
  return (
    obj &&
    typeof obj.validate == "function" &&
    typeof obj.clearValidation == "function"
  );
}

function isEmbeddedFormLike(obj): obj is embeddedFormLike {
  return obj && isFormLike(obj.form);
}

function isEmbeddedFormLikeArray(obj): obj is embeddedFormLikeArray {
  return obj && Array.isArray(obj.form) && obj.form.length > 0 && isFormLike(obj.form[0]);
}

const handlePathValidation = (v: { name: string; message: string }, form: formLike | formLike[]) => {
  const fullPathArray = toPath(v.name);
  const pathArray = take(fullPathArray, fullPathArray.length - 1);
  const lastPath = fullPathArray[fullPathArray.length - 1];
  const thing = <formLike>get(form, pathArray);

  if (thing != null && thing.validation != null) {
    thing.validation[lastPath] = v.message;
    thing.isInvalid = true;
  } else if (Array.isArray(thing)) {
    const arrayItemThing = <formLike>get(form, fullPathArray);

    if (arrayItemThing != null && arrayItemThing.validation != null) {
      arrayItemThing.validationMessages.push(v.message); // we will just push it into the validation messages for now.
      arrayItemThing.isInvalid = true;
    }
  }
}

function handleError(form: formLike, err: NetworkException<any>) {
  if (isConflict(err)) {
    form.isInvalid = true;
    form.validationMessages = [err.result.Message];
  } else if (isValidationResult(err)) {
    form.clearValidation();

    err.result.validation_result.forEach(v => {
      if ((v.name == null || v.name.length == 0) && v.type == "custom") {
        form.validationMessages.push(v.message);
        form.isInvalid = true;
      } else if (v.name.length > 0 && form.validation[v.name] == "") {
        form.validation[v.name] = v.message;
        form.isInvalid = true;
      } else if (form.validation[v.name] === undefined) {
        handlePathValidation(v, form);
      }
    });
  }
}

// if using a wrapped action (where a form is in there) as long as you name the property `form`
// this will work automatically!
export class FormErrorHandling {
  async handle(method: () => Promise<any>, form: formLike | embeddedFormLike | embeddedFormLikeArray) {
    try {
      //if (isFormLike(form)) {
      //  form.validate();
      //} else if (isEmbeddedFormLike(form)) {
      //  form.form.validate();
      //}

      await method();
    } catch (err) {
        handleFormErrorsFromNetworkException(form, err);
        throw err;
    }
  }
}

export function handleFormErrorsFromNetworkException(form: formLike | embeddedFormLike | embeddedFormLikeArray, err: unknown) {
    if (err instanceof NetworkException) {
        if (isFormLike(form)) {
            handleError(form, err);
        } else if (isEmbeddedFormLike(form)) {
            handleError(form.form, err);
        } else if (isEmbeddedFormLikeArray(form)) {
            for (const v of err.result.validation_result) {
                handlePathValidation(v, form.form);
            }
        }
    }
}