import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { ATS, IListResults } from "./ats";

import { CalendarEventSeries, CalendarEventSeriesInfo, CalendarEventSeriesStats } from "models/calendar-event";
import { GetImageUploadUrlResult, ImageInfo } from "models/image";

@inject
export class CalendarEventSeriesRepository {
  constructor(private network: ATS) { }

  async index() {
    const res = await this.network.contacts.get<CalendarEventSeries[]>("calendar-event-series");
    return createFromArray(CalendarEventSeries, res.body);
  }

  async list(
    q: string = null,
    f: string = null,
    aggs: string = null,
    sort: string = null,
    page: number = 1,
    limit: number = 10
  ): Promise<IListResults<CalendarEventSeries>> {
    return this.network.contacts.list("calendar-event-series", CalendarEventSeries, q, f, aggs, sort, page, limit);
  }

  async get(id: string) {
    const res = await this.network.contacts.get<CalendarEventSeries>(`calendar-event-series/${id}`);
    return createFrom(CalendarEventSeries, res.body);
  }

  async getInfo(
    seriesId: string,
    from: string | Date = null,
    to: string | Date = null,
    timezone: string = null
  ): Promise<CalendarEventSeriesInfo> {
    from = from instanceof Date ? from.toISOString() : from;
    to = to instanceof Date ? to.toISOString() : to;

    const params: any = {};
    if (from != null) params.from = from;
    if (to != null) params.to = to;
    if (timezone != null) params.timezone = timezone;

    const path = `calendar-event-series/${seriesId}/info`;
    const res = await this.network.contacts.get<CalendarEventSeriesInfo>(path, params);

    return createFrom(CalendarEventSeriesInfo, res.body);
  }

  async post(event: CalendarEventSeries) {
    const res = await this.network.contacts.post<CalendarEventSeries>("calendar-event-series", event);
    return createFrom(CalendarEventSeries, res.body);
  }

  async put(event: CalendarEventSeries) {
    const res = await this.network.contacts.put<CalendarEventSeries>(`calendar-event-series/${event.id}`, event);
    return createFrom(CalendarEventSeries, res.body);
  }

  async getImageUploadUrl(id: string, type: string, fileName: string) {
    const params: any = {};
    if (id == null) return;
    if (type == null) return;
    if (fileName == null) return;

    params.type = type;
    params.fileName = fileName;

    const res = await this.network.contacts.get<ImageInfo>(`calendar-event-series/${id}/image-upload-url`, params);
    return createFrom(GetImageUploadUrlResult, res.body);
  }

  async imageUploadComplete(id: string, imageId: string, type: string, fileName: string) {
    const params: any = {};
    if (id == null) return;
    if (type == null) return;
    if (fileName == null) return;

    params.type = type;
    params.fileName = fileName;

    const res = await this.network.contacts.put<ImageInfo>(`calendar-event-series/${id}/image-upload-complete/${imageId}`, null, params);
    return createFrom(ImageInfo, res.body);
  }

  async getImagePaths(id: string, type: string) {
    const params: any = {};
    if (type != null) params.type = type;

    const res = await this.network.contacts.get<ImageInfo[]>(`calendar-event-series/${id}/image-paths`, params);
    return createFromArray(ImageInfo, res.body);
  }

  async delete(seriesId: string) {
    const path = `calendar-event-series/${seriesId}`;
    await this.network.contacts.delete(path);
  }

  async getTags() {
    const res = await this.network.contacts.get<string[]>("calendar-event-series/tags");
    return res.body;
  }

  async getCounts(seriesId: string) {
    const res = await this.network.contacts.get<CalendarEventSeriesStats>(`calendar-event-series/${seriesId}/counts`);
    return createFrom(CalendarEventSeriesStats, res.body);
  }
}
