import { ApplicationRestrictionType } from "models/role";
import { AllRestrictions } from "./all-restrictions";
import { ApplicationPropertyRestrictions } from "./application-property-restrictions";
import { FormRestrictions } from "./form-restrictions";
import { ProgramStepRestrictions } from "./program-step-restrictions";

export const getDataRestrictionType = (type: ApplicationRestrictionType) => {
  switch (type) {
    case "ApplicationProperty":
      return "application-property-restrictions";

    case "FormStep":
    case "Evaluation":
    case "ReferenceForm":
      return "form-restrictions";

    case "DocumentStep":
    case "PortfolioStep":
      return "program-step-restrictions";

    default: return "all-restrictions";
  }
};

export const dataRestrictionComponents = [
  AllRestrictions,
  ApplicationPropertyRestrictions,
  FormRestrictions,
  ProgramStepRestrictions,
];
