import { fromClassArray, fromClass } from "fw-model";

import { FormAnswer, CalculatedFieldValue } from "./application";

export class InquiryResponseMetaData {
  FormId: string;
  FormKey: string;
  FormVersion: number;
  OrganizationId: string;
  ContactId: string;
  SeenQuestionKeys: string[];
}

export class InquiryResponse {
  Id: string;

  EmailAddress: string = null;
  FirstName: string = null;
  LastName: string = null;
  FullName: string = null;

  @fromClassArray(FormAnswer) Answers: FormAnswer[];
  @fromClassArray(CalculatedFieldValue) CalculatedValues: CalculatedFieldValue[];

  @fromClass MetaData: InquiryResponseMetaData;
}
