import { inject } from "fw";

import { CurrentOrganizationStore } from "state/current-organization";

@inject
export class AuthLogo {
  constructor(private orgStore: CurrentOrganizationStore) {}

  get isLoading() {
    return this.orgStore.state.isLoading;
  }

  get logoUrl() {
    return this.orgStore.state.portalContext?.OrganizationPortal.Content.FullLogoUrl ||
      this.orgStore.state.customization.authLogo;
  }

  get logoAlt() {
    return this.orgStore.state.portalContext?.OrganizationPortal.BrowserTitle ||
      "Liaison Outcomes";
  }
}
