import { inject, prop, ComponentEventBus, Navigator } from "@derekpitt/fw"

import { ChartingService } from "slideroom-charts";
import { type WidgetContext, GoalChartViewItem, GoalChartData } from "../../models";
import { fillColorPalette } from "../../util";

@inject
export class GoalsChart {
  @prop(null) data!: GoalChartData;
  @prop(false) interactive!: boolean;
  @prop(null) context!: WidgetContext;

  constructor(
    private charting: ChartingService,
    private ceb: ComponentEventBus,
    private nav: Navigator
    ) { }

  private getGoals() : GoalChartViewItem[] {
    return (this.context == null || this.data == null)
      ? []
      : this.data.Data;
  }

  private editGoal(goalId: string) {
    if (this.interactive) {
      this.nav.navigate(`/analytics/goals/${goalId}?context=dashboard`);
    }
  }

  private nextColor(id: string) {
    return fillColorPalette[this.getHashCode(id) % fillColorPalette.length];
  }

  private getHashCode(value: string) {
    let numbersOnlyValue = value.toString().replace(/a/g, "0");
    numbersOnlyValue = numbersOnlyValue.replace(/b/g, "1");
    numbersOnlyValue = numbersOnlyValue.replace(/c/g, "2");
    numbersOnlyValue = numbersOnlyValue.replace(/d/g, "3");
    numbersOnlyValue = numbersOnlyValue.replace(/e/g, "4");
    numbersOnlyValue = numbersOnlyValue.replace(/f/g, "5");

    return parseInt(numbersOnlyValue.substring(12));
  }
}
