import { inject, NetworkException } from "fw";
import { dispatch, handle, Store } from "fw-state";
import { cloneDeep, isEqual } from "lodash-es";

import {
  Conversation,
  ConversationFilters,
  ConversationNotificationCounts,
  ConversationStatus,
  ConversationSummaryCounts,
  ConversationTypeCounts,
  DeliveryAddress,
} from "models/conversation";
import { ConversationRepository } from "network/conversation-repository";
import { ConversationPermissionService } from "service/permissions/conversation";
import { FeatureFlagService } from "service/feature-flag";
import { LogoutAction, StartAction } from "./actions";
import { EnsureContactReferencesAction } from "./entity-reference";
import {
  EntityChanged, filterEntityChangedMessage, WebSocketMessageAction
} from './filter-websocket-message';

const DEFAULT_PAGE_SIZE = 20;

interface ConversationStoreShape {
  conversations: Conversation[];
  contactConversations: Conversation[];
  contactConversationsTotal: number;
  contactId: string;
  currentUserId: string;
  deliveryAddresses: DeliveryAddress[];
  errorLoading: boolean;
  errorMessage: string;
  filters: ConversationFilters;
  loaded: boolean;
  loading: boolean;
  notificationCounts: ConversationNotificationCounts;
  page: number;
  pageSize: number;
  selectedDeliveryAddress: DeliveryAddress;
  selectedIds: string[];
  showFilters: boolean;
  summaryCounts: ConversationSummaryCounts[];
  total: number;
}

export class EnsureConversationStoreAction { }
export class RefreshConversationStoreAction { }

export class LoadConversationsAction { }

export class LoadContactConversationsAction {
  constructor(public contactId: string) { }
}

export class ConversationNextPage { }
export class ConversationPreviousPage { }

export class ResetConversationFiltersAction { }

export class SetConversationFiltersAction {
  constructor(public filters: ConversationFilters) { }
}

export class SetConversationFilterByAssignedAction {
  constructor(public assignedToUserId: string) { }
}

export class SetConversationSortAction {
  constructor(public sort: string) { }
}

export class SetConversationStatusAction {
  constructor(public status: ConversationStatus) { }
}

export class SetConversationSearchAction {
  constructor(public search: string) { }
}

export class SetDeliveryAddressesAction {
  constructor(public deliveryAddresses: DeliveryAddress[]) { }
}

export class UpdateDeliveryAddressesAction {
  constructor(public updatedDeliveryAddress: DeliveryAddress) { }
}

export class SetSelectedDeliveryAddressAction {
  constructor(public selectedDeliveryAddress: DeliveryAddress) { }
}

export class SetConversationSelectIdsAction {
  constructor(public ids: string[]) { }
}

export class UpdateConversationStatusAction {
  constructor(public ids: string | string[], public status: ConversationStatus) { }
}

export class UpdateConversationAssignAction {
  constructor(public ids: string[], public userId: string, public comment?: string) { }
}

export class SetConversationNotificationCountsAction {
  constructor(public notificationCounts: ConversationNotificationCounts) { }
}

@inject
export class ConversationStore extends Store<ConversationStoreShape> {
  constructor(
    private conversationRepo: ConversationRepository,
    private conversationPermissionService: ConversationPermissionService,
    private ffs: FeatureFlagService,
  ) {
    super();
  }

  defaultState() {
    return {
      conversations: [],
      contactConversations: [],
      contactConversationsTotal: 0,
      contactId: null,
      currentUserId: null,
      deliveryAddresses: [],
      errorLoading: false,
      errorMessage: null,
      filters: {
        Type: null,
        Search: '',
        Sort: 'lastReplyReceivedAt DESC',
        ReceivedDateFrom: '',
        ReceivedDateTo: '',
        AssignedToUserId: '',
        Status: [],
      },
      loaded: false,
      loading: false,
      notificationCounts: null,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      selectAll: false,
      selectedDeliveryAddress: new DeliveryAddress(),
      selectedIds: [],
      showFilters: false,
      summaryCounts: null,
      total: 0,
    };
  }

  @handle(StartAction)
  private async handleStart(action: StartAction) {
    if (this.ffs.isFeatureFlagEnabled("SMSConversationsInbox") && this.conversationPermissionService.canAccess) {
      const currentUserId = action.context?.Me?.Id;
      const defaultState = this.defaultState();
      defaultState.currentUserId = currentUserId;
      if (this.conversationPermissionService.shouldLoadMyConversations) {
        defaultState.filters.AssignedToUserId = currentUserId;
      }
      this.setState(s => defaultState);

      try {
        const notificationCounts = await this.conversationRepo.getNotificationCounts();
        this.handleConversationNotificationCountsAction({ notificationCounts });
      } catch (ex) {
        let errorMessage = 'Error loading conversations.';
        if (ex instanceof NetworkException && ex.result?.errors?.length) {
          errorMessage = ex.result.errors.join(' ');
        }

        this.setState(state => ({
          ...state,
          errorLoading: true,
          errorMessage,
          loaded: true,
          loading: false,
        }));
      }
    }
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  private async loadConversations() {
    let errorMessage = null;

    if (!this.conversationPermissionService.canViewListing) {
      errorMessage = 'The current user does not have permission to view conversations.';
      this.setState(state => ({
        ...state,
        errorLoading: true,
        errorMessage,
        loaded: true,
        loading: false,
      }));

      return;
    }

    this.setState(state => ({
      ...state,
      loading: true,
    }));

    const { filters, page, pageSize } = this.state;
    const contactId = null;
    let conversations = {
      counts: new ConversationTypeCounts(),
      list: [],
      total: 0,
    };
    let summaryCounts: ConversationSummaryCounts[] = [];

    try {
      conversations = await this.conversationRepo.search(
        filters.Type,
        filters.Status,
        filters.AssignedToUserId,
        filters.ReceivedDateFrom,
        filters.ReceivedDateTo,
        filters.Search,
        filters.Sort,
        contactId,
        page,
        pageSize,
      );

      summaryCounts = [
        { Text: 'Open', Count: conversations.counts.OpenTotal, Filter: ConversationStatus.Open },
        { Text: 'Responded', Count: conversations.counts.RespondedTotal, Filter: ConversationStatus.Responded },
        { Text: 'Resolved', Count: conversations.counts.ResolvedTotal, Filter: ConversationStatus.Resolved }
      ];

      const contactIds: string[] = [];
      for (const conversation of conversations.list as Conversation[]) {
        contactIds.push(conversation.ContactId);
      }

      if (contactIds.length > 0) {
        dispatch(new EnsureContactReferencesAction(contactIds));
      }
    } catch (response) {
      errorMessage = 'Error loading conversations.';
      if (response?.result?.errors?.length) {
        errorMessage = response?.result?.errors?.join(' ');
      }

      this.setState(state => ({
        ...state,
        errorLoading: true,
        errorMessage,
        loaded: true,
        loading: false,
      }));

      return;
    }

    this.setState(state => ({
      ...state,
      conversations: conversations.list,
      total: conversations.total,
      loading: false,
      loaded: true,
      errorLoading: false,
      errorMessage,
      summaryCounts,
    }));
  }

  private async loadContactConversations() {
    let errorMessage = null;

    if (!this.conversationPermissionService.canViewListing) {
      errorMessage = 'The current user does not have permission to view conversations.';
      this.setState(state => ({
        ...state,
        errorLoading: true,
        errorMessage,
        loaded: true,
        loading: false,
      }));

      return;
    }

    this.setState(state => ({
      ...state,
      loading: true,
    }));

    const { contactId, pageSize } = this.state;
    const page = 1;
    const filters = {
      Type: null,
      Search: '',
      Sort: 'lastReplyReceivedAt DESC',
      ReceivedDateFrom: '',
      ReceivedDateTo: '',
      AssignedToUserId: '',
      Status: [],
    }
    let conversations = {
      list: [],
      total: 0,
    };

    try {
      conversations = await this.conversationRepo.search(
        filters.Type,
        filters.Status,
        filters.AssignedToUserId,
        filters.ReceivedDateFrom,
        filters.ReceivedDateTo,
        filters.Search,
        filters.Sort,
        contactId,
        page,
        pageSize,
      );
    } catch (response) {
      errorMessage = 'Error loading contact conversations.';
      if (response?.result?.errors?.length) {
        errorMessage = response?.result?.errors?.join(' ');
      }

      this.setState(state => ({
        ...state,
        errorLoading: true,
        errorMessage,
        loaded: true,
        loading: false,
      }));

      return;
    }

    this.setState(state => ({
      ...state,
      contactConversations: conversations.list,
      contactConversationsTotal: conversations.total,
      loading: false,
      loaded: true,
      errorLoading: false,
      errorMessage,
    }));
  }

  @handle(EnsureConversationStoreAction)
  private async ensureConversations(action: EnsureConversationStoreAction) {
    if (this.state.loading || this.state.loaded) return;
    await this.loadConversations();
  }

  @handle(RefreshConversationStoreAction)
  private async refreshConversations(action: RefreshConversationStoreAction) {
    if (this.state.loading) return;
    await this.loadConversations();
  }

  @handle(LoadConversationsAction)
  private async handleLoadConversationsAction(action: LoadConversationsAction) {
    if (this.state.loading || this.state.conversations.length) return;
    await this.loadConversations();
  }

  @handle(LoadContactConversationsAction)
  private async handleLoadContactConversationAction(action: LoadContactConversationsAction) {
    if (this.state.loading || (action.contactId && (this.state.contactId === action.contactId))) return;
    this.setState(state => ({
      ...state,
      contactId: action.contactId,
      contactConversations: [],
    }));

    await this.loadContactConversations();
  }

  @handle(ConversationNextPage)
  async handleConversationNextPage() {
    const page = this.state.page + 1;
    this.setState(state => ({
      ...state,
      page
    }));

    await this.loadConversations();
  }

  @handle(ConversationPreviousPage)
  async handleConversationPreviousPage() {
    let page = this.state.page - 1;
    if (page < 0) {
      page = 0;
    }

    this.setState(state => ({
      ...state,
      page
    }));

    await this.loadConversations();
  }

  @handle(SetDeliveryAddressesAction)
  private async handleSetDeliveryAddressesAction(action: SetDeliveryAddressesAction) {
    const { deliveryAddresses } = action;
    this.setState(state => ({
      ...state,
      deliveryAddresses,
    }));
  }

  @handle(UpdateDeliveryAddressesAction)
  private async handleUpdateDeliveryAddressesAction(action: UpdateDeliveryAddressesAction) {
    const { updatedDeliveryAddress } = action;
    const deliveryAddresses = this.state.deliveryAddresses.map((rec: DeliveryAddress) => (
      updatedDeliveryAddress.deliveryAddress === rec.deliveryAddress
        ? updatedDeliveryAddress
        : rec
    ));
    this.setState(state => ({
      ...state,
      deliveryAddresses,
    }));
  }

  @handle(SetSelectedDeliveryAddressAction)
  private async handleSetSelectedDeliveryAddressAction(action: SetSelectedDeliveryAddressAction) {
    const { selectedDeliveryAddress } = action;
    this.setState(state => ({
      ...state,
      selectedDeliveryAddress,
    }));
  }

  @handle(SetConversationSelectIdsAction)
  handleConversationIdsSelectedAction(action: SetConversationSelectIdsAction) {
    this.setState(state => ({
      ...state,
      selectedIds: action.ids,
    }));
  }

  @handle(ResetConversationFiltersAction)
  private async handleResetConversationFiltersAction(action: ResetConversationFiltersAction) {
    const { filters: defaultFilters } = this.defaultState();
    if (this.state.loading || isEqual(this.state.filters, defaultFilters)) return;

    this.setState(state => ({
      ...state,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      filters: defaultFilters,
    }));

    await this.loadConversations();
  }

  @handle(SetConversationFiltersAction)
  private async handleSetConversationFiltersAction(action: SetConversationFiltersAction) {
    if (this.state.loading || isEqual(this.state.filters, action.filters)) return;

    this.setState(state => ({
      ...state,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      filters: action.filters,
    }));

    await this.loadConversations();
  }

  @handle(SetConversationFilterByAssignedAction)
  async handleSetConversationFilterByAssignedAction(action: SetConversationFilterByAssignedAction) {
    const filters = cloneDeep(this.state.filters);
    const { assignedToUserId } = action;

    filters.AssignedToUserId = assignedToUserId;

    await this.handleSetConversationFiltersAction({ filters });
  }

  @handle(SetConversationSortAction)
  async handleSetConversationSortAction(action: SetConversationSortAction) {
    const filters = cloneDeep(this.state.filters);
    const { sort } = action;

    if (sort && `${sort} ASC` === filters.Sort) {
      filters.Sort = `${sort} DESC`;
    } else {
      filters.Sort = `${sort} ASC`;
    }

    await this.handleSetConversationFiltersAction({ filters });
  }

  @handle(SetConversationStatusAction)
  async handleSetConversationStatusAction(action: SetConversationStatusAction) {
    const filters = cloneDeep(this.state.filters);
    const { status } = action;

    filters.Status = status ? [status] : [];

    await this.handleSetConversationFiltersAction({ filters });
  }

  @handle(SetConversationSearchAction)
  async handleSetConversationSearchAction(action: SetConversationSearchAction) {
    const filters = cloneDeep(this.state.filters);
    const { search } = action;

    filters.Search = search;

    await this.handleSetConversationFiltersAction({ filters });
  }

  @handle(UpdateConversationAssignAction)
  private async handleUpdateConversationAssignAction(action: UpdateConversationAssignAction) {
    if (this.conversationPermissionService.canGenerallyUpdate) {
      const { ids, userId, comment = '' } = action;
      await this.conversationRepo.assign(ids, userId, comment);
    } else {
      throw {
        result: {
          errors: ['User does not have required permissions.'],
        }
      }
    }
  }

  @handle(UpdateConversationStatusAction)
  private async handleUpdateConversationStatusAction(action: UpdateConversationStatusAction) {
    if (this.conversationPermissionService.canGenerallyUpdate) {
      const { ids, status } = action;
      await this.conversationRepo.setStatus(ids, status);

      const conversations = this.state.conversations.map((conversation: Conversation) =>
        ids.includes(conversation?.Id)
          ? new Conversation({ ...conversation, Status: status })
          : conversation
      );

      this.setState(state => ({
        ...state,
        conversations,
      }));
    } else {
      throw {
        result: {
          errors: ['User does not have required permissions.'],
        }
      }
    }
  }

  @handle(SetConversationNotificationCountsAction)
  public handleConversationNotificationCountsAction(action: SetConversationNotificationCountsAction) {
    this.setState(state => ({
      ...state,
      notificationCounts: action.notificationCounts,
    }));
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("ConversationChangedEvent"))
  private async handleWebSocketMessageAction(action: WebSocketMessageAction<EntityChanged>) {
    if (this.ffs.isFeatureFlagEnabled("SMSConversationsInbox") && this.conversationPermissionService.canAccess) {
      this.refreshConversations(null);

      const data = action?.data?.data;
      if (
        ('assignment' === data?.change)
        || (
          'status' === data?.change
          && data.conversation.AssignedToUserId === this.state.currentUserId
        )
      ) {
        const notificationCounts = await this.conversationRepo.getNotificationCounts();
        this.handleConversationNotificationCountsAction({ notificationCounts })
      }
    }
  }
}
