import { FormForType, formFor } from "fw-model";

import { ContactSegment } from "models/contact-segment";

export type FormForContactSegment = FormForType<ContactSegment>;

export class UpdateContactSegmentAction {
  constructor(public form: FormForContactSegment) {}
}

export class CreateContactSegmentAction {
  public createdId: string = null;

  constructor(public form: FormForContactSegment) {}
}

export const contactSegmentFormCreator = formFor(ContactSegment, s => {
  s.requiredField(cs => cs.display_name, "Name");
  s.requiredField(cs => cs.contact_type, "Contact Type");
  s.requiredField(cs => cs.filter, "Filter (Temp)");
  s.field(cs => cs.key, "Key");
  s.field(cs => cs.category, "Category");
  s.field(cs => cs.client_data, "Client Data");
});
