import { inject } from "fw";
import { PopoverService } from "service/popover";
import { CurrentUserStore } from "state/current-user";

import { UserPopover } from "views/header/user-popover";

@inject
export class UserNavLink {
  constructor(
    private popover: PopoverService,
    public currentUser: CurrentUserStore,
  ) { }

  public popUserMenu() {
    this.popover.open(UserPopover);
  }
}
