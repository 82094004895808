import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { ATS, IListResults } from "./ats";
import { Operation } from "fast-json-patch";

import {
  CalendarEvent,
  UpdateCalendarEventModel,
  getSeriesAndInstanceFromId,
} from "models/calendar-event";
import { ImageInfo } from "models/image";

export interface CalendarEventSelection {
  calendar_event_series_id: string;
  instance_key: string;
  calendar_event_type_id: string;
}

@inject
export class CalendarEventRepository {
  constructor(private network: ATS) { }

  async list(
    from: string | Date = null,
    to: string | Date = null,
    timezone: string = null,
    f: string = null,
    q: string = null,
    sort: string = null,
    page: number = 1,
    limit: number = 10
  ): Promise<IListResults<CalendarEvent>> {
    from = from instanceof Date ? from.toISOString() : from;
    to = to instanceof Date ? to.toISOString() : to;
    const params: any = {
      from,
      to,
      timezone,
      page,
      limit: limit == null ? 10 : limit
    };

    if (sort != null) params.sort = sort;
    if (f != null) params.f = f;
    if (q != null) params.q = q;

    const res = await this.network.contacts.get<IListResults<CalendarEvent>>("calendar-events", params);

    const results: IListResults<CalendarEvent> = {
      aggregations: res.body.aggregations,
      results: createFromArray(CalendarEvent, res.body.results),
      total: parseInt(res.headers["x-result-count"], 10)
    };
    return results;
  }

  async getById(calendarEventId: string): Promise<CalendarEvent> {
    const { calendarEventSeriesId, instanceKey } = getSeriesAndInstanceFromId(calendarEventId);
    return await this.get(calendarEventSeriesId, instanceKey);
  }

  async get(seriesId: string, instanceKey: string): Promise<CalendarEvent> {
    const path = `calendar-events/${seriesId}/${instanceKey}`;
    const res = await this.network.contacts.get<CalendarEvent>(path);
    return createFrom(CalendarEvent, res.body);
  }

  async listByIds(ids: string[], organizationId: string): Promise<CalendarEvent[]> {
    const res = await this.network.contacts.post<CalendarEvent[]>("calendar-events/list", ids, {
      organizationId: organizationId
    });

    return createFromArray(CalendarEvent, res.body);
  }

  async put(event: UpdateCalendarEventModel): Promise<CalendarEvent> {
    const path = `calendar-events/${event.calendar_event_series_id}/${event.instance_key}`;
    const res = await this.network.contacts.put<CalendarEvent>(path, event);
    return createFrom(CalendarEvent, res.body);
  }

  async patch(
    event: UpdateCalendarEventModel,
    data: Operation[],
    doUpdateAllInSeries: boolean = false,  // if false, will only update current instance forward
  ): Promise<CalendarEvent> {
    const path = `calendar-events/${event?.calendar_event_series_id}/${event?.instance_key}/update-series-occurrences`;
    try {
      const res = await this.network.contacts.post<CalendarEvent>(path, data, { all: doUpdateAllInSeries });
      if (!res?.body?.id) {
        throw new Error('Error while patching event.');
      }
      return createFrom(CalendarEvent, res.body);
    } catch (err) {
      console.log("Error patching:", err);
    }
  }

  /**
   * @param {boolean} [all=null] - determines what instances in the series to update:
   *   - null: single (current instanceId)
   *   - false: future + current instanceId
   *   - true: all instances in series
   */
  async imageUploadComplete(seriesId: string, instanceId: string, imageId: string, type: string, fileName: string, all: boolean = null) {
    const params: any = {};
    if (seriesId == null) return;
    if (instanceId == null) return;
    if (type == null) return;
    if (fileName == null) return;

    params.type = type;
    params.fileName = fileName;
    params.all = all;

    const res = await this.network.contacts.put<ImageInfo>(`calendar-events/${seriesId}/${instanceId}/image-upload-complete/${imageId}`, null, params);
    return createFrom(ImageInfo, res.body);
  }

  async delete(seriesId: string, instanceKey: string) {
    const path = `calendar-events/${seriesId}/${instanceKey}`;
    await this.network.contacts.delete(path);
  }

  public async deleteSelection(selections: CalendarEventSelection[]) {
    if (selections == null || selections.length === 0) {
      return;
    }
    await this.network.contacts.post<void>("calendar-events/delete", selections);
  }

  public async getEventDatesList(
    from: string | Date = null,
    to: string | Date = null,
    timezone: string = null
  ) {
    from = from instanceof Date ? from.toISOString() : from;
    to = to instanceof Date ? to.toISOString() : to;

    const params: any = {};
    if (from != null) params.from = from;
    if (to != null) params.to = to;
    if (timezone != null) params.timezone = timezone;

    const res = await this.network.contacts.get<string[]>("calendar-events/calendar-dates", params);
    return res.body;
  }

  async getTags() {
    const res = await this.network.contacts.get<string[]>("calendar-events/tags");
    return res.body;
  }

  public async getClientModel(seriesId: string, instanceKey: string) {
    const path = `calendar-events/client-model/${seriesId}/${instanceKey}`;
    const res = await this.network.contacts.get<any>(path);
    return res.body;
  }
}
