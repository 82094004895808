import { inject, prop, ComponentEventBus } from "@derekpitt/fw"

import { Question, FormAnswer } from "../../models";
import { QuestionType } from "../../enums";

@inject
export class ShortTextType {
  @prop(null) question!: Question;
  @prop(null) answer!: FormAnswer;
  @prop(false) readonly!: boolean;
  @prop(false) displayonly!: boolean;
  @prop(false) compact!: boolean;
  @prop(null) ariaLabelledby;

  @prop(null) requestCeeb;
  @prop(null) fileHash;
  @prop(null) fileService;
  @prop(null) showOptions;

  constructor(private ecb: ComponentEventBus) {}

  get maxLength() {
    if (this.question == null) return 80;

    switch (this.question.Type) {
      case QuestionType.ShortText:
        return this.question.Options.MaxLength || 80;

      case QuestionType.Date:
        return 11;

      case QuestionType.URL:
        return 1024;
    }
  }

  get placeholderText() {
    if (this.question == null) return null;

    if (this.question.Type == QuestionType.Date) {
      return "mm/dd/yyyy";
    } else {
      return null;
    }
  }

  onClick(event) {
    const handleBlur = () => this.onBlur(
      () => event.target.removeEventListener("blur", handleBlur)
    );
    event.target.addEventListener("blur", handleBlur);
  }

  onBlur(cb)  {
    this.answer.Text = this.answer.Text?.trim();
    this.change();
    cb
  }

  change() {
    // clear out the date so the api doesn't get confused as to what it should take
    if (this.question.Type == QuestionType.Date) {
      this.answer.Date = null;
    }

    this.ecb.dispatch("answer-changed");
  }
}
