import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";
import { MemoryCache, cacheFor } from "caching";

import {
  SearchList,
  Application,
  ApplicationCopyResult,
  ApplicationAttachment,
  ApplicationStep,
  ClientModelSearchResult,
  ApplicationComment,
  ContractionInfo,
  ApplicationStepGroup,
} from "models/application";
import { ApplicationClientModel, EntitySelection } from "models/application-client-model";
import { EmailTemplate, EmailTemplatePreview } from "models/email-template";
import { ApplicationEvaluation } from "models/application-evaluation";

import { ATS } from "./ats";
import { SuccessResult } from "models/success-result";
import { encodeEmailTemplate } from "./encode";
import { TaskRequest } from "models/task-request";
import { parseHeader, TASK_HEADER_NAME } from "./middleware/task-request-middleware";
import { PaymentGateway } from "models/organization";

export type ApplicationCopyArgs = {
  TargetProgramId: string;
  TargetProgramStageId: string;
  MaterialTypesToCopy: string[];
  DryRun: boolean;
};

const MAX_RECIPIENTS = 50;
const SEARCH_FIELDS = "Results(id,applicant(familyName,givenName,profileImageUrl),program(publicName))";

@inject
export class ApplicationRepository {
  constructor(private s: ATS, private cache: MemoryCache) {}

  public async search(
    f: string,
    sort: string = null,
    previousPageToken: string = null,
    nextPageToken: string = null,
    programId: string = null,
    aggs: string = null,
    countsOnly: boolean = null,
    useAsyncQuery: boolean = false,
    asyncQueryId: string = null,
    pageSize: number = null
  ) {
    const res = await this.s.get<SearchList>("application/search", {
      f,
      sort,
      previousPageToken,
      nextPageToken,
      programId,
      aggs,
      countsOnly,
      useAsyncQuery,
      asyncQueryId,
      pageSize,
    });
    return createFrom(SearchList, res.body);
  }

  public async impersonatedCount(f: string, userId: string) {
    const res = await this.s.get<SearchList>("application/impersonated-count", {
      f,
      userId,
    });
    return createFrom(SearchList, res.body);
  }

  public cancelSearch(asyncQueryId: string) {
    return this.s.delete<void>(`application/search/${asyncQueryId}`);
  }

  public async clientModelSearch(
    f: string,
    sort: string = null,
    previousPageToken: string = null,
    nextPageToken: string = null,
    aggs: string = null,
    fields: string = null,
    pageSize: number = 200,
    useAsyncQuery: boolean = false,
    asyncQueryId: string = null
  ) {
    const res = await this.s.get<ClientModelSearchResult>("application/client-model/search", {
      f,
      sort,
      previousPageToken,
      nextPageToken,
      aggs,
      fields,
      pageSize,
      useAsyncQuery,
      asyncQueryId,
    });
    return createFrom(ClientModelSearchResult, res.body);
  }

  public async recipientsList(selection: EntitySelection) {
    const filters: string[] = [];
    const { ids, filter, excludedIds } = selection;

    if (ids?.length) {
      filters.push(`id:${ids.join(" OR id:")}`);
    } else {
      if (filter) filters.push(filter);
      if (excludedIds.length) filters.push(`-(id:${excludedIds.join(" OR id:")})`);
    }

    return this.clientModelSearch(
      filters.join(" AND "),
      null,
      null,
      null,
      null,
      SEARCH_FIELDS,
      MAX_RECIPIENTS
    );
  }

  async clientModel(id: string, useCache = true) {
    if (useCache) {
      const fromCache = this.cache.get<ApplicationClientModel>(`client-model:${id}`);
      if (fromCache != null) return fromCache;
    }

    const res = await this.s.get<ApplicationClientModel>(`application/${id}/client-model`);

    if (useCache) this.cache.set(`client-model:${id}`, res.body, cacheFor(5).minutes);

    return res.body;
  }

  async delete(id: string) {
    await this.s.delete(`application/${id}`);
  }

  public async indexFields(paths: string[]): Promise<string> {
    const res = await this.s.put<void>("application/index-fields", { paths });
    if (res.headers[TASK_HEADER_NAME] == null) {
      return;
    }

    const data = parseHeader(res.headers[TASK_HEADER_NAME]);
    return data.taskRequestId;
  }

  public async reindex(
    organizationId: string,
    deleteApplications: boolean,
    filter: string,
    contractIndexedFields: boolean = false
  ): Promise<void> {
    await this.s.get("application/reindex", {
      organizationId,
      delete: deleteApplications,
      f: filter,
      contractIndexedFields: contractIndexedFields,
    });
  }

  public async checkContractionInfo(organizationId: string): Promise<ContractionInfo> {
    let response = await this.s.get<ContractionInfo>("application/check-contraction-info", {
      organizationId,
    });
    return createFrom(ContractionInfo, response.body);
  }

  public async reindexApplications(
    applicationIds: string[],
    deleteApplications: boolean = false
  ): Promise<SuccessResult> {
    var response = await this.s.get<SuccessResult>(`application/${applicationIds.join(",")}/reindex`, {
      delete: deleteApplications,
    });
    return createFrom(SuccessResult, response.body);
  }

  public async email(selection: EntitySelection, emailTemplate: EmailTemplate): Promise<TaskRequest> {
    const encoded = encodeEmailTemplate(emailTemplate);
    const res = await this.s.post("application/email", { Selection: selection, Template: encoded });
    return createFrom(TaskRequest, res.body);
  }

  public async putStage(selection: EntitySelection, stageId: string, programId: string): Promise<TaskRequest> {
    const res = await this.s.put<any[]>("application/stage", selection, { stageId, programId });
    return createFrom(TaskRequest, res.body);
  }

  public async putPhase(selection: EntitySelection, phaseId: string): Promise<TaskRequest> {
    const res = await this.s.put<any[]>(`application/phase`, selection, { phaseId });
    return createFrom(TaskRequest, res.body);
  }

  public async putDecision(selection: EntitySelection, decisionId: string): Promise<TaskRequest> {
    const res = await this.s.put<any[]>(`application/decision`, selection, { decisionId });
    return createFrom(TaskRequest, res.body);
  }

  async postEvaluation(
    applicationId: string,
    phaseId: string,
    evaluation: ApplicationEvaluation,
    seenQuestionKeys: string[]
  ) {
    const res = await this.s.post(`application/${applicationId}/evaluation`, evaluation, {
      phaseId,
      seenQuestionKeys,
    });
    return createFrom(ApplicationEvaluation, res.body);
  }

  async putEvaluation(applicationId: string, evaluation: ApplicationEvaluation, seenQuestionKeys: string[]) {
    const res = await this.s.put(`application/${applicationId}/evaluation/${evaluation.Id}`, evaluation, {
      seenQuestionKeys,
    });
    return createFrom(ApplicationEvaluation, res.body);
  }

  async postAttachment(applicationId: string, attachment: ApplicationAttachment) {
    const res = await this.s.post(`application/${applicationId}/attachment`, attachment);
    return createFrom(ApplicationAttachment, res.body);
  }

  async deleteAttachments(applicationId: string, attachmentIds: string[]) {
    await this.s.delete(`application/${applicationId}/attachment/${attachmentIds.join(",")}`);
  }

  async getComments(applicationId: string, f: string = null, page: number = null, pageSize: number = null) {
    const res = await this.s.get<any[]>(`application/${applicationId}/comments`, { f, page, pageSize });

    return {
      comments: createFromArray(ApplicationComment, res.body),
      total: parseInt(res.headers["x-ats-total"], 10),
    };
  }

  async addComment(applicationId: string, comment: string) {
    const res = await this.s.post(`application/${applicationId}/comment`, comment);
    return createFrom(ApplicationComment, res.body);
  }

  async updateComment(applicationId: string, commentId: string, comment: string) {
    const res = await this.s.put(`application/${applicationId}/comment/${commentId}`, comment);
    return createFrom(ApplicationComment, res.body);
  }

  async deleteComment(applicationId: string, commentId: string) {
    const res = await this.s.delete(`application/${applicationId}/comment/${commentId}`);
    return createFrom(ApplicationComment, res.body);
  }

  public async putPropertyValues(
    selection: EntitySelection,
    values: { [key: string]: any }
  ): Promise<TaskRequest> {
    const res = await this.s.put(`application/property-values`, [
      {
        selection,
        propertyValues: values,
      },
    ]);

    return createFrom(TaskRequest, res.body);
  }

  async putStep(
    applicationId: string,
    stepGroupId: string,
    stepId: string,
    step: ApplicationStep,
    documentFileIds: string[] = null,
    seenQuestionKeys: string[] = null
  ) {
    const res = await this.s.put(`application/${applicationId}/group/${stepGroupId}/step/${stepId}`, step, {
      documentFileIds,
      seenQuestionKeys,
    });
    return createFrom(ApplicationStep, res.body);
  }

  async unlock(applicationId: string, stepGroupId: string, stepId: string) {
    await this.s.post(`application/${applicationId}/group/${stepGroupId}/step/${stepId}/unlock`, null);
  }

  async waiveFees(applicationId: string, instructions: { [stepGroupId: string]: boolean }) {
    await this.s.post(`application/${applicationId}/waive-fees`, instructions);
  }

  async waiveDeadlines(applicationId: string, instructions: { [stepGroupId: string]: boolean }) {
    await this.s.post(`application/${applicationId}/waive-deadlines`, instructions);
  }

  public async tag(
    selection: EntitySelection,
    addTags: string[],
    removeTags: string[]
  ): Promise<TaskRequest> {
    const res = await this.s.post("application/tag", {
      Selection: selection,
      AddTags: addTags,
      RemoveTags: removeTags,
    });

    return createFrom(TaskRequest, res.body);
  }

  async copy(applicationId: string, args: ApplicationCopyArgs) {
    const res = await this.s.post(`application/${applicationId}/copy`, args);
    return createFrom(ApplicationCopyResult, res.body);
  }

  async post(params: { programId: string; applicantId: string; stageId: string }) {
    const res = await this.s.post("application", null, params);
    return createFrom(Application, res.body);
  }

  public async temp_submitGroupForTouchnetTest(
    applicationId: string,
    groupId: string,
    paymentGatewayId?: string,
    paymentToken?: string
  ) {
    const res = await this.s.post(`application/${applicationId}/group/${groupId}`, null, {
      paymentGatewayId,
      paymentToken,
    });
    return createFrom(ApplicationStepGroup, res);
  }

  public async generateTouchLinkSecureLinkTicket(
    gateway: PaymentGateway,
    primaryTransactionId: string,
    secondaryTransactionId: string,
    chargeAmount: number,
    paymentArgs: TouchNetApplicationPaymentArgs
  ) {
    console.log("Sending info to get secure link ticket");

    var args: TouchNetGenerateSecureLinkTicketArgs = new TouchNetGenerateSecureLinkTicketArgs();
    args.gatewayId = gateway.Id;
    args.chargeAmount = chargeAmount;
    args.primaryTransactionId = primaryTransactionId;
    args.secondaryTransactionId = secondaryTransactionId;
    args.paymentArgs = paymentArgs;

    const res = await this.s.post(
      `touchnet/initiateTransactionSession`,
      args
    );

    return res;
  }
}

export class TouchNetGenerateSecureLinkTicketArgs {
  gatewayId: string = null;
  primaryTransactionId: string = null;
  secondaryTransactionId: string = null;
  chargeAmount: number = null;
  paymentArgs: TouchNetApplicationPaymentArgs = null;
}

export class TouchNetApplicationPaymentArgs {
  CancelUrl: string = null;
  PortalId: string = null;
  PortalType: string = null;
  ApplicationId: string = null;
  ApplicationStepGroupId: string = null;
  EmailAddress: string = null;
  GivenName: string = null;
  FamilyName: string = null;
}
