import { inject } from "fw";

import { ATS } from "./ats";

import { ServerDecisionLetterTemplateSet } from "models/decision-letter-template-set"
import { createFrom } from "fw-model";
import { ClientModelSearchResult } from "models/application";
import { TaskRequest } from "models/task-request";

@inject
export class DecisionLetterTemplateSetRepository {
  constructor(private s: ATS) {}

  async post(data: ServerDecisionLetterTemplateSet) {
    const res = await this.s.post<ServerDecisionLetterTemplateSet>("decision-letter-template-set", data);

    return res.body;
  }

  async get(
    ids: string[],
    filter: string,
    search: string,
    sort: string = null,
    previousPageToken: string = null,
    nextPageToken: string = null,
    pageSize: number = 20
  ) {
    const res = await this.s.get<ClientModelSearchResult>("decision-letter-template-set", {
      ids: ids?.join(),
      filter,
      search,
      sort,
      previousPageToken,
      nextPageToken,
      pageSize,
      excludePaths: "templates.*.template"
    });

    return createFrom(ClientModelSearchResult, res.body);
  }

  async generatePdf(id, applicationIds) {
    const res = await this.s.post<any>(`decision-letter/generate/${id}`, applicationIds);

    return createFrom(TaskRequest, res);
  }

  async deleteDecisionLetter(applicationId: string) {
    const res = await this.s.delete<any>(`decision-letter/application/${applicationId}`);

    return res.body;
  }

  async getById(id) {
    const res = await this.s.get<ServerDecisionLetterTemplateSet>(`decision-letter-template-set/${id}`);

    return res.body;
  }

  async delete(ids) {
    const res = await this.s.post<ServerDecisionLetterTemplateSet>("decision-letter-template-set/delete", ids);

    return res.body;
  }

  async put(data: ServerDecisionLetterTemplateSet): Promise<ServerDecisionLetterTemplateSet> {
    const res = await this.s.put<ServerDecisionLetterTemplateSet>(`decision-letter-template-set/${data.Id}`, data);
    return res.body;
  }
}
