import { inject } from "fw";
import { LocalStorageCache } from "caching";

import { CurrentOrganizationStore } from "state/current-organization";
import { wait } from "wait";

const keyMap = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
const genStateKey = () => {
  let k = "";

  for (let i = 0; i < 7; i++) {
    k += keyMap[Math.floor(Math.random() * keyMap.length)];
  }

  return k;
};

type OAuthResult = {
  code: string;
  state: string;
  error: string;
  error_description: string;
  error_uri: string;
};

type OAuthCache = {
  redirectHash: string;
};

type KeyValues = { [key: string]: string | boolean };

export const providerKeyFromDatasourceKey = (key: string) => {
  switch (key) {
    case "googlesheets":
    case "googledrive":
    case "googleforms":
    case "googleclassroom":
    case "gmail":
      return "google";

    case "constantcontact":
    case "hubspot":
    case "mailchimp":
    case "slideroom":
    case "stripe":
    case "zendesk":
      return key;
  }

  return null;
};

export const parseQueryString = (keyValues: string) => {
  const obj: KeyValues = {};
  const parts = (keyValues || '').split('&');

  parts.filter(k => k).forEach(keyValue => {
    const value = keyValue.split('=');
    const key = decodeURIComponent(value[0]);
    obj[key] = typeof value[1] !== 'undefined' ? decodeURIComponent(value[1]) : true;
  });

  return obj;
}

export interface IPopupOptions {
  width: number,
  height: number
}

const popAndWait = async (url: string, options?: IPopupOptions) => {
  const width: number = options && options.width || 500;
  const height: number = options && options.height || 500;

  const fullOptions = {
    ...options,
    width: width,
    height: height,
    left: window.screenX + ((window.outerWidth - width) / 2),
    top: window.screenY + ((window.outerHeight - height) / 2.5)
  };

  const parts = [];
  for (const key in fullOptions) {
    parts.push(key + '=' + fullOptions[key]);
  }

  const popupWindow = window.open(url, "Auth", parts.join(","));
  if (popupWindow && popupWindow.focus) popupWindow.focus();

  const documentOrigin = document.location.host;

  while (true) {
    await wait(35);

    try {
      const popupWindowOrigin = popupWindow.location.host;
      if (popupWindowOrigin == documentOrigin && (popupWindow.location.search || popupWindow.location.hash)) {
        const queryParams = popupWindow.location.search.substring(1).replace(/\/$/, '');
        const hashParams = popupWindow.location.hash.substring(1).replace(/[\/$]/, '');
        const hash = parseQueryString(hashParams);
        const qs = parseQueryString(queryParams);

        Object.assign(qs, hash);

        if (qs.not_done) continue;

        if (qs.error) throw { error: qs.error };

        popupWindow.close();
        return qs;
      }
    } catch(err) {}

    if (!popupWindow) {
      throw { error: 'Provider Popup Blocked' };
    } else if (popupWindow.closed) {
      throw { error: 'Problem Poll popup' };
    }
  }
};

@inject
export class OAuth {
  constructor(
    private cache: LocalStorageCache,
    private orgStore: CurrentOrganizationStore,
  ) {}

  get relayUrl() {
    return `https://${this.orgStore.state.portalContext.OAuthRelayDomain}/auth/oauth/connect`;
  }

  get responseUrl() {
    return `https://${this.orgStore.state.portalContext.OAuthRelayDomain}/auth/oauth/connect/response`;
  }

  async auth(provider: string, scope: string, params: KeyValues = {}, popupOptions?: IPopupOptions) {
    if (provider == null) throw new Error("Need a provider");

    const stateKey = genStateKey();

    const redirectHash = window.location.hash;
    this.cache.set(`oauth:${stateKey}`, <OAuthCache>{
      redirectHash,
    });
    const oauthPage =`${document.location.origin}/client/oauth/oauth.html`;

    let allParams: { [key: string]: string } = {
      ...params,

      provider,
      //state: stateKey,
      returnTo: oauthPage,
    };

    if (scope) {
      allParams = { ...allParams, scope };
    }

    const ps = Object.keys(allParams).map(key => `${key}=${encodeURIComponent(allParams[key])}`);
    const relay = `${this.relayUrl}?${ps.join("&")}`;
    const popUrl = `${oauthPage}?not_done&redirect=${encodeURIComponent(relay)}`;
    return await popAndWait(popUrl, popupOptions);
  }

  processRedirect(uri: string) {
    const str = uri.replace(/^#oauth&/, "");
    const split = str.split("&");

    const fromSource: OAuthResult = {
      code: "",
      state: "",
      error: "",
      error_description: "",
      error_uri: "",
    };

    for (const s of split) {
      const [ key, value ] = s.split("=");
      fromSource[decodeURIComponent(key)] = decodeURIComponent(value);
    }

    const cache = this.cache.get<OAuthCache>(`oauth:${fromSource.state}`);
    if (!cache) {
      return;
    }

    window.location.hash = cache.redirectHash;
  }
}
