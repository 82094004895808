import { Subscription } from "fw";

export enum CustomEvent {
  NextSection = 1,
  PreviousSection = 2,
  NextApplicant = 3,
  PreviousApplicant = 4,
}

const keyboardMappings = [
  { CustomEvent: CustomEvent.NextSection, KeyboardShortcut: "shift-down" },
  { CustomEvent: CustomEvent.PreviousSection, KeyboardShortcut: "shift-up" },

  { CustomEvent: CustomEvent.NextApplicant, KeyboardShortcut: "shift-right" },
  { CustomEvent: CustomEvent.PreviousApplicant, KeyboardShortcut: "shift-left" },
];

interface NormalizedKeyPress {
  Shift: boolean;
  Up: boolean;
  Down: boolean;
  Left: boolean;
  Right: boolean;
  Space: boolean;

  // todo..  fill out more
}

const normalizeKeyboardPressEvent = (e: KeyboardEvent): NormalizedKeyPress => {
  return {
    Shift: e.shiftKey,
    Down: e.keyCode == 40,
    Up: e.keyCode == 38,
    Right: e.keyCode == 39,
    Left: e.keyCode == 37,
    Space: e.keyCode == 32,
  };
};

export const preventLeadingSpaces = (event: KeyboardEvent, value: string) => {
  const incoming = normalizeKeyboardPressEvent(event);
  if (incoming.Space && (!value || value.length === 0)) {
    event.preventDefault();
    return;
  }
};

const makeEventFromShortcutString = (str: string): NormalizedKeyPress => {
  const split = str.split("-").map(s => s.trim().toLowerCase());
  const ev: NormalizedKeyPress = {
    Down: false,
    Shift: false,
    Up: false,
    Left: false,
    Right: false,
    Space: false,
  };

  for (const key of split) {
    switch (key) {
      case "shift": ev.Shift = true; break;
      case "up": ev.Up = true; break;
      case "down": ev.Down = true; break;
      case "left": ev.Left = true; break;
      case "right": ev.Right = true; break;
      case "space": ev.Space = true; break;
    }
  }

  return ev;
};

const matches = (ke1: NormalizedKeyPress, ke2: NormalizedKeyPress): boolean => {
  for (const key of Object.keys(ke1)) {
    if (ke1[key] != ke2[key]) return false;
  }

  return true;
};


export class KeyboardPress {
  private subs: { [key: number]: Array<() => void> } = {};

  constructor() {
    window.document.addEventListener("keydown", e => {
      const incoming = normalizeKeyboardPressEvent(e);
      let shouldPreventDefault = false;

      for (const mapping of keyboardMappings) {
        if (this.subs[mapping.CustomEvent] == null || this.subs[mapping.CustomEvent].length == 0) continue;

        const mapped = makeEventFromShortcutString(mapping.KeyboardShortcut);
        if (matches(incoming, mapped)) {
          this.subs[mapping.CustomEvent].forEach(sub => sub());
          shouldPreventDefault = true;
        }
      }

      if (shouldPreventDefault)
        e.preventDefault();
    });
  }

  subscribe(customEvent: CustomEvent, cb: () => void): Subscription {
    if (this.subs[customEvent] == null) {
      this.subs[customEvent] = [];
    }

    this.subs[customEvent].push(cb);

    return {
      dispose: () => {
        const idx = this.subs[customEvent].indexOf(cb);
        if (idx >= 0) this.subs[customEvent].splice(idx, 1);
      },
    };
  }
}
