const toArray = (value: string | string[]): string[] => {
  if (value == null) return [];
  if (Array.isArray(value)) return value;
  return value.split(",").map(a => a.trim());
};

export const allOf = (list: string | string[], valuesToCheck: string | string[]) => {
  const allList = toArray(list);
  const allValues = toArray(valuesToCheck);

  return allValues.every(a => allList.some(cp => cp == a));
}

export const anyOf = (list: string | string[], valuesToCheck: string | string[]) => {
  const allList = toArray(list);
  const allValues = toArray(valuesToCheck);

  return allValues.some(a => allList.some(cp => cp == a));
}
