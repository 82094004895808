import { inject } from "fw";

import { Permissions } from "service/permission";
import { UserPermissionService } from "./user";

@inject
export class MarketingPermissionService {
  constructor(
    private permissions: Permissions,
    private userPermissionService: UserPermissionService,
  ) { }

  public get isGlobalUser(): boolean {
    return this.userPermissionService.isGlobalUser;
  }

  public get isImpersonating(): boolean {
    return this.userPermissionService.isImpersonating;
  }

  public get currentUserId(): string {
    return this.userPermissionService.currentUserId;
  }

  public get canAccess(): boolean {
    if (this.isGlobalUser) return true;
    return this.canAccessBulkMessages || this.canAccessCampaigns || this.canAccessTemplates;
  }

  public get canAccessBulkMessages(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.any([
      "ManageEmails",
      "SendEmails",
      "ManageTextMessages",
      "SendTextMessages",
      "ManageVoiceMessages",
      "SendVoiceMessages",
      "ManageEventEmails", // events utilize bulk messages workflow
    ]);
  }

  public get canAccessCampaigns(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.any([
      "ManageOutreach",
      "ViewOutreach",
      "ManagePrintPieces",
      "SendPrintPieces",
    ]);
  }

  public get canAccessTemplates(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.any([
      "ManageEmailTemplates",
      "ViewEmailTemplates",
      "ManagePrintTemplates",
      "ViewPrintTemplates",
      "ManagePdfApproval",
      "ViewPdfApproval",
    ]);
  }

  public get canSendEmails(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["SendEmails"]);
  }

  public get canSendTextMessages(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["SendTextMessages"]);
  }

  public get canSendVoiceMessages(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["SendVoiceMessages"]);
  }

  public get canManageOutreach(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ManageOutreach"]);
  }

  public get canViewOutreach(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ViewOutreach"]);
  }

  public get canManageEmailTemplates(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ManageEmailTemplates"]);
  }

  public get canViewEmailTemplates(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ViewEmailTemplates"]);
  }

  public get canManagePrintTemplates(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ManagePrintTemplates"]);
  }
  public get canViewPrintTemplates(): boolean {
    if (this.isGlobalUser) return true;
    return this.permissions.all(["ViewPrintTemplates"]);
  }
}
