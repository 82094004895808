import { prop, inject } from "fw";
import { ContactsSlideroomAdmissionsApplicationsFilter as ContactsSlideroomAdmissionsApplicationsFilterModel } from "models/contacts-filters";
import { EvaluationPhasesStore } from "state/evaluation-phases";
import { ProgramStore } from "state/program";
import { FeatureFlagService } from "service/feature-flag";

@inject
export class ContactsSlideroomAdmissionsApplicationsFilter {
  @prop(null) filter!: ContactsSlideroomAdmissionsApplicationsFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  private phases: { text: string, value: string }[] = [];
  private programs: { text: string, value: string }[] = [];

  constructor(
    private phaseStore: EvaluationPhasesStore,
    private programStore: ProgramStore,
    private ffs: FeatureFlagService
  ) { }

  public attached() {
    this.programStore.state.programs.filter(p => p.IsHidden !== true).forEach(p => {
      this.programs.push({ text: p.Name, value: p.Name });
    });

    this.phases.push({ text: this.defaultPhaseLabel, value: null });
    this.phaseStore.state.phases.forEach(s => {
      this.phases.push({ text: s.Name, value: s.Name });
    });
  }

  get defaultPhaseLabel() {
      return this.phaseStore.state.defaultPhaseLabel;
  }
}
