import { inject } from "fw";
import { DialogController } from "fw-dialog";

export type ButtonOption = {
  action: string,
  text: string,
  class?: string,
  isDisabled?: boolean,
}

export interface ConfirmDialogWithButtonChoicesArgs {
  text: string;
  buttons: ButtonOption[];
}

@inject
export class ConfirmDialogWithButtonChoices {
  private text = '';
  private choiceButtons: ButtonOption[] = [];
  private cancelButtons: ButtonOption[] = [];
  private closeButtonEl: HTMLElement;

  constructor(private controller: DialogController<string>) { }

  attached() {
    this.closeButtonEl.focus();
  }

  activate(args: ConfirmDialogWithButtonChoicesArgs) {
    const { buttons, text } = args;
    this.text = text;
    this.choiceButtons = buttons.filter(b => 'cancel' !== b.action);
    this.cancelButtons = buttons.filter(b => 'cancel' === b.action);

    if (!this.cancelButtons.length) {
      this.cancelButtons.push({ action: 'cancel', text: 'Cancel' });
    }
  }

  ok(action: string = '') {
    this.controller.ok(action);
  }

  cancel() {
    this.controller.cancel();
  }
}
