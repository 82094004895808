 import { inject, needs } from "fw";
import { PopoverController } from "service/popover";

import { ApplicationRepository } from "network/application-repository";
import { TagPill } from "./tag-pill";
import { orderBy } from "lodash-es";

@inject
@needs(TagPill)
export class TagSelectorPopover {
  private loading = true;
  private showAdd = false;
  private tags: string[] = [];
  private allTags: string[] = [];
  private filter = "";
  private selectedTags: string[] = [];

  constructor(
    private applicationRepo: ApplicationRepository,
    private controller: PopoverController<string>,
  ) {}

  activate(params: { selected: string[]; showAdd: boolean; }) {
    this.selectedTags = params.selected || [];
    this.showAdd = !!params.showAdd;
  }

  async attached() {
    this.loading = true;

    const res = await this.applicationRepo.search(null, null, null, null, null, "terms:tag~1000", true);

    const items = res.Aggregations["terms_tag"].Items;

    if (items) {
      this.allTags = items.map(i => i.Key);
      this.tags = this.allTags.filter(t => !this.selectedTags.includes(t));
    }

    this.loading = false;
  }

  get filteredTags() {
    const lowerFilter = this.filter.toLowerCase().trim();

    const filtered = this.tags.filter(t => {
      return t.toLowerCase().trim().indexOf(lowerFilter) >= 0;
    });

    return orderBy(filtered, [t => t.toLowerCase()])
  }

  selectTag(tag: string) {
    if (tag.length == 0) return;

    this.controller.ok(tag);
  }

  get searchText() {
    if (this.loading || !this.showAdd) return "Search tags...";

    if (this.tags.length > 0) return "Search or create tags...";

    return "Create new tag...";
  }

  createFilteredTag() {
    this.controller.ok(this.filter);
  }

  get canAddTag() {
    if (!this.showAdd) return false;

    const filterLower = this.filter.toLowerCase().trim();
    if (filterLower.length == 0) return false;

    const exactFilterMatch = this.filteredTags.concat(this.selectedTags).some(ft => ft.toLowerCase().trim() == filterLower);
    return !exactFilterMatch;
  }

  get errorMessage() {
    if (this.showAdd && !this.canAddTag && this.filteredTags.length == 0 && this.filter.length > 0) return "Tag already added";
    else if (!this.showAdd && this.filteredTags.length == 0 && this.filter.length > 0) return "No tags found";
    else if (this.tags.length == 0 && this.allTags.length > 0) return "All tags used";
    else if (this.allTags.length == 0) return "No tags available";
  }
}
