import { inject, prop, needs } from "fw";
import { hashOn } from "hashing";

import { ApplicationSegmentStore } from "state/application-segments";
import { SegmentFilter as SegmentFilterModel } from "models/application-filters";
import { PopoverService } from "service/popover";

import { ValueField, ValueType } from "./value-field";
import { CategoryOptionChoice, OptionChoice, OptionChooserWithCategories } from "views/components/option-chooser-with-categories";

@inject
@needs(ValueField)
export class SegmentFilter {
  @prop(null) public filter!: SegmentFilterModel;
  @prop(() => ({})) public validation;
  @prop(false) public editing!: boolean;

  public segments: CategoryOptionChoice<string>[] = [];
  public valueType = ValueType.IsOnly;

  constructor(
    private segmentStore: ApplicationSegmentStore,
    private popover: PopoverService,
  ) { }

  public attached() {
    const segmentsData: { [name: string]: CategoryOptionChoice<string> } = {};

    this.segmentStore.state.segments.forEach((s, i) => {
      if (s.Category && segmentsData[s.Category]) segmentsData[s.Category].options.push({ text: s.Label, value: s.Id });
      if ((s.Category && !segmentsData[s.Category]) || !s.Category) {
        segmentsData[s.Category ? s.Category : `${s.Label}-${i}`] = {
          category: s.Category ?? '',
          options: [{ text: s.Label, value: s.Id }]
        };
      }
    });

    this.segments = Object.values(segmentsData);
  }

  public get segmentNameHash() {
    return hashOn(this.segmentStore.state.segments, s => s.Id, s => s.Label);
  }

  public get filterText() {
    switch (this.filter.operator) {
      case "=": return "is";
      case "!=": return "is not";
    }

    return this.filter.operator;
  }

  public async chooseField() {
    const res = await this.popover.open<OptionChoice<string>>(OptionChooserWithCategories, this.segments);

    if (res.canceled) return;

    this.filter.segmentId = res.result.value;
  }
}
