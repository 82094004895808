// mdl has some IE stuff..  not sure if we need this yet though..
// https://github.com/google/material-design-lite/blob/mdl-1.x/src/slider/slider.js#L179

import { prop, ComponentEventBus, inject } from "fw";

let uniqueId = 0;

@inject
export class RangeField {
  @prop(null) private label;
  @prop(null) private value;
  @prop(0) private min;
  @prop(100) private max;

  private uniqueId = uniqueId++;

  constructor(private componentEventBus: ComponentEventBus) {}

  get fraction() {
    if (this.value > this.max) return this.max;
    if (this.value < this.min) return this.min;

    return (this.value - this.min) / (this.max - this.min);
  }

  get sliderValue() {
    return this.value;
  }

  set sliderValue(val) {
    this.componentEventBus.updateModel(val);
  }
}
