import { inject } from "fw";
import { handle } from "fw-state";
import { StartAction, LogoutAction } from "./actions";
import { MarketingOutreachCampaignRepository } from "network/marketing-outreach-campaign-repository";
import { MarketingOutreachCampaign } from "models/marketing-outreach-campaign";
import {
  CreateMarketingOutreachCampaignAction,
  UpdateMarketingOutreachCampaignAction,
  CopyMarketingOutreachCampaignAction,
  DeleteMarketingOutreachCampaignAction,
  ActivateMarketingOutreachCampaignAction,
  DeactivateMarketingOutreachCampaignAction
} from "forms/marketing-outreach-campaign";
import { FormErrorHandling } from "./error-handling";
import { MarketingOutreachListStore } from "./marketing-outreach-list";

const MAX_SKIP = 2000;

export class FetchMarketingOutreachCampaignsAction {
  constructor(public refresh: boolean = false) {}
}
export class FetchMarketingOutreachCampaignAction {
  constructor(public id: number) {}
}

@inject
export class MarketingOutreachCampaignStore extends MarketingOutreachListStore<MarketingOutreachCampaign>  {
  constructor(private network: MarketingOutreachCampaignRepository) {
    super();
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    this.setState(state => ({ ...this.defaultState() }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(state => this.defaultState());
  }

  @handle(FetchMarketingOutreachCampaignsAction)
  private async handleFetchMarketingOutreachCampaigns(action: FetchMarketingOutreachCampaignsAction) {
    if (this.state.loaded && ! action.refresh) return;

    const { toggle, search, sort, pageSize } = this.state;
    let { currentPage } = this.state;

    const res = await this.network.list(toggle ? '1' : '0', search, null, sort, currentPage, pageSize);
    const totalCount = res.total;

    const lastPage = Math.ceil(totalCount / pageSize);
    currentPage = Math.min(currentPage, lastPage);

    const pageStartNumber = ((currentPage - 1) * pageSize) + 1;
    const pageEndNumber = pageStartNumber + (pageSize - 1);
    const hasNextPage = pageEndNumber < totalCount;
    const hasPreviousPage = pageStartNumber > 1;
    const canAdvancePage = pageEndNumber + 1 < MAX_SKIP;

    this.setState(state => ({
      ...state,
      loaded: true,
      collection: res.results,
      totalCount,
      currentPage,
      pageStartNumber: Math.max(pageStartNumber, 0),
      pageEndNumber: Math.min(pageEndNumber, totalCount),
      hasNextPage,
      hasPreviousPage,
      canAdvancePage,
      lastPage
    }));
  }

  @handle(FetchMarketingOutreachCampaignAction)
  private async handleFetchMarketingOutreachCampaign(action: FetchMarketingOutreachCampaignAction) {
    if (! action.id) return;

    const item = this.state.collection.find(i => i.Id == action.id);
    if (item) return;

    const newItem = await this.network.show(action.id);
    const collection = this.state.collection.slice();

    collection.push(newItem);
    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(CreateMarketingOutreachCampaignAction, FormErrorHandling)
  private async handleCreateMarketingOutreachCampaign(action: CreateMarketingOutreachCampaignAction) {
    action.form.validate();

    const newItem = await this.network.post(action.form.updatedModel());
    const collection = this.state.collection.slice();

    collection.push(newItem);
    this.setState(state => ({ ...state, collection: collection }));
    action.created = newItem;
  }

  @handle(UpdateMarketingOutreachCampaignAction, FormErrorHandling)
  private async handleUpdateMarketingOutreachCampaign(action: UpdateMarketingOutreachCampaignAction) {
    action.form.validate();

    const updatedItem = await this.network.put(action.form.updatedModel());
    const collection = this.state.collection.slice();
    const currentItem = collection.find(i => i.Id == updatedItem.Id);

    collection[collection.indexOf(currentItem)] = updatedItem;
    this.setState(state => ({ ...state, collection: collection }));
    action.updated = updatedItem;
  }

  @handle(DeleteMarketingOutreachCampaignAction)
  private async handleDeleteMarketingOutreachCampaign(action: DeleteMarketingOutreachCampaignAction) {
    const idsArr: number[] = Array.isArray(action.ids) ? action.ids : [action.ids];
    const items = this.state.collection.filter(i => idsArr.indexOf(i.Id) !== -1);
    if (items.length !== idsArr.length) return;

    await this.network.destroy(idsArr, action.all, action.toggle, action.search);
    this.setState(state => ({ ...state, selected: [] }));

    items.forEach(i => this.state.collection.splice(this.state.collection.indexOf(i), 1));
  }

  @handle(CopyMarketingOutreachCampaignAction, FormErrorHandling)
  private async handleCopyMarketingOutreachCampaign(action: CopyMarketingOutreachCampaignAction) {
    const idsArr: number[] = Array.isArray(action.ids) ? action.ids : [action.ids];
    const items = this.state.collection.filter(i => idsArr.indexOf(i.Id) !== -1);
    if (items.length !== idsArr.length) return;

    const copiedItems = await this.network.copy(idsArr, action.all, action.toggle, action.search);
    const collection = this.state.collection.slice();

    collection.push(...copiedItems);
    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(ActivateMarketingOutreachCampaignAction, FormErrorHandling)
  private async handleActivateMarketingEmailTemplate(action: ActivateMarketingOutreachCampaignAction) {
    const idsArr: number[] = Array.isArray(action.ids) ? action.ids : [action.ids];
    const items = this.state.collection.filter(i => idsArr.indexOf(i.Id) !== -1);
    if (items.length !== idsArr.length) return;

    await this.network.activate(idsArr, action.all, action.toggle, action.search);

    items.forEach((i: MarketingOutreachCampaign) => i.Archived = 0);
  }

  @handle(DeactivateMarketingOutreachCampaignAction, FormErrorHandling)
  private async handleDeactivateMarketingEmailTemplate(action: DeactivateMarketingOutreachCampaignAction) {
    const idsArr: number[] = Array.isArray(action.ids) ? action.ids : [action.ids];
    const items = this.state.collection.filter(i => idsArr.indexOf(i.Id) !== -1);
    if (items.length !== idsArr.length) return;

    await this.network.deactivate(idsArr, action.all, action.toggle, action.search);

    items.forEach((i: MarketingOutreachCampaign) => i.Archived = 1);
  }
}
