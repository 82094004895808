import { inject, prop } from "fw";

import { debounce } from "lodash-es";
import { OrganizationWithDomainView } from "network/app-repository";
import { MAX_ORG_LENGTH } from "./select-organization.constants";

@inject
export class SelectOrganization {
  @prop(() => {}) public onSelect!: (organizationId: string) => void;
  @prop(null) public allowedOrganizations: OrganizationWithDomainView[];

  private organizationsList: OrganizationWithDomainView[] = [];
  private lastLoginsList: OrganizationWithDomainView[] = [];

  private searchValue: string = "";
  private selectedOrganizationId: string = null;
  private showSearch: boolean = false;
  private debouncedSearch: () => Promise<void> = null;

  public constructor() {}

  public async attached() {
    this.setShowSearch();

    if (!this.showSearch) {
      this.organizationsList = this.allowedOrganizations;
      return;
    }

    this.searchOrganization();

    this.debouncedSearch = debounce(async () => {
      await this.searchOrganization();
    }, 250);
  }

  private setShowSearch() {
    this.showSearch = this.allowedOrganizations.length > MAX_ORG_LENGTH;
  }

  private searchOrganization() {
    if (!this.searchValue) {
      // the first 3 orgs are the most recent
      this.lastLoginsList = this.allowedOrganizations.slice(0, 3);
      // the rest are sorted alphabetically
      this.organizationsList = this.allowedOrganizations.slice(3);
      return;
    }

    this.lastLoginsList = [];
    this.organizationsList = this.allowedOrganizations.filter((org) => {
      return org.Name.toLowerCase().includes(this.searchValue.toLowerCase());
    });
  }

  public get searchTerm(): string {
    return this.searchValue;
  }

  public set searchTerm(v: string) {
    this.searchValue = v;
    this.debouncedSearch();
  }

  private selectOrganization(organizationId: string) {
    this.selectedOrganizationId = organizationId;
    this.onSelect(organizationId);
  }

  private isSelected(organizationId: string) {
    return this.selectedOrganizationId === organizationId;
  }
}
