import { fromClass } from "fw-model";

export enum TaskRequestStatusTypeCode {
  Requested = 0,
  Running = 1,
  Complete = 2,
  Error = 3,
}

export class TaskRequestMetaData {
  OrganizationId: string;
  ActorType: string;
  ActorId: string;

  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

export class TaskRequest {
  Id: string;
  Type: string;
  Data: { [key: string]: string };
  StatusUpdates: string[];
  TotalActions: number;
  CompletedActions: number;
  ErroredActions: number;

  Status: TaskRequestStatusTypeCode;
  @fromClass MetaData: TaskRequestMetaData;
}

export class TaskAware {
  TaskRequest: TaskRequest;
  TaskFriendlyName: string;
}
