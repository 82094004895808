import {
  inject,
  makeAndActivate,
  ContainerInstance,
  ViewEngine,
  Bus,
  ViewRouterLocationChanged,
  CloseStack,
} from "fw";

import offset from "document-offset";

export interface makerOf<T> {
  new (...args): T;
}

export interface PopoverResult<T> {
  canceled: boolean;
  result: T;
}

const getScrollY = () => (window.pageYOffset !== undefined)
  ? window.pageYOffset
  : ((document.documentElement || document.body.parentNode || document.body) as any).scrollTop;

@inject
export class PopoverCoordinator {
  private openPosition: { x: number; y: number; fixed: boolean } = {
    x: 0,
    y: 0,
    fixed: false,
  };
  private opened = [];

  private openAwaiters = [];
  private closeAwaiters = [];

  constructor(private bus: Bus) {
    this.bus.subscribe(ViewRouterLocationChanged, this.closeAll.bind(this));
  }

  public openAt(x: number, y: number, fixed = false) {
    this.openPosition = { x, y, fixed };
  }

  public openAtElement(element: any, fixed = false) {
    const { offsetHeight } = element;

    const { left, top } = offset(element);

    let y = top + (offsetHeight || 0);
    if (fixed) {
      y -= getScrollY();
    }

    this.openAt(left, y, fixed);
  }

  public getPosition(
    popoverWidth: number,
    popoverHeight: number,
    repositionOnOverflow?: boolean
  ): { x: number; y: number; fixed: boolean } {
    const {
      innerWidth: viewPortWidth,
      innerHeight: viewPortHeight,
    } = window;

    const spacing = 20;

    let { x, y, fixed } = this.openPosition;

    if (repositionOnOverflow !== false && x + popoverWidth > viewPortWidth) {
      x = viewPortWidth - popoverWidth - spacing;
    } else {
      x = Math.max(x, spacing);
    }

    if (repositionOnOverflow !== false) {
      let maxY = viewPortHeight - popoverHeight - spacing;

      if (!fixed) {
        maxY += getScrollY();
      }

      y = Math.min(y, maxY);
    }

    return { x, y, fixed };
  }

  public closeAll() {
    this.opened.forEach(p => p({ canceled: true, result: null }));
    this.opened = [];

    this.closeAllAwaiters();
  }

  public closeAllAwaiters() {
    this.closeAwaiters.forEach(p => p());
    this.closeAwaiters = [];
  }

  public push(resolver) {
    this.opened.push(resolver);

    this.openAwaiters.forEach(p => p());
    this.openAwaiters = [];
  }

  public waitForOpen() {
    return new Promise(res => {
      this.openAwaiters.push(res);
    });
  }

  public waitForClose() {
    return new Promise(res => {
      this.closeAwaiters.push(res);
    });
  }
}

const classes = {
  wrapper: "fw-popover-wrapper",
  container: "fw-popover",
  open: "open",
};

export class DomElementAttributes {
  id?: string;
  class?: string;
  width?:  number;
}


@inject
export class PopoverService {
  constructor(
    private coordinator: PopoverCoordinator,
    private closeStack: CloseStack,
  ) {}

  public async open<TResult>(
    view: makerOf<any>,
    data?: any,
    element?: any,
    focus?: boolean,
    repositionOnOverflow?: boolean,
    container?: any,
    noAutoClose?: boolean,
    popoverAttrs?: DomElementAttributes,
  ): Promise<PopoverResult<TResult>> {
    this.coordinator.closeAll();

    const popoverElement = document.createElement("div");
    popoverElement.classList.add(classes.wrapper);

    if (popoverAttrs?.id) {
      popoverElement.id = popoverAttrs.id;
    }
    if (popoverAttrs?.class) {
      popoverElement.classList.add(popoverAttrs.class);
    }
    if (popoverAttrs?.width) {
      popoverElement.style.width = `${popoverAttrs.width}px`;
    }

    popoverElement.setAttribute("aria-label", "Popover");
    popoverElement.setAttribute("role", "dialog");
    popoverElement.appendChild(document.createElement("div"));
    popoverElement.setAttribute("tabindex", "0");
    (container != null ? container : document.body).appendChild(popoverElement);

    const getViewElement = (): HTMLElement => popoverElement.children[0] as HTMLElement;

    let resolver = null;
    const returnPromise = new Promise<PopoverResult<TResult>>(
      res => (resolver = res),
    );
    const controller = new PopoverController<TResult>(resolver);

    await makeAndActivate(view, getViewElement(), data, o => o.use(PopoverController, controller));

    if (element != null) {
      this.coordinator.openAtElement(element);
    }

    setTimeout(() => {
      popoverElement.classList.add(classes.open);
      if (focus !== false) popoverElement.focus();
    }, 10);

    // setup key listener for ESC; and call cancel or close or something on the controller...
    //
    const closer = this.closeStack.enroll(() => resolver({ canceled: true }));

    const clickHandler = (e: MouseEvent) => {
      window.removeEventListener("resize", setPopoverPosition);

      if ((<any>e).__getFile) return;
      this.coordinator.closeAll();
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
    };

    const setPopoverPosition = () => {
      const hasOffset = Boolean(element && offset(element));
      if (hasOffset) {
        this.coordinator.openAtElement(element);
      }

      const { x, y, fixed } = this.coordinator.getPosition(
        popoverElement.clientWidth,
        popoverElement.clientHeight,
        repositionOnOverflow
      );

      popoverElement.style.left = `${x}px`;
      popoverElement.style.top = `${y}px`;
      popoverElement.style.position = fixed ? "fixed" : null;

    };
    
    const resizeObserver = new ResizeObserver(entries => setPopoverPosition());
    resizeObserver.observe(popoverElement)

    const stopBubble = e => {
      closer.closeAbove();

      e.stopImmediatePropagation();
      e.stopPropagation();
      // Removed the e.preventDefault() here because it prevents interaction with checkboxes in popovers
    };

    window.addEventListener("mousedown", clickHandler);
    window.addEventListener("resize", setPopoverPosition);
    popoverElement.addEventListener("mousedown", stopBubble);

    if (!noAutoClose) this.coordinator.push(resolver);

    const res = await returnPromise;

    popoverElement.classList.remove(classes.open);
    this.coordinator.closeAllAwaiters();

    setTimeout(() => {
      popoverElement.removeEventListener("click", stopBubble);
      popoverElement.remove();

      //document.removeEventListener("keydown", escHandler);
      window.removeEventListener("mousedown", clickHandler);
    }, 300);

    return res;
  }
}

export class PopoverController<T> {
  constructor(private resolver: (result: PopoverResult<T>) => void) {}

  close(canceled = false, result?: T) {
    this.resolver({ canceled, result });
  }

  cancel() {
    this.close(true);
  }

  ok(result: T) {
    this.close(false, result);
  }
}
