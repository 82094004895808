import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { ReviewPortal } from "models/review-portal";

@inject
export class ReviewPortalRepository {
  constructor(private s: ATS) {}

  public async get(id: string) {
    const res = await this.s.get(`review-portal/${id}`);
    return createFrom(ReviewPortal, res.body);
  }

  public async list() {
    const res = await this.s.get<ReviewPortal[]>("review-portal");
    return createFromArray(ReviewPortal, res.body);
  }

  public async delete(id: string) {
    await this.s.delete(`review-portal/${id}`);
  }

  public async post(portal: ReviewPortal) {
    const res = await this.s.post("review-portal", portal);
    return createFrom(ReviewPortal, res.body);
  }

  public async put(portal: ReviewPortal) {
    const res = await this.s.put<ReviewPortal>(
      `review-portal/${portal.Id}`,
      portal
    );
    return createFrom(ReviewPortal, res.body);
  }
}
