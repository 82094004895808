import { inject, needs, prop, ComponentEventBus } from "fw";

import { ChartOptions } from "../chart-options";
import { ReportAxisDefinitionEditor } from "../report-axis-definition-editor";
import { PopoverResult, PopoverService } from "service/popover";

import {
  ReportChartType,
  AggregationType,
  ChartDataSource,
  TermsOrderBy,
} from "shared/report-runtime";
import {
  FieldSelector,
  ChartField,
} from "views/applicants/chart/field-selector";
import { FormForReportChartDefinition, type FormForWidgetDefinition } from "forms/report-definition";
import { ContactTypeDefinition } from 'models/contact-organization';
import { ChartContactTypeSelector } from '../../contacts/charts/chart-contact-type-selector';
import { ChartContactsFieldSelector } from '../../contacts/charts/chart-contacts-field-selector';
import { FeatureFlagService } from "service/feature-flag";
import { ReportService } from "service/report";
import { DataDictionaryAggregationType, DataDictionaryField, DataDictionaryIndexStatus } from "models/data-dictionary";
import { DataDictionaryFieldSelectorOptions, DataDictionaryFieldSelectorPopover } from "views/components/data-dictionary-field-selector-popover";
import { DataDictionaryService } from "service/data-dictionary";
import { CurrentContactOrganizationStore } from 'state/current-contact-organization';
import { CustomFieldType } from 'models/contact-organization';

let inputIdIterator: number = 0;

@inject
@needs(ReportAxisDefinitionEditor, ChartOptions, ChartContactTypeSelector)
export class ChartsAxisEditor {
  @prop(null)
  widgetDefinitionForm: FormForWidgetDefinition;

  private chartAxisEditorBaseInputId: string = "chart-axis-editor-input";
  private chartAxisEditorPopoverId: string = "chart-axis-editor-popover";
  private chartTypes = ReportChartType;
  private dataSource: ChartDataSource = ChartDataSource.Admissions;
  private defaultChartName: string = null;
  private focusedOptionId: string = '';
  private popoverIsOpen: boolean = false;
  private selectedContactType?: string = null;

  constructor(
    private ceb: ComponentEventBus,
    private dataDictionaryService: DataDictionaryService,
    private popoverService: PopoverService,
    private reportService: ReportService,
    private ffs: FeatureFlagService,
    private currentContactOrganizationStore: CurrentContactOrganizationStore
  ) { }

  attached() {
    this.dataSource = this.widgetDefinitionForm.DataSource;
    this.selectedContactType = this.widgetDefinitionForm.ContactType;
    this.defaultChartName = this.reportChartDefinition.PrimaryIndependentVariable?.Label;

    if (this.selectedContactType) {
      const fields = this.getChartFieldsForContactType(this.selectedContactType);
      if (this.reportChartDefinition.PrimaryIndependentVariable) {
        this.reportChartDefinition.PrimaryIndependentVariable.HasOptionValues = fields.find(f => f.Path == this.reportChartDefinition.PrimaryIndependentVariable.Path)?.HasOptionValues;
      }
      if (this.reportChartDefinition.SecondaryIndependentVariable) {
        this.reportChartDefinition.SecondaryIndependentVariable.HasOptionValues = fields.find(f => f.Path == this.reportChartDefinition.SecondaryIndependentVariable.Path)?.HasOptionValues;
      }

    }
  }

  chartChanged() {
    this.ceb.dispatch("changed");
  }

  get chartAxisEditorInputId() {
    inputIdIterator++;
    return `${this.chartAxisEditorBaseInputId}-${inputIdIterator}`;
  }

  get isContactDataSource() {
    return this.dataSource === ChartDataSource.Contacts;
  }

  private selectContactType(contactType: ContactTypeDefinition) {
    this.selectedContactType = contactType.key;
    this.reportChartDefinition.ContactType = contactType.key;
    this.reportChartDefinition.PrimaryIndependentVariable.Label = null;
    this.reportChartDefinition.PrimaryIndependentVariable.Path = null;
    this.reportChartDefinition.PrimaryIndependentVariable.AggregationType = AggregationType.Terms;
    this.reportChartDefinition.PrimaryIndependentVariable.Interval = null;
    this.reportChartDefinition.PrimaryIndependentVariable.LabelTransform = null;
    this.reportChartDefinition.PrimaryIndependentVariable.TermsOrderBy = TermsOrderBy.Counts;
    this.reportChartDefinition.PrimaryIndependentVariable.HasOptionValues = false;

    this.reportChartDefinition.SecondaryIndependentVariable.Label = null;
    this.reportChartDefinition.SecondaryIndependentVariable.Path = null;
    this.reportChartDefinition.SecondaryIndependentVariable.AggregationType = AggregationType.Terms;
    this.reportChartDefinition.SecondaryIndependentVariable.Interval = null;
    this.reportChartDefinition.SecondaryIndependentVariable.LabelTransform = null;
    this.reportChartDefinition.SecondaryIndependentVariable.TermsOrderBy = TermsOrderBy.Counts;
    this.reportChartDefinition.SecondaryIndependentVariable.HasOptionValues = false;
    this.chartChanged();
  }
  private fieldsByType: { [type: string]: ChartField[] } = {};

  private getChartFieldsForContactType(contactType: string) {
    if (!this.fieldsByType[contactType]) {
      let fields = this.currentContactOrganizationStore.state.organization
        .fields
        .filter(field => {
          if (field.contact_type !== contactType || !field.is_indexed) {
            return false;
          }

          switch (field.type) {
            case CustomFieldType.number:
            case CustomFieldType.boolean:
            case CustomFieldType.date:
            case CustomFieldType.dropdown:
            case CustomFieldType.multiselect:
            case CustomFieldType.tags:
            case CustomFieldType.postalcode:
            case CustomFieldType.country:
            case CustomFieldType.funnel:
              return true;

            case CustomFieldType.string:
            case CustomFieldType.largestring:
            case CustomFieldType.address:
            case CustomFieldType.email:
            case CustomFieldType.phone:
            case CustomFieldType.social:
            case CustomFieldType.link:
            case CustomFieldType.relationship:
            case CustomFieldType.table:
            case CustomFieldType.slideroomapplications:
            case CustomFieldType.slideroomadmissionsapplications:
            case CustomFieldType.testscore:
            case CustomFieldType.user:
            case CustomFieldType.concealed:
            case CustomFieldType.attachment:
            default:
              return false;
          }
        })
        .map(field => {
          let fieldType = AggregationType.Terms;
          if (field.type == CustomFieldType.date) {
            fieldType = AggregationType.Dates;
          } else if (field.type == CustomFieldType.number) {
            fieldType = AggregationType.Histogram;
          }
          let path = field.search_field;
          if (field.is_system_field) {
            switch (field.search_field.toLowerCase()) {
              case "firstname": { path = "first_name"; break; }
              case "lastname": { path = "last_name"; break; }
              case "companyname": { path = "company_name"; break; }
              case "tags": { path = 'tags'; break; }
            }
          }

          return <ChartField>{
            Label: field.display_name,
            LabelTransform: (field.type == CustomFieldType.boolean)
              ? 'boolean'
              : null,
            Path: path,
            Type: fieldType,
            HasOptionValues: field.type != CustomFieldType.tags && field.options?.length > 0
          }
        });

      fields.push({ Label: "Engagement Score", Path: "engagement_score", Type: AggregationType.Histogram });

      this.fieldsByType[contactType] = fields;
    }
    return this.fieldsByType[contactType];
  }

  private async getChartField(selectedPath?: string): Promise<ChartField> {
    let res: PopoverResult<ChartField> = null;
    switch (this.dataSource) {
      case ChartDataSource.Admissions:
        if (this.ffElectiveIndexing) {
          const fieldCategories = await this.reportService.getChartableFieldCategories();
          const options: DataDictionaryFieldSelectorOptions = {
            filter: (f: DataDictionaryField) => f.IndexStatus === DataDictionaryIndexStatus.Indexing || f.IndexStatus === DataDictionaryIndexStatus.Indexed,
            options: fieldCategories,
            selectedOption: fieldCategories.reduce((fields, category) => fields.concat(category.fields), []).find(f => f.Path === selectedPath),
            updateOptionFocus: this.updateOptionFocus,
          };
          const response = await this.popoverService.open<DataDictionaryField>(
            DataDictionaryFieldSelectorPopover,
            options,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { id: this.chartAxisEditorPopoverId },
          );
          res = { canceled: response.canceled, result: this.toChartField(response.result) };
        } else {
          res = await this.popoverService.open<ChartField>(
            FieldSelector,
            { updateOptionFocus: this.updateOptionFocus },
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { id: this.chartAxisEditorPopoverId },
          );
        }
        break;

      case ChartDataSource.Contacts:
        res = await this.popoverService.open<ChartField>(
          ChartContactsFieldSelector,
          {
            fields: this.getChartFieldsForContactType(this.selectedContactType),
            selectedChartField: this.reportChartDefinition.PrimaryIndependentVariable,
            updateOptionFocus: this.updateOptionFocus,
          },
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          { id: this.chartAxisEditorPopoverId },
        );
        break;

      case ChartDataSource.Tasks:
        break;
    }
    return !res || res.canceled ? null : res.result;
  }

  public updateOptionFocus(optionId: string) {
    this.focusedOptionId = optionId;
  }

  async chooseField(secondary: boolean = false) {
    const selectedPath: string = secondary ? this.reportChartDefinition.SecondaryIndependentVariable.Path : this.reportChartDefinition.PrimaryIndependentVariable.Path;
    this.popoverIsOpen = true;
    const chartField = await this.getChartField(selectedPath);
    this.popoverIsOpen = false;
    if (!chartField) return;

    const { Label, Path, Type, LabelTransform, HasOptionValues } = chartField;
    let interval = null;

    switch (Type) {
      case AggregationType.Histogram:
        interval = this.dataSource === ChartDataSource.Admissions ? 10 : 1;
        break;

      case AggregationType.Dates:
        interval = "1d";
        break;
    }

    if (secondary == false) {
      if (this.widgetDefinitionForm.Name == this.defaultChartName) {
        this.defaultChartName = Label;
        this.widgetDefinitionForm.Name = this.defaultChartName;
      }

      this.reportChartDefinition.PrimaryIndependentVariable.Label = Label;
      this.reportChartDefinition.PrimaryIndependentVariable.Path = Path;
      this.reportChartDefinition.PrimaryIndependentVariable.AggregationType = Type;
      this.reportChartDefinition.PrimaryIndependentVariable.Interval = interval;
      this.reportChartDefinition.PrimaryIndependentVariable.LabelTransform = LabelTransform;
      this.reportChartDefinition.PrimaryIndependentVariable.TermsOrderBy = TermsOrderBy.Counts;
      this.reportChartDefinition.PrimaryIndependentVariable.HasOptionValues = HasOptionValues;
    } else {
      this.reportChartDefinition.SecondaryIndependentVariable.Label = Label;
      this.reportChartDefinition.SecondaryIndependentVariable.Path = Path;
      this.reportChartDefinition.SecondaryIndependentVariable.AggregationType = Type;
      this.reportChartDefinition.SecondaryIndependentVariable.Interval = interval;
      this.reportChartDefinition.SecondaryIndependentVariable.LabelTransform = LabelTransform;
      this.reportChartDefinition.SecondaryIndependentVariable.TermsOrderBy = TermsOrderBy.Counts;
      this.reportChartDefinition.SecondaryIndependentVariable.HasOptionValues = HasOptionValues;
    }

    this.chartChanged();
  }

  swapFields() {
    let axis = this.reportChartDefinition.PrimaryIndependentVariable;
    this.reportChartDefinition.PrimaryIndependentVariable = this.reportChartDefinition.SecondaryIndependentVariable;
    this.reportChartDefinition.SecondaryIndependentVariable = axis;
    this.chartChanged();
  }

  chooseType(type: ReportChartType) {
    this.reportChartDefinition.ChartType = type;

    this.chartChanged();
  }

  private toChartField(field: DataDictionaryField): ChartField | null {
    let aggType: AggregationType;
    switch (field?.AggregationType) {
      case DataDictionaryAggregationType.None:
        return null;
      case DataDictionaryAggregationType.Histogram:
        aggType = this.dataDictionaryService.isDateField(field) ? AggregationType.Dates : AggregationType.Histogram;
        break;
      case DataDictionaryAggregationType.Terms:
        aggType = AggregationType.Terms;
        break;
      default:
        return null;
    }

    return <ChartField>{
      Label: field.Label,
      LabelTransform: field.LabelTransform,
      Path: field.SearchPath ?? field.Path,
      Type: aggType,
      HasOptionValues: field.Values?.length > 0
    };
  }

  private get reportChartDefinition(): FormForReportChartDefinition {
    return this.widgetDefinitionForm as FormForReportChartDefinition;
  }

  private get ffElectiveIndexing(): boolean {
    return this.ffs.isFeatureFlagEnabled("ElectiveIndexing");
  }
}
