import { inject, prop } from "fw";

import { EvaluationPhasesStore } from "state/evaluation-phases";
import { FeatureFlagService } from "service/feature-flag";

@inject
export class PhaseFilter {
  @prop(() => ({})) validation;
  @prop(null) filter;
  @prop(false) editing!: boolean;

  private phases: { text: string, value: string }[] = [];
  private phaseNameHash: { [id: string]: string } = {};

  constructor(
    private phaseStore: EvaluationPhasesStore,
    private ffs: FeatureFlagService
  ) { }

  attached() {
    const newHash: { [id: string]: string } = {};

    this.phases.push({ text: this.defaultPhaseLabel, value: null });

    this.phaseStore.state.phases.forEach(s => {
      this.phases.push({ text: s.Name, value: s.Id });
      newHash[s.Id] = s.Name;
    });

    this.phaseNameHash = newHash;
  }

  get defaultPhaseLabel() {
    return this.phaseStore.state.defaultPhaseLabel;
  }
}
