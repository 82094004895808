import { inject } from "fw";
import { createFromArray } from "fw-model";

import { ATS } from "./ats";

import { ContactDataDictionaryField } from "models/contact-data-dictionary";

@inject
export class ContactDataDictionaryRepository {
  constructor(private s: ATS) { }

  public async list(contactTypeKey: string) {
    const res = await this.s.contacts.get<ContactDataDictionaryField[]>(`data-dictionary/by-contact-type/${contactTypeKey}`);
    return createFromArray(ContactDataDictionaryField, res.body);
  }
}
