import { FormForType, formFor, Validators } from "fw-model";
const { isEmail, wrap } = Validators;
import { Contact } from 'models/contact';
import { ContactTypeDefinition } from 'models/contact-organization';

export type AddNewContactFormType = FormForType<Contact>;

export class AddContactAction {
  constructor(public form: AddNewContactFormType) { }
}

export const addNewContactFormCreator = (type?: ContactTypeDefinition) => formFor(Contact, s => {
  s.requiredField(u => u.email_address, "Email", wrap(isEmail));

  if (type != null) {
    if (type.use_full_name) {
      s.requiredField(u => u.company_name, "Full Name");
    } else {
      s.requiredField(u => u.first_name, "First Name");
      s.requiredField(u => u.last_name, "Last Name");
    }
  }
});
