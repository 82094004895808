import { FormForType, formFor, Validators } from "fw-model";
import { ContactTypeDefinition } from 'models/contact-organization';
const { wrap } = Validators;
export type ContactTypeFormType = FormForType<ContactTypeDefinition>;

export class AddContactTypeAction {
  constructor(public form: ContactTypeFormType) { }
}

export class UpdateContactTypeAction {
  constructor(public form: ContactTypeFormType) { }
}

export class DeleteContactTypeAction {
  constructor(public key: string) { }
}

const validContactTypeKey = (inputKey) => {
  return (inputKey == "new") ?  inputKey + " is not a valid key." : null;
};

export const contactTypeFormCreator = formFor(ContactTypeDefinition, s => {
  s.requiredField(u => u.name, "Name");
  s.requiredField(u => u.plural_name, "Plural Name");
  s.requiredField(u => u.key, "Key", wrap(validContactTypeKey));
  s.requiredField(u => u.color, "Color");
  s.requiredField(u => u.use_full_name, "Name Display Format");
});
