import { inject } from "fw";
import { createFrom, createFromArray, cloneOf } from "fw-model";

import { ATS } from "./ats";
import { encodeStudentPortal } from "./encode";

import { StudentPortal } from "models/student-portal";

@inject
export class StudentPortalRepository {
  constructor(private s: ATS) {}

  public async get(id: string) {
    const res = await this.s.get(`student-portal/${id}`);
    return createFrom(StudentPortal, res.body);
  }

  public async list() {
    const res = await this.s.get<StudentPortal[]>("student-portal");
    return createFromArray(StudentPortal, res.body);
  }

  public async delete(id: string) {
    await this.s.delete(`student-portal/${id}`);
  }

  public async post(portal: StudentPortal) {
    const res = await this.s.post("student-portal", encodeStudentPortal(portal));
    return createFrom(StudentPortal, res.body);
  }

  public async put(portal: StudentPortal) {
    const res = await this.s.put<StudentPortal>(
      `student-portal/${portal.Id}`,
      encodeStudentPortal(portal),
    );
    return createFrom(StudentPortal, res.body);
  }
}
