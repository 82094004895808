import { inject } from "fw";
import { createFrom, createFromArray, createFromProperties } from "fw-model";
import { MemoryCache, cacheFor } from "caching";

import { ATS } from "./ats";

import { Form, FormTypeCode } from "models/form";

@inject
export class FormRepository {
  constructor(private s: ATS, private cache: MemoryCache) {}

  public async post(form: Form, formType: FormTypeCode, contactType: string = null) {
    const res = await this.s.post("form", form, { type: formType, contactType });
    return createFrom(Form, res.body);
  }

  public async update(form: Form, version: string) {
    const res = await this.s.put<Form>(`form/${form.Id}`, form, {version});
    return createFrom(Form, res.body);
  }

  public async get(id: string) {
    const res = await this.s.get(`form/${id}`);
    return createFrom(Form, res.body);
  }

  public async getByKey(key: string) {
    const res = await this.s.get(`form/key/${key}`);
    return createFrom(Form, res.body);
  }

  public async del(ids: string[]) {
    await this.s.post("form/delete", ids);
  }

  // a list of form and version with a colon in the middle [ "123ad4:1", "...." ]
  public async versions(idsAndVersion: string[]) {
    const uncachedIds: string[] = [];
    const cached: { [id: string]: Form } = {};

    idsAndVersion.forEach(formAndVersion => {
      const cachedForm = this.cache.get<Form>(`form-version:${formAndVersion}`);

      if (cachedForm != null) cached[formAndVersion] = cachedForm;
      else uncachedIds.push(formAndVersion);
    });

    let uncached: { [id: string]: Form } = {};
    if (uncachedIds.length > 0) {
      const formVersionIdHash: { [id: string]: string } = {};

      uncachedIds.forEach(id => {
        const split = id.split(":");
        formVersionIdHash[split[0]] = split[1];
      });

      const res = await this.s.post(`form/versions/`, uncachedIds);
      const created = createFromProperties(Form, res.body);

      Object.keys(created).forEach(key => {
        const f = created[key];
        uncached[key] = f;

        // just cache forever..  we don't have to reload this one
        this.cache.set(`form-version:${key}`, f);
      });
    }

    const formList: { [key: string]: Form } = {};

    idsAndVersion.forEach(id => {
      const fromCached = cached[id];

      if (fromCached != null) {
        formList[id] = fromCached;
        return;
      }

      const fromUncached = uncached[id];

      if (fromUncached != null) formList[id] = fromUncached;
    });

    return formList;
  }
}
