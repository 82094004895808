import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LocalStorageCache } from "caching";

import { LogoutAction, StartAction } from "./actions";

export type FormsMode = "full" | "condensed";

interface UserExperienceSettingsShape {
  organizationId: string;
  userId: string;

  readingOptions: {
    formsMode: FormsMode;
  };
}

export class UpdateReadingOptionsAction {
  constructor(public formsMode: FormsMode = null) { }
}

/*
 * this store saves to local storage per user
 * (so if they are associated to multiple orgs,
 * they will get the same settings)
 */

@inject
export class UserExperienceSettingsStore extends Store<
UserExperienceSettingsShape
> {
  constructor(private cache: LocalStorageCache) {
    super();
  }

  defaultState() {
    return {
      organizationId: null,
      userId: null,

      readingOptions: {
        formsMode: "full" as FormsMode,
      },
    };
  }

  private saveState() {
    if (this.state.userId == null) return;

    this.cache.set<UserExperienceSettingsShape>(
      `${this.state.organizationId}:${this.state.userId}:user-experience-settings-store`,
      this.state,
    );
  }

  private restoreState() {
    if (this.state.userId == null) return;

    const savedState = this.cache.get<UserExperienceSettingsShape>(
      `${this.state.organizationId}:${this.state.userId}:user-experience-settings-store`,
    );
    if (savedState == null) return;

    this.setState(state => savedState);
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    const { Me, Organization } = s.context;

    this.setState(state => ({
      ...state,
      organizationId: Organization.Id,
      userId: Me.Id,
    }));

    this.restoreState();
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(UpdateReadingOptionsAction)
  private handleUpdateReadingOptions(action: UpdateReadingOptionsAction) {
    let newState = { ...this.state };
    let updatedNewState = false;

    if (action.formsMode != null) {
      newState = {
        ...newState,
        readingOptions: {
          ...newState.readingOptions,
          formsMode: action.formsMode,
        },
      };
      updatedNewState = true;
    }

    if (updatedNewState) {
      this.setState(state => newState);
      this.saveState();
    }
  }
}
