import { ComponentEventBus, inject, prop } from "fw";
import { Validators } from "fw-model";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { ContactsService } from "service/contacts";
import { FieldMode } from "../field-mode";

import { EmailType as ContactEmailType } from "models/contact";
import { IDropdownFieldOptions } from "views/components/dropdown-field";
import { ContactsOrganizationService } from "service/contacts-organization";

const { isEmail } = Validators;

@inject
export class EmailType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: any;
  @prop("Email") placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(true) public setfocus!: boolean;
  @prop(false) public showClear!: boolean;

  public validation: string = null;

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  public attached() {
    this.ceb.dispatch("validate", this.validate);
  }

  public get metaDescription() {
    return this.showMeta
      ? this.contactOrganizationService.getFieldMetaDescription(this.field)
      : null;
  }

  public get label() {
    return this.showLabel ? this.field.display_name : null;
  }

  public get localAddress(): string {
    return this.value && this.value.address;
  }

  public set localAddress(address: string) {
    this.ceb.dispatch("update:value", {
      ...this.value,
      address: address && address.trim(),
    });
  }

  public get localType(): ContactEmailType {
    return this.value && this.value.type;
  }

  public set localType(type: ContactEmailType) {
    this.ceb.dispatch("update:value", { ...this.value, type: type });
  }

  public get options(): IDropdownFieldOptions[] {
    return [
      { value: ContactEmailType.Email, text: "Email" },
      { value: ContactEmailType.Personal, text: "Personal Email" },
      { value: ContactEmailType.Work, text: "Work Email" },
    ];
  }

  public get localValue() {
    return this.value;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", value);
  }

  public clear() {
    this.value = undefined;
    this.ceb.dispatch("update:value", undefined);
  }

  private validate(originalValue): boolean {
    if (!this.value) return true;

    this.validation = isEmail(this.value);
    return this.validation === null;
  }
}
