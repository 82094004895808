import { inject } from "fw";

import { Store, handle, dispatch } from "fw-state";
import { WorkflowRepository } from "network/workflow-repository";

import { Workflow } from "models/workflow";
import { LogoutAction, StartAction } from "./actions";
import { AddWorkflowAction, UpdateWorkflowAction, DeleteWorkflowsAction } from "forms/workflow";
import {FormErrorHandling} from 'state/error-handling';
import { difference } from "lodash-es";

type StoreShape = {
  loading: boolean;
  loaded: boolean;
  total: number;
  page: number;
  pageSize: number;
  workflows: Workflow[];
  workflowHash: { [id: string]: Workflow };
};

export class EnsureWorkflowsAction {}

export class EnsureWorkflowAction {
  constructor(public id: string) {}
}

const clean = (model: Workflow) => {
  for(const node of model.Path) {
    if (node["Completer"])
      delete node["Completer"];
    if (node["PathInspector"])
      delete node["PathInspector"];
  }

  return model;
};

@inject
export class WorkflowStore extends Store<StoreShape> {
  constructor(private workflowRepo: WorkflowRepository) {
    super();
  }

  defaultState() {
    return {
      loaded: false,
      loading: false,
      total: 0,
      page: 1,
      pageSize: 20,
      workflows: [],
      workflowHash: {},
    };
  }

  @handle(LogoutAction)
  private resetState() {
    this.setState(_ => this.defaultState());
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    this.setState(state => ({
      ...state,
      workflows: action.context.Workflows,
      loaded: true,
    }));
  }

  @handle(EnsureWorkflowsAction)
  private async handleEnsureWorkflowsAction() {
    if (this.state.loading || this.state.loaded) return;

    this.setState(state => ({
      ...state,
      loading: true,
    }));

    const { total, list } = await this.workflowRepo.list(null, null, "name");

    this.setState(state => ({
      ...state,
      loading: false,
      loaded: true,
      workflows: list,
      total,
    }));
  }

  @handle(AddWorkflowAction, FormErrorHandling)
  private async handleAddWorkflowAction(action: AddWorkflowAction) {
    action.form.validate();

    const model = clean(action.form.updatedModel());
    const newWf = await this.workflowRepo.post(model, action.objectType);
    action.saved = newWf;

    await dispatch(new EnsureWorkflowsAction());
    this.setState(state => ({
      ...state,
      workflows: [ ...state.workflows, newWf ],
    }));
  }

  @handle(UpdateWorkflowAction, FormErrorHandling)
  private async handleUpdateWorkflowAction(action: UpdateWorkflowAction) {
    action.form.validate();

    const model = clean(action.form.updatedModel());
    const updatedWf = await this.workflowRepo.put(model);
    action.saved = updatedWf;

    const existing = this.state.workflows.find(a => a.Id == updatedWf.Id);
    if (existing) {
      Object.assign(existing, updatedWf);
    }

    this.setState(state => ({
      ...state,
      workflowHash: {
        ...state.workflowHash,
        [updatedWf.Id]: updatedWf,
      },
    }));
  }

  @handle(EnsureWorkflowAction)
  private async handleEnsureWorkflowAction(action: EnsureWorkflowAction) {
    if (this.state.workflowHash[action.id]) return;

    // check to see if we have it in the list first
    const workflowInList = this.state.workflows.find(wf => wf.Id == action.id);
    if (workflowInList) {
      this.setState(state => ({
        ...state,
        workflowHash: {
          ...state.workflowHash,
          [action.id]: workflowInList,
        },
      }));

      return;
    }

    const workflow = await this.workflowRepo.get(action.id);

    this.setState(state => ({
      ...state,
      workflowHash: {
        ...state.workflowHash,
        [action.id]: workflow,
      },
    }));
  }

  @handle(DeleteWorkflowsAction)
  private async handleDeleteWorkflowsAction(action: DeleteWorkflowsAction) {
    const workflows = this.state.workflows.filter(t =>
      action.ids.some(id => id == t.Id),
    );

    await this.workflowRepo.del(workflows.map(t => t.Id));
    this.setState(state => ({
      ...state,
      workflows: difference(state.workflows, workflows),
    }));
  }
}

