import { needs, inject, prop } from "fw";

import { PopoverController } from "service/popover";

import { ItemSearcher, LoadDataContext, SearchResults } from "./item-searcher";
import { TeamRepository } from "network/team-repository";
import { Team } from "models/team";

@inject
@needs(ItemSearcher)
export class SelectTeamPopover {
  private filter: string = null;

  public constructor(
    private teamRepo: TeamRepository,
    private controller: PopoverController<Team>
  ) { }

  public activate(params: { filter: string }) {
    if (!params) {
      return;
    }

    this.filter = params.filter;
  }

  public loadData(context: LoadDataContext): Promise<SearchResults<Team>> {
    const { filter, search, sort, page, pageSize, fields } = context;
    return this.teamRepo.list(filter, search, sort, page, pageSize, fields);
  }

  public select(team: Team) {
    this.controller.ok(team);
  }
}
