export type LatLong = { lat: number, long: number };

export type MarkerPoint<T> = LatLong & { total: number, data?: T };

export type BoundingBox = { ne: LatLong; sw: LatLong };

export type MapState = {
  bounding?: BoundingBox;
  center: LatLong;
  zoom: number;
  maxTotal: number;
};

export const DEFAULT_MAPSTATE: MapState = {
  center: { lat: 38.5, long: -95.6 },
  zoom: 4,
  maxTotal: null,
};

export const getPrecisionFromZoom = (zoom: number) => {
  if (zoom >= 1 && zoom < 7) {
    return 3;
  } else if (zoom >= 7 && zoom < 9) {
    return 4;
  } else if (zoom >= 9 && zoom < 11) {
    return 5;
  } else {
    return 6;
  }
};

export const boundingBoxToElasticFilter = (bb: BoundingBox) => {
  const nwLl = `${bb.ne.lat},${bb.sw.long}`;
  const seLl = `${bb.sw.lat},${bb.ne.long}`;

  return `[${nwLl} TO ${seLl}]`;
};
