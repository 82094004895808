import { fromClass, fromClassArray } from "fw-model";

import { ExportDeliveryOptions } from "./export-task";
import { TimeOfDay } from "./calendar-event";

export enum ScheduleRecurrenceType {
  Once = -1,
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  CustomFrequency = 3
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum ExportRecordFrequency {
  Always = 0,
  OnlyOnce = 1,
  OnChange = 2
}

export class DailyScheduleOptions {
  HourTimes: number[] = [0];
}

export class WeeklyScheduleOptions {
  HourTimes: number[] = [0];
  DaysOfWeek: DayOfWeek[] = [];
}

export class MonthlyScheduleOptions {
  HourTimes: number[] = [0];
  Days: number[] = [];
}

export class CustomFrequencyScheduleOptions {
  Interval: string = "00:15:00";
  StartTime: TimeOfDay = null;
  EndTime: TimeOfDay = null;
}

export class Schedule {
  StartDate: string = null;
  EndDate: string = null;
  Type = ScheduleRecurrenceType.Daily;

  @fromClass Daily: DailyScheduleOptions;
  @fromClass Weekly: WeeklyScheduleOptions;
  @fromClass Monthly: MonthlyScheduleOptions;
  @fromClass CustomFrequency: CustomFrequencyScheduleOptions;
}

export type EventNotificationEvent = "Success" | "Failure";

export class EventNotification {
  EmailAddress: string = null;
  SubscribedEvents: EventNotificationEvent[] = ["Success", "Failure"];
}

export class ScheduledExportMetaData {
  OrganizationId: string;
  SeasonId: string;
  ActorType: string;
  ActorId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;

  LastRunDateUtc: string;
  NextRunDateUtc: string;
}

export class ScheduledExport {
  Id: string;
  Name: string = null;
  SegmentId: string = null;
  ExportDefinitionId: string = null;
  IsEnabled: boolean = true;
  ExportFrequency: ExportRecordFrequency = ExportRecordFrequency.Always;
  WatchFieldPath: string = null;

  @fromClass Schedule: Schedule;
  @fromClass Delivery: ExportDeliveryOptions;
  @fromClass MetaData: ScheduledExportMetaData;
  @fromClassArray(EventNotification) Notifications: EventNotification[];
}

export const cleanSchedule = (schedule: Schedule, hasStartEndDateSupport: boolean = false) => {
  switch (schedule.Type) {
    case ScheduleRecurrenceType.Once:
      if (!hasStartEndDateSupport) {
        schedule.EndDate = null;
      }

      schedule.Daily = null;
      schedule.Monthly = null;
      schedule.Weekly = null;
      schedule.CustomFrequency = null;
      break;

    case ScheduleRecurrenceType.Daily:
      if (!hasStartEndDateSupport) {
        schedule.StartDate = null;
        schedule.EndDate = null;
      }

      schedule.Monthly = null;
      schedule.Weekly = null;
      schedule.CustomFrequency = null;
      break;

    case ScheduleRecurrenceType.Monthly:
      if (!hasStartEndDateSupport) {
        schedule.StartDate = null;
        schedule.EndDate = null;
      }

      schedule.Daily = null;
      schedule.Weekly = null;
      schedule.CustomFrequency = null;
      break;

    case ScheduleRecurrenceType.Weekly:
      if (!hasStartEndDateSupport) {
        schedule.StartDate = null;
        schedule.EndDate = null;
      }

      schedule.Daily = null;
      schedule.Monthly = null;
      schedule.CustomFrequency = null;
      break;

    case ScheduleRecurrenceType.CustomFrequency:
      schedule.Daily = null;
      schedule.Monthly = null;
      schedule.Weekly = null;
      delete schedule.CustomFrequency.StartTime;
      delete schedule.CustomFrequency.EndTime;
      break;
  }
};
