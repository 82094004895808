import { prop } from "fw";
import { ContactsPostalCodeFilter as ContactsPostalCodeFilterModel } from "models/contacts-filters";

export class ContactsPostalCodeFilter {
  @prop(null) filter!: ContactsPostalCodeFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  public get valueText() {
    const value = !this.filter.value || this.filter.value === "__missing__" 
      ? "is empty" 
      : `= "${this.filter.value}"`;
    return value;
  }
}
