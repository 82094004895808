import { FormForType, formFor, Validators } from "fw-model";
import { MarketingOutreachTier } from "models/marketing-outreach-tier";

export type FormForMarketingOutreachTier = FormForType<MarketingOutreachTier>;

export class CreateMarketingOutreachTierAction {
  public created: MarketingOutreachTier = null;
  constructor(public form: FormForMarketingOutreachTier) {}
}

export class UpdateMarketingOutreachTierAction {
  public updated: MarketingOutreachTier = null;
  constructor(public form: FormForMarketingOutreachTier) {}
}

export class CopyMarketingOutreachTierAction {
  constructor(public id: number) {}
}

export class DeleteMarketingOutreachTierAction {
  constructor(public id: number) {}
}

export const createMarketingOutreachTierFormCreator = formFor(MarketingOutreachTier, s => {
  s.requiredField(f => f.OutreachTrackId, "Track");
  s.field(f => f.Order, "Order");
  s.field(f => f.Repeat, "Repeat");
  s.field(f => f.SendOn, "Send on date");
  s.field(f => f.Time, "Send on time");
});

export const updateMarketingOutreachTierFormCreator = formFor(MarketingOutreachTier, s => {
  s.requiredField(f => f.Id, "Id");
  s.requiredField(f => f.OutreachTrackId, "Track");
  s.field(f => f.Order, "Order");
  s.field(f => f.Repeat, "Repeat");
  s.field(f => f.SendOn, "Send on date");
  s.field(f => f.Time, "Send on time");
});
