import { inject } from "fw";
import { PopoverController } from "service/popover";
import { UserNotificationSettingsFormType } from "forms/user";

@inject
export class EmailSettings {
  private userNotificationSettingsForm: UserNotificationSettingsFormType = null;
  private frequencyOptions = [
    { value: false, text: "Each time I have a notification" },
    { value: true, text: "Send a daily summary" },
  ];

  constructor(private controller: PopoverController<UserNotificationSettingsFormType>) { }

  activate(params) {
    this.userNotificationSettingsForm = params.form;
  }

  ok() {
    this.controller.ok(this.userNotificationSettingsForm);
  }

  cancel() {
    this.controller.cancel();
  }
}
