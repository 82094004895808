import { inject } from "fw";
import moment from "moment/moment";
import { CurrentOrganizationStore } from "state/current-organization";

@inject
export class TimezoneAbbreviation {

  constructor(public currentOrganizationStore: CurrentOrganizationStore) {}

  public get organizationTimezoneAbbreviation(): any {
    return moment.tz(this.currentOrganizationStore.state.organization.Timezone).zoneAbbr();
  }
}
