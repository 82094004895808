import { RawFilter } from "./application-filters/raw-filter";

import { KeywordFilter } from "./application-filters/keyword-filter";
import { FormFieldFilter } from "./application-filters/form-field-filter";
import { ReferenceCountFilter } from "./application-filters/reference-count-filter";
import { ProgramFilter } from "./application-filters/program-filter";
import { OriginFilter } from "./application-filters/origin-filter";
import { ApplicationSourceFilter } from "./application-filters/application-source-filter";
import { SegmentFilter } from "./application-filters/segment-filter";
import { PhaseFilter } from "./application-filters/phase-filter";
import { PhaseFilterWithCategories } from "./application-filters/phase-filter-with-categories";
import { AssignedUserFilter } from "./application-filters/assigned-user-filter";
import { EvaluatedByFilter } from "./application-filters/evaluated-by-filter";
import { MyCompletedEvaluationsFilter } from "./application-filters/my-completed-evaluations-filter";
import { MyIncompletedEvaluationsFilter } from "./application-filters/my-incompleted-evaluations-filter";
import { EvaluationCompleteFilter } from "./application-filters/evaluation-complete";
import { ApplicationPropertyFilter } from "./application-filters/application-property-filter";
import { PhaseCalculatedFieldFilter } from "./application-filters/phase-calculated-field-filter";
import { CollaborationCalculatedFieldFilter } from "./application-filters/collaboration-calculated-field-filter";
import { CollaborationAssignedUserFilter } from "./application-filters/collaboration-assigned-user-filter";
import { CollaborationEvaluatedByFilter } from "./application-filters/collaboration-evaluated-by-filter";
import { LastUpdatedFilter } from "./application-filters/last-updated-filter";
import { DateStageChangedFilter } from "./application-filters/date-stage-changed-filter";
import { DatePhaseChangedFilter } from "./application-filters/date-phase-changed-filter";
import { DateDecisionChangedFilter } from "./application-filters/date-decision-changed-filter";

import { ApplicantPropertyFilter } from "./application-filters/applicant-property-filter";
import { ErrorsFilter } from "./application-filters/errors-filter";
import { TagFilter } from "./application-filters/tag-filter";
import { CommentFilter } from "./application-filters/comment-filter";
import { ProgramStageFilter } from "./application-filters/program-stage-filter";
import { ProgramPropertyFilter } from "./application-filters/program-property-filter";
import { DecisionFilter } from './application-filters/decision-filter';
import { ApplicationFilter } from "./application-filters/application-filter";
import { DecisionLetterFilter } from "./application-filters/decision-letter-filter";
import { DecisionLetterRevealedFilter } from "./application-filters/decision-letter-revealed-filter";

import { ContactsApplicationGroupFilter } from "./contacts-filters/contacts-application-group-filter";
import { ContactsActivityGroupFilter } from "./contacts-filters/contacts-activity-group-filter";
import { NestedGroupFilter } from "./contacts-filters/nested-group-filter";
import { ContactsEventFilter } from "./contacts-filters/contacts-event-filter";
import { ContactsContactNumberFilter } from "./contacts-filters/contacts-contact-number-filter";
import { ContactsSegmentFilter } from "./contacts-filters/contacts-segment-filter";
import { ContactsKeywordFilter } from "./contacts-filters/contacts-keyword-filter";
import { ContactsEmailFilter } from "./contacts-filters/contacts-email-filter";
import { ContactsDateFilter } from "./contacts-filters/contacts-date-filter";
import { ContactsNameFilter } from "./contacts-filters/contacts-name-filter";
import { ContactsKeyValueFilter } from "./contacts-filters/contacts-key-value-filter";
import { ContactsStringFilter } from "./contacts-filters/contacts-string-filter";
import { ContactsAddressFilter } from "./contacts-filters/contacts-address-filter";
import { ContactsOperatorFilter } from "./contacts-filters/contacts-operator-filter";
import { ContactsOptionFilter } from "./contacts-filters/contacts-option-filter";
import { ContactsLinkFilter } from "./contacts-filters/contacts-link-filter";
import { ContactsMultiselectFilter } from "./contacts-filters/contacts-multiselect-filter";
import { ContactsTagsFilter } from "./contacts-filters/contacts-tags-filter";
import { ContactsRelationshipFilter } from "./contacts-filters/contacts-relationship-filter";
import { ContactsUserFilter } from "./contacts-filters/contacts-user-filter";
import { ContactsDataSourceInstanceFilter } from "./contacts-filters/contacts-data-source-instance-filter";
import { ContactsSlideroomAdmissionsApplicationsFilter } from "./contacts-filters/contacts-slideroom-admissions-applications-filter";
import { ContactsSlideroomApplicationsFilter } from "./contacts-filters/contacts-slideroom-applications-filter";
import { ContactsTestScoreFilter } from "./contacts-filters/contacts-test-score-filter";
import { ContactsPostalCodeFilter } from "./contacts-filters/contacts-postal-code-filter";
import { ContactsCountryFilter } from "./contacts-filters/contacts-country-filter";
import { ContactsErrorsFilter } from "./contacts-filters/contacts-errors-filter";

import { ActivityBusinessValueFilter } from "./activity-filters/activity-business-value-filter";
import { ActivityDescriptionFilter } from "./activity-filters/activity-description-filter";
import { ActivityGroupFilter } from "./activity-filters/activity-group-filter";
import { ActivitySourceFilter } from "./activity-filters/activity-source-filter";
import { ActivityTitleFilter } from "./activity-filters/activity-title-filter";

import { TaskDescriptionFilter } from "./task-filters/task-description-filter";
import { TaskDateDueFilter } from "./task-filters/task-date-due-filter";
import { TaskDateRangeFilter } from "./task-filters/task-date-range-filter";
import { TaskDateCompletedFilter } from "./task-filters/task-date-completed-filter";
import { TaskDateLastAssignedFilter } from "./task-filters/task-date-last-assigned-filter";
import { TaskTagFilter } from "./task-filters/task-tag-filter";
import { TaskAssignedUserFilter } from "./task-filters/task-assigned-user-filter";
import { TaskTargetTypeFilter } from "./task-filters/task-target-type-filter";

import { CalendarEventLocationFilter } from "./calendar-event-filters/calendar-event-location-filter";
import { CalendarEventDescriptionFilter } from "./calendar-event-filters/calendar-event-description-filter";
import { CalendarEventTypeFilter } from "./calendar-event-filters/calendar-event-type-filter";
import { CalendarEventTagsFilter } from "./calendar-event-filters/calendar-event-tags-filter";
import { CalendarEventDepartmentFilter } from "./calendar-event-filters/calendar-event-department-filter";
import { CalendarEventDateFilter } from "./calendar-event-filters/calendar-event-date-filter";
import { DateStartedFilter } from "./application-filters/date-started-filter";
import { SubmissionStatusFilter } from "./application-filters/submission-status-filter";
import { PaymentStatusFilter } from "./application-filters/payment-status-filter";

export default [
  RawFilter,

  KeywordFilter,
  FormFieldFilter,
  ReferenceCountFilter,
  ProgramFilter,
  OriginFilter,
  ApplicationSourceFilter,
  SegmentFilter,
  PhaseFilter,
  PhaseFilterWithCategories,
  AssignedUserFilter,
  EvaluatedByFilter,
  MyCompletedEvaluationsFilter,
  MyIncompletedEvaluationsFilter,
  EvaluationCompleteFilter,
  ApplicationPropertyFilter,
  PhaseCalculatedFieldFilter,
  CollaborationCalculatedFieldFilter,
  CollaborationAssignedUserFilter,
  CollaborationEvaluatedByFilter,
  LastUpdatedFilter,
  DateStartedFilter,
  DateStageChangedFilter,
  DatePhaseChangedFilter,
  DateDecisionChangedFilter,
  SubmissionStatusFilter,
  PaymentStatusFilter,

  ApplicantPropertyFilter,
  ErrorsFilter,
  TagFilter,
  CommentFilter,
  ProgramStageFilter,
  ProgramPropertyFilter,
  DecisionFilter,
  ApplicationFilter,
  DecisionLetterFilter,
  DecisionLetterRevealedFilter,

  ContactsApplicationGroupFilter,
  ContactsActivityGroupFilter,
  NestedGroupFilter,
  ContactsEventFilter,
  ContactsContactNumberFilter,
  ContactsSegmentFilter,
  ContactsKeywordFilter,
  ContactsDateFilter,
  ContactsEmailFilter,
  ContactsNameFilter,
  ContactsKeyValueFilter,
  ContactsStringFilter,
  ContactsAddressFilter,
  ContactsOperatorFilter,
  ContactsOptionFilter,
  ContactsLinkFilter,
  ContactsMultiselectFilter,
  ContactsTagsFilter,
  ContactsRelationshipFilter,
  ContactsUserFilter,
  ContactsDataSourceInstanceFilter,
  ContactsSlideroomAdmissionsApplicationsFilter,
  ContactsSlideroomApplicationsFilter,
  ContactsTestScoreFilter,
  ContactsPostalCodeFilter,
  ContactsCountryFilter,
  ContactsErrorsFilter,

  ActivityBusinessValueFilter,
  ActivityDescriptionFilter,
  ActivityGroupFilter,
  ActivitySourceFilter,
  ActivityTitleFilter,

  TaskAssignedUserFilter,
  TaskDateCompletedFilter,
  TaskDateDueFilter,
  TaskDateRangeFilter,
  TaskDateLastAssignedFilter,
  TaskDescriptionFilter,
  TaskTagFilter,
  TaskTargetTypeFilter,

  CalendarEventLocationFilter,
  CalendarEventDescriptionFilter,
  CalendarEventTypeFilter,
  CalendarEventTagsFilter,
  CalendarEventDepartmentFilter,
  CalendarEventDateFilter,
];