import { inject, Route, Navigator } from "fw";

import { Permissions } from "service/permission";

@inject
export class RequirePermission {
  constructor(private permissions: Permissions,
    private nav: Navigator) { }

  navigating(r: Route, fullRoute: string) {
    const requiresRoles = r.data && r.data.permissions;

    if (requiresRoles && !this.permissions.all(r.data.permissions)) {
      this.nav.navigate("/no-permission", { redirect: fullRoute });
      return false;
    }

    return true;
  }
}
