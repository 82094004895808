import { conditionInfoFormCreator, ConditionInfoFormType } from "forms/condition-info";
import { inject, needs } from "fw";
import { DialogController } from "fw-dialog";
import { DataDictionaryField } from "models/data-dictionary";
import { ConditionInfo } from "models/form";
import { DataDictionaryService } from "service/data-dictionary";
import { ConditionBuilder } from "views/components/condition-builder";
import { Notification } from "service/notification";
import { Completer } from "helpers/auto-complete";
import { ConditionBuilderService } from "service/condition-builder";

export interface ConditionInfoDialogResult {
  condition: string;
  conditionInfo: ConditionInfo;
}

export interface ConditionBuilderDialogParams {
  completer: Completer | Completer[];
  condition: string;
  conditionInfo: ConditionInfo;
  fields: DataDictionaryField[];
  disabled: boolean;
  showClear: boolean;
  showFunctionSelector: boolean;
  showFieldDictionaryLink: boolean;
  expressionModeOnly: boolean;
}

@inject
@needs(ConditionBuilder)
export class ConditionBuilderDialog {
  public fields: DataDictionaryField[] = [];
  public completer: Completer | Completer[] = null;
  public conditionInfoForm: ConditionInfoFormType = null;
  public loading: boolean = false;
  public disabled: boolean = false;
  public showClear: boolean = true;
  public showFunctionSelector: boolean = true;
  public showFieldDictionaryLink: boolean = true;
  public expressionModeOnly: boolean = false;

  private originalCondition: string = null;
  private originalConditionInfo: ConditionInfo = null;

  constructor(
    private controller: DialogController<ConditionInfoDialogResult>,
    private dataDictionaryService: DataDictionaryService,
    private notify: Notification,
    private conditionBuilderService: ConditionBuilderService
  ) { }

  public async activate(params: ConditionBuilderDialogParams) {
    this.completer = params.completer;
    if (params.disabled != null) {
      this.disabled = params.disabled === true;
    }
    if (params.showClear != null) {
      this.showClear = params.showClear === true;
    }
    if (params.showFunctionSelector != null) {
      this.showFunctionSelector = params.showFunctionSelector === true;
    }
    if (params.showFieldDictionaryLink != null) {
      this.showFieldDictionaryLink = params.showFieldDictionaryLink === true;
    }

    this.expressionModeOnly = !!params.expressionModeOnly;
    if (params.fields) {
      this.fields = params.fields
    } else {
      try {
        this.loading = true;
        this.fields = await this.dataDictionaryService.getFieldsByCategory(false, true, true, false, false, false);
      } catch (ex) {
        this.notify.error("Error loading condition builder. Please try again.");
        this.close();
        return;
      } finally {
        this.loading = false;
      }
    }

    this.conditionInfoForm = conditionInfoFormCreator(params.conditionInfo);

    this.originalCondition = params.condition;
    this.originalConditionInfo = params.conditionInfo;
  }

  public close() {
    this.controller.cancel();
  }

  public async save() {
    try {
      this.conditionInfoForm.validate();

      const conditionInfo: ConditionInfo = this.conditionInfoForm.updatedModel();
      const condition: string = await this.conditionBuilderService.generateExpression(this.originalCondition, this.originalConditionInfo, conditionInfo);
      this.controller.ok({ condition, conditionInfo });
    } catch (err) { }
  }
}
