export class GoalDefinition {
  public Id: string;
  public Name: string;
  public SegmentId: string;
  public TargetNumber: number;
  public GoalType: GoalType;
  public StartDateUtc: Date;
  public EndDateUtc: Date;
  public MetaData: GoalMetaData;
}

export class GoalMetaData {
  public ContactType: string;
}

export type GoalValueSet = {
  [segmentId: string]: number,
};

export enum GoalType{
  Application = 0,
  Contact = 1,
 }
