import { ComponentEventBus, inject, prop, needs } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { FieldMode } from "../field-mode";
import { LazyUserNames } from "views/components/lazy-user-names";
import { UserSelector } from "../user-selector";
import { LazyUser } from "views/components/lazy-user";

@inject
@needs(LazyUserNames, UserSelector, LazyUser)
export class UserType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string|string[];
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public isFilter!: boolean;

  constructor(private ceb: ComponentEventBus) {}

  public get localValue(): string[] {
    if (this.isFilter && Array.isArray(this.value)) {
      return this.value;
    }

    return this.value ? [<string>this.value] : [];
  }
  
  public get fieldModeList() {
    return FieldMode;
  }

  public set localValue(value: string[]) {
    // NOTE: Control is also used by filters which need to return multiple user ids.
    if (this.isFilter) {
      this.ceb.dispatch("update:value", value);
    } else {
      this.ceb.dispatch("update:value", Array.isArray(value) ? value[0] : value);
    }
  }
}
