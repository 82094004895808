import { inject, prop, ComponentEventBus } from "fw";
import { dispatch } from "fw-state";

import type {
  ContactTypeDataPolicyFormType,
  ContactRoleSettingsFormType,
} from "forms/contact-organization";

import { FeatureFlagService } from "service/feature-flag";
import { PopoverService } from "service/popover";

import { ContactSegmentStore, EnsureContactSegmentStoreAction } from "state/contact-segments";
import { CurrentContactOrganizationStore  } from "state/current-contact-organization";

import { PermissionTooltip } from "./permission-tooltip";
import {
    ContactDataRestrictionPopover
} from "views/settings/components/contact-data-restrictions/contact-data-restriction-popover";

@inject
export class ContactRestrictions {
    @prop(null) public form!: ContactRoleSettingsFormType;
    @prop(false) public canEdit: boolean;

    private loading: boolean = true;
    public invalidRestrictions: string[] = [];

    // contact type names
    private contactTypeHash: { [key: string]: string } = {};
    // contact field names
    private contactFieldNameHash: { [id: string]: string } = {};
    // contact field names
    private contactTypeFieldCounts: { [key: string]: number } = {};
    // contact segment names
    private contactSegmentHash: { [id: string]: string } = {};

  constructor(
    private ceb: ComponentEventBus,
    private popoverService: PopoverService,
    private ffs: FeatureFlagService,
    private contactSegmentStore: ContactSegmentStore,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
  ) { }

  private async getInvalidRestrictions(): Promise<string[]> {
    const invalid: string[] = [];
    // todo: resolve invalid restrictions here (i.e. deleted fields in the restricted field ids collection, etc.)
    return invalid;
  }

  public async attached() {
    
    await dispatch(new EnsureContactSegmentStoreAction());
    this.contactSegmentStore.state.segments.forEach(s => (this.contactSegmentHash[s.id] = s.display_name));

    const { contact_types, fields } = this.currentContactOrganizationStore.state.organization;
    
    contact_types.forEach(c => (this.contactTypeHash[c.key] = c.name));
    fields.forEach(f => {
      if (f.is_system_field)
        return;

      this.contactFieldNameHash[f.id] = f.display_name;
      if (!this.contactTypeFieldCounts[f.contact_type]) {
        this.contactTypeFieldCounts[f.contact_type] = 1;
        return;
      }
      this.contactTypeFieldCounts[f.contact_type] += 1;
    });

    // NOTE: this is actually a clone of a clone for an existing role;  see above NOTE
    this.invalidRestrictions = await this.getInvalidRestrictions();
    this.loading = false;
  }
  

  

  async addRestriction(dataPolicy, selectedType: string) {
    const popover = await this.popoverService.open<ContactTypeDataPolicyFormType>(
      ContactDataRestrictionPopover,
      { form: dataPolicy, selectedType }
    );
    if (popover.canceled || !popover.result)
      return;

    dataPolicy = popover.result;
    this.ceb.dispatch("update:form", this.form);
  }

  removeField(index: number, dataPolicy) {
    dataPolicy.restricted_field_ids.splice(index,1);
  }
}
