import { inject, prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import {
  type ITestScoreContainer,
  TestScoringMode,
  ITestScoreInfo,
  getSections,
  getSectionScore,
  getSectionMaxScore
} from "models/testscore";
import { FieldMode } from "views/contacts/components/field-mode";

@inject
export class TestscoreType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: ITestScoreContainer;
  @prop(FieldMode.View) public mode!: FieldMode;
  
  public fieldModes = FieldMode;

  public get gridValue(): string {
    return (this.value?.Scores?.length > 0
      ? `${this.value.Scores.length} ${ this.value.Scores.length === 1 ? "value" : "values" }`
      : null);
  }

  public get type(): string {
    if (this.field == null) {
      return null;
    }

    return this.field.data["test_score_type"];
  }

  public get scoringMode(): TestScoringMode {
    if (this.field == null) {
      return TestScoringMode.All;
    }

    return this.field.data["test_score_scoring_mode"] || TestScoringMode.All;
  }

  public get showMaxScore(): boolean {
    const mode = this.scoringMode;
    return (
      mode !== TestScoringMode.BestSitting &&
      mode !== TestScoringMode.Superscore
    );
  }

  public get hasCalculatedScoreSections(): boolean {
    return this.value?.CalculatedScore?.Sections != null;
  }

  public get sections() {
    return Array.from(getSections(this.field, this.scores));
  }

  public getCalculatedSectionScore(section: string): number {
    const sections = this.value.CalculatedScore.Sections;
    return sections[
      Object.keys(sections).find(
        (key) => key.toLowerCase() === section.toLowerCase()
      )
    ];
  }

  public getSectionScore(score: ITestScoreInfo, section: string): number {
    return getSectionScore(this.scores, score, section);
  }

  public getSectionMaxScore(section: string): number {
    return getSectionMaxScore(this.scores, section);
  }

  public get scores(): ITestScoreInfo[] {
    return this.value?.Scores || [];
  }
}
