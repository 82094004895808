import { escapeTerm, getOps } from "helpers/filter";
import { DateFilter, dateToFilterPaths, dateToFilterString, timeRangeFilter } from "./date-filter";
import { registerFilter } from "./filter-setup";
export enum DateDueFilterType {
  Any = "",
  Overdue = "overdue",
  DueToday = "dueToday",
  DueSoon = "dueSoon",
  Today = "today",
  Tomorrow = "tomorrow",
  Next7Days = "week",
  NextXDays = "nextX",
  CurrentMonth = "month",
  CurrentYear = "year",
  DayRange = "day",
  DateRange = "date"
}

export enum TaskListDueDateFilter {
  Overdue = "isCompleted:false dateDueUtc:<now/d",
  "Due Today" = "isCompleted:false dateDueUtc:>=now/d dateDueUtc:<now+1d/d",
  "Due Soon" = "isCompleted:false dateDueUtc:>=now/d dateDueUtc:<now+7d/d"
}

export class TaskDescriptionFilter {
  description: string = null;
  operator: string = "=";
}

export class TaskDateDueFilter {
  timing: string = "";
  startDate?: string = null;
  endDate?: string = null;
  daysFrom?: number = null;
  daysTo?: number = null;
  marker?: Array<string> = null;
}

export class TaskDateRangeFilter extends DateFilter {
  startDate?: string = null;
  endDate?: string = null;
  daysFrom?: number = null;
  daysTo?: number = null;
  marker?: Array<string> = null;
}

export class TaskDateCompletedFilter extends DateFilter {
  title: string = "Date Completed";
  field: string = "metaData.dateCompletedUtc";
}

export class TaskDateLastAssignedFilter extends DateFilter {
  title: string = "Last Assigned";
  field: string = "metaData.dateLastAssignedUtc";
}

export class TaskTagFilter {
  mode: "any" | "all" | "none" = "any";
  tags: string[] = [];
}

export class TaskAssignedUserFilter {
  userId: string = null;
}

export class TaskTargetTypeFilter {
  targetType: "Application" | "Contact" = null;
}

export const registerTaskFilters = () => {
  registerFilter(TaskDescriptionFilter, f => {
    f.toFilterString(data => {
      if (!data.description) {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      return `${preOp}description:${op}"${escapeTerm(data.description)}"`;
    });
    f.toFilterTerms(data => [{ term: "description" }]);
  });

  registerFilter(TaskDateDueFilter, f => {
    f.toFilterString(filter => {

      if (filter.marker?.length) {
        return timeRangeFilter(filter);
      }

      switch (filter.timing) {
        case DateDueFilterType.Overdue:
          return TaskListDueDateFilter.Overdue;

        case DateDueFilterType.DueToday:
          return TaskListDueDateFilter["Due Today"];

        case DateDueFilterType.DueSoon:
          return TaskListDueDateFilter["Due Soon"];

        case DateDueFilterType.Today:
          return `dateDueUtc:>=now/d dateDueUtc:<now+1d/d`;

        case DateDueFilterType.Tomorrow:
          return `dateDueUtc:[now+1d/d TO now+2d/d]`;

        case DateDueFilterType.Next7Days:
          return `dateDueUtc:>=now/d dateDueUtc:<now+7d/d`;

        case DateDueFilterType.CurrentMonth:
          return `dateDueUtc:>=now/M`;

        case DateDueFilterType.CurrentYear:
          return `dateDueUtc:>=now/y`;

        case DateDueFilterType.NextXDays:
        case DateDueFilterType.DayRange:
          // when daysFrom is blanked out it will be typeof string instead of number
          if (typeof filter.daysFrom == "number" && filter.daysFrom >= 0) {
            if (filter.daysTo > 0) {
              return `dateDueUtc:[now+${filter.daysFrom}d/d TO now+${filter.daysTo}d/d}`;
            } else {
              return `dateDueUtc:[now/d TO now+${filter.daysFrom}d]`;
            }
          }
          break;

        case DateDueFilterType.DateRange:
          if (filter.startDate && filter.endDate) {
            return `dateDueUtc:[${filter.startDate} TO ${filter.endDate}]`;
          }
          break;
      }
      return "";
    });
    f.toFilterTerms(data => {
      if (data.marker?.length) {
        return [];
      }

      switch (data.timing) {
        case DateDueFilterType.Overdue:
        case DateDueFilterType.DueToday:
        case DateDueFilterType.DueSoon:
          return [{ term: "isCompleted" }, { term: "dateDueUtc" }];

        case DateDueFilterType.Today:
        case DateDueFilterType.Tomorrow:
        case DateDueFilterType.Next7Days:
        case DateDueFilterType.CurrentMonth:
          return [{ term: "dateDueUtc" }];

        case DateDueFilterType.NextXDays:
        case DateDueFilterType.DayRange:
          // when daysFrom is blanked out it will be typeof string instead of number
          if (typeof data.daysFrom == "number" && data.daysFrom >= 0) {
            return [{ term: "dateDueUtc" }];
          }
          break;

        case DateDueFilterType.DateRange:
          if (data.startDate && data.endDate) {
            return [{ term: "dateDueUtc" }];
          }
          break;
      }
      return [];
    });
  });

  registerFilter(TaskDateRangeFilter, f => {
    f.toFilterString(dateToFilterString);
    f.toFilterTerms(dateToFilterPaths);
  });

  registerFilter(TaskDateCompletedFilter, f => {
    f.toFilterString(dateToFilterString);
    f.toFilterTerms(dateToFilterPaths);
  });

  registerFilter(TaskDateLastAssignedFilter, f => {
    f.toFilterString(dateToFilterString);
    f.toFilterTerms(dateToFilterPaths);
  });

  registerFilter(TaskTagFilter, s => {
    s.toFilterString(data => {
      if (data.tags == null || data.tags.length == 0) return "";

      const tags = data.tags.map(v => `"${v.replace(/\"/g, "\\\"")}"`)
        .join(data.mode == "all" ? " AND " : " OR ");
      return data.mode == "none" ? `-(tags:(${tags}))` : `(tags:(${tags}))`;
    });
    s.toFilterTerms(data => [{ term: "tags" }]);
  });

  registerFilter(TaskAssignedUserFilter, s => {
    s.toFilterString(data => {
      return data.userId ? `assignedToUserId:${data.userId}` : "";
    });
    s.toFilterTerms(data => [{ term: "assignedToUserId" }]);

    s.validate((v, data) => {
      v.required(d => d.userId);
    });
  });

  registerFilter(TaskTargetTypeFilter, s => {
    s.toFilterString(data => {
      return data.targetType ? `targetType:${data.targetType}` : "";
    });
    s.toFilterTerms(data => [{ term: "targetType" }]);

    s.validate((v, data) => {
      v.required(d => d.targetType);
    });
  });
}
