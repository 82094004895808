import { prop, ComponentEventBus, inject } from "fw";
import { padStart } from "lodash-es";

let uniqueId = 0;

@inject
export class DropdownTimeField {
  @prop(false) private nullIsDirty;
  @prop(null) private label;
  @prop(null) private value;
  @prop(false) private disabled;
  @prop(null) private validation;

  private hourOptions = [
    {text: '12', value: '0'},
    {text: '1', value: '1'},
    {text: '2', value: '2'},
    {text: '3', value: '3'},
    {text: '4', value: '4'},
    {text: '5', value: '5'},
    {text: '6', value: '6'},
    {text: '7', value: '7'},
    {text: '8', value: '8'},
    {text: '9', value: '9'},
    {text: '10', value: '10'},
    {text: '11', value: '11'}
  ];

  private minuteOptions = [
    {text: '-', value: '00'},
    {text: '15', value: '15'},
    {text: '30', value: '30'},
    {text: '45', value: '45'}
  ];

  private meridiemOptions = [
    {text: 'am', value: 'am'},
    {text: 'pm', value: 'pm'}
  ];

  private uniqueIds = {
    "hour": uniqueId++,
    "minute": uniqueId++,
    "meridiem": uniqueId++
  };
  private isFocused = false;
  private localValueHour = null;
  private localValueMinute = null;
  private localValueMeridiem = null;

  constructor(private componentEventBus: ComponentEventBus) {}

  private makeId(name) {
    return `${ this.label ? this.label.replace(/\s/g,'') : "" }-${ name }-sf-${ this.uniqueIds[name] }`
  }

  private onFocus() {
    this.isFocused = true;
  }

  private onBlur() {
    this.isFocused = false;
  }

  attached() {
    this.valueChanged();
  }

  valueChanged() {
    const value = typeof this.value == 'string' ? this.value.split(":") : "";
    const hour = parseInt(value[0]);
    this.localValueHour = hour > 11 ? hour - 12 : hour;
    this.localValueMinute = value.length > 1 ? value[1] : "00";
    this.localValueMeridiem = this.localValueHour > 11 ? "pm" : "am";
  }

  private onChange() {
    const hour = this.localValueMeridiem == "pm" ? this.localValueHour + 12 : this.localValueHour;
    const value = padStart(hour, 2, "0") + ":" + (this.localValueMinute || "00");
    this.componentEventBus.dispatch("update:value", value);
    this.componentEventBus.dispatch("change");
  }
}
