import { inject } from "fw";
import { Store, handle } from "fw-state";
import { LogoutAction, StartAction } from "./actions";
import { MarketingOutreachTrackRepository } from "network/marketing-outreach-track-repository";
import { MarketingOutreachTrack } from "models/marketing-outreach-track";
import {
  CreateMarketingOutreachTrackAction,
  UpdateMarketingOutreachTrackAction,
  CopyMarketingOutreachTrackAction,
  DeleteMarketingOutreachTrackAction
} from "forms/marketing-outreach-track";
import { FormErrorHandling } from "./error-handling";

interface CurrentTrackInfo {
  trackId: string;
  recipientCount: number;
}

interface OutreachTrackShape {
  loadedCampaigns: number[];
  collection: MarketingOutreachTrack[];
  currentTrack: CurrentTrackInfo
}

export class FetchMarketingOutreachTracksAction {
  constructor(public campaignId: number) {}
}

export class IndicateRefreshNeededAction {
  constructor(public trackId: number) {
  }
}

export class FetchMarketingOutreachTrackAction {
  constructor(public id: number, public needsTiers: boolean = false) {}
}

export class UpdateTrackRecipientCountAction {
  constructor(public trackId: string, public count: number) {}
}

@inject
export class MarketingOutreachTrackStore extends Store<OutreachTrackShape> {
  constructor(private network: MarketingOutreachTrackRepository) {
    super();
  }

  defaultState() {
    return { loadedCampaigns: [], collection: [], currentTrack: { trackId: null, recipientCount: 0 } };
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    this.setState(state => this.defaultState());
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(state => this.defaultState());
  }

  @handle(IndicateRefreshNeededAction)
  private handleIndicateRefreshNeeded(action: IndicateRefreshNeededAction) {
    const collection = this.state.collection.filter(c => c.OutreachCampaignId !== action.trackId);
    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(FetchMarketingOutreachTracksAction)
  private async handleFetchMarketingOutreachTracks(action: FetchMarketingOutreachTracksAction) {
    if (this.state.loadedCampaigns.find(i => i == action.campaignId)) return;

    const newItems = await this.network.index(action.campaignId);
    const loadedCompaigns = this.state.loadedCampaigns.slice();
    const collection = this.state.collection.slice();

    loadedCompaigns.push(action.campaignId);
    collection.push(...newItems.filter(i => ! collection.find(j => j.Id == i.Id)));
    this.setState(state => ({ ...state, loadedCampaigns: loadedCompaigns, collection: collection, forceRefresh: false }));
  }

  @handle(FetchMarketingOutreachTrackAction)
  private async handleFetchMarketingOutreachTrack(action: FetchMarketingOutreachTrackAction) {
    const track = this.state.collection.find(i => i.Id == action.id);

    if (track && (!action.needsTiers || track?.tiers.length)) return;

    const newItem = await this.network.show(action.id);
    let collection = this.state.collection.slice();

    if (action.needsTiers && !track?.tiers.length) {
      collection = collection.filter(c => c.Id != action.id);
    }

    collection.push(newItem);
    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(CreateMarketingOutreachTrackAction, FormErrorHandling)
  private async handleCreateMarketingOutreachTrack(action: CreateMarketingOutreachTrackAction) {
    action.form.validate();

    const newItem = await this.network.post(action.form.updatedModel());
    const collection = this.state.collection.slice();

    collection.push(newItem);
    this.setState(state => ({ ...state, collection: collection }));
    action.created = newItem;
  }

  @handle(UpdateMarketingOutreachTrackAction, FormErrorHandling)
  private async handleUpdateMarketingOutreachTrack(action: UpdateMarketingOutreachTrackAction) {
    action.form.validate();

    const updatedItem = await this.network.put(action.form.updatedModel());
    const collection = this.state.collection.slice();
    const currentItem = collection.find(i => i.Id == updatedItem.Id);

    collection[collection.indexOf(currentItem)] = updatedItem;
    this.setState(state => ({ ...state, collection: collection }));
    action.updated = updatedItem;
  }

  @handle(DeleteMarketingOutreachTrackAction)
  private async handleDeleteMarketingOutreachTrack(action: DeleteMarketingOutreachTrackAction) {
    const item = this.state.collection.find(i => i.Id == action.id);
    if (! item) return;

    await this.network.destroy(action.id);
    this.state.collection.splice(this.state.collection.indexOf(item), 1);
  }

  @handle(CopyMarketingOutreachTrackAction, FormErrorHandling)
  private async handleCopyMarketingOutreachTrack(action: CopyMarketingOutreachTrackAction) {
    if (! this.state.collection.find(i => i.Id == action.id)) return;

    const item = await this.network.copy(action.id);
    const collection = this.state.collection.slice();

    collection.push(item);
    this.setState(state => ({ ...state, collection: collection }));
  }

  @handle(UpdateTrackRecipientCountAction)
  private async handleUpdateTrackRecipientCount(action: UpdateTrackRecipientCountAction) {
    this.setState(state => ({ ...state, currentTrack: { trackId: action.trackId, recipientCount: action.count } }));
  }
}
