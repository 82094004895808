import { prop, inject } from "fw";
import { dispatch } from "fw-state";
import { EnsureTeamsAction, TeamsStore } from "state/teams";

@inject
export class LazyTeam {
  @prop(null) id!: string;
  @prop("div") rootType!: string;

  private isLoading = false;

  constructor(
    private teamsStore: TeamsStore,
  ) {}

  get loading() {
    if (this.team != null) return false;
    return this.isLoading;
  }

  get team() {
    if (this.id == null) return null;

    const { teamHash } = this.teamsStore.state;
    if (!teamHash[this.id]) {
      this.isLoading = true;
      dispatch(new EnsureTeamsAction([ this.id ]));
      return null;
    }

    return teamHash[this.id];
  }
}
