import { inject, NetworkException } from "fw";
import { allOf } from "helpers/permission";
import { AppRepository } from "network/app-repository";
import { CurrentOrganizationStore } from "state/current-organization";
import { CurrentUserStore } from "state/current-user";
import { CUSTOM_PERMISSIONS_PROPERTY, SPLIT_CHAR } from "views/settings/roles/edit-role";
import { Notification } from "./notification";
import { Contact } from "models/contact";

const IMPERSONATE_TRANSFER_PLANNER_PERMISSION = "Impersonate Transfer Planner Student";
const TP_TENANT_KEY_KEY = "tp_tenant_key";
const TP_PROFILE_ID_FIELD_KEY = "tp_profile_id_field_name";

@inject
export class OneOffService {
    public constructor(
        private appRepo: AppRepository,
        private notifier: Notification,
        private currentUserStore: CurrentUserStore,
        private currentOrganizationStore: CurrentOrganizationStore,
    ) { }

    public async launchCsoProgramSso() {
        try {
            const response = await this.appRepo.linkTransferPlanner();
            window.open(response.SsoUrl, "_blank");
        } catch (error) {
            let message = "Error generating session link";
            if (error instanceof NetworkException && error.result?.Message) {
                message = error.result?.Message;
            }
            this.notifier.error(message);
        }
    }

    public async launchTransferPlannerImpersonation(contactId:string) {
        try {
            const response = await this.appRepo.linkImpersonateTransferPlanner(contactId);
            window.open(response.ImpersonateUrl, "_blank");
        } catch (error) {
            let message = "Error generating session link";
            if (error instanceof NetworkException && error.result?.Message) {
                message = error.result?.Message;
            }
            this.notifier.error(message); 
        }
    }

    private get hasTransferPlannerPermission(): boolean {
        const { PropertyValues = [] } = this.currentOrganizationStore.state.organization;
        const propertyValues = PropertyValues.find(item => item.FieldKey === CUSTOM_PERMISSIONS_PROPERTY)?.Value;
        const permissions = propertyValues?.split(SPLIT_CHAR).map(value => value.trim()) || [];

        return permissions.includes(IMPERSONATE_TRANSFER_PLANNER_PERMISSION);
    }

    private get hasTpTenantKey(): boolean {
        const { PropertyValues = [] } = this.currentOrganizationStore.state.organization;

        return !!(PropertyValues.find(item => item.FieldKey === TP_TENANT_KEY_KEY)?.Value);
    }

    private hasTpProfileIdFieldWithValue(contact: Contact): boolean {
        const { PropertyValues = [] } = this.currentOrganizationStore.state.organization;
        const profileIdFieldValue = PropertyValues.find(item => item.FieldKey === TP_PROFILE_ID_FIELD_KEY)?.Value;

        if (!profileIdFieldValue) {
            return false;
        }
        
        return contact && !!contact.data[profileIdFieldValue];
    }

    private get impersonateTransferPlannerPermission(): boolean {
        const { permissions } = this.currentUserStore.state;

        return allOf(permissions, IMPERSONATE_TRANSFER_PLANNER_PERMISSION);
    }

    private get isGlobalUser(): boolean {
        return this.currentUserStore.state.isGlobalPrincipal;
    }

    private hasFieldsConfigured(contact: Contact): boolean {
        return this.hasTpTenantKey && this.hasTpProfileIdFieldWithValue(contact);
    }

    private get hasRequiredPermissions(): boolean {
        return (this.impersonateTransferPlannerPermission ||
            (this.isGlobalUser && this.hasTransferPlannerPermission))
    }

    public hasImpersonateTransferPlannerConfigAndPermission(contact: Contact): boolean {
        // make sure that the applicant has profile id field with its value
        // make sure that all the fields are setup properly in org properties and
        // user has permission either explicitly or implicitly as a global admin
        return this.hasFieldsConfigured(contact) && this.hasRequiredPermissions;
    }
}