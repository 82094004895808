import { inject, needs } from "fw";
import { preventLeadingSpaces } from "service/keyboard-press";
import { UserTaskRepository } from "network/user-task-repository";
import { PopoverController } from "service/popover";
import { TagPill } from "views/components/tag-pill";

@inject
@needs(TagPill)
export class TaskTagSelectorPopover {
  public loading = true;
  public showAdd = false;
  public tags: string[] = [];
  public allTags: string[] = [];
  public filter = "";
  private selectedTags: string[] = [];
  public cssClass: string = '';

  constructor(
    private userTaskRepository: UserTaskRepository,
    private controller: PopoverController<string>,
  ) { }

  public activate(params: { selected: string[]; showAdd: boolean; cssClass?: string }) {
    this.selectedTags = params.selected || []
    this.showAdd = !!params.showAdd;
    this.cssClass = params.cssClass || '';
  }

  async attached() {
    this.loading = true;

    const res = await this.userTaskRepository.count(null, null, "terms:tags~1000");
    const items = res.Aggregations["terms_tags"].Items;

    if (items) {
      this.allTags = items.map(i => i.Key);
      this.tags = this.allTags.filter(t => !this.selectedTags.includes(t));
    }

    this.loading = false;
  }

  get filteredTags() {
    const lowerFilter = this.filter.toLowerCase().trim();

    return this.tags.filter(t => {
      return t.toLowerCase().indexOf(lowerFilter) >= 0;
    });
  }


  onKeyEvent(e: KeyboardEvent) {
    preventLeadingSpaces(e, this.filter);
  }

  selectTag(tag: string) {
    if (tag.length == 0) return;

    this.controller.ok(tag);
  }

  get searchText() {
    if (this.loading || !this.showAdd) return "Search tags...";

    if (this.tags.length > 0) return "Search or create tags...";

    return "Create new tag...";
  }

  createFilteredTag() {
    this.controller.ok(this.filter.trim());
  }
}
