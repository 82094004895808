import { inject, prop, ComponentEventBus, needs } from "fw";

@inject
export class ActionButton {

  constructor(
    private ceb: ComponentEventBus
  ) {}

  @prop("info") public icon: string;
  @prop(null) public title: string;
  @prop(null) public subtitle: string;
  @prop(false) public inactive: boolean;

  public clickAction() {
    this.ceb.dispatch("update:click", null)
  };
}
