import { fromClass, fromClassArray, cloneOf } from "fw-model";
import { ContactData, IAddressData, ILinkInfo } from "models/contact";
import { CalendarEventRegistrationStatusInfo } from "models/calendar-event-registration";
import { ImageInfo } from "models/image";

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

export enum CalendarEventTypes {
  Series = 'CalendarEventSeries',
  Instance = 'CalendarEvent',
}

export enum CalendarEventScheduleInterval {
  CustomDate = 0,
  DaysBefore = 1
}

export enum CalendarEventPersonLimitType {
  NoLimit = 0,
  Attendees = 1,
  TotalPersons = 2
}

export enum EventStatuses {
  PENDING = "Pending",
  ACTIVE = "Active",
  CANCELLED = "Cancelled",
  COMPLETE = "Complete"
}

export enum EventBuilderSteps {
  Details,
  Schedule,
  Audience,
  Finish
}

export enum TimeSlotTypes {
  AllDayEvent = 0,
  SpecifyTimeSlots = 1
}

export enum EventFrequencies {
  oneOccurrence = "one-occurrence",
  futureOccurrences = "future-occurrences",
}

export enum ScheduleTypes {
  Daily = "daily",
  Weekly = "weekly",
  Custom = "custom-dates",
  MultiDay = "multi-day"
}

export enum EmailTimeMatcher {
  "email-registration" = "After Registration",
  "email-unregistration" = "After Unregistration",
  "email-checkin" = "After Checkin",
  "email-confirmation" = "After Registration",
  "email-cancellation" = "After Cancellation",
  "email-reminder" = "7 Days Before Event",
  "email-thank-you" = "2 Days After Event",
  "email-we-missed-you" = "2 Days After Event"
}

export type StepsNavigationItem = {
  text: string;
  label?: string;
  icon?: string;
}

export type DropdownOptionType = {
  text: string;
  value: string | number | boolean;
}

export type EventRegistrantViewOption = {
  icon: string;
  title: string;
  component: string;
  disabled: boolean;
}

export type CalendarPage = {
  month: number;
  year: number;
}

export class CalendarEventAddress implements IAddressData {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
  geo?: string;
  geo_hash?: string;
  normalized_geo_hash?: string;
  geo_country?: string;
  geo_level1?: string;
  geo_level2?: string;
  geo_locality?: string;
  latitude?: number;
  longitude?: number;
  full_address?: string;
}

export class CalendarEventExternalWebsite implements ILinkInfo {
  name?: string;
  url: string = null;
}

export class CalendarEventActivityPoints {
  register: number = null;
  unregister: number = null;
  attend: number = null;
}

export class CalendarEventRegistrationLimits {
  type: CalendarEventPersonLimitType = null;
  count: number = null;
  guest_limit: number = 0;
}

export class TimeOfDay {
  hour: number = 0;
  minute: number = 0;
}

export class CalendarEventRegistrationSchedule {
  frequency_type: CalendarEventScheduleInterval = null;
  countdown: number = null;
  date: string = null;
  date_time_utc?: string = null;
  @fromClass time: TimeOfDay;
}

export class CalendarEventTimeSlot {
  @fromClass from: TimeOfDay;
  @fromClass to: TimeOfDay;
}

export class CalendarEventRegistrationSettings {
  details: string = null;
  inquiry_form_id: string = null;
  is_limit_one: boolean = false;
  is_self_checkin_allowed: boolean = false;
  @fromClass limits: CalendarEventRegistrationLimits;
  @fromClass active_options: CalendarEventRegistrationSchedule;
  @fromClass closed_options: CalendarEventRegistrationSchedule;
}

export class CalendarEventRegistrationData {
  is_active: boolean = false;
  start_date: string = null;
  end_date: string = null;
  current_limit_count: number = 0;
  details: string = null;
  is_self_checkin_allowed: boolean = false;
  @fromClass limits: CalendarEventRegistrationLimits;
  @fromClassArray(CalendarEventRegistrationStatusInfo) counts_by_status: CalendarEventRegistrationStatusInfo[];
}

export class CalendarEventSeriesStats {
  calendar_event_series_id: string;
  @fromClassArray(CalendarEventRegistrationStatusInfo) counts_by_status: CalendarEventRegistrationStatusInfo[];
}

export class CalendarEventInstanceStats {
  id: string;
  @fromClassArray(CalendarEventRegistrationStatusInfo) counts_by_status: CalendarEventRegistrationStatusInfo[];
}

export class CalendarEventSchedule {
  schedule_type: "daily" | "weekly" | "custom-dates" | "multi-day" = null;
  is_all_day: boolean = false;
  start_date: string = null;
  end_date?: string = null;
  dates: string[] = [];
  week_days: DayOfWeek[] = [];
  @fromClassArray(CalendarEventTimeSlot) time_slots: CalendarEventTimeSlot[];
}

export interface ICalendarEvent {
  id: string;
  calendar_event_type_id: string;
  organization_id: string;
  contact_type: string;
  contact_segment_id: string;
  name: string;
  display_name: string;
  location: string;
  timezone: string;
  description: string;
  details: string;
  tags: string[];
  date?: string;
  is_all_day?: boolean;
  time_slot?: CalendarEventTimeSlot;
  // inquiry_form_key: string;                                      // awaiting spike on how to handle this
  registration_details?: string;
  registration_start_date?: string;
  registration_end_date?: string;
  is_self_checkin_allowed?: boolean;
  is_featured: boolean;
  is_private: boolean;
  address: CalendarEventAddress;
  external_website: CalendarEventExternalWebsite;
  images: ImageInfo[];
  registration_data?: CalendarEventRegistrationData;
}

export class CalendarEventSeries implements ICalendarEvent {
  id: string = null;
  name: string = null;
  display_name: string = null;
  location: string = null;
  timezone: string = null;
  description: string = null;
  details: string = null;
  calendar_event_type_id: string = null;
  tags: string[] = [];
  inquiry_form_key: string = null;
  contact_type: string = null;
  contact_segment_id: string = null;
  is_featured: boolean = false;
  is_private: boolean = false;
  organization_id: string = null;
  status: string = null;

  @fromClass address: CalendarEventAddress;
  @fromClass external_website: CalendarEventExternalWebsite;
  @fromClass schedule: CalendarEventSchedule;
  @fromClass activity_points: CalendarEventActivityPoints;
  @fromClass registration_settings: CalendarEventRegistrationSettings;
  @fromClassArray(ImageInfo) images: ImageInfo[];
}

export class CalendarEvent implements ICalendarEvent {
  calendar_event_series_id: string = null;
  calendar_event_type_id: string = null;
  contact_segment_id: string = null;
  contact_type: string = null;
  date: string = null;
  description: string = null;
  details: string = null;
  display_name: string = null;
  from_utc: string = null;
  id: string = null;
  instance_key: string = null;
  instance_type: string = null;
  is_all_day: boolean = false;
  inquiry_form_key: string = null;
  registration_details?: string = null;
  registration_start_date?: string = null;
  registration_end_date?: string = null;
  is_self_checkin_allowed?: boolean = false;
  is_featured: boolean = false;
  is_instance_created: boolean = false;
  is_private: boolean = false;
  location: string = null;
  name: string = null;
  organization_id: string = null;
  schedule_recurrence_key: string = null;
  schedule_type: string = null;
  status: string = null;
  tags: string[] = [];
  timezone: string = null;
  to_utc: string = null;
  @fromClass address: CalendarEventAddress;
  @fromClass external_website: CalendarEventExternalWebsite;
  @fromClassArray(ImageInfo) images: ImageInfo[];
  @fromClass registration_data: CalendarEventRegistrationData;
  @fromClass time_slot: CalendarEventTimeSlot;
}

export class UpdateCalendarEventModel {
  id: string = null;
  name: string = null;
  calendar_event_series_id: string = null;
  instance_key: string = null;
  organization_id: string = null;
  timezone: string = null;
  description: string = null;
  details: string = null;
  location: string = null;
  status: string = null;
  date: string = null;
  is_private: boolean = false;
  is_all_day: boolean = false;
  display_name: string = null;
  calendar_event_type_id: string = null;
  external_website: CalendarEventExternalWebsite = null;
  tags: string[] = [];
  contact_type?: string = null;
  // inquiry_form_key?: string = null;                              // awaiting spike on how to handle this
  contact_segment_id: string = null;
  registration_details?: string = null;
  registration_start_date?: string = null;
  registration_end_date?: string = null;
  is_self_checkin_allowed?: boolean = false;
  registration_data: CalendarEventRegistrationData = null;
  registrationInterval?: CalendarEventScheduleInterval;
  slidingRegistrationStart?: number = null;
  slidingRegistrationEnd?: number = null;

  @fromClass address: CalendarEventAddress;
  @fromClass time_slot: CalendarEventTimeSlot;
  @fromClass limits: CalendarEventRegistrationLimits;
  @fromClassArray(ImageInfo) images: ImageInfo[];
}

export class CalendarEventInfo {
  calendar_event_series_id: string = null;
  calendar_event_id: string = null;
  instance_key: string = null;
  is_all_day: boolean = false;
  from_utc: string = null;
  to_utc: string = null;
  date: string = null;
  is_instance_created: boolean = false;
  @fromClass time_slot: CalendarEventTimeSlot;
}

export interface InstanceMappedInfo {
  date: string;
  instances: CalendarEventInfo[];
}

export class ExistingInstancesDates {
  date: string = null;
  time_slots: CalendarEventTimeSlot[];
}

export class CalendarEventSeriesInfo {
  @fromClass calendar_event_series: CalendarEventSeries;
  @fromClassArray(CalendarEventInfo) calendar_event_infos: CalendarEventInfo[];
}

export interface RegistrantContactData extends ContactData {
  contact_type: string;
  attendees: number;
  type: string;
  details: string;
  email_address: string;
}

export class RegistrantContactData implements RegistrantContactData {
  contact_type: string = null;
  attendees: number = null;
  type: string = null;
  details: string = null;
}

export class CalendarEventType {
  id: string = null;
  organization_id: string = null;
  name: string = null;
}

export enum ActiveCalendarDateType {
  ThisMonth = 'This Month',
  Today = 'Today',
  Next7Days = 'Next 7 Days',
  Next30Days = 'Next 30 Days',
  Next6Months = 'Next 6 Months',
  Next12Months = 'Next 12 Months',
  Custom = 'Custom Range',
}

export enum CalendarGridViewMode {
  List,
  Grid
}

export const getSeriesAndInstanceFromCalendarEvent = (calendarEvent: CalendarEvent): { calendarEventSeriesId: string, instanceKey: string } => {
  return getSeriesAndInstanceFromId(calendarEvent?.id);
}

export const getSeriesAndInstanceFromId = (calendarEventId: string): { calendarEventSeriesId: string, instanceKey: string } => {
  if (calendarEventId?.length > 0) {
    const parts = calendarEventId.split("_");
    if (parts.length === 2) {
      return { calendarEventSeriesId: parts[1], instanceKey: parts[0] };
    }
  }
  return { calendarEventSeriesId: null, instanceKey: null };
}


export const toUpdateModel = (calendarEvent: CalendarEvent): UpdateCalendarEventModel => {
  if (!calendarEvent)
    return null;

  return {
    id: calendarEvent.id,
    calendar_event_series_id: calendarEvent.calendar_event_series_id,
    instance_key: calendarEvent.instance_key,
    organization_id: calendarEvent.organization_id,
    timezone: calendarEvent.timezone,
    description: calendarEvent.description,
    details: calendarEvent.details,
    location: calendarEvent.location,
    status: calendarEvent.status,
    date: calendarEvent.date,
    is_all_day: calendarEvent.is_all_day,
    is_private: calendarEvent.is_private,
    address: calendarEvent.address,
    time_slot: calendarEvent.time_slot,
    limits: calendarEvent.registration_data?.limits,
    name: calendarEvent.name,
    display_name: calendarEvent.display_name,
    calendar_event_type_id: calendarEvent.calendar_event_type_id,
    external_website: calendarEvent.external_website,
    tags: calendarEvent.tags,
    images: calendarEvent.images,
    contact_segment_id: calendarEvent.contact_segment_id,
    // inquiry_form_key: calendarEvent.inquiry_form_key,            // awaiting spike on how to handle this
    registration_details: calendarEvent.registration_data?.details,
    registration_start_date: calendarEvent.registration_data?.start_date,
    registration_end_date: calendarEvent.registration_data?.end_date,
    is_self_checkin_allowed: calendarEvent.registration_data?.is_self_checkin_allowed,
    registration_data: calendarEvent.registration_data,
    registrationInterval: CalendarEventScheduleInterval.CustomDate,
  };
}

export const cleanUpdateCalendarEventModel = (m: UpdateCalendarEventModel): UpdateCalendarEventModel => {
  const cloned = cloneOf(UpdateCalendarEventModel, m)
  if (cloned.is_all_day) {
    cloned.time_slot = null;
  }

  return cloned;
}

export const cleanCalendarEventSeries = (ce: CalendarEventSeries): CalendarEventSeries => {
  const cloned = cloneOf(CalendarEventSeries, ce)

  if (cloned.activity_points
    && !cloned.activity_points.attend
    && !cloned.activity_points.register
    && !cloned.activity_points.unregister
  ) {
    cloned.activity_points = null;
  }

  if (!cloned.schedule.schedule_type) {
    cloned.schedule = null;
  }

  if (cloned.registration_settings) {
    let hasSettings: boolean = false;
    cloned.registration_settings.active_options = cleanRegistrationSchedule(cloned.registration_settings.active_options);
    if (cloned.registration_settings.active_options)
      hasSettings = true;

    cloned.registration_settings.closed_options = cleanRegistrationSchedule(cloned.registration_settings.closed_options);
    if (cloned.registration_settings.closed_options)
      hasSettings = true;

    const inquiryForm = cloned.registration_settings.inquiry_form_id;
    if (!!inquiryForm) {
      hasSettings = true;
    }

    if (!hasSettings) {
      cloned.registration_settings = null;
    }
  }
  return cloned;
}

const cleanRegistrationSchedule = (registrationSchedule: CalendarEventRegistrationSchedule): CalendarEventRegistrationSchedule => {
  if (!registrationSchedule)
    return null;

  if (registrationSchedule.frequency_type == null)
    return null;

  if (!registrationSchedule.time?.hour && !registrationSchedule.time?.minute)
    registrationSchedule.time = null;
  if (registrationSchedule.countdown == null)
    registrationSchedule.countdown = 0;

  switch (registrationSchedule.frequency_type) {
    case CalendarEventScheduleInterval.CustomDate:
      registrationSchedule.countdown = 0;
      return !!registrationSchedule.date ? registrationSchedule : null;

    case CalendarEventScheduleInterval.DaysBefore:
      registrationSchedule.date = null;
      registrationSchedule.time = null;
      return registrationSchedule;

    default:
      return null;
  }
}
