import { inject } from "fw";
import { createFrom, cloneOf } from "fw-model";

import { ATS } from "./ats";
import { ApplicationSegment } from "models/application-segment";

@inject
export class ApplicationSegmentRepository {
  constructor(private s: ATS) {}

  public async post(segment: ApplicationSegment) {
    const encodedSeg = cloneOf(ApplicationSegment, segment);
    encodedSeg.ClientData = encodeURIComponent(segment.ClientData);

    const res = await this.s.post("application-segment", encodedSeg);
    return createFrom(ApplicationSegment, res.body);
  }

  public async put(segment: ApplicationSegment) {
    const encodedSeg = cloneOf(ApplicationSegment, segment);
    encodedSeg.ClientData = encodeURIComponent(segment.ClientData);

    const res = await this.s.put(`application-segment/${segment.Id}`, encodedSeg);
    return createFrom(ApplicationSegment, res.body);
  }

  public async del(ids: string[]) {
    await this.s.delete(`application-segment/${ids.join(",")}`);
  }

  public async putCategory(ids: string[], category: string) {
    await this.s.put(`application-segment/${ids.join(",")}/category`, null, {
      category,
    });
  }
}
