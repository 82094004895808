import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { createFrom } from "fw-model";

import { LogoutAction, StartAction } from "./actions";
import { Actor } from "models/app-organization-portal-context";

import { OrganizationPortalRepository } from "network/organization-portal-repository";
import { wait } from "wait";

import { difference } from "lodash-es";

interface ActorShape {
  filter: string;
  filterArg?: string;
  filterType?: string;
  search: string;
  page: number;
  pageSize: number;
  sort: string;
  total: number;

  currentUserPage: Actor[];
  actorHash: {};
  actors: Actor[];
  loaded: boolean;
  loading: boolean;
}

export const defaultActor = createFrom(Actor, {
  Id: "",
  EmailAddress: "",
  FirstName: null,
  LastName: null,
  FullName: null
});

export class EnsureActorsAction {
  constructor(public actors: Actor[]) { }
}

// outside of Store to prevent vue from tracking..
let actorsToLoad: Actor[] = [];
let actorsToLoadLoading: Actor[] = [];

@inject
export class ActorsStore extends Store<ActorShape> {
  private orgId: string = null;
  private seasonId: string = null;

  constructor(private orgPortalRepo: OrganizationPortalRepository) {
    super();
  }

  defaultState() {
    return {
      filter: null,
      filterArg: null,
      filterType: null,
      search: null,
      page: 1,
      pageSize: 20,
      sort: "lastName firstName",
      loaded: false,
      loading: false,
      total: 0,

      currentUserPage: [],
      actorHash: {},
      actors: []
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    const { Organization } = s.context;

    this.orgId = Organization.Id;
    this.seasonId = Organization.ActiveSeasonId;
    this.setState(state => ({
      ...state,
      ...this.defaultState(),
    }));
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.orgId = null;
    this.setState(state => this.defaultState());
  }

  @handle(EnsureActorsAction)
  private async handleEnsureUsersAction(action: EnsureActorsAction) {
    if (action.actors == null) return;
    const actorsToFetch = action.actors.filter(
      actor =>
        actor != null &&
        actor.Id.length > 0 &&
        actor.Type.length > 0 &&
        this.state.actorHash[actor.Id] == null &&
        !actorsToLoad.find(i => i.Id == actor.Id) &&
        !actorsToLoadLoading.find(i => i.Id == actor.Id)
    );

    if (actorsToFetch.length == 0) {
      return;
    }

    if (actorsToLoad.length > 0) {
      // push it on to this thing and return..
      actorsToLoad.push(...actorsToFetch);
      return;
    }

    actorsToLoad.push(...actorsToFetch);

    await wait(10); // this is the buffer time, so that multiple components can all get in here
    actorsToLoadLoading.push(...actorsToLoad);
    actorsToLoad = [];

    const currentActorsToLoad = actorsToLoadLoading.slice();
    const res = await this.orgPortalRepo.getActors(currentActorsToLoad);

    const newHash: { [id: string]: Actor } = {};

    for (const actor of res) {
      newHash[actor.Id] = actor;

      const idx = actorsToLoadLoading.findIndex(i => i.Type == actor.Type && i.Id == actor.Id);
      if (idx >= 0) actorsToLoadLoading.splice(idx, 1);
    }

    // did anything that got loaded not return from the server?
    // if so, we need to fill it out with the defaultUser
    currentActorsToLoad.filter(actor => !res.find(i => i.Type == actor.Type && i.Id == actor.Id))
      .forEach(actor => {
        newHash[`${actor.Type}-${actor.Id}`] = defaultActor;

        const idx = actorsToLoadLoading.findIndex(i => i.Type == actor.Type && i.Id == actor.Id);
        if (idx >= 0) actorsToLoadLoading.splice(idx, 1);
      });

    this.state.actorHash = {
      ...this.state.actorHash,
      ...newHash
    };
  }
}
