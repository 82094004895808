import { inject, Subscription } from "fw";
import { dispatch } from "fw-state";

import { File, ProcessStatusTypeCode } from "models/file";
import { FileRepository } from "network/file-repository";
import { UpdateFileAction } from "state/current-application";


const isMainTerminal = (file: File) => file.MetaData.Status != ProcessStatusTypeCode.Pending;
const isSecondaryTerminal = (file: File) => file.MetaData.SecondaryProcessingStatus != ProcessStatusTypeCode.Pending;

const isAllTerminal = (file: File) => {
  const mainStatusTerminal = isMainTerminal(file);
  const secondaryStatusTerminal = isSecondaryTerminal(file)

  return mainStatusTerminal && secondaryStatusTerminal;
};

const TIMER_INTERVALS = [
  2000,
  2000,
  5000,
];

type OnUpdated = (file: File) => void;

@inject
export class FileWatcher {
  private onTimerInterval = 0;
  private timer = null;

  private watching: { file: File; onUpdated: OnUpdated }[] = [];
  private secondaryWatching: { file: File; onUpdated: OnUpdated }[] = [];
  private isChecking = false;

  constructor(private fileRepo: FileRepository) { }

  watch(file: File, onUpdated?: OnUpdated) {
    this.startWatching(file, true, onUpdated);
 }

  stopWatching(file: File | string) {
    if (file instanceof File) {
      this.removeFromWatching(file.Id);
    } else {
      this.removeFromWatching(file);
    }
  }

  clearAll() {
    this.watching = [];
    this.secondaryWatching = [];

    this.isChecking = false;

    if (this.timer != null) clearInterval(this.timer);
    this.timer = null;
    this.onTimerInterval = 0;
  }

  private startWatching(file: File, startOver = false, onUpdated?: OnUpdated) {
    if (isAllTerminal(file)) return;

    if (isMainTerminal(file)) {
      this.secondaryWatching.push({ file, onUpdated });
    } else {
      this.watching.push({ file, onUpdated });
    }

    this.triggerNextCheck(startOver);
  }

  private triggerNextCheck(startOver = false) {
    if (this.watching.length == 0 && this.secondaryWatching.length == 0) return;

    this.onTimerInterval += 1;

    if (this.onTimerInterval >= TIMER_INTERVALS.length) this.onTimerInterval = TIMER_INTERVALS.length - 1;

    if (startOver) this.onTimerInterval = 0;

    if (this.timer != null) clearTimeout(this.timer);

    console.log("timeout check", this.onTimerInterval, TIMER_INTERVALS[this.onTimerInterval]);

    this.timer = setTimeout(this.checkFiles.bind(this), TIMER_INTERVALS[this.onTimerInterval]);
  }

  private async checkFiles() {
    if (this.isChecking || (this.watching.length == 0 && this.secondaryWatching.length == 0)) return;

    this.isChecking = true;

    try {
      const files = await this.fileRepo.list([ ...this.watching, ...this.secondaryWatching ].map(f => f.file.Id));

      files.forEach(f => {
        const inMainWatching = this.watching.find(mf => mf.file.Id == f.Id);
        const inSecondaryWatching = this.secondaryWatching.find(mf => mf.file.Id == f.Id);

        if (inMainWatching) {
          if (isMainTerminal(f)) {
            this.removeFromWatching(f.Id);
            dispatch(new UpdateFileAction(f));
            if (inMainWatching.onUpdated) inMainWatching.onUpdated(f);

            if (!isSecondaryTerminal(f)) {
              this.startWatching(f, false);
            }
          }
        } else if (inSecondaryWatching) {
          if (isSecondaryTerminal(f)) {
            this.removeFromWatching(f.Id);
            dispatch(new UpdateFileAction(f));
            if (inSecondaryWatching.onUpdated) inSecondaryWatching.onUpdated(f);
          }
        }
      });
    } catch(err) { }

    this.isChecking = false;

    this.triggerNextCheck();
  }

  // this will try remove it from both lists
  private removeFromWatching(fileId: string) {
    const fileInMainList = this.watching.find(f => f.file.Id == fileId);
    if (fileInMainList != null) {
      const idx = this.watching.indexOf(fileInMainList);
      if (idx != -1) this.watching.splice(idx, 1);
    }

    const fileInSecondaryList = this.secondaryWatching.find(f => f.file.Id == fileId);
    if (fileInSecondaryList != null) {
      const idx = this.secondaryWatching.indexOf(fileInSecondaryList);
      if (idx != -1) this.secondaryWatching.splice(idx, 1);
    }
  }
}
