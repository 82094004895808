const on = (element, events, handler) => {
	if (!(events instanceof Array)) {
		events = [events];
	}
	for (let i = 0; i < events.length; i++) {
		element.addEventListener(events[i], handler);
	}
};

const off = (element, events, handler) => {
	if (!(events instanceof Array)) {
		events = [events];
	}
	for (let i = 0; i < events.length; i++) {
		element.removeEventListener(events[i], handler);
	}
};

// got this from: http://stackoverflow.com/a/39494245

function getElementY(query) {
  return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top
}

function doScrolling(selector, duration, padding) {
  const startingY = window.pageYOffset
  const elementY = getElementY(selector) - padding;
  // If element is close to page's bottom then window will scroll only to some position above the element.
  const targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
  const diff = targetY - startingY
  // Easing function: easeOutCubic
  // From: https://gist.github.com/gre/1650294
  const easing = function (t) { return (--t)*t*t+1 };
  let start;

  if (!diff) return;

  if (duration == 0) {
    window.scrollTo(0, targetY);
    return;
  }

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    const time = timestamp - start
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1)
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent)

    window.scrollTo(0, startingY + diff * percent)

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  })
}

type Binding = { value: string | { selector: string, padding: number }  };

export const plugin = {
	install: (Vue) => {
		const handleClick = function(this: HTMLElement) {
      const selector = this.dataset["scrollDestination"];
      const padding = this.dataset["padding"];

      doScrolling(selector, 200, padding);
		};

    const updateDataset = (el, binding: Binding) => {
      if (typeof binding.value == "string") {
        el.dataset["scrollDestination"] = binding.value;
        el.dataset["padding"] = 60;
      } else {
        el.dataset["scrollDestination"] = binding.value.selector;
        el.dataset["padding"] = binding.value.padding || 60;
      }
    };

		Vue.directive('scroll-to', {
      bind: function(el, binding: Binding) {
				on(el, 'click', handleClick.bind(el));
        updateDataset(el, binding);
			},
      update: function (el, binding: Binding) {
        updateDataset(el, binding);
      },
			unbind: function(el) {
				off(el, 'click', handleClick);
			}
		});
	},
};

export const scrollTo = (selector: string, padding = 60, duration = 200) => {
  doScrolling(selector, duration, padding);
}

export default plugin;
