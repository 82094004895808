import { inject, needs } from "fw";
import {
  type ICustomFieldDefinition,
  IdentifierMode,
  CustomFieldType,
} from "models/contact-organization";

import { PopoverController } from "service/popover";
import { allTypes } from "./fields";

import { Contact, ContactMetaData } from "models/contact";
import { ChooseFieldValue, ChooseFieldValueResult } from "./choose-field-value";
import { FieldContext } from "./field";
import { Permissions } from "service/permission";
import { ContactRepository } from "network/contact-repository";
import { ContactsFieldEditService } from "service/contacts-field-edit-service";
import { ContactsFieldPaths } from "service/contacts-field-paths";
import { Notification } from "service/notification";

export class EditFieldPopoverOptions {
  contact: Contact;
  contactMeta: ContactMetaData;
  field: ICustomFieldDefinition;
  value: any;
  path: string;
  dataId: string;
  controlType: string;
  context: FieldContext;
}

@inject
@needs(...allTypes, ChooseFieldValue)
export class EditFieldPopover<T> {
  public contact: Contact = null;
  public contactMeta: ContactMetaData = null;
  public field: ICustomFieldDefinition = null;
  private originalValue: any = null;
  public value: any = null;
  public dataId: string = null;
  public controlType: string = null;
  public context: FieldContext = null;
  public saving: boolean = false;
  public versionConflictDetected: boolean = false;
  public chooserIsOpen: boolean = false;

  private validate: (originalValue: any) => boolean = null;

  constructor(
    private popoverController: PopoverController<T>,
    private contactRepo: ContactRepository,
    private permissions: Permissions,
    private contactsFieldEditService: ContactsFieldEditService,
    private contactFieldPaths: ContactsFieldPaths,
    private notification: Notification
  ) {}

  public activate(options: EditFieldPopoverOptions) {
    this.contact = options.contact;
    this.contactMeta = options.contactMeta;
    this.field = options.field;
    this.originalValue = options.value;
    this.value = JSON.parse(JSON.stringify(options.value));
    this.dataId = `${options.dataId}-edit`;
    this.controlType = options.controlType;
    this.context = { ...options.context };
  }

  public closeChooser() {
    this.chooserIsOpen = false;
  }

  public openChooser() {
    this.chooserIsOpen = true;
  }

  public cancel() {
    this.popoverController.cancel();
  }

  public get fieldMeta() {
    if (!this.contactMeta) {
      return null;
    }

    if (this.field.is_system_field) {
      return this.contactMeta.fields.find(
        (f) => f.field_name === this.field.name
      );
    } else {
      // TODO: Why don't the ids match for system fields?
      return this.contactMeta.fields.find((f) => f.field_id === this.field.id);
    }
  }

  public async onFieldChoose(result: ChooseFieldValueResult) {
    if (!result) {
      return;
    }

    if (this.field.type === CustomFieldType.concealed) {
      if (this.permissions.all("ViewEncryptedFields")) {
        if (result.context.decryptedValue) {
          this.value = result.context.decryptedValue;
        } else {
          try {
            const decryptedValue = await this.contactRepo.decrypt(
              this.context.contactId,
              this.field.id,
              result.context.contactMetaValueId
            );
            this.value = decryptedValue?.value;
          } catch (ex) {
            // TODO: Handle any errors that occur while decrypting
          }
        }
        this.context.decryptedValue = this.value;
      }
    } else {
      this.value = result.fieldValue.value;
    }

    this.chooserIsOpen = false;
  }

  public async save() {
    this.saving = true;
    this.versionConflictDetected = false;

    try {
      const fieldContext = this.contactFieldPaths.getContactProperty(
        this.field,
        this.contact,
        this.contactMeta
      );
      fieldContext.value = this.value;
      const result = await this.contactsFieldEditService.save(
        this.contact,
        fieldContext,
        this.originalValue,
        this.validate
      );
      if (result.success) {
        this.notification.notify(result.message);
        this.popoverController.ok(this.value);
      } else {
        this.notification.error(result.message);
      }
    } catch (err) {
      this.versionConflictDetected = (err.message || "").includes(
        "version conflict"
      );
    } finally {
      this.saving = false;
    }
  }

  public get contactType(): string {
    return this.field && this.field.data && this.field.data.contact_type;
  }

  public get canEdit(): boolean {
    switch (this.field?.type) {
      case CustomFieldType.attachment:
        return false;
      default:
        return true;
    }
  }

  public get canClearValue(): boolean {
    if (!this.value) return false;

    if (this.field) {
      const { is_read_only, identifier_mode, type } = this.field;
      if (
        is_read_only ||
        [CustomFieldType.funnel, CustomFieldType.attachment].includes(type)
      )
        return false;

      if (
        this.originalValue &&
        ![IdentifierMode.None, IdentifierMode.Email].includes(identifier_mode)
      )
        return false;
    }

    return true;
  }

  public clearValue() {
    this.value = undefined;
  }
}
