import { inject } from "fw";
import { dispatch } from "fw-state";
import { isEmpty, keys, toPath, uniq } from "lodash-es";
import { DataDictionaryField, DataDictionaryFieldCategory } from "models/data-dictionary";
import { ApplicationRestriction, ApplicationRestrictionType, getCategoryFromApplicationRestrictionType, getFormName } from "models/role";
import { DataDictionaryStore, EnsureDataDictionaryFieldsAction } from "state/data-dictionary";
import { ApplicationSettingsService } from "./application-settings";
import { FormService } from "./form";
import { DataDictionaryService } from "./data-dictionary";

@inject
export class DataRestrictionsService {
    constructor(
        private applicationSettingsService: ApplicationSettingsService,
        private dataDictionaryService: DataDictionaryService,
        private dataDictionaryStore: DataDictionaryStore,
        private formService: FormService
    ) { }

    public normalize(
        activeRestrictions: ApplicationRestriction[],
        createRestriction: (restriction: ApplicationRestriction) => void,
        deleteRestriction: (restriction: ApplicationRestriction) => void
    ) {
        const { propertiesHash, formsHash, stepsHash } = this.generateRestrictionsHashes(activeRestrictions);
        this.normalizePropertyRestrictions(propertiesHash, activeRestrictions, createRestriction, deleteRestriction);
        this.normalizeStepRestrictions(stepsHash, activeRestrictions, createRestriction, deleteRestriction);
        this.normalizeFormRestrictions(formsHash, activeRestrictions, createRestriction, deleteRestriction);
    }

    public async getInvalidRestrictions(activeRestrictions: ApplicationRestriction[]): Promise<string[]> {
        const invalidRestrictions: string[] = [];
        let fields: DataDictionaryField[] = null;
        for (const restriction of activeRestrictions) {
            switch (restriction.Type) {
                case "ApplicationProperty":
                    for (const propertyKey of restriction.Paths) {
                        if (!propertyKey || propertyKey.length === 0) {
                            continue;
                        }

                        const property = this.applicationSettingsService.getApplicationPropertyFromPath(propertyKey);
                        if (!property) {
                            invalidRestrictions.push(`Application Properties/${propertyKey}`);
                        }
                    }

                    break;

                case "FormStep":
                case "Evaluation":
                case "ReferenceForm": {
                    for (const path of restriction.Paths) {
                        const pathResult = await this.formService.getFormPathResult(path);
                        if (!pathResult || pathResult.IsValid) {
                            continue;
                        }

                        let invalidPath = pathResult.FormTypeName || getFormName(restriction.Type);
                        const form = pathResult.FormName || pathResult.FormKey;
                        if (form) {
                            invalidPath += `/${form}`;
                        }

                        const section = pathResult.SectionTitle || pathResult.SectionKey;
                        if (section) {
                            invalidPath += `/${section}`;
                        }

                        const question = pathResult.QuestionKey;
                        if (question) {
                            invalidPath += `/${question}`;
                        }

                        invalidRestrictions.push(invalidPath);
                    }

                    break;
                }

                case "PortfolioStep":
                case "DocumentStep": {
                    if (!restriction.StepKey || restriction.StepKey.length === 0) {
                        continue;
                    }

                    if (fields == null) {
                        // Lazy load fields, this ensures it's always up to date.
                        await dispatch(new EnsureDataDictionaryFieldsAction(false));
                        fields = this.dataDictionaryStore.state.fields;
                    }

                    const category: DataDictionaryFieldCategory = getCategoryFromApplicationRestrictionType(restriction.Type);
                    const hasStep = !!this.dataDictionaryService.resolveStep(fields, category, restriction.StepKey);
                    if (!hasStep) {
                        invalidRestrictions.push(`${restriction.Type.replace("Step", "")}/${restriction.StepKey}`);
                    }

                    break;
                }
            }
        }

        return invalidRestrictions;
    }

    private generateRestrictionsHashes(activeRestrictions: ApplicationRestriction[]): { propertiesHash: {}; formsHash: {}; stepsHash: {}; } {
        const propertiesHash = {};
        const formsHash = {};
        const stepsHash = {};

        for (const restriction of activeRestrictions) {
            switch (restriction.Type) {
                case "ApplicantIdentity":
                case "ApplicantProfileImage":
                    break;

                case "ApplicationProperty":
                    for (const propertyPath of restriction.Paths) {
                        propertiesHash[propertyPath] = true;
                    }
                    break;

                case "FormStep":
                case "Evaluation":
                case "ReferenceForm": {
                    const type = restriction.Type;
                    if (!formsHash[type]) {
                        formsHash[type] = {};
                    }

                    for (const formPath of restriction.Paths) {
                        const path = formPath.startsWith("forms.") ? formPath.substring(6) : formPath;
                        const paths = toPath(path);
                        const formKey = paths[0];

                        if (!formsHash[type][formKey]) {
                            formsHash[type][formKey] = {};
                        }

                        formsHash[type][formKey][path] = true;
                    }
                    break;
                }

                case "DocumentStep":
                case "PortfolioStep":
                    if (!stepsHash[restriction.Type]) {
                        stepsHash[restriction.Type] = {};
                    }
                    stepsHash[restriction.Type][restriction.StepKey] = true;
                    break;
            }
        }

        return { propertiesHash, formsHash, stepsHash };
    }

    private normalizeFormRestrictions(
        formsHash: {},
        activeRestrictions: ApplicationRestriction[],
        createRestriction: (restriction: ApplicationRestriction) => void,
        deleteRestriction: (restriction: ApplicationRestriction) => void
    ): void {
        if (!formsHash || isEmpty(formsHash)) {
            return;
        }

        for (const type of keys(formsHash)) {
            if (!formsHash[type] || isEmpty(formsHash[type])) {
                continue;
            }

            for (const formKey of keys(formsHash[type])) {
                if (!formsHash[type][formKey] || isEmpty(formsHash[type][formKey])) {
                    continue;
                }

                const formPaths = keys(formsHash[type][formKey]);
                if (!formPaths || formPaths.length === 0) {
                    continue;
                }

                const restrictions = activeRestrictions.filter(r => r.Type == type && r.Paths && r.Paths.length > 0 && r.Paths.findIndex(p => p.startsWith(`${formKey}.`)) != -1);
                let restriction = null;
                if (!restrictions || restrictions.length === 0) {
                    restriction = this.createPathRestriction(type as ApplicationRestrictionType, formPaths, createRestriction);
                } else {
                    restriction = restrictions[0];
                    restriction.Paths = restrictions.length === 1
                        ? restriction.Paths.concat(formPaths)
                        : restrictions.map(r => r.Paths && r.Paths.length > 0 ? r.Paths : []).reduce((a, b) => a.concat(b)).concat(formPaths);
                    restriction.Paths = uniq(restriction.Paths);
                    if (restrictions.length > 1) {
                        for (let idx = 1; idx < restrictions.length; idx++) {
                            deleteRestriction(restrictions[idx]);
                        }
                    }
                }

                const wildCard = `${formKey}.*`;
                if (restriction.Paths.indexOf(wildCard) != -1) {
                    restriction.Paths = [wildCard];
                } else {
                    const pathHash = {};
                    for (const path of restriction.Paths) {
                        pathHash[path] = true;
                    }
                    restriction.Paths = keys(pathHash);
                }
            }
        }
    }

    private normalizePropertyRestrictions(
        propertiesHash: {},
        activeRestrictions: ApplicationRestriction[],
        createRestriction: (restriction: ApplicationRestriction) => void,
        deleteRestriction: (restriction: ApplicationRestriction) => void
    ): void {
        if (!propertiesHash || isEmpty(propertiesHash)) {
            return;
        }

        const propertyPaths = keys(propertiesHash);
        if (!propertyPaths || propertyPaths.length === 0) {
            return;
        }

        const restrictions = activeRestrictions.filter(r => r.Type == "ApplicationProperty");
        let restriction = restrictions && restrictions.length > 0 && restrictions[0];
        if (!restriction) {
            this.createPathRestriction("ApplicationProperty", propertyPaths, createRestriction);
        } else {
            restriction.Paths = propertyPaths;
            if (restrictions.length > 1) {
                for (let idx = 1; idx < restrictions.length; idx++) {
                    deleteRestriction(restrictions[idx]);
                }
            }
        }
    }

    private normalizeStepRestrictions(
        stepsHash: {},
        activeRestrictions: ApplicationRestriction[],
        createRestriction: (restriction: ApplicationRestriction) => void,
        deleteRestriction: (restriction: ApplicationRestriction) => void
    ): void {
        if (!stepsHash || isEmpty(stepsHash)) {
            return;
        }

        for (const type of keys(stepsHash)) {
            if (!stepsHash[type] || isEmpty(stepsHash[type])) {
                continue;
            }

            for (const stepKey of keys(stepsHash[type])) {
                const restrictions = activeRestrictions.filter(r => r.Type == type && r.StepKey == stepKey);
                let restriction = restrictions && restrictions.length > 0 && restrictions[0];
                if (!restriction) {
                    this.createStepRestriction(type as ApplicationRestrictionType, stepKey, createRestriction);
                } else if (restrictions.length > 1) {
                    for (let idx = 1; idx < restrictions.length; idx++) {
                        deleteRestriction(restrictions[idx]);
                    }
                }
            }
        }
    }

    private createPathRestriction(type: ApplicationRestrictionType, paths: string[], createRestriction: (restriction: ApplicationRestriction) => void) {
        if (!type || !paths || paths.length === 0) return;
        const applicationRestriction: ApplicationRestriction = new ApplicationRestriction();
        applicationRestriction.Type = type;
        applicationRestriction.Paths = paths;
        return createRestriction(applicationRestriction);
    }

    private createStepRestriction(type: ApplicationRestrictionType, stepKey: string, createRestriction: (restriction: ApplicationRestriction) => void) {
        if (!type || !stepKey || stepKey.trim().length === 0) return;
        const applicationRestriction: ApplicationRestriction = new ApplicationRestriction();
        applicationRestriction.Type = type;
        applicationRestriction.StepKey = stepKey;
        return createRestriction(applicationRestriction);
    }
}
