import { inject } from "fw";
import { DialogResult, DialogService } from "fw-dialog";

import { PopoverResult, PopoverService } from "service/popover";

import { ConfirmDialog, ConfirmDialogArgs } from "views/components/confirm-dialog";
import { ConfirmDialogV2, ConfirmDialogV2Args } from "views/components/confirm-dialog-v2";
import {
  ButtonOption,
  ConfirmDialogWithButtonChoices,
  ConfirmDialogWithButtonChoicesArgs,
} from "views/components/confirm-dialog-with-button-choices";
import { SystemMessageDialog, SystemMessageDialogArgs } from "views/components/system-message";

@inject
export class Confirm {
  constructor(private dialogService: DialogService, private popoverService: PopoverService) {}

  async confirmV2(
    text: string,
    args: {
      title?: string;
      okButtonText?: string;
      cancelButtonText?: string;
      okButtonClass?: string;
      cancelButtonClass?: string;
    } = {},
    asPopover = false
  ): Promise<boolean> {
    const { title, cancelButtonText, okButtonClass, okButtonText, cancelButtonClass } = args;
    const confirmArgs: ConfirmDialogV2Args = {
      text,
      title,
      cancelButtonText,
      okButtonClass,
      okButtonText,
      cancelButtonClass,
    };

    const result = asPopover
      ? await this.popoverService.open<void>(ConfirmDialogV2, confirmArgs)
      : await this.dialogService.open<void>(ConfirmDialogV2, confirmArgs, {
          cssClass: "confirmation-dialog-v2",
        });

    return !result.canceled;
  }

  async confirm(
    text: string,
    args: { okButtonText?: string; cancelButtonText?: string; okButtonClass?: string } = {},
    asPopover = false
  ): Promise<boolean> {
    const { cancelButtonText, okButtonClass, okButtonText } = args;
    const confirmArgs: ConfirmDialogArgs = {
      text,
      cancelButtonText,
      okButtonClass,
      okButtonText,
    };

    const result = asPopover
      ? await this.popoverService.open<void>(ConfirmDialog, confirmArgs)
      : await this.dialogService.open<void>(ConfirmDialog, confirmArgs, { cssClass: "confirmation-dialog" });

    return !result.canceled;
  }

  async withButtonChoices(
    text: string,
    buttons: ButtonOption[] = [],
    dialogCssClass: string = 'confirmation-dialog',
  ): Promise<DialogResult<string>> {
    const confirmArgs: ConfirmDialogWithButtonChoicesArgs = { text, buttons };

    return await this.dialogService.open<string>(ConfirmDialogWithButtonChoices, confirmArgs, { cssClass: dialogCssClass });
  }

  async confirmDelete(text: string, deleteButtonText = "Delete", cancelButtonText = "Keep"): Promise<boolean> {
    return await this.confirm(text, {
      okButtonText: deleteButtonText,
      cancelButtonText,
      okButtonClass: "alert",
    });
  }

  async systemMessage(text: string, okButtonText = "OK", infos: string[] = null): Promise<boolean> {
    const result = await this.dialogService.open<void>(
      SystemMessageDialog,
      { text, infos, okButtonText },
      { cssClass: "confirmation-dialog" }
    );
    return !result.canceled;
  }
}
