import { inject, prop, needs } from "fw";

import { TagFilter as TagFilterModel } from "models/application-filters";
import { PopoverService } from "service/popover";
import { TagSelectorPopover } from "views/components/tag-selector-popover";
import { TagsField } from "views/components/tags-field";

@inject
@needs(TagsField)
export class TagFilter {
  @prop(null)
  filter: TagFilterModel;

  @prop(() => ({}))
  validation;

  @prop(false)
  editing: boolean;

  constructor(private popoverService: PopoverService) {}

  get modeText() {
    return {
      any: "Any of the following tags:",
      all: "All of the following tags:",
      none: "None of the following tags:",
    };
  }

  get modes() {
    return [
      { text: this.modeText["any"], value: "any" },
      { text: this.modeText["all"], value: "all" },
      { text: this.modeText["none"], value: "none" },
    ];
  }

  removeTag(tag: string) {
    const idx = this.filter.tags.indexOf(tag);
    if (idx >= 0) {
      this.filter.tags.splice(idx, 1);
    }
  }

  async openTagPopover() {
    const res = await this.popoverService.open<string>(TagSelectorPopover, {
      selected: this.filter.tags,
    });

    if (res.canceled) return;

    this.filter.tags.push(res.result);
  }
}
