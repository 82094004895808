import { inject, prop } from "fw";

import { ContactsErrorsFilter as ContactsErrorsFilterModel } from "models/contacts-filters";

@inject
export class ContactsErrorsFilter {
  @prop(null) filter!: ContactsErrorsFilterModel;
  @prop(() => ({})) validation;
  @prop(false) editing!: boolean;

  constructor() { }

  get friendly() {
    if (this.filter == null) return "";
    return this.filter.hasErrors ? "Has" : "No";
  }
}
