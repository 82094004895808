import { inject } from "fw";
import { Store, handle } from "fw-state";

import { FormErrorHandling } from "./error-handling";
import { StartAction, LogoutAction } from "./actions";
import {
  AddCollaboratorRoleAction,
  DeleteCollaboratorRolesAction,
  UpdateCollaboratorRoleAction,
} from "forms/collaborator-role";
import { CollaboratorRole } from "models/collaborator-role";

import { without } from "lodash-es";
import { CollaboratorRoleRepository } from "network/collaborator-role-repository";

interface CollaboratorRolesShape {
  roles: CollaboratorRole[];
}

@inject
export class CollaboratorRolesStore extends Store<CollaboratorRolesShape> {
  constructor(private collabRoleRepository: CollaboratorRoleRepository) {
    super();
  }

  defaultState() {
    return {
      roles: [],
    };
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    const { CollaboratorRoles } = action.context;

    this.setState(state => ({
      ...state,
      roles: CollaboratorRoles,
    }));
  }

  @handle(AddCollaboratorRoleAction, FormErrorHandling)
  private async handleAddCollaboratorRoleAction(
    action: AddCollaboratorRoleAction,
  ) {
    action.form.validate();

    const newCollabRole = await this.collabRoleRepository.post(
      action.form.updatedModel(),
    );

    action.addedId = newCollabRole.Id;

    this.setState(state => ({
      roles: [...state.roles, newCollabRole],
    }));
  }

  @handle(UpdateCollaboratorRoleAction, FormErrorHandling)
  private async handleUpdateCollaboratorRoleAction(
    action: UpdateCollaboratorRoleAction,
  ) {
    action.form.validate();

    const updatedRole = await this.collabRoleRepository.put(
      action.form.updatedModel(),
    );

    const existingRole = this.state.roles.find(t => t.Id == updatedRole.Id);
    if (existingRole == null) return;

    Object.assign(existingRole, updatedRole);

    this.setState(s => s);
  }

  @handle(DeleteCollaboratorRolesAction)
  private async handleDeleteCollaboratorRolesAction(
    action: DeleteCollaboratorRolesAction,
  ) {
    const role = this.state.roles.find(t =>
      t.Id == action.id
    );

    if (role == null) return;

    await this.collabRoleRepository.del(action.id);

    this.setState(state => ({
      ...state,
      roles: without(state.roles, role),
    }));
  }
}
