import { inject } from "fw";
import { createFrom } from "fw-model";
import { dispatch, handle, Store } from "fw-state";
import {
  FormResolverService,
  validateAll,
} from "shared/form-runtime";

import { StartAction, LogoutAction } from "./actions";
import {
  EntityChanged,
  WebSocketMessageAction,
  filterEntityChangedMessage
} from './filter-websocket-message';

import { getSeriesAndInstanceFromId } from "models/calendar-event";
import {
  CalendarEventRegistration,
  CalendarEventRegistrationStatus,
  CalendarEventRegistrationStatusPretty,
  ContactRegistrationResponse
} from "models/calendar-event-registration";
import { GroupFilter } from "models/filter-setup";
import { Form, FormAnswer } from "models/form";
import { InquiryResponseForm } from "forms/inquiry-response";

import { IListResults } from "network/ats";
import { CalendarEventRegistrationRepository } from "network/calendar-event-registration-repository";
import { FeatureFlagService } from "service/feature-flag";

import { FetchCurrentCalendarEventAction, RefreshCalendarSeriesTotalsAction } from "./current-calendar-event";
import { EnsureContactReferencesAction } from "./entity-reference";

export class FetchCurrentCalendarEventRegistrations {
  constructor(
    public seriesId: string,
    public instanceKey: string,
    public status?: CalendarEventRegistrationStatus,
  ) { }
}

export class ToggleRegistrantsSelectAllAction {
  constructor(public selectAll: boolean) { }
}

export class RegistrantsSelectedAction {
  constructor(public ids: string[], public registrants: CalendarEventRegistration[]) { }
}

export class NextPageAction {
  constructor() { }
}

export class PreviousPageAction {
  constructor() { }
}

export class UpdateContactRegistrationStatusAction {
  public updated: CalendarEventRegistration = null;
  public error = null;
  public get hasError(): boolean {
    return this.error;
  }

  constructor(
    public seriesId: string,
    public instanceKey: string,
    public contactId: string,
    public status?: CalendarEventRegistrationStatus,
  ) { }
}

export class UpdateContactRegistrationStatusBulkAction {
  public error = null;
  public get hasError(): boolean {
    return this.error;
  }

  constructor(
    public seriesId: string,
    public instanceKey: string,
    public contactIds: string[],
    public status?: CalendarEventRegistrationStatus,
  ) {}
}


export class ContactRegistrationStatusUpdatedAction {
  constructor(public updated: CalendarEventRegistration) {}
}

export class SearchCalendarEventRegistrants {
  constructor(public search: string) { }
}

export class SortCalendarEventRegistrants {
  constructor(public sort: string) { }
}

export class RegisterContactAction {
  public registrationResponse: ContactRegistrationResponse = null;
  public isValid: boolean = true;
  public error = null;
  public get hasError(): boolean {
    return this.error;
  }

  constructor(
    public calendarEventId: string,
    public contactId: string,
    public guestCount: number,
    public inquiryForm: Form,
    public response: InquiryResponseForm,
    public answers: FormAnswer[],
    public seenQuestionKeys: string[],
    public pruneAnswers = true
  ) { }
}

export class ContactRegisteredAction {
  constructor(public registrationResponse: ContactRegistrationResponse) { }
}

export class SetCurrentCalendarEventRegistrationAction {
  public current: CalendarEventRegistration = null;
  constructor(public calendarEventRegistrationId: string) { }
}

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_SORT = "first_name last_name";

export interface CurrentCalendarRegistrationsShape {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  list: IListResults<CalendarEventRegistration>;
  registrants: CalendarEventRegistration[];
  instanceKey: string;
  seriesId: string;
  status: CalendarEventRegistrationStatus;
  filter: GroupFilter;
  search: string;
  sort: string;
  selectAll: boolean;
  selectedIds: string[];
  selectedRegistrants: CalendarEventRegistration[];
  pageSize: number;
  currentPage: number;
  total: number;
  currentRegistration: CalendarEventRegistration;
}

@inject
export class CurrentCalendarEventRegistrations extends Store<CurrentCalendarRegistrationsShape> {
  constructor(
    private registrationRepo: CalendarEventRegistrationRepository,
    private formResolverService: FormResolverService,
    private ffs: FeatureFlagService,
  ) {
    super();
  }

  defaultState() {
    const emptyFilterContainer = createFrom(GroupFilter, {
      operation: "AND",
      filters: [],
    });

    return {
      loaded: false,
      loading: false,
      error: false,
      list: null,
      registrants: null,
      instanceKey: null,
      seriesId: null,
      status: null,
      filter: emptyFilterContainer,
      search: "",
      sort: DEFAULT_SORT,
      selectAll: false,
      selectedIds: [],
      selectedRegistrants: null,
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      total: 0,
      currentRegistration: null,
    };
  }

  @handle(StartAction)
  @handle(LogoutAction)
  private handleDefaults() {
    this.setState(() => this.defaultState());
  }


  @handle(FetchCurrentCalendarEventRegistrations)
  private async handleFetchCurrentCalendarEventRegistrations(action: FetchCurrentCalendarEventRegistrations) {
    if (this.state.seriesId !== action.seriesId || this.state.instanceKey != action.instanceKey) {
      const defaultState = this.defaultState();
      defaultState.seriesId = action.seriesId;
      defaultState.instanceKey = action.instanceKey;
      defaultState.status = action.status;
      this.setState(() => defaultState);
    }

    await this.loadRegistrants();
  }

  private async loadRegistrants() {
    if (this.state.loading)
      return;

    const { seriesId, instanceKey, status, search, sort, currentPage, pageSize } = this.state;
    if (this.ffs.isFeatureFlagEnabled("ExpandEditsToPublishedEventsOUT3758Apr2024") && !instanceKey) {
      this.setState(state => ({
        ...state,
        loaded: true,
        loading: false,
      }));
      return;
    }
    let wildcardSearch = `${search.charAt(0).toUpperCase()}${search.slice(1)}*`;
    if (search.length > 4 && CalendarEventRegistrationStatusPretty.attended.toLowerCase().startsWith(search.toLowerCase())) {
      // one-off: user has entered `check...` so let's add `attended` status to the query
      wildcardSearch = `${wildcardSearch} OR ${CalendarEventRegistrationStatus.attended}`;
    }

    this.setState(state => ({ ...state, loading: true }));

    try {
      const res = await this.registrationRepo.listByEvent(
        seriesId,
        instanceKey,
        status,
        null,
        wildcardSearch,
        null,
        sort,
        currentPage,
        pageSize,
      );

      const registrants = res?.results;
      this.setState(state => ({
        ...state,
        list: res,
        registrants,
        total: res.total,
        loaded: true,
        loading: false,
      }));

      // fetch contact info for registration avatars
      const contactIds: string[] = [];
      for (const registrant of registrants) {
        contactIds.push(registrant?.contact_id);
      }
      if (contactIds.length > 0) {
        dispatch(new EnsureContactReferencesAction(contactIds));
      }
    } catch (error) {
      this.setState(state => ({
        ...state,
        loaded: true,
        loading: false,
        error: true,
      }));
    }
  }

  @handle(ToggleRegistrantsSelectAllAction)
  async handleToggleRegistrantsSelectAllAction(action: ToggleRegistrantsSelectAllAction) {
    this.setState(state => ({
      ...state,
      selectAll: action.selectAll
    }));
  }

  @handle(RegistrantsSelectedAction)
  handleRegistrantsSelectedAction(action: RegistrantsSelectedAction) {
    this.setState(state => ({
      ...state,
      selectedIds: action.ids,
      selectedRegistrants: action.registrants
    }));
  }

  @handle(SearchCalendarEventRegistrants)
  public async handleSearchCalendarEventRegistrants(action: SearchCalendarEventRegistrants) {
    this.setState(state => ({
      ...state,
      search: action.search,
    }));

    await this.loadRegistrants();
  }

  @handle(SortCalendarEventRegistrants)
  async handleSortCalendarEventRegistrants(action: SortCalendarEventRegistrants) {
    let sort = action?.sort;
    if (!!sort) {
      if (sort === this.state?.sort) {
        sort = `-(${sort})`;
      }
      this.setState(state => ({
        ...state,
        sort,
      }));

      await this.loadRegistrants();
    }
  }

  @handle(UpdateContactRegistrationStatusAction)
  private async handleUpdateContactRegistrationStatus(action: UpdateContactRegistrationStatusAction) {
    try {
      action.updated = await this.registrationRepo.updateStatus(
        action.seriesId,
        action.instanceKey,
        action.status,
        action.contactId,
      );

      await this.ensureCurrentRegistration(action.updated);
      await dispatch(new ContactRegistrationStatusUpdatedAction(action.updated));

    } catch (ex) {
      action.error = ex;
      throw ex;
    }
  }

  @handle(UpdateContactRegistrationStatusBulkAction)
  private async handleUpdateContactRegistrationStatusBulkAction(action: UpdateContactRegistrationStatusBulkAction) {
    try {
      await this.registrationRepo.updateStatusBulk(
        action.seriesId,
        action.instanceKey,
        action.status,
        action.contactIds
      );

      if (action.status) {
        const registrants = this.state.selectedRegistrants.map(r => {
          r.status = action.status;
          return r;
        });

        this.setState(state => ({
          ...state,
          selectedRegistrants: registrants
        }));
      }

      await this.loadRegistrants();
    } catch (ex) {
      action.error = ex;
      throw ex;
    }
  }

  @handle(RegisterContactAction)
  private async handleRegisterContact(action: RegisterContactAction) {
    const form = action.inquiryForm;
    if (form == null) return;

    const isValid = validateAll(form, action.answers);
    action.response.validate();
    if (!isValid || action.response.isInvalid) {
      action.isValid = false;
      return;
    }

    if (action.pruneAnswers) {
      action.response.Answers = this.formResolverService.pruneAnswers(form, action.answers);
    }

    const { calendarEventSeriesId, instanceKey } = getSeriesAndInstanceFromId(action.calendarEventId);
    try {
      const registrationResponse = await this.registrationRepo.post(
        calendarEventSeriesId,
        instanceKey,
        action.contactId,
        action.guestCount,
        action.response.updatedModel(),
        action.seenQuestionKeys
      );
      action.registrationResponse = registrationResponse;
      await this.ensureCurrentRegistrationById(action.registrationResponse.calendar_event_registration_id);
      await dispatch(new ContactRegisteredAction(action.registrationResponse));

    } catch (err) {
      action.error = err;

      // try to reapply the validation
      if (err && err.result && err.result.validation_result) {
        for (const validationResult of err.result.validation_result) {
          if (validationResult.key == null) continue;
          const answer = action.response.Answers.find(
            a => a.QuestionKey == validationResult.key,
          );
          if (answer != null) {
            answer.MetaData.ValidationSummary = [];
            answer.MetaData.ValidationSummary.push({
              Message: validationResult.message,
              Path: validationResult.path,
            });
            answer.MetaData.IsValid = false;
          }
        }
        await dispatch(new RefreshCalendarSeriesTotalsAction());
      }
    }
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("CalendarEventRegistration"))
  private async handleEntityChangedAction(action: WebSocketMessageAction<EntityChanged>) {
    if (action.data?.id?.length > 0) {
      await this.ensureCurrentRegistrationById(action.data.id);
    }
  }

  @handle(NextPageAction)
  public async handleNextPageAction(action: NextPageAction) {
    const { currentPage, total, pageSize } = this.state;
    const start = Math.min(((currentPage - 1) * pageSize) + 1, total);

    if ((start + pageSize) > total)
      return;

    this.setState(state => ({
      ...state,
      currentPage: currentPage + 1
    }));

    await this.loadRegistrants();
  }

  @handle(PreviousPageAction)
  public async handlePreviousPageAction(action: PreviousPageAction) {
    const { currentPage, total, pageSize } = this.state;
    const start = Math.min(((currentPage - 1) * pageSize) + 1, total);

    if (currentPage < 2)
      return;

    this.setState(state => ({
      ...state,
      currentPage: currentPage - 1
    }));

    await this.loadRegistrants();
  }

  @handle(SetCurrentCalendarEventRegistrationAction)
  private async handleSetCurrentCalendarEventRegistration(action: SetCurrentCalendarEventRegistrationAction) {
    await this.ensureCurrentRegistrationById(action.calendarEventRegistrationId);
  }

  private async ensureCurrentRegistrationById(registrationId: string) {
    const registration = registrationId?.length > 0 ? await this.registrationRepo.get(registrationId) : null;
    await this.ensureCurrentRegistration(registration);
  }

  private async ensureCurrentRegistration(registration: CalendarEventRegistration) {
    this.setState(state => ({
      ...state,
      currentRegistration: registration,
    }));

    if (!registration)
      return;

    const { calendarEventSeriesId, instanceKey } = getSeriesAndInstanceFromId(registration.calendar_event_id);
    await dispatch(new FetchCurrentCalendarEventAction(true, calendarEventSeriesId, instanceKey));

    let hasChanged: boolean = false;
    const updatedList = this.state.registrants.map(item => {
      if (item.id == registration.id) {
        hasChanged = true;
        return registration;
      }
      return item;
    });

    if (hasChanged) {
      this.setState(state => ({
        ...state,
        registrants: updatedList
      }));
    } else {
      await dispatch(new FetchCurrentCalendarEventRegistrations(calendarEventSeriesId, instanceKey));
    }
  }
}
