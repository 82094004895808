import { inject, prop } from "@derekpitt/fw";
import { Form, FormSection, Question } from "../models";


@inject
export class Restrictable {
  @prop(null) form!: Form;
  @prop(null) section!: FormSection;
  @prop(null) question!: Question;
  @prop(() => []) restrictions!: string[];
  @prop("div") element!: string;

  constructor() { }

  get entireFormPath() {
    if (this.form == null) return null;
    return `${this.form.Key}.*`;
  }

  path() {
    if (!this.form || !this.form.Key) return null;
    if (!this.question) return this.entireFormPath;

    const sectionKey = this.section && this.section.IsTableSection ?
      `${this.section.TableSectionOptions.Key}.rows.` : "";
    return `${this.form.Key}.${sectionKey}${this.question.Key}`;
  }

  get isRestricted() {
    const entireFormPath = this.entireFormPath;
    return this.restrictions && !!this.restrictions.find(path => path == this.path() || path == entireFormPath);
  }
}
