import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { EmailTask } from "models/email-task";
import { ClientModelSearchResult } from "models/application";
import { EntitySelection } from "models/application-client-model";
import { CustomEmailTemplateTypeCode, EmailTemplate } from "models/email-template";
import { TaskRequest } from "models/task-request";
import { encodeEmailTemplate } from "./encode";

@inject
export class EmailTaskRepository {
  constructor(private s: ATS) {}

  async get(id: string) {
    const res = await this.s.get(`email-task/${id}`);
    return createFrom(EmailTask, res.body);
  }

  async list(
    seasonId: string = null,
    search: string = null,
    filter: string = null,
    sort: string = null,
    pageSize: number = null,
    previousPageToken: string = null,
    nextPageToken: string = null
  ) {
    const res = await this.s.get<ClientModelSearchResult>("email-task", {
      seasonId,
      search,
      filter,
      sort,
      pageSize,
      previousPageToken,
      nextPageToken,
    });
    return createFrom(ClientModelSearchResult, res.body);
  }

  public async send(
    type: CustomEmailTemplateTypeCode,
    selection: EntitySelection,
    emailTemplate: EmailTemplate
  ): Promise<TaskRequest> {
    const encoded = encodeEmailTemplate(emailTemplate);
    const res = await this.s.post("email-task/send", { Type: type, Selection: selection, Template: encoded });
    return createFrom(TaskRequest, res.body);
  }
}
