import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import {
  Organization,
  Season,
  OrganizationPostArgs,
  OrganizationPutArgs
} from "models/organization";

@inject
export class OrganizationRepository {
  constructor(private s: ATS) {}

  public async get(id: string) {
    const res = await this.s.get(`organization/${id}`);
    return createFrom(Organization, res.body);
  }

  public async list() {
    const res = await this.s.get<any[]>("organization");
    return createFromArray(Organization, res.body);
  }

  public async delete(id: string) {
    await this.s.delete(`organization/${id}`);
  }

  public async post(args: OrganizationPostArgs) {
    const res = await this.s.post("organization", args);
    return createFrom(Organization, res.body);
  }

  public async put(organizationId: string, args: OrganizationPutArgs) {
    const res = await this.s.put(`organization/${organizationId}`, args);
    return createFrom(Organization, res.body);
  }

  public async postSeason(organizationId: string, args: PostSeasonArgs) {
    const res = await this.s.post(`season`, args);
    return createFrom(Season, res.body);
  }

  public async putActiveModules(organizationId: string, args: ModulePutArgs) {
    const res = await this.s.put(`organization/${organizationId}/modules`, args);
    return createFrom(Organization, res.body);
  }
}

export interface PostSeasonArgs {
  Name: string;
}

export interface ModulePutArgs {
  ModuleKeys: string[];
  Features: string[];
}
