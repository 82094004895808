import { formFor, FormForType } from "fw-model";

import {
  RoutingTable,
  RoutingTableCell,
  RoutingTableRow,
  RoutingTableColumn,
  RoutingTableFallThroughAssignment,
  AssignmentType,
} from "models/routing-table";

export type FormForRoutingTable = FormForType<RoutingTable>;
export type FormForRoutingTableColumn = FormForType<RoutingTableColumn>;
export type FormForRoutingTableRow = FormForType<RoutingTableRow>;
export type FormForRoutingTableCell = FormForType<RoutingTableCell>;
export type FormForRoutingTableFallThroughAssignment = FormForType<
  RoutingTableFallThroughAssignment
>;

export class UpdateRoutingTableAction {
  constructor(public form: FormForRoutingTable) {}
}

export class AddRoutingTableAction {
  constructor(public form: FormForRoutingTable, public type: AssignmentType) {}
}

export const routingTableColumnFormCreator = formFor(RoutingTableColumn, s => {
  s.requiredField(i => i.Label, "Label");
  s.requiredField(i => i.Path, "Path");
});

export const routingTableCellFormCreator = formFor(RoutingTableCell, s => {
  s.field(i => i.StringValue, "Value");
});

export const routingTableRowFormCreator = formFor(RoutingTableRow, s => {
  s.field(i => i.TeamId, "Team");
  s.field(i => i.UserId, "User");

  s.formArray(i => i.Cells, "Cells", routingTableCellFormCreator);
});

export const routingTableFallThroughAssignmentFormCreator = formFor(
  RoutingTableFallThroughAssignment,
  s => {
    s.field(i => i.TeamId, "Team");
    s.field(i => i.UserId, "User");
  },
);

export const routingTableFormCreator = formFor(RoutingTable, s => {
  s.requiredField(i => i.Name, "Name");

  s.form(
    i => i.FallThroughAssignment,
    "Fall Through Assignment",
    routingTableFallThroughAssignmentFormCreator,
  );
  s.formArray(i => i.Columns, "Columns", routingTableColumnFormCreator);
  s.formArray(i => i.Rows, "Rows", routingTableRowFormCreator);
});
