import { prop, ComponentEventBus, inject } from "fw";

let uniqueId = 0;

export interface IDropdownFieldOptions {
  value: any;
  text: string;
}

@inject
export class DropdownField {
  constructor(private componentEventBus: ComponentEventBus) {}

  @prop(false) private nullIsDirty;
  @prop(null) private label;
  @prop(false) public hideLabel!: boolean;
  @prop(null) public ariaLabel?: string;
  @prop(null) private value;
  @prop(null) private required;
  @prop(null) private dataOptionId!: (value) => string;
  @prop(false) private disabled;
  @prop(null) private validation;
  @prop(() => []) private options!: IDropdownFieldOptions[];
  @prop(null) public meta!: string;
  @prop(false) public showClear!: boolean; 

  private uniqueId = uniqueId++;
  private isFocused = false;
  private localValue = null;

  private makeId() {
    return `${this.label ? this.label.replace(/\s/g, "") : ""}-sf-${
      this.uniqueId
    }`;
  }

  private onFocus() {
    this.isFocused = true;
  }
  private onBlur() {
    this.isFocused = false;
  }

  attached() {
    this.valueChanged();
  }

  valueChanged() {
    this.localValue = this.value;
  }

  private onChange() {
    this.componentEventBus.dispatch("update:value", this.localValue);
    this.componentEventBus.dispatch("change");
  }

  public dataId(option: IDropdownFieldOptions): string {
    if (!option || !option.value == null) {
      return null;
    }

    try {
      return this.dataOptionId != null
        ? this.dataOptionId(option.value)
        : option.value;
    } catch (e) {
      return option.value;
    }
  }

  public clear() {
    this.localValue = undefined;
    this.onChange();
  }
}
