import { inject, prop } from "fw";
import { dispatch } from "fw-state";

import { CollaborationAssignedUserFilter as CollaborationAssignedUserFilterModel } from "models/application-filters";
import { CollaborationModulesStore } from "state/collaboration-modules";
import { UsersStore, EnsureUsersAction } from "state/users";
import { CurrentOrganizationStore } from "state/current-organization";
import { hashOn } from "hashing";
import { PopoverService } from "service/popover";
import { SelectUserPopover } from "views/components/select-user-popover";

@inject
export class CollaborationAssignedUserFilter {
  @prop(null)
  filter: CollaborationAssignedUserFilterModel;

  @prop(() => ({}))
  validation;

  @prop(false)
  editing: boolean;

  constructor(
    private currentOrganizationStore: CurrentOrganizationStore,
    private moduleStore: CollaborationModulesStore,
    private usersStore: UsersStore,
    private popover: PopoverService,
  ) {}

  attached() {
    dispatch(new EnsureUsersAction([ this.filter.userId ]));
  }

  get moduleNameHash() {
    return hashOn(this.moduleStore.state.modules, m => m.Key, m => m.Name);
  }

  get modules() {
    return this.moduleStore.state.modules.map(m => ({
      text: m.Name,
      value: m.Key,
    }));
  }

  get users() {
    if (this.filter == null) return [];
    if (this.filter.moduleKey == null) return [];

    const mod = this.moduleStore.state.modules.find(
      m => m.Key == this.filter.moduleKey,
    );
    if (mod == null) return [];

    return this.usersStore.state.users
      .filter(u => {
        const membership = u.Memberships.find(
          m =>
            m.OrganizationId ==
            this.currentOrganizationStore.state.organization.Id,
        );
        if (membership == null) return false;

        return (
          membership.CollaboratorRoleIds != null &&
          membership.CollaboratorRoleIds.length > 0 &&
          membership.CollaboratorRoleIds.some(roleId =>
            mod.CollaboratorRoleIds.some(modRoleId => modRoleId == roleId),
          )
        );
      })
      .map(u => ({ text: `${u.FirstName} ${u.LastName}`, value: u.Id }));
  }

  get userHash() {
    return hashOn(
      this.usersStore.state.users,
      u => u.Id,
      u => `${u.FirstName} ${u.LastName}`,
    );
  }

  async selectUser() {
    const res = await this.popover.open<string>(SelectUserPopover);
    if (res.canceled) return;

    this.filter.userId = res.result;
    dispatch(new EnsureUsersAction([ res.result ]));
  }

  get selectedUser() {
    if (this.filter == null || this.filter.userId == null) return "Choose User";

    const u = this.usersStore.state.userHash[this.filter.userId];
    if (u == null) return "Loading...";

    return `${u.FirstName} ${u.LastName}`;
  }
}
