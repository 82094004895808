export class PortalPreviewArgs {
  CssSelector: string;
  Css: string;
  GlobalCssSelector: string;
  GlobalCss: string;
}

export class PortalPreviewResult {
  ScopedCss: string;
  ScopedGlobalCss: string;
}
