import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";

import { ExportTaskRepository } from "network/export-task-repository";
import { TaskRequestRepository } from "network/task-request-repository";

import {
  LogoutAction,
  StartAction,
  TaskUpdatedAction,
  TaskFinishedAction,
  RetryExportAction,
  WatchTaskAction,
} from "./actions";
import { ExportTask } from "models/export-task";
import { difference } from "lodash-es";

interface ExportDownloadStoreShape {
  loaded: boolean;
  newIds: string[];
  downloads: ExportTask[];
}

export class DeleteExportDownloadAction {
  constructor(public ids: string[]) { }
}

export class ExportDownloadDeleteAction {
  constructor(public exportTasks: string[]) { }
}

export class EnsureExportDownloadsAction { }

export class ClearExportDownloadsAction { }

@inject
export class ExportDownloadStore extends Store<ExportDownloadStoreShape> {
  constructor(
    private exportTaskRepo: ExportTaskRepository,
    private taskRequestRepo: TaskRequestRepository,
  ) {
    super();
  }

  defaultState() {
    return {
      loaded: false,
      newIds: [],
      downloads: null,
    };
  }

  @handle(LogoutAction)
  @handle(StartAction)
  private handleStartAction(s: StartAction) {
    this.setState(s => this.defaultState());
  }

  @handle(ClearExportDownloadsAction)
  private handleClearExportDownloadsAction() {
    this.setState(state => ({
      ...state,
      loaded: false,
      downloads: null,
    }));
  }

  @handle(EnsureExportDownloadsAction)
  private async handleEnsureExportDownloads() {
    if (this.state.loaded) return;

    const result = await this.exportTaskRepo.downloads(
      null,
      1,
      1000,
      null,
      null,
      "-metaData.dateCreatedUtc");

    this.setState(state => ({
      ...state,
      loaded: true,
      newIds: [],
      downloads: result.list,
    }));
  }

  @handle(RetryExportAction)
  private async handleRetryExportAction(action: RetryExportAction) {
    const updatedTask = await this.exportTaskRepo.put(
      action.exportTask.Id,
      true,
    );

    if (this.state.downloads == null) return;

    const existingRunningTask = this.state.downloads.find(
      t => t.Id == action.exportTask.Id,
    );
    if (existingRunningTask == null) return;

    Object.assign(existingRunningTask, updatedTask);

    if (action.watch) {
      const task = await this.taskRequestRepo.get(updatedTask.MetaData.TaskRequestId);
      await dispatch(new TaskUpdatedAction(task));
      await dispatch(new WatchTaskAction(task, task.Type));
    }

    this.setState(s => s);
  }

  @handle(TaskUpdatedAction)
  private async handleTaskUpdatedAction(action: TaskUpdatedAction) {
    if (this.state.downloads == null) return;
    const existingRunningTask = this.state.downloads.find(
      t => t.MetaData.TaskRequestId == action.taskRequest.Id,
    );
    if (existingRunningTask == null) return;

    const task = await this.exportTaskRepo.get(existingRunningTask.Id);

    Object.assign(existingRunningTask, task);

    this.setState(state => state);
  }

  @handle(TaskFinishedAction)
  private async handleTaskFinishedAction(action: TaskFinishedAction) {
    if (action.taskRequest.Data["exportTaskId"] == null) return;

    const exportTaskId = action.taskRequest.Data["exportTaskId"];

    this.setState(state => ({
      ...state,
      newIds: [...state.newIds, exportTaskId],
    }));
  }

  @handle(ExportDownloadDeleteAction)
  private async handleDelete(fd: ExportDownloadDeleteAction) {
    const downloads = this.state.downloads.filter(f =>
      fd.exportTasks.some(id => id == f.Id),
    );

    await this.exportTaskRepo.del(downloads.map(f => f.Id));

    this.setState(state => ({
      ...state,
      loaded: true,
      downloads: difference(state.downloads, downloads),
    }));
  }
}
