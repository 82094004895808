import { formFor, FormForType } from "fw-model";

import {
  Organization,
  OrganizationPostArgs,
  OrganizationPutArgs,
} from "models/organization";

export type AddOrganizationForm = FormForType<OrganizationPostArgs>;

export const addOrganizationFormCreator = formFor(OrganizationPostArgs, s => {
  s.requiredField(o => o.Domain, "Domain");
  s.requiredField(o => o.Name, "Organization Name");
  s.requiredField(o => o.Timezone, "Timezone");
});

export class AddOrganizationAction {
  public addedOrganization: Organization;
  constructor(public form: AddOrganizationForm) {}
}

export type OrganizationForm = FormForType<OrganizationPutArgs>;

export class UpdateOrganizationAction {
  constructor(public form: OrganizationForm) {}
}

export const updateOrganizationFormCreator = formFor(OrganizationPutArgs, s => {
  s.requiredField(o => o.Domain, "Domain");
  s.requiredField(o => o.Name, "Name");
  s.requiredField(o => o.Timezone, "Timezone");
});
