import { ComponentEventBus, inject, prop } from "fw";
import type { ConditionInfoFormType } from "forms/condition-info";
import { DialogService } from "fw-dialog";
import { DataDictionaryField } from "models/data-dictionary";
import { ConditionBuilderDialog, ConditionBuilderDialogParams, ConditionInfoDialogResult } from "./condition-builder-dialog";
import { Completer } from "helpers/auto-complete";

@inject
export class OpenConditionBuilderDialog {
  @prop(null) public fields: DataDictionaryField[];
  @prop(null) public completer: Completer | Completer[];
  @prop(null) public conditionInfo: ConditionInfoFormType;
  @prop(null) public condition: string;
  @prop(null) public title: string;
  @prop(false) public disabled: boolean;
  @prop(true) public showClear: boolean;
  @prop(true) public showFunctionSelector: boolean;
  @prop(true) public showFieldDictionaryLink: boolean;
  @prop(false) public expressionModeOnly!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private dialog: DialogService
  ) { }

  public attached() {
    this.ceb.dispatch("open", this.open)
  }

  public async open() {
    this.ceb.dispatch("opened");

    const result = await this.dialog.open<ConditionInfoDialogResult>(ConditionBuilderDialog, <ConditionBuilderDialogParams>{
      completer: this.completer,
      condition: this.condition,
      conditionInfo: this.conditionInfo?.updatedModel(),
      fields: this.fields,
      disabled: this.disabled,
      showClear: this.showClear,
      showFunctionSelector: this.showFunctionSelector,
      showFieldDictionaryLink: this.showFieldDictionaryLink,
      expressionModeOnly: this.expressionModeOnly
    });

    if (!result.canceled) {
      this.conditionInfo?.applyModel(result.result.conditionInfo);
      this.ceb.dispatch("update:condition", result.result.condition);
      this.ceb.dispatch("changed", result.result);
    }
  }
}
