import { ComponentEventBus, inject, prop, needs } from "fw";
import { FieldMode } from "../field-mode";
import { ConcealedField } from "views/components/concealed-field";
import { ConcealedTextField } from "views/components/concealed-text-field";
import { ContactRepository } from "network/contact-repository";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { FieldContext } from "../field";
import { ContactsOrganizationService } from "service/contacts-organization";

@inject
@needs(ConcealedField, ConcealedTextField)
export class ConcealedType {
  @prop(null) public field!: ICustomFieldDefinition;
  @prop(null) public value!: string;
  @prop(null) public context!: FieldContext;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(true) public setfocus!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private contactRepo: ContactRepository,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  public get metaDescription() {
    return this.showMeta
      ? this.contactOrganizationService.getFieldMetaDescription(this.field)
      : null;
  }

  public get label() {
    return this.showLabel ? this.field.display_name : null;
  }

  public get localValue() {
    return this.value;
  }

  public set localValue(value: string) {
    this.ceb.dispatch("update:value", value);
  }

  public get localDecryptedValue() {
    return this.context?.decryptedValue;
  }

  public set localDecryptedValue(decryptedValue: string) {
    this.ceb.dispatch("update:context", { ...this.context, decryptedValue });
  }

  public async decrypt(value: string): Promise<string> {
    const decryptedValue = await this.contactRepo.decrypt(
      this.context.contactId,
      this.field.id,
      this.context.contactMetaValueId
    );
    return decryptedValue?.value;
  }
}
