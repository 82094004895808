import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS, IListResults } from "./ats";
import { encodeEmailTemplatePreviewArgs, encodeMarketingOutreachItem } from "./encode";
import { MarketingOutreachItem } from "models/marketing-outreach-item";
import { MarketingOutreachSent } from "models/marketing-outreach-sent";
import { MarketingOutreachArchive } from "models/marketing-outreach-archive";
import { EmailTemplatePreview } from "models/email-template";
import { EmailContact } from "models/email-contact";
import { MarketingOutreachArchiveContent, MarketingOutreachArchiveType } from "models/marketing-outreach-archive-content";

@inject
export class MarketingOutreachItemRepository {
  constructor(private network: ATS) {}

  async index(tierId: number = 0, trackId: number = 0, templateId: number = 0) {
    const type = tierId ? 'tier' : trackId ? 'track' : 'email-template';
    const id = tierId || trackId || templateId;
    const res = await this.network.marketing.get<any[]>(`outreach/index/${type}/${id}`);
    return createFromArray(MarketingOutreachItem, res.body);
  }

  async show(id: number) {
    const res = await this.network.marketing.get<MarketingOutreachItem>(`outreach/${id}`);
    return createFrom(MarketingOutreachItem, res.body);
  }

  async post(outreach: MarketingOutreachItem) {
    const encoded = encodeMarketingOutreachItem(outreach);
    const res = await this.network.marketing.post<MarketingOutreachItem>("outreach", encoded);
    return createFrom(MarketingOutreachItem, res.body);
  }

  async put(outreach: MarketingOutreachItem) {
    const encoded = encodeMarketingOutreachItem(outreach);
    const res = await this.network.marketing.put<MarketingOutreachItem>(`outreach/${outreach.Id}`, encoded);
    return createFrom(MarketingOutreachItem, res.body);
  }

  async destroy(id: number) {
    await this.network.marketing.delete<MarketingOutreachItem>(`outreach/${id}`);
  }

  async copy(id: number) {
    const res = await this.network.marketing.post<MarketingOutreachItem>("outreach/copy", {Id: id});
    return createFrom(MarketingOutreachItem, res.body);
  }

  async move(itemId: number, tierId: number) {
    const res = await this.network.marketing.post<MarketingOutreachItem>("outreach/move", {ItemId: itemId, TierId: tierId});
    return createFrom(MarketingOutreachItem, res.body);
  }

  async activate(id: number) {
    const res = await this.network.marketing.post<MarketingOutreachItem>("outreach/activate", {Id: id});
    return createFrom(MarketingOutreachItem, res.body);
  }

  async deactivate(id: number) {
    const res = await this.network.marketing.post<MarketingOutreachItem>("outreach/deactivate", {Id: id});
    return createFrom(MarketingOutreachItem, res.body);
  }

  async sentList(
    q: string = null,
    f: string = null,
    aggs: string = null,
    sort: string = null,
    page: number = 1,
    limit: number = 10
  ): Promise<IListResults<MarketingOutreachSent>> {
    return this.network.marketing.list("outreach/sent_list", MarketingOutreachSent, q, f, aggs, sort, page, limit);
  }

  async sentContent(
    emailAddress: string = null,
    outreachSentId: string = null,
    archiveId: string = null,
    type: MarketingOutreachArchiveType = null
  ): Promise<MarketingOutreachArchiveContent> {
    let params = {
      emailAddress: emailAddress,
      outreachSentId: outreachSentId,
      archiveId: archiveId,
      type: type
    }
    const res = await this.network.marketing.get<MarketingOutreachArchiveContent>("outreach/sent_content", params);
    return createFrom(MarketingOutreachArchiveContent, res.body);
  }

  async resend(
    deliveryAddress: string = null,
    archiveId: string = null,
    type: MarketingOutreachArchiveType = null
  ): Promise<MarketingResendResult> {
    let params = {
      deliveryAddress: deliveryAddress,
      archiveId: archiveId,
      type: type
    }

    const res = await this.network.marketing.post<MarketingResendResult>("outreach/resend", params);
    return createFrom(MarketingResendResult, res.body);
  }

  async deliveryList(
    q: string = null,
    f: string = null,
    aggs: string = null,
    sort: string = null,
    page: number = 1,
    limit: number = 10
  ): Promise<IListResults<MarketingOutreachArchive>> {
    return this.network.marketing.list("outreach/delivery_list", MarketingOutreachArchive, q, f, aggs, sort, page, limit);
  }

  async previewEmail(args: EmailTemplatePreviewArgs) {
    const encoded = encodeEmailTemplatePreviewArgs(args);
    const res = await this.network.post("email-template/preview-email", encoded);
    return createFrom(EmailTemplatePreview, res.body);
  }

  async sendSystemEmail(args: any) {
    const encoded = encodeEmailTemplatePreviewArgs(args);
    const res = await this.network.marketing.post<MarketingOutreachItem>("outreach/send_system_email", encoded);
    return createFrom(MarketingSendSystemEmailResult, {id: res.body});
  }

  async uploadFiles(files): Promise<MarketingUploadResult> {
    let data = new FormData();
    files.forEach(file => data.append("files[]", file));
    const res = await this.network.marketing.post("outreach/upload_files", data);
    return createFrom(MarketingUploadResult, res.body);

  }

  async beeEditorAuthInfo(): Promise<BeeEditorAuthInfo> {
    const res = await this.network.marketing.get<BeeEditorAuthInfo>("outreach/get_bee_editor_auth_token");
    return createFrom(BeeEditorAuthInfo, res.body);
  }
}

export class EmailTemplatePreviewArgs {
  public To: string;
  public From: EmailContact;
  public ReplyTo: EmailContact;
  public Subject: string;
  public Text: string;
  public Html: string;
  public Cc: string;
  public Bcc: string;
  public Tokens: object;
  public Test: number;
}

export class BeeEditorAuthInfo {
  public authToken: string;
  public uid: string;
}

export class MarketingUploadResult {
  public results: MarketingUploadedFile[];
  public error: string;
}

export class MarketingUploadedFile {
  public name: string;
  public src: string;
}

export class MarketingResendJob {
  public id: string;
}

export class MarketingResendResult {
  public results: MarketingResendJob;
  public error: string;
  public message: string;
}

export class MarketingSendSystemEmailResult {
  public id: string;
}
