import { inject } from "fw";
import { createFrom } from "fw-model";

import { ATS } from "./ats";
import { ContactsIntegrationSettings } from "models/contacts-integration-settings";

@inject
export class IntegrationRepository {
  constructor(private s: ATS) { }

  async getContactsSettings() {
    const res = await this.s.get("integration/contacts-api");
    return createFrom(ContactsIntegrationSettings, res.body);
  }

  async putContactsSettings(settings: ContactsIntegrationSettings) {
    const res = await this.s.put("integration/contacts-api", settings);
    return createFrom(ContactsIntegrationSettings, res.body);
  }

  async proxyContacts<T>(path: string, apiKey: string = null) {
    const res = await this.s.get<T>("integration/contacts-api/proxy", { path, apiKey });
    return res.body;
  }
}
