import { inject } from "fw";
import { handle, Store } from "fw-state";
import { debounce, difference } from "lodash-es";

import { LogoutAction, StartAction } from "./actions";
import { cleanProgram, Program, ProgramStep, ProgramStepGroup } from "models/program";
import { EditProgramAction, ProgramCreateAction, programFormCreator } from "forms/program";
import { ProgramRepository } from "network/program-repository";
import { FormErrorHandling } from "./error-handling";
import { FormForType } from 'fw-model';
import { ProgramSettings } from "models/program-settings";
import { ProgramPropertyForm } from 'forms/application-settings';
import { ProgramSettingsRepository } from "network/program-settings-repository";
import {
  EntityChanged,
  WebSocketMessageAction,
  filterEntityChangedMessage
} from './filter-websocket-message';
import { AppRepository } from "../network/app-repository";
import { ChangeType } from "./message-broker-actions";
import { SearchAfterListResult } from "models/search-after-list-result";

export interface SelectedProgramState {
  id: string;
  version: string;
  isDeleted: boolean;
}

export interface SuspendedState {
  currentProgramStepFormIndex: number,
  currentProgramStepGroupIndex: number,
  startingStageIndex: number,
  currentMediaTypeIndex?: number,
}

export class RefreshProgramsAction { }

export class SuspendProgramAction {
  constructor(public program: FormForType<Program>,
    public state: SuspendedState) { }
}

export class SelectProgramAction {
  constructor(public programId: string, public version: string) { }
}

export class ClearProgramStaleState { }

export class ClearSuspendedProgramAction { }

export class SetResumedProgramEdit {
  constructor(
    public programStep: FormForType<ProgramStep>,
    public stepGroup: ProgramStepGroup
  ) { }
}

export class DeleteResumedProgramEdit { }

export class EnsureProgramsPageAction {
    constructor(
      public pageSize: number,
      public filter: string,
      //public search: string,
      public sort: string = null,
      public previousPageToken: string = null,
      public nextPageToken: string = null,
      public fields:string = null,
      public includeHidden:boolean = false
    ) { }
}

export class UpdateProgramPropertiesAction {
  constructor(public properties: ProgramPropertyForm[]) { }
}

interface ProgramShape {
  programs: Program[];

  currentProgramsPage?: SearchAfterListResult<Program>;
  page: number;
  loaded: boolean;
  loading: boolean;
  total: number;
  errorLoading: boolean;

  suspendedProgram: FormForType<Program>;
  suspendedState: SuspendedState;
  settings: ProgramSettings;
  selectedProgramState: SelectedProgramState;
  resumed: {
    programStep: FormForType<ProgramStep>;
    stepGroup: ProgramStepGroup;
  }
}

export class ProgramDeleteAction {
  constructor(public Programs: string[]) { }
}

@inject
export class ProgramStore extends Store<ProgramShape> {
  private debouncedReloadPrograms = async () => { };

  constructor(
    private programRepo: ProgramRepository,
    private programSettingsRepo: ProgramSettingsRepository,
    private applicationRepo: AppRepository
  ) {
    super();
    this.debouncedReloadPrograms = debounce(() => this.updateProgramContext(), 2000, { maxWait: 5000 });
  }

  defaultState() {
    return {
      programs: [],
      suspendedProgram: null,
      suspendedState: null,
      settings: null,
      selectedProgramState: null,
      resumed: null,
      page: 1,
      loaded: false,
      loading: false,
      total: 0,
      errorLoading: false,
    };
  }

  @handle(StartAction)
  private async handleStart(s: StartAction) {
    const init = this.defaultState();
    init.programs = s.context.Programs;
    init.settings = s.context.Season.ProgramSettings;
    this.setState(() => init);
  }

  @handle(LogoutAction)
  private handleLogout() {
    this.setState(s => this.defaultState());
  }

  @handle(EnsureProgramsPageAction)
  private async handleEnsureProgramsPageAction(action:EnsureProgramsPageAction) {

    const changedPgNum =  (!action.previousPageToken && !action.nextPageToken) ? 1 : (!action.previousPageToken) ? this.state.page+1 : this.state.page-1;

    this.setState(state => ({
      ...state,
      loaded: false,
      loading: true,
      errorLoading: false,
    }));

    try {
      const result = await this.programRepo.search(action.pageSize, action.filter, action.sort, action.previousPageToken, action.nextPageToken, action.fields, action.includeHidden);

      this.setState(state => ({
        ...state,
        currentProgramsPage: result,
        page: changedPgNum,
        total: result.Total,
        loaded: true,
        loading: false
      }));
    } catch(error) {

      // console.error("Error in program repository search: ");
      // console.debug(error);

      this.setState(state => ({
        ...state,
        currentProgramsPage: null,
        page: null,
        loaded: true,
        loading: false,
        errorLoading: true
      }));
    }
  }

  @handle(SelectProgramAction)
  private async handleSelectProgram(action: SelectProgramAction) {
    this.setState(state => ({
      ...state,
      selectedProgramState: !!action.programId
        ? {
          id: action.programId,
          version: action.version,
          isDeleted: false
        }
        : null
    }));
  }

  @handle(ProgramDeleteAction)
  private async handleDelete(pd: ProgramDeleteAction) {
    const programs = this.state.programs.filter(p => pd.Programs.some(id => id == p.Id));
    await this.programRepo.del(programs.map(p => p.Id));
    this.setState(state => ({
      ...state,
      programs: difference(state.programs, programs),
      selectedProgramState: null
    }));
  }

  @handle(ProgramCreateAction, FormErrorHandling)
  private async handleCreate(pc: ProgramCreateAction) {
    pc.form.validate();

    const programToCommit = cleanProgram(pc.form.updatedModel());
    const newProgram = await this.programRepo.create(programToCommit);

    this.setState(state => ({
      ...state,
      programs: [...state.programs, newProgram],
      selectedProgramState: {
        id: newProgram.Id,
        version: newProgram.MetaData.Version,
        isDeleted: false
      }
    }));

    pc.createdId = newProgram.Id;
  }

  @handle(EditProgramAction, FormErrorHandling)
  private async handleEditProgram(i: EditProgramAction) {
    i.form.validate();

    const programToCommit = cleanProgram(i.form.updatedModel());
    const updatedProgram = await this.programRepo.update(programToCommit);
    i.form = programFormCreator(updatedProgram);

    const existingProgram = this.state.programs.find(p => p.Id == updatedProgram.Id);
    if (existingProgram == null) {
      return;
    }

    Object.assign(existingProgram, updatedProgram);
    this.setState(state => ({
      ...state,
      selectedProgramState: {
        id: updatedProgram.Id,
        version: updatedProgram.MetaData.Version,
        isDeleted: false
      }
    }));
  }

  @handle(SuspendProgramAction)
  private handleSuspendProgramAction(action: SuspendProgramAction) {
    this.setState((state) => ({
      ...state,
      suspendedProgram: action.program,
      suspendedState: action.state
    }));
  }

  @handle(ClearSuspendedProgramAction)
  private handleClearSuspendedProgramAction(action: ClearSuspendedProgramAction) {
    this.setState((state) => ({
      ...state,
      suspendedProgram: null,
      suspendedState: null
    }));
  }

  @handle(UpdateProgramPropertiesAction)
  private async handleUpdateProgramPropertiesAction(action: UpdateProgramPropertiesAction) {
    const properties = await this.programSettingsRepo.putProperties(action.properties.map(p => p.updatedModel()));

    this.setState(state => ({
      ...state,
      settings: {
        ...state.settings,
        ProgramProperties: properties,
      },
    }));
  }

  @handle(RefreshProgramsAction)
  private async handleRefreshPrograms(action: RefreshProgramsAction) {
    await this.updateProgramContext();
  }

  @handle(SetResumedProgramEdit)
  private async handleSetResumedProgramEdit(action: SetResumedProgramEdit) {
    this.setState(state => ({
      ...state,
      resumed: {
        programStep: action.programStep,
        stepGroup: action.stepGroup
      }
    }));
  }

  @handle(DeleteResumedProgramEdit)
  private async handleDeleteResumedProgramEdit() {
    this.setState(state => ({
      ...state,
      resumed: null
    }));
  }

  @handle(WebSocketMessageAction, filterEntityChangedMessage("Program"))
  private async handleEntityChangedAction(action: WebSocketMessageAction<EntityChanged>) {
    await this.debouncedReloadPrograms();

    let { selectedProgramState } = this.state;
    if (!action?.data?.id || !selectedProgramState?.id || action.data.id !== selectedProgramState.id)
      return;

    switch (action.data.changeType) {
      case ChangeType.Removed:

        this.setState(state => ({
          ...state,
          selectedProgramState: {
            id: selectedProgramState.id,
            version: null,
            isDeleted: true
          }
        }));

        break;

      case ChangeType.Saved:

        const program = await this.programRepo.get(selectedProgramState.id, false);
        this.setState(state => ({
          ...state,
          selectedProgramState: {
            id: selectedProgramState.id,
            version: program.MetaData.Version,
            isDeleted: false
          }
        }));


        break;
    }
  }

  private async updateProgramContext() {
    const context = await this.applicationRepo.organizationContext();
    this.setState(state => ({
      ...state,
      programs: context.Programs,
      settings: context.Season.ProgramSettings
    }))
  }

}
