import { inject, prop, needs } from "fw";

import { ValueType, ValueField } from "views/components/application-filters/value-field";
import { FeatureFlagService } from "service/feature-flag";
import { getFriendlyTiming } from "models/date-filter";
import { DateStageChangedFilter as DateStageChangedFilterModel } from "models/application-filters";

@inject
@needs(ValueField)
export class DateStageChangedFilter {
  @prop(null) filter!: DateStageChangedFilterModel;
  @prop(false) editing!: boolean;
  @prop(() => ({})) validation;

  constructor(
    private ffs: FeatureFlagService
  ) {}

  valueType = ValueType.Date;
  get userTypeFriendly() {
    return "stage changed"
  }
  get friendlySince() {
    if (this.filter == null) return "";
    return getFriendlyTiming(this.filter);
  }
  
}
