import { dispatch } from "fw-state";
import { getPortalContext } from "service/get-portal-context";
import { SeedOrganizationPortalContextAction } from "state/current-organization";

export const seedOrganizationPortal = async () => {
  try {
    const context = await getPortalContext();

    await dispatch(new SeedOrganizationPortalContextAction(context));
  } catch {
    // we previously deleted the auth token here, but that forcibly logs out users that might be on unknown domains
    // that would be ok for first login, but also fires on refresh and logs them out. don't currently see a need here
    // for that behavior as we are intentionally allowing users to operate on the site via unknown domains
  }
}