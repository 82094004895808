import { fromClass, fromClassArray } from "fw-model";

export class PdfExportTemplate {
  Template: string;
}

export type PdfExportTemplateHash = {
  [key: string]: PdfExportTemplate
}

export class PdfExportTemplateSetOptions {
  MarginInches: number;
}

export class PdfExportTemplateSet {
  @fromClass Options: PdfExportTemplateSetOptions;
  Templates: PdfExportTemplateHash = {};
}

export class ExportHtmlPage {
  RenderedHtml: string;
  Parameters: any;
}

export class ExportHtmlResult {
  Css: string;
  RenderedHeaderHtml: string;
  RenderedFooterHtml: string;
  MarginInches: number;
  @fromClassArray(ExportHtmlPage) Pages: ExportHtmlPage[];
}