import {
  type ICustomFieldDefinition,
  CustomFieldType,
} from "models/contact-organization";

export class ContactsOrganizationService {
  constructor() {}

  public getFieldDisplayName(
    field: ICustomFieldDefinition,
    path?: string
  ): string {
    const name: string = field?.display_name;

    switch (path) {
      case "state":
        return `${name} State`;
      case "postal_code":
        return `${name} Postal Code`;
      case "country":
        return `${name} Country`;

      default:
        return name;
    }
  }

  public getFieldMetaDescription(field: ICustomFieldDefinition) {
    let typeName = this.getCustomFieldTypeFriendlyName(field.type);
    if (field.is_calculated) {
      typeName = `${typeName} (Calculated)`;
    }
    return `Key: ${field.name}, Type: ${typeName}`;
  }

  public getCustomFieldTypeFriendlyName(type: CustomFieldType): string {
    switch (type) {
      case CustomFieldType.number:
        return "Number";
      case CustomFieldType.boolean:
        return "Yes/No";
      case CustomFieldType.date:
        return "Date";
      case CustomFieldType.string:
        return "Single Line";
      case CustomFieldType.largestring:
        return "Multi Line";
      case CustomFieldType.dropdown:
        return "Dropdown";
      case CustomFieldType.address:
        return "Address";
      case CustomFieldType.email:
        return "Email";
      case CustomFieldType.phone:
        return "Phone";
      case CustomFieldType.social:
        return "Social";
      case CustomFieldType.link:
        return "URL";
      case CustomFieldType.relationship:
        return "Contact Relationship";
      case CustomFieldType.table:
        return "Table";
      case CustomFieldType.slideroomapplications:
        return "SlideRoom Applications";
      case CustomFieldType.multiselect:
        return "Multi-select";
      case CustomFieldType.slideroomadmissionsapplications:
        return "SlideRoom Admissions Applications";
      case CustomFieldType.testscore:
        return "Test Score";
      case CustomFieldType.tags:
        return "Tags";
      case CustomFieldType.user:
        return "User Relationship";
      case CustomFieldType.postalcode:
        return "Postal Code";
      case CustomFieldType.country:
        return "Country";
      case CustomFieldType.concealed:
        return "Concealed";
      case CustomFieldType.funnel:
        return "Funnel";
      case CustomFieldType.attachment:
        return "Attachment";
      case CustomFieldType.coursework:
        return "Coursework";
    }
  }
}
