import { escapeTerm, getOps } from "helpers/filter";
import { registerFilter } from "./filter-setup"

export class ActivityBusinessValueFilter {
  value: string = "1";
  operator: string = ">";
}

export class ActivityDescriptionFilter {
  description: string = null;
  operator: string = "=";
}

export class ActivitySourceFilter {
  datasource: string = "";
  datasourceinstance: string = "";
  type: string = "";
}

export class ActivityTitleFilter {
  title: string = null;
  operator: string = "=";
}

export const registerActivityFilters = () => {
  registerFilter(ActivityBusinessValueFilter, f => {
    f.toFilterString(data => {
      if (data.value === "") {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      return `${preOp}value:${op}${data.value}`;
    });
    f.toFilterTerms(data => [{ term: "value" }]);
  });

  registerFilter(ActivityDescriptionFilter, f => {
    f.toFilterString(data => {
      if (!data.description) {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      return `${preOp}description:${op}"${escapeTerm(data.description)}"`;
    });
    f.toFilterTerms(data => [{ term: "description" }]);
  });

  registerFilter(ActivitySourceFilter, f => {
    f.toFilterString(data => {
      if (!data.datasource) {
        return "";
      }

      const filters: string[] = [];

      if (data.datasourceinstance) {
        filters.push(`datasourceinstance:${data.datasourceinstance}`);
      } else if (data.datasource) {
        filters.push(`datasource:${data.datasource}`);
      }

      if (data.type) {
        const type = data.type.indexOf(" ") >= 0 ? `"${data.type}"` : data.type;
        filters.push(`type:${type}`);
      }

      return filters.length > 0
        ? `(${filters.join(" ")})`
        : "";
    });
    f.toFilterTerms(data => {
      const paths = [];
      if (!data.datasource) {
        return paths;
      }

      if (data.datasourceinstance) {
        paths.push({ path: "datasourceinstance" });
      } else if (data.datasource) {
        paths.push({ path: "datasource" });
      }

      if (data.type) {
        paths.push({ path: "type" });
      }

      return paths;
    });
  });

  registerFilter(ActivityTitleFilter, f => {
    f.toFilterString(data => {
      if (!data.title) {
        return "";
      }

      const { preOp, op } = getOps(data.operator);

      return `${preOp}title:${op}"${escapeTerm(data.title)}"`;
    });
    f.toFilterTerms(data => [{ term: "title" }]);
  });
}
