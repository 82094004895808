import { inject } from "fw";
import { createFrom, createFromArray, cloneOf } from "fw-model";

import { ATS } from "./ats";
import { encodeContentTemplate } from "./encode";

import { ContentTemplate } from "models/content-template";

@inject
export class ContentRepository {
  constructor(private s: ATS) {}

  async list() {
    const res = await this.s.get<any[]>("content-template");
    return createFromArray(ContentTemplate, res.body);
  }

  async getById(id: string, decrypt = false) {
    const res = await this.s.get(`content-template/${id}`, { decrypt });
    return createFrom(ContentTemplate, res.body);
  }

  async post(template: ContentTemplate) {
    const encoded = encodeContentTemplate(template);

    const res = await this.s.post("content-template", encoded);
    return createFrom(ContentTemplate, res.body);
  }

  async put(template: ContentTemplate) {
    const encoded = encodeContentTemplate(template);

    const res = await this.s.put<ContentTemplate>(
      `content-template/${template.Id}`,
      encoded,
    );

    return createFrom(ContentTemplate, res.body);
  }

  public async del(ids: string[]) {
    await this.s.delete(`content-template/${ids.join(",")}`);
  }
}
