import { ComponentEventBus, inject, needs, prop } from "fw";
import { CustomFieldType, type ICustomFieldDefinition, IdentifierMode } from "models/contact-organization";
import { Contact, ContactMetaData, type IFieldWarning } from "models/contact";
import { DataPolicyService } from "service/data-policy";
import { FeatureFlagService } from "service/feature-flag";
import { Permissions } from "service/permission";
import { PopoverService } from "service/popover";
import { EditFieldPopover, EditFieldPopoverOptions } from "./edit-field-popover"
import { allTypes, getControlType } from "./fields";
import { FieldMode } from "./field-mode";

export class FieldContext {
  public contactId: string = null;
  public contactMetaValueId: string = null;
  public decryptedValue: string = null;

  // needed for conditional options scope
  public contact: Contact = null;
}

@inject
@needs(...allTypes)
export class Field {
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(null) private contact!: Contact;
  @prop(null) private contactMeta!: ContactMetaData;
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) private value!: any;
  @prop(null) private path!: string;
  @prop(false) private showEdit!: boolean;
  @prop(false) public isFilter!: boolean;
  @prop(undefined) public warning!: IFieldWarning;
  public context: FieldContext = new FieldContext();

  private isRestricted: boolean = false;

  constructor(
    private dataPolicy: DataPolicyService,
    private popoverService: PopoverService,
    private ceb: ComponentEventBus,
    private permissions: Permissions,
    private ffs: FeatureFlagService
  ) { }

  public attached() {
    this.context.contactId = this.contact?.id;
    this.context.contact = this.contact;
    this.isRestricted = this.dataPolicy.isContactFieldRestricted(this.contact?.type, this.field?.id);
  }

  public get localValue() {
    return this.value;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", value);
  }

  public get restrictedAlignment() {
    switch (this.mode) {
      case FieldMode.View: return 'alignr';
      case FieldMode.GridView: return 'alignl';
      default: return '';
    }
  }

  public get fieldMeta() {
    if (!this.field || !this.contactMeta) {
      return null;
    }

    if (this.field.is_system_field) {
      return this.contactMeta.fields.find(f => f.field_name == this.field.name);
    } else {
      // TODO: Why don't the ids match for system fields?
      return this.contactMeta.fields.find(f => f.field_id == this.field.id);
    }
  }

  public get hasMultipleValues() {
    return this.fieldMeta && this.fieldMeta.values.length > 1;
  }

  public get isEmptyValue() {
    if (this.value != null) {
      if (Array.isArray(this.value) && !this.value.length) {
        return true;
      } else return this.value === "";
    } else {
      return true;
    }
  }

  public get dataId(): string {
    return this.field && `${this.field.display_name}-${this.mode}`;
  }

  public get controlType(): string {
    return getControlType(this.field?.type);
  }

  public get canEdit(): boolean {
    if (this.isRestricted) {
      return false;
    }

    if (!this.showEdit || this.field === null || this.contact === null || this.contact.is_deleted) {
      return false;
    }

    if (!this.permissions.all("EditContacts")) {
      return false;
    }

    if (this.field.is_read_only) {
      return false;
    }

    const readonlyFieldTypes = [CustomFieldType.slideroomapplications, CustomFieldType.testscore];
    if (readonlyFieldTypes.includes(this.field.type)) {
      return false;
    }

    if (this.field.identifier_mode === IdentifierMode.Email) {
      return true;
    }

    if (this.field.identifier_mode === IdentifierMode.Instance) {
      return false;
    }

    if (this.field.identifier_mode !== IdentifierMode.None) {
      return !this.value;
    }

    return !this.field.is_read_only;
  }

  public get warningType() {
    return this.warning?.show ? this.warning.type : "";
  }

  public get isWarning() {
    return this.warning?.show
  }

  public get editFieldAriaLabel() {
    return `Edit ${this.field.display_name || this.field.field_name}`
  }

  public get warningTitle() {
    return this.warning?.show ? this.warning.title : ""
  }

  public async editProperty() {
    await this.popoverService.open(EditFieldPopover, <EditFieldPopoverOptions>{
      contact: this.contact,
      contactMeta: this.contactMeta,
      controlType: this.controlType,
      dataId: this.dataId,
      field: this.field,
      path: this.path,
      value: this.value,
      context: this.context
    }).then(result => {
      this.ceb.dispatch("update:warning", result)
    });
  }
}
