import { fromClass } from "fw-model";

export class DocumentMetaData {
  OrganizationId: string;
  SeasonId: string;
}

export class Document {
  Id: string;
  FileId: string;
  ApplicantId: string;

  Source: string;
  SourceIdentifier: string;
  DocumentType: string;
  DateSent: string;
  EmailAddress: string;
  FirstName: string;
  LastName: string;
  School: string;
  City: string;
  Region: string;
  Country: string;
  CEEBCode: string;

  Fields: { [key: string]: string };
  @fromClass MetaData: DocumentMetaData;

  IsDeleted: boolean;
}
