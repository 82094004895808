import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { debounce } from "lodash-es";

import { DataDictionaryField } from "models/data-dictionary";
import { toDataDictionaryFields } from "models/contact-data-dictionary";
import { ContactDataDictionaryRepository } from "network/contact-data-dictionary-repository";
import { StartAction, LogoutAction, ContactOrganizationModelChangedAction } from "./actions";

interface ContactDataDictionaryShape {
  status: "loading" | "loaded" | "error" | null;
  organizationId: string;
  contactTypeKey: string;
  fields: DataDictionaryField[];
}

export class EnsureContactDataDictionaryFieldsAction {
  constructor(public contactTypeKey: string) { }
}

export class ResetContactDataDictionaryAction { }

export class ContactDataDictionaryRefreshedAction { }

@inject
export class ContactDataDictionaryStore extends Store<ContactDataDictionaryShape> {
  private debouncedLoadFields = async () => { };

  constructor(private repository: ContactDataDictionaryRepository) {
    super();
    this.debouncedLoadFields = debounce(() => this.loadFields(), 1000, { maxWait: 1000 });
  }

  defaultState() {
    return {
      status: null,
      organizationId: null,
      contactTypeKey: null,
      fields: []
    };
  }

  @handle(StartAction)
  private handleStart(action: StartAction) {
    this.reset(action.context.ContactOrganization.id);
  }

  @handle(LogoutAction)
  handleLogout(action: LogoutAction) {
    this.setState(() => this.defaultState());
  }

  @handle(ResetContactDataDictionaryAction)
  private handleResetContactDataDictionary(action: ResetContactDataDictionaryAction) {
    this.reset(this.state.organizationId);
  }

  private reset(organizationId: string) {
    const defaultState = this.defaultState();
    defaultState.organizationId = organizationId;
    this.setState(() => defaultState);
  }

  @handle(EnsureContactDataDictionaryFieldsAction)
  private async handleEnsureContactDataDictionaryFields(action: EnsureContactDataDictionaryFieldsAction) {
    if (!action.contactTypeKey)
      return;

    const { contactTypeKey, status } = this.state;
    const forceRefresh = contactTypeKey !== action.contactTypeKey;

    if (!forceRefresh && (status === "loading" || status === "loaded")) {
      return;
    }

    await this.loadFields(action.contactTypeKey);
  }

  private async loadFields(key: string = null) {
    const organizationId = this.state.organizationId;
    const contactTypeKey = key || this.state.contactTypeKey;
    if (!organizationId || !contactTypeKey)
      return;

    this.setState(state => ({
      ...state,
      status: "loading"
    }));

    try {
      const contactFields = await this.repository.list(contactTypeKey);
      const fields = toDataDictionaryFields(contactFields);
      this.setState(state => ({
        ...state,
        status: "loaded",
        contactTypeKey,
        fields
      }));
      await dispatch(new ContactDataDictionaryRefreshedAction());
    } catch {
      this.setState(state => ({
        ...state,
        status: "error"
      }));
    }
  }

  @handle(ContactOrganizationModelChangedAction)
  private async handleContactOrganizationModelChanged(action: ContactOrganizationModelChangedAction) {
    if (action.organization.id === this.state.organizationId) {
      await this.loadFields();
    }
  }
}
