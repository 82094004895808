import { FormForType, formFor } from "fw-model";

import { CalendarEventType } from "models/calendar-event";

export type FormForCalendarEventType = FormForType<CalendarEventType>;

export class CreateCalendarEventTypeAction {
  public created: CalendarEventType = null;
  constructor(public form: FormForCalendarEventType) { }
}

export class UpdateCalendarEventTypeAction {
  public updated: CalendarEventType = null;
  constructor(public form: FormForCalendarEventType) { }
}

export class DeleteCalendarEventTypeAction {
  constructor(public ids: string | string[], public all?: boolean, public search?: string) { }
}

export const createCalendarEventTypeFormCreator = formFor(CalendarEventType, s => {
  s.field(f => f.name, "Name");
});

export const updateCalendarEventTypeFormCreator = formFor(CalendarEventType, s => {
  s.requiredField(f => f.id, "Id");

  s.field(f => f.name, "Name");
});