import { fromClass, fromPropertyClass, fromClassArray } from "fw-model";
import { CalculatedField, Form, FormSection, Question } from "../../../form-runtime/src/models";
import { FormTypeCode } from '../../../form-runtime/src/enums';
import { ProgramStepTypeCode } from './program';
import { DataDictionaryFieldCategory } from "./data-dictionary";

export class RoleMetaData {
  OrganizationId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

// ff: RoleDataPolicies

export const formPath = (form: Form, prefix: string = null, includeAll: boolean = false): string => {
  if (form == null) return null;
  const path = prefix && prefix.length > 0 ? `${prefix}.${form.Key}` : form.Key;
  return includeAll ? `${path}.*` : path;
}

export const calculatedFieldPath = (form: Form, calculatedField: CalculatedField, prefix: string = null): string => {
  const formKey = formPath(form, prefix);
  return formKey ? `${formKey}.${calculatedField.Key}` : null;
}

export const sectionPath = (form: Form, section: FormSection, prefix: string = null): string => {
  if (!form) return null;
  const formKey = formPath(form, prefix);
  return section && section.IsTableSection ? `${formKey}.${section.TableSectionOptions.Key}.rows` : formKey;
}

export const questionPath = (form: Form, section: FormSection, question: Question, prefix: string = null): string => {
  if (!form || !question) return null;

  const sectionKey = sectionPath(form, section, prefix);

  return `${sectionKey}.${question.Key}`;
};

export type ApplicationRestrictionType = (
  "FormCalculatedFields" |
  "ApplicantDocumentSource" |
  "ApplicantIdentity" |
  "ApplicantProfileImage" |
  "ApplicantProperty" |
  "ApplicantPhase" |
  "ApplicationProperty" |
  "ApplicantReference" |
  "Evaluation" |
  "FormStep" |
  "ReferenceSetStep" |
  "ReferenceForm" |
  "PortfolioStep" |
  "DocumentStep" |
  "CeebExtendedData" |
  "EncryptedData" |
  "Attachments" |
  "Tags"
);

export const getFormName = (type: ApplicationRestrictionType): string => {
  switch (type) {
    case "FormStep":
      return "Applicant Form";
    case "Evaluation":
      return "Evaluation Form";
    case "ReferenceForm":
      return "Reference Form";
    default:
      return "";
  }
}

export const getFormType = (code: FormTypeCode): ApplicationRestrictionType => {
  switch (code) {
    case FormTypeCode.Applicant:
      return "FormStep";
    case FormTypeCode.Recommender:
      return "ReferenceForm";
    case FormTypeCode.Evaluation:
      return "Evaluation";
    default:
      return null;
  }
};

export const getStepCode = (type: ApplicationRestrictionType): ProgramStepTypeCode => {
  switch (type) {
    case "PortfolioStep":
      return ProgramStepTypeCode.Portfolio;
    case "DocumentStep":
      return ProgramStepTypeCode.Document;
    default:
      return null;
  }
};

export const getCategoryFromApplicationRestrictionType = (type: ApplicationRestrictionType): DataDictionaryFieldCategory => {
  switch (type) {
    case "PortfolioStep":
      return DataDictionaryFieldCategory.PortfolioSteps;
    case "DocumentStep":
      return DataDictionaryFieldCategory.DocumentSteps;
    default:
      return null;
  }
}

export class ApplicationRestriction {
  Type: ApplicationRestrictionType = null;
  StepKey: string = null;
  Paths: string[] = [];
  Options: { [name: string]: string } = {};
}

export class DataPolicy {
  @fromClassArray(ApplicationRestriction)
  ApplicationRestrictions: ApplicationRestriction[];
}

export class SeasonalRoleSettings {
  DashboardReportId: string = null;
  ApplicationSegmentIds: string[] = [];
}

export class Role {
  Id: string;
  Name: string = null;
  Permissions: string[] = [];

  @fromPropertyClass(DataPolicy) DataPolicies: {
    [seasonId: string]: DataPolicy;
  };
  @fromPropertyClass(SeasonalRoleSettings) SeasonalRoleSettings: {
    [seasonId: string]: SeasonalRoleSettings;
  };
  @fromClass MetaData: RoleMetaData;
}
