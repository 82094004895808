import { inject, prop, ComponentEventBus } from "fw";

import { FeatureFlagService } from "service/feature-flag";
import { PopoverService } from "service/popover";
import { OptionChoice, OptionChooserPopover } from "./charts/option-chooser-popover";
import type { FormFormReportAxisDefinition } from "forms/report-definition";
import { ReportAxisDefinition, AggregationType, TermsOrderBy } from "shared/report-runtime";

let inputIdIterator: number = 0;

@inject
export class ReportAxisDefinitionEditor {
  @prop(null) axisForm!: FormFormReportAxisDefinition;
  
  private AggregationType = AggregationType;
  private focusedOptionId: string = '';
  private popoverIsOpen: { [key: string]: boolean } = {
    bucketLimit: false,
    dateInterval: false,
    numberInterval: false,
    termsOrderBy: false,
  };
  private reportAxisDefinitionEditorBaseInputId: string = "report-axis-definition-editor-input";
  private reportAxisDefinitionEditorPopoverId: string = "report-axis-definition-editor-popover";
  private TermsOrderBy = TermsOrderBy;

  constructor(
    private ceb: ComponentEventBus,
    private featureFlagService: FeatureFlagService,
    private popoverService: PopoverService,
  ) { }

  get reportAxisDefinitionEditorInputId() {
    inputIdIterator++;
    return `${this.reportAxisDefinitionEditorBaseInputId}-${inputIdIterator}`;
  }

  get numberIntervalOptions(): { value: any; text: string }[] {
    let options = [];
    if (this.featureFlagService.isFeatureFlagEnabled("NonIntegerReportIntervalOptions")) {
      options.push({ text: 'by .1', value: .1 });
    }

    return options.concat(
      [
        { text: 'by 1', value: 1 },
        { text: 'by 5', value: 5 },
        { text: 'by 10', value: 10 },
        { text: 'by 100', value: 100 },
      ]
    );
  }

  get termsOrderByOptions(): { value: any; text: string }[] {
    let options = [];
    options.push({ text: 'by counts', value: TermsOrderBy.Counts });
    options.push({ text: 'alphabetically', value:  TermsOrderBy.Alpha });    
    
    if (this.axisForm.HasOptionValues || this.axisForm.TermsOrderBy == TermsOrderBy.OptionsOrder)
      options.push({ text: 'by order of options', value: TermsOrderBy.OptionsOrder });

    return options;
  };

  dateIntervalOptions: { value: any; text: string }[] = [
    { text: 'by day', value: "1d" },
    { text: 'by week', value: "1w" },
    { text: 'by month', value: "1M" },
    { text: 'by year', value: "1y" },
  ];

  bucketLimitOptions: { value: any; text: string }[] = [
    { text: 'Top 5', value: 5 },
    { text: 'Top 10', value: 10 },
    { text: 'Top 25', value: 25 },
    { text: 'Top 50', value: 50 },
    { text: 'Top 100', value: 100 },
  ];

  public updateOptionFocus(optionId: string) {
    this.focusedOptionId = optionId;
  }

  get numberInterval() {
    if (this.axisForm == null) return null;
    const opt = this.numberIntervalOptions.find(opt => opt.value == this.axisForm.Interval);
    return opt.text;
  }

  async selectNumberInterval() {
    this.popoverIsOpen.numberInterval = true;
    const res = await this.popoverService.open<OptionChoice<number>>(
      OptionChooserPopover,
      {
        options: this.numberIntervalOptions,
        selectedOption: this.numberIntervalOptions.find(option => this.numberInterval === option.text),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.reportAxisDefinitionEditorPopoverId },
    );
    this.popoverIsOpen.numberInterval = false;

    if (!res.canceled) {
      this.axisForm.Interval = res.result.value;
      this.ceb.dispatch("changed");
    }
  }

  get termsOrderBy() {
    if (this.axisForm == null) return null;
    
    let opt = this.termsOrderByOptions.find(opt => opt.value == this.axisForm.TermsOrderBy);
    if (!opt) 
      opt = this.termsOrderByOptions[0];

    return opt.text;
  }

  async selectTermsOrderBy() {
    this.popoverIsOpen.termsOrderBy = true;
    const res = await this.popoverService.open<OptionChoice<number>>(
      OptionChooserPopover,
      {
        options: this.termsOrderByOptions,
        selectedOption: this.termsOrderByOptions.find(option => this.termsOrderBy === option.text),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.reportAxisDefinitionEditorPopoverId },
    );
    this.popoverIsOpen.termsOrderBy = false;

    if (!res.canceled) {
      this.axisForm.TermsOrderBy = res.result.value;
      this.ceb.dispatch("changed");
    }
  }

  get bucketLimit() {
    if (this.axisForm == null) return null;
    const opt = this.bucketLimitOptions.find(opt => opt.value == this.axisForm.BucketLimit);
    return opt.text;
  }

  async selectBucketLimit() {
    this.popoverIsOpen.bucketLimit = true;
    const res = await this.popoverService.open<OptionChoice<number>>(
      OptionChooserPopover,
      {
        options: this.bucketLimitOptions,
        selectedOption: this.bucketLimitOptions.find(option => this.bucketLimit === option.text),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.reportAxisDefinitionEditorPopoverId },
    );
    this.popoverIsOpen.bucketLimit = false;

    if (!res.canceled) {
      this.axisForm.BucketLimit = res.result.value;
      this.ceb.dispatch("changed");
    }
  }

  get dateInterval() {
    if (this.axisForm == null) return null;
    const opt = this.dateIntervalOptions.find(opt => opt.value == this.axisForm.Interval);
    return opt.text;
  }

  async selectDateInterval() {
    this.popoverIsOpen.dateInterval = true;
    const res = await this.popoverService.open<OptionChoice<string>>(
      OptionChooserPopover,
      {
        options: this.dateIntervalOptions,
        selectedOption: this.dateIntervalOptions.find(option => this.dateInterval === option.text),
        updateOptionFocus: this.updateOptionFocus,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      { id: this.reportAxisDefinitionEditorPopoverId },
    );
    this.popoverIsOpen.dateInterval = false;

    if (!res.canceled) {
      this.axisForm.Interval = res.result.value;
      this.ceb.dispatch("changed");
    }
  }
}
