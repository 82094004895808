import { Subscription, provided, inject, ComponentEventBus } from "fw";
import { WebSocketStore } from "state/web-socket";

@inject
export class WebsocketMessage {
  constructor(private wss: WebSocketStore, private ceb: ComponentEventBus) {}

  sub: Subscription = null;

  created() {
    this.sub = this.wss.subscribe(msg => {
      this.ceb.dispatch(msg.type, msg.data);
    });
  }

  detached() {
    this.sub.dispose();
  }
}
