import { Validators, FormForType, formFor } from "fw-model";
const { required, wrap } = Validators;

import {
  PaymentGateway,
  PaymentProviderTypeCode,
  PaymentGatewayData,
} from "models/organization";

export type PaymentGatewayForm = FormForType<PaymentGateway>;

export class ActivatePaymentGatewayAction {
  constructor(public id: string) {}
}

export class SavePaymentGatewayAction {
  public returnedPaymentGateway: PaymentGateway;

  constructor(public form: PaymentGatewayForm, public makeActive) {}
}

const requiredIfProvider = (requiredProvider: PaymentProviderTypeCode) => {
  return (input, _, provider) => {
    if (provider != requiredProvider) return null;
    return required(input);
  };
};

const requiredIfProviderIsNot = (requiredProvider: PaymentProviderTypeCode) => {
  return (input, _, provider) => {
    if (provider == requiredProvider) return null;
    return required(input);
  };
};

export const paymentGatewayDataFormCreator = formFor(PaymentGatewayData, s => {
  s.field(
    d => d.apiLogin,
    "API Login ID",
    wrap(requiredIfProvider(PaymentProviderTypeCode.AuthorizeDotNet)),
  );

  s.field(
    d => d.apiKeyId,
    "API Key ID",
    wrap(requiredIfProvider(PaymentProviderTypeCode.Cybersource)),
  );
  s.field(
    d => d.apiKeySharedSecret,
    "API Key Shared Secret",
    wrap(requiredIfProvider(PaymentProviderTypeCode.Cybersource)),
  );
  s.field(
    d => d.profileId,
    "Secure Acceptance Profile ID",
    wrap(requiredIfProvider(PaymentProviderTypeCode.Cybersource)),
  );
  s.field(
    d => d.merchantId,
    "Merchant ID",
    wrap(requiredIfProvider(PaymentProviderTypeCode.Cybersource)),
  );
  s.field(
    d => d.username,
    "Username",
    wrap(requiredIfProvider(PaymentProviderTypeCode.TouchNet)),
  );
  s.field(
    d => d.password,
    "Password",
    wrap(requiredIfProvider(PaymentProviderTypeCode.TouchNet)),    
  );
  s.field(
    d => d.uPayId,
    "UPayId",
    wrap(requiredIfProvider(PaymentProviderTypeCode.TouchNet)),    
  );
  s.field(
    d => d.timeout,
    "Timeout",
    wrap(requiredIfProvider(PaymentProviderTypeCode.TouchNet)),    
  );
  s.field(
    d => d.endpoint,
    "TouchLink endpoint",
    wrap(requiredIfProvider(PaymentProviderTypeCode.TouchNet)),    
  );
  s.field(
    d => d.uPayUrl,
    "uPay URL",
    wrap(requiredIfProvider(PaymentProviderTypeCode.TouchNet)),    
  );
});

export const paymentGatewayFormCreator = formFor(PaymentGateway, s => {
  s.requiredField(g => g.Provider, "Provider");  
  s.field(
    g => g.PublicKey, "Public Key",
    wrap(requiredIfProviderIsNot(PaymentProviderTypeCode.TouchNet)),    
  );
  s.field(
    g => g.PrivateKey, "Private Key",
    wrap(requiredIfProviderIsNot(PaymentProviderTypeCode.TouchNet)),    
  );
  s.field(g => g.Production, "Is Production Account");
  s.form(g => g.Data, "Data", paymentGatewayDataFormCreator);
});
