import { inject, Bus } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { HubConnectionBuilder, LogLevel, HubConnection } from "@microsoft/signalr";
import { StartAction, LogoutAction } from "state/actions";
import { UserRepository } from "network/user-repository";
import { WebSocketMessageAction } from 'state/web-socket-message';

interface WebSocketStoreShape {
  connection: HubConnection;
};

@inject
export class WebSocketStore extends Store<WebSocketStoreShape> {
  private bus = new Bus();

  constructor(private userRepo: UserRepository) {
    super();
  }

  public subscribe(cb: (message: WebSocketMessageAction) => void) {
    return this.bus.subscribe(WebSocketMessageAction, cb);
  }

  defaultState() {
    return {
      connection: null,
    };
  }

  private onMessage(type: string, data: any) {
    const message = new WebSocketMessageAction(type, data);
    this.bus.publish(message);
    dispatch(message);
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    // wrapping in a closure to defer waiting..
    (async () => {
      try {
        await this.stopConnection();
        const wsKey = await this.userRepo.webSocketKey();
  
        const connection = new HubConnectionBuilder()
          .configureLogging(LogLevel.Debug)
          .withAutomaticReconnect()
          .withUrl(action.context.SystemConfig.WebSocketUrl, { accessTokenFactory: () => wsKey })
          .build();
  
        await connection.start();
  
        connection.on("message", this.onMessage.bind(this));
  
        this.setState(state => ({
          ...state,
          connection,
        }));
      } catch (exception) {
        console.warn("Error creating websocket connection; " + exception);
      }
    })();
  }

  @handle(LogoutAction)
  private async handleLogoutAction() {
    await this.stopConnection();
  }

  private async stopConnection() {
    if (this.state.connection == null) return;

    await this.state.connection.stop();

    this.setState(state => ({
      ...state,
      connection: null,
    }));
  }
}
