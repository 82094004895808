import { fromClass } from "fw-model";
import { ApplicationSegment } from "./application-segment";
import { ContactSegment } from "./contact-segment";
import { MarketingOutreachTier } from "./marketing-outreach-tier";

export enum MarketingOutreachTrackPrettyTypes {
  datetime = 'Scheduled',
  triggered = 'Triggered'
}

export class MarketingOutreachTrack {
  Id: number = null;
  OutreachCampaignId: number = null;
  Name: string = null;
  Description: string = null;
  SegmentType: string = null;
  SegmentId: string = null;
  ScheduleType: 'datetime' | 'triggered' = null;
  Archived: number = null;
  EndDate: string = null;
  StartDate: string = null;
  NextSendOn: string = null;
  Filter: string = null;
  @fromClass tiers: MarketingOutreachTier[] = null;
  @fromClass segment: ApplicationSegment | ContactSegment;
}
