import { ComponentEventBus, prop, inject, needs } from "fw";
import { MultiselectField } from "./multiselect-field";

@inject
@needs(MultiselectField)
export class TagsField {
  @prop(undefined) public value!: string[];
  @prop(true) public randomizeColor!: boolean;
  @prop(false) public showRemove!: boolean;

  @prop(false) public withOverflowCount!: boolean;

  constructor(private ceb: ComponentEventBus) { }

  public remove(tag: string) {
    this.ceb.dispatch("remove", tag);
  }

  public onOverflowClick() {
    this.ceb.dispatch("overflow-click");
  }
}
