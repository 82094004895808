import Vue from "vue";
import { ComponentEventBus, inject, prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { FieldMode } from "../field-mode";
import { isValidPhoneNumber } from "forms/validators";
import { ContactsOrganizationService } from "service/contacts-organization";
import {
  AsYouType,
  CountryCode,
  getCountryCallingCode,
} from "libphonenumber-js";
import CountryFlag from "vue-country-flag";
import { DropdownWithFilterPopover } from "views/components/dropdown-with-filter-popover";
import { LinkOptionPopoverOptionsType } from "views/components/link-option-popover";
import { PopoverService } from "service/popover";
import { countries } from "../../../../../../countries/index";
import { generateDataId } from "helpers/data-id-gen";

const DEFAULT_COUNTRY = "US";

type CountryPopoverOptionsType = Pick<LinkOptionPopoverOptionsType, "value" | "text" | "dataId">;

@inject
export class PhoneType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;
  @prop(true) public setfocus!: boolean;
  @prop(false) public showClear!: boolean;

  public selectedCountry: CountryCode = null;
  public validation: string = null;

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService,
    private popoverService: PopoverService,
  ) {}

  public created() {
    Vue.use(CountryFlag);
  }

  public attached() {
    if (this.localValue) {
      const country = this.getCountryFromPhone(this.localValue);
      if (!!country) {
        this.setCountry(country);
      }
    }

    if (!this.selectedCountry) {
      this.setCountry(DEFAULT_COUNTRY);
    }

    this.localValue = this.getLocalPhone({
      phone: this.localValue,
      country: this.selectedCountry,
    });

    this.ceb.dispatch("validate", this.validate);
  }

  public set localValue(value: string) {
    this.ceb.dispatch("update:value", value && value.trim());
  }

  public get localValue(): string {
    return this.value;
  }

  public get dataId() {
    return generateDataId(null, this.field.display_name);
  }

  public get metaDescription() {
    return this.showMeta
      ? this.contactOrganizationService.getFieldMetaDescription(this.field)
      : null;
  }

  private get countries(): CountryPopoverOptionsType[] {
    let options: CountryPopoverOptionsType[] = [];

    for (const countryKey in countries) {
      options.push({
        value: countryKey,
        text: countries[countryKey],
        dataId: countryKey,
      });
    }
    return options;
  }

  public setCountry(selectedCountry: CountryCode) {
    this.selectedCountry = selectedCountry;
  }

  public getCountryCallingCode(country: CountryCode) {
    let countryCode: string = '';
    try {
      countryCode = getCountryCallingCode(country);
    } catch {
      countryCode = '';
    }
    return countryCode;
  }

  private getCountryFromPhone(phoneNumber: string): CountryCode {
    if (!phoneNumber || "+" !== phoneNumber.charAt(0)) {
      return null;
    }

    const dynamicPhoneType = new AsYouType();
    dynamicPhoneType.input(phoneNumber);
    return dynamicPhoneType.getCountry();
  }

  public getLocalPhone({ phone, country }: { phone: string, country: CountryCode }): string {
    if (!phone) {
      return '';
    }

    let phoneNumber = phone;
    const countryCallingCode = this.getCountryCallingCode(country);

    // remove selected country code if exists
    const countryCodeRegex = new RegExp(`^\\+?${countryCallingCode}`);
    phoneNumber = phoneNumber.replace(countryCodeRegex, '');

    // remove `+` if exists
    phoneNumber = phoneNumber.replace(/\+/g, '');

    return `${phoneNumber}`;
  }

  public getInternationalPhone({ phone, country }: { phone: string, country: CountryCode }): string {
    if (!phone) {
      return '';
    }

    let phoneNumber = this.getLocalPhone({ phone, country });
    const countryCallingCode = this.getCountryCallingCode(country);
    if (!!countryCallingCode) {
      return `+${countryCallingCode}${phoneNumber}`;
    }
    return phone;
  }

  public async openSelectCountryPopover() {
    const response = await this.popoverService.open<CountryCode>(
      DropdownWithFilterPopover,
      {
        list: this.countries,
      }
    );
    if (response.canceled) {
      return;
    }

    this.setCountry(response?.result);
  }

  public onKeyUp() {
    if (this.localValue) {
      const country = this.getCountryFromPhone(this.localValue);
      if (!!country) {
        this.setCountry(country);
      }
    }

    this.ceb.dispatch("keyup");
  }

  public clear() {
    this.setCountry(null);
    this.localValue = '';
  }

  private validate(): boolean {
    const phoneNumber = this.getInternationalPhone({
      phone: this.value?.toString(),
      country: this.selectedCountry,
    });

    this.validation = isValidPhoneNumber({
      phone: phoneNumber,
      country: this.selectedCountry,
    });

    if (this.validation === null) {
      this.ceb.dispatch("update:value", phoneNumber);
      return true;
    }
    return false;
  }
}
