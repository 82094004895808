import { fromClass, fromClassArray } from "fw-model";

import { User, UserInvitation } from "./user";
import { UserSeasonSettings } from "./user-season-settings";
import { Program } from "./program";
import {
  Organization,
  Season,
  PaymentSettings,
  OrganizationPortal,
} from "./organization";
import { Form } from "models/form";
import { ApplicationSegment } from "./application-segment";
import { ReportDefinition } from "shared/report-runtime";
import { Team } from "./team";
import { Role } from "./role";
import { SystemConfig } from "./system-config";
import { FeatureFlagSet } from "./feature-flag-set";
import { ContactsIntegrationSettings } from "./contacts-integration-settings";
import { ContactOrganization } from "./contact-organization";
import { CollaborationPortal } from "./collaboration-portal";
import { CollaborationModule } from "./collaboration-module";
import { CollaboratorRole } from "./collaborator-role";
import { EmailTemplate } from "./email-template";
import { Workflow } from "./workflow";
import { DocumentProvider } from "./document-provider";
import { NotificationProfile } from 'models/notification';
import { ContactSegment } from "./contact-segment";
import { ConversationNotificationCounts } from 'models/conversation';

export class PathTranslation {
  Pattern: string;
  Replacement: string;
}

export class AppOrganizationContext {
  @fromClass Organization: Organization;
  @fromClass Me: User;
  @fromClass Season: Season;
  @fromClass UserSeasonSettings: UserSeasonSettings;
  @fromClassArray(Program) Programs: Program[];
  @fromClassArray(User) Users: User[];
  @fromClassArray(Workflow) Workflows: Workflow[];
  @fromClassArray(UserInvitation) Invites: UserInvitation[];
  @fromClassArray(Form) Forms: Form[];
  @fromClassArray(CollaborationPortal) CollaborationPortals: CollaborationPortal[];
  @fromClassArray(CollaborationModule) CollaborationModules: CollaborationModule[];
  @fromClassArray(CollaboratorRole) CollaboratorRoles: CollaboratorRole[];
  @fromClass OrganizationPortal: OrganizationPortal;
  @fromClass PaymentSettings: PaymentSettings;
  @fromClass ContactsIntegrationSettings: ContactsIntegrationSettings;
  @fromClass ContactOrganization: ContactOrganization;
  @fromClassArray(ApplicationSegment) ApplicationSegments: ApplicationSegment[];
  @fromClassArray(ReportDefinition) ReportDefinitions: ReportDefinition[];
  @fromClassArray(Team) Teams: Team[];
  @fromClassArray(Role) Roles: Role[];
  @fromClassArray(EmailTemplate) CustomEmailTemplates: EmailTemplate[];
  @fromClassArray(DocumentProvider) DocumentProviders: DocumentProvider[];
  @fromClassArray(PathTranslation) PathTranslations: PathTranslation[];
  @fromClass NotificationProfile: NotificationProfile;
  @fromClass ConversationNotificationCounts: ConversationNotificationCounts;

  @fromClass SystemConfig: SystemConfig;

  FeatureFlags: FeatureFlagSet;
  ResolvedDashboardId: string;
  PortalInvitationCount: number;
  CurrentUserWorkspaceIds: string[];
}
