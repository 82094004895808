import { Validators, FormForType, formFor, Validator } from "fw-model";
const { required, wrap } = Validators;

import {
  ContactsApiFieldMapping,
  ContactsCeebDataSettings,
  ContactsIntegrationSettings,
  ContactsApplicantDataSettings,
} from "models/contacts-integration-settings";
import { isValidKey } from "./validators";

export type ContactsIntegrationSettingsFormType = FormForType<
  ContactsIntegrationSettings
>;
export type ContactsCeebDataSettingsFormType = FormForType<
  ContactsCeebDataSettings
>;
export type ContactsApplicantDataSettingsFormType = FormForType<
  ContactsApplicantDataSettings
>;
export type ContactsApiFieldMappingFormType = FormForType<
  ContactsApiFieldMapping
>;

export class UpdateContactsIntegrationSettingsAction {
  constructor(public form: ContactsIntegrationSettingsFormType) {}
}

export const contactsApiFieldMappingFormCreator = formFor(
  ContactsApiFieldMapping,
  s => {
    s.field(a => a.DataType, "Data Type");
    s.requiredField(a => a.ATSPath, "Key", wrap(isValidKey));
    s.requiredField(a => a.ContactsPath, "Field in Contacts");
    s.requiredField(a => a.Label, "Field Label");
  },
);

export const contactsCeebDataSettingsFormCreator = formFor(
  ContactsCeebDataSettings,
  s => {
    s.field(a => a.IsEnabled, "Sync CEEB Institutions with Contacts");

    s.formArray(
      a => a.FieldMappings,
      "Contacts Field Mappings",
      contactsApiFieldMappingFormCreator,
    );
  },
);


export const contactsIntegrationSettingsFormCreator = formFor(
  ContactsIntegrationSettings,
  s => {

    s.form(
      a => a.CeebDataSettings,
      "Ceeb Data Settings",
      contactsCeebDataSettingsFormCreator,
    );

  },
);
