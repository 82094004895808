import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";

import { ExportTask } from "models/export-task";

@inject
export class ExportTaskRepository {
  constructor(private s: ATS) { }

  async get(id: string) {
    const res = await this.s.get(`export-task/${id}`);
    return createFrom(ExportTask, res.body);
  }

  async put(id: string, retryDelivery = false) {
    const res = await this.s.put(`export-task/${id}`,null, { retryDelivery });
    return createFrom(ExportTask, res.body);
  }

  public async scheduled(
    seasonId: string = null,
    exportDefinitionId: string = null,
    scheduledExportId: string = null,
    page: number = null,
    pageSize: number = null,
    filter: string = null,
    search: string = null,
    sort: string = null,
    fields: string = null
  ) {
    // for now one of these is required
    if (!exportDefinitionId && !scheduledExportId)
      return {};

    const res = await this.s.get<any[]>("export-task/scheduled", {
      seasonId,
      exportDefinitionId,
      scheduledExportId,
      page,
      pageSize,
      f:filter,
      s:search,
      sort,
      fields
    });
    return { list: createFromArray(ExportTask, res.body), total: parseInt(res.headers["x-ats-total"], 10) };
  }

  async downloads(
    seasonId: string = null,
    page: number = null,
    pageSize: number = null,
    filter: string = null,
    search: string = null,
    sort: string = null
  ) {
    const res = await this.s.get<any[]>("export-task/downloads", {
      seasonId,
      page,
      pageSize,
      filter,
      search,
      sort
    });
    return { list: createFromArray(ExportTask, res.body), total: parseInt(res.headers["x-ats-total"], 10) };
  }

  public async del(ids: string[]) {
    await this.s.post("export-task/delete", ids);
  }
}
