import { PdfExportFormatOptions } from "./export-definition";

export class InterviewPortalLink {
  SsoUrl: string;
}

export enum ApplicationType {
  Full = 0,
  Limited = 1
}

interface InterviewPortalMetaData {
  OrganizationId: string;
  SeasonId: string;
  DateCreatedUtc: string;
  DateUpdatedUtc: string;
}

export class InterviewPortal {
  Id: string = null;
  AimId: string = null;
  Enabled: boolean = false;
  FullApplicationPdfRoleId: string = null;
  FullApplications: PdfExportFormatOptions;
  LimitedApplicationPdfRoleId: string = null;
  LimitedApplications: PdfExportFormatOptions;
  InterviewPhaseIds: string[] = [];
  WaitlistPhaseIds: string[] = [];
  ProgramDirectorUserIds: string[] = [];
  ProgramAssistantUserIds: string[] = [];
  InterviewerUserIds: string[] = [];
  MetaData: InterviewPortalMetaData;
}
