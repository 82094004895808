import { inject, Route } from "fw";
import { dispatch } from "fw-state";

import { ApplicationStore, HideChrome as HideChromeAction } from "state/application";

@inject
export class HideChrome {
  constructor(private appStore: ApplicationStore) { }

  navigating(r: Route) {
    if (r.data && r.data.hideChrome) {
      dispatch(new HideChromeAction(true));
    } else {
      dispatch(new HideChromeAction(false));
    }

    return true;
  }
}
