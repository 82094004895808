export enum ChangeType {
  Added = 0,
  Saved = 1,
  Removed = 2
}

export enum DataImportStatus {
  Enqueued,
  Processing,
  NeedsManualMapping,
  Complete,
  Error
}

export class aDataImportChangedAction {
  public request_id: string;
  public organization_id: string;
  public status: DataImportStatus;
}

export class aEntityChangedAction {
  public type: string;
  public id: string;
  public organization_id: string;
  public contact_id: string;
  public change_type: ChangeType;
}
