import { inject } from "fw";
import { createFrom } from "fw-model";
import { ATS } from "./ats";
import { InterviewPortal, InterviewPortalLink } from "models/interview-portal";

@inject
export class InterviewPortalRepository {
  constructor(private s: ATS) { }

  public async get(): Promise<InterviewPortal> {
    const res = await this.s.get("interview-portal");
    return createFrom(InterviewPortal, res.body);
  }

  public async put(args: InterviewPortal): Promise<InterviewPortal> {
    const res = await this.s.put("interview-portal", args);
    return createFrom(InterviewPortal, res.body);
  }

  public async refresh(): Promise<void> {
    await this.s.post("interview-portal/process", null);
  }

  public async linkInterviewPortal(): Promise<InterviewPortalLink> {
    const res = await this.s.get("interview-portal/oo/aim-portal-sso");
    return createFrom(InterviewPortalLink, res.body);
  }
}
