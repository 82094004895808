import { fromClass } from "fw-model";
import { PortalMetaData, PortalContent, PortalSocial } from "./portal";

export class ReviewPortalContent extends PortalContent {}
export class ReviewPortalMetaData extends PortalMetaData {}
export class ReviewPortalSocial extends PortalSocial {}

export class ReviewPortal {
  Id: string = null;
  Name: string = null;
  Domain: string = null;
  PrimaryContactType: string = null;
  BrowserTitle: string = null;

  @fromClass Content: ReviewPortalContent = null;

  @fromClass MetaData: ReviewPortalMetaData = null;

  @fromClass Social: ReviewPortalSocial = null;
}
