import { prop, inject } from "fw";
import { ContactsOptionFilter as ContactsOptionFilterModel } from "models/contacts-filters";
import { IDropdownFieldOptions } from "../dropdown-field";

@inject
export class ContactsOptionFilter {
  @prop(null)
  filter: ContactsOptionFilterModel;

  @prop(() => ({}))
  validation;

  @prop(false)
  editing: boolean;

  private valueOptions: IDropdownFieldOptions[] = [];

  get optionText() : string {
    if (!this.filter || !this.filter.options)
      return null;

    if (this.filter.value === "__missing__")
      return "empty";

    const option = this.filter.options.find(o => o.value == this.filter.value);

    return option && option.text;
  }

  attached() {
    if (this.filter && this.filter.options) {
      this.valueOptions = this.filter.options.map(o => ({
        value: o.value,
        text: o.text,
      }));
      this.valueOptions.push({
        value: "__missing__",
        text: "[Empty]"
      })
    }
  }
}
