import { inject } from "fw";
import { PopoverController } from "service/popover";

export type LinkOptionPopoverOptionsType = {
  value: unknown;
  text: string;
  url?: string;
  icon?: string;
  dataId?: string;
  iconDataName?: string;
  class?: string;
};

@inject
export class LinkOptionPopover {
  public options = [];

  constructor(private controller: PopoverController<any>) { }

  public activate(params: { options: LinkOptionPopoverOptionsType[]; }) {
    this.options = params.options || [];
  }

  public choose(value) {
    this.controller.ok(value);
  }

  public cancel() {
    this.controller.cancel();
  }

  public getKey(option: LinkOptionPopoverOptionsType): string {
    return option.dataId ?? option.url ?? option.text;
  }
}
