import { inject } from "fw";
import { createFrom, cloneOf } from "fw-model";
import { Operation } from 'fast-json-patch';

import {
  IEntitySelection,
  IEntitySelectionPatches,
  IListResults,
  ATS
} from "./ats";

import { ContactSegment } from "models/contact-segment";

export interface NewContactSegmentArgs {
  contact_type: string;
  display_name: string;
  key: string;
  filter: string;
  client_data: string;
  category: string;
}

@inject
export class ContactSegmentRepository {
  constructor(private s: ATS) {}

  public async list(q: string = null, f: string = null, aggs: string = null, sort: string = null, page = 1, limit = 10): Promise<IListResults<ContactSegment>> {
    return this.s.contacts.list("segments", ContactSegment, q, f, aggs, sort, page, limit);
  }

  public async getById(id: string): Promise<ContactSegment> {
    const res = await this.s.contacts.get<ContactSegment>(`segments/${id}`);
    return createFrom(ContactSegment, res.body);
  }

  public async put(segment: ContactSegment) {
    const res = await this.s.contacts.put(`segments/${segment.id}`, segment);
    return createFrom(ContactSegment, res.body);
  }

  public async post(args: NewContactSegmentArgs) {
    const res = await this.s.contacts.post<ContactSegment>('segments', args);
    return createFrom(ContactSegment, res.body);
  }

  public async patch(id: string, version: string, data: Operation[]): Promise<ContactSegment> {
    const res = await this.s.contacts.patch<ContactSegment>(`segments/${id}`, data, { version: version });
    return createFrom(ContactSegment, res.body);
  }

  public async patchSelection(selectionPatches: IEntitySelectionPatches) {
    if (selectionPatches == null)
      return;

    return await this.s.contacts.patch<ContactSegment[]>("segments/patch-selection", selectionPatches);
  }

  public async setCategoryByIds(ids: string[], category: string) {
    const patch: Operation[] = [ <Operation>{ "op": "replace", "path": "$.category", "value": category} ];
    return await this.patchSelection({ ids: ids, patch: patch })
  }

  public async delete(segment: ContactSegment) {
    await this.s.contacts.delete(`segments/${segment.id}`);
  }

  public async deleteSelection(selection: IEntitySelection) {
    if (selection == null)
      return;

    return await this.s.contacts.post("segments/delete-selection", selection);
  }
}
