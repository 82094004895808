import { prop, inject, needs } from "fw";
import { TaskDateRangeFilter as TaskDateRangeDueFilterModel } from "models/task-filters";
import { DateFilter } from "views/components/filters/date-filter";
import { FeatureFlagService } from "service/feature-flag";
import { DateRange, RangeStamp } from "views/components/date-range";
import { ComponentEventBus } from "fw";
import { getFriendlyTiming } from "models/date-filter";

@inject
@needs(DateFilter, DateRange)
export class TaskDateRangeFilter {
  @prop(null) filter!: TaskDateRangeDueFilterModel;
  @prop(false) editing!: boolean;
  @prop(() => ({})) validation;

  constructor(
    private ffs: FeatureFlagService,
    private ceb: ComponentEventBus
  ) {}

  public dateRangeCallback(date: RangeStamp): void {
    this.filter.timing = date.timing;
    this.filter.since = date.timing;
    this.filter.marker = date.marker;
    this.filter.field = "dateDueUtc";

    if (date.timing === "date") {
      this.filter.startDate = date?.range?.startDate;
      this.filter.endDate = date?.range?.endDate;
    }

    this.ceb.dispatch("update:filter", this.filter);
    this.ceb.dispatch("filter-changed", this.filter);
  }

  get filterText() {
    if (this.filter == null) return "";
    return getFriendlyTiming(this.filter);
  }

}
