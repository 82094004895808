import { fromClass } from "fw-model";

export class UserTaskMetaData {
  OrganizationId: string = null;
  SeasonId: string = null;

  DateLastAssignedUtc: string = null;
  DateCompletedUtc: string = null;
  DateCreatedUtc: string = null;
  DateUpdatedUtc: string = null;
}

export class UserTask {
  Id: string = null;
  Description: string = null;
  AssignedToUserId: string = null;
  TargetType: "Application" | "Contact" = null;
  TargetId: string = null;
  DateDueUtc: string = null;
  Tags: Array<string> = null;
  IsCompleted: boolean = false;
  IsDeleted: boolean = false;

  @fromClass MetaData: UserTaskMetaData;
}

export class TaskSummaryResult {
  Overdue: number = 0;
  DueToday: number = 0;
  DueSoon: number = 0;
}
