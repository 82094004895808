import { fromClass, fromClassArray } from "fw-model";
import { CustomProperty } from "./custom-property";
import { ConditionInfo } from "./form";
import { GridColumn } from "./grid-column";

export {
  CustomPropertyEnumerationOption,
  CustomPropertyTypeCode,
} from "./custom-property";

export class PinnedField {
  Label: string = null;
  Path: string = null;
  Expression: string = null;
  HideLabel = false;
}

export class PinnedFieldGroup {
  ColumnSpan = 1;
  Label: string = null;
  Condition: string = null;
  @fromClass ConditionInfo: ConditionInfo;

  @fromClassArray(PinnedField) Fields: PinnedField[];
}

export class PinnedFieldSettings {
  @fromClassArray(PinnedFieldGroup) PinnedFieldGroups: PinnedFieldGroup[];
  @fromClassArray(PinnedFieldGroup) SidePinnedFieldGroups: PinnedFieldGroup[];
}

export class ApplicationProperty extends CustomProperty {}

export class ApplicationSettingsMetaData {
  ManagedRefs: string[];
  ApplicationPropertiesLastUpdatedUtc: string;
  ApplicationPropertiesUpdatedBy: string;
}

export class ApplicationPropertiesUpdateResult {
  @fromClassArray(ApplicationProperty)
  ApplicationProperties: ApplicationProperty[];
  @fromClass MetaData: ApplicationSettingsMetaData;
}

export class ProgramProperty extends CustomProperty {
  IsPublic: boolean = false;
}

export class ApplicationSettings {
  @fromClassArray(GridColumn) GridColumns: GridColumn[];
  @fromClassArray(GridColumn) DefaultGridColumns: GridColumn[];

  @fromClassArray(PinnedFieldGroup) PinnedFieldGroups: PinnedFieldGroup[];
  @fromClassArray(PinnedFieldGroup) SidePinnedFieldGroups: PinnedFieldGroup[];

  @fromClassArray(ApplicationProperty)
  ApplicationProperties: ApplicationProperty[];

  @fromClass MetaData: ApplicationSettingsMetaData;
}
