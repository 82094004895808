import { ComponentEventBus, inject, prop } from "fw";
import type { ICustomFieldDefinition } from "models/contact-organization";
import { FieldMode } from "views/contacts/components/field-mode";
import { IDropdownFieldOptions } from "views/components/dropdown-field";
import { ContactsOrganizationService } from "service/contacts-organization";
import { orderBy } from "lodash-es";

@inject
export class FunnelType {
  @prop(null) private field!: ICustomFieldDefinition;
  @prop(undefined) public value!: string;
  @prop("") public placeholder!: string;
  @prop(FieldMode.View) public mode!: FieldMode;
  @prop(false) public disabled!: boolean;
  @prop(false) public showLabel!: boolean;
  @prop(false) public showMeta!: boolean;

  constructor(
    private ceb: ComponentEventBus,
    private contactOrganizationService: ContactsOrganizationService
  ) {}

  public get metaDescription() {
    return this.showMeta
      ? this.contactOrganizationService.getFieldMetaDescription(this.field)
      : null;
  }

  public get label() {
    return this.showLabel ? this.field.display_name : null;
  }

  public get options(): IDropdownFieldOptions[] {
    if (!this.field || !this.field.options) {
      return [];
    }

    const sorted = orderBy(this.field.options, "display_order");
    return sorted
      .filter((o) => o != null)
      .map((o) => <IDropdownFieldOptions>{ value: o.name, text: o.name });
  }

  public get localValue() {
    return this.value;
  }

  public set localValue(value) {
    this.ceb.dispatch("update:value", value);
  }
}
