import { inject } from "fw";
import { DialogController } from "fw-dialog";

export interface ConfirmDialogV2Args {
  title?: string;
  text: string;
  okButtonText?: string;
  okButtonClass?: string;
  cancelButtonClass?: string;
  cancelButtonText?: string;
}

@inject
export class ConfirmDialogV2 {
  public title = "Are You Sure?";
  public text = "";
  public okButtonText = "Ok";
  public okButtonClass = "";
  public cancelButtonClass = "";
  public cancelButtonText = "Cancel";
  public closeButtonEl: HTMLElement;

  constructor(private controller: DialogController<void>) {}

  attached() {
    this.closeButtonEl.focus();
  }

  activate(args: ConfirmDialogV2Args) {
    const { cancelButtonText, okButtonClass, okButtonText, text, title, cancelButtonClass } = args;
    this.text = text;
    this.title = title || this.title;
    this.okButtonText = okButtonText || this.okButtonText;
    this.okButtonClass = okButtonClass || this.okButtonClass;
    this.cancelButtonClass = cancelButtonClass || this.cancelButtonClass;
    this.cancelButtonText = cancelButtonText || this.cancelButtonText;
  }

  ok() {
    this.controller.ok(null);
  }

  cancel() {
    this.controller.cancel();
  }
}
