import { inject } from "fw";
import { createFrom, createFromArray } from "fw-model";

import { ATS } from "./ats";
import { FilePostResult, File, FileCopyResult } from "models/file";

@inject
export class FileRepository {
  constructor(private s: ATS) {}

  public async post(args: PostArgs) {
    const res = await this.s.post("file", args);
    return createFrom(FilePostResult, res.body);
  }

  public async get(id: string) {
    const res = await this.s.get(`file/${id}`);
    return createFrom(File, res.body);
  }

  public async uploadComplete(id: string) {
    const res = await this.s.put(`file/${id}/upload-complete`, null);
    return createFrom(File, res.body);
  }

  public async list(ids: string[]) {
    const res = await this.s.post<any[]>("files/list", ids);
    return createFromArray(File, res.body);
  }

  public async copy(args: CopyArgs) {
    const res = await this.s.post("file/copy", args);
    return createFrom(FileCopyResult, res.body);
  }
}

export interface PostArgs {
  Filename: string;
  Size: number;
  ContextType: string;
  ContextId: string;
  ContentType: string;
  OrganizationId: string;
}

export interface CopyArgs {
  sourceId: string;
  contextType: string;
  contextId: string;
}
