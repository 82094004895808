import { prop, inject, ComponentEventBus } from "fw";
import { Form } from "fw-model";

@inject
export class ColorFormField {
  @prop(null) public form!: Form;
  @prop(null) public field;
  @prop(null) public label!: string;
  @prop(false) public hideLabel!: boolean;
  @prop(null) public ariaLabel!: string;

  @prop(null) public setfocus;
  @prop(null) public readonly;
  @prop(false) public disabled;
  @prop(null) public floatingLabel;
  @prop(null) public placeholder;
  @prop(null) public required: boolean;
  @prop(false) showReset!: boolean;
  @prop(null) public defaultValue: string;

  private name = "";

  constructor(private ceb: ComponentEventBus) { }

  public attached() {
    this.formChanged();
  }

  private formChanged() {
    this.name = this.form && this.form.getFieldName ? this.form.getFieldName(this.field) : this.field;
  }

  private onChange() { this.ceb.dispatch("change"); }
  private onKeyUp() { this.ceb.dispatch("keyup"); }
  private onKeyDown() { this.ceb.dispatch("keydown"); }

  private onUpdateValue(value) {
    this.ceb.dispatch("update:value", value);
  }
}
