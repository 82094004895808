import { inject } from "fw";
import { Store, dispatch, handle } from "fw-state";
import { groupBy, difference, orderBy } from "lodash-es";

import { ApplicationSegment } from "models/application-segment";
import { ApplicationSegmentRepository } from "network/application-segment-repository";
import { LogoutAction, StartAction } from "./actions";
import { FormErrorHandling } from "./error-handling";

import {
  CreateApplicationSegmentAction,
  UpdateApplicationSegmentAction,
} from "forms/application-segment";

interface ApplicationSegmentStoreShape {
  categories: { [categoryName: string]: ApplicationSegment[] };
  segmentFilterString: string,
  segments: ApplicationSegment[];
  selectedCategory: string;
}

export class ApplicationSegmentCreatedAction {
  constructor(public segment: ApplicationSegment) { }
}

export class ApplicationSegmentUpdatedAction {
  constructor(public segment: ApplicationSegment) { }
}

export class ApplicationSegmentsDeletedAction {
  constructor(public segmentIds: string[]) { }
}

export class ApplicationSegmentDeleteAction {
  constructor(public segmentIds: string[]) {}
}

export class SelectCurrentSegmentCategoryAction {
  constructor(public selectedCategory: string) {}
}

export class ApplicationSegmentCategorizeAction {
  constructor(public segmentIds: string[], public category: string) {}
}

export class SetApplicationSegmentFilterStringAction {
  constructor(public segmentFilterString: string) { }
}

@inject
export class ApplicationSegmentStore extends Store<ApplicationSegmentStoreShape> {
  constructor(private segRepo: ApplicationSegmentRepository) {
    super();
  }

  defaultState() {
    return {
      categories: {},
      segmentFilterString: '',
      segments: [],
      selectedCategory: null
    };
  }

  private computeCategories(segments: ApplicationSegment[]): { [categoryName: string]: ApplicationSegment[] } {
    return groupBy(orderBy(segments.filter(s => s.Category != null), s => s.Category.toLowerCase()), s => s.Category);
  }

  @handle(StartAction)
  private handleStartAction(action: StartAction) {
    const { ApplicationSegments } = action.context;
    const sortedSegments = orderBy(ApplicationSegments, [s => s.Label.toLowerCase()]);

    this.setState(state => ({
      ...state,
      segments: sortedSegments,
      categories: this.computeCategories(sortedSegments),
    }));
  }

  @handle(LogoutAction)
  private handleLogoutAction() {
    this.setState(s => this.defaultState());
  }

  @handle(CreateApplicationSegmentAction, FormErrorHandling)
  private async handleCreateApplicationSegmentAction(
    f: CreateApplicationSegmentAction,
  ) {
    f.form.validate();

    const newSegment = await this.segRepo.post(f.form.updatedModel());

    f.createdId = newSegment.Id;
    const segments = [...this.state.segments, newSegment];
    const sortedSegments = orderBy(segments, [s => s.Label.toLowerCase()]);

    this.setState(state => ({
      ...state,
      segments: sortedSegments,
      categories: this.computeCategories(sortedSegments),
    }));

    await dispatch(new ApplicationSegmentCreatedAction(newSegment));
  }

  @handle(SelectCurrentSegmentCategoryAction)
  private handleSelectCurrentSegmentCategoryAction(action: SelectCurrentSegmentCategoryAction) {
    this.setState(state => ({
      ...state,
      selectedCategory: action.selectedCategory,
    }));
  }

  @handle(UpdateApplicationSegmentAction, FormErrorHandling)
  private async handleUpdateApplicationSegmentAction(
    f: UpdateApplicationSegmentAction,
  ) {
    f.form.validate();

    const updatedSegment = await this.segRepo.put(f.form.updatedModel());
    const existingSegment = this.state.segments.find(
      s => s.Id == updatedSegment.Id,
    );

    Object.assign(existingSegment, updatedSegment);

    const sortedSegments = orderBy(this.state.segments, [s => s.Label.toLowerCase()]);

    this.setState(state => ({
      ...state,
      segments: sortedSegments,
      categories: this.computeCategories(sortedSegments),
    }));

    await dispatch(new ApplicationSegmentUpdatedAction(updatedSegment));
  }

  @handle(ApplicationSegmentDeleteAction)
  private async handleDelete(action: ApplicationSegmentDeleteAction) {
    const deleted = this.state.segments.filter(s =>
      action.segmentIds.some(id => id == s.Id),
    );

    await this.segRepo.del(deleted.map(s => s.Id));
    const segments = difference(this.state.segments, deleted);

    this.setState(state => ({
      ...state,
      segments,
      categories: this.computeCategories(segments),
    }));

    await dispatch(new ApplicationSegmentsDeletedAction(action.segmentIds));
  }

  @handle(ApplicationSegmentCategorizeAction)
  private async handleApplicationSegmentCategorizeAction(
    action: ApplicationSegmentCategorizeAction,
  ) {
    await this.segRepo.putCategory(action.segmentIds, action.category);

    action.segmentIds.forEach(sid => {
      const segment = this.state.segments.find(s => s.Id == sid);
      if (segment == null) {
        return;
      }

      segment.Category = action.category;
    });

    this.setState(state => ({
      ...state,
      categories: this.computeCategories(state.segments),
    }));
  }

  @handle(SetApplicationSegmentFilterStringAction)
  private async handleSetApplicationSegmentFilterStringAction(
    action: SetApplicationSegmentFilterStringAction
  ) {
    if (!!action.segmentFilterString) {
      this.setState(state => ({
        ...state,
        segmentFilterString: action.segmentFilterString,
      }))
    }
  }
}
