import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { once } from "helpers/once";

import {
  EntityChanged,
  WebSocketMessageAction,
  filterWebsocketMessage
} from './filter-websocket-message';
import { DataDictionaryField } from "models/data-dictionary";
import { DataDictionaryRepository } from "network/data-dictionary-repository";
import { debounce } from "lodash-es";

interface DataDictionaryShape {
  status: LoadingStatus;
  includeUsages: boolean;
  fields: DataDictionaryField[];
  fieldPathHash: { [path: string]: string };
  userFields: DataDictionaryField[];
}

export type LoadingStatus = "loading" | "loaded" | "error" | null

export class EnsureDataDictionaryFieldsAction {
  constructor(public includeUsages: boolean = false) { }
}

export class ForceRefreshDataDictionaryFieldsAction {
  constructor() {}
}

export class DataDictionaryRefreshedAction {
  constructor() { }
}

export class EnsureUserDataDictionaryFieldsAction {
  constructor() {}
}

@inject
export class DataDictionaryStore extends Store<DataDictionaryShape> {
  private debouncedLoadFields = async () => { };

  constructor(
    private repository: DataDictionaryRepository
  ) {
    super();
    this.debouncedLoadFields = debounce(() => this.loadFields(), 1000, { maxWait: 1000 });
  }

  defaultState() {
    return {
      status: null,
      includeUsages: false,
      fields: [],
      fieldPathHash: {},
      userFields: []
    };
  }

  @handle(EnsureDataDictionaryFieldsAction)
  private async handleEnsureDataDictionaryFieldsAction(action: EnsureDataDictionaryFieldsAction) {
    const { includeUsages, status } = this.state;
    const forceRefresh = !includeUsages && action.includeUsages;

    if (action.includeUsages !== includeUsages) {
      this.setState(state => ({
        ...state,
        includeUsages: action.includeUsages
      }));
    }

    if (!forceRefresh && (status === "loading" || status === "loaded")) {
      return;
    }

    await this.loadFields();
  }

  @handle(ForceRefreshDataDictionaryFieldsAction)
  private async handleForceRefreshDataDictionaryFieldsAction(action: ForceRefreshDataDictionaryFieldsAction) {
    await this.loadFields();
  }

  private async loadFields() {
    this.setState(state => ({
      ...state,
      status: "loading"
    }));

    try {
      const fields = await this.repository.list(this.state.includeUsages);
      const fieldPathHash = {};
      for (const [idx, field] of fields.entries()) {
          fieldPathHash[field.Path] = idx;
      }

      this.setState(state => ({
        ...state,
        status: "loaded",
        fields,
        fieldPathHash,
      }));
      await dispatch(new DataDictionaryRefreshedAction());
    } catch {
      this.setState(state => ({
        ...state,
        status: "error"
      }));
    }
  }

  @handle(WebSocketMessageAction, filterWebsocketMessage("EntityChanged"))
  private async handleEntityChangedAction(action: WebSocketMessageAction<EntityChanged>) {
    switch (action.data.type) {
      case "Organization":
      case "Season":
      case "SeasonAliasList":
      case "Program":
      case "Form":
        await this.debouncedLoadFields();
        break;
    }
  }

  @handle(EnsureUserDataDictionaryFieldsAction)
  private async handleEnsureUserDataDictionaryFieldsAction() {
    await once("ensure-user-fields", async () => {
      const userFields = await this.repository.listUserData();
      this.setState(state => ({
        ...state,
        userFields
      }));
    });

  }
}

