import { inject } from "fw";
import { createFrom, createFromArray, makerOf } from "fw-model";
import { MemoryCache, cacheFor } from "caching";
import { uniq } from "lodash-es";

import { ATS } from "./ats";

import { encodeProgram } from "./encode";

import { Program, ProgramStep, ProgramStepGroup, ProgramStepGroupHistory } from "models/program";
import { SearchAfterListResult } from "models/search-after-list-result";

@inject
export class ProgramRepository {
  constructor(private s: ATS, private cache: MemoryCache) { }

  public async getAll() {
    const res = await this.s.get<any[]>(`program`);
    const programs = createFromArray(Program, res.body);

    programs.forEach(program =>
      this.cache.set(`program:${program.Id}`, program, cacheFor(5).minutes),
    );

    return programs;
  }

  public async create(p: Program) {
    const res = await this.s.post(`program`, encodeProgram(p));
    return createFrom(Program, res.body);
  }

  public async update(p: Program) {
    const res = await this.s.put(`program/${p.Id}`, encodeProgram(p));

    this.cache.remove(`program:${p.Id}`);

    return createFrom(Program, res.body);
  }

  public async bulkEnsureStageTemplates(programIds: string[], templateIds: string[]) {
    const res = await this.s.post<string[]>("program/bulk-ensure-stage-templates",
      {
        programIds,
        templateIds
      }
    );
    return res.body;
  }

  public async get(id: string, useCache: boolean = true): Promise<Program> {
    if (useCache) {
      const cached = this.cache.get<Program>(`program:${id}`);
      if (cached != null) return cached;
    }

    const res = await this.s.get(`program/${id}`);
    const program = createFrom(Program, res.body);

    this.cache.set(`program:${id}`, program, cacheFor(5).minutes);

    return program;
  }

  public async getStepGroup(id: string, useCache = true): Promise<ProgramStepGroup> {
    const cacheKey: string = `program-group-step:${id}`;
    if (useCache) {
      const cached = this.cache.get<ProgramStepGroup>(cacheKey);
      if (cached != null) {
        return cached;
      }
    }

    const stepGroups = await this.getStepGroups([id]);
    if (stepGroups.length > 1) {
      throw new Error("More than none step group returned");
    }

    if (useCache) {
      this.cache.set(cacheKey, stepGroups[0], cacheFor(10).seconds);
    }

    return stepGroups[0];
  }

  public async getStepGroups(ids: string[]): Promise<ProgramStepGroup[]> {
    const res = await this.s.get<ProgramStepGroup[]>(`program/step-group/${ids.join(",")}`);
    return createFromArray(ProgramStepGroup, res.body);
  }

  public async del(ids: string[]) {
    await this.s.post("program/delete", ids);

    ids.forEach(id => this.cache.remove(`program:${id}`));
  }

  private async getWithCache<T extends { Id: string }>(ids: string[], apiUrlPrefix: string, cacheKey: string, dataType: makerOf<T>) {
    ids = ids ? uniq(ids) : [];

    const uncachedIds: string[] = [];
    const cached: T[] = [];

    ids.forEach(id => {
      const cachedThing = this.cache.get<T>(
        `${cacheKey}:${id}`,
      );

      if (cachedThing != null) cached.push(cachedThing);
      else uncachedIds.push(id);
    });

    const uncached: T[] = [];
    if (uncachedIds.length > 0) {
      const res = await this.s.get<any[]>(
        `${apiUrlPrefix}/${uncachedIds.join(",")}`,
      );
      const created = createFromArray(dataType, res.body);

      created.forEach(t => {
        uncached.push(t);

        // just cache forever..  we don't have to reload this one
        this.cache.set(`${cacheKey}:${t.Id}`, t);
      });
    }

    const thingList: T[] = [];

    ids.forEach(id => {
      const fromCached = cached.find(c => c.Id == id);
      if (fromCached != null) {
        thingList.push(fromCached);
        return;
      }

      const fromUncached = uncached.find(c => c.Id == id);

      if (fromUncached != null) thingList.push(fromUncached);
    });

    return thingList;
  }

  public async listStepGroups(stepGroupIds: string[]) {
    return await this.getWithCache(stepGroupIds, "program/step-group", "program-step-group", ProgramStepGroupHistory);
  }

  public async listSteps(stepIds: string[]) {
    return await this.getWithCache(stepIds, "program/step", "program-step", ProgramStep);
  }

  public async search(pageSize:number, filter:string, sort:string, previousPageToken: string, nextPageToken: string, fields: string, includeHidden:boolean) {
    
    const res = await this.s.get<SearchAfterListResult<Program>>("program/search", {
      f : filter,
      sort,
      pageSize,
      previousPageToken,
      nextPageToken,
      fields,
      includeHidden
    });
    return createFrom<SearchAfterListResult<Program>>(SearchAfterListResult, res.body);
  }
}
