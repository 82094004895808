import { prop } from "fw";
import { isLiaisonIcon } from "./select-icon-popover";

export class Icon {
  @prop(null) public name!: string;
  @prop(null) public ariaLabel?: string;
  @prop(null) public ariaHidden?: boolean;
  @prop(false) public useSvg?: boolean;
  @prop("") public viewBox?: string;

  public attached() {
    const hasHiddenValue: boolean =
      this.ariaHidden === false || this.ariaHidden === true;
    if (hasHiddenValue && this.ariaHidden && this.ariaLabel) {
      console.warn("aria-hidden and aria-label cannot both be set.");
    }
  }

  public get isLiaisonIcon(): boolean {
    return isLiaisonIcon(this.name);
  }

  paths = {
    abc: [
      "M15.4 10.5V9c0-1.1-.9-2-2-2h-2-1.5-.5v10h.5 1.5 2c1.1 0 2-.9 2-2v-1.5a1.54 1.54 0 0 0-1.5-1.5 1.54 1.54 0 0 0 1.5-1.5zm-2 4.5h-2v-2h2v2zm0-4h-2V9h2v2zm-7-4h-.5 0-1.5c-1.1 0-2 .9-2 2v8h2v-4h1.5 0 .5v4h2V9c0-1.1-.9-2-2-2zm0 4h-.5 0-1.5V9h1.5 0 .5v2zm12-4h.5 1 .5c1.1 0 2 .9 2 2v.9h-2V9h-.5-1-.5v.4V11v2 1.6.4h.5 1 .5v-.9h2v.9c0 1.1-.9 2-2 2h-.5-1-.5c-1.1 0-2-.9-2-2v-2-2-2c0-1.1.9-2 2-2z",
    ],
    numbers: [
      "M4 17h2V7H2v2h2zm10-2h-4v-2h2c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8v2h4v2h-2c-1.1 0-2 .9-2 2v4h6v-2zm8 0v-1.5a1.54 1.54 0 0 0-1.5-1.5 1.54 1.54 0 0 0 1.5-1.5V9c0-1.1-.9-2-2-2h-4v2h4v2h-2v2h2v2h-4v2h4c1.1 0 2-.9 2-2z",
    ],
    boolean: [
      "M11.5 6.6h2v12h-2zm-3.1 3L6 11.8 3.7 9.6l-1.1 1 2.1 2.5-2.1 2.5 1.1.9L6 14.2l2.4 2.3 1.2-1.2-2.4-2.2 2.4-2.3zm13.2 0l-3 4-2-2-1 1 3 3h0l4-5z",
    ],
    array: [
      "M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z",
    ],
    cube: [
      "m11.583 23.769.417.231.417-.231 6.25-3.461 4.003-2.217v-.507-12.148-.552l-.502-.23-1.192-.546-8.976-4.108-8.975 4.108-1.192.546-.502.23v.552 12.148.507l4.003 2.217 6.25 3.461zm3.255-15.456-2.838 1.572-2.839-1.572-5.062-2.804 7.901-3.616 7.901 3.616-5.062 2.804zm-1.978 13.243v-10.181l8.088-4.48v10.181zm-1.72-10.181v10.181l-8.088-4.48v-10.18l8.088 4.48z",
    ],
    tag: [
      "M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41a2.02 2.02 0 0 0-.59-1.42zM13 20.01L4 11V4h7v-.01l9 9-7 7.02zM5 6.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 1 0-3 0",
    ],
    campaign: [
      "M19.2 10.4v2.4H24v-2.4h-4.8zm-2.4 7.932l3.84 2.868 1.44-1.92-3.84-2.88-1.44 1.932zM22.08 3.92L20.64 2 16.8 4.88l1.44 1.92 3.84-2.88zM2.4 8A2.41 2.41 0 0 0 0 10.4v2.4a2.41 2.41 0 0 0 2.4 2.4h1.2V20H6v-4.8h1.2l6 3.6V4.4L7.2 8H2.4zm6.036 2.052L10.8 8.636v5.928l-2.364-1.416-.576-.348H7.2 2.4v-2.4h4.8.66l.576-.348zM16.2 11.6c.002-1.536-.653-2.999-1.8-4.02v8.028a5.33 5.33 0 0 0 1.8-4.008h0z",
    ],
    logout: [
      "M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z",
    ],
    alert: [
      "M8.27,3L3,8.27V15.73L8.27,21H15.73C17.5,19.24 21,15.73 21,15.73V8.27L15.73,3M9.1,5H14.9L19,9.1V14.9L14.9,19H9.1L5,14.9V9.1M11,15H13V17H11V15M11,7H13V13H11V7",
    ],
    icon_calculator_solid:[
      "M 17.99994,0 H 1.9999914 C 0.8959899,0 0,0.89600057 0,2.0000057 V 21.999943 C 0,23.103943 0.8959899,24 1.9999914,24 H 17.99994 C 19.103942,24 20,23.103943 20,21.999943 V 2.0000057 C 20,0.89600057 19.103942,0 17.99994,0 Z M 4.5000064,21 c -0.8280012,0 -1.5000021,-0.672 -1.5000021,-1.5 0,-0.828 0.6720009,-1.5 1.5000021,-1.5 0.8280012,0 1.5000022,0.672 1.5000022,1.5 0,0.828 -0.672001,1.5 -1.5000022,1.5 z m 0,-5.000006 c -0.8280012,0 -1.5000021,-0.672 -1.5000021,-1.5 0,-0.828 0.6720009,-1.5 1.5000021,-1.5 0.8280012,0 1.5000022,0.672 1.5000022,1.5 0,0.828 -0.672001,1.5 -1.5000022,1.5 z M 10.000009,21 c -0.828002,0 -1.5000026,-0.672 -1.5000026,-1.5 0,-0.828 0.6720006,-1.5 1.5000026,-1.5 0.828001,0 1.500002,0.672 1.500002,1.5 0,0.828 -0.672001,1.5 -1.500002,1.5 z m 0,-5.000006 c -0.828002,0 -1.5000026,-0.672 -1.5000026,-1.5 0,-0.828 0.6720006,-1.5 1.5000026,-1.5 0.828001,0 1.500002,0.672 1.500002,1.5 0,0.828 -0.672001,1.5 -1.500002,1.5 z M 15.499994,21 c -0.827984,0 -1.499985,-0.672 -1.499985,-1.5 0,-0.828 0.672001,-1.5 1.499985,-1.5 0.828001,0 1.500002,0.672 1.500002,1.5 0,0.828 -0.672001,1.5 -1.500002,1.5 z m 0,-5.000006 c -0.827984,0 -1.499985,-0.672 -1.499985,-1.5 0,-0.828 0.672001,-1.5 1.499985,-1.5 0.828001,0 1.500002,0.672 1.500002,1.5 0,0.828 -0.672001,1.5 -1.500002,1.5 z M 16.999996,9 H 3.0000043 V 3 H 16.999996 Z",
    ],
    icon_compare:[
      "M19.7559 6.91074C19.4305 6.58531 18.9028 6.58531 18.5774 6.91074L15.8333 9.65482L13.0893 6.91074C12.7638 6.58531 12.2362 6.58531 11.9107 6.91074C11.5853 7.23618 11.5853 7.76382 11.9107 8.08926L15.2441 11.4226C15.5695 11.748 16.0972 11.748 16.4226 11.4226L19.7559 8.08926C20.0814 7.76382 20.0814 7.23618 19.7559 6.91074Z",
      "M15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10V6.66667C16.6667 4.3656 14.8011 2.5 12.5 2.5H5.83333C5.3731 2.5 5 2.8731 5 3.33333C5 3.79357 5.3731 4.16667 5.83333 4.16667H12.5C13.8806 4.16667 15 5.28607 15 6.66667V10C15 10.4602 15.3731 10.8333 15.8333 10.8333Z",
      "M8.08926 13.0893C8.41469 12.7638 8.41469 12.2362 8.08926 11.9107L4.75592 8.57741C4.43049 8.25197 3.90285 8.25197 3.57741 8.57741L0.244078 11.9107C-0.0813593 12.2362 -0.0813593 12.7638 0.244078 13.0893C0.569515 13.4147 1.09715 13.4147 1.42259 13.0893L4.16667 10.3452L6.91074 13.0893C7.23618 13.4147 7.76382 13.4147 8.08926 13.0893Z",
      "M15 16.6667C15 16.2064 14.6269 15.8333 14.1667 15.8333H7.5C6.1194 15.8333 5 14.7139 5 13.3333V10C5 9.53976 4.6269 9.16667 4.16667 9.16667C3.70643 9.16667 3.33333 9.53976 3.33333 10V13.3333C3.33333 15.6344 5.19893 17.5 7.5 17.5H14.1667C14.6269 17.5 15 17.1269 15 16.6667Z",
      "M5 3.33333C5 4.25381 4.25381 5 3.33333 5C2.41286 5 1.66667 4.25381 1.66667 3.33333C1.66667 2.41286 2.41286 1.66667 3.33333 1.66667C4.25381 1.66667 5 2.41286 5 3.33333ZM3.33333 6.66667C5.17428 6.66667 6.66667 5.17428 6.66667 3.33333C6.66667 1.49238 5.17428 0 3.33333 0C1.49238 0 1.98682e-07 1.49238 1.98682e-07 3.33333C1.98682e-07 5.17428 1.49238 6.66667 3.33333 6.66667Z",
      "M18.3333 16.6667C18.3333 17.5871 17.5871 18.3333 16.6667 18.3333C15.7462 18.3333 15 17.5871 15 16.6667C15 15.7462 15.7462 15 16.6667 15C17.5871 15 18.3333 15.7462 18.3333 16.6667ZM16.6667 20C18.5076 20 20 18.5076 20 16.6667C20 14.8257 18.5076 13.3333 16.6667 13.3333C14.8257 13.3333 13.3333 14.8257 13.3333 16.6667C13.3333 18.5076 14.8257 20 16.6667 20Z"
    ],
  };

  public get iconName() {
    if (this.name == "reorder") {
      return "menu";
    } else {
      return this.name;
    }
  }

  public get isHidden() {
    // if label is set then it defaults to false.
    if (this.ariaLabel) {
      return false;
    }

    const hasHiddenValue: boolean =
      this.ariaHidden === false || this.ariaHidden === true;
    return !hasHiddenValue || this.ariaHidden === true;
  }
}
