import { inject, needs, prop, ComponentEventBus } from "fw";

import { PopoverResult, PopoverService } from "service/popover";
import { AggregationType, ChartDataSource, } from "shared/report-runtime";
import { ChartField, FieldSelector, } from "views/applicants/chart/field-selector";
import { type FormForFunnelDefinition } from "forms/report-definition";
import { ContactTypeDefinition } from 'models/contact-organization';
import { ChartContactTypeSelector } from '../../contacts/charts/chart-contact-type-selector';
import { ChartContactsFieldSelector } from '../../contacts/charts/chart-contacts-field-selector';
import { CurrentContactOrganizationStore } from 'state/current-contact-organization';
import { CustomFieldType } from 'models/contact-organization';
import {
    DataDictionaryFieldSelectorOptions,
    DataDictionaryFieldSelectorPopover,
} from "views/components/data-dictionary-field-selector-popover";
import {
    DataDictionaryAggregationType,
    DataDictionaryField,
    DataDictionaryIndexStatus,
} from "models/data-dictionary";
import { FeatureFlagService } from "service/feature-flag";
import { ReportService } from "service/report";
import { DataDictionaryService } from "service/data-dictionary";

let inputIdIterator: number = 0;

@inject
@needs(ChartContactTypeSelector)
export class FunnelEditor {
  @prop(null)
  widgetDefinitionForm: FormForFunnelDefinition;

  private dataSource: ChartDataSource = ChartDataSource.Admissions;
  private fieldLabel?: string = null;
  private focusedOptionId: string = '';
  private funnelEditorBaseInputId: string = "funnel-editor-input";
  private funnelEditorPopoverId: string = "funnel-editor-popover";
  private popoverIsOpen: boolean = false;
  private selectedContactType?: string = null;

  constructor(
    private ceb: ComponentEventBus,
    private popoverService: PopoverService,
    private currentContactOrganizationStore: CurrentContactOrganizationStore,
    private ffs: FeatureFlagService,
    private reportService: ReportService,
    private dataDictionaryService: DataDictionaryService
  ) { }

  async attached() {
    this.dataSource = this.widgetDefinitionForm.DataSource;
    this.selectedContactType = this.widgetDefinitionForm.ContactType;
    const path = this.funnelChartDefinition?.Path || this.funnelChartDefinition?.PrimaryIndependentVariable?.Path
    if (!(path?.trim().length > 0))
      return;

    if (this.selectedContactType) {
      const fields = this.getChartFieldsForContactType(this.selectedContactType);
      this.fieldLabel = fields.find(f => f.Path == path)?.Label;
    } else {
      const field = await this.reportService.getChartableField(path);
      this.fieldLabel = field?.Label;
    }
  }

  chartChanged() {
    this.ceb.dispatch("changed");
  }

  public updateOptionFocus(optionId: string) {
    this.focusedOptionId = optionId;
  }

  private selectContactType(contactType: ContactTypeDefinition) {
    this.selectedContactType = contactType.key;
    this.funnelChartDefinition.ContactType = contactType.key;
    this.fieldLabel = null;
    this.chartChanged();
  }
  private fieldsByType: { [type: string]: ChartField[] } = {};

  private async getChartField(selectedPath?: string): Promise<ChartField> {
    let res: PopoverResult<ChartField> = null;

    switch (this.dataSource) {
      case ChartDataSource.Admissions:
        if (this.ffElectiveIndexing) {
          const fieldCategories = await this.reportService.getChartableFieldCategories();
          const options: DataDictionaryFieldSelectorOptions = {
              filter: (f: DataDictionaryField) => f.IndexStatus === DataDictionaryIndexStatus.Indexing || f.IndexStatus === DataDictionaryIndexStatus.Indexed,
              options: fieldCategories,
              selectedOption: fieldCategories.reduce((fields, category) => fields.concat(category.fields), []).find(f => f.Path === selectedPath),
              updateOptionFocus: this.updateOptionFocus,
          };
          const response = await this.popoverService.open<DataDictionaryField>(
            DataDictionaryFieldSelectorPopover,
            options,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { id: this.funnelEditorPopoverId },
          );
          res = { canceled: response.canceled, result: this.toChartField(response.result) };
        } else {
          res = await this.popoverService.open<ChartField>(
            FieldSelector,
            { updateOptionFocus: this.updateOptionFocus },
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { id: this.funnelEditorPopoverId },
          );
        }
        break;

      case ChartDataSource.Contacts:
        res = await this.popoverService.open<ChartField>(
          ChartContactsFieldSelector,
          {
            fields: this.getChartFieldsForContactType(this.selectedContactType),
            selectedChartField: this.selectedContactType,
            updateOptionFocus: this.updateOptionFocus,
          },
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            { id: this.funnelEditorPopoverId },
          );
        break;
    }

      return !res || res.canceled ? null : res.result;
  }

  private toChartField(field: DataDictionaryField): ChartField | null {
    let aggType: AggregationType;
    switch (field?.AggregationType) {
      case DataDictionaryAggregationType.None:
        return null;
      case DataDictionaryAggregationType.Histogram:
        aggType = this.dataDictionaryService.isDateField(field) ? AggregationType.Dates : AggregationType.Histogram;
        break;
      case DataDictionaryAggregationType.Terms:
        aggType = AggregationType.Terms;
        break;
      default:
        return null;
    }

    return <ChartField>{
      Label: field.Label,
      LabelTransform: field.LabelTransform,
      Path: field.Path,
      Type: aggType,
      HasOptionValues: field.Values?.length > 0
    };
  }

  async chooseField() {
    this.popoverIsOpen = true;
    const chartField = await this.getChartField();
    this.popoverIsOpen = false;

    if (!chartField) return;

    const { Label, Path } = chartField;

    this.funnelChartDefinition.Path = Path;
    this.funnelChartDefinition.Label = Label;
    this.fieldLabel = Label;

    this.chartChanged();
  }

  private getChartFieldsForContactType(contactType: string) {
    if (!this.fieldsByType[contactType]) {
      let fields = this.currentContactOrganizationStore.state.organization
        .fields
        .filter(field => {
          if (field.contact_type !== contactType || !field.is_indexed) {
            return false;
          }

          switch (field.type) {
            case CustomFieldType.number:
            case CustomFieldType.boolean:
            case CustomFieldType.date:
            case CustomFieldType.dropdown:
            case CustomFieldType.multiselect:
            case CustomFieldType.tags:
            case CustomFieldType.postalcode:
            case CustomFieldType.country:
            case CustomFieldType.funnel:
              return true;

            case CustomFieldType.string:
            case CustomFieldType.largestring:
            case CustomFieldType.address:
            case CustomFieldType.email:
            case CustomFieldType.phone:
            case CustomFieldType.social:
            case CustomFieldType.link:
            case CustomFieldType.relationship:
            case CustomFieldType.table:
            case CustomFieldType.slideroomapplications:
            case CustomFieldType.slideroomadmissionsapplications:
            case CustomFieldType.testscore:
            case CustomFieldType.user:
            case CustomFieldType.concealed:
            case CustomFieldType.attachment:
            default:
              return false;
          }
        })
        .map(field => {
          let fieldType = AggregationType.Terms;
          if (field.type == CustomFieldType.date) {
            fieldType = AggregationType.Dates;
          } else if (field.type == CustomFieldType.number) {
            fieldType = AggregationType.Histogram;
          }
          let path = field.search_field;
          if (field.is_system_field) {
            switch (field.search_field.toLowerCase()) {
              case "firstname": { path = "first_name"; break; }
              case "lastname": { path = "last_name"; break; }
              case "companyname": { path = "company_name"; break; }
              case "tags": { path = 'tags'; break; }
            }
          }

          return <ChartField>{
            Label: field.display_name,
            LabelTransform: (field.type == CustomFieldType.boolean)
              ? 'boolean'
              : null,
            Path: path,
            Type: fieldType,
            HasOptionValues: field.type != CustomFieldType.tags && field.options?.length > 0
          }
        });

      fields.push({ Label: "Engagement Score", Path: "engagement_score", Type: AggregationType.Histogram });

      this.fieldsByType[contactType] = fields;
    }
    return this.fieldsByType[contactType];
  }

  private get funnelChartDefinition(): FormForFunnelDefinition {
    return this.widgetDefinitionForm as FormForFunnelDefinition;
  }

  private get ffElectiveIndexing(): boolean {
    return this.ffs.isFeatureFlagEnabled("ElectiveIndexing");
  }

  get funnelEditorInputId() {
    inputIdIterator++;
    return `${this.funnelEditorBaseInputId}-${inputIdIterator}`;
  }

  get isContactDataSource() {
    return this.dataSource === ChartDataSource.Contacts;
  }
}
