import { inject } from "fw";
import { Store, handle, dispatch } from "fw-state";
import { difference } from "lodash-es";
import { wait } from "wait";

import {
  LogoutAction,
  StartAction
} from "./actions";

import { Contact, UnrestrictedContact } from "models/contact";
import { AppRepository } from "network/app-repository";
import { ContactRepository } from "network/contact-repository";
import { UserRepository } from "network/user-repository";
import { defaultUser } from "state/users";
import { FeatureFlagService } from "service/feature-flag";

export interface EntityReferenceItem {
  id: string;
  type: "Contact" | "Application" | "User";
  description: string;
  data: any;
  timestamp: number,
}

interface EntityReferenceShape {
  loading: boolean;
  referenceHash: { [id: string]: EntityReferenceItem };
}

export class EnsureApplicationReferencesAction {
  constructor(public ids: string[]) { }
}

export class EnsureContactReferencesAction {
  constructor(public ids: string[], public ignoreHashFilter: boolean = false) { }
}

export class EnsureUserReferencesAction {
  constructor(public ids: string[]) { }
}

export class EnsureEntityReferencesAction {
  constructor(public applicationIds?: string[], public contactIds?: string[], public userIds?: string[]) { }
}

export class CleanupEntityReferencesAction { }

// outside of UsersStore to prevent vue from tracking..
let applicationIdsToLoad: string[] = [];
let applicationIdsToLoadLoading: string[] = [];

let contactIdsToLoad: string[] = [];
let contactIdsToLoadLoading: string[] = [];

let userIdsToLoad: string[] = [];
let userIdsToLoadLoading: string[] = [];

@inject
export class EntityReferenceStore extends Store<EntityReferenceShape> {
  constructor(
    private appRepository: AppRepository,
    private contactRepository: ContactRepository,
    private userRepository: UserRepository,
    private ffs: FeatureFlagService,
  ) {
    super();
  }

  defaultState() {
    return {
      loading: false,
      referenceHash: {}
    };
  }

  @handle(StartAction)
  @handle(LogoutAction)
  private async handleStartAction(action: StartAction) {
    this.setState(state => this.defaultState());
  }

  @handle(EnsureEntityReferencesAction)
  private async handleEnsureEntityReferencesAction(action: EnsureEntityReferencesAction) {
    if ((!action.applicationIds || action.applicationIds.length === 0)
      && (!action.contactIds || action.contactIds.length === 0)
      && (!action.userIds || action.userIds.length === 0))
      return;

    this.setState(state => ({
      ...state,
      loading: true
    }));

    let newHash: { [id: string]: EntityReferenceItem } = {};
    if (action.applicationIds && action.applicationIds.length > 0)
      newHash = await this.loadApplications(action.applicationIds);

    if (action.contactIds && action.contactIds.length > 0)
      newHash = {
        ...newHash,
        ...(await this.loadContacts(action.contactIds))
      };

    if (action.userIds && action.userIds.length > 0)
      newHash = {
        ...newHash,
        ...(await this.loadUsers(action.userIds))
      };

    this.setState(state => ({
      ...state,
      loading: false,
      referenceHash: {
        ...this.state.referenceHash,
        ...newHash
      }
    }));

    dispatch(new CleanupEntityReferencesAction());
  }

  @handle(EnsureApplicationReferencesAction)
  private async handleEnsureApplicationReferencesAction(action: EnsureApplicationReferencesAction) {
    if (action.ids == null || action.ids.length === 0)
      return;

    this.setState(state => ({
      ...state,
      loading: true
    }));

    const newHash = await this.loadApplications(action.ids);

    this.setState(state => ({
      ...state,
      loading: false,
      referenceHash: {
        ...this.state.referenceHash,
        ...newHash
      }
    }));

    dispatch(new CleanupEntityReferencesAction());
  }

  private async loadApplications(ids: string[]) {
    if (ids == null || ids.length === 0)
      return;

    const updates = ids.filter(
      id =>
        id != null &&
        id.length > 0 &&
        this.state.referenceHash[id] != null
    );

    for (const id in updates) {
      if (this.state.referenceHash[id])
        this.state.referenceHash[id].timestamp = Date.now();
    }

    const idsToFetch = ids.filter(
      id =>
        id != null &&
        id.length > 0 &&
        this.state.referenceHash[id] == null &&
        applicationIdsToLoad.indexOf(id) == -1 &&
        applicationIdsToLoadLoading.indexOf(id) == -1
    );

    if (idsToFetch.length == 0) {
      return;
    }

    if (applicationIdsToLoad.length > 0) {
      // push it on to this thing and return..
      applicationIdsToLoad.push(...idsToFetch);
      return;
    }

    applicationIdsToLoad.push(...idsToFetch);

    await wait(10); // this is the buffer time, so that multiple components can all get in here
    applicationIdsToLoadLoading.push(...applicationIdsToLoad);
    applicationIdsToLoad = [];

    const currentIdsToLoad = applicationIdsToLoadLoading.slice();

    const res = await this.appRepository.clientModelList(currentIdsToLoad);

    const newHash: { [id: string]: EntityReferenceItem } = {};

    for (const application of res.filter(r => r !== undefined)) {
      newHash[application.id] = {
        id: application.id,
        type: "Application",
        description: `${application.applicant.givenName} ${application.applicant.familyName}`,
        data: application,
        timestamp: Date.now()
      };

      const idx = applicationIdsToLoadLoading.indexOf(application.id);
      if (idx >= 0) applicationIdsToLoadLoading.splice(idx, 1);
    }

    return newHash;
  }

  @handle(EnsureContactReferencesAction)
  private async handleEnsureContactReferencesAction(action: EnsureContactReferencesAction) {
    if (action.ids == null || action.ids.length === 0)
      return;

    this.setState(state => ({
      ...state,
      loading: true
    }));

    const newHash = await this.loadContacts(action.ids, action.ignoreHashFilter);

    this.setState(state => ({
      ...state,
      loading: false,
      referenceHash: {
        ...this.state.referenceHash,
        ...newHash
      }
    }));

    dispatch(new CleanupEntityReferencesAction());
  }

  private async loadContacts(ids: string[], ignoreHashFilter: boolean = false) {
    if (ids == null || ids.length === 0)
      return;

    const updates = ids.filter(
      id =>
        id != null &&
        id.length > 0 &&
        this.state.referenceHash[id] != null
    );

    for (const id in updates) {
      if (this.state.referenceHash[id])
        this.state.referenceHash[id].timestamp = Date.now();
    }

    let idsToFetch: string[];
    const contactIdsToCheckArray = [...contactIdsToLoad, ...contactIdsToLoadLoading];
    
    const isValidId = (id: string) => {
      const isValidId = !!id?.length;
      const isNotInLoadArrays = !contactIdsToCheckArray.includes(id);
      return isValidId && isNotInLoadArrays;
    }

    if (ignoreHashFilter) {
      idsToFetch = ids.filter(
        id => isValidId(id));
    }
    else {
      idsToFetch = ids.filter(
        id => isValidId(id) && !this.state.referenceHash[id]);
    }

    if (idsToFetch.length == 0) {
      return;
    }

    if (contactIdsToLoad.length > 0) {
      // push it on to this thing and return..
      contactIdsToLoad.push(...idsToFetch);
      return;
    }

    contactIdsToLoad.push(...idsToFetch);

    await wait(10); // this is the buffer time, so that multiple components can all get in here
    contactIdsToLoadLoading.push(...contactIdsToLoad);
    contactIdsToLoad = [];

    const currentIdsToLoad = contactIdsToLoadLoading.slice();

    let contacts: Contact[] | UnrestrictedContact[] = [];
    try {
      contacts = (await this.contactRepository.selectUnrestricted({ ids: currentIdsToLoad })).results;
    } catch { /* not found is too generic to know */ }

    const newHash: { [id: string]: EntityReferenceItem } = {};

    for (const contactId of currentIdsToLoad) {
      const contact = contacts.find(c => c.id === contactId);
      newHash[contactId] = {
        id: contactId,
        type: "Contact",
        description: contact ? `${contact.display_name}` : null,
        data: contact,
        timestamp: Date.now()
      };

      const idx = contactIdsToLoadLoading.indexOf(contactId);
      if (idx >= 0) contactIdsToLoadLoading.splice(idx, 1);
    }

    return newHash;
  }

  

  @handle(EnsureUserReferencesAction)
  private async handleEnsureUserReferencesAction(action: EnsureUserReferencesAction) {
    if (action.ids == null || action.ids.length === 0)
      return;

    this.setState(state => ({
      ...state,
      loading: true
    }));

    const newHash = await this.loadUsers(action.ids);

    this.setState(state => ({
      ...state,
      loading: false,
      referenceHash: {
        ...this.state.referenceHash,
        ...newHash
      }
    }));

    dispatch(new CleanupEntityReferencesAction());
  }

  private async loadUsers(ids: string[]) {
    if (ids == null || ids.length === 0)
      return;

    const updates = ids.filter(
      id =>
        id != null &&
        id.length > 0 &&
        this.state.referenceHash[id] != null
    );

    for (const id in updates) {
      if (this.state.referenceHash[id])
        this.state.referenceHash[id].timestamp = Date.now();
    }

    const idsToFetch = ids.filter(
      id =>
        id != null &&
        id.length > 0 &&
        this.state.referenceHash[id] == null &&
        userIdsToLoad.indexOf(id) == -1 &&
        userIdsToLoadLoading.indexOf(id) == -1
    );

    if (idsToFetch.length == 0) {
      return;
    }

    if (userIdsToLoad.length > 0) {
      // push it on to this thing and return..
      userIdsToLoad.push(...idsToFetch);
      return;
    }

    userIdsToLoad.push(...idsToFetch);

    await wait(10); // this is the buffer time, so that multiple components can all get in here
    userIdsToLoadLoading.push(...userIdsToLoad);
    userIdsToLoad = [];

    const currentIdsToLoad = userIdsToLoadLoading.slice();

    const res = await this.userRepository.getIds(currentIdsToLoad);

    const newHash: { [id: string]: EntityReferenceItem } = {};

    for (const user of res) {
      newHash[user.Id] = {
        id: user.Id,
        type: "User",
        description: `${user.FirstName} ${user.LastName}`,
        data: user,
        timestamp: Date.now()
      };

      const idx = userIdsToLoadLoading.indexOf(user.Id);
      if (idx >= 0) userIdsToLoadLoading.splice(idx, 1);
    }

    // did anything that got loaded not return from the server?
    // if so, we need to fill it out with the defaultUser
    const idsToBeDefaulted = difference(currentIdsToLoad, res.map(u => u.Id));
    for (const id of idsToBeDefaulted) {
      newHash[id] = {
        id: defaultUser.Id,
        type: "User",
        description: `${defaultUser.FirstName} ${defaultUser.LastName}`,
        data: defaultUser,
        timestamp: Date.now()
      };

      const idx = userIdsToLoadLoading.indexOf(id);
      if (idx >= 0) userIdsToLoadLoading.splice(idx, 1);
    }

    return newHash;
  }

  @handle(CleanupEntityReferencesAction)
  private async handleCleanupEntityReferencesAction(action: CleanupEntityReferencesAction) {
    const { referenceHash } = this.state;
    const now = Date.now();
    const oneDay = 24 * 60 * 60 * 1000;
    for (const [id, reference] of Object.entries(referenceHash)) {
      if ((now - (reference?.timestamp || 0)) > oneDay)
        delete this.state.referenceHash[id];
    }

    this.setState(state => ({
      ...state,
      referenceHash: referenceHash
    }));

  }

}
